'use strict';

angular.module('gmpApp')
/**
   * 루트 스코프 전역 설정은 AppController 안에서 한다.
   * 2017-02-10 MatthewKim app.js 에서 옮겨옴
   */

  .controller('AppController', function ($rootScope, $scope, localStorageService, settings, $timeout, $state, userInfo, deliveryInfoList, deliveryInfoListAll, countryList, workSVC, paySVC, gettextCatalog, $cookies, commonSVC, channelSVC, tokenSVC, langSVC, SettingsService, affList, shopAccountModel, tagSVC) {
    langSVC.setCurrentAsync(userInfo.user.sol_type);

    /**
     * 루트스콥 전역 설정
     */
    // localhost 접속 여부
    $rootScope.localMode = window.location.host.indexOf('localhost') > -1;

    // 디버그 접속여부
    // localhost 접속도 디버그 모드로 인식되도록 처리 2019-04-19 rony
    $rootScope.adminMode = userInfo.user.adminMode;

    // 전화번호 노출조건: (첫결제 X 무료 + 웨이크업) 제외 전체 사용자 (https://playautogmpproject.atlassian.net/browse/PA20-5632)
    // 채팅 노출조건: BASIC 이상 전체사용자 + LITE 사용자 && (판토스,콜로세움)
    $rootScope.call_able = !(!userInfo.user.charged_yn && $rootScope.wakeUpTarget);
    $rootScope.chat_able = (!paySVC.limitedVersion.includes(userInfo.user.sol_version) || userInfo.user.sol_version == 'LITE') && ![1051, 1057].includes(userInfo.user.aff_no);

    $rootScope.osse_sol = [26717, 81419, 136886, 162480, 156843, 116854, 138585].includes(userInfo.user.sol_no);

    $rootScope.insightPromotionYN = moment('2025-09-31').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') && ![13749, 17096, 189146].includes(userInfo.user.sol_no);

    window.clarity('set', 'sol_no', userInfo.user.sol_no?.toString());
    window.clarity('set', 'version', userInfo.user.sol_version);

    // clarity 사용자 정보 연결
    $rootScope.setClarityCustomPageId = (pageId) => {
      window.clarity('identify', userInfo.user.email, `${moment().format('YYYYMMDD')}_${userInfo.user.sol_no}`, pageId);
    };

    $rootScope.setClarityCustomPageId('home');

    // 전역 변수 ----------------------------------
    $rootScope.$state = $state;
    $rootScope.settings = settings;
    $rootScope.workUpdatedList = [];

    // 전역 유저 프로필 2017-01-17 Matthew
    $rootScope.user_profile = userInfo.user;

    // 메인메뉴 기본노출조건
    $rootScope.defaultProfileCheck = $rootScope.user_profile.sol_expired === false && $rootScope.user_profile.shopAccount_exceeded === false && $rootScope.user_profile.userAccount_exceeded === false;

    // 운송장 기본문구에 대해 말이 너무 많아서 3월 5일부터 가입한 신규업체만 나오게 설정
    $rootScope.isInvoiceTextView = $rootScope.user_profile.sol_sdate >= '2021-03-05';

    if (!deliveryInfoList || !deliveryInfoList || !deliveryInfoList.length) {
      commonSVC.showMessage('실패', '택배사 정보 로드 실패 - 새로고침 해주세요 \n 이 문제가 지속적으로 발생하는 경우 1:1 문의 남겨주시기 바랍니다.');
    }
    // 사용 택배사 리스트
    $rootScope.deliveryInfoList = deliveryInfoList;
    // 전체 택배사 리스트 (같을 수 있음)
    $rootScope.deliveryInfoListAll = deliveryInfoListAll;

    if (!countryList || !countryList.length) {
      commonSVC.showMessage('실패', '국가 정보 로드 실패 - 새로고침 해주세요 \n 이 문제가 지속적으로 발생하는 경우 1:1 문의 남겨주시기 바랍니다.');
    }
    // 전체 국가 리스트
    $rootScope.countryList = countryList;

    // 작업 카운트 충전 모달창 뜰지 안뜰지 여부
    $rootScope.showWorkCountModal = JSON.parse(localStorageService.get('showWorkCountModal'));

    // 작업카운트 설정 없는 경우 설정
    if (!$rootScope.showWorkCountModal) {
      $rootScope.showWorkCountModal = {
        foreverCloseYn: false,
        closeYn: false,
      };

      localStorageService.set('showWorkCountModal', JSON.stringify($rootScope.showWorkCountModal));
    }

    // 유저 등급
    $scope.user_grade = userInfo.user.user_grade;

    // 웨이크업 서비스 시작 여부
    $rootScope.startWakeUpYN = localStorageService.get('startWakeUpYN') || window.location.hash.substring(3) === 'startWakeUp';
    // 웨이크업 통한 로그인 여부
    $rootScope.isWakeupLogin = $rootScope.startWakeUpYN;
    localStorageService.set('startWakeUpYN', $rootScope.startWakeUpYN);

    // 잔여 작업카운트에 따른 결제유도 여부
    $rootScope.workCount_exceeded = false;

    // 사용자태그 색상 적용
    $rootScope.getColorName = (tagPack) => {
      return tagPack.map(({ color, tag_name }) => {
        return `<span style="color: ${tagSVC.tag_color().find(c => c.name === color).code};">${tag_name}</span>`;
      });
    };

    // 전역 함수 ----------------------------------

    // HTML 상에서 ng-init 등에서 사용하기 위함 2017-02-10 MatthewKim
    $rootScope.parseJson = function (json) {
      return angular.fromJson(json);
    };

    /**
     * 2018-06-25 Daniel
     * 유저프로필 체크
     */
    $rootScope.userProfileCheck = function(key, value, type) {
      if (!key || !value) {
        return false;
      }

      const data = $rootScope.user_profile[key];

      if (!type || type === 'equal') {
        return data === value;
      } else if (type === 'like') {
        return _.indexOf(data, value) >= 0;
      } else {
        return false;
      }
    };

    /**
     * 채널이 있는지 혹은 채널에서 뭘 사용하고 있는지 여부 체크
     * ex) 스마트스토어 사용하는지 useChannelCheck('A077'), 쿠팡 로켓그로스 사용하는지 useChannelCheck('B378', 'growth') 등
     */
    $rootScope.useChannelCheck = function(shop_cd, type = '') {
      if (!shop_cd) {
        return false;
      }

      if (type) {
        // 쿠팡 로켓그로스 체크
        if (shop_cd === 'B378' && type === 'growth') {
          return $rootScope.use_channel_list_scm.find(channel => channel.pa_shop_cd === shop_cd && channel.etc6 === 'true');
        }

      } else {
        return $rootScope.use_channel_list_scm.find(channel => channel.pa_shop_cd === shop_cd);
      }

      return false;
    };

    $rootScope.listColorObj = {
      order: {},
      payment: {},
      unstoring: {},
      claim: {},
      integrated: {},
      product: {}
    };

    /**
     * 웨이크업 서비스 모달 오픈
     */
    $rootScope.openWakeUpModal = async (type, isFirstLogin) => {
      // 최초로그인 완료처리
      $rootScope.first_login && $rootScope.completeFirstLogin();

      const isLocal = window.location.host.includes('localhost');

      if (!isLocal && type === 'ALL') {
        try {
          // 진행중인 웨이크업 작업 여부 확인
          const result = await commonSVC.sendUrl('POST', `${settings.wakeupApiUrl}/api/job/get-procceding-job`, {
            email: userInfo.user.email,
            solType: 'ALL'
          });

          if (result.data.success) {
            if (result.data.isProceeding) {
              commonSVC.showToaster('error', '실패', '다른 상품 이관 작업 진행중입니다.');

              return false;
            }
          } else {
            commonSVC.showToaster('error', '실패', '작업 조회에 실패했습니다.');

            return false;
          }
        } catch (err) {
          commonSVC.showToaster('error', '실패', '작업 조회에 실패했습니다.');

          return false;
        }
      }

      // 상품권한 전체 & 쇼핑몰계정관리 권한 체크
      const channelAuth = commonSVC.checkPermission('setting.roles.channel', userInfo.permission, true),
            onlineAuth = (!userInfo.permission.online || userInfo.permission.online.use_yn)
                          && commonSVC.checkPermission('online.roles.registProd', userInfo.permission, true)
                          && commonSVC.checkPermission('online.roles.editProd', userInfo.permission, true)
                          && commonSVC.checkPermission('online.roles.restoreProd', userInfo.permission, true)
                          && commonSVC.checkPermission('online.roles.deleteProd', userInfo.permission, true)
                          && commonSVC.checkPermission('online.roles.scrapProd', userInfo.permission, true)
                          && commonSVC.checkPermission('online.roles.linkageProd', userInfo.permission, true)
                          && commonSVC.checkPermission('online.roles.excelProd', userInfo.permission, true);

      if (channelAuth && onlineAuth) {
        commonSVC.openModal('xxg', { data: { type, isFirstLogin } }, 'wakeUpCtrl', 'views/home/modals/wakeUp.html', false, true, !$rootScope.wakeUpTarget);
      } else {
        commonSVC.showToaster('error', '앗! 사용 권한이 없네요.', '요청하신 기능은 사용 권한이 필요합니다.\n(상품, 쇼핑몰계정관리)\n PLAYAUTO 2.0 관리자에게 문의해보세요!');
      }
    };

    // client 토큰 세팅
    tokenSVC.setClientToken(localStorageService.get('token'));
    // wakeUp 토큰 세팅
    tokenSVC.setWakeUpToken(localStorageService.get('wakeUpToken'));

    /** 서비스 활성화 */
    // 작업관리 WS 구독으로 변경
    workSVC.Subscribe();

    // $('head').append('<link rel="stylesheet" type="text/css" href="styles/custom_gmp.css?ver=1"/>');

    const filter = 'win16|win32|win64|mac|macintosh|macintel';

    if (navigator.platform) {
      // pc 버전일 경우에만 viewport 적용
      if (filter.indexOf(window.navigator.platform.toLowerCase()) >= 0) {
        $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1">');
      } else {
        // 모바일 접속인 경우 안내 추가
        const mobile_confirm = $cookies.get('mobile_confirm');

        if (mobile_confirm != 'y') {
          const expireDate = new Date();

          expireDate.setDate(expireDate.getDate() + 1);
          $cookies.put('mobile_confirm', 'y', { expires: expireDate });
          noty({
            width: 200,
            text: gettextCatalog.getString('모바일 버전은 준비중입니다.'),
            type: 'alert',
            dismissQueue: true,
            timeout: 4000,
            layout: 'top',
            buttons: false
          });
        }
      }
    }

    const aff = affList.result.find(aff => userInfo.user.aff_no === aff.aff_no);
    let channelBootProfile = {
      name: userInfo.user.company_name,
      user_name: userInfo.user.user_name, //fill with user name
      mobileNumber: userInfo.user.htel, //fill with user phone number
      email: userInfo.user.email,
      CUSTOM_VALUE_1: userInfo.user.user_name, //any other custom meta data
      CUSTOM_VALUE_2: userInfo.user.email,
      sol_sdate: new Date(userInfo.user.sol_sdate).getTime(),
      sol_edate: new Date(userInfo.user.sol_edate).getTime(),
      sol_version: userInfo.user.sol_version,
      charged_yn: userInfo.user.charged_yn ? '유료' : '무료',
      affiliate: aff?.aff_name,
      sales_name: userInfo.user.sales_name,
    };

    if (userInfo.channelIOData) {
      channelBootProfile = {
        ...channelBootProfile,
        ...userInfo.channelIOData,
        cash_date: new Date(userInfo.channelIOData.cash_date).getTime(),
        invoice_date: new Date(userInfo.channelIOData.invoice_date).getTime(),
        mdate: new Date(userInfo.channelIOData.mdate).getTime(),
        ol_date: new Date(userInfo.channelIOData.ol_date).getTime(),
        ord_date: new Date(userInfo.channelIOData.ord_date).getTime(),
        sol_shop_date: new Date(userInfo.channelIOData.sol_shop_date).getTime()
      };
    }

    if ($rootScope.chat_able) {
      // channel.io boot
      channelSVC.boot({
        pluginKey: 'a749ffe5-34f5-4736-885f-338c73a51825',
        userId: userInfo.user.sol_no, //fill with user id
        profile: channelBootProfile,
        hideDefaultLauncher: true
      });
    }

    // 글로벌 이벤트 바인딩
    $rootScope.$on('$stateChangeStart', (...args) => {

      // 페이지 이동시 채널톡에 페이지 이벤트 등록. (스티븐 요청)
      // 어드민모드인경우에는 처리안함.
      if (!$rootScope.adminMode && args.length === 5 && args[1].url && typeof args[1].url === 'string') {
        channelSVC.track('PageView', {
          url: `${window.location.origin}/#!${args[1].url}`
        });
      }

      $rootScope.$broadcast('$stateChangeStartGlobal', ...args);
    });

    $rootScope.$on('$stateChangeSuccess', async (...args) => {
      $rootScope.$broadcast('$stateChangeSuccessGlobal', ...args);
    });

    $rootScope.$on('$stateNotFound', (...args) => {
      $rootScope.$broadcast('$stateNotFoundGlobal', ...args);
    });

    $rootScope.$on('$stateChangeError', (...args) => {
      $rootScope.$broadcast('$stateChangeErrorGlobal', ...args);
    });

    $rootScope.$on('$stateChangeSuccessForJquery', (...args) => {
      $rootScope.$broadcast('$stateChangeSuccessForJqueryGlobal', ...args);
    });

    $rootScope.$on('$locationChangeSuccess', (...args) => {
      $rootScope.$broadcast('$locationChangeSuccessGlobal', ...args);
    });

    $rootScope.$on('$closeTab', (...args) => {
      $rootScope.$broadcast('$closeTabGlobal', ...args);
    });

    $rootScope.$on('infoEdit', (...args) => {
      $rootScope.$broadcast('infoEditGlobal', ...args);
    });

    $rootScope.$on('templateRefresh', (...args) => {
      $rootScope.$broadcast('templateRefreshGlobal', ...args);
    });

    $rootScope.$on('$reloadTableForMappingResult', (...args) => {
      $rootScope.$broadcast('$reloadTableForMappingResultGlobal', ...args);
    });

    $rootScope.$on('sidebarChange', (...args) => {
      $rootScope.$broadcast('sidebarChangeGlobal', ...args);
    });

    $rootScope.$on('edit', (...args) => {
      $rootScope.$broadcast('editGlobal', ...args);
    });

    $rootScope.$on('helpTypeChange', (...args) => {
      $rootScope.$broadcast('helpTypeChangeGlobal', ...args);
    });

    $rootScope.$on('matchSave', (...args) => {
      $rootScope.$broadcast('matchSaveGlobal', ...args);
    });

    $rootScope.$on('modalOpen', (e, ...args) => {
      $rootScope.$broadcast('modalOpenGlobal', ...args);
    });

    $rootScope.$on('modalClosed', (...args) => {
      $('#tip').remove(); // 모달창에서 esc로 종료할 경우 툴팁이 삭제되지 않는 경우가 있어서 추가

      const element = document.querySelector('.select2-container--open');

      if (element) {
        element.remove();
      }

      $rootScope.$broadcast('modalClosedGlobal', ...args);
    });

    // 즐겨찾기 탭 저장
    const setBookmarkTab = () => {
      const tabs = localStorageService.get('tab_menus');

      if (tabs) {
        const bookmarkTabs = tabs.filter(({ bookmark }) => bookmark);

        localStorageService.set('tab_menus', _.uniqBy(bookmarkTabs));
      }
    };

    // 접속 정보 제거
    $rootScope.cleanUpConnection = () => {
      commonSVC.logout();
    };

    const isSolExpired = $rootScope.user_profile.sol_expired,               // 솔루션 만료 여부
          isMaster = $rootScope.user_profile.user_grade === '마스터'; // 마스터계정 여부

    // 대리점, 부품사 등 super, sub 사용 업체 (pa_sol_no)
    if ($rootScope.userProfileCheck('sol_ser', 'super_user', 'like') || $rootScope.user_profile.pa_sol_no) {
      switch (userInfo.user.aff_no) {
        case 800:
          $rootScope.affName = 'LG전자';
          break;
        case 1062: // 테스트용
        case 1063:
          $rootScope.affName = '에픽카';
          break;
        default:
          $rootScope.affName = '';
          break;
      }
    } else {
      $rootScope.affName = '';
    }

    const ROOT_URL = `/login.html${$rootScope.affName === '에픽카' ? '?company=efc' : ''}`;

    // 주문매출리포트 대상자 ( 대리점, 슈퍼계정 X )
    // 쇼핑몰, 사용자 아이디수가 초과되어 솔루션 사용이 불가능한경우 모달 출력 X
    // 리포트 신청했거나 거부한 사용자의 경우 모달 출력 X
    $rootScope.isShowOrderReport =
      !isSolExpired && isMaster && !$rootScope.user_profile.pa_sol_no && !$rootScope.userProfileCheck('sol_ser', 'super_user', 'like') &&
      !(userInfo.user.shopAccount_exceeded || userInfo.user.userAccount_exceeded) &&
      !localStorageService.get('orderReportNotShow') && !($rootScope.user_profile.report_time || $rootScope.user_profile.report_out_yn);

    // TODO: 최종오픈시 변경 필요
    // // 웨이크업 서비스 노출 대상자 : (신규가입자 or 기존 웨이크업 유저) && (LG, 판토스, 콜로세움) 제휴업체 아닌 유저
    // $scope.wakeUpTarget = ($rootScope.user_profile.sol_sdate >= '2023-06-07' || [999, 998, 997, 996].includes(userInfo.user.aff_no)) && ![800, 1051, 1057].includes(userInfo.user.aff_no);
    // 웨이크업 서비스 노출 대상자 : 올웨이즈, 11번가, 위메프, 쿠팡 웨이크업 유저
    $rootScope.wakeUpTarget = [999, 998, 997, 996, 995, 994, 993].includes(userInfo.user.aff_no);

    // 패스워드 변경 안내모달 노출
    $scope.showPwdNoticeYN = false;
    // 최초로그인 여부
    $rootScope.first_login = localStorageService.get('first_login');
    // 최초로그인 완료처리
    $rootScope.completeFirstLogin = () => {
      localStorageService.set('first_login', false);
      $rootScope.first_login = false;
    };
    // 정기적 사용자정보 확인 안내모달 노출
    $scope.showCmpInfoNoticeYN = $rootScope.user_profile.user_grade === '마스터' && moment().endOf('day').diff(moment($rootScope.user_profile.sol_sdate).endOf('day'), 'days') + 1 >= 180;

    // 웨이크업 페이지에서 접속시 웨이크업모달 Open
    if ($rootScope.startWakeUpYN) {
      $rootScope.openWakeUpModal();
    } else {
      // 패스워드 변경 안내 대상자 확인
      commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/users/check-passwords`, {}, async (state, data) => {
        if (data?.result?.[0].pass_yn == 1) {
          const today = moment().format('YYYYMMDD');
          const dd = localStorage.getItem('pwd_change');

          if (dd !== null && (dd === 'init' || today > dd)) {
            $scope.showPwdNoticeYN = true;
          }
        }

        if ($rootScope.first_login) {
          if ($rootScope.wakeUpTarget) {
            // TODO: 웨이크업 최종오픈시 변경해야함
            showSolIntroductionModal();
            // $rootScope.openWakeUpModal('', true);
          } else {
            welcomePage();
          }
        }
      });
    }

    /**
     * 솔루션 소개 모달 오픈
     */
    function showSolIntroductionModal () {
      // 최초로그인 완료처리
      $rootScope.first_login && $rootScope.completeFirstLogin();

      commonSVC.openModal('xmd', { data: { isSummary: false }, addClass: 'order-report' }, 'solIntroductionCtrl', 'views/home/modals/solIntroduction.html', false, false, false);
    }

    // Welcome Page 노출
    function welcomePage () {
      $timeout(() => {
        $('.tutorial-welcomePage-main').show();
      }, 300);
    }

    let isWelcomeFirst = true;

    $scope.welcomeClick = () => {
      if (isWelcomeFirst) {
        $('.tutorial-welcomePageWrap').animate({ left: '-100%' });
        $('.currentList li').removeClass();
        $('.currentList li:last').addClass('active');
      } else {
        $('.tutorial-welcomePageWrap').animate({ left: '0' });
        $('.currentList li').removeClass();
        $('.currentList li:first').addClass('active');
      }
      isWelcomeFirst = !isWelcomeFirst;
    };

    /**
     * PLTO 2.0 시작!
     */
    $scope.startSolution = () => {
      $('.tutorial-welcomePage-main').hide();
      $rootScope.showFirstShopAddModal();
    };

    // 최상단 페이지로 이동
    $rootScope.moveRoot = () => {
      location.href = ROOT_URL;
    };

    // 로그아웃
    $rootScope.logout = () => {
      setBookmarkTab();
      $rootScope.cleanUpConnection();
      $rootScope.moveRoot();
    };

    /**
     * 사용제한시 버전추천 모달 오픈
     * mainController -> AppController 위치 이동: scope 함수선언 전에 disableSolution 호출되는 문제로 코드를 상단으로 이동시켜야하는데. 가독성을 위해 AppController 로 이동
     */
    $rootScope.showVersionAdvisor = (location, next) => {
      $timeout(function() {
        const type = location == 'main.configs_user' ? 'user' : 'shop';
        const typeNm = type == 'user' ? '사용자' : '쇼핑몰';

        // 추천 버전 검사
        const versionInfos = $rootScope.getVersionInfos(type, userInfo.user[`${type}AccountCnt`]);

        const resolve = {
          data: { type, ...versionInfos }
        };

        commonSVC.openModal('md', resolve, 'VersionAdvisorController', 'views/common/versionAdvisor.html', false, true, false)
          .result.then(re => {
            if (re === 'success') {
              $rootScope.openPayReq({ recommendVersion: versionInfos.recommendInfo.prod_name });
            } else if (next) {
              next();
            }
          }).catch(function(err) {
            if (err.includes('escape')) {
              if (next) {
                next();
              }

              return false;
            }
            commonSVC.showConfirmCustomSwal2({
              width: 700,
              title: `<div style="text-align: left;">사용 가능한 ${typeNm} 계정 개수를 초과하였습니다.</div>`,
              text: `<div style="white-space: pre-wrap;text-align: left;line-height: 2;padding-bottom: 40px!important;">[설정 > ${typeNm} ${typeNm == '쇼핑몰' ? '계정' : ''}관리]에 저장된 ${typeNm} 계정을 삭제하거나 ${typeNm} 계정 개수에 맞는 버전으로 업그레이드를 진행하시면 정상적으로 사용이 가능합니다.</div>`,
              showCancelButton: true,
              confirmButtonText: '버전 업그레이드 하기',
              cancelButtonText: `${typeNm} 계정 삭제 후 사용`,
            }, (re) => {
              if (re.isConfirmed === true) {
                $rootScope.openPayReq({ recommendVersion: versionInfos.recommendInfo.prod_name });
              } else {
                next();
              }
            });
          });
      }, 500);
    };

    // 추천 노출버전
    $rootScope.recommendVersions = ['SLIM', 'BASIC', 'CLASSIC', 'SPECIAL', 'PLATINUM', 'VIP', 'VIP+'];

    // 추천 버전
    const getRecommendVersion = (type, accountCnt) => {
      const accountCntByVersion = (() => {
        switch (type) {
          case 'shop': return userInfo.user.availableShopAccountCntByVersion;
          case 'user': return userInfo.user.availableUserAccountCntByVersion;
          case 'order': return userInfo.user.availableOrdCntByVersion;
          case 'product': return userInfo.user.availableOlCntByVersion;
        }
      })();

      // 추천버전
      return accountCntByVersion.find(o => $rootScope.recommendVersions.includes(o.prod_name) && o.versionCntNumeric >= accountCnt)?.prod_name || 'SLIM';
    };

    // 추천 버전 검사
    $rootScope.getVersionInfos = (type, accountCnt) => {
      // 사용중인 버전정보
      const currentInfo = {
        prod_name: userInfo.user.sol_version,
        shopVersionCnt: userInfo.user.sol_version == 'ZERO' ? 2 : userInfo.user.shopVersionCnt,
        userVersionCnt: userInfo.user.userVersionCnt,
        ordVersionCnt: userInfo.user.availableOrdCntByVersion.find(o => o.prod_name == userInfo.user.sol_version).versionCnt,
        olVersionCnt: userInfo.user.availableOlCntByVersion.find(o => o.prod_name == userInfo.user.sol_version).versionCnt,
      };

      let recommend_version = userInfo.user.sol_version;

      // 최상위버전 제외검사
      if (recommend_version !== 'VIP+') {
        if (type) {
          // 특정 타입만 검사시
          recommend_version = getRecommendVersion(type, accountCnt);
        } else {
          // 전부 검사
          const recommendIdx = ['shop', 'user', 'order', 'product'].reduce((prev, type) => {
            let idx;
            switch (type) {
              case 'shop':
              case 'user':
                idx = $rootScope.recommendVersions.findIndex(o => o === getRecommendVersion(type, userInfo.user[`${type}AccountCnt`]));
                if (idx > prev) { prev = idx; }
                break;
              case 'order':
              case 'product':
                idx = $rootScope.recommendVersions.findIndex(o => o === userInfo.user.sol_version) + +$rootScope.workCount_exceeded;
                if (idx > prev) { prev = idx; }
                break;
            }

            return prev;
          }, 0);

          recommend_version = $rootScope.recommendVersions[recommendIdx];
        }
      }

      // 추천 버전정보
      const recommendInfo = {
        prod_name: recommend_version,
        shopVersionCnt: userInfo.user.availableShopAccountCntByVersion.find(o => o.prod_name == recommend_version).versionCnt,
        userVersionCnt: userInfo.user.availableUserAccountCntByVersion.find(o => o.prod_name == recommend_version).versionCnt,
        ordVersionCnt: userInfo.user.availableOrdCntByVersion.find(o => o.prod_name == recommend_version).versionCnt,
        olVersionCnt: userInfo.user.availableOlCntByVersion.find(o => o.prod_name == recommend_version).versionCnt,
      };

      return { currentInfo, recommendInfo };
    };

    // 자동 세팅 솔루션 작업이 완료됐으나 쇼핑몰 상품 관리 로딩중인 경우 리스트 정상 출력
    const checkAutoSettingJob = async () => {
      const params = {
        start: 0,
        length: 100,
        orderby: 'id desc',
        solJobYn: true,
        jobType: 'sol',
        search_key: 'job_name',
        search_word: '자동세팅',
        search_type: 'exact',
        date_type: 'wdate',
        state: '',
      };

      const url = `${settings.pa20ApiUrl}/app/work/list`;
      const { data: { results: solWorks } } = await commonSVC.sendUrl('POST', url, params);

      if (!solWorks.some(work => work.state === 'active')) {
        localStorage.setItem(`isOnlineListLoading_${userInfo.user.sol_no}`, false);
        $rootScope.isOnlineProdLoading = false;
      }
    };

    if (localStorage.getItem(`isOnlineListLoading_${userInfo.user.sol_no}`) === 'true') {
      checkAutoSettingJob();
    }

    /**
     * 솔루션 만료 결제유도 모달
     * mainController -> AppController 위치 이동: scope 함수선언 전에 disableSolution 호출되는 문제로 코드를 상단으로 이동시켜야하는데. 가독성을 위해 AppController 로 이동
     */
    $rootScope.openPaySolExpired = () => {
      $timeout(function() {
        try {
          commonSVC.openModal('mini', {}, 'PaySolExpiredController', 'views/common/paySolExpired.html', false, true, false);
        } finally {
          // 입점결과 안내 알림
          $rootScope.notifyShopEntryStatus();
        }
      }, 500);
    };
  });
