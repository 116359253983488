'use strict';

angular.module('gmpApp')
  .service('insightModel', function (settings, commonSVC) {
    return {
      /**
       * 세일즈 인사이트 관련 카운트 조회
       */
      searchInsightCount: () => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight`, {});
      },

      /**
       * 세일즈 인사이트 상품 등록
       */
      addInsightProd: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/insight`, params);
      },

      /**
       * 세일즈 인사이트 그룹, 그룹상세 삭제
       */
      deleteInsightProd: (params) => {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/insight`, params);
      },

      /**
       * 세일즈 인사이트 그룹 리스트 조회
       */
      getInsightGroups: () => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/group`, {});
      },

      /**
       * 세일즈 인사이트 그룹 상세 리스트 조회
       */
      getInsightGroupDetail: (params) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/detail`, params);
      },

      /**
       * 세일즈 인사이트 그룹 알람 발송 시각 초기화
       */
      resetInsightGroupReadAlarm: (params) => {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/insight/reset-alarm-time`, params);
      },
      /**
       * 세일즈 인사이트 그룹 순서 변경
       */
      changeInsightGroupSeq: (params) => {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/insight/group`, params);
      },

      /**
       * 세일즈 인사이트 판매가 변동 추이 조회
       */
      getInsightStatList: (params) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/insight/stat`, params);
      },

      /**
       * 세일즈 인사이트 내 상품 (쿠팡) 옵션 리스트 조회
       */
      getOnlineOpts: (params) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/opts`, params);
      },

      /**
       * 세일즈 인사이트 상품 가격 수정 예약 등록
       */
      addPriceEditSchedule: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/insight/schedule`, params);
      },

      /**
       * 세일즈 인사이트 비교상품 판매가 알림 등록
       */
      addPriceAlarmSchedule: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/insight/alarm`, params);
      },
      /**
       * 세일즈 인사이트 상품 가격 수정 예약 등록
       */
      deletePriceEditSchedule: function(params) {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/insight/schedule`, params);
      },

      /**
       * 세일즈 인사이트 비교상품 판매가 알림 등록
       */
      deletePriceAlarmSchedule: function(params) {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/insight/alarm`, params);
      },
      /**
       * 세일즈 인사이트 상품 가격 수정 예약 리스트 조회
       */
      priceEditScheduleList: function(params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/schedule`, params);
      },

      /**
       * 세일즈 인사이트 상품 가격 수정 예약 상세 조회
       */
      priceEditScheduleDetail: function(params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/schedule/${params.insight_book_no}`, {});
      },

      /**
       * 세일즈 인사이트 비교상품 판매가 알림 리스트 조회
       */
      priceAlarmScheduleList: function(params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/alarm`, params);
      },

      /**
       * 세일즈 인사이트 비교상품 판매가 상세 조회
       */
      priceAlarmScheduleDetail: function(params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/alarm/${params.insight_alarm_no}`, {});
      },
    };
  });
