'use strict';
angular.module('gmpApp')
  .controller('OnlineProductAddCtrl', function ($rootScope, $scope, $state, $compile, $q, $timeout, onlineProductSVC, productSVC, categorySVC, commonSVC, errorSVC, commonModel, shopAccountModel, templateModel, categoryModel, systemModel, onlineProductModel, productModel, systemList, madeinList, warehouseList, categoryList, templateGroupList, siteInfo, userInfo) {
    $scope.sol_stock_auth = userInfo.user.sol_stock > 0 && (userInfo.user.auth_type === '총괄관리자' || userInfo.permission?.stock?.use_yn);

    // 사용중인 쇼핑몰
    const channelList = $rootScope.useChannelList({ site_action: 'RegistProd', func: shop => !!shop.prod_use_yn });

    // 엘지 커스텀용 모델번호 노출 여부
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));

    // 스마일배송 사용여부
    const isSmile = $rootScope.userProfileCheck('sol_ser', 'smile', 'like');
    $scope.ebayDepots = warehouseList.data?.result?.filter(o => o.ebaydepot_yn).map(o => o.code) || [];

    // 에픽카 전용
    $scope.efcCheck = {
      oemChecked: false, // OEM 부품번호 비활성화 여부
      modelChecked: false, // 차대번호 비활성화 여부
      textCheck: function (site_list) {
        return $rootScope.affName === '에픽카' && site_list.some(site => site.pa_shop_cd === 'P051');
      } // 에픽카 전용 문구 출력 여부
    };

    $scope.esmIdCheck = false;
    // ESM 마스터 상품 생성 전용 변수
    $scope.checkEsmMaster = { // ESM 마스터 상품 생성 가능 여부
      disabled: false,
      checked: false
    };
    $scope.canEsmMasterList = []; // ESM 마스터 상품 생성 가능 계정 리스트
    $scope.maxEsmCnt = 0;
    $scope.esmMatchShops = []; // ESM 마스터 상품 선택한 지마켓,옥션 묶음

    $scope.leadProdInfoModelno = {};
    $scope.modelProdMatch = {
      checked: false
    };

    function basicInfoInit () {
      $scope.esmImage = true;
      $scope.detailType = 'add';                                  // 수정인지 등록인지 구분하는 타입
      $scope.data = angular.copy(onlineProductSVC.defaultVdata);  // 상품등록 기본정보

      // 대리점모듈일 경우 내일배송 변수 기본값 세팅
      if ($scope.viewModelNo) {
        $scope.data.nextday_ship_ol_yn = 0;
        $scope.data.nextday_ship_avail_yn = 0;
      }

      $scope.data.checkGrowth = { // 쿠팡 로켓 그로스 생성 가능 여부
        available: false,
        coupang: true,
        rocket: false
      };

      $scope.categoryListModal = false;                           // 카테고리 선택모달창 유무
      $scope.categoryCode = '';                                   // 선택한 카테고리 코드
      $scope.categoryName = '';                                   // 선택한 카테고리 이름
      $scope.stock = {
        image: '/assets/images/upload.png'
      };
      $scope.inValidShop = false;                                 // 쇼핑몰별 유효성 체크
      $scope.pattern = {
        validateModelBrand: '[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\\s`~!@#$%^&*()_+-=[\\]\\{}|:;\'",.<>/?\\\\]{0,40}',
        validateMaker: '[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\\s`~!@#$%^&*㈜()_+-=[\\]\\{}|:;\'",.<>/?\\\\]{0,40}',
      };

      // directive(form) 용 추가 2018-03-13 rony
      $scope.reCheckClass = {};

      // 안전인증유형
      $scope.safeCertifyList = onlineProductSVC.safeCertifyList;

      // 면제유형 2018-07-13 alvin
      $scope.ExemptionList = angular.copy(onlineProductSVC.ExemptionList);

      $scope.categoryOptionsFlag = false;

      // 기타상세설명
      $scope.detailDescEtc = angular.copy(onlineProductSVC.detailDescEtc);

      // 카테고리 검색
      $scope.searchForm = angular.copy(searchForm);
      $scope.categorySearchKey = '';
    }

    const checkAdditionalInfo = categorySVC.checkAdditionalInfo;
    $scope.search_yn = false;
    $scope.updateCateData = {};
    $scope.originData = {};
    $scope.applyList = {};
    $scope.simCateList = {};
    $scope.scrapShopList = {};
    $scope.matchingData = {}; //매칭정보 초기화시 데이터
    $scope.placeholder = '상단의 검색창에서 카테고리명을 검색해 보세요.';
    $scope.search = {};
    $scope.matchingInfo = [];
    $scope.shopList = [];
    $scope.type = 'new';
    $scope.modalLoadYn = false;
    $scope.selectOptList = []; // esm 추천옵션 리스트

    let originSimCateList = {};
    let openIdx = null;
    let searchWord = [];

    $scope.getCateData = () => {
      $scope.cateReloadYn = true;

      // 카테고리 리스트 조회
      categoryModel.categorySelectList()
        .then(function (data) {
          $scope.categoryList = data.data;
        })
        .catch(function () {
          commonSVC.showMessage('카테고리 불러오기 실패', '일시적 서버 불안정 일수 있으므로, 재시도 후 안될 시 본사로 문의 부탁드립니다.');
        })
        .finally(function () {
          $scope.cateReloadYn = false;
        });
    };

    // 카테고리 새로고침
    $scope.cateReload = () => {
      $scope.categorySearchKey = '';
      $scope.getCateData();
    };

    function basicNecessaryListInit () {
      $scope.madeinList = madeinList.data.result || [];                  // 원산지 리스트
      $scope.madein_group_list = [];                                     // 원산지 그룹
      $scope.madein_name_list = [];                                      // 원산지 지역
      $scope.warehouseList = warehouseList.data.result || [];            // 배송처 리스트
      $scope.categoryList = categoryList.data.results || [];             // 카테고리 리스트
      $scope.getCateData();
      $scope.templateGroupList = templateGroupList.data.results || [];   // 템플릿 그룹 리스트
      $scope.use_engines = channelList; // 사용중인 채널 리스트
      $scope.prod_auto_set_yn =
        systemList.data.prod_auto_set_yn == 'Y' ? 1 : 0;                 // SKU상품 대표 자동세팅 여부
      $scope.expirationMonths = _.range(1, productSVC.expirationMonth);  // 유통기한 개월수 리스트(1~50)
      $scope.loadTemporary = false;                                      // 임시저장 시에는 카테고리 메타데이터 로딩하지 않기 위해 쓰이는 flag
      $scope.sku_list = [];                                              // SKU상품 리스트
      $scope.use_shops = [];
      $scope.use_engines.forEach(s => $scope.scrapShopList[s.shop_cd] = s.scrap_cate_yn); // 쇼핑몰별 카테고리 검색 여부
    }

    // 템플릿 요약정보 출력
    function templateCapsuleDataInit () {
      $scope.template_summary = false;
      $scope.template_group_no = '';
    }

    // 기본 노출 탭
    function basicExposureTabInit () {
      $scope.tabDisabled = true;
      $scope.tabActive = 1;
      $scope.opTabActive = 0;
      $scope.optInValidTab = [true, true, true, true, true, true, true, true, true, true, true, true, true, true];
      $scope.channelSelectAll = true;
    }

    function basicOptCheckListInit () {
      $scope.checked = angular.copy(onlineProductSVC.checkedField);             // 상품 등록 속성들 체크 여부
      $scope.disabled = angular.copy(onlineProductSVC.activatedField);          // 활성화 여부
      $scope.flag = angular.copy(onlineProductSVC.flagFiled);                   // 체크 플래그
      $scope.prodOpt = angular.copy(onlineProductSVC.prodOpt);                  // 옵션설정 매칭된 SKU상품 리스트
      $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);          // 일반 옵션명, 옵션내용
      $scope.prodOpt.wmp_opt = angular.copy(onlineProductSVC.default_opt);      // 위메프 옵션명, 옵션내용
      $scope.prodOpt.esm_opt = angular.copy(onlineProductSVC.default_opt);			// ESM 옵션명, 옵션내용
      $scope.prodOpt.esm_checked_opt = [];																	    // ESM 선택 옵션
      $scope.prodOpt.std_opt = angular.copy(onlineProductSVC.default_opt);  		// 11번가 옵션명, 옵션내용
      $scope.prodOpt.cpang_opt = angular.copy(onlineProductSVC.default_opt);    // 쿠팡 옵션명, 옵션내용
      $scope.prodOpt.rocket_opt = angular.copy(onlineProductSVC.default_opt);   // 로켓그로스 옵션명, 옵션내용
      $scope.prodOpt.hplus_opt = [];                                            // 홈플러스 옵션명, 옵션내용
      $scope.prodOpt.galleria_opt = [];                                         // 갤러리아몰 옵션명, 옵션내용
      $scope.prodOpt.aliexpress_opt = angular.copy(onlineProductSVC.default_opt);	// 알리 익스프레스 옵션명, 옵션내용
      $scope.prodOpt.alibaba_opt = angular.copy(onlineProductSVC.default_opt);	// 알리바바 옵션명, 옵션내용
      $scope.prodOpt.naver_single_opt = angular.copy(onlineProductSVC.default_opt);	// 스마트스토어 옵션명, 옵션내용
      $scope.prodOpt.aliexpress_global_opt = angular.copy(onlineProductSVC.default_opt);	// 알리익스프레스 글로벌 옵션명, 옵션내용
      $scope.prodOpt.temu_opt = angular.copy(onlineProductSVC.default_opt);	// 테무 옵션명, 옵션내용
      $scope.data.site_list = [];                                               // 사이트 리스트
    }

    function onlineProductOptListInit () {
      $scope.onlineOptionsList = [];  // 온라인, 단일상품 모델명, 브랜드, 제조사 검색 리스트
      $scope.prodOpt.add_opt = [{}];          // 추가 구매 옵션
      $scope.prodOpt.add_opt_table = [];      // 추가 구매 옵션 테이블
    }

    // 오픈되어있는 템플릿 정보
    function openTemplateDataInit () {
      $scope.openTemplate = '';
      $scope.templateListModal = false; // 템플릿 선택
      $scope.olShopType = 'add';
    }

    // 기본옵션을 esm 옵션으로
    function basicOptToEsmInit () {
      $scope.checked.esm_defaultOpt = false;
      $scope.checked.std_esm_defaulOpt = false;
      $scope.checked.wmp_esm_defaultOpt = false;
    }

    // 기본옵션을 std 옵션으로
    function basicOptToStdInit () {
      $scope.checked.std_defaultOpt = false;
      $scope.checked.wmp_std_defaultOpt = false;
    }

    // 인증유형 데이터 초기화
    function certDataInit () {
      $scope.certlist = {};               // 인증유형
      $scope.certDataList = [];
      $scope.certDataList.push({
        cert_cd: '',                      // 인증유형
        cert_exc_type: '',                // 면제유형
        cert_ministry: '',                // 인증기관
        cert_no: '',                      // 인증번호
        cert_model: '',                   // 인증모델
        cert_cname: '',                   // 인증상호
        cert_date: '',                    // 인증일
        cert_expire_date: '',             // 만료일
        dateChk: false                    // 인증일 체크
      });
    }

    // 조회된 ESM 2.0 옵션데이터 2018-04-25 rony
    let checkEsmOptData = [],
        stdOptLoadESM = false,
        stdOptLoadA112 = false; // 단일상품 옵션 정보 PA 로딩 카운트

    let dataBak = null,
        tmpsave_no;

    // SKU상품 검색 폼 초기화. 2017-10-18 서상권
    const searchForm = {
      start: '0',
      length: '99999',
      orderby: 'wdate desc',
      date_type: 'wdate',
      search_key: 'all',
      search_word: '',
      supplier_vendor: 'all',
      delivery_vendor: 'all',
      brand_code: '',
      category: 'all',
      linkage_flag: 'all',
      tax_type: 'all'
    };

    const models = ['model', 'brand', 'maker'],
          site_tab = ['', 'esm_', 'std_', 'cpang_', 'wmp_', 'hplus_', 'galleria_', 'naver_', 'aliexpress_', 'alibaba_', 'rocket_', 'naver_single_', 'aliexpress_global_', 'temu_'];
    let currentOpt = '';

    basicInfoInit();                  // 기본 정보
    basicOptCheckListInit();          // 기본 옵션 및 체크 리스트
    basicNecessaryListInit();         // 기본 필수 리스트
    basicExposureTabInit();           // 기본 노출 탭
    basicOptToEsmInit();              // 기본 옵션을 esm 옵션으로
    basicOptToStdInit();              // 기본 옵션을 std 옵션으로
    templateCapsuleDataInit();        // 템플릿 요역 정보
    onlineProductOptListInit();       // 온라인 상품 옵션 리스트
    openTemplateDataInit();           // 오픈 되어있는 템플릿 정보
    certDataInit();                   // 인증 정보

    // 재고관리 미사용 기본배송처 선택
    if ($rootScope.user_profile.sol_stock < 1) {
      $scope.data.depot_no = String(systemList.data.default_depot_no);
    }

    //2018-07-13 alvin - 인증유형 추가, 삭제
    $scope.calculateOPT = function (calMode, idx) {
      if (calMode === 'plus') {
        const maxCount = 10; //10개까지 추가 가능

        if ($scope.certDataList.length < maxCount) {
          $scope.certDataList.push({
            cert_cd: '',
            cert_exc_type: '',
            cert_ministry: '',
            cert_no: '',
            cert_model: '',
            cert_cname: '',
            cert_date: '',
            cert_expire_date: '',
            dateChk: false,
          });
        }
      } else {
        $scope.certDataList.splice(idx, 1);
      }
    };

    //2018-07-13 alvin - 조건에 따른 면제유형 비활성화 처리
    $scope.defaultCertCheck = function(idx) {
      const certData = _.find($scope.safeCertifyList, function(o) {
        return o.value == $scope.certDataList[idx].cert_cd;
      });

      if (certData) {
        var bool = !!((certData.text.indexOf('생활용품') == -1 && certData.text.indexOf('전기용품') == -1));
      }
      $scope.certDataList[idx].cert_exc_type = bool ? '' : $scope.certDataList[idx].cert_exc_type;

      return bool;
    };

    //2018-07-13 alvin - 만료일 인증일 체크
    $scope.certDateSet = function(idx) {
      const cert_date = $scope.certDataList[idx].cert_date;
      const cert_expire_date = $scope.certDataList[idx].cert_expire_date;

      //인증일이 만료일보다 크면
      if (cert_date && cert_expire_date) {
        if (cert_date > cert_expire_date) {
          $scope.certDataList[idx].dateChk = true;
        } else {
          $scope.certDataList[idx].dateChk = false;
        }
      }
    };

    /**
   * input file clear
   */
    $scope.fileClear = function($event) {
      const $target = $($event.currentTarget);
      const $input = $target.siblings('input');

      $input.val(null);
    };

    /**
     * 탭 변경
     */
    $scope.changeTab = function(idx) {
      $scope.data.site_list = $scope.data.site_list.map(v => ({ ...v, img_ck: false }));

      if (!$scope.tabDisabled) {
        $scope.tabActive = idx;
      } else {
        $scope.tabActive = 1;

        return;
      }

      if (idx === 2) {
        prodNameCheck();
        if ($scope.categoryOptionsFlag === true) {
          $scope.categoryOptions();
          $scope.categoryOptionsFlag = false;
        } else {
          $scope.defaultOpVisible();
        }
      }

      // 갤러리아몰 옵션 재조합
      if (idx === 2 && $scope.checked.GALLERIA) {
        $scope.getGalleriaCate();
      }
    };

    //상품명(프로모션 상품명 추가) 체크
    function prodNameCheck() {
      $scope.data.site_list.forEach((site) => {
        let check_shop = site.pa_shop_cd;
        let std_ol_yn = 0;

        if (['B719', 'P020'].includes(check_shop)) {
          std_ol_yn = 1;
        }
        if (((check_shop === 'A001' || check_shop === 'A006') && $scope.checked.ESM) || (check_shop === 'A112' && $scope.checked.A112)) {
          check_shop += '_single';
          std_ol_yn = $scope.checked.smileProd ? 2 : 1;
        }
        const { shop_sale_name_max_length, shop_sale_name_max_length_char_yn } = onlineProductSVC.getSaleNameLimiter(site.pa_shop_cd);
        const maxLength = shop_sale_name_max_length || 100;

        site.prod_name_max = maxLength;
        site.prod_name_is_byte = shop_sale_name_max_length_char_yn !== 1;
        site.prod_name_role = `${maxLength} ${shop_sale_name_max_length_char_yn ? '자' : 'byte'}`;
        site.std_ol_yn = std_ol_yn;

        if (onlineProductSVC.promotionProductName[check_shop] && (check_shop !== 'B378' || (check_shop === 'B378' && $scope.checkRocketGrowth(site.shop_id)))) {
          site.promotion_name = onlineProductSVC.promotionProductName[check_shop].name;
          site.promotion_name_str = onlineProductSVC.promotionProductName[check_shop].str || 0;
          site.promotion_name_byte = site.promotion_name_str ? 0 : onlineProductSVC.promotionProductName[check_shop].byte;
        } else {
          site.promotion_name = '';
          site.promotion_name_byte = 0;
        }
      });
    }

    $scope.setCategoryOptionsFlag = async (check, type) => {
      switch (type) {
        case 'A112':
          $scope.checked.A112 = check;
          // 11번가 신규 상품 등록 이면 단일 상품 으로 등록 std_ol_yn = 1
          if ($scope.data.site_list.find(site => site.pa_shop_cd === 'A112')) {
            const siteIndexList = $scope.data.site_list.reduce((pre, curr, index) => curr.pa_shop_cd === 'A112' ? [...pre, index] : pre, []);
            siteIndexList.forEach(indx => {
              $scope.data.site_list[indx].std_ol_yn = check ? 1 : 0;
            });
          }
          break;
        case 'A077':
          $scope.checked.A077single = check;
          if ($scope.data.site_list.find(site => site.pa_shop_cd === 'A077')) {
            const siteIndexList = $scope.data.site_list.reduce((pre, curr, index) => curr.pa_shop_cd === 'A077' ? [...pre, index] : pre, []);
            siteIndexList.forEach(indx => {
              $scope.data.site_list[indx].std_ol_yn = check ? 1 : 0;
            });
          }
          break;
        case 'smile':
          $scope.checked.smileProd = check;
          // ESM 상품 물류 상품 으로 등록 std_ol_yn = 2
          if ($scope.data.site_list.find(site => ['A001', 'A006'].includes(site.pa_shop_cd))) {
            const siteIndexList = $scope.data.site_list.reduce((pre, curr, index) => ['A001', 'A006'].includes(curr.pa_shop_cd) ? [...pre, index] : pre, []);
            siteIndexList.forEach(indx => {
              $scope.data.site_list[indx].std_ol_yn = check ? 2 : 1;
            });
          }
          break;
      }

      // std_ol_yn이 변경되어서 추가항목 기본값 세팅 다시 불러와야해
      await $scope.shopSetInfoMatch(); // 쇼핑몰별 추가항목 기본값 자동세팅
      $scope.categoryOptionsFlag = check;
      checkTabDisabled();
      // 탭 비활성화가 바로 적용되지 않아 추가
      $timeout();
    };

    // ESM 마스터 상품 selectbox 선택
    // flag: boolean 마스터 상품 등록 여부
    $scope.changeEsmMaster = (flag, index, shop_cd, shop_id) => {
      if (flag) {
        const matchingShop = angular.copy($scope.canEsmMasterList.find(shop => shop.shop_cd === shop_cd && shop.shop_id === shop_id));

        if (matchingShop) {
          delete matchingShop.checked;
          if (shop_cd === 'A006') {
            $scope.esmMatchShops[index].gmarket = matchingShop;
            if (!matchingShop.checked) { $scope.esmMatchShops[index].auction = {}; }
          } else {
            $scope.esmMatchShops[index].auction = matchingShop;
          }
        } else {
          if (shop_cd === 'A006') {
            $scope.esmMatchShops[index] = {
              gmarket: {},
              auction: {}
            };
          } else {
            if ($scope.esmMatchShops[index]) {
              $scope.esmMatchShops[index].auction = {};
            }
          }

          // ESM 마스터 조합 수가 리스트 보다 적을 때 선택 배열에서 제거해줌
          if ($scope.maxEsmCnt < $scope.esmMatchShops.length) {
            if (Object.values($scope.esmMatchShops[index]).every(shop => _.isEmpty(shop))) {
              $scope.esmMatchShops.splice(index, 1);
            }
          }
        }

        if (!index && !shop_cd) {
          $scope.esmMatchShops = [{
            gmarket: {},
            auction: {}
          }];
        }

        makeEsmMatchChecked();
      } else {
        $scope.esmMatchShops = [];

        $scope.data.site_list.forEach(shop => {
          if (['A001', 'A006'].includes(shop.shop_cd)) {
            shop.esmMatchId = '';
          }
        });
        makeEsmMasterInfo();
      }

      $scope.esmIdCheck = checkedEsmTabDisabled();
    };

    /**
     * ESM 마스터 상품 선택 시 체크 여부 수정
     */
    function makeEsmMatchChecked() {
      $scope.canEsmMasterList.forEach(shop => {
        shop.checked = false;
        $scope.esmMatchShops.forEach(match => {
          Object.values(match).forEach(m => {
            if (shop.shop_cd === m.shop_cd && shop.shop_id === m.shop_id && shop.etc7 === m.etc7) {
              shop.checked = true;
            }
          });
        });
      });
    }

    /**
   * 템플릿 설정 닫기
   */
    $scope.templateInit = function () {
    //템플릿 데이터 초기화
      $scope.templateList = '';
      $scope.templateList2 = '';
      $scope.templateListModal = false;

      // tooltip
      $('[data-popup="tooltip"]').tooltip();
    };

    // 일반옵션 사용 여부
    $scope.defaultOpVisible = function(checkedEsmVal, shop) {

      // scope 바인딩이 바로바로 되지않아 선택값 넘겨받도록 처리 2018-06-07 rony
      if (!_.isUndefined(checkedEsmVal)) {
        $scope.checked[shop] = checkedEsmVal;
      }

      let isShow = false;
      const use_tab_shop = [];
      const shop_list = _.map($scope.data.site_list, 'shop_cd');

      $scope.opTabActive = 0;
      if ($scope.disabled.CPANG) {
        angular.forEach(shop_list, function(row) {
          if (row == 'B378') {
            use_tab_shop.push('B378');
          }
        });
        $scope.opTabActive = 3;
      }
      if ($scope.disabled.ROCKET) {
        angular.forEach(shop_list, function(row) {
          if (row == 'B378') {
            use_tab_shop.push('B378');
          }
        });
        $scope.opTabActive = 10;
      }
      if ($scope.disabled.NAVER) {
        angular.forEach(shop_list, function(row) {
          if (row == 'A077') {
            use_tab_shop.push('A077');
          }
        });
        $scope.opTabActive = 7;
      }
      if ($scope.checked.GALLERIA) {
        shop_list.forEach(row => {
          if (row === 'B394') {
            use_tab_shop.push('B394');
          }
        });
        $scope.opTabActive = 6;
      }
      if ($scope.checked.WMP) {
        angular.forEach(shop_list, function (row) {
          if (row == 'B719') {
            use_tab_shop.push('B719');
          }
        });
        $scope.opTabActive = 4;
      }
      if ($scope.checked.HPLUS) {
        shop_list.forEach(row => {
          if (row === 'B502') {
            use_tab_shop.push('B502');
          }
        });
        $scope.opTabActive = 5;
      }
      if ($scope.checked.A112) {
        angular.forEach(shop_list, function(row) {
          if (row == 'A112') {
            use_tab_shop.push('A112');
          }
        });
        $scope.opTabActive = 2;
      }
      if ($scope.checked.ESM) {
        angular.forEach(shop_list, function(row) {
          if (row == 'A001') {
            use_tab_shop.push('A001');
          }
        });
        angular.forEach(shop_list, function(row) {
          if (row == 'A006') {
            use_tab_shop.push('A006');
          }
        });
        $scope.opTabActive = 1;
      }
      if ($scope.checked.ALIEXPRESS) {
        shop_list.forEach(row => {
          if (row === 'A093') {
            use_tab_shop.push('A093');
          }
          $scope.opTabActive = 8;
        });
      }
      if ($scope.checked.ALIBABA) {
        shop_list.forEach(row => {
          if (row === 'A092') {
            use_tab_shop.push('A092');
          }
        });
        $scope.opTabActive = 9;
      }
      if ($scope.checked.A077single) {
        shop_list.forEach(row => {
          if (row === 'A077') {
            use_tab_shop.push('A077');
          }
        });
        $scope.opTabActive = 11;
      }
      if ($scope.checked.ALIEXPRESSGLOBAL) {
        shop_list.forEach(row => {
          if (row === 'X094') {
            use_tab_shop.push('X094');
          }
          $scope.opTabActive = 12;
        });
      }
      if ($scope.checked.TEMU) {
        shop_list.forEach(row => {
          if (row === 'A098') {
            use_tab_shop.push('A098');
          }
          $scope.opTabActive = 13;
        });
      }
      isShow = _.isEqual(use_tab_shop.sort(), shop_list.sort());
      $scope.data.defaultOpTab = !isShow;
      $scope.changeOptTab($scope.opTabActive);
    };

    // 카테고리명 검사
    $scope.validationCategory = function(cate) {
      const flag = $scope.categoryList.all.filter(row => row.name === cate);

      if (flag.length) {
        return true;
      } else {
        $('.reg-channel-select').val([]);
        $scope.data.site_list = [];

        return false;
      }
    };

    /**
     * 저장된 카테고리 설정 불러오기
     */
    $scope.infoLoad = async (loadType) => {
      try {
        if ($scope.data.site_list.length && loadType !== 'tempData') {
          const confirm = await commonSVC.showConfirm('불러오기 안내', `화면에 설정되어 있는 정보는 모두 초기화 되고 불러오는 정보로 설정됩니다.\n[${loadType === 'cateTemp' ? '카테고리 템플릿' : '마스터상품'} 불러오기]를 계속 진행하시겠습니까?`);

          if (!confirm) {
            return;
          }
        }

        $scope.modalLoadYn = true;

        // 카테고리 템플릿 불러오기 모달
        if (loadType === 'cateTemp') {
          const { data: cateList } = await categoryModel.categorySelectList();

          const { id, name, message } = await commonSVC.openModal('xg', { data: { cateList } }, 'LoadCateTemplateCtrl', 'views/online/product/modals/load_category_template.html').result;

          if (message === 'cancel') { return; }

          $scope.data.site_list = [];
          $scope.categoryCode = id;
          $scope.categoryName = name;
          $scope.checkEsmMaster = {
            disabled: false,
            checked: false
          };
          $scope.canEsmMasterList = [];
          $scope.esmMatchShops = [{
            gmarket: {},
            auction: {}
          }];

          $scope.shopList = (await categoryModel.cateShopIdSelectList({ sol_cate_no: $scope.categoryCode })).data;
        }
        //마스터 상품 불러오기 모달
        else if (loadType === 'masterProd') {
          const { data: prodList } = await onlineProductModel.masterProdList();

          $scope.shopList = await commonSVC.openModal('xg', { data: { prodList } }, 'LoadMasterProdCtrl', 'views/online/product/modals/load_master_prod.html').result;

          if (!$scope.shopList?.length || $scope.shopList === 'cancel') { return; }

          $scope.data.site_list = [];
          $scope.categoryCode = $scope.shopList[0].sol_cate_no;
          $scope.categoryName = $scope.shopList[0].pa_sol_cate_no === 10000 ? $scope.shopList[0].sol_cate_name : `${$scope.shopList[0].parentsName || '(미분류)'} : ${$scope.shopList[0].sol_cate_name}`;

          $scope.checkEsmMaster = {
            disabled: false,
            checked: false
          };
          $scope.canEsmMasterList = [];
          $scope.esmMatchShops = [{
            gmarket: {},
            auction: {}
          }];
        }

        // 데이터 초기화
        $scope.updateCateData = {};
        $scope.originData = {};
        $scope.applyList = {};
        $scope.isOpen = false;
        $scope.matchingData = {};

        makeEsmMasterInfo();
        makeEsmMatchChecked();

      } catch (err) {
        err && commonSVC.showToaster('error', '실패', err?.message || '카테고리 불러오기에 실패했습니다.');
      } finally {
        $scope.modalLoadYn = false;
      }

      $scope.type = $scope.categoryCode ? loadType : 'new';

      const pushSiteIdx = [];

      if ($scope.shopList.length) {
        $scope.shopList = $scope.shopList.filter(s => $scope.use_engines.some(shop => shop.shop_cd === s.shop_cd && shop.shop_id === s.shop_id));
        $scope.use_shops = $scope.shopList;
        $scope.data.sol_cate_no = $scope.categoryCode;

        $scope.categoryListModal = false;
        $scope.overlapResult = null;

        //매칭된 카테고리 셀렉박스에 추가
        $scope.use_shops.forEach((v) => {
          pushSiteIdx.push($scope.use_engines.findIndex((o) => {
            return o.shop_id === v.shop_id && o.shop_cd === v.shop_cd;
          }));
        });

        //쇼핑몰 계정별 카테고리 자동매칭
        $('.reg-channel-select').val(pushSiteIdx).trigger('change');
        $scope.categoryOptionsFlag = true;
      } else { // 매칭된 카테고리가 없는경우 쇼핑몰 계정 리스트 초기화
        $('.reg-channel-select').val([]).trigger('change');
        $scope.flag.categorySearch = false;
      }

      $scope.shopList.forEach(shop => {
        if (shop.shop_cd === 'Z000') {
          return true;
        }

        const idx = $scope.data.site_list.findIndex(s => s.shop_cd === shop.shop_cd && s.shop_id === shop.shop_id);

        if (shop.shop_cate_no_add_info && typeof shop.shop_cate_no_add_info === 'string') {
          shop.shop_cate_no_add_info = JSON.parse(shop.shop_cate_no_add_info);
        }
        if (shop.sol_cate_shop_add_info && typeof shop.sol_cate_shop_add_info === 'string') {
          shop.sol_cate_shop_add_info = JSON.parse(shop.sol_cate_shop_add_info);
        }

        const cate_add_info = shop.shop_cate_no_add_info && Object.keys(shop.shop_cate_no_add_info).length ? shop.shop_cate_no_add_info : shop.sol_cate_shop_add_info || {};
        shop.shop_name = siteInfo[shop.shop_cd].name;

        // // shop_cate_no 제대로 있을때만 새로운 카테고리 정보 세팅해줌 2018-10-16 Amelia
        if (['A001', 'A006'].includes(shop.pa_shop_cd)) {
          $scope.data.site_list[idx].esm_match_cate_name = cate_add_info.catename_esm;
          $scope.data.site_list[idx].esm_match_cate_codes = cate_add_info.catecode_esm;
        } else if (shop.pa_shop_cd === 'A112') {
          $scope.data.site_list[idx].key_selPrdTypCd = cate_add_info.key_selPrdTypCd;
        }

        const cate_code = cate_add_info.category_code || cate_add_info.category_codes || shop.shop_cate_cd;

        $scope.data.site_list[idx].pin_yn = shop.pin_yn;
        $scope.data.site_list[idx].sol_cate_no = shop.sol_cate_no;
        $scope.data.site_list[idx].match_cate_code = cate_code;
        $scope.data.site_list[idx].match_cate_name = cate_add_info.category_names;

        if ($scope.data.site_list[idx].std_ol_yn || $scope.data.site_list[idx].shop_cd === 'B378') {
          $scope.data.site_list[idx].shop_cate_no = shop.shop_cate_no || 0;
          $scope.data.site_list[idx].shop_cate_no_add_info = JSON.stringify(cate_add_info);
        }

        $scope.data.coupang_cate = cate_add_info.category_code;

        if (cate_add_info && Object.keys(cate_add_info).length) {
          // sol_cate_shop_add_info에 카테고리명이 지정되지 않았을 떄
          if (!cate_add_info.category_names) {
            const noneCateCd = ['B719', 'B579', 'B394', 'B700'].includes(shop.pa_shop_cd);
            const shop_names = [shop.shop_cate1, shop.shop_cate2, shop.shop_cate3, shop.shop_cate4, shop.shop_cate5, shop.shop_cate6, shop.shop_cate7, shop.shop_cate8].filter((name, idx) => name && (noneCateCd || shop[`shop_cate${idx + 1}_cd`]));

            cate_add_info.category_names = shop_names.join(' > ');
          }

          const val = {
            category_names: cate_add_info.category_names,
            category_code: cate_code,
            detailData: { ...{ category_code: cate_code }, ...cate_add_info },
            isOrigin: true,
            pa_shop_cd: shop.pa_shop_cd
          };

          // 스마트스토어 단일(그룹) 상품 지원 카테고리 여부 확인
          if (shop.pa_shop_cd === 'A077') {
            val.singleCateYn = shop.singleCateYn ? shop.singleCateYn === 'true' : true;
          }

          $scope.updateCateData[shop.shop_cd][shop.shop_id] = val;
          $scope.originData[shop.shop_cd][shop.shop_id] = val;
        }
      });

      $scope.resetCateSearch();
      $scope.search_yn = false;

      checkTabDisabled();

      $scope.matchingInfo = $scope.shopList.filter((cate) => $scope.data.site_list.some((site) => cate.shop_cd === site.shop_cd && cate.shop_id === site.shop_id));
      $scope.simCateList = Object.assign(originSimCateList);

      const length = $scope.matchingInfo.length;

      const tickUpdate = () => {
        if ($scope.tick < length) {
          $scope.tick += 10;
          // requestAnimationFrame 60 프레임 보장시켜 애니메이션 화 시킴.
          requestAnimationFrame(tickUpdate);
        } else {
          $scope.loading = false;
        }

        $scope.$digest();
      };

      requestAnimationFrame(tickUpdate);
    };

    /**
     * 카테고리 검색
     */
    $scope.findCategory = async () => {
      if (!$scope.search.word || !$scope.search.word.replace(/[^a-zA-Z가-힣0-9]/g, '')) {
        commonSVC.showMessage('실패', '카테고리 검색어를 입력해주세요.');

        return false;
      }

      try {
        const res = await categoryModel.findCategory({ searchWord: $scope.search.word });

        await $timeout(() => {
          $scope.search_yn = true;
          $scope.placeholder = '카테고리 검색이 지원되지 않는 쇼핑몰입니다. 우측의 화살표를 클릭하여 카테고리를 설정해 보세요.';
          $scope.simCateList = res.data.result;

          originSimCateList = Object.assign(res.data.result);

          // 중복 키워드 확인
          if (!searchWord.includes($scope.search.word)) {
            searchWord.push($scope.search.word);
          }

          for (const { pa_shop_cd, shop_cd, shop_id } of $scope.shopList) {
            if (shop_cd !== 'Z000' &&
            (!$scope.updateCateData[shop_cd][shop_id] || !$scope.updateCateData[shop_cd][shop_id].isOrigin) &&
            $scope.simCateList[pa_shop_cd]?.length) {
              $scope.updateCateData[shop_cd][shop_id] = $scope.originData[shop_cd][shop_id] || $scope.simCateList[pa_shop_cd][0] || '';
              $scope.tabDisabled = true; // 개별 카테고리 수정하면 저장하기 전까지 탭 이동 불가
            }
          }

          // 열려있을 때 변경 이벤트 처리
          if (typeof openIdx === 'number' && $scope.isOpen) {
            const { shop_cd, shop_id } = $scope.matchingInfo[openIdx];

            if (!$scope.updateCateData[shop_cd][shop_id]) {
              return false;
            }

            if (!$scope.updateCateData[shop_cd][shop_id].isOrigin && !$scope.updateCateData[shop_cd][shop_id].isApply) {
              $scope.changeUpdate(openIdx, 'category_selection', { shop_cd, shop_id });
            }
          }

          setSaveCnt();
        });
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.message || err.data.error);
      }
    };

    /**
     *
     */
    $scope.changeUpdate = async (index, type, shop) => {
      if ($(`#test_${index}`).css('display') !== 'none') {
        if ($scope.updateCateData[shop.shop_cd][shop.shop_id].isApply || $scope.updateCateData[shop.shop_cd][shop.shop_id].isOrigin) {
          $scope.$broadcast(type, { ...$scope.updateCateData[shop.shop_cd][shop.shop_id], site_code: shop.shop_cd, site_id: shop.shop_id });
        } else {
          const detailData = await categoryModel.getDetailData({ shop_cd: shop.pa_shop_cd, cateData: $scope.updateCateData[shop.shop_cd][shop.shop_id] });

          $scope.$broadcast(type, { ...detailData.data.result, site_code: shop.shop_cd, site_id: shop.shop_id });
        }
      }

      setSaveCnt();
    };

    /**
     * 쇼핑몰별 카테고리 매칭 설정
     **/
    $scope.infoOpen = async (index, data) => {
      //리스트 선택시 매칭정보상세 초기화
      $('.shopListShow').html('');
      $('.shopListShow').hide();

      //열고 닫기
      if ($(`#test_${index}`).css('display') == 'none') {
        $scope.isOpen = true;

        //열기 일 경우 쇼핑몰 매칭정보 html 새로 그리기
        const url = angular.element(`<div data-ng-include='"views/settings/category/shopDetail/${data.pa_shop_cd}.html"' id='matchingInfo_${index}' class='row blue-bg border-bottom border-bottom-grey-100 p-20 shopListShow'></div>`);

        angular.element(`#test_${index}`).html(url);
        $compile(url)($scope);
        $scope.matchingId = data.shop_id;

        $scope.matchingData = {
          categoryId: $scope.categoryCode,
          site_code: data.shop_cd,
          pa_shop_code: data.pa_shop_cd,
          site_id: data.shop_id,
          categoryNames: _.get(data, 'sol_cate_shop_add_info.category_names') || '매칭정보 없음',
          pin_yn: data.pin_yn,
          isOrigin: !!$scope.updateCateData[data.shop_cd][data.shop_id].isOrigin,
          isOnline: true // 온라인 상품내 카테고리 매칭인지 확인
        };

        if ($scope.updateCateData[data.shop_cd][data.shop_id] && ($scope.updateCateData[data.shop_cd][data.shop_id].isOrigin || $scope.updateCateData[data.shop_cd][data.shop_id].isApply)) {
          $scope.matchingData.sol_cate_shop_add_info = { ...$scope.updateCateData[data.shop_cd][data.shop_id].detailData };
        } else if ($scope.updateCateData[data.shop_cd][data.shop_id]) {
          const detailData = await categoryModel.getDetailData({ shop_cd: data.pa_shop_cd, cateData: $scope.updateCateData[data.shop_cd][data.shop_id] });

          $scope.matchingData.sol_cate_shop_add_info = detailData.data.result.detailData || null;
        } else {
          $scope.matchingData.sol_cate_shop_add_info = null;
        }

        //열기
        openIdx = index;
        $(`#test_${index}`).show();
      } else if ($(`#test_${index}`).css('display') == 'block') {
        $scope.isOpen = false;
        //닫기
        $(`#test_${index}`).hide();
      }

      $scope.shop_cd = data.shop_cd;
      $scope.shop_id = data.shop_id;
    };

    /**
     * 카테고리 검색 초기화
     */
    $scope.resetCateSearch = async (init) => {
      await $timeout(() => {
        for (const shop in $scope.updateCateData) {
          for (const shop_id in $scope.updateCateData[shop]) {
            $scope.updateCateData[shop][shop_id] = $scope.originData[shop][shop_id];
          }
        }

        $scope.simCateList = init ? [] : Object.assign(originSimCateList);
        $scope.placeholder = '상단의 검색창에서 카테고리명을 검색해 보세요.';

        if (init) {
          $scope.search.word = '';
          $scope.search_yn = false;
        }

        setSaveCnt();
      });
    };

    /**
     * 일괄저장 카운팅
     */
    const setSaveCnt = () => {
      $scope.saveCnt = 0;

      for (const shop_cd in $scope.updateCateData) {
        for (const shop_id in $scope.updateCateData[shop_cd]) {
          // 필터링 되어 있는 경우 필터링 된 데이터만 카운트함.
          const data = $scope.updateCateData[shop_cd][shop_id];

          if (data) {
            // 뷰와 같은 함수를 써서 카운트를 맞춤
            if (['border-success', 'border-blue-600'].includes($scope.getSelectionCateClass(data))) {
              $scope.saveCnt++;
            }
          }
        }
      }
    };

    /**
     * 카테고리 설정 고정
     */
    $scope.setPin = async (cate) => {
      // 고정 변경인 경우 업데이트 처리
      try {
        // 현재 고정상태일때 확인처리 추가
        if (cate.pin_yn) {
          // 매칭된 상품이 있는 지 확인
          const res = await categoryModel.findOlExsits({ shop_cd: cate.shop_cd, shop_id: cate.shop_id, sol_cate_no: cate.sol_cate_no });

          if (res.data.result) {
            const confirm = await commonSVC.showConfirmCustom({
              title: '카테고리 정보 고정을 해제하시겠습니까?',
              text: `<p class="no-padding help-text">- 해당 카테고리 정보는 온라인에서 판매중인 상품에 적용된 카테고리 정보입니다.</p>
                <p class="text-danger no-padding help-text"> - 해당 카테고리 정보를 변경하면 온라인에서 판매중인 상품의 카테고리 정보도 변경됩니다.</p>
                <p class="no-padding help-text">- 온라인에서 판매중인 상품의 카테고리 정보를 변경을 원하지 않는다면 고정을 유지하길 권장합니다.</p>`,
              confirmButtonText: '고정 해제',
              cancelButtonText: '닫기',
              html: true,
            });

            if (!confirm) {
              return false;
            }
          }
        } else {
          // 고정하고 변경 사항이 있을 대 확인 처리 추가
          if (!$scope.updateCateData[cate.shop_cd][cate.shop_id].isOrigin) {
            const confirm = await commonSVC.showConfirmCustom({
              title: '확인',
              text: '일괄저장하지 않고 고정 시 이전에 매칭된 카테고리 정보로 고정됩니다.',
              confirmButtonText: '고정',
              cancelButtonText: '닫기',
              html: true
            });

            if (!confirm) {
              return true;
            }

            $scope.updateCateData[cate.shop_cd][cate.shop_id] = $scope.originData[cate.shop_cd][cate.shop_id];
          }
        }

        await categoryModel.categoryPinEdit({ sol_cate_no: cate.sol_cate_no, pin_yn: !cate.pin_yn, shop_cd: cate.shop_cd, shop_id: cate.shop_id });

        await $timeout(() => {
          cate.pin_yn = !cate.pin_yn;
          setSaveCnt();
        });

        commonSVC.showToaster('success', '성공', `카테고리 고정 ${cate.pin_yn ? '' : '해제'}`);
      } catch (err) {
        commonSVC.showToaster('error', '실패', `카테고리 고정 ${cate.pin_yn ? '' : '해제'}`);
      }

      if ($scope.matchingData.categoryId === cate.sol_cate_no && $scope.matchingData.site_code === cate.shop_cd && $scope.matchingData.site_id === cate.shop_id) {
        $scope.matchingData.pin_yn = cate.pin_yn;
      }
    };

    /**
     * 쇼핑몰별 매칭정보 초기화
     **/
    $scope.shopInfoDelete = function() {
      commonSVC.showConfirm('주의', '카테고리 정보를 초기화 하시겠습니까?')
        .then(function (confirm) {
          if (confirm) {
            const { site_code, site_id } = $scope.matchingData;

            if ($scope.originData[site_code][site_id]) {
              categoryModel.shopInfoDetailDelete($scope.matchingData)
                .then(function () {
                  commonSVC.showToaster('success', '성공', '카테고리 정보 초기화에 성공하였습니다.');
                  $scope.updateCateData[site_code][site_id] = '';
                  $scope.originData[site_code][site_id] = '';
                  $scope.applyList[site_code][site_id] = '';
                })
                .catch(function (err) {
                  commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
                });
            } else {
              $scope.updateCateData[site_code][site_id] = '';
              $scope.applyList[site_code][site_id] = '';
            }

            const row = $scope.use_engines.find(shop => shop.shop_cd === site_code && shop.shop_id === site_id);

            if (row) {
              const index = $scope.data.site_list.findIndex(s => s.shop_cd === site_code && s.shop_id === site_id);
              const paShopCd = row.pa_shop_cd;

              // 홍보문구 규칙 적용
              // 이미지 규칙 적용
              const shop_promotion_name_role = onlineProductSVC.promotionNameRole(paShopCd);
              const site_sub_img_objects = angular.copy(onlineProductSVC.sub_img_objects[paShopCd]);
              const site_sub_img_box = onlineProductSVC.sub_img_box[paShopCd] ? onlineProductSVC.sub_img_box[paShopCd](row) : {};

              // 쇼핑몰 정보 생성
              const site_info = onlineProductSVC.new_shop(index, row, site_sub_img_objects, site_sub_img_box, shop_promotion_name_role);

              $scope.data.site_list[index] = site_info;
            }

            // 카운팅
            setSaveCnt();
            checkTabDisabled();

            // 닫기
            $scope.infoOpen(openIdx, $scope.matchingInfo[openIdx]);
          }
        });
    };

    /**
     * 카테고리 인풋 클래스 설정
     */
    $scope.getSelectionCateClass = (data) => {
      let className = '';

      // 옥션, 지마켓 ESM 카테고리 설정되지 않은 경우
      if (['A001', 'A006'].includes(data?.pa_shop_cd) && !(data?.detailData?.catecode_esm || data?.esm_category_code)) {
        className = 'border-danger';
      } else if (data?.pa_shop_cd === 'A077' && $scope.checked.A077single && (!data.singleCateYn || data.singleCateYn === 'false')) {
        className = 'border-danger';
      } else if (data?.detailData?.isProdScrap && data.isOrigin) { // 수집시 매칭된 카테고리인 경우
        if (data.detailData.isPlayautoCate) { // 표준카테고리 매칭
          className = 'border-blue-600';
        } else { // 유사카테고리 매칭
          className = 'border-success';
        }
      } else if (!data || data.isOrigin) { // 기존 데이터의 경우 디폴트
        className = 'border-default';
      } else if (!data.isApply && (!data.end_yn || checkAdditionalInfo[data.pa_shop_cd || data.shop_cd])) {
        className = 'border-danger';
      } else {
        className = 'border-success';
      }

      return className;
    };

    /**
     * 카테고리 적용
     */
    $scope.applyCate = async (param) => {
      // 동일 쇼핑몰 적용처리.
      if (param.set_all_shop) {
        for (const cate of $scope.matchingInfo.filter(cate => cate.pa_shop_cd === $scope.matchingData.pa_shop_code)) {
          $scope.updateCateData[cate.shop_cd][cate.shop_id] = param;
          $scope.applyList[cate.shop_cd][cate.shop_id][0] = param;
        }

      } else {
        $scope.updateCateData[param.site_code][param.site_id] = param;

        if (!$scope.applyList[param.site_code]) { $scope.applyList[param.site_code] = {}; }
        if (!$scope.applyList[param.site_code][param.site_id]) { $scope.applyList[param.site_code][param.site_id] = []; }

        $scope.applyList[param.site_code][param.site_id][0] = param;
      }

      // ESM 카테고리 변경시 옵션초기화
      if (param.isChange) {
        commonSVC.showToaster('success', '카테고리 변경 성공', '카테고리 변경으로 ESM 옵션이 초기화되었습니다.');

        // 여러 계정 등록시 하나라도 직접입력 옵션 불가인 카테고리가 있는경우 직접입력 불가로 처리되어야 하기 떄문에 사이트 루프처리 추가.
        if (['A001', 'A006'].includes(param.site_code)) {
          const esmDirectOptionDisableCategorys = await categoryModel.getEsmDirectOptionDisableCate();
          const originEsmDirectOptionEnable = $scope.data.esmDirectOptionEnable;
          const esmShops = {};

          let esmDirectOptionEnable = true;

          const site = commonSVC.getShopList($scope.data.site_list, ['A001', 'A006']);

          site.forEach(({ shop_cd, match_cate_code, selected }) => {
            if (selected && match_cate_code) {
              const categoryCode = shop_cd === 'A001' ? match_cate_code.replace(/_/g, '').padEnd(8, '0') : _(match_cate_code).split('_').last();

              if (!esmShops[shop_cd].includes(categoryCode)) {
                esmShops[shop_cd].push(categoryCode);
              }
            }
          });

          const res = await categoryModel.getScrapCategoryOption({ shopList: esmShops });

          $scope.data.site_list.map(shop => {
            const checkCategoryCode = param.site_code === shop.shop_cd && param.site_id === shop.shop_id ? param.category_code : shop.match_cate_code;

            if (esmDirectOptionEnable) {
              esmDirectOptionEnable = (!(new Set(esmDirectOptionDisableCategorys.data.result)).has(checkCategoryCode.replace(/_/g, '').padEnd(8, '0')))
              || res.data.result.some(cate => cate.shop_cd === $scope.data.pa_shop_cd && cate.etc.options.find(opt => opt.ObjOptNo === 0));
            }
          });

          if (originEsmDirectOptionEnable !== esmDirectOptionEnable) {
            $scope.data.esmDirectOptionEnable = esmDirectOptionEnable;
            $scope.prodOpt.esm_opt = angular.copy(onlineProductSVC.default_opt);
          }
        }
      }

      setSaveCnt();
      checkTabDisabled();

      $timeout(() => {
        $scope.tabDisabled = true; // 개별 카테고리 수정하면 저장하기 전까지 탭 이동 불가
      });

      $scope.shopCateTmpSave(param.set_all_shop ? param.site_code : null);
    };

    /**
     * 쇼핑몰 카테고리 임시저장
     */
    $scope.shopCateTmpSave = async (site_code) => {
      let pa_shop_cd, shop_cd, shop_id;
      const oriIdx = openIdx === null ? 0 : openIdx;

      do {
        if (openIdx === null) {
          openIdx = 0;
        } else {
          openIdx += 1;
        }

        if ($scope.matchingInfo[openIdx]) {
          shop_cd = $scope.matchingInfo[openIdx].shop_cd;
          shop_id = $scope.matchingInfo[openIdx].shop_id;
          pa_shop_cd = $scope.matchingInfo[openIdx].pa_shop_cd;
        }
      }
      while ($scope.matchingInfo[openIdx] && shop_cd !== 'Z000' &&
        ($scope.matchingInfo[openIdx].pin_yn // 고정돼 있거나
        || (site_code ? $scope.matchingInfo[openIdx].shop_cd === site_code : false) // 동일 쇼핑몰 적용이거나
        || ($scope.updateCateData[shop_cd][shop_id].end_yn && !checkAdditionalInfo[pa_shop_cd]) // 추가적용이 필요 없거나
        || $scope.updateCateData[shop_cd][shop_id].isOrigin // 변경사항이 없어서 설정이 필요 없는 경우
        || $scope.updateCateData[shop_cd][shop_id].isApply
        )); // 변경사항이 있는경우

      // 다음 카테고리가 없을 경우 현재 카테고리 창 닫음
      $timeout(() => {
        $scope.infoOpen(oriIdx, $scope.matchingInfo[oriIdx]);
      });
    };

    /**
     * 카테고리 템플릿 저장
     */
    // 일괄저장
    $scope.saveCateTemplate = async () => {

      try {
        const updateList = [];

        for (const shopIdx in $scope.matchingInfo) {
          const shop = $scope.matchingInfo[shopIdx];
          const data = $scope.updateCateData[shop.shop_cd] ? $scope.updateCateData[shop.shop_cd][shop.shop_id] : null;

          if ((!data
            || data.isOrigin // 원래 데이터
            || (!data.isApply && (!data.end_yn || checkAdditionalInfo[shop.pa_shop_cd])) // 원래데이터가 아니고 마지막 카테고리가 아니거나 추가 정보를 입력해야하는 경우
          ) && !(data?.isOrigin && data.detailData?.isProdScrap)) { // 상품수집시 저장된 카테 정보 수정여부
            continue;
          }

          data.index = shopIdx;

          let param = {};

          // 상세에서 카테고리 선택 적용한 경우
          if (data.isApply) {
            param = { ...data, pa_shop_cd: shop.pa_shop_cd, shop_cd: shop.shop_cd, shop_id: shop.shop_id, categoryId: $scope.categoryCode };
          } else {
            // 적용하지 않고 바로 저장하는 경우 (유사 카테고리 검색)
            const detailData = await categoryModel.getDetailData({ shop_cd: shop.pa_shop_cd, cateData: data });
            param = {
              pa_shop_cd: shop.pa_shop_cd,
              shop_cd: shop.shop_cd,
              shop_id: shop.shop_id,
              category_codes: 'test',
              category_names: data.category_names,
              categoryId: $scope.categoryCode,
              set_all_shop: false,
              country: 'kr',
              type: 0
            };
            Object.assign(param, detailData.data.result);
          }

          if (['A001', 'A006'].includes(param.shop_cd)) {

            param.cateData = data.detailData;
          }

          updateList.push(param);
        }

        if (!updateList.length) {
          commonSVC.showMessage('실패', '일괄 저장 가능한 쇼핑몰이 없습니다.');

          return;
        }

        //ESM 등록시 매칭된 카테고리로 독립형, 조합형 옵션 생성
        if ($scope.checked.ESM || $scope.checked.A112 || $scope.checked.ALIEXPRESS || $scope.checked.ALIBABA || $scope.checked.A077single || $scope.checked.ALIEXPRESSGLOBAL || $scope.checked.TEMU) {
          $scope.categoryOptionsFlag = true;
        }
        let confirm = false;

        if ($scope.type === 'new') {
          const parentList = (await categoryModel.categoryListTree({ search_keyword: '' })).data.results;
          const data = {
            list: parentList,
            cateData: {
              categoryData: updateList,
              searchWord,
              timeout: (2 * 60 * 1000) + parseInt(updateList.length / 100) * 10
            }
          };

          const modal = await commonSVC.openModal('lg', { data }, 'CategoryAddCtrl', 'views/settings/category/modals/categoryAdd.html').result;

          if (modal.state === 'success') {
            $scope.categoryCode = $scope.data.sol_cate_no = modal.code;
            $scope.categoryName = `${modal.parents_title} : ${modal.title}`;
            $scope.type = 'cateTemp';

            updateList.forEach(shop => {
              const val = {
                category_names: shop.category_names,
                category_code: shop.category_code,
                detailData: shop.detailData,
                isOrigin: true,
                pa_shop_cd: shop.pa_shop_cd
              };

              // 스마트스토어 단일(그룹) 상품 지원 카테고리 여부 확인
              if (shop.pa_shop_cd === 'A077') {
                val.singleCateYn = shop.singleCateYn;
              }

              $scope.originData[shop.shop_cd][shop.shop_id] = val;
              $scope.updateCateData[shop.shop_cd][shop.shop_id] = val;
            });

            commonSVC.showToaster('success', '성공', '카테고리 템플릿 저장이 완료되었습니다.');
          }
        } else {
          const msg = `<div>
          아니오 선택 시,<br />
          해당 상품에만 변경한 정보를 반영하고 솔루션 카테고리에는 저장하지 않습니다.<br /><br />
          예 선택 시,<br />
          해당 상품 및 솔루션 카테고리와 <br />
          해당 카테고리가 연동된 쇼핑몰 상품의 카테고리를 일괄 수정합니다.<br />
          (추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 [상세] ><br />
          개별 카테고리가 지정된 상품은 제외,<br />
          저장되어 있는 옵션은 그대로 유지됩니다.)<br /><br />
          *쇼핑몰에 이미 등록된 상품은 수정대기 -> 상품전송 시<br />
          카테고리는 수정하지 않습니다.
          </div>`;

          confirm = await commonSVC.showConfirmHtml('설정하신 정보를 카테고리 템플릿으로 저장하시겠습니까?', msg, '', { confirmButtonText: '예', cancelButtonText: '아니오' });

          if (confirm) {
            $scope.loading = true;

            const timeout = (2 * 60 * 1000) + parseInt(updateList.length / 100) * 10; // 100개 이후 100개 마다 10초씩 추가
            const res = await categoryModel.shopInfoDetailEditAll({ categoryData: updateList, searchWord, timeout });

            // 검색어 초기화
            searchWord = [];

            $scope.applyList = { ...$scope.applyList };

            updateList.forEach(shop => {
              const val = {
                category_names: shop.category_names,
                category_code: shop.category_code,
                detailData: shop.detailData,
                isOrigin: true,
                pa_shop_cd: shop.pa_shop_cd
              };

              // 스마트스토어 단일(그룹) 상품 지원 카테고리 여부 확인
              if (shop.pa_shop_cd === 'A077') {
                val.singleCateYn = shop.singleCateYn;
              }

              $scope.originData[shop.shop_cd][shop.shop_id] = val;
              $scope.updateCateData[shop.shop_cd][shop.shop_id] = val;
            });
            commonSVC.showToaster('success', '성공', `카테고리 수정 ${res.data.result.success}건 성공 / ${res.data.result.fail}건 실패`);
          } else {
            updateList.forEach(shop => $scope.updateCateData[shop.shop_cd][shop.shop_id].detailData = shop.detailData);
          }
        }

        for (const cate of updateList) {
          // 일괄적용할 쇼핑몰 리스트
          let shop_list = [];

          if (cate.set_all_shop === true) {
            shop_list = $scope.data.site_list.filter(s => s.pa_shop_cd === cate.pa_shop_cd);
          } else {
            shop_list = $scope.data.site_list.filter(s => s.shop_cd === cate.shop_cd && s.shop_id === cate.shop_id);
          }

          for (const shop of shop_list) {
            const idx = $scope.data.site_list.findIndex(s => s.shop_cd === shop.shop_cd && s.shop_id === shop.shop_id);
            const updateListIdx = updateList.findIndex(s => s.shop_cd === shop.shop_cd && s.shop_id === shop.shop_id);

            const sData = {
              pa_shop_cd: shop.pa_shop_cd,
              shop_cd: shop.shop_cd,
              shop_id: shop.shop_id,
              sol_cate_no: $scope.data.sol_cate_no,
              cate_code: cate.category_code,
              cate_name: cate.category_names,
              shop_cate_no_add_info: $scope.data.site_list[idx].shop_cate_no_add_info
            };

            const shop_cate_no = (await categoryModel.shopCategoryAdd(sData)).data.result;

            // update한 쇼핑몰 별 추가항목 기본값 세팅을 위해 필요한값 매핑
            updateList[updateListIdx].shop_cate_no = shop_cate_no;
            updateList[updateListIdx].sol_cate_no = $scope.data.sol_cate_no;
            updateList[updateListIdx].std_ol_yn = $scope.data.site_list[idx].std_ol_yn;

            // shop_cate_no 제대로 있을때만 새로운 카테고리 정보 세팅해줌 2018-10-16 Amelia
            if (['A001', 'A006'].includes(cate.shop_cd)) {
              $scope.data.site_list[idx].esm_match_cate_name = cate.detailData.catename_esm;
              $scope.data.site_list[idx].esm_match_cate_codes = cate.detailData.catecode_esm;
            } else if (cate.shop_cd === 'A112') {
              $scope.data.site_list[idx].key_selPrdTypCd = cate.detailData.key_selPrdTypCd;
            }

            $scope.data.site_list[idx].match_cate_code = cate.category_code;
            $scope.data.site_list[idx].match_cate_name = cate.category_names;

            if ((!confirm && $scope.type !== 'new') || ($scope.data.site_list[idx].std_ol_yn || cate.shop_cd === 'B378')) {
              $scope.data.site_list[idx].shop_cate_no = shop_cate_no || 0;
              $scope.data.site_list[idx].shop_cate_no_add_info = JSON.stringify(cate.detailData);
            }

            // 변경된 카테고리 기준 ssf 쇼핑몰 인증정보 입력을 위해 카테고리 정보 변경
            if ($scope.data.site_list[idx].pa_shop_cd === 'P054') {
              $scope.data.site_list[idx].sol_cate_shop_add_info = cate.detailData;
            }

            $scope.data.coupang_cate = cate.category_code;

            if ($scope.data.site_list[idx].pa_shop_cd === 'A077') {
              $scope.checked.NAVER = true;
              naverCateLoad($scope.data.site_list.filter((row) => row.pa_shop_cd === 'A077'), true);
            }

            if ($scope.data.site_list[idx].pa_shop_cd === 'A092') {
              $scope.checked.ALIBABA = true;
            }
            if ($scope.data.site_list[idx].pa_shop_cd === 'A093') {
              $scope.checked.ALIEXPRESS = true;
            }
            if ($scope.data.site_list[idx].pa_shop_cd === 'X094') {
              $scope.checked.ALIEXPRESSGLOBAL = true;
            }
            if ($scope.data.site_list[idx].pa_shop_cd === 'A098') {
              $scope.checked.TEMU = true;
            }
          }
        }

        setSaveCnt();
        // update한 쇼핑몰 별 추가항목 기본값 세팅
        await $scope.shopSetInfoMatch(updateList);
        checkTabDisabled();
      } catch (err) {
        err !== 'cancel' && commonSVC.showToaster('error', '실패', err.message || err.data.error);
      } finally {
        $scope.loading = false;

        $timeout(() => {});
      }
    };

    $scope.categoryOptions = async (checkedEsmVal, shop) => {
      const checked = $scope.checked;
      const disabled = $scope.disabled;
      const prodOpt = $scope.prodOpt;

      // scope 바인딩이 바로바로 되지않아 선택값 넘겨받도록 처리 2018-06-07 rony
      if (!_.isUndefined(checkedEsmVal)) {
        checked[shop] = checkedEsmVal;
      }

      //일반 옵션 사용 여부
      $scope.defaultOpVisible();

      // ESM2.0, 11번가 단일 둘다 미체크시 일반옵션으로 전환(+ 일반옵션이 있을 경우)
      if (!checked.ESM && !checked.A112 && !checked.ALIEXPRESS && !checked.ALIBABA && !checked.A077single && !checked.ALIEXPRESSGLOBAL && !checked.TEMU && $scope.data.defaultOpTab) {
        $scope.opTabActive = 0;
        currentOpt = '';
      }
      // 매칭된 카테고리별 ESM 2.0, 11번가 단일상품 옵션 조합
      else {
        const addData = {
          A001: [],
          A006: [],
          A112: [],
          A092: [],
          A093: [],
          A077: [],
          X094: [],
          A098: [],
        };

        $('.optionSpinner').removeClass('hidden');
        prodOpt.esm_input_list = [];

        const site_list = $scope.data.site_list;
        let site = [];
        let hasOpt = false;
        let esmLen = 0;
        const stdLen = 0;

        if (checked.ALIBABA) {
          site = commonSVC.getShopList(site_list, ['A092']);

          site.forEach(({ shop_cd, match_cate_code, selected }) => {

            if (selected && match_cate_code) {
              const categoryCode = _(match_cate_code).split('_').last();

              if (categoryCode) {
                hasOpt = true;
              }

              if (!addData[shop_cd].includes(categoryCode)) {
                addData[shop_cd].push(categoryCode);
              }
            }
          });
        }

        if (checked.ALIEXPRESS) {
          site = commonSVC.getShopList(site_list, ['A093']);

          site.forEach(({ shop_cd, match_cate_code, selected }) => {

            if (selected && match_cate_code) {
              const categoryCode = _(match_cate_code).split('_').last();

              if (categoryCode) {
                hasOpt = true;
              }

              if (!addData[shop_cd].includes(categoryCode)) {
                addData[shop_cd].push(categoryCode);
              }
            }
          });
        }

        if (checked.A077single) {
          site = commonSVC.getShopList(site_list, ['A077', '1077', '2077', '3077', '4077', '5077', '6077', '7077', '8077', '9077']);

          site.forEach(({ pa_shop_cd, match_cate_code, selected }) => {

            if (selected && match_cate_code) {
              const categoryCode = _(match_cate_code).split('_').last();

              if (categoryCode) {
                hasOpt = true;
              }

              if (!addData[pa_shop_cd].includes(categoryCode)) {
                addData[pa_shop_cd].push(categoryCode);
              }
            }
          });
        }

        if (checked.ALIEXPRESSGLOBAL) {
          site = commonSVC.getShopList(site_list, ['X094']);

          site.forEach(({ shop_cd, match_cate_code, selected }) => {

            if (selected && match_cate_code) {
              const categoryCode = _(match_cate_code).split('_').last();

              if (categoryCode) {
                hasOpt = true;
              }

              if (!addData[shop_cd].includes(categoryCode)) {
                addData[shop_cd].push(categoryCode);
              }
            }
          });
        }

        if (checked.TEMU) {
          site = commonSVC.getShopList(site_list, ['A098']);

          site.forEach(({ shop_cd, match_cate_code, selected }) => {

            if (selected && match_cate_code) {
              const categoryCode = _(match_cate_code).split('_').last();

              if (categoryCode) {
                hasOpt = true;
              }

              if (!addData[shop_cd].includes(categoryCode)) {
                addData[shop_cd].push(categoryCode);
              }
            }
          });
        }

        if (checked.ESM) {
          stdOptLoadESM = true;
          site = commonSVC.getShopList(site_list, ['A001', 'A006']);
          esmLen = site.length;
          $scope.data.esmDirectOptionEnable = true;

          const esmDirectOptionDisableCategorys = await categoryModel.getEsmDirectOptionDisableCate();

          site.forEach(({ shop_cd, match_cate_code, selected }) => {
            //매칭된 카테고리의 옥션, 지마켓
            if (selected && match_cate_code) {
              const categoryCode = shop_cd === 'A001' ? match_cate_code.replace(/_/g, '').padEnd(8, '0') : _(match_cate_code).split('_').last();

              // 옵션 직접 등록 가능한 카테고리인지 확인.
              if ($scope.data.esmDirectOptionEnable) {
                $scope.data.esmDirectOptionEnable = !(new Set(esmDirectOptionDisableCategorys.data.result)).has(match_cate_code.replace(/_/g, '').padEnd(8, '0'));
              }

              if (categoryCode) {
                hasOpt = true;
              }

              if (!addData[shop_cd].includes(categoryCode)) {
                addData[shop_cd].push(categoryCode);
              }
            }
          });

          esmLen = addData.A001.length + addData.A006.length;

          // 입력형, 추가옵션 사용여부 재확인
          checkOptVisivled();
        }

        let res;
        let temu_option_values;

        if (hasOpt) {
          res = await categoryModel.getScrapCategoryOption({ shopList: addData });
        }
        if (checked.TEMU) {
          // 테무 옵션은 직접 호출하여 가져오는 방식
          const temu_site = commonSVC.getShopList(site_list, ['A098']);
          const category_code = temu_site[0].match_cate_code.split('_').pop();
          const { shop_cd, shop_id } = temu_site[0];
          const response = await commonModel.pa5('/channel_accounts/{uuid}/etc/get_categoty_options/sync', { code: category_code }, shop_cd, shop_id);
          const { options, option_values } = response.data?.results[0]?.data || { options: [], option_values: {} };
          $scope.data.temu_option_max = options[0].ObjOptMax;
          // OjbOptMax(옵션 최대 입력 개수)가 0이면 추천옵션값 사용, 아니면 직접입력
          $scope.disabled.temu_direct_opt = options[0].ObjOptMax !== 0;
          temu_option_values = option_values;
          res.data.result.push({
            shop_cd,
            etc: { options }
          });
        }

        try {
          // ESM 추천 옵션이 기존과 동일한 경우 갱신하지않음. 2018-04-25 rony
          // 등록 쇼핑몰이 변경되거나 EMS2.0 등록이 클릭될때마다 해당 로직이 돌기 때문에 설정한 ESM 2.0 옵션정보가 계속 초기화됨.
          // 해당 문제를 방지하기 위해 재호출되는 값이 동일한경우 초기화하지 않음.
          // ESM이나 11번가가 아니라서 res에 값이 없을 때 그냥 finally로 가도록 처리
          if (!res || angular.equals(res.data.result, checkEsmOptData)) {
            return true;
          }

          // 값 비교를 위해 초기 호출정보 담아둠.
          checkEsmOptData = angular.copy(res.data.result);

          disabled.esm_opt = false;
          disabled.std_opt = false;
          disabled.aliexpress_opt = false;
          disabled.alibaba_opt = false;
          disabled.aliexpress_global_opt = false;
          disabled.temu_opt = false;

          const items = res.data.result.flatMap(({ shop_cd, etc }) => (
            shop_cd !== 'A077' ?
              etc.options.filter(option => !Object.prototype.hasOwnProperty.call(option, 'ObjOptNo') || (option.ObjOptNo || option.ObjOptClaseName === '직접입력')).map(option => ({
                ...option,
                shop_cd
              })) : (etc.useOptionYn ? etc.optionGuides.map(option => ({
                ...option,
                shop_cd
              })) : [])
          ));

          const esmOptionsFlag = [],
                stdOptionsFlag = [],
                aliexpressOptionsFlag = [],
                alibabaOptionsFlag = [],
                naverSingleOptionsFlag = [],
                aliexpressGlobalOptionsFlag = [],
                temuOptionsFlag = [];

          for (const item of items) {
            if (['A001', 'A006'].includes(item.shop_cd)) {
              // A006, A001 일 때 중복 옵션만 사용가능z
              const anotherOpt = items.filter(opt => opt.ObjOptClaseName === item.ObjOptClaseName);

              $scope.data.esmDirectOptionEnable = $scope.data.esmDirectOptionEnable || item.ObjOptNo === 0;

              // 모든 옵션이 계정별로 있어야 하고 이미 들어간 것은 없어야 됨.
              if (item.ObjOptClaseName !== '직접입력' && anotherOpt.length === esmLen && !anotherOpt.find(opt => opt.isPushed)) {
                // 중복 옵션 중 한쪽이라도 선택옵션이 있는 경우 선택옵션으로 처리
                item.cateOpAttr = !!anotherOpt.find(opt => opt.DirectInsEnableYn === 'N');
                item.isPushed = true;

                if (item.TextUseYn !== 'Y') {
                  esmOptionsFlag.push(item);
                }
              }
            } else if (item.shop_cd === 'A092') {
              // 알리바바
              alibabaOptionsFlag.push(item);
            } else if (item.shop_cd === 'A093') {
              // 알리 익스프레스
              aliexpressOptionsFlag.push(item);
            } else if (item.shop_cd === 'A077') {
              // 스마트스토어 추천옵션
              naverSingleOptionsFlag.push(item);
            } else if (item.shop_cd === 'X094') {
              // 알리익스프레스 글로벌
              aliexpressGlobalOptionsFlag.push(item);
            } else if (item.shop_cd === 'A098') {
              // 테무 추천옵션
              temuOptionsFlag.push(item);
            } else {
              const anotherOpt = items.filter(opt => opt.stdOptNm === item.stdOptNm);

              if (anotherOpt.length === stdLen && !anotherOpt.find(opt => opt.isPushed)) {
                // S 선택형 N 숫자형 F 입력형
                // 숫자형은 뒤에 단위를 맞춰야함
                item.cateOpAttr = !!anotherOpt.find(opt => opt.stdOptTypCd === 'N');
                item.isPushed = true;
                stdOptionsFlag.push(item);
              }
            }
          }

          $scope.data.esm_options = [];
          $scope.data.aliexpress_options = [];
          $scope.data.alibaba_options = [];
          $scope.data.naver_single_options = [];
          $scope.data.aliexpress_global_options = [];
          $scope.data.temu_options = [];

          const handleOptions = async function (shopType, optionsFlag, notDisabled = false) {
            const optType = `${shopType}_opt_type`;
            const opt = `${shopType}_opt`;
            const options = `${shopType}_options`;
            const checkedOpt = `${shopType}_checked_opt`;
            const optionsTable = `${shopType}_options_table`;
            const attr = `.${shopType}_attr`;

            if (!optionsFlag.length && !notDisabled) {
              disabled[opt] = true;
              $scope.data[optType] = '옵션없음';
              prodOpt[opt] = [];
              prodOpt[optionsTable] = [];
            } else {
              const opt_list = [];

              for (const row of optionsFlag) {
                const opt_obj = {};
                if (shopType !== 'naver_single') {
                  opt_obj.name = row.ObjOptClaseName;

                  if (!_.isUndefined(row.ObjOptNo) || opt_obj.name_cd == '') {
                    opt_obj.name_cd = row.ObjOptNo;
                  }

                  opt_obj.shop_cd = row.shop_cd;
                  opt_obj.cateOpAttr = row.cateOpAttr;

                  if (shopType === 'esm') {
                    opt_obj.TextUseYn = row.TextUseYn;
                  }

                  // 테무는 엔진 호출로 옵션 값을 가져오기에 호출 값 넣어줌
                  if (shopType === 'temu') {
                    opt_obj.cateOpAttrList = temu_option_values[row.ObjOptNo];
                  } else {
                    const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: row.shop_cd, opt_cd: row.ObjOptNo });

                    if (res.data.result && res.data.result.shop_cate_opt_detail && res.data.result.shop_cate_opt_detail.length) {
                      opt_obj.cateOpAttrList = res.data.result.shop_cate_opt_detail;
                    }
                  }
                } else {
                  opt_obj.name = row.standardPurchaseOptions.map(opt => opt.optionName).join();
                  opt_obj.shop_cd = row.shop_cd;
                  opt_obj.TextUseYn = true;
                  opt_obj.cateOptList = row.standardPurchaseOptions;
                  opt_obj.guideId = row.guideId;
                }
                opt_list.push(opt_obj);
              }

              // 기존에 설정하던 옵션과 다르면 초기화
              // 직접입력 옵션만 있는경우 옵션없음 처리되지 않음
              if (
                (!(opt_list.length || notDisabled) && prodOpt[opt].filter(o => o.name).length) ||
                (shopType === 'naver_single' ? !prodOpt[opt][0]?.guideId : opt_list.some(cur => !prodOpt[opt].some(pre => pre.name === cur.name && pre.name_cd == cur.name_cd)))
              ) {
                $scope.data[optType] = '옵션없음';
                prodOpt[opt] = angular.copy(onlineProductSVC.default_opt);
                $scope.data[options] = opt_list;
                prodOpt[checkedOpt] = [];
                prodOpt[optionsTable] = [];
                $(attr).tagsinput('removeAll');

                if (shopType === 'esm') {
                  opt_checkbox_init('esm');
                }
                if (shopType === 'naver_single') {
                  $scope.data[optType] = '조합형';
                }
              }
              else if (opt_list.length && !$scope.data[options].length) {
                $scope.data[options] = opt_list;
              }
            }
          };

          if (checked.ALIEXPRESS) {
            await handleOptions('aliexpress', aliexpressOptionsFlag);
          }

          if (checked.ALIEXPRESSGLOBAL) {
            await handleOptions('aliexpress_global', aliexpressGlobalOptionsFlag);
          }

          if (checked.ALIBABA) {
            await handleOptions('alibaba', alibabaOptionsFlag);
          }

          if (checked.A077single) {
            await handleOptions('naver_single', naverSingleOptionsFlag);
          }

          if (checked.TEMU) {
            await handleOptions('temu', temuOptionsFlag);
          }

          // ESM 옵션 직접입력 옵션만 있는경우 비활성되지않고 옵션 입력 가능해야함
          const esmOpts = items.filter(row => ['A001', 'A006'].includes(row.shop_cd));

          await handleOptions('esm', esmOptionsFlag, (esmOpts.length && !esmOpts.some(item => item.ObjOptClaseName !== '직접입력')));

          if (!stdOptionsFlag.length) {
            disabled.std_opt = true;
            $scope.data.std_opt_type = '옵션없음';

          } else {
            // prodOpt.std_opt = [];
            // prodOpt.std_options_table = [];
            const std_opt_list = [];

            for (const row of stdOptionsFlag) {
              const std_opt = {};

              std_opt.required = row.stdOptRecmYn === 'Y';
              std_opt.title = `${std_opt.required ? '(추천)' : ''}${row.stdOptNm}(${row.stdOptTypCd})`;
              std_opt.name = row.stdOptNm;
              std_opt.name_cd = row.stdOptNo;
              std_opt.shop_cd = row.shop_cd;
              std_opt.cateOpAttr = row.cateOpAttr;
              std_opt.typeCd = row.stdOptTypCd;

              const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: 'A112', opt_cd: row.stdOptNo });

              if (res.data.result && res.data.result.shop_cate_opt_detail && res.data.result.shop_cate_opt_detail.length) {
                std_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
              }

              std_opt_list.push(std_opt);
            }

            _.forEach(std_opt_list, function(cur) {
              const find = _.find(prodOpt.std_opt, function(pre) {
                return pre.name == cur.name;
              });

              if (!find || std_opt_list.length !== prodOpt.std_opt.length) {
                $scope.data.std_opt_type = '옵션없음';
                prodOpt.std_opt = std_opt_list;
                prodOpt.std_checked_opt = [];
                prodOpt.std_options_table = [];
                //체크박스 초기화
                opt_checkbox_init('std');

                return false;
              }
            });

          }

          const inputOptionsFlag = items.filter(row => (
            row.ObjOptClaseName && row.TextUseYn == 'Y'
          ));

          //입력형 옵션
          if (inputOptionsFlag.length) {

            for (const row of inputOptionsFlag) {
              const find = inputOptionsFlag.filter(inputOpt => inputOpt.ObjOptClaseName === row.ObjOptClaseName);

              // 동일한 입력형이 있어야만 등록가능
              if (find.length && find.length === esmLen) {
                prodOpt.esm_input_list.push(row.ObjOptClaseName);
                prodOpt.esm_input_list = _.uniq(prodOpt.esm_input_list);
              }
            }
          }

          if (prodOpt.esm_input_list.length) {
            $('.esm_input_opt').select2({
              minimumResultsForSearch: Infinity,
              tags: false,
              width: '100%',
              placeholder: '콤마(,) 또는 엔터(enter) 구분',
              closeOnSelect: false
            });
            $('.esm_input_opt').change(function () {
              const va = $(this).val() || [];

              prodOpt.esm_input_name = va;
            });
          }

        } catch (err) {
          if (err !== 'SAME_OPTION') {
            commonSVC.showConfirmCustom({ title: '단일상품 옵션정보 호출에 실패했습니다.', text: '재시도 버튼을 클릭하시어 카테고리 호출을 다시 시도해 주세요.', confirmButtonText: '재시도' }, function (Confirm) {
              if (Confirm) {
                $scope.categoryOptions(checkedEsmVal, shop);
              }
            });
          }

          return false;
        } finally {
          if (checked.ESM) {
            stdOptLoadESM = false;
          }
          if (checked.A112) {
            stdOptLoadA112 = false;
            //옵션 변경 없이 11번가 단일상품 추가시 옵션명 확인
            if ($scope.prodOpt.esm_checked_opt.length && _.uniqBy($scope.prodOpt.esm_checked_opt, 'name').length !== $scope.prodOpt.esm_checked_opt.length) {
              $scope.checked.std_esm_defaultOpt = false;
            }
          }
          if (stdOptLoadESM === false && stdOptLoadA112 === false) {
            $('.optionSpinner').addClass('hidden');
            // 임시저장의 경우 ESM 입력형 옵션 select2에 넣어주는 처리
            await $timeout(() => {
              $('.esm_input_opt').val($scope.prodOpt.esm_input_name).trigger('change');
            });
          }
        }
      }
    };

    $scope.getGalleriaCate = async () => {
      $scope.cateReloadYn = true;
      if (!$scope.data.galleria_options) {
        $scope.data.galleria_options = [];
      }

      const match_cate_code = [];
      // 갤러리아몰 처리 (여러 카테고리 선택된 경우도 툴에서 처리)

      const site = $scope.data.site_list.filter(shop => {
        if (shop.pa_shop_cd === 'B394') {
          const category = shop.match_cate_code.split('_');

          if (category && category.length) {
            match_cate_code.push(category[2]);
          }
        }

        return shop.pa_shop_cd === 'B394';
      });

      if (!site) {
        return;
      }

      $('.optionSpinner').removeClass('hidden');

      let vend_code;
      let category_no;
      let no_add_info = site[0].shop_cate_no_add_info;

      if (_.isString(no_add_info)) {
        no_add_info = JSON.parse(no_add_info);
      }

      // 솔루션카테고리에 매칭된 쇼핑몰 카테고리
      if (no_add_info && no_add_info.category_code) {
        vend_code = no_add_info.key_vend_code;
        category_no = no_add_info.category_code;
      } else if (site[0].cate_info && site[0].cate_info.category_code) {
        vend_code = site[0].cate_info.vir_vend_code;
        category_no = site[0].cate_info.category_code;
      }

      const shopInfo = {
        shop_id: site[0].shop_id,
        shop_pwd: site[0].shop_pwd,
        pa_shop_cd: site[0].pa_shop_cd,
        etc7: site[0].etc7 // 실섭 배포시 퇴근(dev, app 구분용)
      };

      try {
        const response = await commonModel.pa5(
          '/channel_accounts/{uuid}/etc/get_categories_option/sync',
          { code: vend_code, category_id: category_no },
          shopInfo.pa_shop_cd,
          shopInfo.shop_id,
        );
        const list = response.data.results[0].data;

        // 이전과 다른 경우 옵션 초기화
        if (
          !_.isEqual(
            $scope.data.galleria_options.map(opt => opt.name),
            list.map(opt => opt.opt_nm),
          )
        ) {
          $scope.data.galleria_options = list.map(opt => {
            return { name: opt.opt_nm, opt_value: opt.optvalinfo, custom_able: opt.manual_poss_yn === 'Y' };
          });
          $scope.data.galleria_opt = [];
          $scope.prodOpt.galleria_options_table = [];
          $scope.prodOpt.galleria_opt = [];
        }

        if (!$scope.data.galleria_opt_type) {
          $scope.data.galleria_opt_type = '옵션없음';
        }
        $('.optionSpinner').addClass('hidden');
      } catch (err) {
        commonSVC.showMessage('갤러리아몰', `옵션 정보 조회 실패 \n${err.message || err}`);
        $('.optionSpinner').addClass('hidden');
      }
    };

    //옵션 체크박스 초기화
    function opt_checkbox_init(type) {
      if (type == 'esm') {
        $scope.checked.esm_defaultOpt = false;
        $scope.checked.wmp_esm_defaultOpt = false;
      } else {
        //기본옵션을 std 옵션으로
        $scope.checked.std_defaultOpt = false;
        $scope.checked.wmp_std_defaultOpt = false;
      }

      //기본옵션을 esm 옵션으로
      $scope.checked.std_esm_defaultOpt = false;
    }

    //원산지 국가 선택
    $scope.madein_type_change = function() {

      if ($scope.data.madein_type === '원양산') {
        commonSVC.showMessage('주의', '원양산은 스마트스토어에만 적용이 가능하며 원양산으로 입력하시는 경우 타 쇼핑몰에는 기타로 등록됩니다.');
      }

      $scope.madein_group_list = $scope.madeinList[$scope.data.madein_type];
    };

    //원산지 도시 선택
    $scope.madein_country_change = function() {
      $scope.madein_name_list = $scope.data.madein_group && $scope.data.madein_type !== '기타' ? $scope.madeinList[$scope.data.madein_type][$scope.data.madein_group] : null;
    };

    /**
   	* 등록 쇼핑몰 선택
   */
    $scope.reg_channel = [];
    $scope.$on('$viewContentLoaded', function () {
    // Fixed width. Multiple selects
      $('.select-fixed-multiple').select2({
        minimumResultsForSearch: Infinity,
        width: '100%',
        placeholder: '상품이 전시될 쇼핑몰을 선택 또는 검색해 주세요.',
        closeOnSelect: false
      });

      $('.reg-channel-select').on('change.select2', async function () {
        let va = $(this).val() || [];
        const site_list = [];

        $scope.templateListModal = false;

        // 전체 선택일 경우
        if (va[0] == 'all') {
          $scope.channelSelectAll = false;
          va = [];
          for (let i = 0; i < $scope.use_engines.length; i++) {
            va.push(i);
          }
          $(this).val(va).trigger('change');
          $(this).select2('close');

          return;
        }

        // 전체 해제일 경우
        if (va[0] == 'del') {
          $scope.channelSelectAll = true;
          $scope.channelUnAll();
          $(this).select2('close');

          // 전체해제시 이전 카테고리 설정 초기화
          $scope.search_yn = false;
          $scope.updateCateData = {};
          $scope.applyList = {};
          $scope.shopList = [];
          $scope.matchingInfo = [];
          $scope.canEsmMasterList = [];

          setSaveCnt();

          return;
        }

        // 위메프2.0 인덱스 배열
        const wmpIndexArr = [];

        va.forEach((v, k) => {
          const row = $scope.use_engines[parseInt(v)];
          const siteIndex = $scope.data.site_list.findIndex(s => s.shop_cd === row.shop_cd && s.shop_id === row.shop_id);

          if (row.pa_shop_cd === 'B719') {
            wmpIndexArr.push(v);
          }

          // 없던 사이트면 초기화 시켜줌
          if (siteIndex < 0) {
            const paShopCd = row.pa_shop_cd;

            // 홍보문구 규칙 적용
            // 이미지 규칙 적용
            const shop_promotion_name_role = onlineProductSVC.promotionNameRole(paShopCd);
            const site_sub_img_objects = angular.copy(onlineProductSVC.sub_img_objects[paShopCd]);
            const site_sub_img_box = onlineProductSVC.sub_img_box[paShopCd] ? onlineProductSVC.sub_img_box[paShopCd](row) : {};
            // 쇼핑몰 정보 생성
            const site_info = onlineProductSVC.new_shop(k, row, site_sub_img_objects, site_sub_img_box, shop_promotion_name_role);

            if (!$scope.shopList.find(s => s.shop_cd === row.shop_cd && s.shop_id === row.shop_id)) {
              const { pa_shop_cd, shop_cd, shop_id } = row;
              $scope.shopList.push({ pa_shop_cd, shop_cd, shop_id });
            }

            site_list.push(site_info);
            $scope.matchingInfo.push(site_info);
          } else {
            site_list.push($scope.data.site_list[siteIndex]);
          }

          if (!$scope.updateCateData[row.shop_cd]) { $scope.updateCateData[row.shop_cd] = {}; }
          if (!$scope.applyList[row.shop_cd]) { $scope.applyList[row.shop_cd] = {}; }
          if (!$scope.originData[row.shop_cd]) { $scope.originData[row.shop_cd] = {}; }
          if (!$scope.simCateList[row.pa_shop_cd]) { $scope.simCateList[row.pa_shop_cd] = ''; }

          if (!$scope.updateCateData[row.shop_cd][row.shop_id]) { $scope.updateCateData[row.shop_cd][row.shop_id] = ''; }
          if (!$scope.applyList[row.shop_cd][row.shop_id]) { $scope.applyList[row.shop_cd][row.shop_id] = []; }
          if (!$scope.originData[row.shop_cd][row.shop_id]) { $scope.originData[row.shop_cd][row.shop_id] = ''; }
        });

        if (va.length < $scope.data.site_list.length) {
          const removed = $scope.data.site_list.find(ori => site_list.every(updt => !(updt.shop_cd === ori.shop_cd && updt.shop_id === ori.shop_id)));

          if (removed) {
            $scope.updateCateData[removed.shop_cd][removed.shop_id] = '';
            $scope.originData[removed.shop_cd][removed.shop_id] = '';
            $scope.applyList[removed.shop_cd][removed.shop_id] = [];
            $scope.shopList.splice($scope.shopList.findIndex(s => s.shop_cd === removed.shop_cd && s.shop_id && removed.shop_id), 1);
            $scope.matchingInfo.splice($scope.matchingInfo.findIndex(s => s.shop_cd === removed.shop_cd && s.shop_id && removed.shop_id), 1);

            if ($scope.canEsmMasterList.length) {
              let delIdx;
              // ESM 마스터 상품일 경우 ESM 마스터 상품 매칭 데이터 지워줘야 함
              $scope.esmMatchShops.forEach((list, index) => {
                Object.keys(list).forEach(key => {
                  if (list[key].shop_cd === removed.shop_cd && list[key].shop_id === removed.shop_id && list[key].etc7 === removed.etc7) {
                    delIdx = index;
                  }
                });
              });

              if (delIdx) {
                $scope.esmMatchShops[delIdx][removed.shop_cd === 'A006' ? 'gmarket' : 'auction'] = {};
              }
            }

            setSaveCnt();
          }
        }

        await new Promise(async (siteOptCheckRes) => {
          if (!siteOptCheckRes) {
            return false;
          }

          //기존 사이트 리스트 + 추가된 사이트 리스트
          $scope.data.site_list = site_list;

          $scope.canEsmMasterList = [];
          makeEsmMasterInfo();
          makeEsmMatchChecked();

          const { esmFlag, a112Flag, cpangFlag, wmpFlag, naverFlag, hplusFlag, galleriaFlag, fplusFlag, isBenepia, aliexpressFlag, alibabaFlag, aliexpressGlobalFlag, temuFlag } = site_list.reduce((prev, row) => {
            switch (row.pa_shop_cd) {
              case 'A001': case 'A006': prev.esmFlag.push(row); break;
              case 'A112': prev.a112Flag.push(row); break;
              case 'B378': prev.cpangFlag.push(row); break;
              case 'B719': prev.wmpFlag.push(row); break;
              case 'A077': prev.naverFlag.push(row); break;
              case 'B502': prev.hplusFlag = true; break;
              case 'B394': prev.galleriaFlag = true; break;
              case 'B007': prev.fplusFlag = true; break;
              case 'B513': prev.isBenepia = true; break;
              case 'A092': prev.alibabaFlag.push(row); break;
              case 'A093': prev.aliexpressFlag.push(row); break;
              case 'X094': prev.aliexpressGlobalFlag.push(row); break;
              case 'A098': prev.temuFlag.push(row); break;
            }

            return prev;
          }, {
            esmFlag: [],
            a112Flag: [],
            cpangFlag: [],
            wmpFlag: [],
            naverFlag: [],
            hplusFlag: false,
            galleriaFlag: false,
            fplusFlag: false,
            isBenepia: false,
            aliexpressFlag: [],
            alibabaFlag: [],
            aliexpressGlobalFlag: [],
            temuFlag: [],
          });

          $scope.fplusFlag = fplusFlag;
          $scope.isCoupang = site_list.length === 1 && site_list[0].pa_shop_cd === 'B378';
          $scope.isSSG = site_list.length === 1 && ['A032', 'A083', 'A084', 'A085'].includes(site_list[0].pa_shop_cd);
          $scope.isBenepia = isBenepia;

          if (!va.length) {
            $scope.disabled.A112 = false;
            $scope.checked.ESM = $scope.checked.A112 = $scope.checked.ALIEXPRESS = $scope.checked.ALIBABA = $scope.checked.smileProd = $scope.checked.A077single = $scope.checked.ALIEXPRESSGLOBAL = $scope.checked.TEMU = false;
          } else {
            if (isSmile) {
              $scope.disabled.smileProd = !!esmFlag.length;
            }

            $scope.disabled.A112 = !!a112Flag.length;

            if ($scope.data.checkGrowth.available) {
              $scope.disabled.ROCKET = !!cpangFlag.length;
            } else {
              $scope.disabled.CPANG = !!cpangFlag.length;
            }

            $scope.disabled.A077single = !!naverFlag.length;

            if (!a112Flag.length) {
              $scope.checked.A112 = false;
            }

            $scope.checked.ESM = !!esmFlag.length;
            $scope.checked.WMP = !!wmpFlag.length;
            $scope.checked.HPLUS = !!hplusFlag;
            $scope.checked.GALLERIA = !!galleriaFlag;
            $scope.checked.NAVER = !!naverFlag.length;

            //알리 익스프레스 선택한경우
            if (!aliexpressFlag.length) {
              $scope.checked.ALIEXPRESS = false;
            } else {
              $scope.checked.ALIEXPRESS = true;
            }
            //알리바바 선택한경우
            if (!alibabaFlag.length) {
              $scope.checked.ALIBABA = false;
            } else {
              $scope.checked.ALIBABA = true;
            }
            //알리익스프레스 글로벌 선택한경우
            if (!aliexpressGlobalFlag.length) {
              $scope.checked.ALIEXPRESSGLOBAL = false;
            } else {
              $scope.checked.ALIEXPRESSGLOBAL = true;
            }
            //테무 선택한경우
            if (!temuFlag.length) {
              $scope.checked.TEMU = false;
            } else {
              $scope.checked.TEMU = true;
            }
          }

          // 등록쇼핑몰 변경시 ESM2.0옵션 초기화되지 않게 수정 2018-06-05 Amelia
          if (!$scope.loadTemporary) {
            $scope.onlineOptionsList = []; // 모델명, 브랜드, 제조사 입력된 내용 초기화

            await $scope.shopSetInfoMatch(); // 쇼핑몰별 추가항목 기본값 자동세팅
          }

          await $scope.shopCateMatch(); //쇼핑몰 계정별 카테고리 자동매칭

          $scope.defaultOpVisible();

          $scope.overlapResult = null;

          if ($scope.checked.NAVER) {
            naverCateLoad(naverFlag);
          }

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }

          $scope.loadTemporary = false;
        });
      });
    });

    /**
     * ESM마스터상품 만들수 있는 쇼핑몰 리스트 생성
     */
    const makeEsmMasterInfo = () => {
      // ESM 마스터 상품 생성 가능 여부 확인
      if ($scope.data.site_list.some(shop => ['A001', 'A006'].includes(shop.shop_cd))) {
        const gmarketShops = $scope.data.site_list.filter(shop => shop.shop_cd === 'A006' && shop.etc7);
        const auctionShops = $scope.data.site_list.filter(shop => shop.shop_cd === 'A001' && shop.etc7);
        $scope.maxEsmCnt = 0;

        if (gmarketShops.length && auctionShops.length) {

          gmarketShops.forEach(gs => {
            // G마켓, 옥션에 동일한 마스터ID가 있을 경우 마스터 상품 생성 가능
            const matchEsmAccount = auctionShops.filter(as => as.etc7 === gs.etc7);

            if (matchEsmAccount.length) {
              $scope.checkEsmMaster.disabled = true;

              if (!$scope.canEsmMasterList.some(shop => gs.shop_cd === shop.shop_cd && gs.shop_id === shop.shop_id && gs.etc7 === shop.etc7)) {
                $scope.canEsmMasterList.push({
                  shop_cd: gs.shop_cd,
                  shop_id: gs.shop_id,
                  etc7: gs.etc7,
                });
              }

              matchEsmAccount.forEach(as => {
                if (!$scope.canEsmMasterList.some(shop => as.shop_cd === shop.shop_cd && as.shop_id === shop.shop_id && as.etc7 === shop.etc7)) {
                  $scope.canEsmMasterList.push({
                    shop_cd: as.shop_cd,
                    shop_id: as.shop_id,
                    etc7: as.etc7,
                  });
                }
              });
            }
          });
        }

        if (!$scope.canEsmMasterList.length) {
          $scope.checkEsmMaster.disabled = false;
          $scope.checkEsmMaster.checked = false;

          $timeout(() => {});
        } else {
          // 최대 가능한 ESM 마스터 조합 갯수
          // 동일 마스터 계정에서 적은 수의 쇼핑몰 기준
          // 지마켓 3개, 옥션 2개 => 조합 가능한 마스터 상품 2개(옥션)
          Object.values(_.groupBy($scope.canEsmMasterList, 'etc7')).forEach(v => {
            if (v.some(shop => shop.shop_cd === 'A001') && v.some(shop => shop.shop_cd === 'A006')) {
              $scope.maxEsmCnt = $scope.maxEsmCnt + _.minBy(Object.values(_.groupBy(v, 'shop_cd')), 'length').length;
            }
          });
        }
      }

      // 쿠팡 로켓그로스 사용 가능 여부
      // 로켓 그로스 사용 불가능한 계정 하나라도 있을 시 사용여부 불가능 처리
      if (
        $scope.data.site_list.filter(shop => shop.shop_cd === 'B378').length &&
        ($scope.data.site_list.filter(shop => shop.shop_cd === 'B378' && shop.etc6 === 'true').length === $scope.data.site_list.filter(shop => shop.shop_cd === 'B378').length)
      ) {
        $scope.data.checkGrowth.available = true;
        $scope.data.checkGrowth.coupang = true;
      } else {
        $scope.data.checkGrowth.available = false;
        $scope.data.checkGrowth.rocket = false;
        $scope.data.checkGrowth.coupang = true;
      }

      $timeout(() => {});
    };

    const naverCateLoad = async(data, initialize) => {
      try {
        const cateList = _.uniq(data.map(d => {
          const cate = d.match_cate_code.split('_');

          return cate[cate.length - 1];
        }));

        // 2개 이상 카테고리 사용 시 간편옵션 사용불가 처리
        if (cateList.length > 1) {
          $scope.checked.NAVER = false;
          $scope.changeOptTab(0);

          return false;
        }

        const cate = cateList[0];
        if ((!$scope.prodOpt.naver_opt?.length || initialize) && cate) {
          $scope.data.naver_opt_type = '옵션없음';
          $scope.prodOpt.naver_opt = [];
          $scope.data.naver_options = {};
          $scope.data.naver_options.optional = [];

          const { shop_cd, shop_id } = data[0];
          const response = await commonModel.pa5('/channel_accounts/{uuid}/etc/check_is_standard_option_category/sync', { category_code: cate }, shop_cd, shop_id);

          // 간편옵션 조회 결과가 없으면 간편옵션 카테고리가 아님
          if (response.data.results?.length && !response.data.results[0]?.data || response.data.exception_message) { throw 'none standard_option'; }
          const groups = response.data?.results[0].data;

          // pa5 호출은 됐으나 간편옵션이 없는 경우에도 간편옵션 사용할 수 없는 카테고리임
          if (groups.length === 0) { throw 'none standard_option'; }

          _.uniq(groups.map(group => group.groupName ? group.groupName : group.attributeName.split('(')[0].trim()))
            .forEach(groupName => {
              const item = {
                sub_group: [],
                sub_group_name_list: [],
                selected_attribute: [],
                name: groupName
              };
              $scope.prodOpt.naver_opt[groupName === '색상' ? 0 : 1] = item;
            });

          groups.forEach(group => {
            if (group.standardOptionAttributes.find(att => !att.attributeValueId || att.attributeValueId.toString() === '0')) {
              throw 'none option_code';
            }
            const idx = group.groupName === '색상' ? 0 : 1;
            const params = {
              sub_group_name: group.attributeName,
              sub_group_id: group.attributeId.toString(),
              attribute: group.standardOptionAttributes
            };

            // 간편옵션 스타일ID 가져옴
            const color_opt_style = angular.copy(onlineProductSVC.naverColorOptStyle);
            params.attribute.forEach(att => {
              att.selected = false;
              if (group.groupName === '색상') {
                att.styleId = color_opt_style.find(style => style.AttributeValueId === att.attributeValueId).styleId;
              }
            });

            $scope.prodOpt.naver_opt[idx].sub_group.push(params);
            $scope.prodOpt.naver_opt[idx].sub_group_name_list.push(group.attributeName);
          });
          $scope.prodOpt.naver_opt[0].name_cd = $scope.prodOpt.naver_opt[0].sub_group[0].sub_group_id;
          $scope.prodOpt.naver_opt[1].name_cd = $scope.prodOpt.naver_opt[1].sub_group[0].sub_group_id;
          $scope.prodOpt.naver_opt[1].selected_sub_group = $scope.prodOpt.naver_opt[1].sub_group[0].attribute;
          $scope.prodOpt.naver_opt[1].selected_sub_group_name = $scope.prodOpt.naver_opt[1].sub_group_name_list[0];
        }
      } catch (err) {
        if (err !== 'none standard_option') {
          let addMsg = err !== 'none option_code' ? err.data.message : '';

          if (!$rootScope.use_channel_list.find(({ shop_cd, shop_id }) => shop_cd === data[0].shop_cd && shop_id === data[0].shop_id).uuid) {
            addMsg = '쇼핑몰 계정관리에서 계정을 다시 확인해주세요.';
          }
          commonSVC.showMessage('스마트스토어', `${err === 'none option_code' ? '옵션코드' : '간편옵션'} 조회에 실패했습니다.\n` + addMsg);
        }

        $scope.checked.NAVER = false;
        $scope.changeOptTab(0);
      }
    };

    // ESM 마스터 상품 생성 조합 option 출력
    // 옥션 : 선택된 지마켓의 마스터 ID와 동일한 옥션 계정 리스트
    // 지마켓 : 중복 선택되지 않은 마스터 ID 리스트
    $scope.filteredEsmId = (shop_cd, index) => {
      if (shop_cd === 'A001') {
        return $scope.canEsmMasterList.filter(data => data.shop_cd === 'A001' &&
          (data.etc7 === $scope.esmMatchShops[index]?.gmarket?.etc7 && !data.checked || data.shop_id === $scope.esmMatchShops[index]?.auction?.shop_id && data.checked));
      } else {
        return $scope.canEsmMasterList.filter(shop => shop.shop_cd === 'A006' && (!shop.checked || shop.shop_id === $scope.esmMatchShops[index]?.gmarket.shop_id));
      }
    };

    /**
   * 쇼핑몰 개별 이미지 지원여부
   */
    $scope.support_sub_img = function(row) {
      return row.sub_img_objects === undefined;
    };

    /**
     * 추가구매 옵션, 입력형 옵션 등록 가능 여부 체크
     *
     * 설정할 쇼핑몰 정보가 변경될때마다 호출하도록 수정.
     */
    $scope.addOptVisibled = true;
    $scope.inputOptVisibled = true;
    const checkOptVisivled = function () {
      for (const checkVisibled of ['addOptVisibled', 'inputOptVisibled']) {
        let imposs_shop_list = [];

        //등록 불가능한 쇼핑몰 리스트
        if (checkVisibled === 'addOptVisibled') {
          imposs_shop_list = Object.keys(siteInfo).filter(shop_cd => !siteInfo[shop_cd].add_opt_use_yn && shop_cd === siteInfo[shop_cd].pa_shop_cd).map(shop_cd => shop_cd);
        } else {
          imposs_shop_list = Object.keys(siteInfo).filter(shop_cd => !siteInfo[shop_cd].input_opt_use_yn && shop_cd === siteInfo[shop_cd].pa_shop_cd).map(shop_cd => shop_cd);
        }

        //등록 가능한 전체 쇼핑몰
        const poss_shop_list = {};

        //등록 가능한 쇼핑몰
        $scope.data.site_list.forEach(function(row) {
          if (imposs_shop_list.indexOf(row.pa_shop_cd) == -1) {
            poss_shop_list[row.pa_shop_cd] = true;
          }
        });

        // ESM물류 상품인경우 추가구매옵션 미지원
        if ($scope.checked.smileProd && checkVisibled === 'addOptVisibled') {
          delete poss_shop_list.A006;
          delete poss_shop_list.A001;
        }

        if (Object.keys(poss_shop_list).length) {
          $scope[checkVisibled] = true;
        } else {
          $scope[checkVisibled] = false;
        }
      }
    };
    checkOptVisivled();

    /**
     * 개별 상품명 입력 여부
     */
    $scope.prodNameCheck = function() {
      return $scope.data.site_list.some(row => !row.shop_sale_name);
    };

    /**
   * 개별 판매가 입력 여부
   */
    $scope.prodSaleCheck = function() {
      return $scope.data.site_list.some(row => !row.sale_price);
    };

    /**
   * 개별 판매수량 입력 여부
   */
    $scope.prodStockCheck = function() {
      return $scope.data.site_list.some(row => !row.prod_stock);
    };

    /**
   * 바이트 체크
   */
    $scope.byteCheck = onlineProductSVC.byteCheck;
    // 글자수 체크
    $scope.characterCountCheck = onlineProductSVC.characterCountCheck;
    $scope.preByteCheck = (row) => {
      let str = row.shop_promotion_name;

      if (['A001', 'A006'].includes(row.pa_shop_cd)) {
        str += row.shop_sale_name;
      }

      let return_value = $scope.byteCheck(str);

      if (return_value > row.promotion_name_byte) {
        return_value = row.promotion_name_byte;
      }

      return return_value;
    };

    /**
     * 홍보문구 바이트 검사
     */
    $scope.contentByteCheck = function (row, maxbytes) {
      if (!['A001', 'A006', 'A112', 'A076'].includes(row.pa_shop_cd)) {
        return;
      }

      let str = row.shop_promotion_name;
      let cutByte = maxbytes;

      if (['A001', 'A006'].includes(row.pa_shop_cd)) {
        str += row.shop_sale_name;
        cutByte = maxbytes - $scope.byteCheck(row.shop_sale_name);
      }
      let lengthTotal;

      if (row.promotion_name_str) {
        lengthTotal = $scope.characterCountCheck(str);

        if (lengthTotal > row.promotion_name_str) {
          row.shop_promotion_name = commonSVC.cutByLength(row.shop_promotion_name, row.promotion_name_str);
        }
      } else {
        lengthTotal = $scope.byteCheck(str);

        if (lengthTotal > maxbytes) {
          row.shop_promotion_name = commonSVC.cutByByte(row.shop_promotion_name, cutByte);
        }

        return lengthTotal;
      }
    };

    /**
     * 상품명 + 홍보문구 바이트 검사
     */
    $scope.prodNameByteCheck = function(row) {
      let lengthTotal = 0;

      if (row.std_ol_yn && ['A001', 'A006'].includes(row.pa_shop_cd)) {
        // esm 2.0 단일상품은 합산해야하므로 특별처리
        lengthTotal = $scope.byteCheck(row.shop_sale_name) + $scope.byteCheck(row.shop_promotion_name);

        if (lengthTotal > 100) {
          row.shop_sale_name = commonSVC.cutByByte(row.shop_sale_name, (100 - $scope.byteCheck(row.shop_promotion_name)));
          row.product_name_count = 100;
        } else {
          row.product_name_count = lengthTotal;
        }
      } else {
        if (row.prod_name_is_byte) {
          const isUtf8 = onlineProductSVC.utf8ShopCodes.includes(row.pa_shop_cd);

          lengthTotal = $scope.byteCheck(row.shop_sale_name, isUtf8);

          if (lengthTotal > row.prod_name_max) {
            row.shop_sale_name = commonSVC.cutByByte(row.shop_sale_name, row.prod_name_max, isUtf8);

            row.product_name_count = row.prod_name_max;
          } else {
            row.product_name_count = lengthTotal;
          }
        } else {
          lengthTotal = $scope.characterCountCheck(row.shop_sale_name);

          if ($scope.characterCountCheck(row.shop_sale_name) > row.prod_name_max) {
            row.shop_sale_name = commonSVC.cutByLength(row.shop_sale_name, row.prod_name_max);

            row.product_name_count = row.prod_name_max;
          } else {
            row.product_name_count = lengthTotal;
          }
        }
      }
    };

    /**
   * 프로모션명 타이틀
   */
    $scope.getPromotionTitle = (row) => onlineProductSVC.getPromotionTitle(row.pa_shop_cd, $scope.checked.ESM);

    /**
     * 로켓그로스 여부 확인
     */
    $scope.checkRocketGrowth = (shop_id) => {
      if ($rootScope.useChannelCheck('B378', 'growth')) {
        return $rootScope.use_channel_list_scm.find(channel => channel.pa_shop_cd === 'B378' && channel.etc6 === 'true' && channel.shop_id === shop_id);
      } else {
        return false;
      }
    };

    /**
   * 5대 마켓 필터
   */
    $scope.marketFilterFunction = function() {
      // 갤러리아몰은 업체 = 카테고리라서 브랜드 처리 못해 직접입력만 지원
      return onlineProductSVC.marketFilterFunction($scope.data.site_list.filter(o => o.pa_shop_cd !== 'B394'));
    };

    /**
   * 전체선택 해제
   */
    $scope.channelUnAll = function() {
      $scope.data.site_list = [];
      $('.reg-channel-select').val([]).trigger('change');
    };

    /**
   * 리스트 원소 제거
   */
    $scope.removeElement = function(list, element) {
      list.splice(list.indexOf(element), 1);
    };

    /**
   * 모델명/브랜드/제조사 로우 삭제
   */
    $scope.removeModelItem = function(element) {
      angular.forEach($scope.data.site_list, function(v) {
        if ((element.shop_cd == v.shop_cd) || (element.shop_cd == 'A001' && v.shop_cd == 'A006')) {
          _.forEach(models, function (type) {
            v[`search_${type}_yn`] = 0;
            v[`${type}_name`] = '';
          });

          v.product_brand = '';
          v.selectedModels = false;
          if (v.shop_cd == 'A001') {
            v.CatalogId = '';
          }
          // 지마켓 ctlgNo 처리 주석
          // else if (v.shop_cd == 'A006') {
          //   v.ctlgNo = '';
          // }

          v.set_info.brand = { code: '', name: '' };
          v.set_info.model = { code: '', name: '' };
          v.set_info.manufacturer = { code: '', name: '' };
        }
      });

      $scope.removeElement($scope.onlineOptionsList, element);
    };

    /**
   * SKU상품 매칭
   */
    //type [0, 1, 2, 3] = [일반옵션, esm, 쿠팡, 위메프]
    //confirmflag - confirm여부
    //mainProd - 대표상품 지정여부(대표상품인 경우 true)
    //tabNo - [0 ~ 8] = [일반옵션 옵션없음, 독립형, 조합형, esm2.0 옵션없음, 옵션사용, 쿠팡 옵션없음, 옵션사용, 위메프2.0 옵션 없음, 옵션 있음]
    $scope.prodProductSearch = function (idx, list, type, add, confirmflag, mainProd, tabNo) {
      const resolve = {
        data: {
          warehouseList: $scope.warehouseList,
          type: type
        }
      };
      const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

      redata.result.then(function (re) {
        //일반옵션 매칭시 기본등록 체크해제
        if ($scope.opTabActive === 0) {
          $scope.checked.esm_defaultOpt = false;
        }

        //세트상품인 경우
        if (re.isSet) {
          setProdMatch(re.data, idx, list, type, add, tabNo);
        }
        //SKU상품인 경우
        else {
          productMatch(re, idx, list, type, add, confirmflag, mainProd, tabNo);
        }
      });
    };

    // 매칭된 SKU상품 삭제
    $scope.prodProductRemove = async function (row) {
      commonSVC.showConfirmCustom({
        title: 'SKU상품 매칭 해제 안내',
        text: '해당 옵션에 매칭된 SKU상품의 정보를 삭제합니다.\n매칭을 해제하는 경우 옵션의 이미지도 함께 삭제되며,\n검색 버튼 클릭으로 다른 SKU상품을 매칭할 수 있습니다',
        showCancelButton: true,
        confirmButtonText: '삭제',
        cancelButtonText: '취소'
      }).then((confirm) => {
        if (confirm) {
          row.prod_img = '/assets/images/upload.png';
          row.sku_cd = null;
          row.set_no = null;
          row.set_cd = null;
          row.prod_no = null;
          row.prod_name = null;
          row.set_name = null;
          row.sale_price = null;
          row.attri = null;
          row.stock_cnt = null;
          row.safe_stock = null;
          $timeout(() => {});
        }
      });
    };

    /**
     * LG 전자 모델번호 SKU상품 매칭.
     */
    $scope.modelProductSearch = function () {
      const redata = commonSVC.openModal('xxg', { data: { modal_type: 'lg_custom' } }, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

      redata.result.then(function (re) {
        $scope.leadProdInfoModelno = re;
        $scope.data.prodall.model_no = re.model_no || '';

        if ($scope.data.model_prod_no && $scope.data.model_prod_no !== re.prod_no) {
          $scope.modelProdMatch.checked = false;
        } else {
          $scope.prodAutoSetForModelno();
          $scope.modelProdMatch.checked = true;
        }
        $timeout();
      });
    };

    //세트상품 옵션 테이블에 매칭
    function setProdMatch(result, idx, list, type, add, tabNo) {
      //동일한 세트번호로 매칭된게 있는지 확인
      // var flag = _.filter(list, function(row) {
      //   return row.set_no == result.set.set_no;
      // });
      //동일 세트번호 가능하도록 변경됨
      // if(flag.length > 0){
      //   commonSVC.showMessage("실패", "이미 매칭된 세트번호 입니다.");
      //   return false;
      // }

      let main_product;

      //세트상품의 대표상품 구함
      angular.forEach(result.prod, function(row) {
        if (row.prod_no == result.set.main_prod_no) {
          main_product = row;
        }
      });

      //세트상품의 대표상품 정보로 옵션 리스트에 등록
      angular.extend(list[idx], main_product);
      list[idx].prod_no = main_product.prod_no;
      list[idx].prod_name = result.set.set_name;
      list[idx].prod_img = result.set.set_img ? result.set.set_img : (main_product.prod_img || '/assets/images/upload.png');
      list[idx].pack_unit = result.set.set_pack_unit;
      // list[idx].prod_weight = $scope.prod_auto_set_yn ? _(result.prod).map('prod_weight').sum() : null;
      list[idx].set_no = result.set.set_no;
      list[idx].set_cd = list[idx].sku_cd = result.set.set_cd;
      list[idx].prod_status = '정상';
      list[idx].checked = false;
      list[idx].set_prod_list = result.prod; //세트에 묶인 상품 리스트, 상품 등록시 쓰임
      list[idx].sale_price = result.set.sale_price;   // 세트 대표상품 가격 => 세트 가격으로 변경 2019-04-29 rony

    }
    //SKU상품 옵션 테이블에 매칭
    function productMatch(result, idx, list, type, add, confirmflag, mainProd, tabNo) {
    // 원소 할당 안된경우 오브젝트 할당 2018-05-03 roy 추가
      if (list[idx] === undefined) {
        list[idx] = {};
      }

      angular.extend(list[idx], result);
      list[idx].prod_img = result.prod_img || '/assets/images/upload.png';
      list[idx].safe_stock = result.stock_cnt_safe;
      list[idx].pack_unit = result.pack_unit;
      list[idx].prod_status = '정상';//result.stock_status == '재고부족' ? '품절' : '정상';
      // list[idx].prod_weight = $scope.prod_auto_set_yn ? result.prod_weight : null;
      list[idx].checked = false;
      list[idx].set_no = 0;
      list[idx].set_cd = null;
    }

    $scope.uid_tooptip_custom = '카테고리 및 등록하실 쇼핑몰 선택 후 이동 가능합니다.';

    async function checkTabDisabled() {
      $scope.uid_tooptip_custom = '카테고리 및 등록하실 쇼핑몰 선택 후 이동 가능합니다.';
      const same_check = {
        B378: null,
        B394: null,
        B524: null
      };
      const check_shop = new Set();

      if ($scope.data.site_list.length > 0 && $scope.data.sol_cate_no) {
        const cateFlagList = await Promise.all($scope.data.site_list.map(async (row) => {

          const matchCateData = $scope.updateCateData[row.shop_cd][row.shop_id].detailData;
          let flag;

          switch (row.pa_shop_cd) {
            case 'A001':
            case 'A006':
              flag = !matchCateData?.catecode_esm;
              if (!matchCateData?.catecode_esm && matchCateData?.category_code) {
                // esm 카테고리 조회 가능하면 true 불가능하면 false
                const { data } = await categoryModel.getEsmCategoryExistYn({ shop_cd: row.shop_cd, match_cate_code: matchCateData?.category_code });

                flag = data.result;
              }
              break;
            case 'A077':
              flag = !(matchCateData?.category_code || matchCateData?.category_codes);
              // 단일(그룹) 상품 지원 카테고리 여부 확인
              if ($scope.checked.A077single && !$scope.updateCateData[row.shop_cd][row.shop_id]?.singleCateYn) {
                flag = true;
              }
              break;
            case 'B719':
              flag = !matchCateData?.categoryCd;
              break;
            case 'A112':
              flag = !matchCateData?.key_selPrdTypCd;
              break;
            case 'A100':
              flag = !matchCateData?.key_cv0;
              break;
            case 'B616':
            {
              const isCateCodeExists = matchCateData ? Object.keys(matchCateData).some(key =>
                key.startsWith('key_cpm_cate_code') && matchCateData[key]
              ) : false;
              flag = !(matchCateData?.category_code || isCateCodeExists);
              break;
            }
            default:
              flag = !(matchCateData?.category_code || matchCateData?.category_codes);
          }

          if (Object.keys(same_check).includes(row.pa_shop_cd)) {
            if (!same_check[row.pa_shop_cd]) {
              same_check[row.pa_shop_cd] = matchCateData?.category_code;
            } else {
              if (same_check[row.pa_shop_cd] !== matchCateData?.category_code) {
                check_shop.add(row.shop_name);
              }
            }
          }
          row.cateFlag = flag;

          return row;
        })).then(result => result);

        const cateFlag = cateFlagList.filter(({ cateFlag }) => cateFlag);

        if (cateFlag.length) {
          $scope.tabDisabled = true;
        } else {
          if ([...check_shop].length > 0) {
            commonSVC.showMessage([...check_shop].join(', '), '동일 쇼핑몰의 ID가 여러개인 경우 카테고리가 동일한 상품만 추천 옵션 설정이 가능합니다.\n카테고리를 동일하게 설정해 주세요');
            $scope.uid_tooptip_custom = '카테고리가 다중 선택된 경우, 카테고리에 따른 추천 옵션 및 추가 정보 추출이 불가합니다.\n카테고리를 동일하게 설정해 주세요.';
            $scope.tabDisabled = true;
          } else {
            $scope.tabDisabled = false;
          }
        }
      } else {
        $scope.tabDisabled = true;
      }

      // 변경사항 발생시 옵션사용여부 재확인
      checkOptVisivled();
    }

    function checkedEsmTabDisabled () {
      // ESM 마스터 상품 생성 계정 선택 여부 유효성 검사
      if ($scope.checkEsmMaster.checked) {
        if ($scope.esmMatchShops.some(list => !list?.auction.shop_id || !list?.gmarket.shop_id)) {

          $scope.tabDisabled = true;
          $timeout(() => {});

          return true;
        }

        // ESM 마스터 계정 모두 선택한 경우에는 지마켓 쇼핑몰 상품 데이터 연동한 옥션 계정 정보를 넣어줘 다른 탭에서 지마켓, 옥션 공통 처리함
        $scope.data.site_list.forEach((shop) => {
          if (['A001', 'A006'].includes(shop.shop_cd)) {
            const shopName = shop.shop_cd === 'A006' ? 'gmarket' : 'auction'; // 각각 반대 매칭 쇼핑몰의 계정을 넣어줘야함
            const matchShop = $scope.esmMatchShops.find(s => s[shopName].shop_cd === shop.shop_cd && s[shopName].shop_id === shop.shop_id && s[shopName].etc7 === shop.etc7);

            if (matchShop) {
              shop.esmMatchId = matchShop[`${shop.shop_cd === 'A006' ? 'auction' : 'gmarket'}`].shop_id;
              shop.ebay_shop_mas_no = null;
            } else {
              shop.esmMatchId = '';
            }
          }
        });

      }

      checkTabDisabled();

      $timeout(() => {});

      return false;
    }

    /**
   * 세트상품 매칭 제거
   */
    $scope.delStock = function() {
      const ob = $scope.data.match_opt_type == '매칭' ? $scope.prodOpt.matchStockList : $scope.prodOpt.addStockList;
      let cnt = 0;
      let i;

      for (i in ob) {
        if (ob[i].checked) {
          cnt++;
        }
      }
      if (!cnt) {
        commonSVC.showMessage('실패', '삭제하실 세트상품을 하나 이상 선택 해주세요.');

        return;
      }
      if (cnt < 5 && ob.length != 1) {
        for (i = 0; i < ob.length; i++) {
          if (ob[i].checked == true) {
            ob.splice(i, 1);
            i = i - 1;
          }
        }
      } else {
        commonSVC.showMessage('실패', '세트상품 추가 매칭은 최소 1개 이상 등록 해주세요.');
      }
    };

    /**
   * 세트상품 전체 선택 및 해제
   */
    $scope.stockAllCheck = function(ob, flag) {
      for (const i in ob) {
        ob[i].checked = flag;
      }
    };

    //전체 체크박스 확인
    $scope.checkboxClick = function(ob, cbflag) {
      for (const i in ob) {
        if (!ob[i].checked) {
          $scope.flag[cbflag] = false;

          return;
        }
      }
      $scope.flag[cbflag] = true;
    };

    /**
		 * 추가구매 옵션 순서 변경
		 */
    $scope.moveAddOpt = function(upward, position) {
      const obj = {
        upward: upward,
        position: position,
        opt_table: $scope.prodOpt.add_opt_table,
        add: false,
      };
      const resultOpt =	onlineProductSVC.optMoveSet(obj);

      if (resultOpt.state) {
        $scope.prodOpt.add_opt_table = resultOpt.data;
      }
    };

    /**
	  	* 현재 옵션 추출
	  */
    $scope.optGetTables = function(opt_type, options_table, opt_value, type) {
      if (opt_type !== '옵션없음' && !options_table.length) {
        commonSVC.showMessage('옵션테이블이 없습니다. 옵션을 생성해주세요.');
        $scope.changeTab(2);
        $scope.flag.submit = false;

        return false;
      }

      if (opt_type == '조합형') {
        const opt_obj = {
          opt1: [],
          opt2: [],
          opt3: []
        };

        if (options_table.length > 0) {
          // 중복 옵션 확인
          if (options_table.some((row, index, arr) => arr.some((opt, optIndex) => index !== optIndex
            && row.attr1 === opt.attr1
            && (row.name2 ? row.attr2 === opt.attr2 : true)
            && (row.name3 ? row.attr3 === opt.attr3 : true)
          ))) {
            commonSVC.showMessage('중복 옵션이 있습니다. 옵션을 수정해주세요.');
            $scope.changeTab(2);
            $scope.flag.submit = false;

            return false;
          }

          options_table.forEach(row => {
            // 옵션 테이블에서 옵션 정보 추출
            if (row.attr1 && !_.includes(opt_obj.opt1, row.attr1)) {
              opt_obj.opt1.push(row.attr1);
            }
            if (row.attr2 && !_.includes(opt_obj.opt2, row.attr2)) {
              opt_obj.opt2.push(row.attr2);
            }
            if (row.attr3 && !_.includes(opt_obj.opt3, row.attr3)) {
              opt_obj.opt3.push(row.attr3);
            }
          });
        }

        if (type === '' || type === 'wmp_' || type === 'cpang_') {
          // 옵션값 변경시 tagsInput 디렉티브에서 자동으로 갱신됨
          if (opt_value[0]) {
            if (options_table[0].name1) {
              opt_value[0].name = options_table[0].name1;
            }
            opt_value[0].attr = opt_obj.opt1.join(',');
          }

          if (opt_value[1]) {
            if (options_table[0].name2) {
              opt_value[1].name = options_table[0].name2;
            }
            opt_value[1].attr = opt_obj.opt2.join(',');
          }

          if (opt_value[2]) {
            if (options_table[0].name3) {
              opt_value[2].name = options_table[0].name3;
            }
            opt_value[2].attr = opt_obj.opt3.join(',');
          }
        } else {
          if (['std_', 'esm_'].includes(type)) {
            $scope.prodOpt[`${type}checked_opt`] = [];
          }
          const processedIndexes = new Set(); // 이미 처리한 인덱스를 추적하기 위한 Set
          for (let i = 1; i < 4; i++) {
            if (options_table[0][`name${i}`]) {
              // 옵션명이 중복일 경우 중복된 필드에 대한 인덱스 배열을
              const duplicateIndexes = opt_value.reduce((indexes, row, index) => {
                if (type == 'std_') {
                  if (options_table[0][`name${i}`] == row.name && options_table[0][`name${i}_cd`] == row.name_cd) {
                    indexes.push(index);
                  }
                } else if (type == 'esm_') {
                  if (options_table[0][`name${i}`] == row.name) {
                    indexes.push(index);
                  }
                } else {
                  if (options_table[0][`name${i}`] == (row.name || row.attributeTypeName)) {
                    indexes.push(index);
                  }
                }

                return indexes;
              }, []);

              // 이미 처리한 중복 인덱스를 건너뛰고 다음 인덱스를 찾음
              let opt_findIdx = -1;
              for (const idx of duplicateIndexes) {
                if (!processedIndexes.has(idx)) {
                  opt_findIdx = idx;
                  processedIndexes.add(idx);
                  break; // 다음 중복 인덱스를 찾기 위해 반복문 종료
                }
              }

              if (opt_findIdx !== -1) {
                if (type == 'std_') {
                  opt_value[opt_findIdx].attr = opt_obj[`opt${i}`].map(opt => !isNaN(parseInt(opt)) ? parseInt(opt) : opt).join(',');
                } else {
                  opt_value[opt_findIdx].attr = opt_obj[`opt${i}`].join(',');
                }

                opt_value[opt_findIdx].checked = true;
                if (type == 'std_' || type == 'esm_') {
                  $scope.prodOpt[`${type}checked_opt`].push(opt_value[opt_findIdx]);
                }
              }
            }
          }
        }

        // ESM에서 지원하는 옵션만 추출하여 넣어줌

      } else {
        const opt = [], opt_name = [];

        if (options_table.length > 0) {
          // 중복 옵션 확인
          if (options_table.some((row, index, arr) => arr.some((opt, optIndex) => index !== optIndex && row.attr === opt.attr && row.name === opt.name))) {
            commonSVC.showMessage('중복 옵션이 있습니다. 옵션을 수정해주세요.');
            $scope.changeTab(2);
            $scope.flag.submit = false;

            return false;
          }

          options_table.forEach(row => {
            // 옵션 테이블에서 옵션 정보 추출
            if (!row.name) {
              return false;
            }
            if (!opt[row.name]) {
              opt[row.name] = [];
            }
            if (row.name && !_.includes(opt_name, row.name)) {
              opt_name.push(row.name);
            }
            if (row.attr && !_.includes(opt[row.name], row.attr)) {
              opt[row.name].push(row.attr);
            }
          });
        }

        // 옵션값 변경시 tagsInput 디렉티브에서 자동으로 갱신됨
        opt_value[0].name = opt_name[0] || '';
        opt_value[1].name = opt_name[1] || '';
        opt_value[2].name = opt_name[2] || '';
        opt_value[0].attr = opt[opt_name[0]] && opt[opt_name[0]].length > 0 ? opt[opt_name[0]].join(',') : '';
        opt_value[1].attr = opt[opt_name[1]] && opt[opt_name[1]].length > 0 ? opt[opt_name[1]].join(',') : '';
        opt_value[2].attr = opt[opt_name[2]] && opt[opt_name[2]].length > 0 ? opt[opt_name[2]].join(',') : '';
        // opt_value[0].unit = row_data.opt_unit1 || '';
        // opt_value[1].unit = row_data.opt_unit2 || '';
        // opt_value[2].unit = row_data.opt_unit3 || '';

      }

      return true;
    };

    /**
   * 옵션추가
   */
    $scope.add_sub_opt = function(type, ob) {
      let maxCount = 10;
      const opt = $scope.prodOpt[`${currentOpt}opt`];

      if (type == 'opt' && currentOpt == '' && $scope.data.opt_type == '독립형') {
        commonSVC.showMessage('실패', '독립형 옵션에 옵션값을 추가 하고자 하는 경우에는 상위 <옵션생성>을 통해 추가해 주셔야 합니다.');

        return false;
      }
      // 옵션추가는 제한없음
      if (type == 'opt') {
        maxCount = 99999;
      }
      if (ob.length < maxCount) {
        ob.push({
          prod_img: '/assets/images/upload.png',
          parent: -1,
          opt_name1: opt[1] ? opt[0].name || opt[0].attributeTypeName : '',
          opt_name2: opt[1] ? opt[1].name || opt[1].attributeTypeName : '',
          opt_name3: opt[2] ? opt[2].name || opt[2].attributeTypeName : '',
          name1: opt[1] ? opt[0].name || opt[0].attributeTypeName : '',
          name2: opt[1] ? opt[1].name || opt[1].attributeTypeName : '',
          name3: opt[2] ? opt[2].name || opt[2].attributeTypeName : '',
          name1_cd: opt[1] ? opt[0].name_cd : '',
          name2_cd: opt[1] ? opt[1].name_cd : '',
          name3_cd: opt[2] ? opt[2].name_cd : '',
          attr1: '',
          attr2: '',
          attr3: '',
          opt_img: null,
          skuCheck: false,
          attr1_cd: 0,
          attr2_cd: 0,
          attr3_cd: 0,
          opt_add_price: 0,
          prod_status: '정상'
        });
      }
    // $scope.reCheckClass.valid('recheck');
    };

    /**
   * 옵션삭제
   */
    $scope.del_sub_opt = function (ob) {
      for (let i = 0; i < ob.length; i++) {
        if (ob[i].checked == true) {
        //대표상품 지정인 경우 해제
          ob.splice(i, 1);
          i = i - 1;
        }
      }
    };

    /**
   * 추가구매 옵션 테이블 생성
   */
    $scope.create_opt = function() {
    //일괄입력 초기화
      $scope.prodOpt.cnt_limit_all = '';
      $scope.checked.batchType = 'sale_cnt';

      $scope.prodOpt.add_opt_table = [];
      for (const i in $scope.prodOpt.add_opt) {
        const opName = $scope.prodOpt.add_opt[i].opName ? $scope.prodOpt.add_opt[i].opName.split(',') : [];
        const opPrice = $scope.prodOpt.add_opt[i].opPrice ? $scope.prodOpt.add_opt[i].opPrice.split(',') : [];

        //추가 옵션명, 추가 금액의 길이가 같지 않는 경우
        if (opName.length != opPrice.length) {
          commonSVC.showMessage('실패', '추가 옵션명과 추가 금액을 맞춰주세요.');

          return;
        }
        for (let j = 0; j < opName.length; j++) {
          const optData = {
            name: $scope.prodOpt.add_opt[i].name,
            opName: opName[j],
            opPrice: Number(opPrice[j]),
            prod_status: '정상'
          };

          if (isNaN(opPrice[j])) {
            commonSVC.showMessage('실패', '추가 금액은 숫자만 입력 해주세요.');
            $scope.prodOpt.add_opt_table = [];

            return;
          }
          if (optData.name) {
            $scope.prodOpt.add_opt_table.push(optData);
          }
        }
      }
    };

    // SKU 중복체크
    $scope.overlabCheck = function (sku, row) {
      if (!sku) {
        commonSVC.showMessage('', 'SKU코드를 입력해주시기 바랍니다.');
      } else {
        productModel.skuOverlabCheck({ code: sku }, function (state, result) {
          if (result.data.result) {
            commonSVC.showMessage('', '이미 등록된 SKU코드입니다.');
            $scope.isCheckingOverlab = false;
            if (row) {
              row.skuCheck = false;
            }
          } else {
            commonSVC.showMessage('', '등록 가능한 SKU코드입니다.');
            $scope.isCheckingOverlab = true;
            if (row) {
              row.skuCheck = true;
            }
          }
        });
      }
    };

    /**
   * 판매자관리코드 중복체크
   */
    $scope.overlapResult = null;
    $scope.adminCodeCheck = async () => {
      if (!$scope.data.c_sale_cd) {
        $scope.alerts = { msg: '판매자관리코드를 입력해 주세요.' };
        $scope.overlapResult = 'blank';

        return false;
      }

      // 사이트정보 가공
      const shop_info = [];

      _.forEach($scope.data.site_list, function(site) {
        shop_info.push(`(b.shop_cd='${site.shop_cd}' and b.shop_id='${site.shop_id}')`);
      });

      const sendParams = {
        new_c_sale_cd: $scope.data.c_sale_cd,
        site_info: shop_info,
        type: 'match'
      };

      await onlineProductModel.checkDuplicateMasterCd(sendParams, function (state, res) {
        if (state === 'success') {
          const { isChangeMasterCode } = res.results;

          $scope.alerts = { msg: isChangeMasterCode ? '사용 가능한 판매자관리코드 입니다.' : '이미 등록되어있는 판매자관리코드 입니다.' };
          $scope.overlapResult = isChangeMasterCode ? 'y' : 'n';
        } else {
          commonSVC.showToaster('error', '실패', '판매자관리코드 중복체크에 실패했습니다.');
        }
      });
    };

    /**
   * 원산지 나라 변경
   */
    $scope.changeCountry = function () {
      const type = $scope.prodData.madein_type === '국내' ? 'local' : 'global';

      const countryList = `${type}MadeinList`, cityList = `${type}MadeinDetailList`; // 중분류, 소분류 리스트 바인딩 변수
      const country = `${type}Group`; // prodData 원산지 중분류  바인딩 변수

      $scope[cityList] = $scope[countryList][$scope.prodData[country]];
    };

    /**
    * 모델명/브랜드/제조사 검색
    */
    $scope.searchModels = function () {
      const resolve = {};

      resolve.data = {
        site_list: angular.copy($scope.data.site_list).reduce((aur, d) => {
          if (d.pa_shop_cd !== 'B394') {
            let vir_vend_code;
            let pumbun_no;
            let category_code;

            const tmp = d.shop_cate_no_add_info && typeof d.shop_cate_no_add_info === 'string' ? JSON.parse(d.shop_cate_no_add_info) : d.shop_cate_no_add_info;

            // 솔루션카테고리에 매칭된 쇼핑몰 카테고리
            if (tmp && tmp.category_code) {
              category_code = tmp.category_code;
              vir_vend_code = tmp.key_vend_code;
              pumbun_no = tmp.key_pumbun_no;
            } else if (d.cate_info && d.cate_info.category_code) {
              category_code = d.cate_info.category_code;
              vir_vend_code = d.cate_info.vend_code;
              pumbun_no = d.cate_info.pumbun_no;
            }
            aur.push({
              shop_cd: d.shop_cd,
              pa_shop_cd: d.pa_shop_cd,
              shop_name: d.shop_name,
              shop_id: d.shop_id,
              shop_pwd: d.shop_pwd,
              etc1: d.etc1 || '',
              etc2: d.etc2 || '',
              etc3: d.etc3 || '',
              etc7: d.etc7 || '',
              domain: d.domain,
              cate_info: {
                vir_vend_code,
                pumbun_no,
                category_code
              }
            });
          }

          return aur;
        }, []),
        esm: $scope.checked.ESM
      };

      const modal = commonSVC.openModal('lg', resolve, 'modelSearchCtrl', 'views/online/product/modals/models_search.html');

      modal.result.then(function (re) {
      //이미 등록된 정보는 삭제
        angular.forEach($scope.onlineOptionsList, function (v) {
          for (const i in re) {
            if (v.shop_cd == re[i].shop_cd) {
              $scope.onlineOptionsList.splice($scope.onlineOptionsList.indexOf(v), 1);
            }
          }
        });
        $scope.onlineOptionsList = $scope.onlineOptionsList.concat(re);
        for (const i in re) {
          const result = re[i];

          angular.forEach($scope.data.site_list, function (row) {
            const set_info = row.set_info;

            if (result.pa_shop_cd === row.pa_shop_cd) {
              _.forEach(models, function (type) {
                row[`${type}_name`] = result[`${type}_value`] || result[type];

                if (row[`${type}_name`]) {
                  row[`search_${type}_yn`] = 1;
                }
              });

              row.BrandSeq = 0;
              row.MakerSeq = 0;
              row.ProductBrandSeq = 0;
              row.selectedModels = true;

              if (['A001', 'A006'].includes(row.pa_shop_cd)) {
                if (result.ProductBrandName) {
                  row.product_brand = re[i].ProductBrandName;
                }
                if (result.CatalogId) {
                  row.CatalogId = result.CatalogId;
                }
                if (re[i].CatalogId) {
                  //ctlgNo -> 11번가 상품 모델명 테이블값이라 주석
                  // row.ctlgNo = result.ctlgNo;
                } else {
                  row.BrandSeq = result.BrandSeq;
                  row.MakerSeq = result.MakerSeq;
                  row.ProductBrandSeq = result.ProductBrandSeq;
                }
              } else if (row.pa_shop_cd == 'A011') {
                set_info.key_brand_code = result.brand_key;
                set_info.key_brand_name = result.brand_name;
              } else if (row.pa_shop_cd == 'A004') {
                set_info.row.key_maker_Cd = result.maker_code;
                set_info.key_brand_Cd = result.brand_code;
              } else if (row.pa_shop_cd == 'A100') {
                set_info.key_brand_Cd = result.brand_key;
                set_info.key_brand_Nm = result.brand;
              } else if (row.pa_shop_cd == 'B616') {
                set_info.key_brand_name = result.brand;
                set_info.key_brand_code = result.brand_code;
                set_info.key_mnf_code = result.maker_code;
                set_info.key_mnf_name = result.maker;
              } else if (row.pa_shop_cd == 'B688') {
                set_info.modelId = result.modelId;
                set_info.modelName = result.model;
                set_info.brandName = result.brand;
                set_info.manufactureName = result.maker;
              } else if (row.pa_shop_cd == 'B691') {
                set_info.key_brand_Cd = result.brand_key;
                set_info.key_brand_Nm = result.brand;
              } else if (row.pa_shop_cd == 'B691' || row.pa_shop_cd == 'B614') {
                set_info.key_brand_Nm = result.brand;
                set_info.key_brand_Cd = result.brand_code;
                set_info.key_maker_Nm = result.maker;
                set_info.key_maker_Cd = result.maker_code;
              } else if (row.pa_shop_cd == 'B719') {
                set_info.key_brand = result.brand;
                set_info.key_brandNo = result.brand_code;
                set_info.key_maker = result.maker;
                set_info.key_makerNo = result.maker_code;
              } else if (['B877', 'B594', 'A064', 'B878', 'B687'].includes(row.pa_shop_cd)) {
                set_info.key_brand = result.brand;
                set_info.key_brandCode = result.brand_code;
              } else if (row.pa_shop_cd == 'A524') {
                set_info.key_brand = result.brand;
                set_info.key_brand_no = result.brand_key;
              } else if (row.pa_shop_cd === 'A017') {
                set_info.key_brand = {
                  code: result.brand_code,
                  name: result.brand,
                };
              } else {
                const { model_search_avail_yn, brand_search_avail_yn, maker_search_avail_yn } = siteInfo[row.pa_shop_cd];
                // 모델 지원
                if (model_search_avail_yn) {
                  set_info.model = {
                    code: result.model_code,
                    name: result.model
                  };
                }
                // 브랜드 지원
                if (brand_search_avail_yn) {
                  set_info.brand = {
                    code: result.brand_code,
                    name: result.brand
                  };
                }
                // 제조사
                if (maker_search_avail_yn) {
                  set_info.manufacturer = {
                    code: result.maker_code,
                    name: result.maker
                  };
                }
              }
            } // END if
          });

        }
        // GS 브랜드키 수정이벤트
        $rootScope.$broadcast('infoEdit');
      });
    };

    /**
   * 쇼핑몰별 이미지 설정
   * @type {boolean}
   */
    $scope.centerAnchor = true;
    $scope.toggleCenterAnchor = function () {
      $scope.centerAnchor = !$scope.centerAnchor;
    };

    /**
     * 기본이미지를 서브 이미지로 불러오도록 처리
     */
    $scope.subImgSet = function (row) {
      if (row.img_ck) {
        for (const i in row.sub_img_objects) {
          if ($scope.data.img_objects[i] && $scope.data.img_objects[i].files && $scope.data.img_objects[i].files != '') {
            row.sub_img_objects[i].files = $scope.data.img_objects[i].files;
            row.sub_img_objects[i].sub_img_url = $scope.data.img_objects[i].files;
          }
        }
      }
    };

    // 상세설명 이미지 업로드
    $scope.detailImageUpload = async (file, row) => {
      if (!file) {
        return;
      }

      const { data: { results: [{ url }] } } = await commonModel.tempUpload([file]);

      row.desc_img_url = url;
    };

    $scope.$watch('data.c_sale_cd', function () {
      $scope.overlapResult = null;
    });

    /**
     * 2017-12-13 Pada
     * 쇼핑몰별 개별 이미지 등록을 위한 S3 temp 업로드 작업
     */
    $scope.uploadFiles = function (files, name, img, site) {
      let start, end;
      const url_results = [];
      commonSVC.resizingImg(files, systemList.data.img_resize)
        .then(function(files) {
          let i = 0;

          start = Number(img.code.substr(8, 9));
          end = files.length + start;
          if (files && files.length > 0) {
            if (name != 'default') {
              for (i = start; i < end; i++) {
                if (i < site.sub_img_objects.length) {
                  site.sub_img_objects[i].files = files[i - start];
                }
              }
              // site.sub_img_objects[start].files = files[0];
            } else {
              //기본이미지 설정인 경우 대표상품으로 매칭된 이미지 제거
              if (site == 0) {
                $scope.data.matchImg = '';
              }
              for (i = start; i < end; i++) {
                if (i < $scope.data.img_objects.length) {
                  $scope.data.img_objects[i].files = files[i - start];
                }
              }
            }
          } else {
            for (i = start; i < end; i++) {
              if (i < $scope.data.img_objects.length) {
                $scope.data.img_objects[i].files = files[i - start];
              }
            }
          }

          const re_files = [];

          if (name != 'default') {
            _.forEach(site.sub_img_objects, function(v) {
              if (typeof v.files == 'string' && v.files) {
                // url_results.push(v.files);
              } else {
                re_files.push(v.files);
              }
            });
          } else {
            for (i = start; i < end; i++) {
              if (i < $scope.data.img_objects.length) {
                re_files.push($scope.data.img_objects[i].files);
              }
            }
          }

          return commonModel.tempUpload(re_files);
        })
        .then(function (res) {
          const results = res.data.results;
          let i = 0;

          for (i = 0; i < results.length; i++) {
            if (results[i]) {
              url_results.push(results[i].url);
            }
          }

          if (name != 'default') {
            // var j = 0;

            for (i = 0; i < url_results.length; i++) {
              if (url_results[i]) {
                site.sub_img_objects[start + i].files = url_results[i];
                site.sub_img_objects[start + i].sub_img_url = url_results[i];
              }
            }
          } else if (name == 'default') {
            for (i = 0; i < url_results.length; i++) {
              if (url_results[i]) {
                $scope.data.img_objects[start + i].files = url_results[i];
                $scope.data.img_objects[start + i].sub_img_url = url_results[i];
              }
            }
          }

          // if (name == 'default' && !site) {
          //   $scope.data.img_objects[0].files = results[0].url;
          //   $scope.data.img_objects[0].sub_img_url = results[0].url;
          // } else if (name == 'default') {
          //   $scope.data.img_objects[site].files = results[0].url;
          //   $scope.data.img_objects[site].sub_img_url = results[0].url;
          // }
        });
    };
    // cardview, list, mobile, width, exi
    $scope.uploadFile = function (files, name, img, site) {
      // 이미지가 없었을 때 에러메세지 처리
      if (_.isEmpty(files)) {
        uploadImageErrMsg(name);

        return false;
      }

      // # S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              name.includes('_url') ? site[name] = v.url : site[name][`${name}_url`] = v.url;
              _.forEach(site.sub_img_box, function (item, idx) {
                if (item.en_name === name) {
                  item.files = v.url;
                }
              });
            }
          });
        });
    };

    /**
     * 2019-09-03 liam
     * @param {string} fileType - 업로드 파일 유형
     */
    function uploadImageErrMsg (fileType) {
      switch (fileType) {
        case 'mobile_detail_img': {
          commonSVC.showMessage('1.5MB 미만의 jpeg, jpg 확장자만 등록 가능합니다.');
        }
      }
    }

    $scope.prodListSet = function() {

      const prodOpt = $scope.prodOpt;
      let all_sku_list = [];

      _.forEach(site_tab, function(type) {
        if (type === 'rocket_') {
          const optList = $scope.data[`${type}opt_type`] === '옵션없음' ? prodOpt[`${type}matchStockList`] : prodOpt[`${type}options_table`];

          let seq = 1;

          const skuList = ['coupang', 'rocket'].flatMap(shop => {
            return optList.flatMap((opt) => {
              const optData = _.omit({ ...Object.assign({}, opt, opt[shop]), deal_type: shop === 'coupang' ? '기초상품' : '3자배송' }, ['coupang', 'rocket']);

              if (
                ($scope.data.checkGrowth.coupang && optData.deal_type === '기초상품') ||
                ($scope.data.checkGrowth.rocket && optData.deal_type === '3자배송')
              ) {
                optData.sale_opt_seq = seq++;

                return optData;
              }
            });
          }).filter(Boolean);

          all_sku_list = _.concat(all_sku_list, skuList);
        } else {
          if ($scope.data[`${type}opt_type`] == '옵션없음') {
            all_sku_list = _.concat(all_sku_list, prodOpt[`${type}matchStockList`]);
          } else {
            all_sku_list = _.concat(all_sku_list, prodOpt[`${type}options_table`]);
          }
        }
      });

      const sku_list = _(all_sku_list)
        .filter(function (f_row) {
          return f_row && f_row.sku_cd && f_row.sku_cd != '';
        })
        .uniqBy('sku_cd')
        .value();

      $scope.sku_list = sku_list;
    };

    $scope.changeCostPrice = function() {
      $scope.$broadcast('changeCostPrice', { cost: $scope.data.prodall.cost_price });
    };

    /**
     * 이미지파일 원본보기 모달.
     * @param {이미지파일} img
     */
    $scope.imgModal = function (img) {
      const resolve = {};

      resolve.data = {
        file: typeof img === 'object' ? img : { files: img }
      };
      const modal = commonSVC.openModal('lg', resolve, 'olImgModal', 'views/online/product/olProdImg.html');

      modal.result.then(function() {
      });
    };

    /**
     * 쇼핑몰별 이미지 삭제
     */
    $scope.delShopImg = function (sub_img_objects, sub_img_name, idx, index, row) {
      if (idx == 'default') {
        index = Number(sub_img_name.code.substr(8, 9));
        $scope.data.img_objects[index].files = '';
      } else if (idx === 'shop') {
        sub_img_objects[index] = {
          name: sub_img_objects[index].name,
          code: sub_img_objects[index].code,
          files: '',
          old_files: sub_img_objects[index].old_files
        };
      } else if (idx == 'expand1' || idx == 'expand2') {
        let number = '';

        if (idx == 'expand1') {
          number = '1';
        } else {
          number = '2';
        }
        idx = 'expand';
        index[`${idx}_img${number}`] = {
          code: sub_img_objects.code,
          files: '',
          name: sub_img_objects.name,
          old_files: sub_img_objects.old_files,
        };
        index[`${idx}_img${number}`][`${idx + number}_url`] = '';
      } else if (idx === 'description') {
        index[sub_img_name] = '';
        index.files = '';
      } else {
        // 쇼핑몰 별 추가 이미지 처리
        if (index[sub_img_name]) {
          index[sub_img_name].files = '';
          index[sub_img_name][`${sub_img_name}_url`] = '';
          _.forEach(index.sub_img_box, function (item, idx) {
            if (item.en_name === sub_img_name) {
              item.files = '';
            }
          });
        } else {
          index[`${idx}_img`] = {
            code: sub_img_objects.code,
            files: '',
            name: sub_img_objects.name,
            old_files: sub_img_objects.old_files,
          };
          index[`${idx}_img`][`${idx}_url`] = '';
        }

      }
    };
    /**
   * hscode 검색
   **/
    $scope.searchHscode = function () {
      const resolve = {};

      resolve.data = {
        name: $scope.data.hscd
      };
      const redata = commonSVC.openModal('lg', resolve, 'CategorySearchHscodeCtrl', 'views/settings/category/searchHscode.html');

      redata.result.then(function (re) {
        $scope.data.hscd = re;
      });
    };

    /**
   * 옵션탭 변경
   */
    $scope.changeOptTab = function(tab) {
      $scope.opTabActive = tab;
      currentOpt = site_tab[tab];
      $scope.flag.esmOpt = false;
      $scope.prodOpt.cnt_limit_all = '';
      $scope.checked.batchType = 'sale_cnt';
    };

    /**
     * SKU상품 매칭시 대표상품 변경
     **/
    $scope.prodAutoSet = function () {

      const ob = _.find($scope.sku_list, function(row) { return row.prod_no == $scope.data.main_prod_no; });

      if (!$scope.data.main_prod_no) {
        $scope.data.main_prod_no = '';

        return false;
      } else if (!ob) {
        return false;
      }

      const optConfirm = commonSVC.showConfirm('주의', '대표 SKU상품 변경 시 해당 SKU상품의 정보로 일부 항목이 자동 변경됩니다. (환경설정 대표 SKU상품정보 자동세팅항목 참고) 변경하시겠습니까?');

      optConfirm
        .then(function(confirm) {
          if (confirm) {

            $scope.modelProdMatch.checked = false;

            //제조일자 유효일자 매칭
            if (!ob.made_date || ob.made_date == '0000-00-00') {
              $scope.data.made_date = '';
            } else {
              $scope.data.made_date = ob.made_date;
            }

            if (!ob.expire_date || ob.expire_date == '0000-00-00') {
              $scope.data.expiration_month = '52';
              $scope.data.expire_date = '';
            } else {
              $scope.data.expiration_month = '';
              $scope.data.expire_date = ob.expire_date;
            }

            //대표상품과 동일하게 원산지 자동매칭
            if (ob.madein_etc != 'null' && ob.madein_etc) {
              $scope.data.madein_type = '기타';
              $scope.data.etcMadein = ob.madein_etc;
            } else {
              $scope.data.madein_type = ob.madein_type;
              $scope.madein_type_change();
              $scope.data.madein_group = ob.madein_group;
              $scope.madein_country_change();
              $scope.data.madein_name = ob.madein_name;
            }
            $scope.data.multi_madein_yn = ob.multi_madein_yn;

            // 세트 원가, 공급가가 설정된 경우.
            if (ob.set_prod_list?.length) {
              const mainProd = ob.set_prod_list.find((item) => item.prod_no.toString() === $scope.data.main_prod_no);

              $scope.data.prodall.cost_price = mainProd?.set_cost_price || mainProd.cost_price;
              $scope.data.prodall.supply_price = mainProd?.set_supply_price || mainProd.supply_price;
            } else {
              //공급가
              $scope.data.prodall.supply_price = ob.supply_price || 0;

              //원가
              $scope.data.prodall.cost_price = ob.cost_price || 0;
            }

            //과세여부 매칭 - sku는 과세가 일반,간이로 분리돼서 해당 처리 추가
            $scope.data.tax_type = '과세';

            if (ob.tax_type && !['일반과세', '간이과세'].includes(ob.tax_type)) {
              $scope.data.tax_type = ob.tax_type;
            }

            //ISBN코드
            $scope.data.isbn = ob.isbn ? ob.isbn : '';

            // HS코드
            $scope.data.hscd = ob.hscd ? ob.hscd : '';

            // 모델번호
            $scope.data.prodall.model_no = $scope.viewModelNo && ob.set_no ? ob.prod_name : ob.model_no;
            // 읽어오는 상품 초기화
            $scope.leadProdInfoModelno = {};

            //바코드
            if (ob.barcode != 'null') {
              $scope.data.barcode = ob.barcode;
            } else {
              $scope.data.barcode = '';
            }

            $scope.data.global_barcode = ob.global_barcode;
            $scope.data.global_barcode_type = ob.global_barcode_type;

            //대표 SKU상품 정보 자동세팅
            //해제:공급가, 원산지, 과세여부, 바코드, UPC/EAN코드, HS코드, ISBN코드
            //설정시 브랜드, 모델명, 제조사, 대표 이미지, 상세설명, 상품정보제공고시 추가 설정
            if ($scope.prod_auto_set_yn) {

              //대표상품 이미지 지정
              if (ob.prod_img && ob.prod_img !== '/assets/images/upload.png') {
                $scope.data.img_objects[0].files = ob.prod_img;
                $scope.data.matchImg = ob.prod_img;
              }

              //대표상품과 동일하게 모델명,브랜드,제조사 매칭
              $scope.data.prodall.market.model_name = ob.model;
              $scope.data.prodall.market.brand_name = ob.brand;
              $scope.data.prodall.market.maker_name = ob.maker;

              //상세설명
              $scope.data.detail_desc = ob.contents;

              // 헤더 푸터 템플릿
              if (ob.mas_addcontent_no) {
                $scope.data.mas_addcontent_no = ob.mas_addcontent_no;
                $scope.data.mas_addcontent_name = ob.mas_addcontent_name;
                $scope.data.mas_footer_content = ob.mas_footer_content;
                $scope.data.mas_header_content = ob.mas_header_content;
                $scope.data.sub_off_avail_yn = ob.sub_off_avail_yn;
                $scope.renderHtml('mas_header_content');
                $scope.renderHtml('mas_footer_content');
              } else {
                $scope.addcontentDelete();
              }

              //상품상세정보고시
              if (ob) {
                // 신 상품정보제공고시 처리
                if (ob.prod_noti_data && ob.prod_noti_data.infoCode.includes('2023')) {
                  ob.prod_noti_data_new = ob.prod_noti_data;
                  delete ob.prod_noti_data;
                }

                if (ob.prod_noti_data_new != '{}' && ob.prod_noti_data_new) {
                  prod_info_open_type = 'edit';
                  const prod_info = ob.prod_noti_data_new;
                  const prod_noti_data_new = {};

                  if (_.size(prod_info)) {
                    const filter = _.filter(onlineProductSVC.newTypes, { code: prod_info.infoCode });

                    $scope.data.prod_info_opt = filter[0] ? filter[0].name : '';
                  }
                  prod_noti_data_new.infoName = $scope.data.prod_info_opt;
                  prod_noti_data_new.infoDetail = prod_info.infoDetail;
                  prod_noti_data_new.infoCode = prod_info.infoCode;
                  $scope.data.prod_noti_data_new = [prod_noti_data_new, {}, {}];
                  temp_list = angular.copy($scope.data.prod_noti_data_new);
                } else {
                  $scope.data.prod_info_opt = '';
                  $scope.data.prod_noti_data_new = [{}, {}, {}];
                  temp_list = [];
                }
              }
            }
          }

        });
    };

    /**
     * 모델번호에서 대표상품 설정을 하는경우
     */
    $scope.prodAutoSetForModelno = async () => {
      if (!Object.keys($scope.leadProdInfoModelno).length || $scope.modelProdMatch.checked) {
        if ($scope.modelProdMatch.checked) {
          $scope.data.model_prod_no = '';
        }

        return false;
      }

      const optConfirm = await commonSVC.showConfirm('주의', '대표 SKU상품 변경 시 해당 SKU상품의 정보로 일부 항목이 자동 변경됩니다. (환경설정 대표 SKU상품정보 자동세팅항목 참고) 변경하시겠습니까?');

      if (optConfirm) {
        $scope.data.main_prod_no = '';
        $scope.data.model_prod_no = $scope.leadProdInfoModelno.prod_no;

        //제조일자 유효일자 매칭
        if (!$scope.leadProdInfoModelno.made_date || $scope.leadProdInfoModelno.made_date == '0000-00-00') {
          $scope.data.made_date = '';
        } else {
          $scope.data.made_date = $scope.leadProdInfoModelno.made_date;
        }

        if (!$scope.leadProdInfoModelno.expire_date || $scope.leadProdInfoModelno.expire_date == '0000-00-00') {
          $scope.data.expiration_month = '52';
          $scope.data.expire_date = '';
        } else {
          $scope.data.expiration_month = '';
          $scope.data.expire_date = $scope.leadProdInfoModelno.expire_date;
        }

        //대표상품과 동일하게 원산지 자동매칭
        if ($scope.leadProdInfoModelno.madein_etc != 'null' && $scope.leadProdInfoModelno.madein_etc) {
          $scope.data.madein_type = '기타';
          $scope.data.etcMadein = $scope.leadProdInfoModelno.madein_etc;
        } else {
          $scope.data.madein_type = $scope.leadProdInfoModelno.madein_type;
          $scope.madein_type_change();
          $scope.data.madein_group = $scope.leadProdInfoModelno.madein_group;
          $scope.madein_country_change();
          $scope.data.madein_name = $scope.leadProdInfoModelno.madein_name;
        }
        $scope.data.multi_madein_yn = $scope.leadProdInfoModelno.multi_madein_yn;

        // 세트 원가, 공급가가 설정된 경우.
        if ($scope.leadProdInfoModelno.set_prod_list?.length) {
          const mainProd = $scope.leadProdInfoModelno.set_prod_list.find((item) => item.prod_no == $scope.data.main_prod_no);

          $scope.data.prodall.cost_price = mainProd?.set_cost_price || mainProd.cost_price;
          $scope.data.prodall.supply_price = mainProd?.set_supply_price || mainProd.supply_price;
        } else {
          //공급가
          $scope.data.prodall.supply_price = $scope.leadProdInfoModelno.supply_price || 0;

          //원가
          $scope.data.prodall.cost_price = $scope.leadProdInfoModelno.cost_price || 0;
        }

        //과세여부 매칭 - sku는 과세가 일반,간이로 분리돼서 해당 처리 추가
        $scope.data.tax_type = '과세';

        if ($scope.leadProdInfoModelno.tax_type && !['일반과세', '간이과세'].includes($scope.leadProdInfoModelno.tax_type)) {
          $scope.data.tax_type = $scope.leadProdInfoModelno.tax_type;
        }

        //ISBN코드
        $scope.data.isbn = $scope.leadProdInfoModelno.isbn ? $scope.leadProdInfoModelno.isbn : '';

        // HS코드
        $scope.data.hscd = $scope.leadProdInfoModelno.hscd ? $scope.leadProdInfoModelno.hscd : '';

        //바코드
        if ($scope.leadProdInfoModelno.barcode != 'null') {
          $scope.data.barcode = $scope.leadProdInfoModelno.barcode;
        } else {
          $scope.data.barcode = '';
        }

        $scope.data.global_barcode = $scope.leadProdInfoModelno.global_barcode;
        $scope.data.global_barcode_type = $scope.leadProdInfoModelno.global_barcode_type;

        // 모델번호
        $scope.data.prodall.model_no = $scope.leadProdInfoModelno.model_no;

        //대표 SKU상품 정보 자동세팅
        //해제:공급가, 원산지, 과세여부, 바코드, UPC/EAN코드, HS코드, ISBN코드
        //설정시 브랜드, 모델명, 제조사, 대표 이미지, 상세설명, 상품정보제공고시 추가 설정
        if ($scope.prod_auto_set_yn) {

          //대표상품 이미지 지정
          if ($scope.leadProdInfoModelno.prod_img && $scope.leadProdInfoModelno.prod_img !== '/assets/images/upload.png') {
            $scope.data.img_objects[0].files = $scope.leadProdInfoModelno.prod_img;
            $scope.data.matchImg = $scope.leadProdInfoModelno.prod_img;
          }

          //대표상품과 동일하게 모델명,브랜드,제조사 매칭
          $scope.data.prodall.market.model_name = $scope.leadProdInfoModelno.model;
          $scope.data.prodall.market.brand_name = $scope.leadProdInfoModelno.brand;
          $scope.data.prodall.market.maker_name = $scope.leadProdInfoModelno.maker;

          //상세설명
          $scope.data.detail_desc = $scope.leadProdInfoModelno.contents;

          // 헤더 푸터 템플릿
          if ($scope.leadProdInfoModelno.mas_addcontent_no) {
            $scope.data.mas_addcontent_no = $scope.leadProdInfoModelno.mas_addcontent_no;
            $scope.data.mas_addcontent_name = $scope.leadProdInfoModelno.addcontent_name;
            $scope.data.mas_header_content = $scope.leadProdInfoModelno.header_content;
            $scope.data.mas_footer_content = $scope.leadProdInfoModelno.footer_content;
            $scope.data.sub_off_avail_yn = $scope.leadProdInfoModelno.sub_off_avail_yn;
            $scope.renderHtml('mas_header_content');
            $scope.renderHtml('mas_footer_content');
          } else {
            $scope.addcontentDelete();
          }

          //상품상세정보고시
          if ($scope.leadProdInfoModelno.prod_noti_data_new != '{}' && $scope.leadProdInfoModelno.prod_noti_data_new) {
            prod_info_open_type = 'edit';
            const prod_info = $scope.leadProdInfoModelno.prod_noti_data_new;
            const prod_noti_data_new = {};

            if ((+prod_info.infoCode > 0 && +prod_info.infoCode < 40) || ['20A', '20B', '20C'].includes(prod_info.infoCode)) {
              return false;
            }

            if (_.size(prod_info)) {
              const filter = _.filter(onlineProductSVC.newTypes, { code: prod_info.infoCode });

              $scope.data.prod_info_opt = filter[0] ? filter[0].name : '';
            }
            prod_noti_data_new.infoName = $scope.data.prod_info_opt;
            prod_noti_data_new.infoDetail = prod_info.infoDetail;
            prod_noti_data_new.infoCode = prod_info.infoCode;
            $scope.data.prod_noti_data_new = [prod_noti_data_new, {}, {}];
            temp_list = angular.copy($scope.data.prod_noti_data_new);
          } else {
            $scope.data.prod_info_opt = '';
            $scope.data.prod_noti_data_new = [{}, {}, {}];
            temp_list = [];
          }
        }
      } else {
        $scope.modelProdMatch.checked = false;
        $timeout();
      }
    };

    /**
   * 입력한 상품, 옵션들 사이트별 등록 상품 리스트 취합
   */
    function makeProdList() {
      const prodList = [];          // 일반옵션 옵션상품 리스트
      const esmProdList = [];       // esm2.0, 11번가 옵션상품 리스트
      const cpangProdList = [];     // 쿠팡 옵션상품 리스트
      const rocketProdList = [];     // 쿠팡 로켓그로스 옵션상품 리스트
      const wmpProdList = [];       // 위메프 옵션상품 리스트
      const hplusProdList = [];     // 홈플러스 옵션상품 리스트
      const galleriaProdList = [];  // 갤러리아몰 옵션상품 리스트
      const aliexpressProdList = [];       // 알리 익스프레스 옵션상품 리스트
      const alibabaProdList = [];       // 알리바바 옵션상품 리스트
      const naversingleProdList = [];       // 스마트스토어 단일상품 옵션상품 리스트
      const aliexpressGlobalProdList = []; // 알리익스프레스 글로벌 옵션상품 리스트
      const temuProdList = [];       // 테무 단일상품 옵션상품 리스트
      const addOptList = [];        // 추가옵션 상품 리스트
      const stdProdList = [];
      // const lotte_onProdList = [];
      const naverProdList = [];
      // var sale_opt_seq = 1;

      //일반 옵션상품 리스트 정리
      if ($scope.data.defaultOpTab) {
        setProductOptions($scope.data.opt_type, $scope.prodOpt.matchStockList, $scope.prodOpt.options_table, prodList, prodList);
      }
      //ESM 옵션상품 리스트 생성
      if ($scope.checked.ESM) {
        setProductOptions($scope.data.esm_opt_type, $scope.prodOpt.esm_matchStockList, $scope.prodOpt.esm_options_table, esmProdList, esmProdList);
      }
			 //11번가 옵션상품 리스트 생성
			 if ($scope.checked.A112) {
        setProductOptions($scope.data.std_opt_type, $scope.prodOpt.std_matchStockList, $scope.prodOpt.std_options_table, stdProdList, stdProdList);
      }
      //쿠팡 옵션상품 리스트 생성
      if ($scope.disabled.CPANG) {
        setProductOptions($scope.data.cpang_opt_type, $scope.prodOpt.cpang_matchStockList, $scope.prodOpt.cpang_options_table, cpangProdList, cpangProdList);
      }
      //쿠팡그로스 옵션상품 리스트 생성
      if ($scope.disabled.ROCKET) {
        $scope.prodOpt.rocket_matchStockList.forEach((opt) => {
          opt.coupang.deal_type = '기초상품';
          opt.rocket.deal_type = '3자배송';
        });

        $scope.prodOpt.rocket_options_table.forEach((opt) => {
          opt.coupang.deal_type = '기초상품';
          opt.rocket.deal_type = '3자배송';
        });

        setProductOptions($scope.data.rocket_opt_type, $scope.prodOpt.rocket_matchStockList, $scope.prodOpt.rocket_options_table, rocketProdList, rocketProdList);
      }
      //위메프 옵션상품 리스트 생성
      if ($scope.checked.WMP) {
        setProductOptions($scope.data.wmp_opt_type, $scope.prodOpt.wmp_matchStockList, $scope.prodOpt.wmp_options_table, wmpProdList, wmpProdList);
      }
      if ($scope.checked.GALLERIA) {
        setProductOptions($scope.data.galleria_opt_type, $scope.prodOpt.galleria_matchStockList, $scope.prodOpt.galleria_options_table, galleriaProdList, galleriaProdList);
      }
      if ($scope.checked.HPLUS) {
        setProductOptions($scope.data.hplus_opt_type, $scope.prodOpt.hplus_matchStockList, $scope.prodOpt.hplus_options_table, hplusProdList, hplusProdList);
      }
      if ($scope.checked.NAVER) {
        setProductOptions($scope.data.naver_opt_type, $scope.prodOpt.naver_matchStockList, $scope.prodOpt.naver_options_table, naverProdList, naverProdList);
      }

      if ($scope.checked.ALIEXPRESS) {
        setProductOptions($scope.data.aliexpress_opt_type, $scope.prodOpt.aliexpress_matchStockList, $scope.prodOpt.aliexpress_options_table, aliexpressProdList, aliexpressProdList);
      }
      if ($scope.checked.ALIBABA) {
        setProductOptions($scope.data.alibaba_opt_type, $scope.prodOpt.alibaba_matchStockList, $scope.prodOpt.alibaba_options_table, alibabaProdList, alibabaProdList);
      }
      if ($scope.checked.A077single) {
        setProductOptions($scope.data.naver_single_opt_type, $scope.prodOpt.naver_single_matchStockList, $scope.prodOpt.naver_single_options_table, naversingleProdList, naversingleProdList);
      }
      if ($scope.checked.ALIEXPRESSGLOBAL) {
        setProductOptions($scope.data.aliexpress_global_opt_type, $scope.prodOpt.aliexpress_global_matchStockList, $scope.prodOpt.aliexpress_global_options_table, aliexpressGlobalProdList, aliexpressGlobalProdList);
      }
      if ($scope.checked.TEMU) {
        setProductOptions($scope.data.temu_opt_type, $scope.prodOpt.temu_matchStockList, $scope.prodOpt.temu_options_table, temuProdList, temuProdList);
      }

      //추가구매 옵션 리스트 생성
      for (const j in $scope.prodOpt.add_opt_table) {
        const prod = $scope.prodOpt.add_opt_table[j];

        prod.opt_add_price = $scope.prodOpt.add_opt_table[j].opPrice;
        prod.add_item_name = $scope.prodOpt.add_opt_table[j].name;
        prod.add_item_opt_name = $scope.prodOpt.add_opt_table[j].opName;
        addOptList.push(prod);
      }

      // $scope.prodOpt.esm_opt = $scope.prodOpt.esm_opt.filter(o => o.checked);
      // $scope.prodOpt.std_opt = $scope.prodOpt.std_opt.filter(o => o.checked);

      $scope.data.opt = $scope.prodOpt;
      // $scope.prodOpt.esm_opt = $scope.prodOpt.esm_checked_opt;
      // $scope.prodOpt.std_opt = $scope.prodOpt.std_checked_opt;

      /**************************
     * 쇼핑몰별 등록될 상품옵션 정리 *
     **************************/
      const checkedSite = (site, optType, stdOlYn, optionProdList, setOptParmas) => {
        site.opt_type = optType;
        site.std_ol_yn = stdOlYn;
        site.options.singleProdList = site.options.styleProdList = [];
        site.options.prodList = optionProdList;
        setOpt(...setOptParmas);
      };

      //모든 쇼핑몰 일반옵션으로 우선 정리
      _.each($scope.data.site_list, function (site, i) {
        site.adminAuto = $scope.data.adminAuto;
        site.c_sale_cd = $scope.data.c_sale_cd;
        site.std_ol_yn = 0;
        site.opt_type = $scope.data.opt_type;
        site.options.singleProdList = [];
        site.options.prodList = prodList;
        site.options.addOptList = addOptList;
        setOpt(i, $scope.prodOpt.opt, $scope.prodOpt.options_table, $scope.data.opt_type);

        // 롯데슈퍼는 단일상품 + 옵션없음 으로 등록.
        if (site.pa_shop_cd === 'P020') {
          site.std_ol_yn = 1;
          site.opt_type = '옵션없음';
          site.options.prodList = prodList;
          site.options.addOptList = [];
          setOpt(i, $scope.prodOpt.opt, [], '옵션없음');
        }

        if ($scope.checked.ESM && (site.pa_shop_cd == 'A001' || site.pa_shop_cd == 'A006')) {
          checkedSite(site, $scope.data.esm_opt_type, $scope.checked.smileProd ? 2 : 1, esmProdList, [i, $scope.prodOpt.esm_opt, 'name_cd']);
        }

        if ($scope.checked.A112 && site.pa_shop_cd == 'A112') {
          checkedSite(site, $scope.data.std_opt_type, 1, stdProdList, [i, _.filter($scope.prodOpt.std_opt, 'checked')]);
        }

        if ($scope.disabled.CPANG && site.pa_shop_cd == 'B378') {
          checkedSite(site, $scope.data.cpang_opt_type, 0, cpangProdList, [i, $scope.prodOpt.cpang_opt]);
        }

        if ($scope.disabled.ROCKET && site.pa_shop_cd == 'B378') {
          checkedSite(site, $scope.data.rocket_opt_type, 0, rocketProdList, [i, $scope.prodOpt.rocket_opt]);
        }

        if ($scope.checked.WMP && site.pa_shop_cd == 'B719') {
          checkedSite(site, $scope.data.wmp_opt_type, 1, wmpProdList, [i, $scope.prodOpt.wmp_opt]);
        }

        if ($scope.checked.HPLUS && site.pa_shop_cd === 'B502') {
          checkedSite(site, $scope.data.hplus_opt_type, 1, hplusProdList, [i, $scope.prodOpt.hplus_opt]);
        }

        if ($scope.checked.GALLERIA && site.pa_shop_cd === 'B394') {
          checkedSite(site, $scope.data.galleria_opt_type, 0, galleriaProdList, [i, $scope.prodOpt.galleria_opt]);
        }

        if ($scope.checked.NAVER && site.pa_shop_cd === 'A077' && $scope.data.naver_opt_type !== '옵션없음') {
          site.set_info.is_standard_option_category = 'Y'; // 간편옵션 사용유무 값
          // 테이블에 생성 후 사이즈 리스트 변경 시 사이즈 코드가 다르게 들어가는 문제 수정
          $scope.prodOpt.naver_opt[1].name_cd = $scope.prodOpt.naver_options_table[0].name2_cd;

          checkedSite(site, $scope.data.naver_opt_type, 0, naverProdList, [i, $scope.prodOpt.naver_opt, 'name_cd']);
        }

        if ($scope.checked.ALIBABA && site.pa_shop_cd === 'A092') {
          checkedSite(site, $scope.data.alibaba_opt_type, 1, alibabaProdList, [i, $scope.prodOpt.alibaba_opt, 'name_cd']);
        }

        if ($scope.checked.ALIEXPRESS && site.pa_shop_cd === 'A093') {
          checkedSite(site, $scope.data.aliexpress_opt_type, 1, aliexpressProdList, [i, $scope.prodOpt.aliexpress_opt, 'name_cd']);
        }
        if ($scope.checked.A077single && site.pa_shop_cd === 'A077') {
          checkedSite(site, $scope.data.naver_single_opt_type, 1, naversingleProdList, [i, $scope.prodOpt.naver_single_opt, 'name_cd']);
        }
        if ($scope.checked.ALIEXPRESSGLOBAL && site.pa_shop_cd === 'X094') {
          checkedSite(site, $scope.data.aliexpress_global_opt_type, 1, aliexpressGlobalProdList, [i, $scope.prodOpt.aliexpress_global_opt, 'name_cd']);
        }
        if ($scope.checked.TEMU && site.pa_shop_cd === 'A098') {
          checkedSite(site, $scope.data.temu_opt_type, 1, temuProdList, [i, $scope.prodOpt.temu_opt, 'name_cd']);
        }
      });

      /**************************
     * 쇼핑몰별 등록될 상품옵션 정리끝 *
     **************************/

      $scope.data.input_opt_name = '';
      const opt_info = [];

      if ($scope.data.insert_opt) {
        //입력형 옵션
        for (const i in $scope.data.insert_opt_arr) {
          if ($scope.data.insert_opt_arr[i].insert_opt != '') {
            const data = { insert_opt: $scope.data.insert_opt_arr[i].insert_opt };

            opt_info.push(data);
          }
        }
      }

      const esm_info = [];

      if ($scope.data.esm_insert_opt) {
        //esm 입력형 옵션
        _.each($scope.prodOpt.esm_input_name, function(row) {
          if (row) {
            const data = { insert_opt: row };

            esm_info.push(data);
          }
        });
      }

      const std_info = [];

      if ($scope.data.std_insert_opt) {
        _.each($scope.prodOpt.std_input_name, function(row) {
          if (row.insert_opt) {
            const data = { insert_opt: row.insert_opt };

            std_info.push(data);
          }
        });
      }

      // var cpang_info = [];

      // _.each($scope.prodOpt.cpang_insert_opt_arr, function(row) {
      //   if (row.insert_opt) {
      //     var data = { insert_opt: row.insert_opt };

      //     cpang_info.push(data);
      //   }
      // });

      //위메프 입력형 옵션
      const wmp_info = [];

      if ($scope.data.wmp_insert_opt) {
        _.each($scope.prodOpt.wmp_insert_opt_arr, function(row) {
          if (row.insert_opt) {
            const data = { insert_opt: row.insert_opt };

            wmp_info.push(data);
          }
        });
      }

      // 홈플러스 입력형 옵션
      const hplus_info = [];

      if ($scope.data.hplus_insert_opt) {
        _.each($scope.prodOpt.hplus_insert_opt_arr, function(row) {
          if (row.insert_opt) {
            const data = { insert_opt: row.insert_opt };

            hplus_info.push(data);
          }
        });
      }

      $scope.data.esm_opt_name = esm_info.length ? JSON.stringify(esm_info) : '';
      $scope.data.std_opt_name = std_info.length ? JSON.stringify(std_info) : '';
      $scope.data.wmp_opt_name = wmp_info.length ? JSON.stringify(wmp_info) : '';
      $scope.data.hplus_opt_name = hplus_info.length ? JSON.stringify(hplus_info) : '';
      $scope.data.input_opt_name = opt_info.length ? JSON.stringify(opt_info) : '';
      $scope.data.checked = $scope.checked;

      //마스터 상품 정리
      let master_prod = _.find($scope.data.site_list, function(site) {
        if (site.std_ol_yn == 0 && site.pa_shop_cd != 'B378') {
          return site;
        }
      });

      if (!master_prod) {
        master_prod = _.find($scope.data.site_list, function(site) {
          if (site.std_ol_yn == 1) {
            return site;
          }
        });
      }
      if (!master_prod) {
        master_prod = $scope.data.site_list[0];
      }

      $scope.data.master_prod = _.cloneDeep(master_prod);
      $scope.data.master_prod.shop_sale_name = $scope.data.prodall.name;
      $scope.data.master_prod.sale_price = $scope.data.prodall.sale_price;
      $scope.data.master_prod.prod_stock = $scope.data.prodall.cnt;
      $scope.data.master_prod.supply_price = $scope.data.prodall.supply_price;
      $scope.data.master_prod.detail_desc = $scope.data.detail_desc;

      $scope.data.master_prod.shop_cd = 'Z000';
      $scope.data.master_prod.shop_id = '';
      $scope.data.master_prod.html_editor = '';
    }

    //쇼핑몰별 상품옵션 리스트 정리
    function setProductOptions(opt_type, no_option_list, option_list, no_op_output, op_output) {
      let sale_opt_seq = 1;

      //일반 옵션 - 매칭된 SKU상품 리스트
      if (opt_type == '옵션없음') {
        _.each(no_option_list, function (prod) {
          prod.sale_opt_seq = sale_opt_seq++;
          no_op_output.push(prod);
        });
      } else {
        _.each(option_list, function(row) {
          // if (row.prod_no || $rootScope.user_profile.sol_stock < 1) {
          row.opt_add_price = row.opt_add_price || 0;
          row.pack_unit;
          // row.prod_status = row.prod_status;
          // row.attr1_cd = row.attr1_cd;
          // row.attr2_cd = row.attr2_cd;
          if (opt_type == '조합형') {
            row.opt_name1 = row.attr1 || '';
            row.opt_name2 = row.attr2 || '';
            row.opt_name3 = row.attr3 || '';
          } else {
            row.opt_name1 = row.name || '';
            row.opt_name2 = row.attr || '';
            row.opt_name3 = '';
          }
          row.sale_opt_seq = sale_opt_seq;
          op_output.push(row);
          sale_opt_seq++;
          // }
        });
      }
    }

    //쇼핑몰별 옵션내용 정리
    function setOpt(i, opt, type_name) {
      $scope.data.site_list[i].opt_name1 = opt[0] ? opt[0].name || opt[0].attributeTypeName : '';
      $scope.data.site_list[i].opt_name2 = opt[1] ? opt[1].name || opt[1].attributeTypeName : '';
      $scope.data.site_list[i].opt_name3 = opt[2] ? opt[2].name || opt[2].attributeTypeName : '';
      $scope.data.site_list[i].opt_desc1 = opt[0] ? opt[0].attr : '';
      $scope.data.site_list[i].opt_desc2 = opt[1] ? opt[1].attr : '';
      $scope.data.site_list[i].opt_desc3 = opt[2] ? opt[2].attr : '';
      $scope.data.site_list[i].opt_unit1 = opt[0] ? opt[0].unit || opt[0].stdOpt : '';
      $scope.data.site_list[i].opt_unit2 = opt[1] ? opt[1].unit || opt[1].stdOpt : '';
      $scope.data.site_list[i].opt_unit3 = opt[2] ? opt[2].unit || opt[2].stdOpt : '';
      $scope.data.site_list[i].opt_sort1_cd = opt[0] ? opt[0][type_name] : '';
      $scope.data.site_list[i].opt_sort2_cd = opt[1] ? opt[1][type_name] : '';
      $scope.data.site_list[i].opt_sort3_cd = opt[2] ? opt[2][type_name] : '';
    }

    let inValidTab = [true, true, true, true, true, true, true, true, true, true];

    $scope.validTab = function(idx) {
      return !inValidTab[idx - 1];
    };

    // 등록 시점에 옵션을 덮어쓰기하기 위해 저장하는 변수
    // type: origin, ... 형태로 저장함
    const optOverwriteList = {};

    $scope.optOverwriteCk = function(type, origin) {
      if ((`${type + origin}defaultOpt`) === 'std_esm_defaultOpt' && $scope.data.esm_opt_type !== '옵션없음' && $scope.prodOpt.esm_options_table.length) {
        for (let i = 1; i < 3; i++) {
          const find_opt_name = _.find($scope.prodOpt.std_opt, function(row) {

            return $scope.prodOpt.esm_options_table[0][`name${i}`] == row.name;
          });

          if ($scope.prodOpt.esm_options_table[0][`name${i}`] && !find_opt_name) {
            commonSVC.showMessage('실패', '11번가 신규상품에서 지원하지 않는 옵션명입니다.');
            $scope.checked.std_esm_defaultOpt = false;

            return false;
          }
        }
      }

      let optConfirm = $q.resolve(true);

      if ($scope.checked[`${type + origin}defaultOpt`] && ($scope.prodOpt[`${type}options_table`].length || $scope.prodOpt[`${type}matchStockList`][0].sku_cd)) {

        optConfirm = commonSVC.showConfirm('주의', '해당 탭에 입력된 옵션을 초기화하고 현재 옵션 탭 내용과 동일하게 적용하시겠습니까?');
      } else {
        //일반상품에 적용시 다른쪽 체크박스 해제
        if ((type == '' || 'wmp_') && $scope.checked[`${origin}defaultOpt`]) {
          ['esm_', 'std_', 'cpang_', 'wmp_', 'rocket_'].forEach(d => {
            if (d !== origin) {
              $scope.checked[`${type + d}defaultOpt`] = false;
            }
          });
        }
        //체크박스가 풀릴경우 데이터를 넣어준다.
        optOverwrite(type, origin);
      }

      optConfirm
        .then(function(confirm) {
          if (!confirm) {
            delete optOverwriteList[type];
            $scope.checked[`${type + origin}defaultOpt`] = false;
          } else {
            //일반상품에 적용시 다른쪽 체크박스 해제
            if ((type == '' || 'wmp_') && $scope.checked[`${origin}defaultOpt`]) {
              ['esm_', 'std_', 'cpang_', 'wmp_', 'rocket_'].forEach(d => {
                if (d !== origin) {
                  $scope.checked[`${type + d}defaultOpt`] = false;
                }
              });
            }

            optOverwrite(type, origin);
          }
        });
    };

    //esm 옵션을 일반 옵션으로 덮어쓰기
    function optOverwrite (type, origin, saveFlag = true) {
      if (saveFlag) {
        optOverwriteList[type] = origin;
      }

      $scope.data[`${type}opt_type`] = angular.copy($scope.data[`${origin}opt_type`]);
      //옵션이 없는 경우에도 옵션없음 테이블에 같은 데이터를 넣어줌
      if ($scope.data[`${origin}opt_type`] == '옵션없음') {
        $scope.prodOpt[`${type}matchStockList`] = angular.copy($scope.prodOpt[`${origin}matchStockList`]);

        if (origin === 'rocket_') {
          const optData = $scope.prodOpt[`${origin}matchStockList`].map(opt => {
            return angular.merge({}, opt, opt.coupang || opt.rocket);
          });

          $scope.prodOpt[`${type}matchStockList`] = optData;

          delete $scope.prodOpt[`${type}matchStockList`].coupang;
          delete $scope.prodOpt[`${type}matchStockList`].rocket;
        }

        //옵션 사용 테이블 초기화
        $scope.prodOpt[`${type}options_table`] = [];
      } else if ($scope.prodOpt[`${origin}options_table`].length) {
        $scope.prodOpt[`${type}opt`] = angular.copy($scope.prodOpt[`${origin}opt`]);
        $scope.prodOpt[`${type}checked_opt`] = angular.copy($scope.prodOpt[`${origin}checked_opt`]);
        $scope.prodOpt[`${type}options_table`] = angular.copy($scope.prodOpt[`${origin}options_table`]);

        if (origin === 'rocket_') {
          const optData = $scope.prodOpt[`${origin}options_table`].map(opt => {
            return angular.merge({}, opt, opt.coupang || opt.rocket);
          });

          $scope.prodOpt[`${type}options_table`] = optData;

          delete $scope.prodOpt[`${type}options_table`].coupang;
          delete $scope.prodOpt[`${type}options_table`].rocket;
        }

        //옵션없음 데이터 초기화
        $scope.prodOpt[`${type}matchStockList`] = angular.copy(onlineProductSVC.prodOpt[`${type}matchStockList`]);
      }

      //입력형 옵션
      if ($scope.data[`${origin}insert_opt`] && $scope.prodOpt[`${origin}input_name`]) {
        let input_info = [];

        if (origin == 'esm_') {
          _.each($scope.prodOpt.esm_input_name, function(row) {
            const data = { insert_opt: row };

            input_info.push(data);
          });
        } else if (origin == 'std_') {
          input_info = angular.copy($scope.prodOpt.std_input_name);
        }

        if (type == 'std_') {
          $scope.prodOpt.std_input_name = input_info;
          $scope.data.std_insert_opt = true;
        } else if (type == 'wmp_') {
          $scope.prodOpt.wmp_insert_opt_arr = input_info;
          $scope.data.wmp_insert_opt = true;
        } else if (type == 'hplus_') {
          $scope.prodOpt.hplus_insert_opt_arr = input_info;
          $scope.data.hplus_insert_opt = true;
        } else if (type !== 'cpang_' && type !== 'rocket_') {
          $scope.data.insert_opt_arr = input_info;
          $scope.data.insert_opt = true;
        }
      }

      //대표상품 리스트 재 검색
      $scope.prodListSet();
    }

    /**
   * roy 등록하기 버튼을 통해서만 상품등록 가능 하도록 처리
   * 2018-03-19
   */
    let onclick = false;

    $scope.submitListner = function() {
      $scope.flag.submit = true;
      $scope.reCheckClass.valid('recheck');
      onclick = true;

      /*
       * 2018-05-14 lucas 쿠팡 상세정보 -> 검색옵션 임시 특별 처리
       * 후에 크리스가 상품 등록 전에 처리되도록 프로세스 추가해주신다고 하심
       */
      $scope.data.site_list.forEach(function(site) {
        if (site.pa_shop_cd === 'B378') {
          let key_siteOptNmData2 = '',
              key_optNmData2 = '';
          const searchOpt = site.set_info.key_siteOptObject || {};

          if (site.attributes) {
            site.attributes.forEach(function(opt) {
              key_siteOptNmData2 += `${opt.attributeTypeName}|`;
              if (opt.attributeTypeName in searchOpt) {
                key_optNmData2 += searchOpt[opt.attributeTypeName];
              }
              key_optNmData2 += '|';
            });
          }

          site.set_info.key_siteOptNmData2 = key_siteOptNmData2;
          site.set_info.key_optNmData2 = key_optNmData2;

          if (site.etc6 === 'true') {
            site.growthProdType = $scope.data.checkGrowth.coupang && $scope.data.checkGrowth.rocket ? 'ALL' : ($scope.data.checkGrowth.coupang ? 'CP' : 'RG');

            if (['ALL', 'RG'].includes(site.growthProdType)) {
              site.ebay_shop_mas_no = `TEMP${Math.floor(1000000000 + Math.random() * 9000000000)}†${site.growthProdType}`;
            }
          }

        } else if (['B877', 'B594', 'A064', 'B878'].includes(site.pa_shop_cd)) {
          if (!site.set_info.key_keyword) {
            site.set_info.key_keyword = $scope.data.prodall.name;
          }

          if (!site.set_info.key_arsName) {
            site.set_info.key_arsName = $scope.data.prodall.name;
          }

          if (!site.set_info.key_mobileGoodsName) {
            site.set_info.key_mobileGoodsName = $scope.data.prodall.name;
          }
        } else if (['P015'].includes(site.pa_shop_cd)) {
          if (site.set_info.add_desc_info.content_flag === 'F') {
            site.set_info.add_desc_info.content_info = [{}];
          }
        } else if (['A001'].includes(site.shop_cd)) { // ESM 마스터 상품 옥션인 경우 지마켓 상품 데이터와 동일하게 복사해 주어야 함
          if (site.esmMatchId) {
            const matchEsmData = $scope.data.site_list.find(shop => shop.shop_cd === 'A006' && shop.etc7 === site.etc7 && shop.shop_id === site.esmMatchId);

            if (matchEsmData) {

              for (const key of Object.keys(matchEsmData)) {
                if (['sub_img_objects', 'html_editor', 'prod_name_is_byte', 'prod_name_role', 'product_name_count', 'shop_sale_name', 'shop_promotion_name'].includes(key)) {
                  site[key] = matchEsmData[key];
                }
              }

              // 상품 생성 후 마스터 상품 생성 그룹핑 용도로 임시 상품 번호로 부여한 뒤 상품 전송 작업 후 마스터 상품 코드로 교체
              // 판매대기 상태에서는 업체에 노출X
              matchEsmData.ebay_shop_mas_no = `TEMP${Math.floor(1000000000 + Math.random() * 9000000000)}`;
              site.ebay_shop_mas_no = matchEsmData.ebay_shop_mas_no;
            } else {
              site.ebay_shop_mas_no = '';
            }
          } else {
            site.ebay_shop_mas_no = '';
          }
        }
      });

      // 옵션을 선택한 탭에도 동일하게 적용 기능 등록시에 일괄작동하도록 수정
      Object.keys(optOverwriteList).forEach(t => optOverwrite(t, optOverwriteList[t], false));

      let options_table_checked = true;

      //일반옵션 옵션테이블 데이터 추출
      _.forEach(site_tab, function (row) {
        let opt_type, options_table, opt;

        switch (row) {
          case '':
            if ($scope.data.defaultOpTab && $scope.data.opt_type != '옵션없음') {
              opt_type = $scope.data.opt_type;
              options_table = $scope.prodOpt.options_table;
              opt = $scope.prodOpt.opt;
            }
            break;
          case 'esm_':
            if (($scope.checked.ESM) && $scope.data.esm_opt_type != '옵션없음') {
              opt_type = $scope.data.esm_opt_type;
              options_table = $scope.prodOpt.esm_options_table;
              opt = $scope.prodOpt.esm_opt;
            }
            break;
          case 'std_':
            if (($scope.checked.A112) && $scope.data.std_opt_type != '옵션없음') {
              opt_type = $scope.data.std_opt_type;
              options_table = $scope.prodOpt.std_options_table;
              opt = $scope.prodOpt.std_opt;
            }
            break;
          case 'cpang_':
            if ($scope.disabled.CPANG && $scope.data.cpang_opt_type != '옵션없음') {
              opt_type = $scope.data.cpang_opt_type;
              options_table = $scope.prodOpt.cpang_options_table;
              opt = $scope.prodOpt.cpang_opt;
            }
            break;
          case 'rocket_':
            if ($scope.disabled.ROCKET && $scope.data.rocket_opt_type != '옵션없음') {
              opt_type = $scope.data.rocket_opt_type;
              options_table = $scope.prodOpt.rocket_options_table;
              opt = $scope.prodOpt.rocket_opt;
            }
            break;
          case 'wmp_':
            if ($scope.disabled.WMP && $scope.data.wmp_opt_type != '옵션없음') {
              opt_type = $scope.data.wmp_opt_type;
              options_table = $scope.prodOpt.wmp_options_table;
              opt = $scope.prodOpt.wmp_opt;
            }
            break;
          case 'hplus_':
            if ($scope.checked.HPLUS && $scope.data.hplus_opt_type != '옵션없음') {
              opt_type = $scope.data.hplus_opt_type;
              options_table = $scope.prodOpt.hplus_options_table;
              opt = $scope.prodOpt.hplus_opt;
            }
            break;
          case 'galleria_':
            if ($scope.checked.GALLERIA && $scope.data.galleria_opt_type != '옵션없음') {
              opt_type = $scope.data.galleria_opt_type;
              options_table = $scope.prodOpt.galleria_options_table;
              opt = $scope.prodOpt.galleria_opt;
            }
            break;
          case 'naver_':
            if ($scope.checked.NAVER && $scope.data.naver_opt_type != '옵션없음') {
              opt_type = $scope.data.naver_opt_type;
              options_table = $scope.prodOpt.naver_options_table;
              opt = $scope.prodOpt.naver_opt;
            }
            break;
          case 'aliexpress_':
            if ($scope.checked.ALIEXPRESS && $scope.data.aliexpress_opt_type != '옵션없음') {
              opt_type = $scope.data.aliexpress_opt_type;
              options_table = $scope.prodOpt.aliexpress_options_table;
              opt = $scope.prodOpt.aliexpress_opt;
            }
            break;
          case 'alibaba_':
            if ($scope.checked.ALIBABA && $scope.data.alibaba_opt_type != '옵션없음') {
              opt_type = $scope.data.alibaba_opt_type;
              options_table = $scope.prodOpt.alibaba_options_table;
              opt = $scope.prodOpt.alibaba_opt;
            }
            break;
          case 'naver_single_':
            if ($scope.checked.A077single) {
              if ($scope.data.naver_single_opt_type === '옵션없음') {
                commonSVC.showMessage('옵션 정보를 설정해주세요', '스마트스토어 단일(그룹)상품은 옵션 1개 이상 필수입니다.');
                options_table_checked = false;
                $scope.changeTab(2);
                $scope.flag.submit = false;
              } else {
                opt_type = $scope.data.naver_single_opt_type;
                options_table = $scope.prodOpt.naver_single_options_table;
                opt = $scope.prodOpt.naver_single_opt;
              }
            }

            break;
          case 'aliexpress_global_':
            if ($scope.checked.ALIEXPRESSGLOBAL && $scope.data.aliexpress_global_opt_type != '옵션없음') {
              opt_type = $scope.data.aliexpress_global_opt_type;
              options_table = $scope.prodOpt.aliexpress_global_options_table;
              opt = $scope.prodOpt.aliexpress_global_opt;
            }
            break;
          case 'temu_':
            if ($scope.checked.ALIEXPRESS && $scope.data.aliexpress_opt_type != '옵션없음') {
              opt_type = $scope.data.aliexpress_opt_type;
              options_table = $scope.prodOpt.aliexpress_options_table;
              opt = $scope.prodOpt.aliexpress_opt;
            }
            break;
        }

        if (opt_type && options_table && opt) {
          if (!$scope.optGetTables(opt_type, options_table, opt, row)) {
            options_table_checked = false;

            return false;
          }
        }
      });

      if (!options_table_checked) {
        return false;
      }

      // 옵션 모델번호 기준 상품 모델번호 자동입력
      if ($scope.viewModelNo && !$scope.data.prodall.model_no) {
        if ($scope.data.opt_type === '옵션없음' && $scope.prodOpt.matchStockList[0]?.model_no) {
          $scope.data.prodall.model_no = $scope.prodOpt.matchStockList[0].model_no;
        } else if (['조합형', '독립형'].includes($scope.data.opt_type) && $scope.prodOpt.options_table[0]?.model_no) {
          $scope.data.prodall.model_no = $scope.prodOpt.options_table[0].model_no;
        }
      }

      $timeout(() => {
        $('#prodform_add').submit();
        productSubmit();
      });
    };

    /**
     * 상품등록
     */
    const productSubmit = _.debounce(function () {
      inValidTab = [];

      //등록하기 누른 경우가 아니면 등록안함
      if (!onclick) {
        return false;
      }
      onclick = false;

      if ($scope.data.opt_type === '조합형') {
        for (const [idx, { name, attr }] of $scope.prodOpt.opt.entries()) {
          if (name.length && !attr.length) {
            $scope.prodOpt.opt[idx].name = '';
          }
        }
      }

      // 등록후 옵션 데이터 원복용
      dataBak = angular.copy($scope.prodOpt);

      // 등록될 원산지 번호 정리
      if ($scope.data.madein_type != '기타' && $scope.data.madein_type !== '원양산') {
        if ($scope.data.madein_type && $scope.data.madein_group) {
          const madein_list = $scope.madeinList[$scope.data.madein_type][$scope.data.madein_group];

          angular.forEach(madein_list, function(row) {
            if (row.detail == $scope.data.madein_name) {
              $scope.data.madein_no = row.number;
            }
          });
        }
      } else if ($scope.data.madein_type === '원양산') {
        const madein_list = $scope.madeinList[$scope.data.madein_type];

        angular.forEach(madein_list, function(row) {
          if (row.group == $scope.data.madein_group) {
            $scope.data.madein_no = row.number;
          }
        });
        $scope.data.etcMadein = `${$scope.data.madein_type}=${$scope.data.madein_group}`;
      } else {
        $scope.data.madein_no = '';
      }

      if ($scope.data.c_sale_cd && $scope.overlapResult != 'y') {
        commonSVC.showMessage('상품 등록 실패', '판매자관리코드 중복확인을 해주세요.');
        $scope.overlapResult = 'n';
        $scope.alerts = { msg: '판매자관리코드 중복확인을 진행해 주세요.' };
        $scope.flag.submit = false;
      }

      // 추가구매 옵션 없음으로 선택하면 값 초기화 2020-01-06 Tim
      if ($scope.data.add_opt_type === 'N') {
        $scope.prodOpt.add_opt_table = [];
      }

      // 인증유형 등록일 경우에만 인증정보 저장
      $scope.data.certDataList = $scope.data.cert_info.cert_type == '인증유형등록' ? $scope.certDataList : [];
      $scope.data.overlapResult = $scope.overlapResult;

      if ($scope.checked.ESM) {
        if ($scope.data.esm_opt_type === '독립형') {
          onlineProductSVC.setIndependentOptionCode($scope.prodOpt.esm_options_table, $scope.prodOpt.esm_opt);
        } else {
          onlineProductSVC.setOptionCode($scope.prodOpt.esm_options_table, $scope.prodOpt.esm_opt, 'A001');
        }

        // 옵션구분 추천옵션코드가 없는 경우 넣어줌
        if ($scope.data.esm_opt_type !== '옵션없음' && $scope.prodOpt.esm_opt.length) {
          $scope.prodOpt.esm_opt.map(o => {
            if (o.name) {
              const recommendOption = $scope.data.esm_options.find(item => item.name === o.name);

              if (recommendOption) {
                o.name_cd = recommendOption.name_cd;
              } else {
                o.name_cd = 0;
              }
            }
          });
        }
      }
      if ($scope.checked.ALIBABA) {
        onlineProductSVC.setOptionCode($scope.prodOpt.alibaba_options_table, $scope.prodOpt.alibaba_opt, 'A092');
      }
      if ($scope.checked.ALIEXPRESS) {
        onlineProductSVC.setOptionCode($scope.prodOpt.aliexpress_options_table, $scope.prodOpt.aliexpress_opt, 'A093');
      }
      if ($scope.checked.A077single) {
        onlineProductSVC.setOptionCode($scope.prodOpt.naver_single_options_table, $scope.prodOpt.naver_single_opt, 'A077');
      }
      if ($scope.checked.ALIEXPRESSGLOBAL) {
        onlineProductSVC.setOptionCode($scope.prodOpt.aliexpress_global_options_table, $scope.prodOpt.aliexpress_global_opt, 'X094');
      }
      if ($scope.checked.TEMU) {
        onlineProductSVC.setOptionCode($scope.prodOpt.temu_options_table, $scope.prodOpt.temu_opt, 'A098');
      }

      // 입력값 검사
      const flag = onlineProductSVC.checkValue($scope.data, $scope.checked, 'add', $scope.prodOpt, $scope.prodAddForm);

      if ($scope.prodOpt.add_opt_table.some((row, index, arr) => arr.some((opt, optIndex) => index !== optIndex && row.name === opt.name && row.opName === opt.opName))) {
        commonSVC.showMessage('상품 등록 실패', '추가구매 옵션 중복확인 해주세요.');
        $scope.changeTab(2);

        $scope.flag.submit = false;
        flag.result = false;
      }

      // 물류상품인경우 매칭된 SKU가 G마켓물류 배송처인지 체크필요.
      if ($scope.checked.smileProd && flag.result == true && $scope.prodAddForm.$valid) {
        if (
          ($scope.data.opt_type === '옵션없음' && $scope.prodOpt.esm_matchStockList[0]?.depot_no && !$scope.ebayDepots.includes($scope.prodOpt.esm_matchStockList[0]?.depot_no)) ||
          ($scope.prodOpt.esm_options_table.some(o => (o.set_no || o.sku_cd) && !$scope.ebayDepots.includes(o.depot_no)))
        ) {
          flag.optInValidTab[1] = false;
          flag.inValidTab[1] = false;
          flag.result = false;
        }
      }

      inValidTab = flag.inValidTab;
      $scope.inValidShop = flag.shop;

      if (flag.result == true && $scope.prodAddForm.$valid) {
        // 키워드가 null 로 들어가는 경우 빈값처리. 2018-01-18 rony
        if (_.isNull($scope.data.keyword)) {
          $scope.data.keyword = '';
        }
        // 쇼핑몰별 등록될 상품 옵션 리스트 정리
        makeProdList();
        // 상품 등록
        productInsert();

      } else {
        $scope.flag.submit = false;
        // 옵션탭 경고 아이콘
        //'체크 시 일반옵션탭에도 동일하게 옵션 적용' 체크되어 있을경우에는 일반옵션 탭이 아닌 esm옵션탭 활성화
        switch (flag.opTabActive) {
          case 0:
            if ($scope.checked.esm_defaultOpt) {
              $scope.opTabActive = 1;
            }
            if ($scope.checked.std_defaultOpt) {
              $scope.opTabActive = 2;
            }
            break;
          case 2:
            if ($scope.checked.std_esm_defaultOpt) {
              $scope.opTabActive = 1;
            }
            break;
          case 3:
            break;
          case 4:
            if ($scope.checked.wmp_esm_defaultOpt) {
              $scope.opTabActive = 1;
            }
            if ($scope.checked.wmp_std_defaultOpt) {
              $scope.opTabActive = 2;
            }
            break;
        }

        //탭별 경고 아이콘 확인
        $scope.optInValidTab = flag.optInValidTab;
        if ($scope.checked.esm_defaultOpt || $scope.checked.std_defaultOpt) {
          $scope.optInValidTab[0] = true;
        }
        if ($scope.checked.std_esm_defaultOpt) {
          $scope.optInValidTab[2] = true;
        }
        if ($scope.checked.wmp_esm_defaultOpt || $scope.checked.wmp_std_defaultOpt) {
          $scope.optInValidTab[4] = true;
        }

        for (const i in inValidTab) {
          if (!inValidTab[i]) {
            $scope.changeTab(Number(i) + 1);
            break;
          }
        }

        $timeout(() => {});
        if (!$scope.prodAddForm.$valid) {
          commonSVC.showMessage('상품 등록 실패', flag.msg || '입력값을 확인해주세요.');
        }
      }
    }, 1500);

    function productInsert() {
      // 등록
      onlineProductModel.add($scope.data, function (state, data) {
        $scope.flag.submit = false;
        if (state === 'success' && data.data.results === 'success') {
          if (tmpsave_no) {
            onlineProductModel.temporarySaveDelete(tmpsave_no, function (re) {
              if (re === 'success') {
                commonSVC.showToaster('success', '성공', '임시저장 데이터가 정상적으로 삭제되었습니다.');
              }
            });
          }
          commonSVC.showToaster('success', '', '상품이 등록 되었습니다.');

          // 2018-01-11 chris 리스트 새로고침을 위한 이벤트 추가
          $rootScope.$broadcast('reloadOlProductList');

          commonSVC.showConfirm('쇼핑몰 상품 등록 성공', '쇼핑몰 상품 등록에 성공했습니다 상품관리 리스트로 이동하시겠습니까 ?', function (state) {
            if (state) {
              $state.go('main.online_product_list', { modal: '' });
            }

            $scope.prodOpt = angular.copy(dataBak);
            $scope.overlapResult = null;
            // $scope.data = angular.copy(dataBak);

            // ESM 마스터 계정 모두 선택한 경우에는 지마켓 쇼핑몰 상품 데이터 연동한 옥션 계정 정보를 넣어줘 다른 탭에서 지마켓, 옥션 공통 처리함
            $scope.data.site_list.forEach((shop) => {
              if (['A001', 'A006'].includes(shop.shop_cd)) {
                const shopName = shop.shop_cd === 'A006' ? 'gmarket' : 'auction'; // 각각 반대 매칭 쇼핑몰의 계정을 넣어줘야함
                const matchShop = $scope.esmMatchShops.find(s => s[shopName].shop_cd === shop.shop_cd && s[shopName].shop_id === shop.shop_id && s[shopName].etc7 === shop.etc7);

                if (matchShop) {
                  shop.esmMatchId = matchShop[`${shop.shop_cd === 'A006' ? 'auction' : 'gmarket'}`].shop_id;
                  shop.ebay_shop_mas_no = null;
                } else {
                  shop.esmMatchId = '';
                }
              }
            });

            if (!$scope.$$phase && !$scope.$root.$$phase) {
              $scope.$apply();
            }
          });
        } else {
          const errMsg = errorSVC.getError('prod', data.data?.messages[0], data.data?.messages[0]);

          commonSVC.showToaster('error', '실패', '상품 등록이 실패하였습니다.\n' + errMsg);

          if (errMsg === '쇼핑몰 상품 등록 DB 한도 초과') {
            commonSVC.showMessage('LITE버전 쇼핑몰 상품 DB 한도 초과', '쇼핑몰 상품은 5,000개 이하로 관리해 주세요.\n초과되는 상품은 삭제 또는 버전 업그레이드 후 이용하실 수 있습니다.\n(업그레이드 시 더 이상 무료 버전은 이용할 수 없습니다.)');
          }
        }
      });
    }

    // 단일상품등록 여부
    $scope.$watchGroup(['checked.ESM', 'checked.A112'], function(nv, ov) {
      if (_.isEqual(nv, ov)) {
        return;
      }

      // 변경시 템플릿리스트 닫기
      $scope.templateListModal = false;

      // 일반옵션탭 노출
      if (!_.some(nv)) {
        $scope.checked.esm_defaultOpt = false;
        $scope.data.defaultOpTab = true;
        $scope.opTabActive = 0;
      }
    });

    /**
     * 물류상품등록 변경시 옵션재고 99,999로 변경
     */
    $scope.$watch('checked.smileProd', () => {
      if ($scope.checked.smileProd) {
        $scope.prodOpt.esm_options_table.map(opt => opt.cnt_limit = 99999);
        $scope.checked.esmBatchType = 'add_price';
      }
    });

    /**
     * 공통 이미지 변경시 체크
     */
    $scope.$watch('data.img_objects', () => {
      $scope.isSetImageObject = $scope.data.img_objects.some(img => img.files);
    }, true);

    /**
     * 쇼핑몰 별 이미지 변경시 체크
     */
    $scope.$watch('data.site_list', () => {
      $scope.data.site_list.map((site) => {
        if (!$scope.isSetSubImageObject) {
          $scope.isSetSubImageObject = {};
        }

        $scope.isSetSubImageObject[site.idx] = site.sub_img_objects.some(img => img.files);
      });
    }, true);

    /**
   * 온라인 템플릿 리스트 가져오기
   */
    $scope.onlineTemplate = function (index, openFlag, data, row) {
      // 템플릿 리스트 초기화
      $scope.templateList = [];
      $scope.templateList2 = [];
      $scope.templateLoadingMsg = '로딩중';

      // 데이터가 잇을경우에만 사이트 인덱스 수정
      if (data && row) {
        $scope.site_idx = _.findIndex(data.site_list, function(site) {
          return site.shop_cd + site.shop_id == row.shop_cd + row.shop_id;
        });
      }

      const site = $scope.data.site_list[index];

      //템플릿 검색 input 초기화
      site.search = '';

      //템플릿 수정후 펼침상태 유지
      if (openFlag == undefined) {
        $scope.templateListModal = !$scope.templateListModal;
      }

      // 2017-08-29 chris 템플릿 조회 오픈시에만 템플릿 리스트 가져오게 수정
      if (!$scope.templateListModal) {
        return;
      }

      $scope.openTemplate = index;

      //선택된 템플릿 매칭해주기위한 데이터
      $scope.shop_params = { shop_cd: site.shop_cd, shop_id: site.shop_id };

      const param = {
        shop_cd: site.shop_cd,
        shop_id: site.shop_id,
        bookmark: 'all'
      };

      templateModel.templateOnlineProduct(param)
        .then(function (res) {

          const allTemplate = [];
          const bookmarkGroup = _.groupBy(res.data.results, 'bookmark_yn');

          _.each(bookmarkGroup, function (list, yn) {
            if (parseInt(yn)) {
              $scope.templateList = list;
            } else {
              $scope.templateList = [];
            }

            // 전체 템플릿 리스트 노출위해 값 담음 2018-07-09 rony
            _.forEach(list, function(v) {
              allTemplate.push(v);
            });
          });
          $scope.templateList2 = allTemplate;
          if (!$scope.templateList.length) {
            // 없을경우 전체탭으로이동
            $(`#category${index}-1`).removeClass('active');
            $(`#category${index}-2`).addClass('active');

            $(`#bookmarkTab${index}`).removeClass('active');
            $(`#allTab${index}`).addClass('active');
          }
        })
        .catch(function (err) {
          commonSVC.showToaster('error', '', err);
        })
        .finally(function() {
          $scope.templateLoadingMsg = '데이터가 없습니다.';
        });
    };

    /**
   *  템플릿 리스트 닫기
   */
    $scope.listModal = function(param) {
      if (param == 'false') {
        $scope.templateListModal = false;
      }
    };

    /**
   *  템플릿 등록으로 이동
   */
    $scope.template_add = function(index) {
      const param = {
        type1: 'ol'
      };

      $state.go('main.setForm_template_add', param);

      //템플릿 등록시 발생 이벤트
      $scope.$on('templateRefreshGlobal', function() {
      //템플릿리스트 새로고침
        $scope.onlineTemplate(index, 'true');
      });
    },

    //쇼핑몰 계정별 카테고리 자동매칭
    $scope.shopCateMatch = async function() {
      return new Promise((resolve, reject) => {
        if ($scope.checked.ESM || $scope.checked.A112) {
          $scope.categoryOptionsFlag = true;
        }

        //매칭 안된 샾이 있을 경우에만 검색
        const notMatchShopList = $scope.data.site_list.filter(row => !(row.shop_cate_no && row.sol_cate_shop_add_info && Object.keys(row.sol_cate_shop_add_info).length));

        if (notMatchShopList.length) {
          $scope.shopList.forEach(data => {
            if (data.shop_cate_no) {
              const filteredList = $scope.data.site_list.filter(row => {
                return row.shop_id === data.shop_id && row.shop_cd === data.shop_cd;
              });

              filteredList.forEach(site => {
                const sol_cate_add_info = typeof data.sol_cate_shop_add_info === 'string' ? JSON.parse(data.sol_cate_shop_add_info) : data.sol_cate_shop_add_info;
                const shop_cate_add_info = typeof data.sol_cate_shop_add_info === 'string' ? JSON.parse(data.shop_cate_no_add_info) : data.shop_cate_no_add_info;

                const cate_add_info = shop_cate_add_info && Object.keys(shop_cate_add_info).length ? shop_cate_add_info : sol_cate_add_info || {};
                const json = cate_add_info;

                if (site.pa_shop_cd == 'A004') {
                  site.md_category = data.shop_cate_cd.split('_')[3];
                }
                if (site.pa_shop_cd == 'A001' || site.pa_shop_cd == 'A006') {
                  site.esm_match_cate_name = json.catename_esm;
                  site.esm_match_cate_codes = json.catecode_esm;
                }
                if (site.pa_shop_cd === 'A112') {
                  site.key_selPrdTypCd = json.key_selPrdTypCd;
                }
                if (site.pa_shop_cd == 'B118') {
                  if (json.codes.length > 1 && !json.key_show_cate_check) {
                    site.set_info.key_show_cate_check = '';
                    const len = json.codes.length;

                    for (let idx = 1; idx < len; idx++) {
                      site.set_info.key_show_cate_check += `${json.codes[idx]}/`;
                    }
                  }
                }
                if (site.pa_shop_cd == 'B394') {
                  if (json.category_code.length > 1) {
                    site.cate_info = {
                      vir_vend_code: json.key_vend_code,
                      pumbun_no: json.key_pumbun_no,
                      category_code: json.category_code
                    };
                  }
                }

                site.match_cate_name = json.category_names;
                site.match_cate_code = data.shop_cate_cd;

                site.sol_cate_shop_add_info = data.sol_cate_shop_add_info;
              });
            }
          });

          checkTabDisabled();

          $scope.flag.categorySearch = false;

          // 카테고리 변경 이벤트
          $rootScope.$broadcast('infoEdit');

          resolve();
        } else {
          resolve();
        }
      });
    };

    // 쇼핑몰 추가항목 기본값 자동 세팅
    // Params: siteList 값이 없으면 $scope.data.site_list 사용
    $scope.shopSetInfoMatch = async (siteList) => {
      try {
        const site_list = siteList || $scope.data.site_list;
        const result = await onlineProductModel.getSetInfo({ shop_cd_arr: site_list.map(({ pa_shop_cd, shop_cd, shop_id, shop_cate_no, sol_cate_no, std_ol_yn }) => ({ shop_cd, pa_shop_cd, shop_id, shop_cate_no, sol_cate_no, std_ol_yn })) });

        for (const site of $scope.data.site_list) {
          site.set_info = site.set_info || {};

          const matchSetInfo = result.data.results.find(ds => ds.shop_cd === site.shop_cd && ds.shop_id === site.shop_id);

          if (matchSetInfo) {
            site.set_info = matchSetInfo.set_info;
            delete site.saveAsTemplateYn;
          }
        }
      } catch (err) {
        commonSVC.showToaster('error', '', '추가항목 기본값 조회에 실패했습니다.');

        return false;
      }
    };

    /**
     *  입력형 옵션 삭제
     */
    $scope.insert_opt_del = function(idx) {
      // 마지막 입력형 옵션을 삭제할경우 다시 추가하고 싶어도 페이지 새로고침을 하지않는 한 추가가 불가능하기 때문에 삭제하지않고 값을 지워주는 것으로 처리.
      if ($scope.data.insert_opt_arr.length > 1) {
        $scope.data.insert_opt_arr.splice(idx, 1);
      } else {
        $scope.data.insert_opt_arr[0].insert_opt = '';
        $scope.data.insert_opt = false;
      }
    };

    /**
   * 온라인 템플릿 상세모달
   */
    $scope.onlineTemplateDetail = function (template_no, parentSiteCode, siteId, shopName, siteCode) {
      onlineProductSVC.onlineTemplateDetail(template_no, { shop_cd: siteCode, pa_shop_cd: parentSiteCode }, siteId, shopName, $scope.openTemplate, $scope.onlineTemplate);
    };

    // 템플릿 그룹을 드롭다운에서 선택하면 템플릿 그룹에 저장되어있는 템플릿을 자동 설정해줌
    $scope.setTemplateGroup = function (template_group_no) {
      if (!template_group_no) {
        return;
      }

      templateModel.loadGroup({ template_group_no: template_group_no })
        .then(function (results) {
          const templateGroup = results.data.results;

          _.forEach($scope.data.site_list, function (site) {
            const template = _.find(templateGroup, { shop_cd: site.shop_cd, shop_id: site.shop_id });

            if (template) {
              site.template_name = template.template_name;
              site.template_no = template.template_no;
              $scope.templateListModal = false;
            }
          });
        });
    };

    // 템플릿 그룹 번호가 바뀔 때만다 템플릿 설정 함수 호출
    $scope.$watch('template_group_no', $scope.setTemplateGroup);

    // 템플릿 그룹 관리 모달
    $scope.manageTemplateGroup = function () {
      let template_group_no;
      const resolve = {
        data: {},
        systemList: function () {
          return systemModel.load();
        }
      };
      const modal = commonSVC.openModal('lg', resolve, 'ManageTemplateGroupCtrl', 'views/online/product/modals/manage_template_group.html');

      modal.result
        .then(function (re) {
          template_group_no = re[0];

          return templateModel.listGroup();
        })
        .then(function (re) {
          $scope.templateGroupList = re.data.results;
          $scope.template_group_no = `${template_group_no}`;
        });
    };

    /**
   * 모달 열기
   * */
    var temp_list = [];
    var prod_info_open_type = 'add';

    /**
     * 상품정보제공고시 선택 모달 오픈
     */
    $scope.openProdNotiInfoModal = function (tabActive) {
      const resolve = {};

      resolve.data = {
        temp_list: temp_list,
        type: prod_info_open_type,
        tabActive
      };
      const modal = commonSVC.openModal('lg', resolve, 'OnlineProductProdInfoCtrl', 'views/online/product/prod_info.html');

      modal.result.then(function (re) {
        temp_list = re;
        for (const i in re) {
          if (re[i].infoCode && !(_.isUndefined(re[i].infoCode))) {
            const array_result = {};

            $scope.data.prod_info_opt = re[i].infoName;

            //체인지 이벤트 발생 안하기 때문에 직접 처리
            $('#prod_info_opt-error').remove();

            array_result.infoCode = re[i].infoCode;
            array_result.infoName = re[i].infoName;
            array_result.infoDetail = {};

            re[i].infoDetail.forEach(list => {
              list.detail.map(v => {
                array_result.infoDetail[v.code] = v.value;
              });
            });

            $scope.data.prod_noti_data_new[i] = array_result;
          } else {
            $scope.data.prod_noti_data_new[i] = '';
          }
        }

        prod_info_open_type = 'add';
      });
    };

    //채널 추가항목 모달
    $scope.openChannelhModal = async (row, openTemplate) => {
      const templatePath = 'views/online/product/channel/channel_modal.html',
            controller = `olProdShopDetail-${row.pa_shop_cd}-Ctrl`;
      const shop_cate_no = $scope.data.site_list.find(s => s.shop_cd === row.shop_cd && s.shop_id === row.shop_id)?.shop_cate_no || $scope.shopList.find(s => s.shop_cd === row.shop_cd && s.shop_id === row.shop_id)?.shop_cate_no;

      // 단일상품 여부 업데이트 위해 호출
      makeProdList();
      const { std_ol_yn } = $scope.data.site_list.find(({ shop_cd }) => shop_cd === row.shop_cd);

      try {
        // 맨 처음 카테고리 선택 후 진행하게 되면 set_info 설정해 놨음 그래서 중복으로 getSetInfo을 부를 필요가 없음
        if (row.set_info && Object.keys(row.set_info).length) {
          $scope.data.setInfoDefault = row.set_info;
        } else {
          const result = await onlineProductModel.getSetInfo({
            shop_cd_arr: [{
              shop_cd: row.shop_cd,
              pa_shop_cd: row.pa_shop_cd,
              shop_id: row.shop_id,
              sol_cate_no: row.sol_cate_no,
              shop_cate_no: row.shop_cate_no,
              std_ol_yn
            }]
          });

          // 추가항목 기본값
          $scope.data.setInfoDefault = result.data.results[0].set_info;
        }
      } catch (err) {
        commonSVC.showToaster('error', '', '추가항목 기본값 조회에 실패했습니다.');

        return false;
      }

      const resolve = {
        data: {
          shop_data: row,
          openTemplate: openTemplate,
          madeinList: $scope.madeinList,
          data: {
            ...$scope.data,
            shop_cate_no,
            pa_shop_cd: row.pa_shop_cd,
            shop_cd: row.shop_cd,
            shop_id: row.shop_id,
            seller_nick: row.seller_nick,
            std_ol_yn,
            detailType: $scope.detailType
          },
          checked: $scope.checked
        },
        systemList: systemList.data || {},
        templateList: function() {
          return onlineProductModel.setInfoTemplateList({
            shortListYn: true,
            shop_cate_no,
            shop_cd: row.shop_cd,
            pa_shop_cd: row.pa_shop_cd,
            std_ol_yn
          });
        },
      };

      if (['A001', 'A006'].includes(row.pa_shop_cd)) {
        resolve.data.esmMasterFlag = $scope.checkEsmMaster.checked; // ESM 마스터 상품 여부
      } else if (['B378', 'B666', 'P044'].includes(row.pa_shop_cd)) {
        resolve.data.prodOpt = $scope.prodOpt;
      } else if (['P015', 'P018', 'B669', 'P028', 'P037', 'A004'].includes(row.pa_shop_cd)) {
        const cate_cd = row.match_cate_code;
        resolve.data.data = { ...resolve.data.data, cate_cd };
      } else if (['A092'].includes(row.pa_shop_cd)) {
        // switch문 쓰려니까 break 없다고 lint 오류나서 그냥 else if 추가
        resolve.data.prodOpt = $scope.prodOpt;
        const cate_cd = row.match_cate_code;
        resolve.data.data = { ...resolve.data.data, cate_cd };
      }
      // ssf 쇼핑몰의 경우 카테고리에 따라 인증정보가 달라 카테고리 정보 전달
      else if (['P054'].includes(row.pa_shop_cd)) {
        resolve.data.data.sol_cate_shop_add_info = row.sol_cate_shop_add_info;
      }

      const modal = commonSVC.openModal('xxg', resolve, controller, templatePath);

      modal.result.then(function(re) {
        if (re && re != 'cancel') {
          re.shop_cate_no = shop_cate_no;
          $scope.data.site_list[openTemplate] = re;
        }
      });
    };

    /**
   * 유효일자 직접입력시 초기값 설정
   */
    $scope.initExpire = function() {
      if ($scope.data.expiration_month == '') {
        $scope.data.expire_date = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      }
    };

    /**
   * 닫기
   */
    $scope.cancel = function () {
      $rootScope.$emit('$closeTab', 'tab_online_product_add');
    };

    /**
   * 검색 필터
   */
    $scope.search = function (element) {
      if ($scope.data.site_list[$scope.openTemplate]) {
        if ($scope.data.site_list[$scope.openTemplate].search == '') {
          return true;
        }

        return !!JSON.stringify(element).match(new RegExp(`(${$scope.data.site_list[$scope.openTemplate].search})`, 'i'));
      } else {
        return false;
      }
    };

    /**
   * 툴팁 그려주기
   */
    $scope.tooltip = function() {
      $('[data-popup="tooltip"]').tooltip();
    };

    $scope.renderHtml = (id) => {
      $(`#${id}`).html($scope.data[id]);
    };

    /**
   * 2018-10-04 Daniel
   * 머리말/꼬리말 적용모달
   */
    $scope.addcontentApply = function (type) {
      if (type === 'mas') {
        const params = {
          data: {
            type: 'ol_shop',
            returnYn: true,
          }
        };

        const modal = commonSVC.openModal('full', params, 'MasAddcontentCtrl', 'views/online/product/modals/mas_addcontent.html', false);

        modal.result.then(function (result) {
          $scope.data.mas_addcontent_no = result.no;
          $scope.data.mas_addcontent_name = result.name;
          $scope.data.mas_header_content = result.header_content;
          $scope.data.mas_footer_content = result.footer_content;
          $scope.renderHtml('mas_header_content');
          $scope.renderHtml('mas_footer_content');
        });
      } else {
        const params = {
          data: {
            returnYn: true,
            warehouseList: $scope.warehouseList,
            target: type
          }
        };

        const modal = commonSVC.openModal('full', params, 'OnlineProductAddcontentCtrl', 'views/online/product/modals/addcontent.html', false);

        modal.result.then(function (result) {
          if (result.target == '상세설명') {
            $scope.data.addcontent_no = result.no;
            $scope.data.addcontent_name = result.name;
          } else {
            $scope.data.shop_sale_name_addcontent_no = result.no;
            $scope.data.shop_sale_name_addcontent_name = result.name;
          }
        });
      }
    };

    $scope.addcontentDelete = (type = 'mas') => {
      if (type == 'mas') {
        $scope.data.mas_addcontent_no = '';
        $scope.data.mas_addcontent_name = '';
        $scope.data.mas_header_content = '';
        $scope.data.mas_footer_content = '';
        $scope.data.sub_off_avail_yn = 0;
        $scope.renderHtml('mas_header_content');
        $scope.renderHtml('mas_footer_content');
      } else {
        if (type == '상세설명') {
          $scope.data.addcontent_no = null;
          $scope.data.addcontent_name = '';
        } else {
          $scope.data.shop_sale_name_addcontent_no = null;
          $scope.data.shop_sale_name_addcontent_name = '';
        }
      }
    };

    //추가항목 여부 확인
    $scope.checkChannelhModal = (pa_shop_cd) => {
      let resultFlag = false;

      switch (pa_shop_cd) {
        case 'A528':                    // 패션플러스
        case 'P018':                    // 지그재그
        case 'P039':                    // 롯데묘미
        case 'P042':                    // 올웨이즈
        case 'P058':                    // 셀러허브 스토어
        case 'A526':                    // 셀러허브 이지셀
          resultFlag = true;
          break;
        case 'A032':
        case 'A085':
        case 'A076': {
          const ableNotiCode = ['Wear2023', 'Shoes2023', 'Bag2023', 'FashionItems2023', 'SleepingGear2023', 'Furniture2023', 'ImageAppliances2023', 'HomeAppliances2023', 'SeasonAppliances2023', 'OfficeAppliances2023', 'OpticsAppliances2023', 'MicroElectronics2023', 'CellPhone2023', 'Navigation2023', 'CarArticles2023', 'MedicalAppliances2023', 'KitchenUtensils2023', 'Cosmetic2023', 'Food2023', 'ProcessedFood2023', 'HealthFunctionalFood2023', 'Kids2023', 'MusicalInstrument2023', 'SportsEquipment2023', 'Books2023', 'Etc2023', 'HouseHoldChemical2023', 'Biodical2023', 'Jewelry2023', 'RentalHa2023', 'RentalEtc2023'];

          // SSG오픈마켓,SSG종합몰의 경우 품목고시에 따라 추가항목 유무 결정
          if ($scope.data.prod_noti_data_new.every(data => !ableNotiCode.includes(data?.infoCode))) {
            resultFlag = true;
          }
          break;
        }
        case 'B719': {
          const ableNotiCode = ['RentalHa2023', 'RentalEtc2023'];

          // 위메프2.0의 경우 품목고시에 따라 추가항목 유무 결정
          if ($scope.data.prod_noti_data_new.every(data => !ableNotiCode.includes(data?.infoCode))) {
            resultFlag = true;
          }

          break;
        }
      }

      return resultFlag;
    };

    /**
     * 추가항목 필수 확인
     */
    $scope.checkRequired = function(row) {
      let resultFlag = false;

      switch (row.pa_shop_cd) {
        case 'B378':										//쿠팡
        case 'B851':										//티몬
        case 'B956':										//티몬
        case 'A017':										//AK몰
        case 'A004':                    //CJ온스타일
        case 'A076':                    //SSG오픈마켓
        case 'A032':                    //SSG통합
        case 'A085':                    //신세계백화점(SSG)
        case 'A131':                    //HMall
        case 'B957':										//W컨셉
        case 'B696':                    //하이마트
        case 'P028':                    //GS프레시
        case 'B690':                    //K쇼핑
        case 'P037':                    //컴퓨존
        case 'A124':                    //한샘
        case 'A530':                    //공구마켓/할인중독/심쿵할인
        case 'P044':                    //한섬
        case 'P020':                    //롯데슈퍼
        case 'B719':                    //위메프2.0
        case 'P048':                    //뚝딱
        case 'A093':                    //알리익스프레스
        case 'A092':                    //알리바바
        case 'B693':                    //도매꾹
        case 'B730':                    //오너클랜
        case 'X094':                    //알리익스프레스 (글로벌)
        case 'A098':                    //테무
          resultFlag = true;
          break;
        case 'P045': {                  //위메프2.0
          //렌탈 품목 고시만 추가항목 존재
          if ($scope.data.prod_noti_data_new.every(data => !['RentalHa2023', 'RentalEtc2023'].includes(data?.infoCode))) {
            resultFlag = true;
          }
          break;
        }
        case 'A112': {                  // 11번가
          // 수입쇠고기 카테고리 선택시에만 필수 항목 존재
          if ($scope.shop_data?.match_cate_code?.includes('1001481') || $scope.shop_data?.match_cate_name?.includes('수입쇠고기') || $scope.data?.match_cate_name?.includes('수입쇠고기') || $scope.data?.match_sol_cate_name?.includes('수입쇠고기')) {
            resultFlag = true;
          }
          break;
        }
        case 'P054': {                   // SSF
          // 필수 KC 인증서류 및 카테고리 인증서류가 필요한 경우
          if ($scope.data.sol_cate_shop_add_info?.detailData.kcCrtfcTgtCd !== 'TGT_SAFE_STDR' && $scope.data.sol_cate_shop_add_info?.detailData.stdCtgryQltyDcmtList.find(data => data.essntlYn === 'Y')) {
            resultFlag = true;
          }
          // 유통기한관리 필수인 경우
          if ($scope.data.sol_cate_shop_add_info?.detailData.distbTmlmtManageYn === 'Y') {
            resultFlag = true;
          }
          break;
        }
      }

      return resultFlag;
    };

    /**
   * 2018-10-04 Daniel
   * 머리말/꼬리말 상세
   */
    $scope.addcontentRule = function (type) {
      const size = 'lg-md',
            ctrl = 'OnlineProductAddcontentRuleCtrl',
            html = 'views/online/product/modals/addcontent_rule.html',
            resolve = {
              data: {
                addcontentNo: type == '상세설명' ? $scope.data.addcontent_no : $scope.data.shop_sale_name_addcontent_no
              }
            };

      const modal = commonSVC.openModal(size, resolve, ctrl, html, false);

      modal.result.then(function () {
        $scope.searchDo(true);
      });
    };

    // 임시 저장
    $scope.temporarySave = function () {
      $scope.flag.submit = false;
      if (!$scope.data.prodall.name) {
        commonSVC.showMessage('상품명은 필수로 입력하셔야 합니다.');

        return;
      }

      commonSVC.showConfirm('임시저장', '입력된 정보를 임시저장 하시겠습니까?', function (confirm) {
        if (confirm) {
          // $scope 변수 중에서 필요한 데이터만 추출해 저장
          const temp_scope = _.pick($scope, ['checkEsmMaster', 'esmMatchShops', 'checkGrowth', 'add_opt', 'add_opt_table', 'categoryListModal', 'categoryName', 'categoryOptionsFlag', 'categorySearchKey', 'centerAnchor', 'certDataList', 'certlist', 'channelSelectAll', 'checked', 'data', 'disabled', 'flag', 'madein_group_list', 'madein_name_list', 'onlineOptionsList', 'opTabActive', 'openTemplate', 'optInValidTab', 'overlapResult', 'prodOpt', 'sku_list', 'stock', 'tabActive', 'tabDisabled', 'template_group_no', 'use_shops']);
          const stringified = JSON.stringify(temp_scope);

          onlineProductModel.temporarySaveAdd(stringified, function (state, res) {
            if (res.result === 'success') {
              commonSVC.showToaster('success', '성공', '임시저장에 성공했습니다.');
            }
          });
        }
      });

    };

    // 임시 저장 데이터 로드
    $scope.temporarySaveDetail = async () => {
      const redata = commonSVC.openModal('lg', {}, 'SaveTemporaryOnlineCtrl', 'views/online/product/modals/save_temporary_online.html');

      redata.result.then(function (re) {
        onlineProductModel.temporarySaveDetail(re.tmpsave_no, async function (state, res) {
          const [{ save_data, addcontent_exist_yn, shop_sale_name_addcontent_exist_yn }] = res.results;

          // 임시저장된 머리말/꼬리말 템플릿이 삭제된 경우 - 상세설명
          if (save_data.data.addcontent_no && !addcontent_exist_yn) {
            commonSVC.showMessage('머리말/꼬리말 템플릿 오류', '삭제된 상세설명 머리말/꼬리말 템플릿입니다. 머리말/꼬리말 템플릿을 다시 선택해주세요.');

            save_data.data.addcontent_name = '';
            save_data.data.addcontent_no = null;
            save_data.tabActive = 4;
          }

          // 임시저장된 머리말/꼬리말 템플릿이 삭제된 경우 - 상품명
          if (save_data.data.shop_sale_name_addcontent_no && !shop_sale_name_addcontent_exist_yn) {
            commonSVC.showMessage('머리말/꼬리말 템플릿 오류', '삭제된 상품명 머리말/꼬리말 템플릿입니다. 머리말/꼬리말 템플릿을 다시 선택해주세요.');

            save_data.data.shop_sale_name_addcontent_name = '';
            save_data.data.shop_sale_name_addcontent_no = null;
            save_data.tabActive = 2;
          }

          // 옵션 정보 초기화
          basicOptCheckListInit();
          certDataInit();
          tmpsave_no = re.tmpsave_no;
          $scope.loadTemporary = true;
          $scope.overlapResult = null; // 판매자관리코드 중복확인 해제

          _.merge($scope, save_data);

          // 임시저장된 카테고리와 솔루션 카테고리와 다를 때 처리
          const tempSaveList = res.results[0].save_data.data.site_list;

          // 에픽카 OEM 부품 번호 체크 박스 체크 여부
          if (tempSaveList.some(shop => shop.pa_shop_cd === 'P051')) {
            $scope.efcCheck.oemChecked = tempSaveList.some(shop => shop.pa_shop_cd === 'P051' && shop.shop_promotion_name === 'OEM 부품번호 알 수 없음');
            $scope.efcCheck.modelChecked = tempSaveList.some(shop => shop.pa_shop_cd === 'P051' && shop.model_no === '차대번호 알 수 없음');
          }

          let data;

          // 2.3.4 배포 이후 카테고리 코드 없이 임시저장된 상품 있어서 카테고리 코드 없을 경우 추가 처리
          if (res.results[0].save_data.data.sol_cate_no) {
            const categoryParams = {
              shop_cds: [],
              shop_ids: [],
              sol_cate_no: res.results[0].save_data.data.sol_cate_no
            };

            angular.forEach(res.results[0].save_data.data.site_list, function(row) {
              categoryParams.shop_cds.push(row.shop_cd);
              categoryParams.shop_ids.push(row.shop_id);
            });

            data = (await categoryModel.shopCategorysLoad(categoryParams)).data.data;
          } else {
            data = res.results[0].save_data.data.site_list;
          }

          if (data) {
            res.results[0].save_data.data.site_list = [];

            data.forEach(site => {
              tempSaveList.forEach((temp, idx) => {
                if (temp.shop_cd === site.shop_cd && temp.shop_id === site.shop_id) {
                  res.results[0].save_data.data.site_list.push(tempSaveList[idx]);
                }
              });
            });

            // 배열 순서 변경되지 않도록 다시 정렬
            res.results[0].save_data.data.site_list.sort((a, b) => a.idx - b.idx);

            _.merge($scope, res.results[0].save_data);

            if ($scope.checked.GALLERIA) {
              $scope.getGalleriaCate();
            }

            if ($scope.checked.NAVER) {
              naverCateLoad(res.results[0].save_data.data.site_list.filter((row) => row.pa_shop_cd === 'A077'));
            }

            // 임시저장 카테고리 존재하지 않을 시 & 저장된 쇼핑몰 없을 시 카테고리 재설정
            if (!$scope.validationCategory($scope.categoryName) || !data.length) {
              $scope.tabActive = 1;

              commonSVC.showMessage('임시저장된 카테고리가 변경되었습니다.\n 재설정하여 주시길 바랍니다.');
            }

            // 임시저장 상품 불러올 떄 동일옵션적용도 반영
            Object.keys($scope.checked).forEach((k) => {
              if (k.includes('_defaultOpt') && $scope.checked[k]) {
                const temp_list = k.split('_');

                if (temp_list.length === 2) {
                  optOverwrite('', `${temp_list[0]}_`);
                } else if (temp_list.length === 3) {
                  optOverwrite(`${temp_list[0]}_`, `${temp_list[1]}_`);
                }
              }
            });

            $scope.shopList = data;
            $scope.categoryCode = $scope.shopList[0].sol_cate_no;

            $scope.infoLoad('tempData');

            // 쇼핑몰 별 추가이미지 url 불러오기
            _.forEach($scope.data.site_list, function (site) {
              if (!_.isEmpty(site.sub_img_box)) {
                _.forEach(site.sub_img_box, function (si_box) {
                  if (si_box.files) {
                    site[si_box.en_name].files = si_box.files;
                  }
                });
              }
            });

            if ($scope.data.prod_info_opt !== '') {
              temp_list = angular.copy($scope.data.prod_noti_data_new);
              prod_info_open_type = 'edit';
            } else {
              temp_list = [];
              prod_info_open_type = 'add';
            }

            if ($scope.data.madein_type) {
              $scope.madein_type_change();
            }
            if ($scope.data.madein_group) {
              $scope.madein_country_change();
            }

            $scope.data.esmDirectOptionEnable = true;

            const esmDirectOptionDisableCategorys = await categoryModel.getEsmDirectOptionDisableCate();
            commonSVC.getShopList($scope.data.site_list, ['A001', 'A006']).forEach(({ match_cate_code }) => {
              //매칭된 카테고리의 옥션, 지마켓
              if (match_cate_code) {
                // 옵션 직접 등록 가능한 카테고리인지 확인.
                if ($scope.data.esmDirectOptionEnable) {
                  $scope.data.esmDirectOptionEnable = !(new Set(esmDirectOptionDisableCategorys.data.result)).has(match_cate_code.replace(/_/g, '').padEnd(8, '0'));
                }
              }
            });

            $scope.categoryOptions();

            $scope.changeTab($scope.tabActive);

            // 임시저장 flag 초기화(timeout 0으로 걸어도 상관없지만 혹시 몰라 1000으로 해둠)
            $timeout(function () {
              // 임시저장의 경우 ESM 입력형 옵션 select2에 넣어주는 처리
              $('.esm_input_opt').val($scope.prodOpt.esm_input_name).trigger('change');
            }, 1000);
          }
        });
      });
    };

    //추가구매옵션 템플릿
    $scope.addOptTemplate = function () {
      const modal = commonSVC.openModal('xg', {}, 'addOptTemplate', 'views/online/product/option/add_opt_template.html');

      modal.result.then(function (re) {
        $scope.prodOpt.add_opt = re.add_opt;
        $scope.prodOpt.add_opt_table = re.add_opt_table;
      });
    };

    //옵션 불러오기
    $scope.importOpt = function(type) {
      const resolve = {};

      resolve.data = {
        currentOpt: currentOpt,
        type: type,
        warehouseList: $scope.warehouseList,
        systemList,
        detailType: $scope.detailType
      };

      const modal = commonSVC.openModal('xg', resolve, 'importOpt', 'views/online/product/option/import_option.html');

      modal.result.then(function (re) {
        // 세트상품 아닌 옵션 추출
        if (type !== '추가구매') {
          // 로켓그로스 관련 상품일 경우
          if (['rocket_', 'cpang_'].includes(currentOpt)) {
            $scope.data.checkGrowth.coupang = re.optData.some(opt => opt.deal_type === '기초상품');
            $scope.data.checkGrowth.rocket = re.optData.some(opt => opt.deal_type === '3자배송');

            if ($scope.data.checkGrowth.rocket) {
              const availableGrowth = $scope.changeCoupangRocket('rocket');

              if (!availableGrowth) {
                return false;
              }
            }
          } else { // 로켓그로스 옵션 테이블이 아닐 경우 3자배송(그로스 옵션) 데이터를 제외
            re.optData = re.optData.filter(opt => opt.deal_type === '기초상품');
          }

          $scope.data[`${currentOpt}opt_type`] = re.opt_type;

          const optData = onlineProductSVC.initOpt(re, angular.copy($scope.prodOpt), currentOpt);

          let optSubTable = optData.optData;

          if ($scope.data.checkGrowth.available) {
            // 로켓그로스만 등록인 경우 옵션 데이터 deal_type = 3자배송 밖에 없기 때문에 해당 값으로 옵션 테이블 만들어줌
            if ($scope.data.checkGrowth.coupang) {
              optSubTable = optData.optData.filter(opt => opt.deal_type === '기초상품');
            } else if ($scope.data.checkGrowth.rocket) {
              optSubTable = optData.optData.filter(opt => opt.deal_type === '3자배송');
            }
          }

          if (re.opt_type == '옵션없음' && !currentOpt) {
            $scope.prodOpt.matchStockList = angular.copy(optData.matchStockList);
          } else {
            $scope.prodOpt[`${currentOpt}opt`] = angular.copy(optData.opt);

            // esm 상품은 추천옵션 포함한 경우 추천옵션 정보 설정해야 함
            if (currentOpt === 'esm_') {
              $scope.prodOpt.esm_opt.forEach((opt, i) => {
                const recommendOption = $scope.data.esm_options.find(cateOpt => opt.name === cateOpt.name);

                if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
                  opt.cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName);

                  $timeout(() => {
                    const select2 = angular.element(document.querySelector(`#esm_opt_value${i}`));

                    if (select2) {
                      select2.controller('multiSelectCombo').destroySelect2(select2);
                    }

                    opt.attr = opt.attr.split(',');
                    $scope.selectOptList[i] = [
                      ...opt.cateOpAttr,
                      ...opt.attr
                        .filter(value => !opt.cateOpAttr.some(item => item.ObjOptEssenName === value))
                        .map(opt => { return { ObjOptEssenName: opt }; })
                    ];

                    $timeout(() => {
                      select2.controller('multiSelectCombo').initSelect2(select2, {});
                    }, 0);
                  });
                } else {
                  opt.cateOpAttr = [];
                }
              });
            }
          }

          const data = { opt: angular.copy($scope.prodOpt[`${currentOpt}opt`]) };

          if (re.opt_type !== '옵션없음') {
            $scope.prodOpt[`${currentOpt}options_table`] = onlineProductSVC.initOptionsTable({ ...data, std_ol_yn: $scope.checked.smileProd ? 2 : 0 }, $scope.data[`${currentOpt}opt_type`], optSubTable);
          }

          if ($scope.data.checkGrowth.available) {
            if (re.opt_type == '옵션없음') {
              // 로켓그로스만 등록인 경우 옵션 데이터 deal_type = 3자배송 밖에 없기 때문에 해당 값으로 옵션 테이블 만들어줌
              const opt = angular.copy($scope.prodOpt.matchStockList[0]);
              let prodImg = '/assets/images/upload.png';

              ['coupang', 'rocket'].forEach(shop => {
                opt[shop] = optData.optData.find(opt => opt.deal_type === (shop === 'coupang' ? '기초상품' : '3자배송')) || {};

                opt[shop].deal_type = opt[shop].deal_type || '기초상품';
                opt[shop].safe_stock = opt[shop].stock_cnt_safe;
                opt[shop].cnt_limit = opt[shop].sale_opt_cnt_limit;
                opt[shop].prod_weight = opt[shop].opt_weight;
                opt[shop].prod_status = opt[shop].opt_status || '정상';

                if (opt[shop].prod_img) {
                  prodImg = opt[shop].prod_img;
                }

              });
              opt.prod_img = prodImg;

              $scope.prodOpt[`${currentOpt}matchStockList`] = [angular.copy(opt)];
            } else {
              $scope.prodOpt[`${currentOpt}options_table`].forEach(opt => {
                const matchOpts = optData.optData.filter(o => o.opt_name1 === opt.attr1 && o.opt_name2 === opt.attr2 && o.opt_name3 === opt.attr3);

                for (const o of matchOpts) {
                  o.prod_status = o.opt_status || '정상';
                  o.prod_img = o.prod_img || '/assets/images/upload.png';
                  o.cnt_limit = o.sale_opt_cnt_limit;
                  o.safe_stock = o.stock_cnt_safe || data.safe_stock_default;
                  o.prod_weight = o.opt_weight;

                  opt[o.deal_type === '기초상품' ? 'coupang' : 'rocket'] = angular.copy(o);
                }

                if (!opt.coupang) {
                  opt.coupang = { prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' };
                }
                if (!opt.rocket) {
                  opt.rocket = { prod_img: '/assets/images/upload.png', cnt_limit: 0, sku_cd: '', prod_status: '정상' };
                }
              });
            }
          }

          $timeout(function () {
            $scope.prodListSet();
          });
        } else {
          const addOptData = onlineProductSVC.initAddOpt(re.optData);

          $scope.prodOpt.add_opt = addOptData.add_opt;
          $scope.prodOpt.add_opt_table = addOptData.add_opt_table;
        }
      });
    };

    // 쿠팡 로켓그로스 selectbox 선택
    $scope.changeCoupangRocket = (type) => {
      if (!$scope.data.checkGrowth[type]) {
        const otherShop = type === 'rocket' ? 'coupang' : 'rocket';

        if (!$scope.data.checkGrowth[otherShop]) {
          $scope.data.checkGrowth[otherShop] = true;

        }
      }

      if ($scope.data.checkGrowth.rocket) {
        let msg = '';

        if ($scope.data.site_list.some(ch => ch.pa_shop_cd === 'B378' && ch.etc6 !== 'true')) {
          msg = '로켓그로스를 사용할 수 없는 계정이 있습니다.\n[설정 > 쇼핑몰 계정관리]에서 쿠팡계정 선택 후 로켓그로스 상품 사용여부를 확인해 주세요.';
        } else if (!$scope.data.is_possible_growth_cate) { // 카테고리 확인
          msg = '로켓그로스에서 사용 불가한 카테고리입니다.\n해당 카테고리는 판매자배송 상품만 선택 가능합니다.';
        }

        if (msg) {
          $scope.data.checkGrowth.rocket = false;
          $scope.data.checkGrowth.coupang = true;
          commonSVC.showMessage(msg, '');

          return false;

        }

      }

      //대표상품 리스트 재 검색
      $scope.prodListSet();

      $timeout(() => {});

      return true;
    };

    // 29cm 상세설명 리스트 추가
    // ESM 마스터 상품 조합 리스트 추가 (계정 선택 여부도 초기화)
    $scope.clickEvent = (index, type, form) => {
      if (type.includes('del')) {
        if (form.length > 1) {
          if (type === 'delEsm') {
            Object.values($scope.esmMatchShops[index]).forEach(shop => {
              $scope.canEsmMasterList.forEach(s => {
                if (s.shop_cd === shop.shop_cd && s.shop_id === shop.shop_id && s.etc7 === shop.etc7) {
                  s.checked = false;
                }
              });
            });

          }
          form.splice(index, 1);
        } else {
          form = [];
        }
      } else {
        if (type === 'addEsm') {
          form.push({ gmarket: {}, auction: {} });

          checkedEsmTabDisabled();
        } else {
          form.push({});
        }
      }
    };
    // 29cm 상세 설명 이미지 타입 변경 시 초기화
    $scope.changeDescImg = function(image_type, site, index) {
      site[index] = { image_type: image_type };
    };

    /**
     * 이미지호스팅 모달 열기
     */
    $scope.openImageHostingModal = (type, model = 'main', shop_cd = '', shop_id = '', imgIdx = 0, site = null) => {
      const modal = $rootScope.openImageHosting(type);

      modal.result.then((hostImages) => {
        if (hostImages) {
          if (type === 'html') {
            // 상세설명
            if (model === 'main') {
              $scope.data.detail_desc = ($scope.data.detail_desc || '') + '\n<br />\n' + hostImages.join('\n<br />\n');
            }
            // 기타 상세설명
            else if (model) {
              $scope.data[model] = ($scope.data[model] || '') + '\n<br />\n' + hostImages.join('\n<br />\n');
            }
            // 쇼핑몰 개별 상세설명
            else {
              const stie = $scope.data.site_list.find(site => site.shop_cd === shop_cd && site.shop_id === shop_id);

              stie.html_editor = (stie.html_editor || '') + '\n<br />\n' + hostImages.join('\n<br />\n');
            }
          } else {
            // 파일명이 한글인경우 정상조회가 안되는 경우가 있어 encodeURI 적용.
            if (model === 'default') {
              $scope.data.img_objects[imgIdx].files = encodeURI(hostImages[0]);
            } else {
              site.sub_img_objects[imgIdx].files = encodeURI(hostImages[0]);
              site.sub_img_objects[imgIdx].sub_img_url = encodeURI(hostImages[0]);
            }
          }
        }
      });
    };
  })

/**
 * 카테고리선택 filter
 */
  .filter('cateFilter', function () {
    return function (items, props) {
      let out = [];

      if (angular.isArray(items)) {
        const keys = Object.keys(props);

        items.forEach(function (item) {
          let itemMatches = false;

          for (let i = 0; i < keys.length; i++) {
            const prop = keys[i];
            const text = props[prop].toLowerCase();

            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
      // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  })

  .filter('propsFilter', function () {
    return function (items, props) {

      let out = [];

      if (angular.isArray(items)) {
        const keys = Object.keys(props);

        items.forEach(function (item) {
          let itemMatches = false;

          for (let i = 0; i < keys.length; i++) {
            const prop = keys[i];
            const text = props[prop].toLowerCase();

            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
      // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  });
