'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-P015-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, shopAccountModel, onlineProductModel, commonModel, commonSVC) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    // 말머리 추가에 사용
    $scope.shop_sale_name = data.data.shop_sale_name;
    $scope.brand = data.data.brand;
    $scope.cate_code = data.data.cate_cd;
    $scope.model_yn = $scope.cate_code.includes('243100100') || $scope.cate_code.includes('244101100') || $scope.cate_code.includes('245100100'); // 카테고리 의류, 신발일 때 모델 영역 활성화
    $scope.size_yn = $scope.cate_code.includes('243100100') || $scope.cate_code.includes('245100100'); // 카테고리 의류일 때 사이즈 영역 활성화
    $scope.pa5_job_data = {
      get_filters: {
        load: false,
        data: []
      }
    };

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          // 선택된 필터 체크 처리
          $scope.pa5_job_data[job_name].data.map((data) => {
            data.parent_filters.forEach((filtered => {
              $scope.row.set_info.key_search_filtered.map((selected) => {
                if (selected.item_filter_no === filtered.filter_no) {
                  filtered.checked = true;
                }
              });
            }));
          });

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
          $('.box-loading').addClass('hidden'); //로딩중
        } else {
          $('.box-loading').addClass('hidden'); //로딩중
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    // 리스트 추가
    $scope.clickEvent = (index, type, form) => {
      if (type === 'del') {
        if ($scope.row.set_info[form].length > 1) {
          $scope.row.set_info[form].splice(index, 1);
        } else {
          $scope.row.set_info[form] = [];
        }
      } else {
        $scope.row.set_info[form].push({});
      }
    };

    /**
     * 이미지파일 원본보기 모달.
     * @param {이미지파일} img
     */
    $scope.imgModal = function (imgUrl) {
      const img = {
        code: 'gift_img',
        files: imgUrl,
        name: '사은품이미지',
      };
      const resolve = {
        data: {
          file: img
        }
      };
      commonSVC.openModal('lg', resolve, 'olImgModal', 'views/online/product/olProdImg.html');
    };

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, name) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              $scope.row.set_info.gift_img_form[name] = v.url;
              $scope.row.set_info.gift_img_form[`${name}_name`] = v.origFileName;
            }
          });
        });
    };

    $scope.delShopImg = function (name) {
      $scope.row.set_info.gift_img_form[name] = '';
    };

    $scope.prodInfoDesc = (type) => {
      const flag = type + '_flag';
      if ($scope.row.set_info[flag] === 'ref') {
        $scope.row.set_info[type] = '[상세설명참조]';
      } else {
        $scope.row.set_info[type] = '';
      }
    };

    const init = async () => {
      try {
        const cate = $scope.cate_code.split('_').reverse()[0];
        run_pa5_jobs('get_filters', '검색필터', { category_no: cate }, (data) => {
          const filterData = data.map((item) => {
            const filtered = {
              category_filter_binding_no: item.category_filter_binding_no,
              filter_name: item.filter_no.filter_name,
              parent_filters: item.filter_no.parent_filters,
              filter_no: item.filter_no.filter_no,
              ...(item.filter_name === '색상' && { color_filtered: true })
            };

            return filtered;
          });

          return filterData;
        });
      } catch (e) {
        commonSVC.showMessage('실패', '검색필터 정보 로드를 실패했습니다.');
      }

    };

    init();
    $scope.answer = [];
    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {

      $scope.row.set_info.key_search_filtered = [];

      // 선택된 필터 데이터 저장
      $scope.pa5_job_data.get_filters.data.map((data) => {
        data.parent_filters.forEach((filtered => {
          if (filtered.checked) {

            $scope.row.set_info.key_search_filtered.push({
              category_filter_binding_no: data.category_filter_binding_no,
              filter_no: data.filter_no,
              item_filter_no: filtered.filter_no,
              item_filter_value: filtered.filter_name,
            });
          }
        }));
      });
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });