'use strict';

angular.module('gmpApp')
  .controller('SubOnlineListCtrl', function (
    $scope, $rootScope, $filter,
    settings, userInfo,
    commonSVC, productSVC, onlineProductSVC,
    productModel, systemModel, onlineProductModel,
    gettextCatalog, systemList, subUserList, subChannelList) {

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(onlineProductSVC.searchDataSub);
    $scope.searchForm = angular.copy(onlineProductSVC.searchFormSub);
    $scope.searchBtn = {
      table_actions: [
        {
          label: gettextCatalog.getString('헤더/푸터 적용'),
          add_class: 'a-btn-blue',
          perm_only: ['online.product'],
          action: function () {
            $scope.prodAddcontent();
          },
        },
        {
          label: gettextCatalog.getString('헤더/푸터 해제'),
          add_class: 'a-btn-blue',
          perm_only: ['online.product'],
          action: function () {
            $scope.prodAddcontentRelease();
          },
        },
        {
          label: gettextCatalog.getString('엑셀 다운로드'),
          add_class: 'a-btn-blue',
          action: () => {
            $scope.excelDown();
          },
        }
      ]
    };

    // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
    // 값이 있는경우에만 설정기한 사용하도록 함. 기본은 솔루션 신청일. 2018-03-07 rony
    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }

    const channelListArr = subChannelList.data.results || [];
    const searchDetail = [
      {
        title: gettextCatalog.getString('대리점(특약점) 전체'),
        search_name: 'search_sol_no',
        item_list: subUserList?.data || [],
        item_key: 'company_name',
        item_value: 'sol_no',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('쇼핑몰 전체'),
        search_name: 'shop_cd',
        item_list: angular.copy(_.unionBy(channelListArr, 'shop_cd')),
        item_key: 'shop_name',
        item_value: 'shop_cd',
        search_word: '',
        add_class: 'select-search'
      },
      {
        title: gettextCatalog.getString('계정 전체'),
        search_name: 'shop_id',
        item_list: angular.copy(channelListArr),
        item_key: 'shop_id',
        item_value: 'shop_id',
        search_word: '',
        add_class: 'select-search',
        filter: function (option) {
          // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
          return option.shop_cd == $scope.searchForm.shop_cd;
        }
      },
      {
        title: gettextCatalog.getString('상품상태 전체'),
        search_name: 'sale_status',
        item_list: [
          { name: '판매대기', id: '판매대기' },
          { name: '승인대기', id: '승인대기' },
          { name: '반려', id: '반려' },
          { name: '판매중', id: '판매중' },
          { name: '종료대기', id: '종료대기' },
          { name: '수정대기', id: '수정대기' },
          { name: '판매중지', id: '판매중지' },
          { name: '일시품절', id: '일시품절' }],
        item_key: 'name',
        item_value: 'id',
        search_word: ''
      },
      {
        title: gettextCatalog.getString('상품 기술서 Header/Footer 전체'),
        search_name: 'addcontent_type',
        item_list: [
          { code: '공지사항', stock_status: '공지사항' },
          { code: '배송지연', stock_status: '배송지연' },
          { code: '이벤트', stock_status: '이벤트' },
          { code: '기타', stock_status: '기타' },
        ],
        item_key: 'stock_status',
        item_value: 'code',
        select_value: ''
      },
    ];

    // 상세 조건검색 리스트
    $scope.searchDetail = angular.copy(searchDetail);

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.selectRowUniqList = [];

    $scope.isMemoDisabled = (prod_no) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(prod_no);

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ prod_no }) => prod_no);
      $scope.$apply();
    }, 200));

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'sale_img1', 'sale_status', 'company_name', 'mas_addcontent_no', 'mas_addcontent_no', 'nextday_ship_ol_yn', 'nextday_ship_avail_yn'],
      alignRightColumns: ['sale_price', 'sale_cnt_limit'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'mdate', 'sale_img1'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/online/sub-list`,
        requestWillAction: function (data) {
          if ($rootScope.memo_etc_search.page === 'product' && $rootScope.memo_etc_search.search) {
            const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

            $scope.searchForm = {
              ...$scope.searchForm,
              date_type,
              sdate,
              edate,
              search_key,
              search_word,
              search_type: 'exact'
            };
            $rootScope.memo_etc_search.search = false;
          }

          if ($rootScope.multi_search && $rootScope.multi_search.productList) {
            angular.merge($scope.searchForm, $rootScope.multi_search.productList);

            delete $rootScope.multi_search.productList;
          }

          if ($rootScope.side_search.page === 'online' && $rootScope.side_search.search) {
            $scope.resetDo(true);

            $scope.searchDetail[0].select_value = $rootScope.side_search.sub_sol_no || '';
            $scope.searchDetail[3].select_value = $rootScope.side_search.sale_status || '';

            $scope.searchForm.search_sol_no = $rootScope.side_search.sub_sol_no;
            $scope.searchForm.sale_status = $rootScope.side_search.sale_status;

            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $rootScope.side_search.search = false;
          }

          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          data.sol_no = $rootScope.user_profile.sol_no;

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function (row) {
            const location = 'KR';

            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.onlineProductEdit('${row.shop_cd}','${row.shop_id}',${row.ol_shop_no},'${location}', ${row.sol_no})" uib-tooltip="{{'상세보기'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">상세</button>`;
          }
        },
        // 등록일
        {
          key: 'company_name',
          title: '대리점',
          width: 150,
        },
        {
          key: 'sale_img1',
          title: '이미지',
          width: 60,
          notCompile: true,
          template: function(row) {
            //이미지
            let img = '';

            if (row.sale_img_url) {
              img += row.sale_img_url || '';
              img += row.sale_img1 || '';
            } else {
              img = '/assets/images/noimage.png';
            }

            return `<img src='${img}' width='20' height='20' onerror='this.src="/assets/images/noimage.png"'>`;
          }
        },
        // 등록일
        {
          key: 'wdate',
          title: '등록일',
          width: 150,
          filter: 'dateValid',
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 140,
          important: true,
        },
        {
          key: 'sol_cate_name_first',
          title: '카테고리',
          width: 120,
          template: (row) => {
            // 실제 등록 사이트 카테고리 2018-06-15 Daniel update
            const cate = [];
            let cate_name = '';

            for (let i = 1; i <= 8; i++) {
              const c = row[`shop_cate${i}`];

              if (c) {
                cate.push(c);
              }
            }

            if (
              !row.shop_cate1 ||
              row.shop_cate1 == '임시' ||
              row.sol_cate_name_first == '임시'
            ) {
              cate_name =
                '<span uib-tooltip="{{\'상품상세에서 카테고리를 지정해주세요.\'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">-</span>';
            } else if (cate.length > 0) {
              cate_name = cate.join(' > ');
            }

            return row.sol_cate_name_first || cate_name;
          }
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {

            return row.shop_cd === 'Z000'
              ? '마스터상품'
              : `<span uib-tooltip="${row.shop_name}(${row.shop_id})` +
                  `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${
                    row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd
                  }.png" style="width: 50px;">${row.shop_id}</span>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 250,
          notCompile: true,
          template: function(row) {
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            // 일반상품인데 DB에 프로모션 상품명이 들어가있는 경우가 있어서 단일일때만 프로모션명 보여주도록 변경
            const sale_name =
              row.shop_promotion_name && row.std_ol_yn
                ? `${filteredShopSaleName} ${row.shop_promotion_name}`
                : filteredShopSaleName;

            // return '<div class="cursor-pointer"><span id=' + row.ol_shop_no + ',' + row.shop_cd + ',' + row.shop_id + '>' + sale_name + '</span></div>';
            return sale_name;
          }
        },
        {
          key: 'sale_status',
          title: '상품상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            if (row.shop_cd === 'Z000') {
              return '';
            }

            return $filter('statusColorOl')(row.sale_status);
          }
        },
        {
          key: 'sale_cnt_limit',
          title: '수량',
          width: 50,
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 140,
          template: function(row) {
            const sale_no = row.shop_sale_no || '';
            const shop_cd = row.shop_cd;
            const shop_id = row.shop_id;
            let sale_no_link = '';

            if (shop_cd == 'B378') {
              if (row.shop_sale_no_sub && row.shop_sale_no_sub.length === 10) {
                sale_no_link = `${sale_no}?vendorItemId=${row.shop_sale_no_sub}`;
              } else {
                sale_no_link = row.shop_sale_no_sub;
              }
            } else {
              sale_no_link = sale_no;
            }

            if (
              sale_no &&
              !_.includes(onlineProductSVC.impossibeDetailView, shop_cd)
            ) {
              return `<span>${sale_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${shop_cd}','${shop_id}','${sale_no_link}', ${row.sol_no})"></i></span>`;
            } else {
              return sale_no;
            }
          }
        },
        // 판매가
        {
          key: 'sale_price',
          title: '판매가',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        // 모델번호(SKU상품)
        {
          key: 'model_no',
          title: '모델번호',
          width: 130,
          template: function (row) {
            let modelNo = row.model_no || '';

            if (modelNo.toUpperCase().trim() === 'NULL') {
              modelNo = '';
            }

            return modelNo;
          }
        },
        // 브랜드
        {
          key: 'brand',
          title: '브랜드',
          width: 130
        },
        {
          key: 'nextday_ship_ol_yn',
          title: '내일 배송 상품 여부',
          width: 120,
          notCompile: true,
          template: function(row) {
            return row.nextday_ship_ol_yn
              ? '<i class="icon-primitive-dot text-success-600"></i>'
              : '<i class="icon-primitive-dot text-grey-100"></i>';
          }
        },
        {
          key: 'nextday_ship_avail_yn',
          title: '내일 배송 가능 여부',
          width: 120,
          notCompile: true,
          template: function(row) {
            return row.nextday_ship_avail_yn
              ? '<i class="icon-primitive-dot text-success-600"></i>'
              : '<i class="icon-primitive-dot text-grey-100"></i>';
          }
        },
        {
          key: 'mas_addcontent_no',
          title: '헤더/푸터 템플릿번호',
          width: 150,
          notCompile: true,
        },
        {
          key: 'mas_addcontent_name',
          title: '헤더/푸터 템플릿명',
          width: 150,
          notCompile: true,
        },
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = (refresh, noDelay) => {
      $scope.grid.methods.reloadData(null, refresh, noDelay, true);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = (noSearch = false) => {
      $scope.searchForm = angular.copy(productSVC.searchFormSub);
      $scope.searchData.search_key_name = '전체';
      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }

      $scope.searchDetail = angular.copy(searchDetail);

      if (!noSearch) {
        $scope.searchDo(true, true);
      }
    };

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = function () {
      // 2018-08-13 chris 탭에 맞는 그리드 갱신
      $scope.activeTap === 'super' ? $scope.superProductGrid.methods.length($scope.searchData.showCount) : $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 수정 모달열기
     */
    $scope.onlineProductEdit = function (shop_cd, shop_id, ol_shop_no, location, sol_no) {
      const resolve = {};
      const data = {};

      data.shop_cd = shop_cd;
      data.shop_id = shop_id;
      data.ol_shop_no = ol_shop_no;
      data.country = location;
      data.sol_no = sol_no;

      resolve.data = data;

      resolve.prodDetail = function () {
        return onlineProductModel.detail(shop_cd, shop_id, ol_shop_no);
      };
      // 원산지 리스트 조회
      resolve.madeinList = function () {
        return productModel.getMadein();
      };
      //환경설정 셋팃값
      resolve.systemList = function () {
        return systemModel.load();
      };

      const modal = commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html', false, true, false, '');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });
    };

    $scope.prodAddcontent = () => {
      const select_list = $scope.grid.methods.selectedData('all');

      if (!select_list.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }
      const params = {
        data: {
          type: 'ol_shop',
          result: select_list
        }
      };

      const modal = commonSVC.openModal('full', params, 'MasAddcontentCtrl', 'views/online/product/modals/mas_addcontent.html', false, true, false);

      modal.result.then((result) => {
        if (result === 'refresh') {
          $scope.searchDo(false);
        }
      });
    };

    $scope.prodAddcontentRelease = async () => {
      const select_list = $scope.grid.methods.selectedData('all');

      if (!select_list.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      const confirm = await commonSVC.showConfirm('헤더/푸터 템플릿', `선택한 상품(${select_list.length})의 헤더/푸터 템플릿을 해제하시겠습니까?`);

      if (confirm) {
        const params = {
          select_list,
          type: 'ol_shop'
        };
        const release_res = await onlineProductModel.masAddcontentRelease(params);

        if (release_res.status === 200) {
          commonSVC.showToaster('success', '성공', '헤더/푸터 템플릿 해제에 성공했습니다.');
          $scope.searchDo(false);

        } else {
          commonSVC.showToaster('error', '실패', '헤더/푸터 템플릿 해제에 실패했습니다.');
        }
      }
    };

    /**
     * 엑셀 다운 모달창
     **/
    $scope.excelDown = () => {
      // 엑셀권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('online.roles.excelProd', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          type: 'agent',
          excelFieldList: [],
          title: '대리점(특약점) 온라인상품',
          url: '/app/product/online/excelDown',
          searchForm: $scope.pageData,
          isSuper: true,
          page: 'agency',
          visibleTable: $scope.grid.methods.getColumnsVisible(), // 활성화된 필드 데이터,
          progress: true
        }
      };

      const selectList = $scope.grid.methods.selectedData('all');

      if (!selectList.length) {
        if (!$scope.searchData.totalCount) {
          commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 쇼핑몰상품이 없습니다.'));

          return false;
        }

        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        resolve.data.select_list = selectList.map(({ ol_shop_no }) => ol_shop_no);
        resolve.data.count = selectList.length;
        resolve.data.isAll = false;
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 사이드바 집계 클릭시
     */
    $scope.$on('sidebarSearch_online_product', function() {
      $scope.searchDo(true, true);
    });
  });
