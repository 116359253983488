'use strict';

angular.module('gmpApp')
  .controller('AltalkListCtrl',
    function (
      $rootScope, $compile, $scope, systemList,
      settings, commonSVC, gridInfo, templateList,
      commonModel, atalkModel
    ) {
      $scope.tab = 'total';
      $scope.selectCount = 'sendTotal';
      const menu_data = gridInfo.menu_data ? gridInfo.menu_data : false;
      const typeAll = ['autoSend', 'sendTotal'];
      let plusIdList;
      $scope.loadDataTable = gridInfo;

      async function init () {
        $scope.countList = {
          autoSend: 0,
          sendTotal: 0
        };
        plusIdList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
      }

      init();

      const templateListArr = (templateList.data?.results).filter(v => v.inspection_status === 'APR').map(v => ({ template_id: v.id, template_name: v.name })); // 알림톡 템플릿 리스트
      let searchForm = {
        search_key: 'ALL',
        search_word: '',
        search_type: 'partial',
        date_type: 'send_time',
        talk_id: 'ALL',
        sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
      };
      $scope.searchForm = angular.copy(searchForm);

      const send_search = {
        searchData: {
          all_checked: false,
          totalCount: 0,
          showCount: 100,
          selectCount: 0,
          search_key_items: [
            { label: '전체', value: 'ALL' },
            { label: '템플릿명', value: 'name' },
            { label: '발송명', value: 'send_title' },
            { label: '발송자', value: 'invoked_by' },
          ],
          search_date_type: [
            { label: '발송일시', value: 'send_time' },
          ]
        },
        searchDetail: [
          {
            title: '템플릿명',
            search_name: 'template_name',
            item_list: angular.copy(templateListArr),
            item_key: 'template_name',
            item_value: 'template_name',
            select_value: '',
            add_class: 'select-search'
          },
          {
            title: '발송 유형',
            search_name: 'name',
            item_list: [ { send_type: '개별', name: '개별 발송' }, { send_type: '자동', name: '자동 발송' }, { send_type: '테스트', name: '테스트 발송' }],
            item_key: 'name',
            item_value: 'name',
            select_value: '',
            add_class: 'select-search',
          },
        ]
      };

      /**********************
       * 전체발송내역 DataTable
       * ********************/
      $scope.sendTotal = {}; // 전체발송내역 관련 변수
      $scope.sendTotal.searchData = angular.copy(send_search.searchData);
      $scope.sendTotal.searchForm = angular.merge({ page: 'sendTotal' }, angular.copy(send_search.searchForm));
      $scope.sendTotal.searchDetail = angular.copy(send_search.searchDetail);
      $scope.sendTotal.searchFn = {
        searchDo: function() {
          $scope.searchDo(true, true, 'sendTotal');
        },
        resetDo: function() {
          $scope.resetDo('sendTotal');
        },
        changeLen: function(count) {
          $scope.changeCount('sendTotal', count);
        }
      };
      $scope.searchBtn = {
        table_actions: [
          {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            ngIfFunc: function() {
              return $scope.tab === 'total' ? 'y' : 'n';
            },
            item_list: [
              {
                label: '전체엑셀 다운로드',
                action: function () {
                  excelDown('all');
                }
              },
              {
                label: '선택엑셀 다운로드',
                add_class: 'pr-20',
                action: function () {
                  excelDown('select');
                }
              }

            ]
          },
        ],
        actions_right: [
          {
            label: '<i class="icon-forward_to_inbox1"></i><span> 자동 발송 등록</span>',
            small_label: '자동 발송 등록',
            test_id: 'btn-atalkAuto-register',
            btn_type: 'button',
            add_class: 'col-xs-6',
            action: function() {
              $scope.autoAltalkSendRegister();
            }
          },
          {
            label: '<i class="icon-add"></i><span> 템플릿 추가</span>',
            small_label: '템플릿 추가',
            test_id: 'btn-atalkTemplate-add',
            btn_type: 'button',
            add_class: 'col-xs-6',
            action: function() {
              $scope.addAltalkTemplate();
            }
          }
        ],
        table_actions_top: [
          {
            label: '알림톡 연동',
            test_id: 'btn-atalk-link',
            icon_class: 'icon-link',
            action: function () {
              $scope.altalkLink();
            }
          },
          {
            label: '템플릿 관리',
            test_id: 'btn-template-manage',
            icon_class: 'icon-space_dashboard',
            action: function () {
              $scope.manage_template();
            }
          },
          {
            label: '발송 그룹 관리',
            test_id: 'btn-group-check',
            icon_class: 'icon-group',
            action: function () {
              $scope.manage_send_group();
            }
          },
        ],
        table_states_top: [
          {
            label: '전체발송내역',
            test_id: 'btn-state-total',
            icon_class: 'icon-mail',
            count_name: 'sendTotal'
          },
          {
            label: '자동발송등록내역',
            test_id: 'btn-state-auto',
            icon_class: 'icon-layers',
            count_name: 'autoSend'
          },
        ],
      };

      $scope.sendTotal.methods = {};
      $scope.sendTotal.options = {
        pinningColumns: ['widget'],
        alignCenterColumns: ['widget', 'send_time', 'send_target_cnt', 'is_send'],
        alignLeftColumns: ['send_type', 'template_name', 'send_title', 'plus_id', 'invoked_by'],
        alignRightColumns: [],
        defaultSortingColumns: ['send_time'],
        notSortingColumns: ['widget'],
        notResizingColumns: ['widget'],
        notMovingColumns: ['widget'],
        notVisibleColumns: [],
        externalRequestOptions: {
          requestUrl: `${settings.adminApiUrl}/api/marketing/atalk/list`,
          requestWillAction: function(data) {
            data = angular.merge({ type: 'total', sol_no: $rootScope.user_profile.sol_no, ...data }, $scope.searchForm);
            $scope.sendTotal.searchData.totalCount = $scope.countList.total;
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function(result) {
            $scope.sendTotal.searchData.totalCount = result.recordsTotal;
            $scope.countList.sendTotal = result.recordsTotal;

            return result.sendList;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 50,
            template: function (row) {

              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('total', '${row.id}', '${row.send_time}', '${row.send_type}', '${row.is_send}')" >상세</button>`;
            }
          },
          {
            key: 'send_time',
            title: '발송일시',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'plus_id',
            title: '플러스친구 ID',
            width: 200,
            template: function (row) {
              return row.plus_id ? `@${row.plus_id}` : '';
            }
          },
          {
            key: 'send_target_cnt',
            title: '발송 건수',
            width: 100
          },
          {
            key: 'send_type',
            title: '발송 유형',
            width: 100
          },
          {
            key: 'is_send',
            title: '발송상태',
            width: 100,
            template: function(row) {
              return row.is_send ? '-' : '예약';
            }
          },
          {
            key: 'send_title',
            title: '발송명',
            width: 200
          },
          {
            key: 'invoked_by',
            title: '발송자',
            width: 200
          },
          {
            key: 'template_name',
            title: '템플릿명',
            width: 200
          },
        ]
      };

      const auto_search = {
        searchData: {
          all_checked: false,
          totalCount: 0,
          showCount: 100,
          selectCount: 0,
          search_key_items: [
            { label: '전체', value: 'ALL' },
            { label: '템플릿명', value: 'name' },
            { label: '발송명', value: 'send_title' },
            { label: '등록자', value: 'invoked_by' },
          ],
          search_date_type: [
            { label: '자동발송 등록일시', value: 'created_at' }
          ]
        },
        searchDetail: [
          {
            title: '템플릿명',
            search_name: 'template_name',
            item_list: angular.copy(templateListArr),
            item_key: 'template_name',
            item_value: 'template_name',
            select_value: '',
            add_class: 'select-search'
          },
        ]
      };

      /**********************
       * 자동발송등록내역 DataTable
       * ********************/
      $scope.autoSend = {}; // 자동발송등록내역 관련 변수
      $scope.autoSend.searchData = angular.copy(auto_search.searchData);
      $scope.autoSend.searchForm = angular.merge({ page: 'autoSend' }, angular.copy(auto_search.searchForm));
      $scope.autoSend.searchDetail = angular.copy(auto_search.searchDetail);
      $scope.autoSend.searchFn = {
        searchDo: function() {
          $scope.searchDo(true, true, 'autoSend');
        },
        resetDo: function() {
          $scope.resetDo('autoSend');
        },
        changeLen: function(count) {
          $scope.changeCount('autoSend', count);
        },
        getAtalkList: function() {
          return $scope.atalkAutoRegList;
        }
      };

      $scope.autoSend.methods = {};
      $scope.autoSend.options = {
        pinningColumns: ['widget', 'use_yn'],
        defaultSortingColumns: ['created_at'],
        notSortingColumns: ['widget'],
        alignCenterColumns: ['widget', 'use_yn', 'created_at', 'auto_send_time', 'updated_at'],
        alignLeftColumns: ['plus_id', 'send_title', 'name', 'invoked_by'],
        alignRightColumns: [],
        notResizingColumns: ['widget'],
        notMovingColumns: ['widget', 'use_yn'],
        notVisibleColumns: [],
        externalRequestOptions: {
          requestUrl: `${settings.adminApiUrl}/api/marketing/atalk/auto-reg-list`,
          requestWillAction: function(data) {
            data = angular.merge({ type: 'auto', sol_no: $rootScope.user_profile.sol_no, ...data }, $scope.searchForm);

            return data;
          },
          requestDidAction: function(result) {
            $scope.autoSend.searchData.totalCount = result.recordsTotal;
            $scope.countList.autoSend = result.recordsTotal;
            $scope.atalkAutoRegList = result.results;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 50,
            template: function (row) {
              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('auto', '${row.id}')" >상세</button>`;
            }
          },
          {
            key: 'use_yn',
            title: '사용여부',
            width: 80,
            hideColVis: true,
            template: (row) => {
              row.use_yn = !!row.use_yn;

              return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
            }
          },
          {
            key: 'created_at',
            title: '등록일시',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'plus_id',
            title: '플러스친구 ID',
            width: 130,
            template: function (row) {
              return `@${row.plus_id}`;
            }
          },
          {
            key: 'auto_send_time',
            title: '발송시간',
            width: 130,
            template: function(row) {
              return row.auto_send_time || '즉시';
            }
          },
          {
            key: 'send_title',
            title: '발송명',
            width: 200
          },
          {
            key: 'name',
            title: '템플릿명',
            width: 200
          },
          {
            key: 'invoked_by',
            title: '등록자',
            width: 100
          },
          {
            key: 'updated_at',
            title: '최종수정일',
            width: 130,
            filter: 'dateValid'
          }
        ]
      };

      /**
       * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
       */
      $scope.$on('OnColumnChange', function (event, data) {
        _.each(data, function (row) {
          $compile(row)($scope);
        });
      });

      $scope.changeCount = function (type) {
        $scope[type].methods.length($scope[type].searchData.showCount);
      };

      $scope.searchDo = function (refresh, noDelay) {
        for (const t of typeAll) {
          $scope[t].methods.reloadData(function () {}, refresh, noDelay);
        }
      };

      $scope.resetDo = function() {
        $scope.searchForm = angular.copy(searchForm);

        $scope.autoSend.searchDetail = angular.copy(auto_search.searchDetail);
        $scope.sendTotal.searchDetail = angular.copy(send_search.searchDetail);

        $scope.searchDo(true, true);
      };

      /**
       * 리스트에서 사용여부 변경시
       */
      $scope.changeDo = async function ({ id, use_yn }) {
        try {
          const params = {
            task_id: id,
            use_yn,
            sol_no: $rootScope.user_profile.sol_no,
            m_no: $rootScope.user_profile.m_no,
            name: $rootScope.user_profile.user_name,
            log_contents: [`사용여부: ${use_yn ? 'OFF' : 'ON'} -> ${use_yn ? 'ON' : 'OFF'}`]
          };
          const result = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/updateAutoTask`, params);

          if (result.data.result === 'success') {
            commonSVC.showToaster('success', '성공', '알림톡 자동 발송 사용여부 수정에 성공하였습니다.');
          } else {
            throw Error;
          }
        } catch (error) {
          commonSVC.showToaster('error', '실패', '알림톡 자동 발송 사용여부 수정에 실패하였습니다.');

          return false;
        }
      };

      $scope.countSearch = function(type) {
        searchForm = {
          search_key: 'ALL',
          search_word: '',
          search_type: 'partial',
          date_type: type === 'sendTotal' ? 'send_time' : 'created_at',
          talk_id: 'ALL',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
        };

        switch (type) {
          case 'sendTotal':
            $scope.tab = 'total';
            $scope.selectCount = 'sendTotal';
            $scope.searchForm.date_type = 'send_time';
            $scope.searchForm.selectDate = '';
            break;
          case 'autoSend':
            $scope.tab = 'auto';
            $scope.selectCount = 'autoSend';
            $scope.searchForm.date_type = 'created_at';
            $scope.searchForm.selectDate = '';
            break;
        }

        $scope[type].searchFn.searchDo(true, true);
        $scope.selectCount = type;
      };

      /**
       * 상세페이지 보여주기
       * type: 'total' | 'auto'
       */
      $scope.showDetail = async function(type, uniq, time, send_type, is_send) {
        const resolve = {
          data: {
            uniq,
            time,
            send_type,
            ...(type === 'total' && { is_send })
          }
        };
        if (type === 'total') {
          const modal = commonSVC.openModal('lg', resolve, 'AltalkSendDetailCtrl', 'views/alimtalk/modals/detail.html');

          modal.result.then((re) => {
            if (re === 'success') {
              commonSVC.showToaster('success', '성공', '알림톡 예약 발송 삭제 성공');
              $scope.sendTotal.methods.reloadData(function () {}, true);
            }
          });
        } else if (type === 'auto') {
          const modal = commonSVC.openModal('x3g', resolve, 'AltalkAutoRegDetailCtrl', 'views/alimtalk/modals/auto_register_detail.html');

          modal.result.then((re) => {
            if (re === 'success') {
              $scope.autoSend.methods.reloadData(function () {}, true);
            }
          });
        }
      };

      /**
       * 알림톡 연동하기
       */
      $scope.altalkLink = function() {
        commonSVC.openModal('lg', {}, 'AltalkLinkCtrl', 'views/alimtalk/modals/link.html');
      };

      /**
       * 알림톡 템플릿 추가
       */
      $scope.addAltalkTemplate = async function() {
        commonSVC.openModal('x3g', { data: { plusIdList: plusIdList.data?.result } }, 'AltalkTemplateCtrl', 'views/alimtalk/modals/altalk_template_add.html');
      };

      /**
       * 템플릿 관리
       */
      $scope.manage_template = function() {
        commonSVC.openModal('x3g', {}, 'AltalkTemplateManageCtrl', 'views/alimtalk/modals/altalk_template.html');
      };

      /**
       * 발송 그룹 관리
       */
      $scope.manage_send_group = function() {
        commonSVC.openModal('lg', { data: { type: 'manage' } }, 'AltalkSendGroupCtrl', 'views/alimtalk/modals/altalk_send_group.html');
      };

      /**
       * 자동 발송 등록
       */
      $scope.autoAltalkSendRegister = function() {
        const modal = commonSVC.openModal('x3g', { data: { plusIdList: plusIdList.data?.result } }, 'AltalkAutoSendRegisterCtrl', 'views/alimtalk/modals/altalk_auto_send_register.html');
        modal.result.then((re) => {
          if (re === 'success') {
            $scope.autoSend.methods.reloadData(function () {}, true);
          }
        });
      };

      /**
       * 엑셀다운
       */
      const excelDown = (type) => {
        const resolve = {
          data: {
            type: type,
            excelFieldList: [
              { header: '발송일시', key: 'send_time', select: true },
              { header: '발송명', key: 'send_title', select: true },
              { header: '플러스친구 ID', key: 'plus_id', select: true },
              { header: '템플릿명', key: 'template_name', select: true },
              { header: '발송유형', key: 'send_type', select: true },
              { header: '발송자', key: 'invoked_by', select: true },
              { header: '휴대폰번호', key: 'phone', select: true },
              { header: '발송결과', key: 'status', select: true },
              { header: '차감건수', key: 'use_cnt', select: true },
              { header: '실패사유', key: 'sms_result', select: true },
              { header: 'SMS 상태', key: 'send_result', select: true }
            ],
            title: '전체발송내역',
            url: '/app/atalk/excel-down',
            searchForm: $scope.pageData,
            page: 'altalk'
          }
        };

        if (type === 'all') {
          resolve.data.count = $scope.sendTotal.searchData.totalCount;
          resolve.data.isAll = true;
          resolve.data.talk_id = 'ALL';

          if (!resolve.data.count) {
            commonSVC.showMessage('다운로드 가능한 리스트가 없습니다.');

            return false;
          }
        } else {
          const selectedList = $scope.sendTotal.methods.selectedData('all');

          if (!selectedList.length) {
            commonSVC.showMessage('선택된 리스트가 없습니다.');

            return false;
          } else {
            resolve.data.select_list = selectedList;
            resolve.data.count = selectedList.length;
            resolve.data.isAll = false;
          }
        }

        commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
      };
    });