/**
 * 내 상품 가격수정 예약 모달
 * 판매가 알람 등록 모달
 **/

'use strict';

angular.module('gmpApp')
  .controller('RegistPriceScheduleCtrl', function ($scope, data, warehouseList, $filter, $timeout, $uibModalInstance, commonSVC, userInfo, commonModel, productModel, systemModel, onlineProductModel, insightModel) {
    $scope.addedData = []; // 해당 상품으로 등록되어 있는 예약 리스트
    $scope.type = data.type; // 내 상품 가격수정, 비교상품 알람 등록 여부 (priceEdit : 내 상품 가격 수정, compareAlarm : 판매가 알림)
    $scope.mode = data.mode; // 모달 모드 (add : 등록, detail: 상세보기(수정 X))
    $scope.hours = Array.from({ length: 24 }, (_, i) => `${i + 1}`)
      .map((v) => v.padStart(2, '0'));
    $scope.minutes = $scope.seconds = Array.from({ length: 6 }, (_, i) => `${i * 10}`)
      .map((v) => v.padStart(2, '0'));

    $scope.placeholder = '드롭박스를 클릭하여 조건을 설정해주세요.';

    $scope.editData = {
      sale_price_criteria_type1: '',
      sale_price_criteria_type2: '',
      sale_price_criteria_type3: '',
      sale_price_criteria: '',
      first_sale_price: '',
      street_price: '',
      sale_price: '',
      opt_add_price: '',
      send_ol_shop_yn: false,
      agree_yn: false,
      s_hour: '',
      s_min: '',
      e_hour: '',
      e_min: '',
    };

    init();

    async function init() {
      $scope.prodData = (await insightModel.getInsightGroupDetail({ ol_shop_no: data.ol_shop_no, sale_opt_seq: data.sale_opt_seq || null })).data.results.find(ol => ol.insight_seq === 0);

      if ($scope.mode === 'detail') {
        $scope.editData = { ...data.scheduleData, ...($scope.type === 'priceEdit' && { send_ol_shop_yn: true, agree_yn: true }) };

        $scope.placeholder = `현재 ${$scope.editData.sale_price_criteria_type1}는 ${$filter('currency')($scope.editData.first_sale_price, '', 0) || 0}원이며 ${$filter('currency')($scope.editData.sale_price_criteria, '', 0)}원에 도달하면 ${$scope.type === 'priceEdit' ? '내 상품의 가격이 수정됩니다' : '알림이 발송됩니다'}`;

        $timeout(function () {

          // 수정 불가 시 전부 비활성화 처리
          $('#priceScheduleForm input').prop('disabled', true);
          $('#priceScheduleForm select').attr('disabled', true);
        }, 100);
      } else {
        $scope.editData.before_street_price = `${$scope.prodData.ol_street_price}`;
        $scope.editData.before_sale_price = `${$scope.prodData.ol_sale_price}`;

        if ($scope.prodData.pa_shop_cd === 'B378' || ($scope.prodData.pa_shop_cd === 'A077' && $scope.prodData.std_ol_yn === 1)) {
          $scope.editData.before_opt_add_price = `${$scope.prodData.ol_opt_add_price}`;
        }

        const result = $scope.type === 'priceEdit' ? await insightModel.priceEditScheduleList({ ol_shop_no: $scope.prodData.ol_shop_no }) : await insightModel.priceAlarmScheduleList({ ol_shop_no: $scope.prodData.ol_shop_no });

        // 등록되어 있는 가격수정 | 판매가 알림 리스트
        $scope.addedData = result.data.results;
      }

      if (userInfo.user.send_restrict_etime) {
        const sTime = userInfo.user.send_restrict_stime.split(':');
        const eTime = userInfo.user.send_restrict_etime.split(':');

        $scope.editData.s_hour = sTime[0];
        $scope.editData.s_min = sTime[1];
        $scope.editData.e_hour = eTime[0];
        $scope.editData.e_min = eTime[1];
      }

      $scope.userHtel = userInfo.user.htel ? [
        userInfo.user.htel.slice(0, 3), // 첫 3자리
        userInfo.user.htel.slice(3, 7), // 중간 4자리
        userInfo.user.htel.slice(7)     // 마지막 4자리
      ] : ['', '', ''];

      $timeout(() => {});
    }

    $scope.editCriteriaType = () => {
      if (!$scope.editData.sale_price_criteria_type1) {
        $scope.placeholder = '드롭박스를 클릭하여 조건을 설정해주세요.';
        $scope.editData.sale_price_criteria = '';

        return false;
      }
      if (!$scope.editData.sale_price_criteria_type2) {
        $scope.editData.sale_price_criteria_type3 = '';
        $scope.editData.sale_price_criteria = '';
      }

      let price = 0;

      if ($scope.editData.sale_price_criteria_type1 === '최저가') {
        $scope.editData.first_sale_price = price = $scope.prodData.comp_min_price;
      } else if ($scope.editData.sale_price_criteria_type1 === '최고가') {
        $scope.editData.first_sale_price = price = $scope.prodData.comp_max_price;
      } else if ($scope.editData.sale_price_criteria_type1 === '평균 판매가') {
        $scope.editData.first_sale_price = price = Math.floor($scope.prodData.comp_avg_price);
      }

      $scope.placeholder = `현재 ${ $scope.editData.sale_price_criteria_type1}는 ${$filter('currency')(price, '', 0) || 0}원이며`;

      if ($scope.editData.sale_price_criteria_type2) {
        switch ($scope.editData.sale_price_criteria_type2) {
          case '인상되면':
            $scope.editData.sale_price_criteria_type3 = '이상';
            $scope.editData.sale_price_criteria = price + 1;
            break;
          case '3%이상 인상되면':
            $scope.editData.sale_price_criteria_type3 = '이상';
            $scope.editData.sale_price_criteria = Math.floor(price * 1.03);
            break;
          case '인하되면':
            $scope.editData.sale_price_criteria_type3 = '이하';
            $scope.editData.sale_price_criteria = price - 1;
            break;
          case '3%이상 인하되면':
            $scope.editData.sale_price_criteria_type3 = '이하';
            $scope.editData.sale_price_criteria = Math.floor(price * 0.97);
            break;
          case '직접입력':
            if (+$scope.editData.sale_price_criteria > price) {
              $scope.editData.sale_price_criteria_type3 = '이하';
            } else {
              $scope.editData.sale_price_criteria_type3 = '이상';
            }
            break;
        }

        $scope.placeholder += ` ${$filter('currency')($scope.editData.sale_price_criteria, '', 0)}원에 도달하면 ${$scope.type === 'priceEdit' ? '내 상품의 가격이 수정됩니다' : '알림이 발송됩니다'}`;
      }
    };

    /**
     * 상품 상세 모달열기
     */
    $scope.onlineProductEdit = function (shop_cd, shop_id, ol_shop_no, location) {
      const resolve = {};
      const data = {};

      data.shop_cd = shop_cd;
      data.shop_id = shop_id;
      data.ol_shop_no = ol_shop_no;
      data.country = location;
      data.warehouseList = warehouseList.data.result;

      resolve.data = data;

      resolve.prodDetail = function () {
        return onlineProductModel.detail(shop_cd, shop_id, ol_shop_no);
      };
      // 원산지 리스트 조회
      resolve.madeinList = function () {
        return productModel.getMadein();
      };
      //환경설정 셋팃값
      resolve.systemList = function () {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');
    };

    /**
     * 전송 버튼 유효성 검사
     */
    $scope.chkDisabledBtn = () => {
      let chk = true;

      if ($scope.type === 'priceEdit') {
        // 시중가, 판매가 중 하나라도 기입되어 있으면 됨
        chk = Object.entries($scope.editData).some(([key, data]) => (!['street_price', 'sale_price', 'opt_add_price', 's_hour', 's_min', 'e_hour', 'e_min'].includes(key) && !data)
          || (['street_price', 'sale_price'].every(key => !$scope.editData[key])));

      } else {
        chk = Object.entries($scope.editData).some(([key, data]) => ['sale_price_criteria_type1', 'sale_price_criteria_type2', 'sale_price_criteria_type3', 'sale_price_criteria'].includes(key) && !data);
      }
      const cnt = Boolean($scope.editData.s_hour) + Boolean($scope.editData.s_min) + Boolean($scope.editData.e_hour) + Boolean($scope.editData.e_min);

      if (!chk) {
        // 발송 금지 시각 4개 다 선택 또는 없어야 됨
        chk = !(cnt === 0 || cnt === 4);
      }

      if (cnt === 4) {
        $scope.timeValid = `${$scope.editData.s_hour}${$scope.editData.s_min}` === `${$scope.editData.e_hour}${$scope.editData.e_min}`;

        if ($scope.timeValid) {
          chk = true;
        }
      }

      $timeout(() => {});

      return chk;
    };

    /**
     * 내상품 가격수정 예약 등록
     */
    $scope.addPriceEditSchedule = async function () {
      if ($scope.addedData.some(data => data.book_status === '예약대기')) {
        commonSVC.showMessage('내 상품에 예약된 내역(예약대기 상태)이 이미 있습니다.', '새 예약을 원할 경우 기존 예약내역을 삭제 후 진행해주세요');

        return false;
      }

      try {

        const opt = {
          showCancelButton: true,
          cancelButtonText: '취소',
          confirmButtonText: '예약'
        };

        const confirm = await commonSVC.showConfirmHtml('내 상품의 가격정보 수정을 예약하시겠습니까?', '', null, opt);

        if (!confirm) { return false; }

        const params = {
          ol_shop_no: $scope.prodData.ol_shop_no,
          insight_seq: 0, // 내 상품 가격 수정만 가능하므로 0으로 고정
          sale_opt_seq: $scope.prodData.sale_opt_seq,
          book_status: '예약대기',
          sale_price_criteria_type1: $scope.editData.sale_price_criteria_type1,
          sale_price_criteria_type2: $scope.editData.sale_price_criteria_type2,
          sale_price_criteria_type3: $scope.editData.sale_price_criteria_type3,
          sale_price_criteria: `${$scope.editData.sale_price_criteria}`,
          first_sale_price: $scope.editData.first_sale_price,
          street_price: $scope.editData.street_price || null,
          sale_price: $scope.editData.sale_price || null,
          opt_add_price: $scope.editData.opt_add_price || null,
          before_street_price: `${$scope.prodData.ol_street_price}`,
          before_sale_price: `${$scope.prodData.ol_sale_price}`,
          before_opt_add_price: `${$scope.prodData.ol_opt_add_price}`,
          agree_yn: $scope.editData.agree_yn,
          send_restrict_stime: $scope.editData.s_hour ? `${$scope.editData.s_hour}:${$scope.editData.s_min}` : null,
          send_restrict_etime: $scope.editData.s_hour ? `${$scope.editData.e_hour}:${$scope.editData.e_min}` : null
        };

        await insightModel.addPriceEditSchedule(params);

        userInfo.user.send_restrict_stime = params.send_restrict_stime;
        userInfo.user.send_restrict_etime = params.send_restrict_etime;

        commonSVC.showToaster('success', '성공', '가격수정 예약이 등록되었습니다.');

        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', '실패', '가격수정 예약 등록이 실패되었습니다.');
      }
    };

    /**
     * 내상품 가격수정 예약 삭제
     */
    $scope.deletePriceEditSchedule = async function () {
      const opt = {
        showCancelButton: true,
        cancelButtonText: '닫기',
        confirmButtonText: '삭제'
      };

      const confirm = await commonSVC.showConfirmHtml('예약 내역을 삭제하시겠습니까?', '', null, opt);

      if (!confirm) { return false; }

      try {
        await insightModel.deletePriceEditSchedule({ insight_book_no: $scope.editData.insight_book_no });

        commonSVC.showToaster('success', '성공', '가격수정 예약이 취소되었습니다.');

        $uibModalInstance.close('success');

      } catch (err) {
        commonSVC.showToaster('error', '성공', '가격수정 예약 취소가 실패되었습니다.');
      }
    };

    /**
     * 비교상품 알림 등록
     */
    $scope.addCompareAlarmSchedule = async function () {

      // 모든 조건이 동일할 경우 중복 등록 불가능
      if ($scope.addedData.some(data =>
        data.alarm_status === '미발송' &&
        data.sale_price_criteria_type1 === $scope.editData.sale_price_criteria_type1 &&
        data.sale_price_criteria_type2 === $scope.editData.sale_price_criteria_type2 &&
        data.sale_price_criteria_type3 === $scope.editData.sale_price_criteria_type3 &&
        data.sale_price === $scope.editData.sale_price_criteria
      )) {
        commonSVC.showMessage('동일한 조건의 알림설정 내역(알림미발송 상태)이 이미 있습니다.', '새 알림설정을 원할 경우 기존 알림설정 내역을 삭제 후 진행해주세요');

        return false;
      }

      try {

        const opt = {
          showCancelButton: true,
          cancelButtonText: '취소',
          confirmButtonText: '확인'
        };

        const confirm = await commonSVC.showConfirmHtml('판매가 알림을 설정하시겠습니까?', '판매가 알림수신에 동의합니다', null, opt);

        if (!confirm) { return false; }

        const params = {
          ol_shop_no: $scope.prodData.ol_shop_no,
          insight_seq: 0, // 그룹 단위 알림이므로 0으로 고정
          sale_opt_seq: $scope.prodData.sale_opt_seq,
          alarm_status: '미발송',
          sale_price_criteria_type1: $scope.editData.sale_price_criteria_type1,
          sale_price_criteria_type2: $scope.editData.sale_price_criteria_type2,
          sale_price_criteria_type3: $scope.editData.sale_price_criteria_type3,
          first_sale_price: $scope.editData.first_sale_price,
          sale_price_criteria: `${$scope.editData.sale_price_criteria}`,
          send_restrict_stime: $scope.editData.s_hour ? `${$scope.editData.s_hour}:${$scope.editData.s_min}` : null,
          send_restrict_etime: $scope.editData.s_hour ? `${$scope.editData.e_hour}:${$scope.editData.e_min}` : null
        };

        await insightModel.addPriceAlarmSchedule(params);

        userInfo.user.send_restrict_stime = params.send_restrict_stime;
        userInfo.user.send_restrict_etime = params.send_restrict_etime;

        commonSVC.showToaster('success', '성공', '비교상품 알림이 설정되었습니다.');

        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', '실패', '비교상품 알림 설정이 실패되었습니다.');
      }
    };

    /**
     *  판매가 알림 예약 삭제
     */
    $scope.deletePriceAlarmSchedule = async function () {
      const opt = {
        showCancelButton: true,
        cancelButtonText: '닫기',
        confirmButtonText: '삭제'
      };

      const confirm = await commonSVC.showConfirmHtml('판매가 알림설정 내역을 삭제하시겠습니까?', '', null, opt);

      if (!confirm) { return false; }

      try {
        await insightModel.deletePriceAlarmSchedule({ insight_alarm_no: $scope.editData.insight_alarm_no });

        commonSVC.showToaster('success', '성공', '가격수정 예약이 취소되었습니다.');

        $uibModalInstance.close('success');

      } catch (err) {
        commonSVC.showToaster('error', '성공', '가격수정 예약 취소가 실패되었습니다.');
      }
    };

    /**
       * 창 닫기
       */
    $scope.close = function () {
      $uibModalInstance.close();
    };

  });