'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentUnstoringListCtrl',
    function (
      // common
      $state, $rootScope, $scope, $timeout, $filter, gettextCatalog, $sce,
      // info
      settings, userInfo,
      // SVC
      commonSVC, shipmentSVC, onlineProductSVC, columnSVC, deliverySVC, delaySVC, workSVC,
      // Model
      deliveryModel, systemModel, shipmentModel, commonModel, columnModel, giftModel, atalkModel, tagModel,
      // List
      warehouseList, giftList, solCarrList, useSystemList, gridInfo, countryList, globalCarrList, deliveryInfoList, deliveryInfoListAll, systemList, supplierList, tagList, memoTemplateList
    ) {
      $scope.globalCarrList = globalCarrList;
      // 나라 리스트
      $scope.countryList = countryList;
      $scope.memoTemplateList = memoTemplateList.data.results || [];

      // 사용 택배사 미설정 시 전체 택배사 노출
      $scope.deliveryList = deliveryInfoList || deliveryInfoListAll;
      $scope.list_name = 'unstoring';
      $scope.pageData = {};
      $scope.loadShopData = {
        domestic: false,
        global: false
      };

      // 주문 엑셀 수정 사용 가능여부
      const excelEditUseYn = $rootScope.affName === 'LG전자' && !!$rootScope.user_profile.pa_sol_no;

      // 리스트 수정
      $rootScope.unstoringListEditStatus = false; //수정 상태 플래그
      let unstoringTableEditList = {}; // 리스트 수정 현재 값 데이터 객체
      let oldColumns = []; // 수정 당시 컬럼 값
      let changeColumn = 'all';
      let changeProdName;

      let delivInfoUpdateList = {};
      // 컬럼 설정 정보
      const menu_data = gridInfo.menu_data ? gridInfo.menu_data : false;
      // 주문금액 분할 모듈 사용여부
      const isCalStatePerProd = $rootScope.userProfileCheck('sol_ser', 'cal_state_per_prod', 'like') && $rootScope.user_profile.auth_type !== '배송처';
      $scope.loadDataTable = gridInfo;

      $scope.test = window.location.host.includes('localhost') || window.location.host.includes('staging');

      //운송장번호 입력 권한 여부
      $scope.invoicePermission = commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission, true);

      const channelList = angular.copy($rootScope.use_channel_list);

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(channelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      // 직접 입력 추가
      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      $scope.bundle_group = {}; // 묶음 코드
      let prodList = []; //주문리스트(SKU상품정보)
      let addProdList = []; //주문리스트(추가구매옵션상품정보)
      let outConfirmAvailCount = 0; // 검색된 주문 건 중 '운송장출력' 상태인 상태
      let invoicePrintAvailCount = 0; // 검색된 주문 건 중 '운송장출력' 가능한 주문
      let orderList = {};
      let bundleOrdGroup;
      const orderby = {}; // 리스트 정렬기준 (주문서인쇄 정렬용)

      $scope.totalCount = 0;
      $scope.isWarehouseAdmin = userInfo.user.user_grade === '창고';
      $scope.countList = { total: 0, waiting: 0, hold: 0, invoice: 0 }; // 우측 아이콘 카운트
      $scope.selectCount = 'total';
      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.unstoring);
      $scope.selectedShopType = useSystemList.data.main_use_mode === '국내' ? 'domestic' : 'global';

      if (!$rootScope.user_profile.sol_stock) {
        $scope.excelFieldList = _.filter($scope.excelFieldList, function (f) {
          return ['건별출고수량', 'SKU코드', 'SKU상품명', '매입처', '바코드', '총출고수량'].indexOf(f.header) === -1;
        });
      }

      // 엑셀 일괄 수정 사용시 uniq 필요하여 필드목록에 추가함
      if (excelEditUseYn) {
        $scope.excelFieldList.splice(1, 0, { header: '주문고유번호', key: 'uniq', select: true });
      }

      const divideStatus = ['출고대기', '운송장출력', '출고보류', '취소요청', '취소완료', '반품요청', '반품완료'];

      $scope.deliMethodList = angular.copy(shipmentSVC.deliMethodList);
      // 대리점 계정일 경우 내일배송 관련 값 추가
      if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
        $scope.deliMethodList.splice(-1, 0, ...['빠른 가전 배송', '내일 배송']);
      }

      // 대시보드에서 넘어오는 경우 설정이 제대로 안되는 경우가 있음
      if ($rootScope.side_search.page === 'unstoring' && $rootScope.side_search.search) {
        $scope.selectedShopType = $rootScope.side_search.selectedShopType;
      }

      // 일괄입력 변수 2018-11-08 rony
      $scope.setAllData = {
        type: '',
        ship_method: '',
        ship_cost: 0,
        carr_no: '',
        carr_name: ''
      };

      /* 검색 및 버튼 관련 변수 */
      const domesticSearchTemplate = {
        searchForm: {
          search_key: '',
          search_word: '',
          search_type: 'partial',
          shopType: '',
          date_type: 'wdate',
          sdate: moment().subtract(useSystemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          site_id: '',
          site_code: '',
          status: ['출고대기', '출고보류', '운송장출력'],
          ord_status_stat: ['출고대기', '출고보류', '운송장출력'],
          recent_month: 1,
          page: 'unstoring',
          bundle_yn: true,
          delivery_vendor: '',         //배송처
          // to_country_code: '',         //수령자 국가
          gift_prod_use_yn: '',   // 규칙사은품 사용여부
          gift_prod_name: '', //규칙적용사은품
          delay_status: '',            //배송지연
          unstore_status: '',         //출고가능여부
          multi_search_word: '',       //멀티서치워드
          multi_type: 'shop_sale_no',  //멀티서치 타입
          // excel_down_yn: '',
          memo_yn: '', // 메모여부
          multi_shop_id: '',
          multi_deliver_vendor: '',
          multi_supplier_vendor: ''
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectType: true,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
          selectCount: 0,      // 검색전체 건수
          selectBundleCnt: 0,  // 선택 묶음건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
            { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
            { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
            { label: gettextCatalog.getString('주소'), value: 'to_addr' },
            { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
            { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
            { label: gettextCatalog.getString('배송메시지'), value: 'ship_msg' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('배송번호'), value: 'shop_ship_no' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
            { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
            { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
            { label: gettextCatalog.getString('배송 희망일'), value: 'ship_hope_time' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'ship_plan_date' },
            { label: gettextCatalog.getString('주문 확인일'), value: 'ord_confirm_time' },
            { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
            { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' },
            { label: gettextCatalog.getString('송장 입력일'), value: 'invoice_time' },
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('운송장 번호'), value: 'invoice_no' },
            { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('배송처'), value: 'depot' },
            { label: gettextCatalog.getString('매입처'), value: 'supp' },
            { label: gettextCatalog.getString('사용자태그'), value: 'tag_no' }
          ],
          search_date_key_names: commonSVC.searchKeyNames[useSystemList.data.search_date] || '1MONTH'
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              // 글로벌 쇼핑몰 여부 확인
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X');
            },
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            }
          },
          {
            // 매입처 선택
            title: gettextCatalog.getString('매입처 선택'),
            search_name: 'supp_vendor',
            item_list: supplierList.data.result || [],
            item_key: 'supp_name',
            item_value: 'supp_no',
            select_value: '',
            add_class: 'select-search'
          },
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: '',
            add_class: 'select-search'

          },
          {
            // 규칙사은품규칙 구분
            title: gettextCatalog.getString('규칙사은품 사용여부'),
            search_name: 'gift_prod_use_yn',
            item_list: [{ text: '전체', value: 'all' }, { text: '사용', value: '1' }, { text: '미사용', value: '0' }],
            item_key: 'text',
            item_value: 'value',
            select_value: $scope.searchForm ? $scope.searchForm.gift_prod_use_yn : ''
          },
          {
            // 규칙적용 사은품
            title: gettextCatalog.getString('규칙적용사은품 선택'),
            search_name: 'gift_prod_name',
            item_list: giftList.data.results || [],
            item_key: 'gift_rule_name',
            item_value: 'gift_no',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              if ($scope.searchForm.gift_prod_use_yn === 'all') {
                return true;
              } else {
                return option.use_yn === Number($scope.searchForm.gift_prod_use_yn) && $scope.searchForm.gift_prod_use_yn;
              }
            }
          },
          {
            // 배송지연여부
            title: gettextCatalog.getString('배송지연여부'),
            search_name: 'delay_status',
            item_list: [{ key: 1, value: '배송지연 O' }, { key: 0, value: '배송지연 X' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 출고가능 여부
            title: gettextCatalog.getString('출고가능 여부'),
            search_name: 'unstore_status',
            item_list: [{ key: 1, value: '출고 가능' }, { key: 0, value: '출고 불가능' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 배송방법
            title: gettextCatalog.getString('배송방법 선택'),
            search_name: 'ship_method',
            item_list: $scope.deliMethodList.map(val => ({ key: val, value: val })),
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 매칭여부
            title: gettextCatalog.getString('매칭 여부'),
            search_name: 'map_yn',
            item_list: [{ key: 1, value: '매칭 됨' }, { key: 0, value: '매칭 안됨' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 메모여부
            title: gettextCatalog.getString('메모여부'),
            search_name: 'memo_yn',
            item_list: [{ key: 1, value: '메모 있음' }, { key: 0, value: '메모 없음' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 합포장여부
            title: gettextCatalog.getString('합포장 여부'),
            search_name: 'multi_bundle_yn',
            item_list: [{ key: 1, value: '합포장' }, { key: 0, value: '합포장 아님' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 사용자태그여부
            title: gettextCatalog.getString('사용자태그 여부'),
            search_name: 'tag_yn',
            item_list: [{ key: 1, value: '사용자태그 적용' }, { key: 0, value: '사용자태그 미적용' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(channelList).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: '',
            filter: function(option) {
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X');
            }
          },
          {
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'multi_deliver_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            search_word: ''
          },
          {
            title: gettextCatalog.getString('매입처 선택'),
            search_name: 'multi_supplier_vendor',
            item_list: supplierList.data.result || [],
            item_key: 'supp_name',
            item_value: 'supp_no',
            search_word: ''
          },
          {
            title: gettextCatalog.getString('사용자태그 선택'),
            search_name: 'multi_tag',
            item_list: tagList.data.results || [],
            item_key: 'tag_name',
            item_value: 'tag_no',
            search_word: ''
          }
        ]
      };

      const globalSearchTemplate = angular.copy(domesticSearchTemplate);

      // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
      if ($rootScope.user_profile.auth_type === '배송처') {
        const searchIndex = _.findIndex(domesticSearchTemplate.searchDetail, { search_name: 'delivery_vendor' });

        if (searchIndex > -1) {
          domesticSearchTemplate.searchDetail.splice(searchIndex, 1);
        }
      }

      // 글로벌 서치바의 경우 2.0과 다른부분이 있어 별도처리
      globalSearchTemplate.searchData.search_key_items = [
        { label: gettextCatalog.getString('전체'), value: '' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호1'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호2'), value: 'misc13' },
        { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
        { label: gettextCatalog.getString('패키지번호'), value: 'pa_bundle_no' },
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('상품명'), value: 'shop_sale_name' },
        { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
        { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
        { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
        { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
        { label: gettextCatalog.getString('주문자, 수령자 이메일'), value: 'order_email,to_email' },
        { label: gettextCatalog.getString('주문자, 수령자 주소'), value: 'to_addr1,to_addr2' },
        { label: gettextCatalog.getString('메모'), value: 'memo' },
        { label: gettextCatalog.getString('배송메세지'), value: 'ship_msg' },
        { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
      ];

      globalSearchTemplate.searchData.search_date_type = [
        { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
        { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
        { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
        { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
        { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
        { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' }
      ];

      globalSearchTemplate.searchData.search_multi_items = [
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('해외운송장번호'), value: 'invoice_no' },
        { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' },
        { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
        { label: gettextCatalog.getString('배송처'), value: 'depot' }
      ];

      globalSearchTemplate.searchDetail.splice(3, 0, ...[
        // {
        //   title: gettextCatalog.getString('국내택배사 선택'),
        //   search_name: 'carr_no',
        //   item_list: solCarrList.data.results.length ? solCarrList.data.results : $scope.deliveryList,
        //   item_key: 'carr_name',
        //   item_value: 'carr_no',
        //   select_value: ''
        // },
        {
          title: gettextCatalog.getString('해외택배사 선택'),
          search_name: 'global_carr_no',
          item_list: globalCarrList,
          item_key: 'carr_view_name',
          item_value: 'carr_no',
          select_value: '',
          filter: function (option) {
            return option.carr_no !== -1;
          },
        },
        {
          title: gettextCatalog.getString('송장 출력여부'),
          search_name: 'invoice_print_time_yn',
          item_list: [
            { name: '출력완료', code: 1 },
            { name: '출력대기', code: 0 },
          ],
          item_key: 'name',
          item_value: 'code',
          select_value: '',
        },
      ]);

      globalSearchTemplate.searchDetail = globalSearchTemplate.searchDetail.filter(item => !['gift_prod_use_yn', 'gift_prod_name', 'delay_status', 'ship_method', 'tag_yn'].includes(item.search_name));

      let domesticSearch = angular.copy(domesticSearchTemplate), globalSearch = angular.copy(globalSearchTemplate);

      function setSearch(search) {
        $scope.searchData = search.searchData;
        $scope.searchForm = search.searchForm;
        $scope.searchDetail = search.searchDetail;
        $scope.searchDetailMulti = search.searchDetailMulti;
        $scope.searchDetailMultiSelect = search.searchDetailMultiSelect;
      }

      function resetSearch() {
        const showCount = $scope.searchData?.showCount;
        let search;
        if ($scope.selectedShopType === 'global') {
          search = globalSearch = angular.copy(globalSearchTemplate);
        } else {
          search = domesticSearch = angular.copy(domesticSearchTemplate);
        }
        if (showCount) {
          search.searchData.showCount = showCount;
        }
      }

      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="icon-sync worksvc-spinner-orderSync order"></i><span> ${gettextCatalog.getString('주문 동기화')}</span>`,
            test_id: 'btn-async',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-12',
            perm_only: ['order.unstoring+write'],
            action: function () {
              $scope.orderSync();
            }
          }
        ],
        table_actions_top: [
          {
            label: gettextCatalog.getString('SKU상품매칭'),
            test_id: 'btn-product-match',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-shapes',
            action: function () {
              $scope.mappingProd();
            },
            perm_only: ['order.unstoring+write'],
            ngIfFunc: function () {
              return $rootScope.user_profile.sol_stock > 0 ? 'y' : 'n';
            }
          },
          {
            label: gettextCatalog.getString('주문서인쇄'),
            test_id: 'btn-order-print',
            icon_class: 'pui-description',
            action: function () {
              $scope.orderPrint();
            },
            perm_only: ['order.unstoring']
          },
          {
            label: gettextCatalog.getString('운송장출력'),
            test_id: 'btn-print-delivery',
            icon_class: 'pui-receipt',
            action: function () {
              $scope.printInvoice('print');
            },
            ngIfFunc: () => {
              return ['total', 'waiting'].includes($scope.selectCount) && $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
            perm_only: ['order.unstoring+write']
          },
          {
            label: gettextCatalog.getString('운송장재출력'),
            test_id: 'btn-reprint-delivery',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-order_play',
            action: function () {
              $scope.printInvoice('reprint');
            },
            ngIfFunc: () => {
              return ['total', 'invoice'].includes($scope.selectCount) && $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
            perm_only: ['order.unstoring+write']
          },
          // {
          //   label: gettextCatalog.getString('국내송장출력'),
          //   test_id: 'btn-print-delivery',
          //   icon_class: 'pui-receipt',
          //   action: function () {
          //     $scope.printDomesticInvoice();
          //   },
          //   ngIfFunc: () => {
          //     return ['total', 'waiting'].includes($scope.selectCount) && $scope.selectedShopType === 'global' ? 'y' : 'n';
          //   },
          //   perm_only: ['order.unstoring+write']
          // },
          // {
          //   label: gettextCatalog.getString('해외송장출력'),
          //   btn_type: 'dropdown',
          //   test_id: 'btn-print-delivery-global',
          //   icon_class: 'pui-receipt',
          //   perm_only: ['order.unstoring+write'],
          //   ngIfFunc: () => {
          //     return $scope.selectedShopType === 'global' ? 'y' : 'n';
          //   }
          // },
          {
            label: gettextCatalog.getString('출고완료'),
            test_id: 'btn-release-complete',
            icon_class: 'pui-file_download_done',
            action: function () {
              $scope.orderFinish();
            },
            ngIfFunc: () => {
              return ['total', 'invoice'].includes($scope.selectCount) && $scope.selectedShopType !== 'global' ? 'y' : 'n';
            },
            perm_only: ['order.unstoring+write']
          },
          {
            label: $sce.trustAsHtml('출고완료'),
            test_id: 'btn-release-complete',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-file_download_done',
            action: function () {
              $scope.orderFinish();
            },
            ngIfFunc: () => {
              return ['total', 'invoice'].includes($scope.selectCount) && $scope.selectedShopType === 'global' ? 'y' : 'n';
            },
            perm_only: ['order.unstoring+write']
          },
          {
            label: gettextCatalog.getString('출고보류'),
            test_id: 'btn-save-release',
            icon_class: 'pui-production_quantity_limits',
            action: function () {
              $scope.orderHold();
            },
            ngIfFunc: () => {
              return ['total', 'waiting', 'invoice'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.unstoring+write']
          },
          {
            label: gettextCatalog.getString('출고보류 해제'),
            test_id: 'btn-save-release',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-production_quantity',
            action: function () {
              $scope.orderUnhold();
            },
            ngIfFunc: () => {
              return ['total', 'hold'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.unstoring+write']
          },
          {
            label: gettextCatalog.getString('배송지연전송'),
            test_id: 'btn-post-delay',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-schedule',
            action: function () {
              $scope.delayedOrder();
            },
            perm_only: ['order.order+write'],
            ngIfFunc: () => {
              return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('배송일 변경'),
            test_id: '',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-calendar',
            tooltip: "'희망일배송'이 가능한 스마트스토어 상품 주문의 배송희망일을 변경할 수 있습니다.",
            action: function () {
              $scope.updateHopeDate();
            },
            perm_only: ['order.order+write'],
            // 스마트스토어 계정 자체를 안가지고 있으면 해당 버튼 안보여줌
            ngIfFunc: () => {
              return $rootScope.use_channel_list_scm.find(channel => channel.pa_shop_cd === 'A077') && $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
          },
        ],
        table_states_top: [
          {
            label: '전체',
            test_id: 'btn-state-total',
            icon_class: 'pui-storefront',
            count_name: 'total'
          },
          {
            label: '출고대기',
            test_id: 'btn-state-waiting',
            icon_class: 'pui-production_quantity',
            count_name: 'waiting'
          },
          {
            label: '출고보류',
            test_id: 'btn-state-hold',
            icon_class: 'pui-production_quantity_limits',
            count_name: 'hold'
          },
          {
            label: '운송장출력',
            test_id: 'btn-state-invoice',
            icon_class: 'pui-receipt',
            count_name: 'invoice'
          }
        ],
        table_actions: [
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            test_id: 'drop-work',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('주문복사'),
                test_id: 'btn-order-copy',
                action: function () {
                  $scope.copyOrder();
                },
                perm_only: ['order.order+write']
              }, {
                label: gettextCatalog.getString('주문삭제'),
                test_id: 'btn-order-del',
                action: function () {
                  $scope.deleteOrder();
                },
                perm_only: ['order.order+admin']
              }, {
                label: `${gettextCatalog.getString('주문분할')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="묶음 주문을 한 건씩 일괄 분할 합니다." tooltip-placement="right">`,
                menu_tooltip: '묶음 주문을 한 건씩 일괄 분할 합니다.',
                test_id: 'btn-order-split',
                action: function () {
                  $scope.divideBundle();
                },
                perm_only: ['order.unstoring+write'],
              }, {
                label: `${gettextCatalog.getString('주문 선택분할')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="묶음 안에 주문건들을 선택하여 분리할수 있습니다." tooltip-placement="right">`,
                menu_tooltip: '묶음 안에 주문건들을 선택하여 분리할수 있습니다.',
                test_id: 'btn-order-select-split',
                action: function () {
                  $scope.divideBundleSelect();
                },
                perm_only: ['order.unstoring+write'],
              }, {
                label: gettextCatalog.getString('합포장'),
                test_id: 'btn-order-allpack',
                action: function () {
                  $scope.modifyBundle();
                },
                perm_only: ['order.unstoring+write']
              }, {
                label: gettextCatalog.getString('주문수량분할'),
                test_id: 'btn-order-count-split',
                action: function () {
                  $scope.divideOrder();
                },
                perm_only: ['order.unstoring+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: `${gettextCatalog.getString('판매금액 복구')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)" tooltip-placement="right">`,
                menu_tooltip: `판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)`,
                action: function () {
                  $scope.restoreSupplyPrice();
                },
                perm_only: ['order.unstoring+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('SMS 발송'),
                test_id: 'btn-send-sms',
                action: function () {
                  $scope.sendModal('sms');
                },
                perm_only: ['order.order+admin'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              },
              {
                label: gettextCatalog.getString('알림톡 발송'),
                action: function () {
                  $scope.altalkSend();
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('알림톡 그룹 회원 추가'),
                action: function () {
                  $scope.altalkGroupMemAdd();
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              },
              ...$rootScope.user_profile.auth_type !== '배송처' ? [
                {
                  label: gettextCatalog.getString('주문 정보 수정'),
                  test_id: 'btn-order-edit',
                  add_class: 'btn-order-edit',
                  action: function() {
                    $scope.editOrder();
                  },
                  filter: function() {
                    return !$rootScope.unstoringListEditStatus;
                  },
                  perm_only: ['order.order+admin'],
                  ngIfFunc: function () {
                    return $scope.selectedShopType === 'domestic' && $rootScope.user_profile.sol_stock > 0 ? 'y' : 'n';
                  }
                }] : [],
            ]
          },
          {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            test_id: 'drop-excel',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
                menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
                test_id: 'btn-excel-intergrate',
                action: function () {
                  $scope.downloadIntegratedExcel();
                },
                perm_only: ['order.unstoring+write']
              },
              {
                label: gettextCatalog.getString('엑셀 다운'),
                test_id: 'btn-excel-download',
                action: function () {
                  $scope.excelDown();
                },
                perm_only: ['order.unstoring+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' && [496, 640].includes($rootScope.user_profile.sol_no) ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('배송정보 업로드'),
                test_id: 'btn-delivery-upload',

                action: function () {
                  $scope.uploadInvoiceExcel();
                },
                perm_only: ['order.unstoring+write'],
              }, {
                label: gettextCatalog.getString('엑셀 일괄 수정'),
                action: function () {
                  $scope.excelUpload('edit');
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' && excelEditUseYn ? 'y' : 'n';
                },
                perm_only: ['order.unstoring+write']
              }
            ],
          },
          {
            label: '<span class="pl-5">추가 기능</span>' + '<span class="caret"></span>',
            test_id: 'btn-etc',
            btn_type: 'dropdown',
            add_class: 'a-btn-blue',
            item_list: [
              {
                label: gettextCatalog.getString('판매금액 관리'),
                action: function () {
                  $scope.supplyPriceManager();
                },
                perm_only: ['order.order+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              },
              {
                label: gettextCatalog.getString('상품명 관리'),
                test_id: 'btn-product-name-manager',
                action: function () {
                  $scope.prodNameManager();
                },
                perm_only: ['order.unstoring+write']
              },
              {
                label: gettextCatalog.getString('사용자태그 관리'),
                test_id: 'btn-user-tag',
                action: function () {
                  commonSVC.openModal('xg', {}, 'TagListCtrl', 'views/order/shipment/modals/tag_list.html');
                },
                perm_only: ['order.unstoring+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }
            ],
          },
          {
            label: '<span>규칙 사은품</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'a-btn-blue',
            item_list: [
              {
                label: `${gettextCatalog.getString('전체 분배')}<i class="icon-help fl-r" uib-tooltip='규칙 적용 방식을 [수동]으로 설정했거나, 추가된 규칙이 있어서 전체 사은품 규칙 검사를 다시 진행하고 싶을 때 사용해 주세요.\n※ 운송장출력 상태 주문은 사은품 규칙이 적용되지 않습니다. 사은품 적용을 원하는 경우에는 [상태 수동변경 > 출고대기] 처리 후 진행해 주세요. ' tooltip-placement="right"></i>`,
                action: function () {
                  $scope.matchingGift('all');
                },
                perm_only: ['order.unstoring+write']
              },
              {
                label: `${gettextCatalog.getString('선택 분배')}<i class="icon-help fl-r" uib-tooltip='사은품 규칙을 선택한 일부 주문 건에만 적용하고 싶을 때 사용해 주세요.\n이미 규칙 사은품이 분배된 주문건에 수정한 사은품 규칙으로 다시 적용하려면 [규칙 사은품 > 선택 삭제] 후 분배를 진행해 주세요.\n※ 운송장출력 상태 주문은 사은품 규칙이 적용되지 않습니다. 사은품 적용을 원하는 경우에는 [상태 수동변경 > 출고대기] 처리 후 진행해 주세요. ' tooltip-placement="right"></i>`,
                action: function () {
                  $scope.matchingGift('select');
                },
                perm_only: ['order.unstoring+write']
              },
              {
                label: gettextCatalog.getString('선택 삭제'),
                action: function () {
                  $scope.matchingDeleteGift();
                },
                perm_only: ['order.unstoring+write']
              }
            ],
            ngIfFunc: () => {
              return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            }
          },
          {
            label: '바코드출고매니저',
            add_class: 'a-btn-blue',
            test_id: 'btn-barcode-manager',
            action: function () {
              $scope.barcodeManager();
            },
            perm_only: ['order.unstoring+write'],
            ngIfFunc: function () {
              return $scope.selectedShopType === 'domestic' && $rootScope.user_profile.sol_stock > 0 ? 'y' : 'n';
            }
          },
          // {
          //   label: gettextCatalog.getString('해외 운송장 재출력'),
          //   add_class: 'a-btn-blue',
          //   test_id: 'btn-global-reprint',
          //   action: function () {
          //     $scope.globalReprintInvoice();
          //   },
          //   perm_only: ['order.order+admin'],
          //   ngIfFunc: () => {
          //     return $scope.selectedShopType === 'global' ? 'y' : 'n';
          //   }
          // }
        ],
      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },
        getOrderList: function () {
          // 주문 요약정보 매칭 정보
          _.forEach(orderList, function (order) {
            if (prodList[order.uniq]) {
              order.sku_pack = prodList[order.uniq][0].sku_cd;
              order.prod_name_pack = prodList[order.uniq][0].prod_name;
            }
          });

          return orderList;
        }
      };

      $scope.customContext = [
        {
          isSubMenu: true,
          line: true,
          subMemuName: '상태 수동변경',
          contextList: [
          // 더스킨 출고대기 주문 신규주문으로 변경 가능
            ...([1, 16044].includes($rootScope.user_profile.sol_no) ? [{
              label: gettextCatalog.getString('신규주문으로 상태변경'),
              action: function() { $scope.userChangeStatus('신규주문'); },
              filter: row => row.ord_status === '출고대기'
            }] : []),
            {
              label: gettextCatalog.getString('출고대기로 상태변경'),
              action: function() { $scope.userChangeStatus('출고대기'); },
              filter: function(row) {
                return ['출고보류', '운송장출력'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
              }
            }, {
              label: gettextCatalog.getString('출고보류로 상태변경'),
              action: function() { $scope.userChangeStatus('출고보류'); },
              filter: function(row) {
                return ['출고대기', '운송장출력'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
              }
            }, {
              label: gettextCatalog.getString('운송장출력으로 상태변경'),
              action: function() { $scope.userChangeStatus('운송장출력'); },
              filter: function(row) {
                return (
                  ['출고보류', '출고대기'].includes(row.ord_status) &&
                row.carr_no &&
                ([41, 68, 75, 87, 89].includes(row.carr_no) || (row.invoice_no && !row.wasEmpty)) &&
                (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no))
                );
              }
            },
            // 배송처 사용자가 아닐 때 취소요청 상태변경 노출
            ...($rootScope.user_profile.auth_type !== '배송처' ? [{
              label: gettextCatalog.getString('취소요청으로 상태변경'),
              action: function() { $scope.userChangeStatus('취소요청'); },
              filter: function(row) {
                return ['출고대기', '운송장출력', '출고보류'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
              }
            }] : [])
          ]
        }];

      // 이노서브 더존 양식 다운 메뉴 추가
      if ([1143, 1].includes($rootScope.user_profile.sol_no)) {
        const excelAction = $scope.searchBtn.table_actions.find(({ label }) => label.includes('엑셀'));

        excelAction.item_list.splice(2, 0, {
          label: '더존 양식 다운',
          isSubMenu: true,
          item_list: [
            {
              label: gettextCatalog.getString('전체 주문 다운로드'),
              action: function() {
                $scope.excelDown('all', true);
              },
              ngIfFunc: () => {
                return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
              }
            },
            {
              label: gettextCatalog.getString('선택 주문 다운로드'),
              action: function() {
                $scope.excelDown('select', true);
              },
              ngIfFunc: () => {
                return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
              }
            }
          ]
        });
      }

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      // // 묶음순번 확인변수
      // var preBundleNo = '';
      // var viewRowNum = 1;

      /* 데이터 테이블 */
      $scope.domestic = {};
      $scope.domestic.methods = {};
      $scope.domestic.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notSortingColumns: ['cart_cd'],
        notVisibleColumns: ['notice_msg', 'out_time', 'ord_time', 'pay_time', 'ord_status_mdate', 'ord_confirm_time', 'sales', 'ship_delay_yn', 'order_tel', 'order_htel', 'to_tel', 'c_sale_cd', 'barcode', 'last_exceldown_time', 'shop_cost_price', 'shop_supply_price', 'stock_cd', 'total_cnt', 'shop_ship_no', 'model_no', 'seller_discount', 'shop_discount', 'coupon_discount', 'point_discount', 'ship_hope_time', 'order_msg', 'bundle_avail_yn', 'shop_ord_no_real', 'invoice_time', 'tag_pack', 'prod_name,attri', 'cart_cd'],
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'bundle_no',
          bundleUniqKey: 'uniq'
        },
        multiSort: true, // 다중정렬 가능
        addColGrid: !$rootScope.user_profile.pa_sol_no, // LG 대리점 계정은 컬럼추가 작업 불가능
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
          requestWillAction: function(data) {
            $scope.searchForm.shopType = 'domestic';

            if (userInfo.user.auth_type === '배송처' && !userInfo.user.depot_no) {
              commonSVC.showMessage('담당 배송처가 없습니다.');
            }

            if ($rootScope.order_search.page === 'unstoring' && $rootScope.order_search.search) {
              $scope.searchForm.search_word = $rootScope.order_search.input;
              $scope.searchForm.search_key = $rootScope.order_search.key;
              $scope.searchData.search_key_name = $rootScope.order_search.name;
              $rootScope.order_search.search = false;
              $scope.checkNoneOrd = true;
            }

            if ($rootScope.side_search.page === 'unstoring' && $rootScope.side_search.search) {
              $scope.searchForm.date_type = $rootScope.side_search.date_type;
              $scope.searchForm.sdate = $rootScope.side_search.sdate;
              $scope.searchForm.edate = $rootScope.side_search.edate;
              $scope.searchForm.status = $rootScope.side_search.status;
              $scope.selectCount = $rootScope.side_search.selectCount;
              $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
              $rootScope.side_search.search = false;
              $scope.checkNoneOrd = true;
            }

            data = angular.merge({}, data, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData.domestic = angular.copy(data);

            orderby.domestic = data.orderby;

            return data;
          },
          requestDidAction: function(result) {
            // SKU상품정보 처리
            prodList = {};
            addProdList = {};
            bundleOrdGroup = _.groupBy(result.results, 'bundle_no');

            if ($rootScope.unstoringListEditStatus === true && $scope.editableList.length > 0) {
              result.results.forEach((row) => {
                if ($scope.editableList.some(ord => ord.uniq === row.uniq)) {
                  row.isEditing = true;
                  row.isSelected = true;
                  if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.isChangedData) {
                    if (changeColumn !== 'all') {
                      if (changeProdName.length < 1) {
                        unstoringTableEditList[row.bundle_no][row.uniq][changeColumn] = row[changeColumn];
                      } else {
                        changeProdName.forEach((key) => {
                          unstoringTableEditList[row.bundle_no][row.uniq][key] = row[key];
                        });
                      }
                    } else {
                      unstoringTableEditList[row.bundle_no][row.uniq] = row;
                    }

                    delete unstoringTableEditList[row.bundle_no][row.uniq].isChangedData;
                  }
                } else if ($scope.unEditableList.some(ord => ord.uniq === row.uniq)) {
                  row.isSelected = true;
                }
              });
            }
            result.results_prod.forEach(function(subRow) {
              if (subRow.add_opt_yn == 1) {
                if (!addProdList[subRow.uniq]) { addProdList[subRow.uniq] = []; }

                addProdList[subRow.uniq].push(subRow);

              } else {
                if (!prodList[subRow.uniq]) { prodList[subRow.uniq] = []; }

                prodList[subRow.uniq].push(subRow);
              }
            });

            // 처음 데이터 load 시 활성화된 탭보다 나중에 응답 올 경우 값이 비정상 적으로 들어가는 경우가 존재하여 interval 다른 탭 데이터 로드 후 처리
            const timer = setInterval(async () => {
              if ($scope.selectedShopType !== 'domestic' || $scope.loadShopData.global) {
                $scope.isOpen = false;

                outConfirmAvailCount = result.availCount['출고완료_avail_cnt'];
                invoicePrintAvailCount = result.availCount['운송장출력_avail_cnt'];
                $scope.searchData.totalCount = result.recordsTotal;
                $scope.domesticTotalCount = result.recordsTotalCount;
                $scope.totalCount = result.recordsTotalCount;
                // 합포장 분리시 필요
                $scope.bundle_group = _.groupBy(result.results, 'bundle_no');

                // 합포장 갯수
                $scope.bundleCnt = result.recordsTotal;

                orderList = result.results;
                columnModel.setOld(_.cloneDeep(result.results), 'unstoring');

                init(result.statusCount);

                // 전체 주문 조회 시 해당 탭 주문 건 없을 경우 다른 탭으로 조회 하여 보여줌
                if ($rootScope.order_search.page === 'unstoring' && !$scope.domesticTotalCount && $scope.checkNoneOrd) {
                  $scope.selectShopType('global');

                  $scope.checkNoneOrd = false;
                }

                columnSVC.addColIntSet('all', result.results);

                $scope.loadShopData.domestic = true;
                clearInterval(timer);
                $timeout(() => {});
              }
            }, 100);

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 170,
            template: function(row) {
              let notBundleStatus = !divideStatus.includes(row.ord_status);

              if (bundleOrdGroup[row.bundle_no].length > 1) {
                const cantDivideOrd = bundleOrdGroup[row.bundle_no].find(ord => ord.ord_status === '배송완료');
                if (cantDivideOrd) {
                  notBundleStatus = true;
                }
              }

              const divide = `<button class="btn btn-default btn-xxs" ng-click="$event.preventDefault(); grid.appScope.divideBundle('${row.bundle_no}','${row.uniq}')" ng-disabled="${notBundleStatus} || grid.appScope.bundle_group[${row.bundle_no}].length < 2">주문분할</button>`;

              return `<button class="btn btn-default btn-xxs mr-5" ng-click="$event.preventDefault(); grid.appScope.showDetail('${row.uniq}', false, ${row.isEditing},'${row.bundle_no}')" >상세</button>
              <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="$event.preventDefault(); grid.appScope.leftTabAction(2)">메모</button>${divide}`;
            }
          },
          {
            key: 'bundle_no',
            title: '묶음번호',
            name: 'bundle_no',
            width: 170
          },
          {
            key: 'ship_unable_reason',
            title: '출고가능여부',
            width: 90,
            notCompile: true,
            template: function(row) {
              let dot = '<span class="text-success-600">●</span>';

              if (!row.ship_avail_yn) {
                let reason = row.ship_unable_reason ? row.ship_unable_reason : '';

                // 주문수집 및 매칭과정에서의 오류로 매칭정보는 있으나 배송처정보가 없는 경우가 발생해서 해당 주문은 재매칭하게 안내 2018-11-14 Amelia
                if (!row.depot_no && prodList[row.uniq][0].sku_cd) {
                  reason = '배송처 오류(매칭 재시도 요망)';
                }

                // 묶음주문상태확인 메세지는 묶여있는 다른 주문의 재고부족등의 이슈로 해당 주문은 출고가능이기 때문에 조건 처리함 2018-10-31 rony
                if (reason != '묶음주문 상태확인') {
                  dot = `<span class="text-danger">불가(${reason})</span>`;
                }
                else if (!reason) {
                  dot = '<span class="text-danger">불가</span>';
                }
              }

              return dot;
            }
          },
          {
            key: 'map_yn',
            title: '매칭여부',
            requireStock: true,
            width: 80,
            template: function(row) {
              return row.map_yn
                ? `<button class="btn btn-default btn-xxs" ng-disabled="${$rootScope.osse_sol}" ng-click="$event.preventDefault(); grid.appScope.mappingProd('${row.uniq}','${row.wdate}')" >수정</button>`
                : `<button class="btn bg-white border-warning-300 text-warning-300 btn-xxs" ng-click="$event.preventDefault(); grid.appScope.mappingProd('${row.uniq}','${row.wdate}')" >미매칭</button>`;
            }
          },
          {
            key: 'ord_status',
            title: '출고상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return (
                `<span id="${row.uniq}_ordStatus">${$filter('statusColor')(row.ord_status)}</span>`
              );
            }
          },
          {
            key: 'out_order_time',
            title: '출고지시일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'sku_cd',
            title: 'SKU코드',
            requireStock: true,
            width: 160,
            template: function(row) {
              if (row.set_no && prodList[row.uniq]) {
                return `<button class="btn btn-xxs btn-success" ng-click="$event.preventDefault(); grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq].length > 1 ?
                  `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="$event.preventDefault(); grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                  : prodList[row.uniq][0].sku_cd;
              } else {
                return '미매칭 출고';
              }
            }
          }, {
            key: 'stock_cd',
            title: '재고관리코드',
            tooltip: '세트상품의 경우 대표로 지정된 SKU의 재고관리코드만 항목에 노출됩니다.',
            requireStock: true,
            width: 160,
            template: (row) => {
              if (row.set_no && prodList[row.uniq]) {
                const findList = prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

                return findList ? findList.stock_cd : '';
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq][0].stock_cd;
              } else {
                return '';
              }
            }
          },
          {
            key: 'prod_name',
            title: 'SKU상품명',
            requireStock: true,
            width: 130,
            notCompile: true,
            template: function(row) {
              if (row.set_no) {
                return row.set_name;
              } else {
                if (prodList[row.uniq]) {
                  const prod_name = (prodList[row.uniq][0].prod_name || '');

                  return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
                }

                return '';
              }
            }
          },
          {
            key: 'attri',
            title: 'SKU속성',
            width: 130,
            notCompile: true,
            template: function(row) {
              return prodList[row.uniq]?.filter(prod => prod.attri).map(prod => prod.attri).join(',') || '';
            }
          },
          {
            key: 'prod_name,attri',
            title: 'SKU상품명_속성',
            requireStock: true,
            width: 130,
            notCompile: true,
            template: function(row) {
              if (row.set_no) {
                return row.set_name;
              } else {
                if (prodList[row.uniq]) {
                  const prod_name = (prodList[row.uniq][0].prod_name || '') + (prodList[row.uniq][0].attri ? (`_${prodList[row.uniq][0].attri.split('_').join()}`) : '');

                  return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
                }

                return '';
              }
            }
          },
          {
            key: 'pack_unit',
            title: '건별출고수량',
            tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
            requireStock: true,
            width: 110,
            notCompile: true,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.pack_unit;
            }
          },
          {
            key: 'total_cnt',
            title: '총 출고수량',
            tooltip: '건별출고수량 X 주문수량\n*추가옵션제외',
            width: 100,
            template: function(row) {
              row.out_cnt = ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.out_cnt;

              return row.isEditing ? `<span class="total_cnt" uniq="${row.uniq}">${row.out_cnt}</span>` : row.out_cnt;
            }
          },
          {
            key: 'to_name',
            title: '수령자명',
            width: 100,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.to_name) {
                row.to_name = unstoringTableEditList[row.bundle_no][row.uniq].to_name;
              }

              return row.isEditing ? `<input type="text" ptgui-regex-mask="nameAlphaNumKorEtc" max-bytes="60" ng-click="$event.stopPropagation()" ng-model="row.entity.to_name" class="form-control to_name table-form" value='${row.to_name}' bundle="${row.bundle_no}" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'to_name')" uniq="${row.uniq}" required/>` : `${row.to_name}`;
            }
          },
          {
            key: 'carr_name',
            title: '택배사',
            width: 120,
            template: function(row) {
              if (delivInfoUpdateList[row.bundle_no] && delivInfoUpdateList[row.bundle_no].carr_name) {
                row.carr_name = delivInfoUpdateList[row.bundle_no].carr_name;
              }

              return '{{row.entity.carr_name}}';
            }
          },
          {
            key: 'invoice_no',
            title: '운송장번호',
            width: 150,
            template: function(row) {
              const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
              const carr_no = row.carr_no;
              let viewdata = invoice_no;

              if (!invoice_no) {
                if (delivInfoUpdateList[row.bundle_no] && delivInfoUpdateList[row.bundle_no].invoice_no) {
                  row.invoice_no = delivInfoUpdateList[row.bundle_no].invoice_no;
                }
                row.wasEmpty = true;

                if ($scope.invoicePermission) {
                  viewdata = `<input type="text" ptgui-regex-mask="alphanumhyp" class="form-control" style="background-color:#FFFFD9" bundle="${row.bundle_no}" ng-model="row.entity.invoice_no" ng-keyup="grid.appScope.updateInvoice(${row.bundle_no}, ${row.carr_no}, row.entity.invoice_no, 'domestic')" ng-blur="grid.appScope.updateInvoice(${row.bundle_no}, ${row.carr_no}, row.entity.invoice_no, 'domestic', true)" ng-click="$event.stopPropagation();" />`;
                }
              } else if (invoice_no && (row.carr_no === 107 || row.carr_no === 1007) && row.ord_status === '운송장출력') {
                viewdata = '[승인번호]' + invoice_no;

              } else if (invoice_no && carr_no && $rootScope.possibeTrackingView.includes(carr_no)) {
                if ((carr_no === 107 || carr_no === 1007) && row.ord_status === '출고완료') {
                  viewdata = `${invoice_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${carr_no}')"></i>`;
                } else {
                  viewdata = `${invoice_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${carr_no}','${invoice_no.replace(/-/gi, '')}')"></i>`;
                }
              }

              return `${viewdata}`;
            }
          },
          {
            key: 'invoice_time',
            title: '송장입력일',
            tooltip: '운송장번호를 처음 입력한 일시',
            width: 130,
            template: function(row) {
              return !row.invoice_time ? '-' : moment(row.invoice_time).format('YYYY-MM-DD HH:mm:ss');
            }
          },
          {
            key: 'ship_plan_date',
            title: '발송예정일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(useSystemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 190,
            filter: 'isNullHyphen'
          },
          {
            key: 'shop_ord_no_real',
            title: '원주문번호',
            width: 190,
            filter: 'isNullHyphen'
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 140,
            tooltip: '스마트스토어 그룹상품인 경우 그룹 상품번호가 노출됩니다',
            template: function(row) {
              if (row.shop_cd === 'A011') {
                row.shop_sale_no = row.misc16;
              }

              let sale_no = row.shop_sale_no || '';

              if (row.shop_cd === 'B378') { // 쿠팡
                sale_no = row.misc5;
                if (row.misc9) {
                  sale_no += `?vendorItemId=${row.misc9}`;
                }
              } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
                sale_no += `?sitmNo=${row.misc17}`;
              } else if (row.pa_shop_cd === 'A077' && row.misc9) {
                sale_no = row.misc9;
              }

              if (sale_no && !onlineProductSVC.impossibeDetailView.some(shop_cd => row.shop_cd.includes(shop_cd))) {
                return (
                  `<span>
                    <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                    <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${row.shop_cd}','${row.shop_id}','${sale_no}')"></i></span>`
                );
              } else {
                return row.shop_sale_no;
              }
            }
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 300,
            template: function(row) {
              //이미지+상품명
              const exchangeSpan =
                row.exchange_ord_yn == 1 || row.exchange_yn == 1
                  ? '<span class="label label-default bg-blue">교환주문</span> '
                  : '';
              let giftSpan = '';
              let arrivalGuaranteed = '';
              let shipHopeDate = '';

              // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가 및 도착보장 플래그 추가
              if (['A077', 'A000'].includes(row.pa_shop_cd)) {
                giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
                arrivalGuaranteed = ['true', 'is_arrival_guaranteed'].includes(row.misc17) ? '<span><img ng-src="/assets/images/naver_flagX2.png" width="75" height="25" class="mr-5" alt="" src="/assets/images/naver_flagX2.png"></span>' : '';
                shipHopeDate = ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(row.misc19) ? '<span class="label bg-delivery">희망일배송</span> ' : '';
              }              // 클레임 주문에 대한 사본주문은 플래그 노출
              const claimCopyOrderSpan = row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-red">사본</span> ' : '';
              const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
              const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.shop_sale_name) {
                row.shop_sale_name = unstoringTableEditList[row.bundle_no][row.uniq].shop_sale_name;
              }

              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              if (row.isEditing) {
                return `<input type="text" ptgui-regex-mask="shopSaleNameAlphaNumKorEtc" max-bytes="170" ng-paste="grid.appScope.handlePaste($event)" ng-click="$event.stopPropagation()" required ng-model="row.entity.shop_sale_name" class="form-control shop_sale_name table-form" value='${filteredShopSaleName}' ng-keyup="grid.appScope.updateTableEditList('${row.bundle_no}', '${row.uniq}', 'shop_sale_name')" uniq="${row.uniq}"/>`;
              }

              if (row.sale_img) {
                const img = row.sale_img || '/assets/images/upload.png';

                return (
                  `<span>${
                    exchangeSpan
                  }${arrivalGuaranteed}${shipHopeDate}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}<img src='${
                    img
                  }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                    filteredShopSaleName
                  }</span>`
                );
              } else {
                return `<span>${exchangeSpan}${arrivalGuaranteed}${shipHopeDate}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}</span>`;
              }
            }
          },
          {
            key: 'shop_opt_name',
            title: '옵션',
            width: 220,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.shop_opt_name) {
                row.shop_opt_name = unstoringTableEditList[row.bundle_no][row.uniq].shop_opt_name;
              }

              if (row.isEditing) {
                return `<input type="text" max-bytes="170" ng-click="$event.stopPropagation()" not-utf-16-emoji ng-model="row.entity.shop_opt_name" class="form-control table-form shop_opt_name" value='${row.shop_opt_name}' ng-keyup="grid.appScope.updateTableEditList('${row.bundle_no}', '${row.uniq}', 'shop_opt_name')" uniq="${row.uniq}" />`;
              }

              return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
            }
          },
          {
            key: 'sale_cnt',
            title: '주문수량',
            width: 60,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.sale_cnt) {
                row.sale_cnt = unstoringTableEditList[row.bundle_no][row.uniq].sale_cnt;
              }

              const value = !row.sale_cnt || ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.sale_cnt;

              return row.isEditing ? `<input type="text" ptgui-regex-mask="onlyNum" max="16777215" ng-click="$event.stopPropagation()" ng-model="row.entity.sale_cnt" class="form-control table-form sale_cnt" uniq="${row.uniq}" value='${value}' style="text-align:center" ng-keyup="grid.appScope.updateTableEditList('${row.bundle_no}', '${row.uniq}', 'sale_cnt'); grid.appScope.updateTotalCnt(row.entity.uniq, ${row.pack_unit})"/>` : `${value}`;
            }
          },
          {
            key: 'shop_add_opt_name',
            title: '추가구매옵션',
            width: 150,
            notCompile: true,
            template: function(row) {
              // "추가구매옵션1=2;추가구매옵션2=3"
              let return_value = '';
              const retVals = [];

              if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

                _(addProdList[row.uniq])
                  .groupBy('ord_opt_seq')
                  .map(arr => {
                    retVals.push(`${arr[0].ord_opt_name}-${arr[0].opt_sale_cnt}개`);
                  })
                  .value();

                return_value = retVals.join(', ');
              }

              return return_value;
            }
          },
          {
            key: 'order_name',
            title: '주문자(ID)',
            width: 150,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.order_name;

              if (row.order_id) {
                return_value += `(${row.order_id})`;
              }

              return return_value;
            }
          },
          {
            key: 'ship_msg',
            title: '배송메세지',
            width: 200,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.ship_msg) {
                row.ship_msg = unstoringTableEditList[row.bundle_no][row.uniq].ship_msg;
              }

              return row.isEditing ? `<input type="text" max-bytes="100" ng-click="$event.stopPropagation()" ng-model="row.entity.ship_msg" class="form-control table-form ship_msg" value='${row.ship_msg}' ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'ship_msg')" uniq="${row.uniq}"/>` : `${row.ship_msg}`;
            }
          },
          {
            key: 'notice_msg',
            title: '기타메세지',
            width: 200
          },
          {
            key: 'to_addr1',
            title: '수령자 주소',
            width: 300,
            minWidth: 180,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.to_addr1) {
                row.to_addr1 = unstoringTableEditList[row.bundle_no][row.uniq].to_addr1;
              }

              let return_value = row.to_addr1 || '';

              if (return_value.charAt(0) != '[' && row.to_zipcd && return_value) {
                return_value = `[${row.to_zipcd}]${return_value}`;
              }
              if (row.to_addr2) {
                return_value = `${return_value} ${row.to_addr2}`;
              }

              return row.isEditing
                ? `<div class="dis-flex" ng-click="$event.stopPropagation()" style="height: 20px; margin-right: auto;">
                <div style="width: 20px; font-size: 10px; display: flex; jusalign-items: center; padding: 0;" class="btn btn-primary-border" ng-click="$event.stopPropagation()">
                  <span style="margin: 4px;" ptgui-zipcode zip-no="row.entity.to_zipcd" road-addr="row.entity.to_addr1" bundle="row.entity.bundle_no" uniq="row.entity.uniq">
                    <i class="fa fa-search" style="margin: 0"></i>
                  </span>
                </div>
                <input style="width: 40px" type="text" ng-click="$event.stopPropagation()" disabled ng-model="row.entity.to_zipcd" class="form-control table-form to_zipcd" value='${row.to_zipcd}' bundle="${row.bundle_no}" ng-change="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'zip_cd')" uniq="${row.uniq}" required/>
                <input style="width: 70%" type="text" max-bytes="170" ng-click="$event.stopPropagation()" ng-model="row.entity.to_addr1" class="form-control to_addr1 table-form" value='${return_value}' required bundle="${row.bundle_no}" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq,'to_addr1')" uniq="${row.uniq}" />
              </div>`
                : return_value;
            }
          },
          {
            key: 'to_htel',
            title: '수령자 휴대폰번호',
            width: 150,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.to_htel) {
                row.to_htel = unstoringTableEditList[row.bundle_no][row.uniq].to_htel;
              }

              return row.isEditing ? `<input type="text" ptgui-regex-mask="onlyNum_" max-bytes="30" ng-click="$event.stopPropagation()" ng-model="row.entity.to_htel" class="form-control to_htel table-form" value='${row.to_htel}' required bundle="${row.bundle_no}" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'to_htel')" uniq="${row.uniq}" />` : `${row.to_htel}`;
            }
          },
          {
            key: 'tag_pack',
            title: '사용자태그',
            tooltip: '설정한 조건에 맞게 적용한 사용자태그입니다.',
            width: 130,
            template: function(row) {
              // 각 행의 color 속성에 해당하는 컬러 코드를 찾아서 이름에 해당 컬러를 적용
              return row.tag_pack ? $rootScope.getColorName(row.tag_pack).join(', ') : '';
            }
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 150
          },
          {
            key: 'ord_bundle_gift_terms_prod',
            title: '규칙적용사은품',
            tooltip: '솔루션에서 설정한 규칙이 적용된 사은품입니다.\n묶음 주문은 적용된 규칙 사은품이 각 주문 별로 모두 출력됩니다.',
            width: 120,
            template: function (row) {
              // 사은품명1#SKU코드1#재고코드1#속성1#개수1#사은품명1(sku명X)#사은품규칙명1#바코드1|사은품명2#SKU코드2#재고코드2#속성2#개수2#사은품명2(sku명X)#사은품규칙명2#바코드2
              let gift_prod_name = '';

              if (row.gift_pack) {
                const regex = /([^|]*?(?:[^#]*#){7}[^#]*?)(?=\|)/g;

                // 사은품명에 특수문자 '|' 포함한 경우가 있어서 #가 7번 반복된 이후 나오는 '|' 기준으로 사은품 쪼개기
                const giftList = [];
                let match;
                let prevIdx = 0;
                let idx = 0;

                while ((match = regex.exec(row.gift_pack))) {
                  giftList.push(idx ? match[0].substring(1) : match[0]);
                  prevIdx = regex.lastIndex;
                  idx++;
                }

                giftList.push(prevIdx ? row.gift_pack.substring(prevIdx).substring(1) : row.gift_pack);

                gift_prod_name = giftList.map(gift => {
                  const vals = gift.split('#'); // [0]: 사은품명, [1]: SKU코드, [4]: 사은품출고개수

                  return `${vals[0]}-${vals[4]}개`;
                }).join(', ');
              }

              return gift_prod_name;
            }
          },
          {
            key: 'gift_name',
            title: '사은품',
            tooltip: '쇼핑몰에서 수집한 주문정보에 기재된 사은품입니다.',
            width: 100,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.gift_name) {
                row.gift_name = unstoringTableEditList[row.bundle_no][row.uniq].gift_name;
              }

              return row.isEditing ? `<input type="text" max-bytes="100" ng-click="$event.stopPropagation()" ng-model="row.entity.gift_name" class="form-control table-form gift_name" value='${row.gift_name}' ng-keyup="grid.appScope.updateTableEditList('${row.bundle_no}', '${row.uniq}', 'gift_name')" uniq="${row.uniq}" />` : `${row.gift_name || ''}`;
            }
          },
          {
            key: 'ship_method',
            title: '배송방법',
            width: 120,
            template: function (row) {
              if (delivInfoUpdateList[row.bundle_no] && delivInfoUpdateList[row.bundle_no].ship_method) {
                row.ship_method = delivInfoUpdateList[row.bundle_no].ship_method;
              }

              return '{{row.entity.ship_method}}';
            }
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 100,
            template: function(row) {
              row.ship_cost = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.ship_cost, '', 0);
              if (delivInfoUpdateList[row.bundle_no] && delivInfoUpdateList[row.bundle_no].ship_cost) {
                row.ship_cost = delivInfoUpdateList[row.bundle_no].ship_cost;
              }

              return '{{row.entity.ship_cost}}';
            }
          },
          {
            key: 'shop_ship_no',
            title: '배송번호',
            width: 140
          },
          {
            key: 'out_time',
            title: '출고완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_time',
            title: '주문일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_status_mdate',
            title: '상태변경일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_confirm_time',
            title: '주문확인일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'sales',
            title: '금액',
            width: 100,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.sales) {
                row.sales = unstoringTableEditList[row.bundle_no][row.uniq].sales;
              }
              const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.sales, '', 0);

              return row.isEditing
                ? `<input type="text" ptgui-regex-mask="onlyNum" comma max="9999999999" ng-click="$event.stopPropagation()" ng-model="row.entity.sales" class="form-control table-form sales" value='${value}' style="text-align:right" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'sales')" uniq="${row.uniq}"/>`
                : value;
            }
          },
          {
            key: 'ship_delay_yn',
            title: '배송지연여부',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.ship_delay_yn
                ? '<i class="icon-primitive-dot text-success-600"></i>'
                : '<i class="icon-primitive-dot text-grey-100"></i>';
            }
          },
          {
            key: 'multi_bundle_yn',
            title: '묶음주문여부',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.multi_bundle_yn
                ? '<i class="icon-primitive-dot text-success-600"></i>'
                : '<i class="icon-primitive-dot text-grey-100"></i>';
            }
          },
          {
            key: 'order_tel',
            title: '주문자 전화번호',
            width: 150
          },
          {
            key: 'order_htel',
            title: '주문자 휴대폰번호',
            width: 150
          },
          {
            key: 'to_tel',
            title: '수령자 전화번호',
            width: 150,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.to_tel) {
                row.to_tel = unstoringTableEditList[row.bundle_no][row.uniq].to_tel;
              }

              return row.isEditing ? `<input type="text" ptgui-regex-mask="onlyNum_" max-bytes="30" ng-click="$event.stopPropagation()" ng-model="row.entity.to_tel" class="form-control to_tel table-form" value='${row.to_tel}' bundle="${row.bundle_no}" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'to_tel')" uniq="${row.uniq}" />` : `${row.to_tel}`;
            }
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 120
          },
          {
            key: 'barcode',
            title: '바코드',
            width: 120
          },
          {
            key: 'gprivate_no',
            title: '개인통관번호',
            width: 150,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.gprivate_no) {
                row.gprivate_no = unstoringTableEditList[row.bundle_no][row.uniq].gprivate_no;
              }
              const value = !row.gprivate_no ? '' : row.gprivate_no;

              return row.isEditing ? `<input type="text" max-bytes="100" ng-click="$event.stopPropagation()" ng-model="row.entity.gprivate_no" class="form-control table-form gprivate_no" value='${value}' ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'gprivate_no')" uniq="${row.uniq}"/>` : `${value}`;
            }
          },
          {
            key: 'last_exceldown_time',
            title: '엑셀 다운일시',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_cost_price',
            title: '원가',
            tooltip: $rootScope.userProfileCheck('sol_stock', 1) ? '주문에 매칭된 SKU상품 혹은 온라인상품에 입력된 원가 정보입니다. (입력된 원가*출고수량*주문수량)' : '해당 온라인상품에 입력된 원가 정보입니다. (입력된 원가*주문수량)',
            width: 100,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.shop_cost_price) {
                row.shop_cost_price = unstoringTableEditList[row.bundle_no][row.uniq].shop_cost_price;
              }
              const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.shop_cost_price, '', 0);

              return row.isEditing
                ? `<input type="text" ptgui-regex-mask="onlyNum" comma max="9999999999" ng-click="$event.stopPropagation()" ng-model="row.entity.shop_cost_price" class="form-control table-form shop_cost_price" value='${value}' style="text-align:right" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'shop_cost_price')" uniq="${row.uniq}"/>`
                : value;
            }
          },
          {
            key: 'shop_supply_price',
            title: '공급가',
            width: 100,
            template: function(row) {
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.shop_supply_price) {
                row.shop_supply_price = unstoringTableEditList[row.bundle_no][row.uniq].shop_supply_price;
              }
              const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.shop_supply_price, '', 0);

              return row.isEditing
                ? `<input type="text" ptgui-regex-mask="onlyNum" comma max="9999999999" ng-click="$event.stopPropagation()" ng-model="row.entity.shop_supply_price" class="form-control table-form shop_supply_price" value='${value}' style="text-align:right" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'shop_supply_price')" uniq="${row.uniq}"/>`
                : value;
            }
          },
          {
            key: 'order_msg',
            title: '추가메세지',
            width: 200
          },
          {
            key: 'bundle_avail_yn',
            title: '합포장 가능여부',
            width: 100,
            template: function (row) {
              return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
            }
          },
          {
            key: 'supp_name',
            title: '매입처',
            width: 130,
            notCompile: true,
            template: row => {
              return prodList[row.uniq] ? (prodList[row.uniq].map(prod => prod.supp_no ? prod.supp_name : '')).filter(item => !!item?.trim()) : '';
            }
          },
          {
            key: 'pay_amt',
            title: '실결제금액',
            tooltip: '쇼핑몰에서 수집한 상품의 실결제금액(해당 값을 제공하지 않는 경우 공란)',
            width: 100,
            template: function(row) {
              let value = '';

              if (row.pay_amt) {
                value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                  row.ord_status
                ) > -1 && !$rootScope.osse_sol
                  ? 0
                  : $filter('currency')(row.pay_amt, '', 0);
              }

              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.pay_amt) {
                row.pay_amt = unstoringTableEditList[row.bundle_no][row.uniq].pay_amt;
              }

              return row.isEditing
                ? `<input type="text" ptgui-regex-mask="onlyNum" comma max="9999999999" ng-click="$event.stopPropagation()" ng-model="row.entity.pay_amt" class="form-control table-form pay_amt" value='${value}' style="text-align:right" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'pay_amt')" uniq="${row.uniq}"/>`
                : value;
            }
          },
          {
            key: 'discount_amt',
            title: '할인금액',
            tooltip: '쇼핑몰에서 수집한 상품의 할인금액(해당 값을 제공하지 않는 경우 공란)',
            width: 100,
            template: function(row) {
              let value = '';

              if (row.discount_amt) {
                value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                  row.ord_status
                ) > -1 && !$rootScope.osse_sol
                  ? 0
                  : $filter('currency')(row.discount_amt, '', 0);
              }
              if (unstoringTableEditList?.[row.bundle_no]?.[row.uniq]?.discount_amt) {
                row.discount_amt = unstoringTableEditList[row.bundle_no][row.uniq].discount_amt;
              }

              return row.isEditing
                ? `<input type="text" ptgui-regex-mask="onlyNum" comma max="9999999999" ng-click="$event.stopPropagation()" ng-model="row.entity.discount_amt" class="form-control table-form discount_amt" value='${value}' style="text-align:right" ng-keyup="grid.appScope.updateTableEditList(${row.bundle_no}, row.entity.uniq, 'discount_amt')" uniq="${row.uniq}"/>`
                : value;
            }
          },
          {
            key: 'ship_hope_time',
            title: '희망배송일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'cart_cd',
            title: '결제번호',
            width: 130
          },
        ],
        initShowCount: function(showCount) {
          setTimeout(() => {
            domesticSearch.searchData.showCount = showCount;
          }, 100);
          if ($scope.selectedShopType !== 'domestic') {
            setTimeout(() => {
              $scope.searchData.showCount = globalSearch.searchData.showCount;
            }, 200);
          }
        }
      };

      // 쇼핑몰 상품명에 붙여넣기 시 쇼핑몰 상품명 정규표현식 체크함수
      $scope.handlePaste = (event) => {
        const pastedData = (event.originalEvent || event).clipboardData.getData('text');

        const shopSaleNameRegx = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s-_/~+,.(){}[\]%&└':]+$/;

        if (!shopSaleNameRegx.test(pastedData)) {
          event.preventDefault();
        }
      };

      $scope.global = angular.copy($scope.domestic);
      $scope.global.options.notVisibleColumns = ['shop_add_opt_name', 'notice_msg', 'ord_time', 'pay_time', 'ord_status_mdate', 'gprivate_no', 'order_tel', 'order_htel', 'to_tel', 'to_email', 'stock_cd', 'prod_name', 'attri', 'pack_unit', 'total_cnt', 'ship_method', 'shop_supply_price', 'last_exceldown_time', 'order_msg', 'bundle_avail_yn', 'supp_name', 'misc10', 'misc14', 'misc15', 'barcode', 'out_order_time', 'out_time', 'weight', 'order_email', 'tag_pack', 'prod_name,attri'];
      $scope.global.options.externalRequestOptions = {
        requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
        requestWillAction: function(data) {
          $scope.searchForm.shopType = 'global';

          if (userInfo.user.auth_type === '배송처' && !userInfo.user.depot_no) {
            commonSVC.showMessage('담당 배송처가 없습니다.');
          }

          if ($rootScope.order_search.page === 'unstoring' && $rootScope.order_search.search) {
            $scope.searchForm.search_word = $rootScope.order_search.input;
            $scope.searchForm.search_key = $rootScope.order_search.key;
            $scope.searchData.search_key_name = $rootScope.order_search.name;
            $rootScope.order_search.search = false;
            $scope.checkNoneOrd = true;
          }

          if ($rootScope.side_search.page === 'unstoring' && $rootScope.side_search.search) {
            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $scope.searchForm.status = $rootScope.side_search.status;
            $scope.selectCount = $rootScope.side_search.selectCount;
            $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
            $rootScope.side_search.search = false;
            $scope.checkNoneOrd = true;
          }
          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData.global = angular.copy(data);

          orderby.global = data.orderby;

          return data;
        },
        requestDidAction: function(result) {
          // SKU상품정보 처리
          prodList = [];
          addProdList = [];
          result.results_prod.forEach(function(subRow) {
            if (subRow.add_opt_yn == 1) {
              if (!addProdList[subRow.uniq]) { addProdList[subRow.uniq] = []; }

              addProdList[subRow.uniq].push(subRow);

            } else {
              if (!prodList[subRow.uniq]) { prodList[subRow.uniq] = []; }

              prodList[subRow.uniq].push(subRow);
            }
          });

          // 처음 데이터 load 시 활성화된 탭보다 나중에 응답 올 경우 값이 비정상 적으로 들어가는 경우가 존재하여 interval 다른 탭 데이터 로드 후 처리
          const timer = setInterval(async () => {
            if ($scope.selectedShopType !== 'global' || $scope.loadShopData.domestic) {
              $scope.isOpen = false;

              outConfirmAvailCount = result.availCount['출고완료_avail_cnt'];
              invoicePrintAvailCount = result.availCount['운송장출력_avail_cnt'];
              $scope.searchData.totalCount = result.recordsTotal;
              $scope.totalCount = result.recordsTotalCount;
              $scope.globalTotalCount = result.recordsTotalCount;
              // 합포장 분리시 필요
              $scope.bundle_group = _.groupBy(result.results, 'bundle_no');

              // 합포장 갯수
              $scope.bundleCnt = result.recordsTotal;

              orderList = result.results;
              columnModel.setOld(_.cloneDeep(result.results), 'unstoring');

              // 전체 주문 조회 시 해당 탭 주문 건 없을 경우 다른 탭으로 조회 하여 보여줌
              if ($rootScope.order_search.page === 'unstoring' && !$scope.globalTotalCount && $scope.checkNoneOrd) {
                $scope.selectShopType('domestic');

                $scope.checkNoneOrd = false;
              }

              init(result.statusCount);

              columnSVC.addColIntSet('all', result.results);

              $scope.loadShopData.global = true;
              clearInterval(timer);
              $timeout(() => {});
            }
          }, 100);

          return result.results;
        }
      };
      $scope.global.options.columns = [
        {
          key: 'widget',
          title: '도구',
          width: 170,
          template: function(row) {
            const notBundleStatus = !divideStatus.includes(row.ord_status);

            const divide = `<button class="btn btn-default btn-xxs" ng-click="$event.preventDefault(); grid.appScope.divideBundle('${row.bundle_no}','${row.uniq}')" ng-disabled="${notBundleStatus} || grid.appScope.bundle_group[${row.bundle_no}].length < 2">주문분할</button>`;

            return `<button class="btn btn-default btn-xxs mr-5" ng-click="$event.preventDefault(); grid.appScope.showDetail('${row.uniq}', true)" >상세</button>
              <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="$event.preventDefault(); grid.appScope.leftTabAction(2)">메모</button>${divide}`;
          }
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          name: 'bundle_no',
          width: 170
        },
        {
          key: 'ship_unable_reason',
          title: '출고가능여부',
          width: 90,
          notCompile: true,
          template: function(row) {
            let dot = '<span class="text-success-600">●</span>';

            if (!row.ship_avail_yn) {
              let reason = row.ship_unable_reason ? row.ship_unable_reason : '';

              // 주문수집 및 매칭과정에서의 오류로 매칭정보는 있으나 배송처정보가 없는 경우가 발생해서 해당 주문은 재매칭하게 안내 2018-11-14 Amelia
              if (!row.depot_no && prodList[row.uniq][0].sku_cd) {
                reason = '배송처 오류(매칭 재시도 요망)';
              }

              // 묶음주문상태확인 메세지는 묶여있는 다른 주문의 재고부족등의 이슈로 해당 주문은 출고가능이기 때문에 조건 처리함 2018-10-31 rony
              if (reason != '묶음주문 상태확인') {
                dot = `<span class="text-danger">불가(${reason})</span>`;
              }
              else if (!reason) {
                dot = '<span class="text-danger">불가</span>';
              }
            }

            return dot;
          }
        },
        {
          key: 'map_yn',
          title: '매칭여부',
          requireStock: true,
          width: 80,
          template: function(row) {
            return row.map_yn
              ? `<button class="btn btn-default btn-xxs" ng-disabled="${$rootScope.osse_sol}" ng-click="$event.preventDefault(); grid.appScope.mappingProd('${row.uniq}','${row.wdate}')" >수정</button>`
              : `<button class="btn bg-white border-warning-300 text-warning-300 btn-xxs" ng-click="$event.preventDefault(); grid.appScope.mappingProd('${row.uniq}','${row.wdate}')" >미매칭</button>`;
          }
        },
        {
          key: 'ord_status',
          title: '출고상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            return (
              `<span id="${row.uniq}_ordStatus">${$filter('statusColor')(row.ord_status)}</span>`
            );
          }
        },
        {
          key: 'out_order_time',
          title: '출고지시일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          requireStock: true,
          width: 160,
          template: function(row) {
            if (row.set_no && prodList[row.uniq]) {
              return `<button class="btn btn-xxs btn-success" ng-click="$event.preventDefault(); grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
            } else if (prodList[row.uniq]) {
              return prodList[row.uniq].length > 1 ?
                `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="$event.preventDefault(); grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                : prodList[row.uniq][0].sku_cd;
            } else {
              return '미매칭 출고';
            }
          }
        }, {
          key: 'stock_cd',
          title: '재고관리코드',
          tooltip: '세트상품의 경우 대표로 지정된 SKU의 재고관리코드만 항목에 노출됩니다.',
          requireStock: true,
          width: 160,
          template: (row) => {
            if (row.set_no && prodList[row.uniq]) {
              const findList = prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

              return findList ? findList.stock_cd : '';
            } else if (prodList[row.uniq]) {
              return prodList[row.uniq][0].stock_cd;
            } else {
              return '';
            }
          }
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          requireStock: true,
          width: 130,
          notCompile: true,
          template: function(row) {
            if (row.set_no) {
              return row.set_name;
            } else {
              if (prodList[row.uniq]) {
                const prod_name = (prodList[row.uniq][0].prod_name || '');

                return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
              }

              return '';
            }
          }
        },
        {
          key: 'attri',
          title: 'SKU속성',
          width: 130,
          notCompile: true,
          template: function(row) {
            return prodList[row.uniq]?.filter(prod => prod.attri).map(prod => prod.attri).join(',') || '';
          }
        },
        {
          key: 'prod_name,attri',
          title: 'SKU상품명_속성',
          requireStock: true,
          width: 130,
          notCompile: true,
          template: function(row) {
            if (row.set_no) {
              return row.set_name;
            } else {
              if (prodList[row.uniq]) {
                const prod_name = (prodList[row.uniq][0].prod_name || '') + (prodList[row.uniq][0].attri ? (`_${prodList[row.uniq][0].attri.split('_').join()}`) : '');

                return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
              }

              return '';
            }
          }
        },
        {
          key: 'pack_unit',
          title: '건별출고수량',
          tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
          requireStock: true,
          width: 110,
          notCompile: true,
          template: (row) => {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.pack_unit;
          }
        },
        {
          key: 'total_cnt',
          title: '총 출고수량',
          tooltip: '건별출고수량 X 주문수량\n*추가옵션제외',
          width: 100,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.out_cnt;
          }
        },
        {
          key: 'to_name',
          title: '수령자명',
          width: 100
        },

        {
          key: 'ship_plan_date',
          title: '발송예정일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'misc10',
          title: '발송기한',
          width: 130,
          filter: 'dateValid',
          template: function(row) {
            return moment(row.misc10).format('YYYY-MM-DD hh:mm:ss');
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '직접입력';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(useSystemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                ${shop_info[1]}
              </span>`;
            }

            return img;
          }
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호1',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'misc13',
          title: '쇼핑몰 주문번호2',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 140,
          template: function(row) {
            if (row.shop_cd === 'A011') {
              row.shop_sale_no = row.misc16;
            }

            let sale_no = row.shop_sale_no || '';

            if (row.shop_cd === 'B378') { // 쿠팡
              sale_no = row.misc5;
              if (row.misc9) {
                sale_no += `?vendorItemId=${row.misc9}`;
              }
            } else if (row.shop_cd === 'B118') { // 메이크샵
              sale_no = row.misc3;
            } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
              sale_no += `?sitmNo=${row.misc17}`;
            }

            if (sale_no && !onlineProductSVC.impossibeDetailView.some(shop_cd => row.shop_cd.includes(shop_cd))) {
              return (
                `<span>
                  <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                  <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${row.shop_cd}','${row.shop_id}','${sale_no}')"></i></span>`
              );
            } else {
              return row.shop_sale_no;
            }
          }
        },
        {
          key: 'shop_sale_name',
          title: '온라인 상품명',
          width: 300,
          notCompile: true,
          template: function(row) {
            //이미지+상품명
            const exchangeSpan =
              row.exchange_ord_yn == 1 || row.exchange_yn == 1
                ? '<span class="label label-default bg-blue">교환주문</span> '
                : '';
            let giftSpan = '';
            // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가
            if (['A077', 'A000'].includes(row.pa_shop_cd)) {
              giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
            }            // 클레임 주문에 대한 사본주문은 플래그 노출
            const claimCopyOrderSpan = row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-red">사본</span> ' : '';
            const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
            const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            if (row.sale_img) {
              const img = row.sale_img || '/assets/images/upload.png';

              return (
                `<span>${
                  exchangeSpan
                }${giftSpan}${claimCopyOrderSpan}<img src='${
                  img
                }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                  filteredShopSaleName
                }</span>`
              );
            } else {
              return `<span>${exchangeSpan}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}</span>`;
            }
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'misc17',
          title: '옵션코드',
          width: 200,
          filter: 'isNullHyphen'
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60,
          template: (row) => {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : row.sale_cnt;
          }
        },
        {
          key: 'shop_add_opt_name',
          title: '추가구매옵션',
          width: 150,
          notCompile: true,
          template: function(row) {
            // "추가구매옵션1=2;추가구매옵션2=3"
            let return_value = '';
            const retVals = [];

            if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

              _(addProdList[row.uniq])
                .groupBy('ord_opt_seq')
                .map(arr => {
                  retVals.push(`${arr[0].ord_opt_name}-${arr[0].opt_sale_cnt}개`);
                })
                .value();

              return_value = retVals.join(', ');
            }

            return return_value;
          }
        },
        {
          key: 'weight',
          title: '무게(g)',
          width: 80,
          template: function(row) {
            return row.misc16;
          }
        },
        {
          key: 'order_name',
          title: '주문자(ID)',
          width: 150,
          notCompile: true,
          template: function(row) {
            //속성
            let return_value = row.order_name;

            if (row.order_id) {
              return_value += `(${row.order_id})`;
            }

            return return_value;
          }
        },
        {
          key: 'ship_msg',
          title: '배송메세지',
          width: 200
        },
        {
          key: 'notice_msg',
          title: '기타메세지',
          width: 200
        },
        {
          key: 'to_addr1',
          title: '수령자 주소',
          width: 300,
          notCompile: true,
          template: function(row) {
            const addressParts = [row.to_addr2, row.to_addr1, row.to_city, row.to_state].filter(Boolean);
            let return_value = addressParts.join(', ');

            if (return_value && return_value.charAt(0) != '[' && row.to_zipcd) {
              return_value = `[${row.to_zipcd}]${return_value}`;
            }

            return return_value;
          }
        },
        {
          key: 'to_htel',
          title: '수령자 휴대폰번호',
          width: 150
        },
        // {
        //   key: 'tag_pack',
        //   title: '사용자태그',
        //   tooltip: '설정한 조건에 맞게 적용한 사용자태그입니다.',
        //   width: 130,
        //   template: function(row) {
        //     // 각 행의 color 속성에 해당하는 컬러 코드를 찾아서 이름에 해당 컬러를 적용
        //     return row.tag_pack ? $rootScope.getColorName(row.tag_pack).join(', ') : '';
        //   }
        // },
        {
          key: 'to_email',
          title: '수령자 이메일',
          width: 150
        },
        {
          key: 'to_ctry_cd',
          title: '수령자 국가',
          width: 80,
          template: function(row) {
            return countryList.find(country => country.ctry_cd === row.to_ctry_cd)?.ctry_name || '';
          }

        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 150
        },
        {
          key: 'ship_method',
          title: '배송방법',
          width: 120,
          template: function (row) {
            return row.misc12;
          }
        },
        {
          key: 'sales_price',
          title: '쇼핑몰 판매금액',
          tooltip: '할인금액 * 수량 (Voucher 등의 장바구니 할인 제외)',
          template: function(row) {
            return Number(row.sales_unit * row.sale_cnt).toFixed(2);
          },
          width: 120
        },
        {
          key: 'sales_unit',
          title: '쇼핑몰 판매단가',
          tooltip: '개당 할인금액 (Voucher 등의 장바구니 할인 제외)',
          width: 120,
          template: function(row) {
            return Number(row.sales_unit).toFixed(2);
          }
        },
        {
          key: 'ship_cost',
          title: '배송비',
          width: 100,
          template: function(row) {
            return Number(row.ship_cost).toFixed(2);
          }
        },
        {
          key: 'global_carr_name',
          title: '해외택배사',
          width: 140
        },
        {
          key: 'global_invoice_no',
          title: '해외운송장번호',
          width: 140,
          template: function(row) {
            const global_invoice_no = row.global_invoice_no || '';
            let viewdata = global_invoice_no;

            if (!global_invoice_no) {
              if (delivInfoUpdateList[row.bundle_no] && delivInfoUpdateList[row.bundle_no].global_invoice_no) {
                row.global_invoice_no = delivInfoUpdateList[row.bundle_no].global_invoice_no;
              }
              row.wasEmpty = true;

              if ($scope.invoicePermission) {
                viewdata = `<input type="text" ptgui-regex-mask="alphanumhyp" class="form-control" style="background-color:#FFFFD9" bundle="${row.bundle_no}" ng-model="row.entity.global_invoice_no" ng-keyup="grid.appScope.updateInvoice(${row.bundle_no}, ${row.global_carr_no}, row.entity.global_invoice_no, 'global')" ng-blur="grid.appScope.updateInvoice(${row.bundle_no}, ${row.global_carr_no}, row.entity.global_invoice_no, 'global', true)" ng-click="$event.stopPropagation();" />`;
              }
            }

            return viewdata;
          }
        },
        // {
        //   key: 'carr_name',
        //   title: '국내택배사',
        //   width: 120,
        //   template: function(row) {
        //     if (delivInfoUpdateList[row.bundle_no] && delivInfoUpdateList[row.bundle_no].carr_name) {
        //       row.carr_name = delivInfoUpdateList[row.bundle_no].carr_name;
        //     }

        //     return '{{row.entity.carr_name}}';
        //   }
        // },
        // {
        //   key: 'invoice_no',
        //   title: '국내운송장번호',
        //   width: 150,
        //   template: function(row) {
        //     const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
        //     const carr_no = row.carr_no;
        //     let viewdata = invoice_no;

        //     if (!invoice_no) {
        //       if (delivInfoUpdateList[row.bundle_no] && delivInfoUpdateList[row.bundle_no].invoice_no) {
        //         row.invoice_no = delivInfoUpdateList[row.bundle_no].invoice_no;
        //       }
        //       row.wasEmpty = true;

        //       if ($scope.invoicePermission) {
        //         viewdata = `<input type="text" ptgui-regex-mask="alphanumhyp" class="form-control" style="background-color:#FFFFD9" bundle="${row.bundle_no}" ng-model="row.entity.invoice_no" ng-keyup="grid.appScope.updateInvoice(${row.bundle_no}, ${row.carr_no}, row.entity.invoice_no, 'domestic')" ng-blur="grid.appScope.updateInvoice(${row.bundle_no}, ${row.carr_no}, row.entity.invoice_no, 'domestic', true)" ng-click="$event.stopPropagation();" />`;
        //       }
        //     } else if (invoice_no && (row.carr_no === 107 || row.carr_no === 1007) && row.ord_status === '운송장출력') {
        //       viewdata = '[승인번호]' + invoice_no;
        //     } else if (carr_no && invoice_no && $rootScope.possibeTrackingView.includes(carr_no)) {
        //       if ((carr_no === 107 || carr_no === 1007) && row.ord_status === '출고완료') {
        //         viewdata = `<span>${invoice_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${carr_no}')"></i></span>`;
        //       } else {
        //         viewdata = `<span>${invoice_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${carr_no}','${invoice_no.replace(/-/gi, '')}')"></i></span>`;
        //       }
        //     }

        //     return `${viewdata}`;
        //   }
        // },
        // {
        //   key: 'pa_bundle_no',
        //   title: '패키지번호',
        //   width: 130
        // },
        {
          key: 'global_invoice_print_time',
          title: '송장 출력여부',
          width: 80,
          template: function(row) {
            return row.global_invoice_print_time ? '<span class="text-grey-600">출력완료</span>' : '<span class="text-success-300">출력대기</span>';
          }
        },
        {
          key: 'out_time',
          title: '출고완료일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_time',
          title: '주문일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'pay_time',
          title: '결제완료일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_status_mdate',
          title: '상태변경일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_curr_cd',
          title: '기준통화',
          width: 50,
        },
        {
          key: 'sales',
          title: '금액',
          width: 100,
          template: function(row) {
            return Number(row.sales).toFixed(2);
          }
        },
        {
          key: 'multi_bundle_yn',
          title: '묶음주문여부',
          width: 100,
          notCompile: true,
          template: function(row) {
            return row.multi_bundle_yn
              ? '<i class="icon-primitive-dot text-success-600"></i>'
              : '<i class="icon-primitive-dot text-grey-100"></i>';
          }
        },
        {
          key: 'misc15',
          title: '제조국(원산지)',
          width: 80
        },
        {
          key: 'misc14',
          title: 'HS코드',
          width: 80
        },
        {
          key: 'order_tel',
          title: '주문자 전화번호',
          width: 150
        },
        {
          key: 'order_htel',
          title: '주문자 휴대폰번호',
          width: 150
        },
        {
          key: 'order_email',
          title: '주문자 이메일',
          width: 150
        },
        {
          key: 'to_tel',
          title: '수령자 전화번호',
          width: 150
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 150
        },
        {
          key: 'barcode',
          title: '바코드',
          width: 120
        },
        {
          key: 'gprivate_no',
          title: '개인통관번호',
          width: 150
        },
        {
          key: 'last_exceldown_time',
          title: '엑셀 다운일시',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'order_msg',
          title: '추가메세지',
          width: 200
        },
        {
          key: 'bundle_avail_yn',
          title: '합포장 가능여부',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        },
        {
          key: 'supp_name',
          title: '매입처',
          width: 130,
          notCompile: true,
          template: row => {
            return prodList[row.uniq] ? (prodList[row.uniq].map(prod => prod.supp_no ? prod.supp_name : '')).filter(item => !!item?.trim()) : '';
          }
        },
        ...($rootScope.pantosYN ? [{
          key: 'pay_amt',
          title: '실결제 금액',
          width: 100,
          template: function(row) {
            return Number(row.pay_amt).toFixed(2);
          }
        },
        {
          key: 'seller_discount',
          title: '판매자부담할인액',
          width: 100,
          template: function(row) {
            return Number(row.seller_discount).toFixed(2);
          }
        },
        {
          key: 'shop_discount',
          title: '쇼핑몰부담할인액',
          width: 100,
          template: function(row) {
            return Number(row.shop_discount).toFixed(2);
          }
        },
        {
          key: 'coupon_discount',
          title: '쿠폰할인액',
          width: 100,
          template: function(row) {
            return Number(row.coupon_discount).toFixed(2);
          }
        },
        {
          key: 'point_discount',
          title: '포인트할인액',
          width: 100,
          template: function(row) {
            return Number(row.point_discount).toFixed(2);
          }
        }] : []),
      ];

      $scope.global.options.initShowCount = function(showCount) {
        setTimeout(() => {
          globalSearch.searchData.showCount = showCount;
        }, 100);
        if ($scope.selectedShopType !== 'global') {
          setTimeout(() => {
            $scope.searchData.showCount = domesticSearch.searchData.showCount;
          }, 200);
        }
      };

      // 초기 탭 그리드 세팅
      $scope.grid = $scope[$scope.selectedShopType];

      if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $rootScope.user_profile.pa_sol_no) {
        addColumnSet();
      }
      resetSearch();
      setSearch($scope.selectedShopType === 'domestic' ? domesticSearch : globalSearch);

      async function addColumnSet() {
        const domesticParams = {
          gridOption: $scope.domestic.options,
          readOnly: false,
          addcolType: 'ord',
          search_key_items: domesticSearchTemplate.searchData.search_key_items
        };
        const globalParams = {
          gridOption: $scope.global.options,
          readOnly: false,
          addcolType: 'ord',
          search_key_items: globalSearchTemplate.searchData.search_key_items
        };

        // LG 대리점 사용계정인 경우 추가처리
        if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
          // 읽기만 가능한 컬럼으로 추가
          domesticParams.readOnly = true;
          globalParams.readOnly = true;
          // enum의 첫번째 값 디폴트로 사용
          domesticParams.useEnumFirstValDefault = true;
          globalParams.useEnumFirstValDefault = true;
        }

        columnSVC.columnsSet(domesticParams);
        columnSVC.columnsSet(globalParams);

        // LG 대리점 사용계정인 경우 커스텀컬럼 작업 불가능하게 처리
        if (!$rootScope.user_profile.pa_sol_no) {
          await columnSVC.saveBtnSet({
            grid: $scope.domestic,
            table_actions: $scope.searchBtn.table_actions,
            searchDo: function (refresh, noDelay, callback) {
              $scope.domestic.methods.reloadData(function () {
                if (callback) {
                  callback();
                }
              }, refresh, noDelay);
            },
            page: domesticSearchTemplate.searchForm.page,
            ngIfFunc: () => {
              return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
          });
          await columnSVC.saveBtnSet({
            grid: $scope.global,
            table_actions: $scope.searchBtn.table_actions,
            searchDo: function (refresh, noDelay, callback) {
              $scope.global.methods.reloadData(function () {
                if (callback) {
                  callback();
                }
              }, refresh, noDelay);
            },
            page: globalSearchTemplate.searchForm.page,
            ngIfFunc: () => {
              return $scope.selectedShopType === 'global' ? 'y' : 'n';
            },
          });
        }
      }

      // LG custom -> sub user 인경우 노출항목 추가.
      if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
        columnSVC.addColumns_LG($scope.domestic.options);
        columnSVC.addColumns_LG($scope.global.options);
      }

      /**
       * openapi 사용업체는 api 호출시간 필드 출력
       */
      if ($rootScope.userProfileCheck('sol_ser', 'openapi', 'like')) {
        columnSVC.addColumns_API($scope.domestic.options);
        columnSVC.addColumns_API($scope.global.options);
      }

      /**
       * 2018-02-12 ally
       * SKU상품 매칭
       * */
      $scope.mappingProd = function(uniq, wdate) {

        // 주문재고매칭권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.mappingProd', userInfo.permission) === false) {
          return false;
        }

        const uniqList = uniq ? [uniq] : $scope.grid.methods.selectedData('uniq');

        if (uniqList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }
        const firstWdate = wdate ? [wdate] : _.sortBy($scope.grid.methods.selectedData('wdate'), function(o) { return new moment(o); });

        const resolve = {
          // 창고 리스트 조회
          warehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function (systemModel) {
            return systemModel.load();
          },
          //선택한 uniqList
          selectList: { uniq: uniqList, wdate: firstWdate[0], isGlobal: $scope.selectedShopType === 'global' }
        };

        const redata = commonSVC.openModal('full', resolve, 'OrderShipmentMappingCtrl', 'views/order/shipment/modals/mapping.html');

        redata.result.then(function(isMapping) {
          if (isMapping) { $scope.searchDo(false); }
        });
      };

      // 상품명 정리 매니저
      $scope.prodNameManager = function () {
        const selected = $scope.grid.methods.selectedData('all');
        const hasEditingOrd = selected.some(ord => ord.isEditing);

        const resolve = {
          data: {
            select_list: selected,
            targetState: '출고대기',
            shopType: $scope.selectedShopType,
          },
          //환경설정 리스트
          systemList: function (systemModel) {
            return systemModel.load();
          }
        };

        const modal = commonSVC.openModal('xg', resolve, 'ProdNameManagerCtrl', 'views/order/shipment/modals/prod_name_manager.html');

        modal.result.then(function (re) {
          if (re.isApplied) {
            if (hasEditingOrd) {
              for (const ord of selected) {
                unstoringTableEditList[ord.bundle_no][ord.uniq].isChangedData = true;
              }
              changeProdName = re.changeField;
            }
            $scope.searchDo();
          }
        });
      };

      /**
       * 판매금액 관리 매니저
       */
      $scope.supplyPriceManager = () => {
        const selectList = $scope.grid.methods.selectedData('all');
        const hasEditingOrd = selectList.some(ord => ord.isEditing);
        const modal = commonSVC.openModal('full', { data: { uniqList: $scope.grid.methods.selectedData('uniq'), shop_id_view_type: systemList.data.shop_id_view_type } }, 'SupplyPriceManagerCtrl', 'views/order/shipment/modals/supply_price_manager.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            if (hasEditingOrd) {
              for (const ord of selectList) {
                unstoringTableEditList[ord.bundle_no][ord.uniq].isChangedData = true;
              }
              changeColumn = 'shop_supply_price';
            }
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 공급가정리매니저 규칙 적용복구
       */
      $scope.restoreSupplyPrice = async () => {
        const selectList = $scope.grid.methods.selectedData('all');
        const hasEditingOrd = selectList.some(ord => ord.isEditing);

        if (!selectList.length) {
          commonSVC.showMessageHtml('선택된 주문이 없습니다.', '<ul style="margin-top:20px;"><li>복구할 주문을 선택하신 후 다시 진행해 주세요.</li></ul>');

          return false;
        }

        const confirm = await commonSVC.showConfirm('판매금액을 복구 하시겠습니까?',
          '· 판매금액 복구는 규칙을 적용 이 전의 상태로 되돌려 놓습니다.\n'
      + '· 판매금액 복구는 규칙이 적용된 주문에만 적용이 가능하며,\n'
      + '  특정항목만 복구는 불가합니다.(ex. 공급가만 복구 불가)\n'
      + '· 복구된 주문은 다시 규칙을 적용할 수 있으나, 단순히 복구 이 전의 상태로\n'
      + '  되돌려 놓는것은 불가능하니 적용 전에 충분히 고려해 주세요.\n'
      + '· 판매금액 관리 규칙 내 조건이 삭제되었거나 규칙자체가 삭제된 주문은 복구되지 않으며, 이런 경우 주문 [상세]에서 직접 수정할 수 있습니다.');

        if (confirm) {
          try {
            const re = await shipmentModel.restoreSupplyPriceRule({ bundleNoList: selectList.map((ord) => ord.bundle_no) });

            if (re.data.results === 'success') {
              if (hasEditingOrd) {
                for (const ord of selectList) {
                  unstoringTableEditList[ord.bundle_no][ord.uniq].isChangedData = true;
                }
                changeColumn = 'shop_supply_price';
              }
              $scope.searchDo(true, true);
              commonSVC.showToaster('success', '', '데이터 복구가 완료되었습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          } catch (err) {
            if (err.data.messages[0] === 'no restore data') {
              commonSVC.showToaster('error', '', '복구 가능한 데이터가 없습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          }
        }
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        resetSearch();
        setSearch($scope.selectedShopType === 'domestic' ? domesticSearch : globalSearch);
        $scope.selectCount = 'total';
        // 일괄입력 값 초기화
        $scope.setAllData = {
          type: '',
          ship_method: '',
          ship_cost: 0,
          carr_no: '',
          carr_name: ''
        };

        $scope.searchDo(true, true);
      };

      // 다중 정렬
      $scope.multiSort = async () => {
        const resolve = {
          data: {
            grid_id: $scope.selectedShopType === 'global' ? 'unstoring_shipment_globalGrid' : 'unstoring_shipment_grid',
            grid_options: $scope.grid.options
          }
        };

        const modal = commonSVC.openModal('lg', resolve, 'OrderMultiSort', 'views/order/shipment/modals/order_multi_sort.html');

        modal.result.then(res => {
          $scope.grid.methods.gridInit();
        });

      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 상세페이지 보여주기
       */
      $scope.showDetail = function(uniq, isGlobal, isEditing, bundle) {
        const resolve = {
          data: {
            fromPage: domesticSearchTemplate.searchForm.page,
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: useSystemList.data || []
          }
        };
        let modal;
        if (isGlobal) {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
        } else {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
        }
        modal.result.then(function (re) {
          if (re === 'success') {
            if (isEditing) {
              unstoringTableEditList[bundle][uniq].isChangedData = true;
            }
            $scope.searchDo(false);
          }
        });
        // $scope.isDetailShow = show;
        // var selectRow = $scope.isDetailShow && rowIndex > -1 ? rowIndex : null; // 상세보기 누른 row index
        // $scope.grid.methods.ChangeColMode(show, [ 0, 2, 4, 10 ], selectRow);
        // if(show){
        //   $state.go("main.order_shipment_unstoring_list.detail", {fromPage: 'unstoring', rowIndex: rowIndex , uniq: uniq, ord_date: ord_date});
        //   $scope.grid.methods.autoSelect(true);
        // }else{
        //   $scope.grid.methods.autoSelect(false);
        // }
      };

      $scope.showSetDetail = function(setNo) {
        const resolve = {
          data: { from: '수정', set_no: setNo }
        };

        commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
      };

      // 다중 매칭된 SKU 조회 모달
      $scope.showMultiSKUDetail = function (uniq) {
        const resolve = {
          data: { prodList: prodList[uniq] }
        };

        commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
      };

      /**
       * 카운팅에 맞는 검색 결과
       */
      $scope.countSearch = function(type) {
        // if($scope.countList[type] !== 0) {
        const sdate = $scope.searchForm.sdate, edate = $scope.searchForm.edate;

        // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
        // $scope.searchForm = angular.copy(search.searchForm);
        // $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchForm.sdate = sdate;
        $scope.searchForm.edate = edate;
        switch (type) {
          case 'total':
            $scope.searchForm.status = ['출고대기', '출고보류', '운송장출력'];
            break;
          case 'waiting':
            $scope.searchForm.status = ['출고대기'];
            break;
          case 'hold':
            $scope.searchForm.status = ['출고보류'];
            break;
          case 'invoice':
            $scope.searchForm.status = ['운송장출력'];
            break;
        }
        $scope.searchDo(true, true);
        $scope.selectCount = type;
        // }
      };

      /**
       * 출고보류
       * */
      $scope.orderHold = function() {
        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        // 출고보류 가능상태 : 출고대기, 운송장출력
        // 위의 상태가 아닌 주문건이 있는경우 에러. 2018-02-22 rony
        const chkStatus = _.filter(selected, function(o) { return (o.ord_status != '출고대기' && o.ord_status != '운송장출력'); });

        if (chkStatus.length > 0) {
          commonSVC.showMessage(gettextCatalog.getString('출고보류가 불가능한 상태의 주문건이 포함되어있습니다.'), gettextCatalog.getString('출고보류는 [출고대기].[운송장출력] 상태의 주문건만 가능합니다.\n해당 상태의 주문건만 선택하여 주신 후 작업을 재시도해 주십시오.'));

          return false;
        }

        const resolve = {};

        resolve.data = {
          selectList: selected
        };

        const modal = commonSVC.openModal('', resolve, 'orderHoldCtrl', 'views/order/shipment/modals/order_hold.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 출고보류 해제
       * */
      $scope.orderUnhold = function () {
        const selected = $scope.grid.methods.selectedData('all');
        const alertText = $scope.selectedShopType === 'global' ? '해외택배사/해외운송장번호' : '택배사/송장번호';

        if (!selected.length) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        // 출고보류 상태가 없을 경우 에러
        if (!selected.filter(o => o.ord_status === '출고보류').length) {
          commonSVC.showMessage(gettextCatalog.getString('출고 보류 해제가 가능한 주문이 없습니다.'));

          return false;
        }

        // 출고보류 해제 가능상태 : 출고보류
        let errorYn = false;
        const bundleGroups = _.groupBy(selected, 'bundle_no');

        for (const key of Object.keys(bundleGroups)) {
          const ords = bundleGroups[key];

          if (ords.length === ords.filter(o => o.ord_status !== '출고보류').length) {
            commonSVC.showMessage(
              '출고보류 해제가 불가능한 상태의 주문건이 포함되어있습니다.',
              '출고보류 해제는 [출고보류] 상태의 주문건만 가능합니다.\n해당 상태의 주문건만 선택하여 주신 후 작업을 재시도해 주십시오.'
            );

            errorYn = true;
            break;
          }
        }

        if (errorYn) {
          return false;
        }

        commonSVC.showConfirmHtml(
          '선택한 ' +
          '<span class="text-primary">' +
          selected.length +
          '</span>건의 주문을 출고 보류 해제하시겠습니까?',
          `* ${alertText}가 입력되지 않은 주문건은 <span class="text-warning">[출고대기]</span>로 <br>` +
          `  ${alertText}가 입력된 주문건은 <span class="text-success">[운송장출력]</span>상태로 변경됩니다.`,
          function (confirm) {
            if (confirm) {
              const send_data = {
                uniqList: selected.filter(o => o.ord_status === '출고보류').map(o => o.uniq),
                status: '출고대기',
                shopType: $scope.selectedShopType
              };
              shipmentModel.orderUnhold(send_data, async function (state, data) {
                if (state === 'success') {
                  commonSVC.showToaster('success', '', '출고 보류 해제에 성공했습니다.');
                  await $scope.searchDo();
                } else {
                  commonSVC.showToaster('error', '', '출고 보류 해제에 실패했습니다.');
                }
              });
            }
          }
        );
      };

      /**
       *  툴팁 제거 합수
       */
      $scope.isOpen = false;
      $scope.closeTooltip = function() {
        $scope.isOpen = false;
      };

      /**
       * 주문 상태 강제변경
       * */
      $scope.userChangeStatus = function (status) {
        if (status === '출고대기') {
          $scope.orderWaiting();
        } else if (status === '출고보류') {
          $scope.orderHold();
        } else {
          //유니코드로 마지막 글자의 받침 여부 확인
          const isConsonant = (status.charCodeAt(status.length - 1) - 44032) % 28;

          commonSVC.showConfirm('상태를 변경하시겠습니까?', `${status}${isConsonant ? '으로' : '로'} 상태를 강제로 변경 하시겠습니까?`, async function (re) {
            if (re) {
              try {
                await shipmentModel.setStatus({ status: status, uniqList: $scope.grid.methods.selectedData('uniq') });
                commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 상태변경에 성공하였습니다'));
                $scope.searchDo(false);
              } catch (error) {
                commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('주문 상태변경에 실패하였습니다'));
              }
            }
          });
        }
      };

      /**
       * 출고대기
       * */
      $scope.orderWaiting = function() {
        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const dData = {
          bundle_codes: _.map(selected, 'bundle_no'),
          uniqList: _.map(selected, 'uniq'),
          status: '출고대기',
          is_global: $scope.selectedShopType === 'global'
        };

        const message = $scope.selectedShopType !== 'global'
          ? '출고대기로 변경 시 화면상의 배송정보(택배사명, 운송장번호)는 삭제됩니다.<br/>택배연동 기능 사용 시 7일 이내 다시 인쇄하면 최초 발번받은 운송장번호로 출력 되며, 수령자 정보 수정은 택배사 시스템에서도 수정해 주셔야 합니다.'
          : '<span style="font-size: 15px;">출고대기로 변경 시 화면상의 배송정보(해외택배사, 해외운송장번호)는 삭제됩니다.<br/>변경하시겠습니까?</span>';

        commonSVC.showConfirmHtml('출고대기로 상태변경', message, async function () {
          try {
            await shipmentModel.setStatus(dData);
            commonSVC.showToaster('success', '성공', '출고대기 상태변경에 성공하였습니다.');
            $scope.searchDo(false);
          } catch (error) {
            commonSVC.showToaster('error', '실패', '출고대기 상태변경에 실패하였습니다.');
          }
        });

      };

      /**
       * 출고완료
       * */
      $scope.orderFinish = function() {
        if ($scope.selectedShopType === 'global') {
          return orderFinishGlobal();
        }

        const selected = $scope.grid.methods.selectedData('all');

        const dData = {
          bundle_codes: selected.map(o => o.bundle_no),
          uniqList: selected.map(o => o.uniq),
          search: { ...$scope.pageData.domestic, status: ['운송장출력'], shopType: 'domestic' }
        };

        let txt = '';

        if (!selected.length) {
          if (outConfirmAvailCount) {
            dData.all = true;

            txt = `- 전체 운송장출력 상태 주문(${outConfirmAvailCount}건)을 출고 완료 상태로 변경 합니다.\n- 택배사 또는 운송장번호가 모두 입력된 주문건만 출고완료 처리가 가능합니다.`;
          } else {
            commonSVC.showMessage(gettextCatalog.getString('출고완료 처리할 주문건이 없습니다.'));

            return false;
          }
        } else {
          dData.search.multi_type = 'bundle_no';
          dData.search.multi_search_word = dData.bundle_codes.join('\n');

          if (selected[0].ord_status === '운송장출력' && (selected[0].carr_no === 107 || selected[0].carr_no === 1007)) {
            commonSVC.showMessage(
              '승인번호 [출고완료] 처리 불가',
              'CU편의점택배와 이마트24편의점택배의 승인번호 상태에는 [출고완료]가 불가합니다. \n [동기화]를 통하여 접수한 주문의 운송장번호를 발급받은 후 [출고완료]를 눌러주세요. \n (경로: [운송장출력] -> [동기화])'
            );

            return false;
          }
          const dataNone = _.filter(selected, function(d) {
            return d.ord_status != '운송장출력' || d.ship_avail_yn == 0 || !d.carr_no || !d.invoice_no;
          });

          if (dataNone.length) {
            const prod_info = $rootScope.user_profile.sol_stock > 0 ? '- 출고불가인 주문은 매칭된 SKU상품의 재고를 확인하시고 추가 입고 혹은 매칭 삭제 후 출고완료처리 하시기 바랍니다.' : '';

            const opt = {
              title: '주문의 상태가 운송장출력이거나, 택배사와 송장번호가 입력되어있는 출고가능한 주문건만 출고완료가 가능합니다.',
              text: `- 운송장출력 상태가 아닌 주문은 운송장번호를 업로드하여 운송장출력 상태로 변경 후 출고완료처리 하시기 바랍니다.\n- 택배사 또는 운송장번호가 비어있는 주문은 해당정보를 입력하신 후 출고완료처리 하시기 바랍니다.\n${prod_info}`,
              type: 'warning',
              confirmButtonText: '출고완료가 가능한 주문만 선택'
            };

            commonSVC.showConfirmCustom(opt, function () {
              // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
              // 2017-03-13 MatthewKim
              const reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
                const rowFilter = r.ord_status === '운송장출력' && r.carr_no !== 107 && r.carr_no !== 1007 && r.ship_avail_yn == 1 && r.carr_no !== null && r.carr_no !== '' && r.invoice_no !== null && r.invoice_no !== '';

                // dataNone 배열에 포함된 데이터는 false 리턴
                if (dataNone.some(item => item.bundle_no === r.bundle_no)) {
                  return false;
                }

                return rowFilter;
              }, true);

              if (!reselected_indexes.length)
              { commonSVC.showToaster('error', gettextCatalog.getString('자동 선택 불가'), gettextCatalog.getString('출고완료작업이 가능한 건이 없습니다.')); }
            });

            return false;
          } else {
            txt = `선택한 주문 ${selected.length}건의 주문을 출고 완료 상태로 변경합니다.`;
          }
        }

        commonSVC.showConfirm('출고 완료 처리', txt, function () {
          shipmentModel.orderFinish(dData, function(state, data) {
            if (state == 'success') {
              commonSVC.showToaster('success', '성공', '출고완료 상태변경에 성공하였습니다.');
              $scope.searchDo(false);
            } else if (data.results == 'dataNone') {
              commonSVC.showToaster('error', '실패', '출고완료작업이 가능한 건이 없습니다.');
            } else if (data.results === 'smsAuthListNone') {
              commonSVC.showToaster('error', '실패', '출고완료로 상태변경에 성공하였으나\n 사전등록된 발신번호가 없어 SMS 자동발송에 실패하였습니다.');
              $scope.searchDo(false);
            } else if (data.data?.message === 'unavailOrder') {
              commonSVC.showToaster('error', '실패', '출고완료가 불가능한 주문건이 포함되어 있습니다.');
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', '실패', '출고완료 상태변경에 실패하였습니다.');
            }
          });
        });

      };

      /**
       * 해외주문 출고완료
       */
      function orderFinishGlobal() {
        const selected = $scope.grid.methods.selectedData('all');

        const dData = {
          bundle_codes: selected.map(o => o.bundle_no),
          uniqList: selected.map(o => o.uniq),
          search: { ...$scope.pageData.global, status: ['운송장출력'], shopType: 'global' }
        };

        let txt = '';

        if (!selected.length) {
          if (outConfirmAvailCount) {
            dData.all = true;

            txt = `- 전체 운송장출력 상태 주문(${outConfirmAvailCount}건)을 출고 완료 상태로 변경 합니다.\n- 택배사 또는 운송장번호가 모두 입력된 주문건만 출고완료 처리가 가능합니다.`;
          } else {
            commonSVC.showMessage(gettextCatalog.getString('출고완료 처리할 주문건이 없습니다.'));

            return false;
          }
        } else {
          dData.search.multi_type = 'bundle_no';
          dData.search.multi_search_word = dData.bundle_codes.join('\n');

          let checkShopeeOrd = false;
          const dataNone = _.filter(selected, function(d) {
            // 쇼핑몰에 따른 출고완료 가능 조건처리
            if (['X099'].includes(d.pa_shop_cd)) { // 쇼핑몰이 샤피인 경우
              checkShopeeOrd = true;

              return d.ord_status != '운송장출력' || d.ship_avail_yn == 0 || !d.carr_no || !d.invoice_no || !d.global_carr_no || !d.global_invoice_no;
            } else { // 샤피를 재외한 해외 쇼핑몰인 경우
              return d.ord_status != '운송장출력' || d.ship_avail_yn == 0 || !d.global_carr_no || !d.global_invoice_no;
            }
          });

          if (dataNone.length === selected.length) {
            commonSVC.showMessage(gettextCatalog.getString('작업이 가능한 주문이 없습니다.'), `
            ${checkShopeeOrd ? '· Shopee 주문은 국내 배송정보와 해외 배송정보가 모두 입력되어야 합니다.' : ''}
            · 상태가 운송장출력인 주문만 출고완료 처리가 가능합니다.
            · 출고가능여부가 불가로 표시된 주문은 출고완료 처리가 불가합니다.
          `);

            return false;
          }

          if (dataNone.length) {
            const opt = {
              title: '작업이 불가능한 주문이 있습니다.',
              text: `
              ${checkShopeeOrd ? '· Shopee 주문은 국내 배송정보와 해외 배송정보가 모두 입력되어야 합니다.' : ''}
              · 상태가 운송장출력인 주문만 출고완료 처리가 가능합니다.
              · 출고가능여부가 불가로 표시된 주문은 출고완료 처리가 불가합니다.`,
              type: 'warning',
              confirmButtonText: '가능한 주문만 선택'
            };

            commonSVC.showConfirmCustom(opt, function () {
              // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
              const reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
                if (['X099'].includes(r.pa_shop_cd)) { // 샤피 주문은 국내/해외 택배사/송장 번호가 모두 입력되어 있어야함.
                  return r.ord_status === '운송장출력' && r.ship_avail_yn == 1 && r.carr_no && r.invoice_no && r.global_carr_no && r.global_invoice_no;
                }

                return r.ord_status === '운송장출력' && r.ship_avail_yn == 1 && r.global_carr_no && r.global_invoice_no;
              }, true);

              if (!reselected_indexes.length)
              { commonSVC.showToaster('error', gettextCatalog.getString('자동 선택 불가'), gettextCatalog.getString('출고완료작업이 가능한 건이 없습니다.')); }
            });

            return false;
          } else {
            txt = `선택한 주문 ${selected.length}건의 주문을 출고 완료 상태로 변경합니다.`;
          }
        }

        commonSVC.showConfirm('출고 완료 처리', txt, async function () {
          shipmentModel.orderFinish(dData, function(state, data) {
            if (state === 'success' && data.results) {
              let toastType = 'success';
              if (+data.success === 0) {
                toastType = 'error';
              }
              commonSVC.showToaster(toastType, '출고완료 결과', `${data.success}건 성공 ${data.fail}건 실패`);
              $scope.searchDo(false);
            } else if (data.data?.message === 'unavailOrder') {
              commonSVC.showToaster('error', '실패', '출고완료가 불가능한 주문건이 포함되어 있습니다.');
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', '실패', '출고완료 상태변경에 실패하였습니다.');
            }
          });
        });
      }

      /**
       * 운송장 출력
       * 재출력 추가 (printType === 'reprint')
       */

      $scope.printInvoice = async (printType, carrNo) => {
        if ($scope.selectedShopType === 'global') {
          $scope.printInvoiceGlobal(carrNo);
        }
        if (commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission) === false) {
          return false;
        }

        const selected = $scope.grid.methods.selectedData('all');

        if (!solCarrList.data.results.length) {
          commonSVC.showConfirm('택배사 연동정보가 없습니다.', '운송장 출력기능은 택배사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 택배사 관리 페이지로 이동합니다.', function () {
            $state.go('main.settings_delivery');
          });

          return;
        }

        const resolve = {
          data: {
            solCarrList: solCarrList.data.results
          }
        };

        if (selected.length === 0) {
          if (printType === 'reprint') {
            commonSVC.showMessage('실패', '주문을 선택해주세요.');

            return false;
          }

          resolve.totalCount = $scope.countList.waiting;

          if (invoicePrintAvailCount) {
            resolve.data.type = 'all';
            resolve.selectList = function () {
              const param = {
                ...angular.copy($scope.searchForm),
                shopType: $scope.selectedShopType
              };

              // 운송장 출력은 출고대기건만 처리되도록 수정 2018-06-08 rony
              // param.status = ['출고대기','출고보류'];
              param.status = ['출고대기'];
              param.ship_method = ['무료배송', '선결제', '착불', '기타', '조건부배송'];
              param.orderby = orderby.domestic;

              return shipmentModel.list(param);
            };
          } else {
            // 디버그 모드인경우에는 주문건이 없어도 택배사 모달창 열리도록 처리. 2019-04-18 rony
            if ($rootScope.adminMode) {
              resolve.selectList = function () {
                return { data: {} };
              };
            } else {
              commonSVC.showMessage(gettextCatalog.getString('운송장 출력 가능한 주문이 없습니다.'));

              return false;
            }
          }
        } else {
          let unAvailList;

          if (printType !== 'reprint') {
            unAvailList = _.filter(selected, (ord) => {
              return ord.ord_status !== '출고대기' || ['무료배송', '선결제', '착불', '조건부배송', '기타'].indexOf(ord.ship_method) === -1;
            });
          } else {
            // 재출력은 다음의 택배사인경우에만 지원.
            const checkCarr = selected.filter((o) => {
              return ![4, 5, 6, 7, 8, 956].includes(o.carr_no);
            });

            if (checkCarr.length) {
              commonSVC.showMessage('운송장 재출력은 다음의 택배사만 가능합니다.', 'CJ대한통운, 한진택배, 우체국택배, 롯데택배, 카카오T당일배송');

              return false;
            }

            unAvailList = selected.filter(ord => !['운송장출력', '출고완료', '배송중'].includes(ord.ord_status)
              || !['무료배송', '선결제', '착불', '조건부배송', '기타'].includes(ord.ship_method));
          }

          resolve.totalCount = selected.length;

          if (unAvailList.length && selected[0].carr_no !== 107 && selected[0].carr_no !== 1007) {
            const status = printType === 'reprint' ? '운송장출력, 출고완료, 배송중' : '출고대기';

            if (unAvailList.length === selected.length) {
              //선택한 갯수와 불가능 한 갯수가 같을 경우

              commonSVC.showMessage('운송장 출력이 불가능한 주문건이 포함되어 있습니다.', `[출력 가능 조건]\n주문상태: ${status} / 배송방법: 일반 택배발송\n(배송방법: 방문수령, 퀵배송, 일반우편, 설치배송 출력 불가)`);
            } else {
              if (printType === 'reprint') {
                commonSVC.showMessage('운송장 출력이 불가능한 주문건이 포함되어 있습니다.', `[출력 가능 조건]\n주문상태: ${status} / 배송방법: 일반 택배발송\n(배송방법: 방문수령, 퀵배송, 일반우편, 설치배송 출력 불가)`);

                return false;
              }

              const opt = {
                title: '운송장 출력이 불가능한 주문건이 포함되어 있습니다.',
                text: '주문상태가 출고대기이면서 배송방법이 일반 택배발송\n(방문수령, 퀵배송, 일반우편, 설치배송 이외의 주문)인 주문건만 출력 가능',
                type: 'warning',
                confirmButtonText: '운송장출력이 가능한 주문만 선택'
              };

              commonSVC.showConfirmCustom(opt, function () {
                const exceptRows = selected.filter(row => unAvailList.some(ord => ord.bundle_no === row.bundle_no));

                $scope.grid.methods.unSelectByFilter(function (row) {
                  return _.find(exceptRows, o => o.bundle_no === row.bundle_no);
                }, true);
              });
            }

            return false;
          }

          // 송장재출력 유효성 확인
          if (printType === 'reprint') {
            const carr_no = _.map(selected, 'carr_no');
            const invoice_no = _.map(selected, 'invoice_no');

            if (_.uniq(carr_no).length > 1) {
              commonSVC.showMessage('실패', '택배사가 다른 주문이 있습니다.');

              return false;
            } else if (!_.compact(carr_no).length || !_.compact(invoice_no)) {
              commonSVC.showMessage('실패', '택배사 정보가 없습니다.');
            }

            resolve.data.carr_no = _.compact(carr_no)[0];
            resolve.data.invoice_no = _.compact(invoice_no)[0];
          }

          resolve.data.type = 'select';
          resolve.selectList = function () {
            return { data: { results: selected, recordsTotal: selected.length } };
          };
          resolve.solCarrList = solCarrList;
        }

        $scope.printInvoiceWait = true;

        const modal = commonSVC.openModal('lg', resolve, 'printInvoiceCtrl', 'views/order/shipment/modals/print_invoice.html', false, true, false);

        modal.opened.then(() => ($scope.printInvoiceWait = false));
        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          } else if (typeof (re) === 'object') {
            $scope.searchForm.multi_type = 'bundle_no',  //멀티서치 타입
            $scope.searchForm.multi_search_word = re.map(({ bundle_no }) => bundle_no).join('\n'),       //멀티서치워드

            $scope.searchDo(false);
          }
        });
      };

      /**
       * 해외송장출력
       */
      $scope.printInvoiceGlobal = async (carrNo) => {
        // 운송장 출력 가능여부 확인
        const checkPrint = await checkPrintInvoiceGlobal(carrNo);
        if (!checkPrint) {
          return;
        }

        const { type, availList } = checkPrint;

        if (carrNo == 947) {
          // Qoo10 바코드 라벨 출력
          printQoo10BarcodeLabel(availList, type, 'print', carrNo);
        } else {
          // 나머지
          printInvoiceGlobal(availList, type, 'print', carrNo);
        }
      };

      /**
       * 해외송장 출력 가능여부
       */
      async function checkPrintInvoiceGlobal(carrNo) {
        const carrNm = $scope.globalSerCarrList.find(carr => carr.carr_no == carrNo).carr_name;
        const deliveryUseList = solCarrList.data.results;

        // 출력가능조건
        const availPrintShop = row => {
          const {
            ord_status,
            global_invoice_print_time,
            global_invoice_time,
            pa_shop_cd,
            global_carr_no,
            global_pa_carr_no,
            global_invoice_no,
            shop_cd,
            ori_uniq
          } = row;

          const parent_carr = global_pa_carr_no || global_carr_no;

          // 상태가 '출고대기' 이고, 출력여부가 '출력대기' 이어야함.
          if (!((ord_status == '출고대기') && !global_invoice_print_time)) {
            return false;
          }

          // 해외송장번호 직접입력의 경우 출력 불가 조건 추가
          if (global_invoice_no && !global_invoice_time) {
            return false;
          }

          // 트래킹넘버가 비어있거나, 입력된 캐리어가 출력하려는 캐리어와 같아야함
          if (!(!global_invoice_no || (parent_carr && global_invoice_no && parent_carr == carrNo))) {
            return false;
          }

          // 아래처리 = 출력가능한 쇼핑몰이어야함 + 자체물류의 경우 사본주문건은 출력안됨
          // shop_cd 설정부터 확인. shop_cd 설정있을경우 pa_shop_cd 는 검사하면 안됨
          if (carrInfo.shop_cd) {

            return carrInfo.shop_cd.includes(shop_cd) && _.isNull(ori_uniq) && availShops.includes(shop_cd); // later use this one instead of line below!
          }
          if (carrInfo.pa_shop_cd) {

            return carrInfo.pa_shop_cd.includes(pa_shop_cd) && _.isNull(ori_uniq) && availShops.includes(shop_cd); // later use this one instead of line below!
          }

          // 자체물류 배송사인 경우에는 자체물류에 해당하는 pa_shop_cd의 주문인지 확인
          if (deliverySVC.global.deliveryShopOnly.includes(pa_shop_cd) && (deliverySVC.global.logisticList.find(item => item.pa_shop_cd.includes(pa_shop_cd)).carr_no != carrNo)) {
            return false;
          }

          return true;
        };

        const carrInfo = _.find($scope.globalSerCarrList, { carr_no: carrNo });
        const deliChk = !(deliveryUseList.length && deliveryUseList.find(o => o.carr_no == carrNo)) && !carrInfo.pa_shop_cd;
        if (deliChk) {
          commonSVC.showConfirm(
            '배송사 연동정보가 없습니다.',
            '운송장 출력기능은 배송사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 배송사 연동 관리 페이지로 이동합니다.',
            function () {
              $state.go('main.settings_delivery');
            }
          );

          return false;
        }

        let selectedRows = $scope.grid.methods.selectedData('all');
        const type = selectedRows.length ? 'select' : 'all';
        if (selectedRows.length === 0) {
          const param = angular.copy($scope.searchForm);
          param.status = ['출고대기'];
          param.invoice_print_time_yn = '0';
          param.orderby = orderby.global;
          await shipmentModel.list(param).then(data => {
            selectedRows = data.data.results;
          });
        }

        // 상단 상세검색에서 channel.ord_shop_cd 값이 손상되어 pa_shop_cd_use_yn 로 재확인
        const availShops = channelList.map(o => (o.pa_shop_cd_use_yn ? o.pa_shop_cd : o.shop_cd));

        // 출력가능한 리스트 추출
        let availList = [];
        selectedRows.forEach(row => {
          if (availPrintShop(row)) {
            availList.push(row);
          }
        });

        if (!availList.length) {
          commonSVC.showMessage(
            gettextCatalog.getString('출력이 가능한 주문건이 없습니다.'), `
            · 쇼핑몰 별 이용 가능한 택배사를 확인해 주세요.
            · 주문상태가 (출고대기)인 주문만 운송장 출력이 가능합니다.
            · 송장 출력여부가 [출력대기]인 주문만 운송장 출력이 가능합니다.
            · 해외운송장번호를 직접 입력한 주문은 운송장 출력이 불가합니다.
            · 복사한 주문은 운송장 출력이 불가합니다.`
          );

          return false;
        }
        // 선택출력이면서 출력불가능 주문건이 있을경우
        if (type == 'select' && selectedRows.length != availList.length) {
          const opt = {
            title: '출력이 불가능한 주문건이 포함되어 있습니다.',
            text: `
            · 쇼핑몰 별 이용 가능한 택배사를 확인해 주세요.
            · 주문상태가 (출고대기)인 주문만 운송장 출력이 가능합니다.
            · 송장 출력여부가 [출력대기]인 주문만 운송장 출력이 가능합니다.
            · 해외운송장번호를 직접 입력한 주문은 운송장 출력이 불가합니다.
            · 복사한 주문은 운송장 출력이 불가합니다.`,
            confirmButtonText: '가능한 주문만 선택'
          };
          const exceptRows = _.difference(selectedRows, availList);
          commonSVC.showConfirmCustom(opt, function () {
            $scope.grid.methods.unSelectByFilter(function (row) {
              return _.find(exceptRows, o => o.bundle_no === row.bundle_no);
            }, true);
          });

          return false;
        } else if (type == 'all') {
          const exceptRows = _.difference(selectedRows, availList);
          exceptRows.forEach(data => {
            availList = availList.filter(v => v.bundle_no !== data.bundle_no);
          });

          if (!availList.length) {
            commonSVC.showMessage(
              gettextCatalog.getString(`${carrNm}운송장 출력이 가능한 주문이 없습니다.`)
            );

            return false;
          }
        }

        return { type, availList };
      }

      /**
       * 나머지 해외송장 출력 함수
       */
      function printInvoiceGlobal(order_list, type, mode, carr_no) {
        const resolve = {
          data: {
            order_list,
            type,
            mode,
            carr_no
          },
        };

        resolve.globalCarrList = function () {
          return globalCarrList;
        };

        const modal = commonSVC.openModal(
          'xxg',
          resolve,
          'printInvoiceGlobalCtrl',
          'views/order/shipment/modals/printInvoiceGlobal.html'
        );

        modal.result.then(async function (re) {
          if (re === 'success') {
            await $scope.searchDo();
          }
        });
      }

      /**
       * Qoo10 바코드 라벨 출력 함수
       */
      function printQoo10BarcodeLabel() {
        return;
      }

      $scope.printDomesticInvoice = () => {
        if (commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission) === false) {
          return false;
        }
        const resolve = {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 글로벌 택배사 리스트
          globalCarrList: function(deliveryModel) {
            return deliveryModel.bookMarkdeliveryInfoList().then(data => {
              return data.data.carr_list;
            });
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          data: {
            sdate: $scope.searchForm.sdate,
            edate: $scope.searchForm.edate
          },
          solCarrList: solCarrList
        };

        const modal = commonSVC.openModal('full', resolve, 'orderPackageCtrl', 'views/order/shipment/modals/order_package.html', false, false, false);
        modal.result.then(function (re) {

          if (re === 'success') {
            $scope.searchDo(false);
          }
        });

      };

      /**
       * 해외 운송장 재출력 함수
       */
      $scope.globalReprintInvoice = () => {
        const deliveryUseList = solCarrList.data.results;
        const selected = $scope.grid.methods.selectedData('all');
        if (selected.length === 0) {
          return commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));
        }

        const availShops = channelList.map(o => (o.pa_shop_cd_use_yn ? o.pa_shop_cd : o.shop_cd));
        const availList = [];
        selected.forEach(row => {
          const { ord_status, shop_cd, global_invoice_time } = row;

          if (
            (ord_status === '운송장출력' || ord_status === '출고완료' || ord_status === '배송중') &&
            global_invoice_time &&
            availShops.includes(shop_cd)
          ) {
            availList.push(row);
          }
        });

        const msg = `
        <ul style="list-style:none; padding:0; font-size: 15px;">
          <li style="position:relative;padding: 15px 0 0 15px;"><span style="position:absolute; left:0;">-</span>
          주문상태가 '운송장출력','출고완료','배송중' 중 출력여부가 '출력완료'인 주문건만 재출력 가능합니다.</li>
          <li style="position:relative;padding: 15px 0 0 15px;"><span style="position:absolute; left:0;">-</span>
          <span class="text-warning">묶음주문 내 클레임 주문이 있을 경우 운송장 재출력이 불가합니다.</span></li>
        </ul>
        `;

        if (!availList.length) {
          return commonSVC.showMessageHtml('운송장 재출력이 가능한 건이 없습니다.', msg);
        }

        // 직배송 캐리어
        const dropoffCarr = deliverySVC.carrList.filter(o => !o.pa_shop_cd).map(o => o.carr_no);
        if (!deliveryUseList.length && availList.find(o => dropoffCarr.includes(o.global_pa_carr_no || o.global_carr_no))) {
          return commonSVC.showConfirm(
            '배송사 연동정보가 없습니다.',
            '운송장 출력기능은 배송사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 배송사 연동 관리 페이지로 이동합니다.',
            function () {
              $state.go('main.settings_delivery');
            }
          );
        }

        // 불가능 주문건이 있을경우
        if (selected.length != availList.length) {
          const opt = {
            title: '운송장 재출력이 불가능한 주문건이 포함되어 있습니다.',
            html: msg,
            icon: 'warning',
            confirmButtonText: '운송장재출력이 가능한 주문만 선택',
          };

          const exceptRows = _.difference(selected, availList);

          commonSVC.showConfirmCustom(opt, function () {
            $scope.grid.methods.unSelectByFilter(function (row) {
              return _.find(exceptRows, o => o.bundle_no === row.bundle_no);
            }, true);

            if ($scope.grid.methods.selectedData('all').length == 0) {
              return commonSVC.showToaster(
                'error',
                gettextCatalog.getString('자동 선택 불가'),
                gettextCatalog.getString('운송장재출력이 가능한 건이 없습니다.')
              );
            }
          });
        }
        const resolve = {
          data: {
            order_list: selected,
            type: 'select',
            mode: 'reprint'
          }
        };
        resolve.globalCarrList = function () {
          return globalCarrList;
        };
        const modal = commonSVC.openModal('xxg', resolve, 'printInvoiceGlobalCtrl', 'views/order/shipment/modals/printInvoiceGlobal.html');
        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 바코드출고매니저
       * 2019-05-16 rony
       */
      $scope.barcodeManager = function () {
        const resolve = {};

        // 창고 리스트 조회
        resolve.warehouseList = function (warehouseModel) {
          return warehouseModel.ListAll({ use_yn: true });
        };
        //환경설정 리스트
        resolve.systemList = function (systemModel) {
          return systemModel.load();
        };

        resolve.searchForm = $scope.searchForm;

        const modal = commonSVC.openModal('full', resolve, 'barcodeManagerCtrl', 'views/order/shipment/modals/barcode_manager.html');

        modal.result.then(function () {
          $scope.searchDo(true);
        });
      };

      /**
       * 통합 엑셀 다운로드
       */

      $scope.downloadIntegratedExcel = function () {
        if (commonSVC.checkPermission('shipping.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }
        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const selectedData = $scope.grid.methods.selectedData('all');
        const resolve = {};

        resolve.data = {
          excelFieldList: $scope.excelFieldList,
          title: '출고 관리 주문',
          url: '/app/order/excel/downIntegrated',
          searchForm: $scope.pageData[$scope.selectedShopType],
          page: 'unstoring',
          visibleTable: visibleTable,
          isGlobal: $scope.selectedShopType === 'global'
        };

        if (!selectedData.length) {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
          resolve.data.type = 'all';
        } else {
          const uniq = $scope.grid.methods.selectedData('uniq');

          resolve.data.type = 'select';
          resolve.data.uniq = uniq;
          resolve.data.count = uniq.length;
          resolve.data.isAll = false;
        }

        commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
      };

      /**
       * 주문서 인쇄
       */
      $scope.orderPrint = async function () {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return;
        }

        const selectBundle = _.uniqBy(selectList.map(o => o.bundle_no));
        const isBundle = !!selectBundle.find(bundle_no => $scope.bundle_group[bundle_no].length > 1);

        const data = {
          isBundle: isBundle,
          ordCount: selectBundle.length,
          orderby: orderby[$scope.selectedShopType],
          uniqList: $scope.grid.methods.selectedData('uniq')
        };

        commonSVC.openModal('', { data: data }, 'OrderBundlePrintCtrl', 'views/order/shipment/modals/order_bundle_print.html');
      };

      /**
       * 주문복사
       * */
      $scope.copyOrder = function() {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          selectList: selectList,
          selectedShopType: $scope.selectedShopType
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderCopy', 'views/order/shipment/modals/order_copy.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
            commonSVC.showMessage(gettextCatalog.getString("복사된 주문건은 '신규주문' 메뉴에서 확인 가능합니다"));
          }
        });
      };

      /**
       * 주문삭제
       * */
      $scope.deleteOrder = function(uniq) {

        // 삭제권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.deleteOrder', userInfo.permission) === false) {
          return false;
        }

        let param = [];

        if (uniq) {
          param = [uniq];
        } else {
          param = $scope.grid.methods.selectedData('uniq');
        }

        if (param.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          uniqList: param,
          fromPage: 'unstoring'
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderDelete', 'views/order/shipment/modals/order_delete.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 주문 동기화
       */
      $scope.orderSync = function() {
        commonSVC.openModal('md', { data: { work_type: 'sync2' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
      };

      /**
       * 규칙 사은품 분배
       */
      $scope.matchingGift = async (type) => {
        let bundleNoList = [];

        if (type == 'select') {
          let data = $scope.grid.methods.selectedData('all');

          if ($rootScope.osse_sol) {
            data = data.filter(d => !d.ori_uniq || d.ori_uniq && !Object.prototype.hasOwnProperty.call(d.misc_etc[0], 'is_origin_order'));
          }

          if (data.length === 0) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'), '');

            return;
          }

          bundleNoList = _.uniqBy(data.map(o => o.bundle_no));
        }

        const confirm = await commonSVC.showConfirm('규칙 사은품 분배', '사은품 분배가 안된 주문 건이 있거나 추가된 사은품 규칙에 대한 사은품 분배가 진행됩니다.');
        if (confirm) {
          try {
            const res = await giftModel.matching({ bundleNoList });

            if (res.data.result === 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙 사은품 분배에 성공하였습니다'));
              $scope.searchDo(false);
            } else if (res.data.result === 'not exist') {
              commonSVC.showMessage(gettextCatalog.getString('적용 가능한 신규 버전 사은품 규칙이 없습니다.'), '');

              return;
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙 사은품 분배에 실패하였습니다'));
            }
          } catch (error) {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙 사은품 분배에 실패하였습니다'));
          }
        }
      };

      /**
       * 규칙 사은품 삭제
       */
      $scope.matchingDeleteGift = function() {
        const data = $scope.grid.methods.selectedData('all');
        const bundle_code = [];

        // var bundle_code = $scope.grid.methods.selectedData('bundle_no');
        if (data.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'), '');

          return;
        }
        //규칙사은품 확인
        let gift_flag = false;

        for (let i = 0; i < data.length; i++) {
          bundle_code.push(data[i].bundle_no);            //bundle_no list
          if (data[i].gift_pack) { gift_flag = true; }
        }
        // //선택된 항목중에 규칙사은품이 없을 경우
        if (!gift_flag) {
          commonSVC.showMessage(gettextCatalog.getString('적용된 규칙사은품이 없습니다.'), '');

          return;
        }
        // confirm 창 추가 2017-09-04 서상권
        commonSVC.showConfirm('', '설정한 사은품규칙에 의해\n 해당 주문에 적용된 규칙 사은품 내역을 삭제하시겠습니까?', function () {
          shipmentModel.matchingDeleteGift({ bundleNoList: _.uniqBy(bundle_code) }, function (state, data) {
            if (state === 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙 사은품 삭제에 성공하였습니다'));
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙 사은품 삭제에 실패하였습니다'));
            }
          });
        });
      };

      /**
       * 합포장
       * **/
      $scope.modifyBundle = function () {
        const selectList = $scope.grid.methods.selectedData('all');
        const selectBundle = _.uniqBy(_.map(selectList, 'bundle_no'));
        const selectPackage = _.uniqBy(_.map(selectList, 'pa_bundle_no'));
        const checkShopeeOrd = selectList.some(list => list.pa_shop_cd === 'X099');
        const hasEditingOrd = selectList.some(ord => ord.isEditing);

        if (selectPackage.length > 1) {
          commonSVC.showMessage('패키지가 다른 주문은 합포장 할 수 없습니다.');

          return false;
        }

        if (checkShopeeOrd) {
          if (_.uniqBy(_.map(selectList, 'shop_ord_no')).length > 1) {
            commonSVC.showMessage('합포장을 할 수 없습니다.\nShopee는 주문번호1(Order Number)이 같은 주문만 합포장이 가능합니다.');

            return false;
          }
        }

        if (selectBundle.length < 2) {
          commonSVC.showMessage(gettextCatalog.getString('두개 이상의 주문을 선택해주세요.'));

          return false;
        }

        if (_.filter(selectList, { ord_status: '출고대기' }).length !== selectList.length) {
          commonSVC.showMessage(gettextCatalog.getString('출고 대기 상태인 주문건들만 합포장이 가능 합니다.'));

          return false;
        }

        const selectDonotBundle = _.filter(selectList, { bundle_avail_yn: 0 });

        if (selectDonotBundle.length > 0) {
          commonSVC.showMessage(gettextCatalog.getString('합포장이 불가능한 SKU상품이 매칭된 주문이 존재합니다.'));

          return false;
        }

        // if(_.uniq(_.map(selectList, 'depot_name')).length > 1){
        //   commonSVC.showMessage(gettextCatalog.getString("합포장은 배송처가 동일한 주문건만 가능 합니다."));
        //   return false;
        // }

        const params = {
          uniqList: _.map(selectList, 'uniq'),
          selectList: selectList
        };

        const grouping = _.groupBy(selectList, function(o) { return o.to_name + o.to_zipcd + o.to_addr1 + o.to_htel; });

        const msg = $scope.selectedShopType === 'domestic' ? '주문에 적용된 사은품 규칙, 사용자태그가 삭제 후 재적용됩니다.' : `${checkShopeeOrd ? '- Shopee는 주문번호1(Order Number)이 같은 주문이 모두 합포장 됩니다.\n' : ''}- 주문의 묶음번호가 변경됩니다.`;

        commonSVC.showConfirm('주문 합포장을 하시겠습니까?', msg, async function (re) {
          if (re) {
            if (_.size(grouping) === 1) {
              if (checkShopeeOrd) {
                commonSVC.showToaster('success', '성공', '작업이 등록되었습니다.');
              }
              // 합포장 시 수령지 정보 같은 경우 수령지 정보 선택은 미출력되어야함.
              params.standardData = selectList[0].uniq;

              shipmentModel.makeBundle(params, function (state, result) {
                if (state == 'success') {
                  commonSVC.showToaster('success', gettextCatalog.getString('배송 관리'), gettextCatalog.getString('주문 합포장 성공'));
                  if (hasEditingOrd) {
                    for (const ord of selectList) {
                      unstoringTableEditList[ord.bundle_no][ord.uniq].isChangedData = true;
                    }
                  }
                  $scope.searchDo(false);
                } else {
                  commonSVC.showToaster('error', '', result);
                }
              });
            } else {
              const modal = commonSVC.openModal('', { data: { ...params, selectList: selectList } }, 'makeBundleOrderCtrl', 'views/order/shipment/modals/make_bundle_order.html');

              modal.result.then(function (re) {
                if (re === 'success') {
                  $scope.searchDo(false);
                }
              });
            }
          }
        });
      };

      /**
       * 주문분리
       * */
      $scope.divideBundle = function(bundle_no, uniq) {
        const selectList = $scope.grid.methods.selectedData('all');
        const hasEditingOrd = selectList.some(ord => ord.isEditing);
        const divideList = [];
        let checkShopeeOrd = false;
        let message = '';
        let title = '';
        let checkNotBundle = false;

        if (!bundle_no) { //주문 분할 버튼 눌렀을 때
          checkShopeeOrd = selectList.some(ord => ord.pa_shop_cd === 'X099');

          if (selectList.length < 1) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

            return false;
          }

          if ($scope.selectedShopType === 'global') {
            if (selectList.some(ord => ord.misc2) && checkShopeeOrd) {
              commonSVC.showMessage('주문을 합포장 후 다시 분할해 주세요.', '- Shopee의 분할한 주문은 재 분할이 불가능합니다.\n- 다시 분할하려면 합포장 후 분할해 주세요.');

              return false;
            }

            selectList.forEach(ord => {

              // 샤피 번들딜, 애드온딜 체크
              // ord.misc9 !== ord.api_id.spilt('|')[1] : bundle-deal
              // ord.misc20 > 0 : add-on-deal
              if (ord.pa_shop_cd === 'X099' && (ord.misc9 !== ord.api_id.split('|')[1] || +ord.misc20)) {

                checkNotBundle = true;
              }
            });

            if (checkNotBundle) {
              commonSVC.showMessage('분할 할 수 없는 주문이 있습니다.', '- Shopee의 Bundle Deal,Add-on Deal 주문은 분할이 불가합니다.');

              return false;
            }

            title = `선택한 주문 <span class="text-primary">${selectList.length}</span>건을 분할 하시겠습니까?`;
            message = '- 선택된 주문을 묶음주문에서 개별주문으로 일괄 분할합니다.<br/>- 선택한 모든 주문의 묶음번호가 변경됩니다.<br>- 선택한 모든 주문의 해외택배사명, 해외운송장번호가 초기화 됩니다.';
          } else {
            title = '주문 분할을 하시겠습니까?';
            message = `선택한 ${selectList.length}건의 주문을 개별 배송 주문으로 분리합니다. 
            <br> - 주문에 적용된 사은품 규칙이 삭제됩니다. 묶음 분리/합포장 처리 후 사은품 규칙 모달에서 재적용하시기 바랍니다.
            <br> - 주문 분할 시 묶음번호가 변경되니 주의바랍니다.
            <br> - 주문 분할 시 주문에 적용된 사용자태그가 재적용됩니다.`;
          }

          if (_.filter(selectList, function (o) { return divideStatus.indexOf(o.ord_status) > -1; }).length !== selectList.length) {
            commonSVC.showMessage(gettextCatalog.getString('출고대기, 운송장출력, 출고보류, 취소요청, 취소완료, 반품요청, 반품완료 상태인 주문건들만 주문분할이 가능 합니다.'));

            return false;
          }

          _.each(selectList, function (order) {
            if ($scope.bundle_group[order.bundle_no].length > 1) {
              divideList.push([{ bundle_no: order.bundle_no, uniq: order.uniq }]);
            }
          });
        } else { //(개별)분할 아이콘 눌를때
          if ($scope.bundle_group[bundle_no].length === 2) { // 합이 2개면 둘다 분할 처리해야하니까
            _.each($scope.bundle_group[bundle_no], function(v) {
              divideList.push([{ bundle_no: bundle_no, uniq: v.uniq }]);
            });

            message = `선택한 묶음주문을 모두 개별 배송처리 합니다. 
              <br> - 합포장된 2개의 주문이 분할되며 두 건 모두 묶음번호가 변경됩니다.
              <br> - 묶음번호가 변경되면 배송사명, 운송장번호가 초기화 됩니다.
              <br> - 주문에 적용된 사은품 규칙이 삭제 됩니다. 묶음 분리/합포장 처리 후 사은품 규칙 메뉴에서 재적용하시기 바랍니다.`;
          } else {
            divideList.push([{ bundle_no: bundle_no, uniq: uniq }]);

            message = `선택된 주문을 묶음주문에서 분할합니다.
              <br> - 선택하신 주문은 묶음주문에서 분할되며 해당 주문은 묶음번호가 변경됩니다.
              <br> - 묶음번호가 변경된 주문은 배송사명, 운송장번호가 초기화 됩니다.
              <br> - 주문에 적용된 사은품 규칙이 삭제 됩니다. 묶음 분리/합포장 처리 후 사은품 규칙 메뉴에서 재적용하시기 바랍니다.`;
          }

          if ($scope.selectedShopType === 'global') {
            const checkDivOrd = $scope.bundle_group[bundle_no].find(ord => ord.uniq === uniq);

            // 분할된 샤피 주문일 경우
            if (checkDivOrd.pa_shop_cd === 'X099') {
              checkShopeeOrd = true;

              // 샤피 분할된 주문 건일 경우 엔진 작업에서 misc2에 값을 저장함
              if (checkDivOrd.misc2) {
                commonSVC.showMessage('주문을 합포장 후 다시 분할해 주세요.', '- Shopee의 분할한 주문은 재 분할이 불가능합니다.\n- 다시 분할하려면 합포장 후 분할해 주세요.');

                return false;
              }
              // 샤피 번들딜, 애드온딜 체크
              // ord.misc5 !== ord.api_id.spilt('|')[1] : bundle-deal
              // ord.misc20 > 0 : add-on-deal
              if (checkDivOrd.misc9 !== checkDivOrd.api_id.split('|')[1] || +checkDivOrd.misc20) {
                checkNotBundle = true;
              }
            }

            if (checkNotBundle) {
              commonSVC.showMessage('분할 할 수 없는 주문이 있습니다.', '- Shopee의 Bundle Deal,Add-on Deal 주문은 분할이 불가합니다.');

              return false;
            }

            title = '주문을 분할 하시겠습니까?';
            message = '- 선택된 주문을 묶음주문에서 분할합니다.<br>- 선택하신 주문은 묶음주문에서 분할되며 해당 주문은 묶음번호가 변경됩니다.<br>- 묶음번호가 변경된 주문은 해외택배사명, 해외운송장번호가 초기화됩니다.';
          } else {
            title = '주문 분할을 하시겠습니까?';
          }

        }

        if (!divideList.length) {
          commonSVC.showMessage('주문 분할', '묶음 해제 할 묶음 주문건이 없습니다');

          return false;
        }

        if (isCalStatePerProd) {
          message += '<br/> - ESM+(옥션, 지마켓) 주문의 "추가구매옵션"이 원주문과 분리되어 개별 발송되는 경우, 추가구매옵션의 운송장번호는 쇼핑몰로 전송되지 않습니다.';
        }

        commonSVC.showConfirmHtml(title, message, function (re) {
          if (re) {
            if (checkShopeeOrd) {
              commonSVC.showToaster('success', '성공', '작업이 등록되었습니다.');
            }

            const send_data = {
              divideList: divideList
            };

            shipmentModel.divideBundle(send_data, function (state, data) {
              if (state === 'success') {
                commonSVC.showToaster('success', '', '합포장 분할해제에 성공하였습니다.');
                $timeout(function() {
                  if (hasEditingOrd) {
                    for (const ord of selectList) {
                      unstoringTableEditList[ord.bundle_no][ord.uniq].isChangedData = true;
                    }
                  }
                  $scope.searchDo(false);
                }, 500);
              } else {
                commonSVC.showToaster('error', '', `합포장 분할해제에 실패 했습니다.\n${data.data?.message || ''}`);
              }
            });
          }
        });
      };

      /**
       * 주문 선택 분할
       */
      $scope.divideBundleSelect = () => {
        const selectList = $scope.grid.methods.selectedData('all');
        const hasEditingOrd = selectList.some(ord => ord.isEditing);

        if (selectList.length < 1) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        if (_.uniqBy(selectList, 'bundle_no').length === selectList.length) {
          commonSVC.showMessage(gettextCatalog.getString('주문 선택분할'), '묶음 분할 할 묶음 주문 건이 없습니다.');

          return false;
        }

        if (_.uniqBy(selectList, 'bundle_no').length > 1) {
          commonSVC.showMessage(gettextCatalog.getString('주문 선택분할'), '묶음 주문 1건만 선택해 주세요.');

          return false;
        }
        const resolve = {
          data: {
            ordList: selectList
          }
        };

        const modal = commonSVC.openModal('lg', resolve, 'DivideBundleCtrl', 'views/order/shipment/modals/divide_bundle_select.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            if (hasEditingOrd) {
              for (const ord of selectList) {
                unstoringTableEditList[ord.bundle_no][ord.uniq].isChangedData = true;
              }
            }
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 엑셀 다운 모달창
       * */
      $scope.excelDown = (type, isDouzone) => {
        let ctrl = 'invoiceExcelDownCtrl',
            template = 'views/order/shipment/modals/invoice_excel_download.html';

        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('shipping.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const resolve = {};

        resolve.data = {
          url: '/app/order/excelDown',
          isAll: false,
          page: 'unstoring',
          searchForm: { ...$scope.pageData[$scope.selectedShopType], shopType: $scope.selectedShopType }
        };

        // 더존양식 여부
        if (isDouzone) {
          ctrl = 'EtcExcelDownCtrl';
          template = 'views/etc/excel_down.html';
          resolve.data.type = type;
          resolve.data.isDouzone = true;
        }

        const uniqList = $scope.grid.methods.selectedData('uniq');

        if ((!isDouzone && !uniqList.length) || (isDouzone && type === 'all')) {
          resolve.data.isAll = true;
          resolve.data.count = isDouzone ? $scope.totalCount : $scope.searchData.totalCount;
        } else {
          resolve.data.uniq = uniqList;
          resolve.data.count = _.uniq($scope.grid.methods.selectedData('bundle_no')).length;
        }

        if (resolve.data.count === 0) {
          const msg = isDouzone && type === 'select' ? '선택된 주문이 없습니다.' : '다운로드 가능한 주문이 없습니다.';

          commonSVC.showMessage(msg);

          return false;
        }

        const modal = commonSVC.openModal('', resolve, ctrl, template);

        modal.result.then(function (re) {
          if (re === 'success') {
            // 엑셀 다운로드 후 새로고침 안함 (https://playautogmpproject.atlassian.net/browse/GMPKR-5462)
            // 2018-10-24 rony
            // $scope.searchDo(false);
          }
        });
      };

      /**
       * 엑셀 업로드 모달창
       */
      $scope.excelUpload = function (mode) {
        const modal = commonSVC.openModal('', { data: { mode } }, 'OrderShipmentExcelUploadCtrl', 'views/order/shipment/modals/excel_upload.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });

      };

      /**
       * 엑셀 업로드 모달창
       * */
      $scope.uploadInvoiceExcel = function () {

        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('shipping.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const resolve = {
          data: {
            type: 'unstoring',
            shopType: $scope.selectedShopType,
            globalCarrList
          }
        };

        const modal = commonSVC.openModal('lg', resolve, 'invoiceExcelUploadCtrl', 'views/order/shipment/modals/invoice_excel_upload.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 상품코드 클릭시 쇼핑몰 상품 관리로 이동
       */
      $scope.goOlProdList = (shop_sale_no) => {
        $rootScope.order_search.page = 'online';
        $rootScope.order_search = {
          page: 'online',
          search: true,
          search_key: 'shop_sale_no',
          search_word: shop_sale_no
        };

        $state.go('main.online_product_list');
        $rootScope.$broadcast('ordListSearch');
      };

      /**
       * 국내, 해외 선택
       */
      $scope.selectShopType = (shopType) => {
        if (shopType === 'domestic') {
          setSearch(domesticSearch);
        } else {
          setSearch(globalSearch);
        }
        $scope.selectedShopType = shopType;
        $scope.grid = $scope[$scope.selectedShopType];
        $scope.countSearch('total');

        // 일괄입력 값 초기화
        $scope.setAllData = {
          type: '',
          ship_method: '',
          ship_cost: 0,
          carr_no: '',
          carr_name: ''
        };

        if (shopType === 'domestic' && $rootScope.unstoringListEditStatus) {
          $rootScope.unstoringListEditStatus = false;
          unstoringTableEditList = {};
        }
      };

      /**
       * 테이블에서 선택 변경 시
       */
      // $scope.$on('OnSelectChange', function(event, data) {
      //   if($scope.isDetailShow){
      //     $state.go("main.order_shipment_unstoring_list.detail", {fromPage: 'unstoring', rowIndex: data.selectIndex ,  uniq: data.selectData.uniq, number: data.selectData.number});
      //   }
      // });

      $scope.$on('OnSelectChangeBefore', function(event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-04-27 MatthewKim todo. 이거는 좀더 빠르게 바꿀 필요가 있음
        // 2017-05-10 MatthewKim $timeout 이용하지 않고 다이렉트로 처리함 (속도 개선)
        // 2017-05-10 MatthewKim 선택 발생시 타겟 전체를 클래스로 받아 빠르게 처리함
        $(data.targetClasses).addClass('selected');
      });

      /**
       * 테이블에서 선택해제시
       */
      $scope.$on('OnDeSelected', function(event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-05-10 MatthewKim $timeout 제거 하고 처리 위치 제일 먼저 위로 올림
        // 2017-05-10 MatthewKim 선택 해제 발생시 타겟 전체를 클래스로 받아 처리함
        $(data.targetClasses).removeClass('selected');
      });

      /**
       * 상세보기 닫기 눌렀을 시에
       */
      // $scope.$on('OnCloseDetail', function(event, data) {
      //   $scope.showDetail(null, false);
      //   // 2017-03-07 MatthewKim $state.go 옵션을 추가해서 파라미터 상속 false, 알림 false 로 이동하여 화면 이동을 시키게 함
      //   $state.go("main.order_shipment_unstoring_list", null, { inherit: false, notify: false } );
      // });

      /**
       * 주문 전체보기에서 주문코드 눌렀을 때
       */
      $scope.$on('integratedSearch_unstoring', function() {
        $scope.searchDo(true, true);
      });

      /**
       * 사이드바 집계 클릭시
       */
      $scope.$on('sidebarSearch_unstoring', function() {
        let tabIdx = 1;
        $timeout(() => {
          if ($rootScope.side_search.selectedShopType === 'domestic') {
            tabIdx = 1;
          } else {
            tabIdx = 2;
          }

          $(`a[href='#content-tabs-${tabIdx}']`).trigger('click');
          $scope.searchDo(true, true);
        });
      });

      /**
       * 주문 요약정보 열기
       */
      // $scope.$on('openOrderInfoDetail', function(event, vdata) {
      // 	// 주문 요약정보 매칭 정보
      //   _.forEach($scope.orderList, function (order) {
      //     if (prodList[order.uniq]) {
      //       order.sku_pack = prodList[order.uniq][0].sku_cd;
      //       order.prod_name_pack = prodList[order.uniq][0].prod_name;
      //     }
      //   });

      //   vdata.row.child($compile('<detail-preview number=' + vdata.row[0][0] + ' type="order"></detail-preview>')($scope)).show();
      // });

      /**
       *  테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_unstoring_list') {
          const $wrapper = $('#unstoring_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.length > 0 && $tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      // SMS, EMAIL 모달
      $scope.sendModal = async (type) => {
        let controller = '';

        switch (type) {
          case 'sms':
            controller = 'ConfigsSms';
            break;

          case 'email':
            controller = 'ConfigsEmail';
            break;
        }

        const rows = $scope.grid.methods.selectedData('all');
        const t = angular.copy($scope.pageData[$scope.selectedShopType]);

        delete t.start;
        t.length = 1000000;

        const totalRows = (await shipmentModel.listSms(t)).data.results;

        const resolve = {
          data: {
            rows: rows,
            totalRows: totalRows,
            totalCount: $scope.searchData.totalCount,
            searchForm: $scope.pageData[$scope.selectedShopType]
          }
        };

        commonSVC.openModal('lg', resolve, controller, `views/alimtalk/modals/${type}.html`);
      };

      /**
       * 선택 일괄 적용
       * 2018-11-08 rony
       */
      $scope.setAcceptAll = function() {
        if (!commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission)) {
          return false;
        }

        let setType = '';

        switch ($scope.setAllData.type) {
          case 'ship_method':
            setType = '배송방법';
            break;
          case 'ship_cost':
            setType = '배송비';
            break;
          case 'carr_no':
            setType = '택배사';
            break;
          case 'global_carr_no':
            setType = '해외택배사';
            break;
          case 'misc15':
            setType = '제조국';
            break;
        }

        if (setType == '') {
          commonSVC.showMessage('일괄적용할 항목을 선택해주십시오.');

          return false;
        } else if ($scope.setAllData.type == 'ship_method' && $scope.setAllData.ship_method == '') {
          commonSVC.showMessage('배송방법을 선택해주십시오');

          return false;
        } else if ($scope.setAllData.type == 'carr_no') {
          if ($scope.setAllData.carr_no == '') {
            commonSVC.showMessage('택배사를 선택해주십시오');

            return false;
          } else {
            const selectCarr = _.find($scope.deliveryInfoList, function(o) { return o.carr_no == $scope.setAllData.carr_no; });

            if (selectCarr) {
              $scope.setAllData.carr_name = selectCarr.carr_name;
            }
          }
        } else if ($scope.setAllData.type === 'global_carr_no') {
          if ($scope.setAllData.global_carr_no === '') {
            commonSVC.showMessage('해외택배사를 선택해주십시오');

            return false;
          } else {
            const selectCarr = _.find($scope.globalCarrList, function(o) { return o.carr_no == $scope.setAllData.global_carr_no; });

            if (selectCarr) {
              $scope.setAllData.global_carr_name = selectCarr.carr_name;
            }
          }
        } else if ($scope.setAllData.type === 'misc15') {
          if ($scope.setAllData.misc15 === '') {
            commonSVC.showMessage('제조국을 선택해주십시오');

            return false;
          }
        }

        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length == 0) {
          commonSVC.showMessage('주문을 선택해 주십시오.');

          return false;
        } else if ($scope.setAllData.type == 'ship_cost') {
          // 배송비 선택시.
          let unAvailList;
          let confirm_opt;

          if ($scope.setAllData.ship_cost > 0) {
            unAvailList = _.filter(selected, function(ord) { return ord.ship_method === '무료배송'; });
            if (unAvailList.length) {
              if (unAvailList.length == selected.length) {
                //선택한 갯수와 불가능 한 갯수가 같을 경우
                commonSVC.showMessage('배송방법이 무료배송인 주문은 배송비 일괄입력 대상이 아닙니다.');
              } else {
                confirm_opt = {
                  title: '배송방법이 무료배송인 주문건이 포함되어 있습니다.',
                  text: '배송방법이 `무료배송`인 주문은 배송비 일괄입력에서 제외처리 됩니다.',
                  type: 'warning',
                  confirmButtonText: '배송비 일괄입력 가능한 주문만 선택'
                };

                commonSVC.showConfirmCustom(confirm_opt, function () {
                  $scope.grid.methods.doSelectByFilter(function (r) { // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
                    return r.ship_method !== '무료배송';
                  }, true);
                });
              }

              return false;
            }
          } else {
            // 배송비 0원 입력시
            unAvailList = _.filter(selected, function(ord) { return ord.ship_method === '선결제' || ord.ship_method === '착불'; });
            if (unAvailList.length) {
              if (unAvailList.length == selected.length) {
                //선택한 갯수와 불가능 한 갯수가 같을 경우
                commonSVC.showMessage('배송방법이 `선결제` 또는 `착불` 인 주문은 배송비가 0원 이상이 되어야 하므로 배송비 일괄입력 대상이 아닙니다.');
              } else {
                confirm_opt = {
                  title: '배송방법이 선결제 또는 착불인 주문건이 포함되어 있습니다.',
                  text: '배송방법이 `선결제` 또는 `착불` 인 주문은 배송비가 0원 이상이 되어야 하므로 일괄입력에서 제외처리 됩니다.',
                  type: 'warning',
                  confirmButtonText: '배송비 일괄입력 가능한 주문만 선택'
                };

                commonSVC.showConfirmCustom(confirm_opt, function () {
                  $scope.grid.methods.doSelectByFilter(function (r) {
                    return r.ship_method !== '선결제' && r.ship_method !== '착불';
                  }, true);
                });
              }

              return false;
            }
          }
        }

        // 택배사가 자체배송, 방문수령, 퀵서비스 인경우는 운송장 출력으로 상태 변경추가 2018-12-03 rony
        let addMessage = '';

        if (setType === '택배사' && ($scope.setAllData.carr_no === '68' || $scope.setAllData.carr_no === '41' || $scope.setAllData.carr_no === '75' || $scope.setAllData.carr_no === '87' || $scope.setAllData.carr_no === '89')) {
          addMessage = '선택한 택배사가 [자체배송, 방문수령, 퀵서비스, 화물배달, 가구직배송]인경우 [운송장출력] 상태로 변경됩니다.';
        }

        commonSVC.showConfirm(`${setType} 항목을 일괄변경하시겠습니까?`, `선택한 주문의 정보가 일괄 변경됩니다.\n${addMessage}\n변경하신 정보는 택배사정보 저장을 하셔야 최종 저장이 됩니다.`, function (re) {
          if (re) {
            orderList
              .forEach(function(ord) {
                if (!_.map(selected, 'bundle_no').includes(ord.bundle_no)) {
                  return true;
                }

                if (!delivInfoUpdateList[ord.bundle_no]) {
                  delivInfoUpdateList[ord.bundle_no] = {};
                }

                // 리프레시 데이터 처리 및 업데이트 데이터 처리
                if ($scope.setAllData.type === 'carr_no') {
                  const carr_data = $scope.setAllData.carr_no.split(',');

                  ord.carr_name = carr_data[1];
                  ord.carr_no = carr_data[0];
                  delivInfoUpdateList[ord.bundle_no][$scope.setAllData.type] = ord.carr_no;
                  delivInfoUpdateList[ord.bundle_no].carr_name = ord.carr_name;
                } else if ($scope.setAllData.type === 'global_carr_no') {
                  const global_carr_data = $scope.setAllData.global_carr_no.split(',');
                  ord.global_carr_name = global_carr_data[1];
                  ord.global_carr_no = global_carr_data[0];
                  delivInfoUpdateList[ord.bundle_no][$scope.setAllData.type] = ord.global_carr_no;
                  delivInfoUpdateList[ord.bundle_no].global_carr_name = ord.global_carr_name;
                } else {
                  ord[$scope.setAllData.type] = $scope.setAllData[$scope.setAllData.type];
                  delivInfoUpdateList[ord.bundle_no][$scope.setAllData.type] = $scope.setAllData[$scope.setAllData.type];
                }
              });

            $scope.grid.methods.reDraw();
          }
        });
      };

      /**
       * 택배사 select 검색
       */
      $scope.$watchCollection('setAllData', function () {
        $timeout(function () {
          const $searchDetailElem = $('.a-content #search-detail-carr_no');
          $searchDetailElem.attr('data-container-css-class', 'select2-custom2 input-xs');
          $searchDetailElem.select2({
            placeholder: '선택하세요',
            width: 153
          });
        });
      });

      /**
       * 제조국 select 검색
       */
      $scope.$watchCollection('setAllData', function () {
        $timeout(function () {
          const $searchDetailElem = $('.a-content #search-detail-misc15');
          $searchDetailElem.attr('data-container-css-class', 'select2-custom2 input-xs');
          $searchDetailElem.select2({
            placeholder: '선택하세요',
            width: 153
          });
        });
      });

      /**
       * 주문수량분리
       */
      $scope.divideOrder = function () {
        let ordList = [];
        let isBundleGroup = false; // 합포장 판단여부

        ordList = $scope.grid.methods.selectedData('all');
        const hasEditingOrd = ordList.some(ord => ord.isEditing);

        if (ordList.length > 1) {
          commonSVC.showMessage('실패', '하나의 주문만 분할할 수 있습니다.');

          return false;
        } else if (!ordList.length) {
          commonSVC.showMessage('실패', '선택된 주문이 없습니다.');

          return false;
        }

        let isAailable = true;

        _.forEach(ordList, function (order) {
          if ($scope.bundle_group[order.bundle_no].length > 1) {
            isBundleGroup = true;

            return false;
          } else if (order.sale_cnt === 1 &&
              (!addProdList[order.uniq] || _.sumBy(addProdList[order.uniq], 'opt_sale_cnt') === addProdList[order.uniq].length)) {
            isAailable = false; // 주문 수량 1 // 추가구매 옵션 수량 1인 경우 분할 불가.

            return false;
          }
        });

        if (!isAailable) {
          commonSVC.showMessage('실패', '분할할 수 없는 주문이 있습니다.');

          return false;
        }

        if (isBundleGroup) {
          commonSVC.showMessage('실패', '합포장 주문건은 주문수량분할 대상에 해당되지 않습니다.');

          return false;
        }

        const resolve = {
          data: {
            ordList: ordList,
            referer: 'unstoring_list',
          },
          warehouseList: warehouseList,
          systemList: useSystemList
        };

        const modal = commonSVC.openModal('lg', resolve, 'DivideOrderCTRL', 'views/order/shipment/modals/divide_order.html');

        modal.result.then(function (result) {
          if (result === 'success') {
            if (hasEditingOrd) {
              for (const ord of ordList) {
                unstoringTableEditList[ord.bundle_no][ord.uniq].isChangedData = true;
              }
            }
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 2018-12-27 Jacob
       * 배송지연 전송 추가
       */
      $scope.delayedOrder = function () {
        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const dataNone = selected.reduce((aur, d) => {
          if (d.ship_delay_yn || !shipmentSVC.canDeliDelayShop.includes(d.pa_shop_cd) || d.shop_ord_no.indexOf('_copy') > -1 || (d.pa_shop_cd === 'A077' && ['HOPE_SELLER_GUARANTEE'].includes(d.misc19)) || (d.misc_etc?.[0]?.is_addOpt_order && !selected.find(s => s.uniq === d.ori_uniq))) {
            aur.push(d);
          }

          return aur;
        }, []);

        if (dataNone.length > 0 && selected.length !== dataNone.length) {
          commonSVC.showConfirmCustom({
            title: gettextCatalog.getString('배송지연 전송'),
            text: shipmentSVC.delayDeliveryMessage.info,
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#5c90d2',
            confirmButtonText: shipmentSVC.delayDeliveryMessage.confirm,
            cancelButtonText: gettextCatalog.getString('취소'),
            animation: false,
          }, function () {

            // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
            // 2017-03-13 MatthewKim
            const reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
              return !r.ship_delay_yn && shipmentSVC.canDeliDelayShop.includes(r.pa_shop_cd) && r.shop_ord_no.indexOf('_copy') < 0 && !(r.pa_shop_cd === 'A077' && ['HOPE_SELLER_GUARANTEE'].includes(r.misc19)) && !r.misc_etc?.[0]?.is_addOpt_order;
            }, true);

            if (reselected_indexes.length == 0) {
              commonSVC.showToaster('error', '자동 선택 불가', shipmentSVC.delayDeliveryMessage.fail);
            }
          });

          return false;
        } else if (dataNone.length > 0 && selected.length === dataNone.length) {
          commonSVC.showMessageHtml(shipmentSVC.delayDeliveryMessage.fail, shipmentSVC.delayDeliveryMessage.info);

          return false;
        }
        const resolve = {
          systemList: function () {
            return systemModel.load();
          }
        };

        resolve.data = {
          fromPage: 'order',
          selectList: selected
        };

        commonSVC.openModal('', resolve, 'delayDeliveryCtrl', 'views/order/shipment/modals/delay_delivery.html');
      };

      /**
       * 희망배송일 변경
       * */
      $scope.updateHopeDate = function () {
        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        let dataNone;
        const isLg = $rootScope.affName === 'LG전자';
        const lgCondition = selected.filter(ord => ord['pa_addcol_희망배송일 전송 작업'] === '실패' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(ord.misc19) && ord.pa_shop_cd === 'A077').length && isLg;

        if (lgCondition) {
          dataNone = Object.values(_.groupBy(selected, 'bundle_no')).reduce((aur, curr) => {
            let disableBundleNo = false;

            curr.forEach((d) => {
              if (d.pa_shop_cd !== 'A077' || (d.pa_shop_cd === 'A077' && !['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19)) || (d.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19) && d['pa_addcol_희망배송일 전송 작업'] !== '실패') || d.shop_ord_no.indexOf('_copy') > -1 || d.shop_ord_no.indexOf('_d') > -1 || !['신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중'].includes(d.ord_status)) {
                disableBundleNo = true;
              }
            });

            return disableBundleNo ? [...aur, ...curr] : aur;
          }, []);
        } else {
          // 스마트스토어가 아니고 혹은 스마트스토어인데 misc19에 특정 값이 없을 경우 혹은 copy 주문인 경우
          dataNone = Object.values(_.groupBy(selected, 'bundle_no')).reduce((aur, curr) => {
            let disableBundleNo = false;

            curr.forEach((d) => {
              if (d.pa_shop_cd !== 'A077' || (d.pa_shop_cd === 'A077' && !['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19)) || d.shop_ord_no.indexOf('_copy') > -1 || d.shop_ord_no.indexOf('_d') > -1 || !['신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중'].includes(d.ord_status)) {
                disableBundleNo = true;
              }
              if (isLg && (d.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19) && d['pa_addcol_희망배송일 전송 작업'] === '성공')) {
                disableBundleNo = true;
              }
            });

            return disableBundleNo ? [...aur, ...curr] : aur;
          }, []);
        }

        // 희망배송일 [변경가능]+[변경불가] 조합의 주문 선택
        if (dataNone.length > 0 && selected.length !== dataNone.length) {
          commonSVC.showConfirmCustom({
            title: gettextCatalog.getString('희망배송일 변경'),
            text: delaySVC.updateHopeDateMessage(isLg).info,
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#5c90d2',
            confirmButtonText: '희망배송일 변경 작업이 가능한 건만 선택해두기',
            cancelButtonText: gettextCatalog.getString('취소'),
            animation: false,
          }, function () {

            // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
            // 2017-03-13 MatthewKim
            let reselected_indexes;

            if (lgCondition) {
              reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
                return r.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(r.misc19) && r['pa_addcol_희망배송일 전송 작업'] === '실패';
              }, true);
            } else {
              reselected_indexes = $scope.grid.methods.unSelectByFilter(function (r) {
                if (isLg && (r.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(r.misc19) && r['pa_addcol_희망배송일 전송 작업'] === '성공')) {
                  return true;
                }

                return r.pa_shop_cd !== 'A077' || (r.pa_shop_cd === 'A077' && !['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(r.misc19)) || r.shop_ord_no.indexOf('_copy') > -1 || r.shop_ord_no.indexOf('_d') > -1 || !['신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중'].includes(r.ord_status);
              }, true);
            }

            if (reselected_indexes.length == 0) {
              commonSVC.showToaster('error', '자동 선택 불가', delaySVC.updateHopeDateMessage(isLg).fail);
            }
          });

          return false;
        } else if (dataNone.length > 0 && selected.length === dataNone.length) {
          // 희망배송일 [변경불가]한 주문 선택 시
          commonSVC.showMessageHtml(delaySVC.updateHopeDateMessage(isLg).fail, delaySVC.updateHopeDateMessage(isLg).info);

          return false;
        }

        const resolve = {
          systemList: function () {
            return systemModel.load();
          }
        };

        resolve.data = {
          fromPage: 'order',
          selectList: selected
        };

        // 엘지면서 희망배송일 전송작업이 실패한 주문이 있으면 직접 "희망배송일 전송작업"을 실행해
        if (lgCondition) {
          const grouped = _.groupBy(selected.filter(ord => ord['pa_addcol_희망배송일 전송 작업'] === '실패'), (ord) => [ord.ship_hope_time, ord['pa_addcol_희망배송 지연 사유']]);
          const hope_date_group = [];

          for (const key of _.keys(grouped)) {
            hope_date_group.push({
              hope_date: key.split(',')[0],
              reason_code: key.split(',')[1],
              uniqs: _.map(grouped[key], 'uniq')
            });
          }

          if (hope_date_group.length < 1) {
            // 작업을 걸 수 있는게 없다는거여서 실패처리
            commonSVC.showMessageHtml(delaySVC.updateHopeDateMessage(isLg).fail, delaySVC.updateHopeDateMessage(isLg).info);

            return false;
          }

          const params = hope_date_group.length === 1 ? {
            numbers: hope_date_group[0].uniqs,
            delay_code: hope_date_group[0].reason,
            delay_date: hope_date_group[0].hope_date
          } : { hope_date_group };

          workSVC.addWorkSelect('UPDATE_HOPE_DATE', params);
        } else {
          commonSVC.openModal('', resolve, 'updateHopeDateCtrl', 'views/order/shipment/modals/update_hope_date.html');
        }
      };

      $scope.updateInvoice = function (bundle_no, carr_no, invoice_no, carr_type, isBlur) {
        const carr_no_key = carr_type === 'global' ? 'global_carr_no' : 'carr_no';
        const invoice_no_key = carr_type === 'global' ? 'global_invoice_no' : 'invoice_no';

        const inputList = $('input[bundle]');
        const elm = $(`input[bundle=${bundle_no}]`);

        // 업데이트 리스트추가
        if (!delivInfoUpdateList[bundle_no]) {
          delivInfoUpdateList[bundle_no] = {};
        }

        invoice_no = invoice_no ? invoice_no.trim().replace(/[\n\r]*/g, '') : '';

        if (invoice_no) {
          let isErr = false;

          if (!carr_no && !delivInfoUpdateList[bundle_no][carr_no_key] && invoice_no) {
            // 택배사 따로 이후 업데이트 처리에다 다시 확인하므로 입력된것 지워지지 않도록 return은 하지 않음
            commonSVC.showMessage('운송장번호를 입력하려면 택배사가 지정되어 있어야 합니다.');

            isErr = true;
          }

          // 뷰 업데이트
          orderList
            .forEach((ord) => {
              if (String(ord.bundle_no) === String(bundle_no)) {
                ord[invoice_no_key] = invoice_no;
              }
            });

          delivInfoUpdateList[bundle_no][invoice_no_key] = invoice_no;
          // carr_no 없데이트 확인
          delivInfoUpdateList[bundle_no][carr_no_key] = delivInfoUpdateList[bundle_no][carr_no_key] || (carr_no || '');

          if (!isErr) {
            if (isBlur) {
              $scope.grid.methods.reDraw();
            } else if (event && event.keyCode === 13) {
              // 커서이동
              event.preventDefault();
              if (inputList.length - 1 !== inputList.index(elm.last())) {
                inputList.get(inputList.index(elm.last()) + 1).focus();
              }
            }
          }
        } else {
          if (delivInfoUpdateList[bundle_no]) {
            // 로그 남으므로 삭제
            delete delivInfoUpdateList[bundle_no][invoice_no_key];

            if (!Object.keys(delivInfoUpdateList[bundle_no]).length) {
              // 업데이트 리스트에서 제거
              delete delivInfoUpdateList[bundle_no];
            }
          }

          // 커서이동
          if (!isBlur && inputList.length - 1 !== inputList.index(elm.last())) {
            inputList.get(inputList.index(elm.last()) + 1).focus();
          }
        }
      };

      /**
       * 배송정보 일괄 업데이트
		  */
      $scope.setDelivInfo = async () => {
        try {
          if (commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission) === false) {
            return false;
          }

          _.forEach(delivInfoUpdateList, (val, bundle_no) => {
            if (!Object.keys(val).length) {
              delete delivInfoUpdateList[bundle_no];
            }
          });

          if (!Object.keys(delivInfoUpdateList).length) {
            commonSVC.showMessage('변경사항이 없습니다.');

            return false;
          }

          const nonCarr = [];

          // 유효성검사
          _.forEach(delivInfoUpdateList, (val, bundle_no) => {
            if (!val.carr_no && val.invoice_no) {
              nonCarr.push(bundle_no);
            }
          });

          if (nonCarr.length) {
            commonSVC.showMessage(`운송장 번호를 입력하려면 택배사가 지정되어 있어야 합니다. \n 묶음번호: ${nonCarr.join(', ')}`);

            return false;
          }

          const confirm = await commonSVC.showConfirm('택배사 정보를 일괄 변경하시겠습니까?');
          if (!confirm) {
            return false;
          }

          try {
            const re = await deliveryModel.setDelivInfo({ delivInfoUpdateList, isPkgNo: false, shopType: $scope.selectedShopType });

            if (re.data === 'success') {
              delivInfoUpdateList = {};
              commonSVC.showToaster('success', '성공', '배송정보 일괄 수정에 성공하였습니다.');
              $scope.searchDo(false);

              return true; // 로딩바 복구 처리
            } else {
              commonSVC.showToaster('error', '실패', re.data);

              return false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', err.data?.messages ? err.data.messages[0] : err.data?.message || '');

            return false;
          }
        } catch (err) {
          return false;
        }
      };

      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      /**
       * 리스트와 별개로 초기에 세팅되어야하는 값
       * @param {*} data
       */
      function init(data) {
        $scope.selectRowUniqList = [];
        $scope.countList = {
          total: data['출고관리_전체'] ? data['출고관리_전체'][0].cnt : 0,
          waiting: data['출고대기'] ? data['출고대기'][0].cnt : 0,
          hold: data['출고보류'] ? data['출고보류'][0].cnt : 0,
          invoice: data['운송장출력'] ? data['운송장출력'][0].cnt : 0
        };

        $rootScope.listColorObj.unstoring = {};

        // 글로벌 배송사 리스트
        $scope.globalSerCarrList = deliverySVC.global.getCarrList(deliverySVC.global.carrList, channelList);

        // (국제특송 / 쇼핑몰 자체물류 / 물류서비스) 분류에 맞게 택배사 정보 셋팅
        $scope.globalCarrPrintList = deliverySVC.global.deliveryInfo.map((deliveryData, index) => {
          return {
            bundleDisplayNm: deliveryData.bundleDisplayNm,
            carrRows: $scope.globalSerCarrList.filter(c => {
              c.miniLogo = [952, 944, 951].includes(c.carr_no);

              return deliveryData.deliveryNm.includes(c.carr_name);
            }),
          };
        });
      }

      // 초기 선택이 안된 탭에도 검색기간 초기화 적용
      setTimeout(() => {
        const destSearchForm = $scope.selectedShopType === 'global' ? domesticSearch.searchForm : globalSearch.searchForm;
        destSearchForm.search_key = $scope.searchForm.search_key || $scope.searchData.search_key_items[0].value;
        destSearchForm.change_sdate = false;
        destSearchForm.change_edate = false;
        destSearchForm.selectDate = angular.copy($scope.searchForm.selectDate);
      });

      // 2018-04-06 Daniel
      // 재고관리제외버전 추가
      // stockExceptionSVC.scopeExcept(_.last($state.current.url.split('/')), $scope);

      /**
       * 주문 리스트 테이블 내 정보 수정
       */
      $scope.editOrder = async function() {
        $scope.oriSelected = $scope.grid.methods.selectedData('all');
        $scope.oldSelected = angular.copy($scope.oriSelected);
        $scope.unEditableList = $scope.oriSelected.filter(ord => !['신규주문', '주문보류', '출고대기', '출고보류'].includes(ord.ord_status));
        $scope.editableList = $scope.oriSelected.filter(ord => !$scope.unEditableList.includes(ord));

        if ($scope.oriSelected.length === 0) {
          // 선택한 주문이 없을 경우
          commonSVC.showMessage('수정이 필요한 주문을 선택해 주세요.', '주문 건을 선택 후 주문 정보 수정이 가능합니다.');

          return false;
        } else if ($scope.oriSelected.length === $scope.unEditableList.length) {
          // 수정 불가능한 주문건만 선택했을 경우
          commonSVC.showMessage('수정이 불가능한 주문건 입니다.', '수정 가능 주문상태: 신규주문, 주문보류, 출고대기, 출고보류 \n 수정 불가능 주문상태: 취소요청, 취소완료, 운송장출력');

          return false;
        } else {
          if ($scope.unEditableList.length === 0) {
            // 선택한 주문들이 다 수정 가능할 경우
            await commonSVC.showMessage('주문 리스트 정보 수정 초기화 주의!', '수정 중 화면 종료 및 다른 화면으로 이동 시 수정 중이던 정보가 초기화됩니다. \n 수정 중이던 화면의 [주문 정보 저장] 버튼을 클릭하여 저장 후 이동해 주세요.');
            $scope.activateEditMode();
          } else {
            // 수정 불가능한 주문 건도 포함하고 있을 경우
            await commonSVC.showConfirmCustomSwal2({
              width: 630,
              title: '<h2 style="text-align: left;">수정 불가한 주문 상태를 제외하고 수정합니다.</h2>',
              text: '<p style="text-align: left; line-height: 1.5em;">수정 가능 주문상태: 신규주문, 주문보류, 출고대기, 출고보류 <br /> 수정 불가능 주문상태: 취소요청, 취소완료, 운송장출력 <br/><br/> *<span style="color: red;">수정 중 화면 종료 및 다른 화면으로 이동 시 수정 중이던 정보가 초기화 됩니다.</span><br/>*<span style="color: red;">수정 중이던 화면의 [주문 정보 저장] 버튼을 클릭하여 저장 후 이동해 주세요.</span></p>',
              showCancelButton: true,
              confirmButtonText: '수정',
              cancelButtonText: '취소',
            }, (re) => {
              if (re.isConfirmed === true) {
                $scope.activateEditMode();
              } else {
                return false;
              }
            });
          }
        }
      };

      /**
       * 주문 수정 활성화
       */
      $scope.activateEditMode = async function() {
        $rootScope.unstoringListEditStatus = true;
        $('.btn-order-edit').addClass('disabled');
        $('.btn-save-order-unstoring').removeClass('hide');

        $scope.oriSelected.forEach((ord) => {
          if (!unstoringTableEditList[ord.bundle_no]) {
            unstoringTableEditList[ord.bundle_no] = {};
          } else {
            if (!unstoringTableEditList[ord.bundle_no][ord.uniq]) {
              unstoringTableEditList[ord.bundle_no][ord.uniq] = {};
            }
          }
          unstoringTableEditList[ord.bundle_no][ord.uniq] = ord;
        });
        // 노출컬럼 변경 상황떄문에 선택 당시 컬럼 저장
        oldColumns = $scope.grid.methods.getColumnsVisible().map(column => column.key);

        await $scope.searchDo();
      };

      /**
       * 주문 수정 저장
       */
      $scope.saveOrderEdit = async function() {
        // { 묶음번호: { uniq1: {주문정보}, uniq2: {주문정보} } } 형태를 주문정보 array로 변환
        const editedList = Object.entries(unstoringTableEditList).map(([, e]) => Object.entries(e)).reduce((prev, curr) => prev = prev.concat(curr.map(([, e]) => e)), []);
        const requiredList = ['shop_sale_name', 'to_name', 'to_htel', 'to_zipcd', 'to_addr1'];
        const newColumns = $scope.grid.methods.getColumnsVisible().map(column => column.key);
        let missingColumns = [];

        const isError = _.some(editedList, row => {
          return _.some(requiredList, field => {
            return _.trim(row[field]) === '';
          });
        });

        // 저장시 컬럼이 선택당시보다 줄었다면 없어진 필드 확인
        if (newColumns.length < oldColumns.length) {
          missingColumns = oldColumns.filter(column => !newColumns.includes(column));
        }

        if ($scope.tableEditForm.$valid && !isError) {
          for (let i = 0; i < editedList.length; i++) {
            if (editedList[i].sale_cnt === '') {
              editedList[i].sale_cnt = 0;
            }

            // 없어진 필드값 원본데이터로 재할당
            if (missingColumns.length > 0) {
              missingColumns.forEach((column) => {
                editedList[i][`${column}`] = $scope.oldSelected[i][`${column}`];
              });
            }
          }

          // 변경사항 업데이트
          await shipmentModel.updateOrder({ editedList }, function () {
            $rootScope.unstoringListEditStatus = false;
            $scope.editableList = [];
            unstoringTableEditList = {};
            $('.btn-order-edit').removeClass('disabled');
            $('.btn-save-order-unstoring').addClass('hide');
            commonSVC.showMessage('주문 정보 수정 성공', '수정 중 주문상태가 변경된 주문 건은 반영되지 않습니다. \n 변경사항은 로그에서 확인 가능합니다.');
            $scope.searchDo();
          });
        } else {
          await commonSVC.showMessageHtml('출고관리 리스트에서 정보 수정 시 누락된 필수 입력 항목이 존재합니다.', `
            •적색표시된 항목들을 입력해 주세요.
            •<span class="counter-text-color">필수 항목: 온라인상품명, 수령자명, 수령자 핸드폰번호, 우편번호, 상세주소, 주문자명, 주문자 휴대폰번호</span>
            •리스트 내 필수 항목이 없을 경우 리스트 우측 상단의 [노출항목설정]에서 추가할 수 있습니다.
          `);
          let first = null;
          $('form[name="tableEditForm"]').find(':input[required]').each(function() {
            if (!$(this).val()) {
              if (!first) {
                first = this;
              }
            }
          });
          $(first).focus();
        }
      };
      /**
       * 알림톡 발송
       */
      $scope.altalkSend = async () => {
        const plusIdList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
        const resolve = {
          data: {
            open: 'ord',
            type: 'direct',
            plusIdList: plusIdList.data?.result,
            ord: $scope.domestic.methods.selectedData() || []
          }
        };
        commonSVC.openModal('x3g', resolve, 'AltalkSendCtrl', 'views/alimtalk/modals/altalk_send.html');
      };

      /**
       * 알림톡 그룹 회원 추가
       */
      $scope.altalkGroupMemAdd = () => {
        if (!$scope.domestic.methods.selectedData().length) {
          return commonSVC.showMessage('추가할 회원이 없습니다.', '주문리스트에서 주문을 먼저 선택해 주세요.');
        }
        commonSVC.openModal('lg', { data: { type: 'add_mem', ord: $scope.domestic.methods.selectedData() || [] } }, 'AltalkSendGroupCtrl', 'views/alimtalk/modals/altalk_send_group.html');
      };

      /**
       * tableEditList & 묶음주문 input Update
       */
      $scope.updateTableEditList = function(bundle_no, uniq, key) {
        const field = $(`input.${key}[uniq="${uniq}"]`);
        const bundleTarget = $(`.${key}[bundle=${bundle_no}]`);
        const toFieldList = ['to_name', 'to_tel', 'to_htel', 'to_addr1'];
        const priceFieldList = ['sales', 'shop_supply_price', 'shop_cost_price', 'pay_amt', 'discount_amt'];

        if (!unstoringTableEditList[bundle_no]) {
          unstoringTableEditList[bundle_no] = {};
          unstoringTableEditList[bundle_no][uniq] = {};
        } else {
          if (!unstoringTableEditList[bundle_no][uniq]) {
            unstoringTableEditList[bundle_no][uniq] = {};
          }
        }

        // 수령자 정보 변경 시 동일 묶음번호 주문건 동시 변경
        if (toFieldList.includes(key)) {
          if (field.val().length > 0 && $.trim(field.val()) === '') {
            field.val(field.val().trim());
          }

          bundleTarget.val(field.val());

          for (const uniq in unstoringTableEditList[bundle_no]) {
            unstoringTableEditList[bundle_no][uniq][key] = field.val();
          }
        }

        //주문 수량 변경시 원가도 view, data update
        if (key === 'sale_cnt') {
          const opt_cost_price = _(prodList[uniq]).filter({ ord_opt_seq: 1 }).map((opt) => opt.cost_price * opt.pack_unit).sum(); // 옵션에 매칭되어있는 SKU상품 or 세트상품의 원가 총합
          const add_opt_cost_price = (_(addProdList[uniq]).map((opt) => opt.cost_price * opt.opt_sale_cnt).sum()) || 0; //추가구매옵션 원가 총합
          const result = opt_cost_price * Number(field.val()) + add_opt_cost_price;
          $(`input.shop_cost_price[uniq="${uniq}"]`).val(result);
          unstoringTableEditList[bundle_no][uniq].shop_cost_price = result;
        }

        if (priceFieldList.includes(key)) {
          unstoringTableEditList[bundle_no][uniq][key] = Number(field.val().replace(/,/g, ''));
        } else {
          unstoringTableEditList[bundle_no][uniq][key] = field.val();
        }

        $scope.grid.methods.reDraw();
      };

      /**
       * 총 출고수량 view update
       */
      $scope.updateTotalCnt = function(uniq, pack_unit) {
        const saleCntInput = $(`input.sale_cnt[uniq="${uniq}"]`);

        saleCntInput.on('keyup', function() {
          $(`span.total_cnt[uniq="${uniq}"]`).text(Number($(this).val()) * pack_unit);
        });
      };

      // 리스트 수정 폼에서 엔터키 입력 시 submit 막음
      $(function() {
        $('form[name="tableEditForm"]').on('keydown', (e) => {
          if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();
          }
        });
      });

      //우편번호 모달에서 받아온 값 즉시 수정 객체 저장
      $scope.$on('changeZipcd', function(event, data) {
        $(`input.to_zipcd[bundle = ${data.bundle}]`).val(`${data.zipcd}`);
        $(`input.to_addr1[bundle = ${data.bundle}]`).val(`${data.addr}`);
        for (const uniq in unstoringTableEditList[data.bundle]) {
          unstoringTableEditList[data.bundle][uniq].to_zipcd = data.zipcd;
          unstoringTableEditList[data.bundle][uniq].to_addr1 = data.addr;
        }
      });

    });