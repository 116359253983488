import Swal from 'sweetalert2';
import * as _ from 'lodash';
import PNotify from 'pnotify';

/**
 * Alert 메세지
 */
export const showMessage = async (title: string, contents?: string, keyboard = true, outside = true) => {

  // 쿼리문은 에러메세지에 노출되지 않도록 처리.
  if (contents?.includes('threadId') && contents?.includes('Query')) {
    contents = 'Error';
  }

  return new Promise((resolve) => {
    Swal.fire({
      title,
      html: contents,
      confirmButtonColor: '#5C90D2',
      confirmButtonText: '확인',
      allowEscapeKey: keyboard,
      allowOutsideClick: outside
    }).then(() => {
      resolve(true);
    });
  });
};

/**
 * confirm 메세지
 */
export const showConfirm = (title: string, message?: string): Promise<boolean> => {
  if (title && !message) {
    message = title;
    title = '';
  }

  return new Promise((resolve) => {
    Swal.fire({
      title,
      html: message,
      confirmButtonColor: '#5C90D2',
      confirmButtonText: '확인',
      showCancelButton: true,
      cancelButtonText: '취소',
      reverseButtons: true
    }).then((result: any) => {
      resolve(result.isConfirmed);
    });
  });
};

/**
 * Prompt 메세지
 */
export const showPrompt = (
  title: string, message?: string, inputLabel?: string, inputPlaceholder?: string
): Promise<{ isConfirmed: boolean, textValue: string }> => {
  return new Promise((resolve) => {
    Swal.fire({
      title,
      text: message,
      input: 'textarea',
      inputLabel,
      inputPlaceholder,
      inputAttributes: {
        maxlength: '10',
        autocapitalize: 'off',
        autocorrect: 'off'
      },
      confirmButtonColor: '#5C90D2',
      confirmButtonText: '확인',
      showCancelButton: true,
      cancelButtonText: '취소',
      width: '80%',
    }).then((result: any) => {
      resolve({
        isConfirmed: result.isConfirmed,
        textValue: result.value || ''
      });
    });
  });
};

/**
 * Prompt 메세지 (커스텀)
 */
export const showCustomPrompt = (
  title: string,
  message?: string,
  dateLabel?: string,
  dateOptions?: { min?: string, max?: string },
  selectLabel?: string,
  selectOptions?: { [key: string]: string },
  textLabel?: string,
  textPlaceholder?: string,
  validateFunction?: (name: string) => string,
  inputValue?: string
): Promise<{ isConfirmed: boolean, selectedDate: string, selectedOption: string, textValue: string }> => {
  return new Promise((resolve) => {
    const selectOptionsHtml = Object.entries(selectOptions || {})
      .map(([value, label]) => `<option value="${value}">${label}</option>`)
      .join('');

    Swal.fire({
      title,
      text: message,
      html: `
        <div style="display: flex; flex-direction: column; align-items: center; width: 100%;">
          ${dateLabel ? `
            <div style="width: 100%; margin-bottom: 10px; display: flex; flex-direction: column; align-items: center;">
              <label for="dateInput" style="font-size: 16px; margin-bottom: 5px; text-align: center;">${dateLabel}:</label>
              <input type="date" id="dateInput" class="swal2-input" style="width: 100%; max-width: 300px; padding: 10px; font-size: 16px; box-sizing: border-box;" ${dateOptions ? `min="${dateOptions.min}" max="${dateOptions.max}"` : ''} />
            </div>
          ` : ''}
          ${selectLabel ? `
            <div style="width: 100%; margin-bottom: 10px; display: flex; flex-direction: column; align-items: center;">
              <label for="selectInput" style="font-size: 16px; margin-bottom: 5px; text-align: center;">${selectLabel}:</label>
              <select id="selectInput" class="swal2-input" style="width: 100%; max-width: 300px; padding: 10px; font-size: 16px; box-sizing: border-box;">
                ${selectOptionsHtml}
              </select>
            </div>
          ` : ''}
          ${textLabel ? `
            <div style="width: 100%; margin-bottom: 10px; display: flex; flex-direction: column; align-items: center;">
              <label for="textInput" style="font-size: 16px; margin-bottom: 5px; text-align: center;">${textLabel}:</label>
              <input type="text" id="textInput" class="swal2-input" style="width: 100%; max-width: 300px; padding: 10px; font-size: 16px; box-sizing: border-box;" placeholder="${textPlaceholder || ''}" value="${inputValue || ''}" />
            </div>
          ` : ''}
        </div>
      `,
      confirmButtonColor: '#5C90D2',
      confirmButtonText: '확인',
      showCancelButton: true,
      cancelButtonText: '취소',
      customClass: {
        cancelButton: 'swal2-cancel',
      },
      reverseButtons: true,
      didOpen: () => {
        const dateInput = Swal.getPopup()?.querySelector('#dateInput') as HTMLInputElement;
        if (dateInput) {
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const day = String(today.getDate()).padStart(2, '0');
          dateInput.value = `${year}-${month}-${day}`;
        }

        // input focus
        const textInput = Swal.getPopup()?.querySelector('#textInput') as HTMLInputElement;
        if (textInput) {
          textInput.focus();
        }
      },
      preConfirm: () => {
        const dateInput = (Swal.getPopup()?.querySelector('#dateInput') as HTMLInputElement)?.value;
        const selectInput = (Swal.getPopup()?.querySelector('#selectInput') as HTMLSelectElement)?.value;
        const textInput = (Swal.getPopup()?.querySelector('#textInput') as HTMLInputElement)?.value;

        if (validateFunction) {
          const errorMessage = validateFunction(textInput);

          if (errorMessage) {
            Swal.showValidationMessage(`<span style="color: red;">${errorMessage}</span>`);

            return false;
          }
        }

        return {
          selectedDate: dateInput,
          selectedOption: selectInput,
          textValue: textInput || ''
        };
      }
    }).then((result) => {
      resolve({
        isConfirmed: result.isConfirmed,
        selectedDate: result.value?.selectedDate || '',
        selectedOption: result.value?.selectedOption || '',
        textValue: result.value?.textValue || ''
      });
    });
  });
};

/**
 * Toaster 메세지
 */
export const showToaster = _.debounce(function (type, title, contents, opt) {

  // 쿼리문은 에러메세지에 노출되지 않도록 처리.
  if (contents?.includes('threadId') && contents?.includes('Query')) {
    contents = 'Error';
  }

  let icon = '';

  if (type === 'success') {
    icon = 'icon-checkmark3';
  } else if (type === 'error') {
    icon = 'icon-blocked';
  } else if (type === 'warning') {
    icon = 'icon-warning22';
  } else if (type === 'info') {
    icon = 'icon-info22';
  }

  const notice = new PNotify({
    title: title,
    text: contents,
    type: type,
    icon: icon,
    buttons: {
      closer: false,
      sticker: false
    },
    addclass: opt && opt.addclass ? opt.addclass : '',
    width: opt && opt.width ? opt.width : '300px',
    // 2017-03-14 matthew 옵션 일단 뺌 (우측 상단으로)
    //addclass: "stack-top-right",
    //stack: stack_bottom_right
  });

  // 2017-03-15 chris 알림 클릭시 닫기
  notice.get().click(function() {
    notice.remove();
  });

}, 500);

/**
 * ftp_addr 유효성 검사 함수
 */
export const validateFtpAddr = (addr: string): boolean => {
  // const regex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;    // 도메인만 체크
  const regex = /^(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})$/; // 도메인 + 아이피 체크

  return regex.test(addr);
};

/**
 * base64 인코딩
 */
export const base64Encode = (str: string): string => {
  return btoa(encodeURIComponent(str));
};

/**
 * 홈페이지 도움말 열기
 */
export const openHomePageHelp = (guide_no: number): void => {
  let url = 'https://www.plto.com/customer/';

  if (guide_no) {
    url += (`HelpDesc/?sol=gmp&guide_no=${guide_no}`);
  } else {
    url += 'Help/';
  }

  window.open(url);
};

