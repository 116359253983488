/**
 * 판매가 알림 리스트 모달
 **/

'use strict';

angular.module('gmpApp')
  .controller('PriceAlarmScheduleListCtrl', function ($rootScope, $scope, $compile, $timeout, $filter, settings, $uibModalInstance, commonSVC, systemList, systemModel, warehouseModel, insightModel) {

    const channelListArr = $rootScope.useChannelList({ site_action: 'SyncProdState' });
    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach(channelListArr, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    const search = {
      searchForm: {
        alarm_status: 'ALL',
        search_word: ''
      },
      searchData: {
        all_checked: false,
        totalCount: 0,
        showCount: 10,
        selectCount: 0
      },
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.searchFn = {
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: true,
      pinningColumns: ['widget'],
      defaultSortingColumns: ['wdate'],
      alignCenterColumns: ['widget', 'alarm_status', 'shop_sale_img', 'shop_sale_name', 'sale_price_criteria_type1', 'wdate', '발송일', 'send_time'],
      alignRightColumns: [],
      alignLeftColumns: [],
      notSortingColumns: ['widget', 'alarm_status', 'shop_sale_img', 'sale_price_criteria_type1'],
      notVisibleColumns: [],
      notMovingColumns: [],
      notResizingColumns: [],
      initPagingSize: 10,
      gridHeight: 430,
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/insight/alarm`,
        requestMethod: 'GET',
        requestWillAction: function (data) {
          data = angular.merge({}, data, $scope.searchForm);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            return (
              `${'' +
                '<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('}${
                row.insight_alarm_no
              }, ${row.ol_shop_no})">상세</button>` +
              '<button class="btn btn-default btn-xxs mr-5"  ng-click="grid.appScope.deleteDo(row.entity.insight_alarm_no)">삭제</button>'
            );
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 100,
          important: true,
          template: function(row) {
            const shop_info = commonSVC.getShopIdViewText(
              systemList.data.shop_id_view_type,
              seller_nick_info,
              row.shop_cd,
              row.shop_id
            );

            return `<span uib-tooltip="${row.shop_name}(${shop_info[0]})" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${
              ['A001', 'A006', 'B378'].includes(row.shop_cd) && row.ebay_shop_mas_no ? (row.shop_cd === 'A001' ? 'ESMA' : (row.shop_cd === 'A006' ? 'ESMG' : 'CPRG')) : (row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd)
            }.png" style="width: 50px;">${shop_info[1]}</span>`;
          }
        },
        {
          key: 'shop_sale_img',
          title: '이미지',
          width: 40,
          template: function(row) {
            //이미지
            const img = row.shop_sale_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' onerror='this.src="/assets/images/noimage.png"'>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 170
        },
        {
          key: 'sale_price_criteria_type1',
          title: '조건',
          width: 140,
          notCompile: true,
          template: function(row) {
            return row.sale_price_criteria_type1 + ' / ' + row.sale_price_criteria_type2 + ' / ' + `${$filter('number')(row.sale_price || 0)}원`;
          }
        },
        {
          key: 'wdate',
          title: '설정일',
          width: 120
        },
        {
          key: 'alarm_time',
          title: '알림일',
          width: 120
        },
        {
          key: 'send_time',
          title: '발송일',
          width: 120
        }
      ]
    };

    /**
       * ui-grid 노출 건수 설정
       */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    /**
     * 예약 상태 버튼 검색
     */
    $scope.searchDoByStatus = function (status) {
      $scope.searchForm.alarm_status = status;
      $scope.searchDo(true, true);
    };

    $scope.resetDo = function() {
      search.searchData.showCount = $scope.searchData.showCount;
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchData = angular.copy(search.searchData);

      $scope.searchDo(true, true);
    };

    /**
     * 판매가알림 예약 상세
     */
    $scope.showDetail = async function(insight_alarm_no, ol_shop_no) {
      const scheduleData = (await insightModel.priceAlarmScheduleDetail({ insight_alarm_no })).data.results;
      const resolve = {
        data: { type: 'compareAlarm', mode: 'detail', ol_shop_no, sale_opt_seq: scheduleData.sale_opt_seq, scheduleData: { ...scheduleData, sale_price_criteria: scheduleData.sale_price } },
        systemList: function () {
          return systemModel.load();
        },
        warehouseList: function() {
          return warehouseModel.ListAll({ use_yn: true });
        }
      };

      const modal = commonSVC.openModal('sg', resolve, 'RegistPriceScheduleCtrl', 'views/summary/modals/regist_price_schedule.html');

      modal.result.then((re) => {
        if (re === 'success') {
          $scope.searchDo(true, true);
        }
      });
    };

    /**
     * 판매가알림 예약 삭제
     */
    $scope.deleteDo = async function(insight_alarm_no) {
      const opt = {
        showCancelButton: true,
        cancelButtonText: '닫기',
        confirmButtonText: '삭제'
      };

      const confirm = await commonSVC.showConfirmHtml('판매가 알림설정 내역을 삭제하시겠습니까?', '', null, opt);

      if (!confirm) { return false; }

      try {
        await insightModel.deletePriceAlarmSchedule({ insight_alarm_no });

        commonSVC.showToaster('success', '성공', '비교상품 알림이 삭제되었습니다.');

        $scope.searchDo(true, true);

      } catch (err) {
        commonSVC.showToaster('error', '실패', '비교상품 알림 삭제가 실패되었습니다');
      }
    };

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.close();
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    $timeout(() => {});

  });