/**
 * 쿠폰관리 모달
 */
'use strict';

angular.module('gmpApp')
  .controller('couponManagementCtrl', function ($scope, data, $uibModalInstance, $timeout, userInfo, commonSVC, payModel) {
    $scope.couponList = {
      available: [],
      used: []
    };

    let solutionProductInfo;

    $scope.available = {}; // 사용가능한쿠폰 리스트 관련 변수
    $scope.used = {}; // 쿠폰사용내역 리스트 관련 변수

    $scope.tab = data.tab || 'available';

    // 검색영역 데이터
    const searchData = {
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수
      showCount: 10,
    };

    $scope.available.searchData = angular.copy(searchData);
    $scope.used.searchData = angular.copy(searchData);

    /**
     * 쿠폰리스트 조회
     */
    function getCouponList() {
      $scope.couponList.available = [];
      $scope.couponList.used = [];
      $scope.available.searchData = angular.copy(searchData);
      $scope.used.searchData = angular.copy(searchData);

      payModel.getUserCouponList({})
        .then(re => {
          re.data.results
            .forEach(cpn => {
              switch (cpn.ben_type) {
                case '이용료':
                  cpn.content = generateCouponContent(cpn);
                  break;
                case '가입비':
                  cpn.content = '결제시 가입비 할인적용';
                  break;
                case '사용기간':
                  if (cpn.ben_sub_type === '일') {
                    cpn.content = `사용기간 ${cpn.dc_qty}일 ${cpn.use_type == '즉시혜택' ? '연장' : '할인적용'}`;
                  } else if (cpn.ben_sub_type === '월') {
                    cpn.content = `${cpn.dc_qty}개월 연장`;
                  }

                  if (cpn.pay_month) {
                    cpn.content = `${cpn.pay_month}개월 결제시 ${cpn.content}`;
                  }
                  break;
                case '상품과금':
                case '주문과금':
                case 'SMS':
                case 'Email':
                case '알림톡':
                  cpn.content = `${cpn.ben_type.replace('과금', '')}카운트 ${cpn.dc_qty}건 추가`;
                  break;
              }

              // 사용조건 (버전)
              if (cpn.prod_group_nos) {
                const prodGroupName = cpn.prod_group_nos.map(o => solutionProductInfo.find(s => s.basic_pay_yn && s.prod_group_no == o).prod_name);
                cpn.content = `[${prodGroupName.join()}] ${cpn.content}`;

                if (cpn.use_type === '즉시혜택' && !prodGroupName.includes(userInfo.user.sol_version)) {
                  cpn.disabled = true;
                }
              }

              // 사용조건 (무료/유료)
              if (cpn.pay_sol_type) {
                cpn.content = `[${cpn.pay_sol_type == '무료' ? '첫결제' : '기결제'}] ${cpn.content}`;
              }

              // 사용가능/불가능 쿠폰 분리 저장
              if (['미사용', '사용대기'].includes(cpn.cpn_status)) {
                $scope.couponList.available.push(cpn);
              } else {
                $scope.couponList.used.push(cpn);
              }
            });
        });
    }

    function generateCouponContent(coupon) {
      const { pay_month, dc_qty, use_type, dc_cnt, ben_sub_type } = coupon;
      const payMonthText = pay_month ? pay_month + '개월 ' : '';
      const discountText = `${dc_qty}${ben_sub_type === '정률' ? '%' : '원'}`;
      const autopayText = use_type == '정기결제할인' && dc_cnt ? `할인 ${dc_cnt}회` : '할인적용';

      return `${payMonthText}${use_type == '정기결제할인' ? '정기' : ''}결제 시 ${discountText} ${autopayText}`;
    }

    /**
     * 쿠폰 선택
     */
    $scope.selectCpn = cpn => {
      if (cpn.use_type !== '즉시혜택' || cpn.disabled) {
        return false;
      }

      cpn.selected = !cpn.selected;
      $(`#coupon-check${cpn.cpn_no}`).prop('checked', cpn.selected);

      // 쿠폰 선택 카운트
      if (cpn.selected) {
        $scope.available.searchData.selectCount++;
      } else {
        $scope.available.searchData.selectCount--;
      }
    };

    /**
     * 선택쿠폰 사용
     */
    $scope.useCoupon = async () => {
      const couponList = $scope.couponList.available.filter(({ selected }) => selected);

      if (!couponList.length) {
        commonSVC.showMessage('선택된 쿠폰이 없습니다.');

        return false;
      }

      const confirm = await commonSVC.showConfirm(
        '선택 쿠폰 사용',
        `쿠폰 ${$scope.available.searchData.selectCount}개가 선택되었습니다\n선택한 쿠폰을 적용하시겠습니까?`,
      );

      if (confirm) {
        $timeout(function () {
          $scope.waitBtnDisableFlag = true;
        });

        try {
          const re = await payModel.useCoupon({ couponList });

          if (re.status === 200) {
            commonSVC.showToaster('success', '', '쿠폰 적용 성공');
          } else {
            commonSVC.showToaster('error', '', '쿠폰 적용 실패');
          }
        } catch (err) {
          commonSVC.showToaster('error', '', '쿠폰 적용 실패');
        }

        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        });

        getCouponList();
      }
    };

    /**
     * 프로모션 코드 등록
     * 2019-06-17 rony
     */
    $scope.setPromotion = function () {
      const modal = commonSVC.openModal('sm', '', 'addPromoCodeCtrl', 'views/pay/modals/addPromoCode.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          location.reload();
        }
      });
    };

    /**
     * 탭 변경
     */
    $scope.changeTab = tab => {
      $scope.tab = tab;
    };

    /**
     * 창 닫기
     */
    $scope.close = () => {
      $uibModalInstance.close('cancel');
    };

    payModel.getSolInfo(userInfo.user.m_no, function (status, data) {
      if (status === 'success') {
        solutionProductInfo = data.results.solutionProductInfo;
      }
      getCouponList();
    });
  });
