'use strict';

angular.module('gmpApp')
  .controller('CalculateStatePerProdListCtrl', function (
    // Common
    $rootScope, $scope, settings, $filter, localStorageService, userInfo
    // SVC
    , commonSVC, stateSVC
    // List
    , systemList, channelList, memoTemplateList
    // Model
    , commonModel
  ) {
    const isCalStatePerProd = $rootScope.userProfileCheck('sol_ser', 'cal_state_per_prod', 'like') && $rootScope.user_profile.auth_type !== '배송처';
    let orderList = {};
    let orderby = 'wdate asc';

    // 주문/금액 분할 모듈 사용안하는 계정이거나, 배송처 계정이면 권한체크 후 탭 닫기
    if (!isCalStatePerProd) {
      commonSVC.showMessage('권한이 없는 사용자입니다.');
      $rootScope.$emit('$closeTab', 'calculate_state_per_prod_list');
    }

    // 초기탭은 전체 탭
    $scope.tabIndex = 0;

    // 메모 템플릿 리스트
    $scope.memoTemplateList = memoTemplateList.data.results || [];

    const useChannelList = Array.from(angular.copy(channelList.data.results)).concat({ shop_name: '직접입력', shop_cd: 'A000' });

    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach(useChannelList, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    // 주문상태 상세검색 리스트
    const orderStatusList = [
      { status_name: '신규주문', value: '신규주문' },
      { status_name: '주문보류', value: '주문보류' },
      { status_name: '출고대기', value: '출고대기' },
      { status_name: '출고보류', value: '출고보류' },
      { status_name: '운송장출력', value: '운송장출력' },
      { status_name: '출고완료', value: '출고완료' },
      { status_name: '배송중', value: '배송중' },
      { status_name: '배송완료', value: '배송완료' },
      { status_name: '구매결정', value: '구매결정' },
      { status_name: '클레임', value: '클레임' },
    ];
    /* 검색 및 버튼 관련 변수 */
    const searchTemplate = {
      searchForm: {
        search_key: '__ALL__',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        shop_id: '',
        shop_cd: '',
        page: 'cal_state_per_prod_list',
        sku_type: 'all',
        multi_search_word: '', //멀티서치워드
        multi_type: 'sku_cd', //멀티서치 타입
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectType: true,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
        selectCount: 0,      // 검색전체 건수
        selectBundleCnt: 0,  // 선택 묶음건수
        bundleCnt: 0,        // 묶음건수
        totalCount: 0,       // 검색전체 건수
        showCount: 100,      // 페이지당 보여줄 갯수
        search_key_items: [  // 검색영역 키값
          { label: '전체', value: '__ALL__' },
          { label: 'SKU코드', value: 'sku_cd' },
          { label: '세트코드', value: 'set_cd' },
          { label: '주문번호', value: 'shop_ord_no' },
        ],
        search_date_type: [
          { label: '주문 수집일', value: 'wdate' },
          { label: '주문일', value: 'ord_time' },
          { label: '결제 완료일', value: 'pay_time' },
        ],
        search_multi_items: [
          { label: 'SKU코드', value: 'sku_cd' },
          { label: '세트코드', value: 'set_cd' },
          { label: '쇼핑몰 상품코드', value: 'shop_sale_no' },
          { label: '판매자관리코드', value: 'c_sale_cd' },
          { label: '묶음번호', value: 'bundle_no' },
          { label: '운송장번호', value: 'invoice_no' },
          { label: '쇼핑몰(계정)', value: 'shop' },
          { label: '주문번호', value: 'shop_ord_no' },
        ]
      },
      searchDetail: [
        {
          // 주문상태 선택
          title: '주문 상태',
          search_name: 'status',
          item_list: orderStatusList,
          item_key: 'status_name',
          item_value: 'value',
          select_value: '',
        },
        {
          // 채널 선택
          title: '쇼핑몰 선택',
          search_name: 'shop_cd',
          item_list: commonSVC.getSiteList(useChannelList),
          item_key: 'shop_name',
          item_sub_key: 'shop_name_kr',
          item_value: 'shop_cd',
          select_value: '',
          add_class: 'select-search'
        },
        {
          // 채널 계정 선택
          title: '계정 선택',
          search_name: 'shop_id',
          item_list: commonSVC.getSiteIdList(useChannelList),
          item_key: 'shop_id',
          item_value: 'search_shop_id',
          select_value: '',
          add_class: 'select-search',
          filter: function(option) {
            return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
          }
        },
      ],
      searchDetailMulti: [
        {
          title: '쇼핑몰(계정) 선택',
          search_name: 'multi_shop_id',
          item_list: angular.copy(useChannelList).sort(function(prev, curr) {
            return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
          }),
          item_key: 'shop_id',
          item_value: 'shop_id',
          search_word: ''
        }
      ]
    };

    function setSearch(search) {
      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchDetailMulti = angular.copy(search.searchDetailMulti);
    }

    function resetSearch() {
      const showCount = $scope.searchData?.showCount;
      const search = angular.copy(searchTemplate);

      if (showCount) {
        search.searchData.showCount = showCount;
      }
    }

    setSearch(searchTemplate);
    $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
      $scope.$apply();
    }, 200));

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true, null);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      },
    };

    $scope.grid = {};
    $scope.grid.methods = {};

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      resetSearch();
      setSearch(searchTemplate);

      $scope.searchDo(true, true);
    };

    $scope.grid.options = {
      page: 'state_per_prod_list',
      pinningColumns: ['widget'],
      defaultSortingColumns: ['wdate'],
      notMovingColumns: [],
      alignCenterColumns: ['widget', 'total_out_qty', 'ord_status', 'sales_per', 'exchange_ord_yn'],
      alignRightColumns: ['estimate_sales', 'shop_cost_price', 'shop_supply_price', 'discount_amt', 'pay_amt', 'sales', 'avg_sales'],
      alignLeftColumns: ['set_cd', 'sku_cd', 'shop_sale_no', 'order_name'],
      notSortingColumns: ['widget', 'shop_name', 'set_cd', 'sku_cd', 'shop_sale_no', 'order_name', 'prod_name', 'shop_cost_price', 'shop_supply_price', 'discount_amt', 'pay_amt', 'sales', 'sales_per', 'total_out_qty', 'estimate_sales', 'ord_status', 'pay_time', 'shop_ord_no', 'shop_sale_name', 'shop_opt_name', 'c_sale_cd', 'ship_plan_date', 'depot_name', 'exchange_ord_yn', 'to_name', 'ship_msg', 'to_addr', 'invoice_no', 'ship_cost', 'order_tel', 'order_htel', 'to_tel', 'to_htel', 'supp_name', 'avg_sales'],
      notVisibleColumns: ['shop_cost_price', 'shop_supply_price', 'discount_amt', 'ord_time', 'pay_time', 'ship_plan_date', 'depot_name', 'bundle_no', 'exchange_ord_yn', 'to_name', 'ship_msg', 'to_addr', 'invoice_no', 'ship_cost', 'to_tel', 'to_htel', 'order_tel', 'order_htel', 'supp_name'],
      bundleOptions: {
        bundleCountKey: 'selectBundleCnt',
        bundleDataKey: 'uniq',
        bundleUniqKey: 'sku_cd'
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/calculate/state-per-prod-list`,
        requestWillAction: function(data) {
          data = angular.merge({}, data, $scope.searchForm);
          orderby = data.orderby || orderby;

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotalCount;

          // 합포장 갯수
          $scope.bundleCnt = result.recordsTotal;
          orderList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            return `<button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="$event.preventDefault(); grid.appScope.leftTabAction(2)">메모</button>`;
          }

        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '직접입력';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${(row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd) ?? row.shop_cd}.png" style="width: 50px;">` }
                ${shop_info[1]}
              </span>`;
            }

            return img;
          }
        },
        {
          key: 'set_cd',
          title: '세트 코드',
          width: 100,
          template: function(row) {
            return row.set_cd || '';
          }
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 100,
          template: function(row) {
            return row.sku_cd || '-';
          }
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 140,
          template: function (row) {
            if (row.shop_cd === 'A011') {
              row.shop_sale_no = row.misc16;
            }

            return row.shop_sale_no;
          }
        },
        {
          key: 'order_name',
          title: '주문자(ID)',
          width: 150,
          template: function (row) {
            let return_value = row.order_name;

            if (row.order_id) {
              return_value += `(${row.order_id})`;
            }

            return return_value;
          }
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          requireStock: true,
          width: 130,
          notCompile: true,
        },
        {
          key: 'shop_cost_price',
          title: '원가',
          tooltip: '주문에 매칭된 SKU상품에 저장된 원가 정보입니다. (입력된 원가*총출고수량)',
          width: 100,
          template: function(row) {
            const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(row.shop_cost_price, '', 0);

            return value;
          }
        },
        {
          key: 'shop_supply_price',
          title: '공급가',
          width: 100,
          template: function(row) {
            const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(row.shop_supply_price, '', 0);

            return value;
          }
        },
        {
          key: 'discount_amt',
          title: '할인금액',
          tooltip: '쇼핑몰에서 수집한 상품의 할인금액(해당 값을 제공하지 않는 경우 공란)',
          width: 100,
          template: function(row) {
            let value = '';

            if (row.discount_amt !== null) {
              value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.discount_amt, '', 0);
            }

            return value;
          }
        },
        {
          key: 'total_out_qty',
          title: '총출고수량',
          width: 100,
          template: function(row) {
            const value = !row.total_out_qty || ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.total_out_qty;

            return value;
          }
        },
        {
          key: 'pay_amt',
          title: '실결제금액',
          tooltip: '쇼핑몰에서 수집한 상품의 실결제금액(해당 값을 제공하지 않는 경우 공란)',
          width: 100,
          template: function(row) {
            let value = '';

            if (row.pay_amt !== null) {
              value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.pay_amt, '', 0);
            }

            return value;
          }
        },
        {
          key: 'sales',
          title: '판매금액',
          template: function(row) {
            const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(row.sales, '', 0);

            return value;
          },
          width: 120,
        },
        {
          key: 'sales_per',
          title: '금액 비중',
          tooltip: '세트상품 가격에서 각 SKU상품 가격이 차지하는 비중(직접 입력한 값이 없는 경우 공란)',
          template: function(row) {
            return (row.sales_per && row.sales_per !== 'null') ? `${row.sales_per}%` : '';
          },
          width: 100,
        },
        {
          key: 'estimate_sales',
          title: '추정 매출액',
          tooltip: `-단품의 경우, 주문의 실결제금액
- 세트상품의 경우, 주문의 실결제금액에 금액 비중을 적용한 금액(소수점 절삭)
- 실결제금액이 없는 경우, 판매금액을 기준으로 계산합니다.(소수점 절삭)`,
          template: function(row) {
            return !['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) ? $filter('currency')(Math.floor(row.estimate_sales), '', 0) : 0;
          },
          width: 100,
        },
        {
          key: 'avg_sales',
          title: '평균 매출액',
          tooltip: `- 실결제금액이 있는 경우, 실결제금액을 세트상품을 구성하는 SKU 수로 나눈 금액
- 실결제금액이 없는 경우, 판매금액을 세트상품을 구성하는 SKU 수로 나눈 금액`,
          template: function(row) {
            return !['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) ? $filter('currency')(Math.floor(row.avg_sales), '', 0) : 0;
          },
          width: 100,
        },
        {
          key: 'ord_status',
          title: '주문상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            return $filter('statusColor')(row.ord_status);
          }
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          width: 150,
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_time',
          title: '주문일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'pay_time',
          title: '결제완료일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 300,
          template: function(row) { //이미지+상품명
            return $filter('whiteSpace')(row.shop_sale_name);
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 150
        },
        {
          key: 'ship_plan_date',
          title: '발송예정일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 150
        },
        {
          key: 'exchange_ord_yn',
          title: '교환여부',
          width: 80,
          template: function(row) {
            return row.exchange_ord_yn ? '<span class="text-success-600">●</span>' : '<span class="text-grey-100">●</span>';
          }
        },
        {
          key: 'to_name',
          title: '수령자명',
          width: 100
        },
        {
          key: 'ship_msg',
          title: '배송메세지',
          width: 200
        },
        {
          key: 'to_addr',
          title: '주소',
          width: 300,
          notCompile: true,
          template: function(row) {
            let return_value = row.to_addr1 || '';

            if (return_value.charAt(0) != '[' && row.to_zipcd && return_value) {
              return_value = `[${row.to_zipcd}]${return_value}`;
            }
            if (row.to_addr2) {
              return_value += row.to_addr2;
            }

            return return_value;
          }
        },
        {
          key: 'invoice_no',
          title: '운송장번호',
          width: 150,
        },
        {
          key: 'ship_cost',
          title: '배송비',
          width: 100,
          notCompile: true,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(row.ship_cost, '', 0);
          }
        },
        {
          key: 'order_tel',
          title: '주문자 전화번호',
          width: 150
        },
        {
          key: 'order_htel',
          title: '주문자 휴대폰번호',
          width: 150
        },
        {
          key: 'to_tel',
          title: '수령자 전화번호',
          width: 150
        },
        {
          key: 'to_htel',
          title: '수령자 휴대폰번호',
          width: 150
        },
        {
          key: 'supp_name',
          title: '매입처',
          width: 130,
          notCompile: true,
        },
      ]
    };

    /**
     * 상품 기준 변경
     */
    $scope.prodViewTypeChange = function(index) {
      $scope.tabIndex = index;
      let skuType = 'all';

      switch (index) {
        case 0:
          skuType = 'all';
          break;
        case 1:
          skuType = 'single';
          break;
        case 2:
          skuType = 'set';
          break;
      }

      if (Object.keys($scope.grid.methods).length) {

        $scope.searchForm.sku_type = skuType;
        $scope.searchDo(true, true);
        $scope.grid.methods.setShowCountByGridPageSize();
      }
    };

    /**
       * 엑셀 다운로드
       */
    $scope.excelDown = async () => {
      const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
      const uniqs = $scope.grid.methods.selectedData('uniq');
      const resolve = {};

      resolve.data = {
        excelFieldList: angular.copy(stateSVC.statePerProdExcel),
        title: '',
        url: '/app/calculate/state-per-prod-excel-down',
        searchForm: { ...$scope.searchForm, ...$scope.pageData, uniqs, orderby },
        page: 'cal_state_per_prod_list',
        templateType: 'cal_state',
        visibleTable: visibleTable,
        isGlobal: false,
      };

      if (!uniqs.length) {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
        resolve.data.type = 'all';
      } else {
        const uniq = $scope.tabActive === 0 ? $scope.grid.methods.selectedData('uniq') : [];

        resolve.data.type = 'select';
        resolve.data.uniq = uniq;
        resolve.data.count = $scope.grid.methods.selectedData('uniq').length;
        resolve.data.isAll = false;
      }

      commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
    };

    /**
     * 메모 첫 변경시 메뉴 버튼 색상 변경 함수
     */
    $scope.setMemoBtnActive = function (key, value, off) {
      const index = _.findIndex(orderList, (obj) => {
        return obj[key] == value;
      });

      orderList[index].memo_yn = off ? 0 : 1;
      $scope.grid.methods.reDraw();
    };

    /**
     * 메모 완료시 메뉴 버튼 색상 변경 함수
     */
    $scope.setMemoCompleteActive = function (key, value, off) {
      const index = _.findIndex(orderList, (obj) => {
        return obj[key] == value;
      });

      orderList[index].memo_complete_yn = off ? 0 : 1;
      $scope.grid.methods.reDraw();
    };
  });

