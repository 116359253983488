/**
 * Created by ally on 2017. 6. 8..
 */

'use strict';

angular.module('gmpApp')
  .controller('EtcExcelDownCtrl', function ($rootScope, $scope, data, $uibModalInstance, commonSVC, shipmentSVC, columnSVC, commonModel, systemModel, columnModel, $timeout, gettextCatalog) {
    let apiUrl = data.url;
    let modal_closed = false;

    if (data.progress) {
      $scope.percent = '0%';
      $scope.isFail = false;
    }

    $scope.page = data.page;
    $scope.title = data.title;
    $scope.description = data.description;
    $scope.count = data.count;
    $scope.tabIndex = data.tabIndex;
    $scope.visibleTable = data.visibleTable;
    $scope.type = data.type;
    $scope.user_profile = $rootScope.user_profile;
    $scope.masterProductCnt = Object.keys(_.groupBy(data.select_list, 'sale_no')).length;
    $scope.masterExcelType = 'basic';
    $scope.flag = {
      excel_password_yn: 0
    };

    $scope.excelPassword = ['order', 'payment', 'unstoring', 'delivery', 'as', 'integrated', 'claim'].includes($scope.page); // 엑셀 다운로드 시 비밀번호 설정
    $scope.isDouzone = data.isDouzone; // 더존 양식 여부

    // G마켓물류주문 전용
    $scope.ebaydepot = {
      yn: data.ebaydepotYn || false,
      down: false
    };

    // 엑셀 다운로드 필드 활성화된 필드로 셋팅
    angular.forEach(data.excelFieldList, function (value, key) {
      angular.forEach($scope.visibleTable, function (visible) {
        data.excelFieldList[key].select = false;
        // 툴팁때문에 선택안돼서 툴팁제거
        visible.header = visible.header.replace(/<i.*\/i>/, '');

        if (value.header == visible.header || value.noneHeader) {
          data.excelFieldList[key].select = true;
        }
      });
    });
    /**
     * 2018-05-03 roy
     * SKU상품 배송처, 매입처 사용함만 다운로드할 수 있도록 설정
     */
    $scope.etcField = {
      useDepot: 1,
      useSupp: 1
    };
    const page = data.page;

    // 시스템 환경설정 구하기
    let systemList;

    systemModel.load().then(function (result) {
      systemList = result;
    });

    /**
     * 추가컬럼 항목 세팅
     * @param {Array} addColList 추가항목 리스트
     */
    function addColSet(addColList) {
      let allColIdx = $scope.fieldList.length - 1;

      addColList.forEach(col => {
        if (!$scope.fieldList.find(({ key }) => key === col)) {
          // 커스텀 컬럼이 있는 경우 추가
          $scope.fieldList.push({ header: columnSVC.setColumnName(col, 'GET'), key: col, key_settings: '0', unchangable: true, width: 30, index: allColIdx });
          allColIdx++;
        }
      });
    }

    function init() {
      // 출고가능여부 출력 field name 값 변경으로 인해 로컬스토리지 저장명 수정. 2018-03-16
      // var settings = JSON.parse(localStorage.getItem('gmp_excel_download_'+page));
      // 로컬 스토리지에 저장된 선택 항목
      const settings = JSON.parse(localStorage.getItem(`gmp_excel_download_${page}_1`));
      // 엑셀다운로드 가능한 항목
      $scope.fieldList = data.excelFieldList;

      // LG 대리점 계정인 경우 커스텀컬럼도 필드에 추가 (주문만)
      if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no && ['agency', 'claim', 'delivery', 'integrated', 'payment', 'unstoring', 'order'].includes($scope.page)) {
        columnModel.addColumnList({ addcol_type: 'all' })
          .then((res) => {
            if (res.data && res.data.results && res.data.results.length) {
              addColSet(res.data.results.map(({ col_name }) => col_name));
            }
          });
      }

      // 기본 엑셀다운로드 가능한 항목에서 로컬 스토리지에 저장된 선택 항목이 있는경우 select 값만 업데이트
      if (settings) {
        _.each($scope.fieldList, function (v) {
          const ss = _.find(settings.field, { key: v.key });

          if (ss) {
            v.select = ss.select;
          }
        });
      }

      $scope.option = {
        fileExt: !settings || settings.ext === 'xls' ? 'xlsx' : settings.ext
      };
      $scope.allSelected = _.findIndex($scope.fieldList, { select: false }) === -1;

      // 매칭규칙 엑셀 다운로드 시 모든 필드 활성화된 상태로 셋팅
      if (['matchingRule'].includes(page)) {
        _.forEach($scope.fieldList, function (item) {
          item.select = true;
        });
        $scope.allSelected = true;
      }

      // 더존양식일 경우 값 세팅
      if ($scope.isDouzone) {
        $scope.setEbaydepot('ebaydepotInosub');
      }
    }

    $scope.viewPriceTable = function () {
      const viewStr = `<div class="sweet-alert-body" style="padding: 0 !important;">
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">방송통신위원회고시 ‘개인정보의 기술적/관리적 보호조치 기준’ 제6조 4항</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 6조(개인정보의 암호화)</span><br>
        <span style="font-size: small;">④ 정보통신서비스 제공자등은 이용자의 개인정보를 컴퓨터, 모바일 기기 및 보조저장매체 등에 저장 할 때에는 이를 암호화해야 한다.</span><br><br><br>
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">개인정보의 기술적/관리적 보호조치 기준</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 4조(접근통제)</span><br>
        <span style="font-size: small;">⑧ 정보통신서비스 제공자등은 개인정보취급자를 대상으로 다음 각 호의 사항을 포함하는 비밀번호 작성규칙을 수립하고, 이를 적용 운용하여야 한다.</span><br>
        <span style="font-size: small;">1. 영문, 숫자, 특수문자 중 2종류 이상을 조합하여 최소 10자리 이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 구성</span><br><br><br>
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">개인정보의 안전성 확보조치 기준</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 8조(접속기록의 보관 및 점검)</span><br>
        <span style="font-size: small;">② 개인정보처리자는 개인정보의 오·남용, 분실·도난·유출·위조·변조 또는 훼손 등에 대응하기 위하여 개인정보처리시스템의 접속기록 등을 월 1회 이상 점검하여야 한다.´</span><br>
        <span style="font-size: small;">특히 개인정보를 다운로드한 것이 발견되었을 경우에는 내부관리 계획으로 정하는 바에 따라 그 사유를 반드시 확인하여야 한다.</span><br>
        </div>`;
      commonSVC.showMessageHtml('관련 법적 고지 안내<hr style="margin-bottom: 0;">', viewStr);
    };

    /**
     * 비밀번호 변경사항이 있을때 유효성 검사 초기화.
     */
    $scope.$watch('password.new', function () {
      $scope.newOverlap = null;
      $scope.overlapResult = null;
      $scope.confirmOverlap = null;

      //비밀번호 정규식
      const regExp = /^(?=.*[a-zA-Z])(?=.*[,.<>/?~()_[\]{}|;:!@#$%^&*+=-])(?=.*[0-9]).{8,}$/;
      const validPassword = regExp.test($scope.password.new);
      if (!validPassword && $scope.password.new !== '') {
        $scope.newOverlap = 'n';
        $scope.alerts = { msg: '비밀번호 규칙에 맞게 다시 설정해 주세요.' };
      }
    });

    $scope.$watch('password.confirm', function () {
      $scope.overlapResult = null;
      $scope.confirmOverlap = null;

      if ($scope.password.confirm !== '') {
        if ($scope.password.confirm === $scope.password.new) {
          $scope.confirmOverlap = 'y';
          $scope.alerts = { msg: '비밀번호가 일치합니다.' };
        } else if ($scope.password.confirm !== $scope.password.new) {
          $scope.confirmOverlap = 'n';
          $scope.alerts = { msg: '비밀번호가 일치하지 않습니다.' };

          return false;
        }
      }
    });

    //비밀번호 초기값 변수
    $scope.password = {
      new: '',
      confirm: '',
      reason: ''
    };

    // localStroage에 저장된 사유 불러오기
    if (localStorage.getItem('PASSWORD_REASON')) {
      $scope.password.reason = localStorage.getItem('PASSWORD_REASON').replace(/"/g, '');
    }
    // localStroage에 엑셀 암호화 사용여부 체크박스 값 불러오기
    if (localStorage.getItem('EXCEL_PASSWORD_YN')) {
      $scope.flag.excel_password_yn = JSON.parse(localStorage.getItem('EXCEL_PASSWORD_YN'));
    }

    /**
    * 설정값 로컬스토리지에 저장
    * */
    function save() {
      if (page) {
        const val = {
          ext: $scope.option.fileExt,
          field: $scope.fieldList
        };

        // 출고가능여부 출력 field name 값 변경으로 인해 로컬스토리지 저장명 수정. 2018-03-16
        // localStorage.setItem('gmp_excel_download_'+page, JSON.stringify(val));
        localStorage.setItem(`gmp_excel_download_${page}_1`, JSON.stringify(val));
      }
    }

    /**
     * 전체선택 버튼 클릭
     * */
    $scope.allSelectToggle = function () {
      // G마켓물류서비스 발송요청 양식으로 다운로드 체크시 바꿀수없음
      if ($scope.ebaydepot.down || $scope.isDouzone) {
        commonSVC.showMessage('주의', '더존 양식으로 다운로드시 다운로드할 필드를 수정할수 없습니다.');

        return false;
      }

      if ($scope.allSelected) {
        _.forEach($scope.fieldList, function (item) {
          item.select = false;
        });
        $scope.allSelected = false;
      } else {
        _.forEach($scope.fieldList, function (item) {
          item.select = true;
        });
        $scope.allSelected = true;
      }
    };

    /**
     * 버튼 클릭
     * */
    $scope.selectToggle = function (item) {
      // G마켓물류서비스 발송요청 양식으로 다운로드 체크시 바꿀수없음
      if ($scope.ebaydepot.down || $scope.isDouzone) {
        commonSVC.showMessage('주의', '더존 양식으로 다운로드시 다운로드할 필드를 수정할수 없습니다.');

        return false;
      }

      item.select = !item.select;
      $scope.allSelected = _.findIndex($scope.fieldList, { select: false }) === -1;
    };

    /**
     * G마켓 물류서비스 발송요청 양식으로 다운로드
     */
    $scope.setEbaydepot = function (type) {
      if ($scope.ebaydepot.down || $scope.isDouzone) {
        $scope.fieldList = angular.copy(shipmentSVC.excelFieldList[type]);
        $scope.option.fileExt = 'xlsx';
        $scope.allSelected = true;
      } else {
        init();
      }
    };

    /**
     * 다운로드 버튼 비활성화 여부 확인
     */
    $scope.checkBtnDisable = () => {
      return $scope.fieldList?.length && $scope.fieldList.every(({ select }) => !select);
    };

    /**
     * 다운로드
     * */
    $scope.download = function () {
      $scope.alerts = {};

      if ($scope.excelPassword) {
        if ($scope.flag.excel_password_yn === 1) {
          //비밀번호 유효성 체크
          if ($scope.newOverlap === 'n') {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('비밀번호 규칙에 맞게 다시 설정해 주세요.'));
            $scope.alerts = { msg: '비밀번호 규칙에 맞게 다시 설정해 주세요.' };
            $timeout(function () { $scope.waitBtnDisableFlag = false; });

            return false;
          }
          if ($scope.password.new !== $scope.password.confirm) {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('비밀번호가 일치하지 않습니다.'));
            $scope.confirmOverlap = 'n';
            $scope.alerts = { msg: '비밀번호가 일치하지 않습니다.' };
            $timeout(function () { $scope.waitBtnDisableFlag = false; });

            return false;
          }
          if (!$scope.password.new || !$scope.password.confirm || !$scope.password.reason) {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('필수 입력 항목을 확인해주세요.'));
            $scope.overlapResult = 'blank';
            $scope.alerts = { msg: '필수 입력 항목입니다.' };
            $timeout(function () { $scope.waitBtnDisableFlag = false; });

            return false;
          }
        }

        /**
         * 사유 로컬스토리지에 저장
         */
        localStorage.setItem('PASSWORD_REASON', JSON.stringify($scope.password.reason));
        localStorage.setItem('EXCEL_PASSWORD_YN', JSON.stringify($scope.flag.excel_password_yn));
      }

      // 출력될 데이터가 없는 경우 에러.
      if ($scope.count < 1) {
        commonSVC.showMessage('다운로드 받을 내역이 없습니다.');

        return false;
      }

      const downList = angular.copy(_.filter($scope.fieldList, 'select'));

      if ($scope.fieldList.length > 0 && downList.length === 0) {
        commonSVC.showMessage('다운받을 항목를 선택해주세요.');

        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else {
        let params = {
          down_list: downList,
          file_ext: $scope.option.fileExt,
          cate_id: data.cateId,
          sheet_name: data.sheetName || $scope.title,
          file_name: $scope.title,
          is_all: data.isAll,
          type: data.type,
          is_ebaydepot: $scope.ebaydepot.down,
          isDouzone: $scope.isDouzone,
          is_super: data.isSuper,
          sol_stock: $scope.user_profile.sol_stock,
          etcField: $scope.etcField,
          selectList: data.select_list,
          password: $scope.password.new,
          reason: $scope.password.reason,
          excel_password_yn: $scope.flag.excel_password_yn
        };

        if (data.searchForm) {
          params = angular.merge(params, data.searchForm);
        }

        // 일반, 단일 온라인 상품인지 구분
        switch ($scope.tabIndex) {
          case 0: // 일반 상품
            params.onlineType = '일반';
            break;
          case 1: // 단일 상품
            params.onlineType = '단일';
            break;
          case 2:
            params.onlineType = '물류';
            break;
        }

        // 선택 다운로드
        if (!data.isAll) {
          if (data.uniq) {
            params.uniq = data.uniq;
          }
          if (data.number) {
            params.number = data.number;
          }
          if (data.sku_code) {
            params.sku_code = data.sku_code;
          }
          if (data.select_list) {
            params.select_list = data.select_list;
          }
          if (data.list) {
            params.list = data.list;
          }
        }

        if (data.orderby) {
          params.orderby = data.orderby;
        }

        if ($scope.type === 'master' && $scope.masterExcelType === 'masterCode') {
          apiUrl = '/app/product/online/master-code-excel-down';
        }
        // 주문, 문의, 정산 쪽은 별칭사용여부 파라미터로 넘김 사용함
        if (apiUrl == '/app/order/excelDown' || apiUrl == '/app/inquiries/excelDown' || apiUrl == '/app/memos/etc/excelDown') {
          params.shop_id_view_type = systemList.data.shop_id_view_type;
        }

        if ($scope.percent) {
          $('#ext-select').css({ display: 'none' });
          $('.progress-bar-dir').css({ display: 'inline-block' });
        }

        // 2000건당 1분
        if (data.isAll) {
          params.timeout = Math.ceil(parseInt(data.count) / 2000) * 60 * 1000;
        }

        if (apiUrl === '/app/stock/inout/excel-down') {
          params.timeout = 300000;
        }

        if (!params.orderby && data.title.includes('쇼핑몰상품')) {
          commonSVC.showToaster('error', '실패', '리스트를 정렬 후 다운로드를 시도해주세요.');
        }

        commonModel.excelDownload(apiUrl, params, function (state, result) {
          if (state == 'success') {

            if (data.progress) {
              $scope.isFinish = true;
              $scope.percent = '100%';
            }

            const newWin = window.open(result.url);

            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
            } else {
              commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
            }
            // window.open(result.url,'');
            if ($scope.fieldList.length > 0 && !$scope.ebaydepot.yn) {
              save();
            }

            modal_closed = true;
            $uibModalInstance.close('success');
          } else {
            if ($scope.percent) {
              $('#ext-select').css({ display: 'block' });
              $('.progress-bar-dir').css({ display: 'none' });
            }

            commonSVC.showToaster('error', '실패', result.data?.error || '엑셀 다운로드에 실패하였습니다.');

            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          }
        });
      }
    };

    /**
     * 엑셀 다운로드 완료시 푸시 알람.
     */
    $scope.$on('excel_down_complete', function (event, excel_url) {
      if (!modal_closed) {
        const newWin = window.open(excel_url);

        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
          commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
        } else {
          commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
        }
        if ($scope.fieldList.length > 0 && !$scope.ebaydepot.yn) {
          save();
        }

        modal_closed = true;
        $uibModalInstance.close('success');
      }
    });

    /**
     * 모달창 닫기
     * */
    $scope.cancel = function () {
      modal_closed = true;
      $uibModalInstance.dismiss('cancel');
    };

    init();
  });