'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-P060-Ctrl', function ($scope, $rootScope, $timeout, data, systemList, templateList, $uibModalInstance, commonSVC, shopAccountModel, onlineProductModel, commonModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.pa5_job_data = {
      get_metafields: {
        load: false,
        data: []
      }
    };

    $scope.metafields = $scope.row.set_info.key_metafields || [];

    // pa5 작업 호출
    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
          $('.box-loading').addClass('hidden'); //로딩중
        } else {
          $('.box-loading').addClass('hidden'); //로딩중
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const setCategoryDetail = () => {
      // 상품 등록 시 카테고리 || 상품 수정 시 카테고리 코드
      let shop_cate_data =
      $scope.data?.shop_cate_no_add_info?.category_code ||
      $scope.data.site_list.find(site => site.shop_cd === 'P060')?.sol_cate_shop_add_info?.category_code ||
      $scope.data?.sol_cate_shop_add_info.category_codes ||
      $scope.data?.sol_cate_shop_add_info?.category_code ||
      $scope.data.master_prod?.match_cate_code;

      if ($scope.data.shop_cate_cd) { // 개별 카테고리 코드
        if (typeof $scope.data.shop_cate_no_add_info === 'string') {
          shop_cate_data = JSON.parse($scope.data.shop_cate_no_add_info)?.category_code;
        } else if (
          typeof $scope.data.shop_cate_no_add_info === 'object' &&
          $scope.data.shop_cate_no_add_info !== null
        ) {
          shop_cate_data = $scope.data.shop_cate_no_add_info?.category_code || shop_cate_data;
        }
      }

      return shop_cate_data.split('_').pop();
    };

    $scope.addToKeyMetafields = (selected_metafield_key, selected_value_id) => {
      const selected_metafield = $scope.pa5_job_data.get_metafields.data.find(metafield => metafield.key === selected_metafield_key);

      if (selected_metafield && selected_value_id) {
        // 이미 추가된 메타필드인지 확인
        const exist_metafield = $scope.metafields.find(metafield => metafield.key === selected_metafield.key);
        if (exist_metafield) {
          const exist_value = exist_metafield.values.find(value => value.id === selected_value_id);
          if (!exist_value) {
            exist_metafield.values.push({ id: selected_value_id, value: selected_metafield.values.find(value => value.id === selected_value_id).value });
          }
        } else {
          // 추가된 메타필드가 아니라면 새로 추가
          $scope.metafields.push({
            key: selected_metafield.key,
            display_name: selected_metafield.display_name,
            namespace: selected_metafield.namespace,
            category_codes: selected_metafield.category_codes,
            values: [{ id: selected_value_id, value: selected_metafield.values.find(value => value.id === selected_value_id).value }],
          });
        }
      }
    };

    $scope.deleteMetafieldValue = (selected_metafield_key, selected_value_id) => {
      const selected_metafield = $scope.pa5_job_data.get_metafields.data.find(metafield => metafield.key === selected_metafield_key);

      if (selected_metafield && selected_value_id) {
        // 이미 추가된 메타필드인지 확인 후 삭제
        const exist_metafield = $scope.metafields.find(metafield => metafield.key === selected_metafield.key);
        if (exist_metafield) {
          const index = exist_metafield.values.findIndex(value => value.id === selected_value_id);

          if (index !== -1) {
            exist_metafield.values.splice(index, 1);
          }
        }
      }
    };

    const init = async () => {
      // 카테고리 데이터 세팅
      $scope.category_data = setCategoryDetail();

      try {
        // 설정된 카테고리오 기존 카테고리 메타필드의 카테고리가 다르면 초기화
        if ($scope.metafields && $scope.metafields[0]?.category_codes[0] !== $scope.category_data) {
          $scope.metafields = [];
          $scope.row.set_info.key_metafields = [];
        }
        await run_pa5_jobs('get_metafields', '카테고리 메타필드 조회', { id: $scope.category_data });
      } catch (e) {
        commonSVC.showMessage('실패', '카테고리 메타필드 정보 로드를 실패했습니다.');
      }
    };

    // 수량별 할인단가 템플릿 세팅
    $scope.setPricePerQuantityTable = () => {
      if ($scope.row.set_info.key_amtSectionTblStr) {
        const value_arr = $scope.row.set_info.key_amtSectionTblStr.split('|');
        for (const range of value_arr) {
          const [quantity, fee] = range.split('+');
          $scope.unit_price_per_quntity_q.push(quantity);
          $scope.unit_price_per_quntity_p.push(fee);
        }
      } else {
        $scope.unit_price_per_quntity_q = ['1', ''];
        $scope.unit_price_per_quntity_p = ['', ''];
      }
    };
    // 수량별 차등 조건 추가
    $scope.add = function () {
      if ($scope.count_unit_price_per_quntity >= 5) {

        return false;
      }

      $scope.count_unit_price_per_quntity++;
      $scope.unit_price_per_quntity_p.push(null);
      $scope.unit_price_per_quntity_q.push(null);
    };

    // 수량별 차등 조건 삭제
    $scope.delete = function () {
      if ($scope.count_unit_price_per_quntity <= 2) {
        alert('더 이상 삭제 불가능합니다.');

        return false;
      }
      $scope.unit_price_per_quntity_p.pop();
      $scope.unit_price_per_quntity_q.pop();
      $scope.count_unit_price_per_quntity--;
    };

    $scope.count_unit_price_per_quntity = 2; //수량별할인단가 입력폼 카운터(저장된 배열 길이를 초깃값으로)
    $scope.unit_price_per_quntity_q = [], // 수량별할인단가(수량) 저장 배열
    $scope.unit_price_per_quntity_p = [], // 수량별할인단가(단가) 저장 배열
    $scope.setPricePerQuantityTable();
    $scope.count_unit_price_per_quntity = $scope.unit_price_per_quntity_p.length;

    $scope.ok = async type => {
      $scope.row.set_info.key_metafields = $scope.metafields;

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      // 구매수량별 단가 전송 세팅
      if ($scope.row.set_info.key_amtSectionUseN === '1') {
        const amtSectionTblStr = [];
        for (let i = 0; i < $scope.unit_price_per_quntity_q.length; i++) {
          if (+$scope.unit_price_per_quntity_q[i] && +$scope.unit_price_per_quntity_p[i]) {
            amtSectionTblStr.push($scope.unit_price_per_quntity_q[i].toString() + '+' + $scope.unit_price_per_quntity_p[i].toString());
          }
        }
        $scope.row.set_info.key_amtSectionTblStr = amtSectionTblStr.join('|');
        $scope.row.set_info.key_amt1 = $scope.unit_price_per_quntity_p[0];
      } else {
        $scope.row.set_info.key_amtSectionTblStr = '';
        $scope.unit_price_per_quntity_p = [];
        $scope.unit_price_per_quntity_q = [];
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    init();
  });
