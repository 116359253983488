'use strict';

// 쇼피파이(Shopify) 글로벌
angular.module('gmpApp')
  .factory('X093categorySVC', function () {
    return {

      //카테고리 data
      category_data: {
        set_all_shop: false, //상품적용여부
        detailData: {}
      }
    };
  });