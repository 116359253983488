'use strict';

angular.module('gmpApp')
  .controller('InsightCtrl', function ($scope, settings, $rootScope, $state, $timeout, $filter, $uibModalInstance, $location, localStorageService, systemList, warehouseModel, commonSVC, userInfo, systemModel, insightModel, insightSVC, payModel) {

    $scope.userInfo = userInfo.user;

    // 권한있는 계정에서 주문집계 북마크 후 권한없는 계정으로 접속 시 탭꺼지도록 수정
    if (['ZERO', 'SLIM'].includes($scope.userInfo.sol_version)) {
      const url = $rootScope.insightPromotionYN ? 'https://www.plto.com/event/Randing/?promo_no=1419' : 'https://plto.com/customer/HelpDesc/?sol=gmp&number=&guide_no=16420';
      const opt = {
        html: `
          <div class="fl-l no-padding-top pb-20">
            <div class="full-width text-bold pb-10 pt-20" style="font-size: 20px !important; text-align: left;">
              세일즈 인사이트란?<br />
              내 상품과 비교상품을 그룹핑하여 가격정보 및 상품정보 제공하여<br />
              한눈에 비교할 수 있는 서비스입니다.<br />
            </div>
            <div class="full-width" pb-20 style="font-size: 14px !important; text-align: left;">
              <ul>
                <li>판매가 변동추이, 리뷰요약내용, 배송정보 등 가격관리에 필요한 정보 제공</li>
                <li>특정 조건 부합 시 내 상품 가격을 자동으로 수정하는 예약 기능 제공</li>
                <li>비교상품 판매가가 일정금액에 도달하면 알림을 발송하는 기능 제공</li>
                <li><a href="${url}" target="_blank" rel="noopener noreferrer">${$rootScope.insightPromotionYN ? '프로모션 바로가기' : '도움말 바로가기'}</a></li>
              </ul>
            </div>
            <div class="full-width text-bold pb-10" style="font-size: 16px !important; text-align: left;">
              세일즈인사이트 부가서비스는 솔루션 이용버전이 BASIC 이상부터 사용 가능합니다.
            </div>
          </div>
        `,
        confirmButtonText: '버전 업그레이드하기',
        cancelButtonText: '닫기',
        reverseButtons: true,
        customClass: {
          actions: '',
          confirmButton: 'my-swal2-confirm2',
        },
      };

      commonSVC.showConfirmCustomSwal2(opt, ({ isConfirmed }) => {
        if (isConfirmed) {
          // 결제 페이지 이동
          moveToPay();
        }

      });

      $rootScope.$emit('$closeTab', 'main.insight');
    }

    $scope.maxInsightCnt = {
      groupCnt: $rootScope.insightPromotionYN ? 10 : 1, // 가격비교 그룹 제한 개수
      compareCnt: $rootScope.insightPromotionYN ? 5 : 2 // 비교상품 개당 제한 개수 (내상품 1개, 비교 상품 2개)
    };

    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
    $scope.date = moment().format('YYYY-MM-DD').split('-');
    $scope.day = weekdays[moment().day()];

    /* 검색 및 버튼 관련 변수 */
    $scope.insightGroup = {}; //그룹 관련 변수
    $scope.insightGroupData = []; // 그룹데이터
    $scope.selectedGroupData = { // 선택한 그룹 데이터
      ol_shop_no: 0,
      sale_opt_seq: 0
    };

    $scope.insightDetail = {}; // 그룹상세 리스트 관련 변수
    $scope.insightDetailData = []; //선택한 그룹상세데이터

    // 그리드 이전 확장 타입 (펼치고 접기 용도)
    $scope.prevExpandType = '';

    $scope.editScheduleCnt = 0; // 가격수정 예약 리스트 수
    $scope.alarmScheduleCnt = 0; // 판매가 알림 설정 수

    const channelListArr = $rootScope.useChannelList({ site_action: 'SyncProdState' });
    // 별칭정보 담기
    const seller_nick_info = [];

    $scope.inputKeyword = {
      search_value: '',
      expanded: false
    };

    _.forEach(channelListArr, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    /*****************************
     * 세일즈인사이트 그룹 DataTable  *
     * ***************************/
    $scope.insightGroup.searchData = {};
    $scope.insightGroup.methods = {};
    $scope.insightGroup.options = {
      rowHeight: 50,
      pinningColumns: [],
      alignCenterColumns: ['sale_price', 'prod_cnt'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: ['shop_cd', 'shop_sale_name', 'sale_price', 'prod_cnt'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      enablePaginationControls: false,
      gridHeight: 620,
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      emptyText: '등록한 내 상품이 없습니다.<br/> 내 상품을 등록하면 가격비교 그룹이 생성됩니다.',
      rowNgClass: `{
        'insight-row-selected-alarm': row.entity.is_alarmed,
        'insight-row-selected-group': row.isSelected,
        'insight-row-disabled-bg': ['판매중지', '판매대기', '승인대기', '반려'].includes(row.entity.sale_status)
      }`,
      rowSpanToolTipFunc: function (row) {
        return row.is_alarmed ? '24시간 내 가격수정 또는 판매가알림이 발송된 그룹입니다.' : '';
      },
      rowAddTemplate: `
        <div ng-if="colContainer.name !== 'left' && ['판매중지', '판매대기', '승인대기', '반려'].indexOf(row.entity.sale_status) !== -1" class="insight-row-disabled-text">
          <span
            style="font-size:17px; margin-top: 10px; border-radius: 5px;"
            ng-class="{
              'label label-default bg-orange-300': row.entity.sale_status === '판매대기',
              'label label-default border-on border-orange-300 bg-white text-orange-300': row.entity.sale_status === '승인대기',
              'label label-default border-on border-danger-300 bg-white text-danger-300': row.entity.sale_status === '반려',
              'label label-default bg-grey-300': row.entity.sale_status === '판매중지'
            }"
          >
            {{ row.entity.sale_status || '' }}
          </span>
        </div>

        <div ng-if="colContainer.name !== 'left' && row.entity.adult_yn" class="insight-row-disabled-text" style="padding: 5px 10px; margin-top: 7px; border-radius: 5px; background-color: #7A4444;">미성년자 구매불가</div>
      `,
      externalRequestOptions: {
        isDirectInsert: true, // api 통신 없이 데이터 직접 입력 여부
        directData: $scope.insightGroupData, // 직접 입력할 데이터
        requestWillAction: function (data) {
          $scope.insightGroup.methods.setDirectData($scope.insightGroupData);

          return data;
        },
        requestDidAction: function (result) {
          $scope.insightGroup.searchData.totalCount = result.recordsTotal;

          result.results.forEach((res) => {

            // 이전 선택 유지, 초기 로딩 시에는 첫번째 그룹 선택
            if ($scope.selectedGroupData.ol_shop_no) {
              if ($scope.selectedGroupData.ol_shop_no === res.ol_shop_no && $scope.selectedGroupData.sale_opt_seq === res.sale_opt_seq) {
                res.isSelected = true;

              }
            } else {
              if (res.seq === 0) {
                res.isSelected = true;

                $scope.selectedGroupData = res;
              }
            }
          });

          const matchGroup = result.results.find(res => res.isSelected);

          if (matchGroup) {
            $scope.insightDetail.searchForm.ol_shop_no = matchGroup.ol_shop_no;
            $scope.insightDetail.searchForm.sale_opt_seq = matchGroup.sale_opt_seq;

            $scope.searchDo(true, true, 'insightDetail');
          }

          return result.results;
        }
      },
      columns: [
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 130,
          template: function(row) {
            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            const shop_info = commonSVC.getShopIdViewText(
              systemList.data.shop_id_view_type,
              seller_nick_info,
              row.shop_cd,
              row.shop_id
            );

            return `<span uib-tooltip="${row.shop_name}(${shop_info[0] ? shop_info[0] : row.shop_name})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                <img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">
                ${shop_info[1]}
              </span>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 150
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 80,
          notCompile: true,
          template: function(row) {
            return row.sale_price ? $filter('currency')(row.sale_price, '', 0) : '';
          }
        },
        {
          key: 'prod_cnt',
          title: '비교상품',
          width: 80
        }
      ]
    };
    /*****************************
     * 세일즈인사이트 상세 DataTable  *
     * ***************************/
    $scope.insightDetail.searchData = {};
    $scope.insightDetail.searchForm = {
      ol_shop_no: 0,
      sale_opt_seq: 0,
    };

    //세트 데이터 테이블
    $scope.insightDetail.methods = {};
    $scope.insightDetail.options = {
      rowHeight: 50,
      pinningColumns: [],
      alignCenterColumns: ['prod_img', 'street_price', 'sale_price', 'dc_rate', '검색순위', 'sale_qty', 'review_rate'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: ['shop_cd', 'prod_img', 'insight_shop_cate', 'ship_method', 'review_rate', 'dc_rate', 'add_benefit', 'brand'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      gridHeight: 620,
      emptyTemplate: '← 그룹에서 내 상품을 등록하신 후, 그룹상세에서 비교상품을 등록해주세요',
      enablePaginationControls: false,
      enableExpandable: true,
      rowNgClass: `{
        'insight-row-selected-group': colContainer.name === 'left' && row.entity.insight_seq === 0,
        'insight-row-disabled-bg': (row.entity.insight_seq !== 0 && row.entity.online_yn) || (row.entity.insight_seq === 0 && (['판매중지', '판매대기', '승인대기', '반려'].includes(row.entity.sale_status) || row.entity.adult_yn))
      }`,
      rowAddTemplate: `
        <div ng-if="colContainer.name !== 'left' && row.entity.insight_seq !== 0 && row.entity.online_yn === 1" class="insight-row-disabled-text" style="padding: 5px 10px; margin-top: 7px; border-radius: 5px; background-color: #525659;">상품을 찾을 수 없습니다.</div>
        <div ng-if="colContainer.name !== 'left' && ((row.entity.insight_seq !== 0 && row.entity.online_yn === 2) || (row.entity.insight_seq === 0 && row.entity.adult_yn))" class="insight-row-disabled-text" style="padding: 5px 10px; margin-top: 7px; border-radius: 5px; background-color: #7A4444;">미성년자 구매불가</div>
        <div
          ng-if="colContainer.name !== 'left' && row.entity.insight_seq === 0 && ['판매중지', '판매대기', '승인대기', '반려'].indexOf(row.entity.sale_status) !== -1"
          class="insight-row-disabled-text"
        >
          <span
            style="font-size:17px; margin-top: 10px; border-radius: 5px;"
            ng-class="{
              'label label-default bg-orange-300': row.entity.sale_status === '판매대기',
              'label label-default border-on border-orange-300 bg-white text-orange-300': row.entity.sale_status === '승인대기',
              'label label-default border-on border-danger-300 bg-white text-danger-300': row.entity.sale_status === '반려',
              'label label-default bg-grey-300': row.entity.sale_status === '판매중지'
            }">
              {{ row.entity.sale_status || '' }}
            </span>
        </div>
        <div ng-if="colContainer.name !== 'left' && grid.options.data.length === 1" class="insight-footer-cell" colspan="{{grid.options.columnDefs.length}}">
          등록한 비교 상품이 없습니다.
          <br>비교 상품을 등록하면 가격 정보를 확인할 수 있습니다.
        </div>
      `,
      externalRequestOptions: {
        requestMethod: 'GET',
        requestUrl: `${settings.pa20ApiUrl}/app/insight/detail`,
        requestWillAction: function (data) {
          data = angular.merge({}, data, $scope.insightDetail.searchForm);

          return data;
        },
        requestDidAction: function (result) {
          $scope.insightDetail.searchData.totalCount = result.recordsTotal;

          $scope.insightDetailData = result.results;

          $scope.insightDetailData.forEach((res) => {

            res.date_unit = 'day';
            res.sdate = moment().subtract(1, 'week').format('YYYY-MM-DD'),
            res.edate = moment().format('YYYY-MM-DD'),
            res.search_date = 7;

            res.statData = [];

            res.graphData = {
              c3_data: null
            };
            // 리뷰요약
            res.review = {
              positive: null,
              negative: null,
              isLoad: false
            };
          });

          return result.results;
        }
      },
      columns: [
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 130,
          template: function(row) {
            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            const shop_info = commonSVC.getShopIdViewText(
              systemList.data.shop_id_view_type,
              seller_nick_info,
              row.shop_cd,
              row.shop_id
            );

            return `<span uib-tooltip="${row.shop_name}(${shop_info[0] ? shop_info[0] : row.shop_name})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                <img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">
                ${shop_info[1] || row.shop_name}
              </span>`;
          }
        },
        {
          key: 'prod_img',
          title: '이미지',
          width: 40,
          notCompile: true,
          template: function (row) {
            const img = row.shop_sale_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='35' height='35'>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 150,
          template: function (row) {
            return `<span><i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${row.shop_sale_url}')"></i>&nbsp;${row.shop_sale_name}</span>`;
          }
        },
        {
          key: 'insight_shop_cate',
          title: '카테고리',
          width: 100
        },
        {
          key: 'street_price',
          title: '시중가',
          width: 100,
          notCompile: true,
          template: function(row) {
            // 원가
            return $filter('currency')(row.street_price, '', 0);
          }
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 100,
          ngClass: '{\'insight-cell-low\': row.entity.sale_price === row.entity.min_price}',
          template: (row) => {
            let check = false;

            if (row.insight_seq) {
              check = !!row.online_yn;
            } else {
              check = ['판매중지', '판매대기', '승인대기', '반려'].includes(row.sale_status) || row.adult_yn;
            }

            return `
              <div>${row.sale_price ? $filter('currency')(row.sale_price, '', 0) : ''}</div>
              <button class="btn-insight-detail" ng-click="grid.appScope.viewPriceStats(row, 'graph')" ng-class="{'insight-row-disabled-bg': ${check}}" ng-disabled="${check}">변동추이</button>
            `;
          }
        },
        {
          key: 'dc_rate',
          title: '할인율',
          width: 50,
          notCompile: true,
          template: (row) => {
            const dcRate = Math.floor(((row.street_price - row.sale_price) / row.street_price) * 100);

            if (row.street_price !== row.sale_price) {
              return `
                <div>
                  <span style="color: red; font-size:12px;">
                    ${dcRate}%
                  </span>
                  <br/>
                  <span style="color: red; font-size:10px;">
                    (${$filter('currency')(row.street_price - row.sale_price, '', 0)}원 할인)
                  </span>
                </div>
              `;
            } else {
              return '-';
            }
          }
        },
        {
          key: 'ship_method',
          title: '배송',
          width: 80,
          notCompile: true,
          template: (row) => {
            return row.ship_method + `${row.etc?.ship_cost ? ` ${$filter('currency')(row.etc.ship_cost, '', 0)}원` : ' 무료배송'}`;
          }
        },
        ...(false && [{
          key: '검색순위',
          title: '검색순위',
          width: 80,
          tooltip: '네이버쇼핑 검색순위입니다.(100위 내 순위)',
        }] || []),
        {
          key: 'sale_qty',
          title: '판매량',
          width: 60,
          tooltip: '·비교상품 등록일로부터 현재까지 집계된 누적 판매량 추정치입니다.\n(최대 3개월 누적판매량 추정치를 확인할 수 있습니다)\n·내 상품, 쿠팡 비교상품인 경우 판매량이 제공되지 않습니다.',
          notCompile: true,
          template: (row) => {
            return `${$filter('currency')(row.sale_qty, '', 0)}건`;
          }
        },
        {
          key: 'review_rate',
          title: '리뷰',
          width: 110,
          template: (row) => {

            let check = false;

            if (row.insight_seq) {
              check = !!row.online_yn;
            } else {
              check = ['판매중지', '판매대기', '승인대기', '반려'].includes(row.sale_status) || row.adult_yn;
            }

            return `
            <div class="review-rating">
              <span class="icon-review-rating"></span>
              <span style="color: #1e88e5">${row.review_rate || '-'}</span>
              /
              <span>5</span>
              <span style="color: #333">(${$filter('currency')(row.review_cnt, '', 0)}건)</span>
            </div>
            <button class="btn-insight-detail" ng-click="grid.appScope.viewReviewSummary(row, 'summary')" ng-class="{'insight-row-disabled-bg': ${check}}" ng-disabled="${check}">리뷰요약</button>`;
          }
        },
        {
          key: 'add_benefit',
          title: '추가혜택',
          width: 100,
          template: (row) => {
            let msg = '';
            const bene = [];

            if (row.etc && Object.keys(row.etc).length) {
              for (const [key, value] of Object.entries(row.etc)) {
                if (['coupon', 'gift', 'promotion', 'event'].includes(key) && value) {
                  switch (key) {
                    case 'coupon':
                      msg += '쿠폰할인 : ';
                      bene.push('쿠폰할인');
                      break;
                    case 'gift':
                      msg += '증정품 : ';
                      bene.push('증정품');
                      break;
                    case 'promotion':
                      msg += '복수구매할인 : ';
                      bene.push('복수구매할인');
                      break;
                    case 'event':
                      msg += '이벤트 : ';
                      bene.push('이벤트');
                      break;
                  }
                  msg += value + '\n';
                }
              }
            }

            return `<div uib-tooltip="${msg}" tooltip-placement="top-right">${bene.join() || ''}</div>`;
          }
        },
        {
          key: 'brand',
          title: '브랜드/모델명',
          width: 90,
          notCompile: true,
          template: (row) => {
            let brandModel = '';

            if (row.etc?.brand && row.etc?.model) {
              brandModel = `${row.etc.brand}/${row.etc.model}`;
            } else if (row.etc?.brand) {
              brandModel = `${row.etc.brand}`;
            } else if (row.etc?.model) {
              brandModel = `${row.etc.model}`;
            }

            return brandModel;
          }
        },
      ]
    };

    $scope.searchDo = async function(refresh = true, noDelay, type) {
      if (type === 'insightGroup') {
        $scope.insightGroupData = (await insightModel.getInsightGroups()).data.results;
        $scope.insightDetail.methods.setEmpty();
      }

      $scope[type].methods.reloadData(function () {}, refresh, noDelay);
    };

    /**
     * 상품 URL 상세 보기
     */
    $scope.shopDetailView = function (url) {
      // http가 없을 경우 open시 도메인으로 열리는게 아니라 엔드포인트 주소로 열리는 이슈로 인해 http를 붙여줌
      if (!url.includes('http')) {
        url = 'https://' + url;
      }

      window.open(url, '_blank', 'noopener,noreferrer');
    };

    /**
     * 내 상품, 비교 상품 등록 모달
     */
    $scope.openAddInsightOnlineModal = (type) => {
      const resolve = {
        data: { type, groupData: { seq: _.maxBy($scope.insightGroupData, 'seq')?.seq || 0, totalCnt: $scope.insightGroup.searchData.totalCount || 0 } },
        systemList: function () {
          return systemModel.load();
        }
      };

      if (type === 'another') {
        const [selectedData] = $scope.insightGroup.methods.selectedData('all');

        if (!selectedData?.ol_shop_no) {
          commonSVC.showMessage('그룹에서 내 상품을 등록하신 후, 비교상품을 등록해주세요.');

          return false;
        }
        if ($scope.maxInsightCnt.compareCnt <= $scope.insightDetailData.length - 1) { // 내 상품 포함 6개

          if ($scope.userInfo.insight_info || $rootScope.insightPromotionYN) {
            commonSVC.showMessage(`비교상품은 그룹마다 최대 ${$scope.maxInsightCnt.compareCnt}개까지 등록가능합니다.`);

          } else {
            const modal = commonSVC.openModal('mini', { data: {} }, 'confirmCtrl', 'views/summary/modals/insight_count.html');

            modal.result.then(function (res) {

              if (res === 'success') {
                payModel.getSolInfo(userInfo.user.m_no, function (status, data) {
                  if (status === 'success') {

                    $scope.solutionInfo = data.results.solutionInfos;
                    const solutionProductInfo = data.results.solutionProductInfo;
                    const payedJoinPrice = data.results.payedJoinPrice;    // 결제된 가입비 정보

                    // 다음 카운트 갱신일
                    const solEdate = moment($scope.solutionInfo.sol_edate);
                    const today = moment();
                    const usingChargeEdate = moment($scope.solutionInfo.using_charge_edate);

                    if (solEdate.isAfter(today, 'day')) {
                      $scope.solutionInfo.using_charge_edate = usingChargeEdate.add(1, 'days').format('YYYY-MM-DD');
                    } else {
                      $scope.solutionInfo.using_charge_edate = '없음';
                    }

                    // 남은일자
                    $scope.solutionInfo.leftDay = moment($scope.solutionInfo.sol_edate).endOf('day').diff(moment().endOf('day'), 'days');

                    // 현재 사용중인 솔루션의 월금액.
                    for (let i = 0; i < solutionProductInfo.length; i++) {
                      // 사용중인 솔루션의 일금액. ( 환불시 필요 )
                      if (solutionProductInfo[i].prod_name === $scope.solutionInfo.sol_version && solutionProductInfo[i].prod_opt === '1일') {
                        $scope.solutionInfo.dayPrice = solutionProductInfo[i].price;
                      }
                    }

                    // 카운트 부족 추천 버전은 무조건 상위버전
                    const recommendVersion = (() => {
                      if ($scope.solutionInfo.sol_version == 'VIP+') { return 'VIP+'; }

                      return $rootScope.recommendVersions[$rootScope.recommendVersions.findIndex(o => o === $scope.solutionInfo.sol_version) + 1];
                    })();

                    const params = {
                      solutionInfo: $scope.solutionInfo,
                      solutionProductInfo: solutionProductInfo,
                      payedJoinPrice: payedJoinPrice,
                      firstOrdYN: !$scope.solutionInfo.first_pay_no,
                      closeFlag: true,
                      insight_info: userInfo.user.insight_info,
                      recommendVersion
                    };

                    $state.go('main.pay_list');

                    commonSVC.openModal('x3g', { data: params }, 'PayRequestCtrl', 'views/pay/modals/pay_request.html');
                  }
                });

                $uibModalInstance.close();

              }
            });
          }

          return false;
        }

        resolve.data.groupData.ol_shop_no = selectedData.ol_shop_no;
        resolve.data.groupData.sale_opt_seq = selectedData.sale_opt_seq;
        resolve.data.groupData.insight_seq = _.maxBy($scope.insightDetailData, 'insight_seq').insight_seq + 1; // 인사이트 순번 현재 생성된 거 중에 마지막
        resolve.data.groupData.seq = selectedData.seq;
      } else {
        resolve.data.groupData.insight_seq = 0;
      }

      const modal = commonSVC.openModal('xg', resolve, 'RegistInsightOnlineCtrl', 'views/summary/modals/regist_online_prod.html');

      modal.result.then(async (re) => {
        $scope.searchDo(true, true, 'insightGroup');
      });
    };

    /**
     * 가격 수정 예약 || 판매가 알람 등록 모달
     */
    $scope.registPriceScheduleModal = (type, mode) => {

      const [selectedGroup] = $scope.insightGroup.methods.selectedData('all');

      if (!selectedGroup) {
        commonSVC.showMessage('그룹에서 내 상품을 등록하신 후, 비교상품을 등록해주세요.');

        return false;
      }

      if (type === 'priceEdit' && (['판매중지', '판매대기', '승인대기', '반려'].includes(selectedGroup.sale_status) || selectedGroup.adult_yn)) {
        commonSVC.showMessage(`내 상품이 ${selectedGroup.adult_yn ? '미성년자 판매불가' : selectedGroup.sale_status}인 경우 [예약] 설정이 불가능합니다.`);

        return false;
      }

      if (type === 'compareAlarm' && $scope.insightDetailData.filter(data => data.insight_seq !== 0).some(ol => ol.online_yn)) {
        commonSVC.showMessage('가격정보를 확인할 수 없는 비교상품이 포함되어 있습니다.\n해당 상품 삭제 후, [알림]을 설정해주세요.');

        return false;
      }

      const resolve = {
        data: { type, mode, ol_shop_no: selectedGroup.ol_shop_no, sale_opt_seq: selectedGroup.sale_opt_seq },
        systemList: function () {
          return systemModel.load();
        },
        warehouseList: function() {
          return warehouseModel.ListAll({ use_yn: true });
        }
      };

      if ($scope.insightDetailData.length === 1) { // 비교상품이 없는 경우
        commonSVC.showMessage('등록된 비교상품이 없습니다.\n비교상품을 등록하신 후,[예약] 또는 [알림]을 설정해주세요');

        return false;
      }

      const modal = commonSVC.openModal('sg', resolve, 'RegistPriceScheduleCtrl', 'views/summary/modals/regist_price_schedule.html');

      modal.result.then(function () {
        // 알림 등록 시 가격 수정 예약, 판매가 알림 예약 수 갱신
        insightModel.searchInsightCount().then((res) => {
          const data = res.data.results;

          $scope.editScheduleCnt = data.editScheduleCnt;
          $scope.alarmScheduleCnt = data.alarmScheduleCnt;
        });

        $scope.searchDo(true, true, 'insightGroup');
      });
    };

    /**
     * 가격 수정 예약 내역 조회 & 판매가 알림 설정 내역 조회
     */
    $scope.openPriceScheduleList = (type) => {
      const ctrl = type === 'edit' ? 'PriceEditScheduleListCtrl' : 'PriceAlarmScheduleListCtrl';
      const html = type === 'edit' ? 'views/summary/modals/price_edit_schedule_list.html' : 'views/summary/modals/price_alarm_schedule_list.html';
      const resolve = {
        data: { },
        systemList: function () {
          return systemModel.load();
        },
      };
      const modal = commonSVC.openModal('xg', resolve, ctrl, html);

      modal.result.then(function() {
        // 알림 삭제 시 가격 수정 예약, 판매가 알림 예약 수 갱신
        insightModel.searchInsightCount().then((res) => {
          const data = res.data.results;

          $scope.editScheduleCnt = data.editScheduleCnt;
          $scope.alarmScheduleCnt = data.alarmScheduleCnt;
        });
      });
    };

    /**
     * 상품 데이터 삭제
     */
    $scope.deleteInsight = async (type) => {
      let selected = type === 'group' ? $scope.insightGroup.methods.selectedData('all') : $scope.insightDetail.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      const opt = {
        showCancelButton: true,
        cancelButtonText: '닫기',
        confirmButtonText: '삭제'
      };
      let msg = '';

      try {

        // 그룹 또는 내 상품 삭제할 경우 비교 상품까지 일괄 삭제
        if (type === 'group') {
          msg = '그룹에 포함된 내 상품, 비교상품이 모두 삭제됩니다.<br/>삭제하시겠습니까?';

          selected = $scope.insightDetailData;
        } else {
          if (selected.some(ol => ol.insight_seq === 0)) { // 내 상품 삭제
            msg = '<div style="font-size: 21px;">내 상품 삭제 시 그룹에 포함된 내 상품, 비교상품이 모두 삭제됩니다.<br/>삭제하시겠습니까?</div>';

            selected = $scope.insightDetailData;
          } else { // 비교 상품 삭제
            const editScheduleList = (await insightModel.priceEditScheduleList({ ol_shop_no: selected[0].ol_shop_no, sale_opt_seq: selected[0].sale_opt_seq, book_status: '예약대기' })).data.results;
            const alarmList = (await insightModel.priceAlarmScheduleList({ ol_shop_no: selected[0].ol_shop_no, sale_opt_seq: selected[0].sale_opt_seq, alarm_status: '미발송' })).data.results;

            if (editScheduleList.length > 0 || alarmList.length > 0) {
              const isConfirm = await commonSVC.showConfirm('비교상품을 추가/삭제하면 이미 설정된 예약/알림은 삭제됩니다. 계속하시겠습니까?', '');

              if (!isConfirm) {
                return false;
              }
            }
            msg = `<div style="font-size: 21px;">비교상품 ${selected.length}건을 삭제하시겠습니까?</div>`;
          }
        }

        const confirm = await commonSVC.showConfirmHtml(msg, '', null, opt);

        if (!confirm) { return false; }

        await insightModel.deleteInsightProd({ insight_list: selected.map(ol => ({ ol_shop_no: ol.ol_shop_no, insight_seq: ol.insight_seq, sale_opt_seq: ol.sale_opt_seq, api_no: ol.api_no })) });

        commonSVC.showToaster('success', '성공', `${type === 'group' ? '그룹' : '상품'} 삭제에 성공하였습니다.`);

        if (selected.some(ol => ol.insight_seq === 0)) {
          // 삭제 시 가격 수정 예약, 판매가 알림 예약 수 갱신
          insightModel.searchInsightCount().then((res) => {
            const data = res.data.results;

            $scope.editScheduleCnt = data.editScheduleCnt;
            $scope.alarmScheduleCnt = data.alarmScheduleCnt;
          });
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', `${type === 'group' ? '그룹' : '상품'} 삭제에 실패하였습니다.`);
      }

      $scope.searchDo(true, true, 'insightGroup');
    };

    /*************************************
     *       가격 변동 그래프 관련 함수        *
     *************************************/
    let formatter; // 그래프 날짜 formatter

    function updateFormatter(byMonth) {
      formatter = d3.time.format(byMonth ? '%Y-%m' : '%Y-%m-%d');
    }

    updateFormatter();

    const drawGraph = async (index, row) => {
      $timeout(async () => {
        const data = {
          date_unit: row.date_unit,
          sdate: row.sdate,
          edate: row.edate,
          ol_shop_no: row.ol_shop_no,
          insight_seq: row.insight_seq
        };

        try {
          const { data: { results } } = await insightModel.getInsightStatList(data);

          $scope.insightDetailData[index].statData = results;

          $scope.insightDetailData[index].statMinIdx = results.map(o => o.sale_price).lastIndexOf(Math.min(...results.map(o => o.sale_price).filter(Boolean)));
          $scope.insightDetailData[index].statMaxIdx = results.map(o => o.sale_price).lastIndexOf(Math.max(...results.map(o => o.sale_price).filter(Boolean)));
          $scope.insightDetailData[index].statLength = results.length;

          $scope.insightDetailData[index].statMinCpnIdx = results.map(o => o.sale_price_coupon).lastIndexOf(Math.min(...results.map(o => o.sale_price_coupon).filter(Boolean)));
          $scope.insightDetailData[index].statMaxCpnIdx = results.map(o => o.sale_price_coupon).lastIndexOf(Math.max(...results.map(o => o.sale_price_coupon).filter(Boolean)));

          $timeout(() => {});

          // 차트를 c3Data.load({data})를 통하여 기간 조회 등 다시 그릴 경우 padding 등 변경되지 않는 값이 있어 지우고 다시 그리도록 함
          if (row.graphData.c3_data) {
            row.graphData.c3_data.destroy();
          }

          formatter = d3.time.format('%m-%d');

          row.graphData.c3_data = initGraph(index, $scope.insightDetailData[index].statData);

          $(`.spinner_default_${index}`).addClass('hidden');

        } catch (err) {
          commonSVC.showToaster('error', '실패', '판매가 변동추이 조회에 실패했습니다.');

          $(`.spinner_default_${index}`).addClass('hidden');
          $(`.chart_default_${index}`).removeClass('hidden');
        }
      });
    };

    /**
     * 그래프 생성 함수
     */
    const initGraph = (index, data) => {

      const insightData = $scope.insightDetailData[index];
      const start = moment(insightData.sdate);
      const end = moment(insightData.edate);
      const duration = moment.duration(end.diff(start)).asMilliseconds(); // padding 계산

      const graphData = {
        size: insightSVC.chartData.size,
        padding: insightSVC.chartData.padding,
        bindto: `#insight_sales_all_${index}`,
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d',
          columns: [
            ['x'].concat(data.map(({ curdate }) => curdate)),
            ['sales_total'].concat(data.map(({ sale_price }) => sale_price)),
          ],
          colors: {
            sales_total: '#FF7171',
          },
          names: {
            sales_total: '판매가',
          },
          labels: {
            format: function (value, id, idx) {
              const { statMinIdx, statMaxIdx, statMinCpnIdx, statMaxCpnIdx, statLength } = insightData;

              // 최고, 최저가에 라벨 표시 (동일 값일 경우 가장 마지막 인덱스에 표시됨)
              if (id === 'sales_total') {
                if (idx === statMinIdx && idx === statMaxIdx) {
                  return `최고가 ${$filter('currency')(value, '', 0)}원\n최저가 ${$filter('currency')(value, '', 0)}원`;
                } else if (idx === statMinIdx) {
                  return `최저가 ${$filter('currency')(value, '', 0)}원`;
                } else if (idx === statMaxIdx) {
                  return `최고가 ${$filter('currency')(value, '', 0)}원`;
                } else if (statLength - 1 === idx) {
                  return `${$filter('currency')(value, '', 0)}원`;
                }
              }

              if (id === 'sales_coupon_total') {
                if (idx === statMinCpnIdx && idx === statMaxCpnIdx) {
                  return `최고가 ${$filter('currency')(value, '', 0)}원\n최저가 ${$filter('currency')(value, '', 0)}원`;
                } else if (idx === statMinCpnIdx) {
                  return `최저가 ${$filter('currency')(value, '', 0)}원`;
                } else if (idx === statMaxCpnIdx) {
                  return `최고가 ${$filter('currency')(value, '', 0)}원`;
                } else if (statLength - 1 === idx) {
                  return `${$filter('currency')(value, '', 0)}원`;
                }
              }

              return null; // 다른 점은 숨김
            }
          }
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: x => { return formatter(x); },
              rotate: $scope.insightDetailData[index].search_date >= 180 ? 45 : 0, // X축 라벨을 45도 회전
              culling: { max: 12 } // 너무 많으면 일부만 표시
            },
            padding: {
              left: duration * 0.05,
              right: duration * 0.05
            }
          },
          y: {
            tick: {
              format: d3.format(','),
            },
            padding: {
              top: 100
            }
          },
        },
        transition: insightSVC.chartData.transition,
        onrendered: () => {
          d3.selectAll(`#insight_sales_all_${index} .c3-texts text`).each(function (data) {
            const text = d3.select(this);

            const { statMinIdx, statMaxIdx, statLength } = insightData;

            const dy = data.id === 'sales_total' ? (statMinIdx === statMaxIdx ? '-1.8em' : '-0.6em') : '2.4em';

            if (statMinIdx === statMaxIdx) { // 최저, 최고 같은 경우 두줄로 출력
              const xPos = +text.attr('x'); // 현재 X 좌표
              const lines = text.text().split('\n'); // 개행 문자 기준으로 분할

              if (lines?.filter(Boolean).length > 1) {
                text.text(null); // 기존 텍스트 제거

                lines.forEach((line, i) => {
                  text.append('tspan')
                    .attr('x', data.index === statLength - 1 ? xPos - 12 : xPos) // 첫 줄은 원래 위치, 이후 줄은 오른쪽으로 이동
                    .attr('dy', i === 0 ? dy : '1.2em') // 첫 줄은 원래 위치, 이후 줄은 아래로 이동
                    .text(line + '\n');
                });
              }
            } else {
              // 왼쪽 끝 (최좌단 데이터)
              if (data.index === 0) {
                text.attr('dx', '1em'); // 살짝 오른쪽 이동
              }
              // 오른쪽 끝 (최우단 데이터)
              else if (data.index === statLength - 1) {
                text.attr('dx', '-1em'); // 살짝 왼쪽으로 이동
              }

              text.attr('dy', dy);
            }

          });
        }
      };

      if (insightData.pa_shop_cd === 'B378') {
        graphData.data.names.sales_total = '쿠팡판매가';

        graphData.data.columns.push(['sales_coupon_total'].concat(data.map(({ sale_price_coupon }) => sale_price_coupon || 0)));
        graphData.data.colors.sales_coupon_total = '#0072CE';
        graphData.data.names.sales_coupon_total = '와우쿠폰할인가';
      }

      const c3Data = // 매출현황
        c3.generate(graphData);

      return c3Data;
    };

    /**
     * 판매가 추이 그래프 검색단위 변경
     */
    $scope.changeDateUnit = function (idx, date_unit) {
      if ($scope.insightDetailData[idx].date_unit === date_unit) {
        return false;
      }

      $scope.insightDetailData[idx].date_unit = date_unit;

      updateFormatter($scope.insightDetailData[idx].date_unit === 'month');

      drawGraph(idx, $scope.insightDetailData[idx]);
    };

    /**
     * 판매가 추이 그래프 검색일자 변경
     */
    $scope.changeSearchDate = function (idx, search_date) {
      // row가 접힐 경우 ng-change가 또 실행되므로 search_date가 null로 들어와 해당값 초기화 시켜줌
      if (!search_date) {
        $scope.insightDetailData[idx].search_date = 7;
        $scope.insightDetailData[idx].sdate = moment().subtract($scope.insightDetailData[idx].search_date - 1, 'day').format('YYYY-MM-DD');

        return false;
      }

      $scope.insightDetailData[idx].sdate = moment().subtract($scope.insightDetailData[idx].search_date - 1, 'day').format('YYYY-MM-DD');

      drawGraph(idx, $scope.insightDetailData[idx]);
    };

    /**
     * 판매가 추이 펼치기
     */
    $scope.viewPriceStats = (row, type) => {
      row.expandedRowHeight = 400; // 펼침 높이 설정

      row.expandedRowTemplate = insightSVC.getPriceStatTemplate(row);

      if (row.isExpanded && type !== $scope.prevExpandType) {
        $scope.insightDetail.methods.toggleExpand(row);
      }

      $scope.insightDetail.methods.toggleExpand(row, async function () {
        updateFormatter($scope.insightDetailData[row.index].date_unit === 'month');

        drawGraph(row.index, $scope.insightDetailData[row.index]);

        $scope.prevExpandType = 'graph';
      });
    };

    /**
     * 리뷰요약 보기
     */
    $scope.viewReviewSummary = async (row, type) => {

      try {

        row.expandedRowHeight = 250; // 펼침 높이 설정

        row.expandedRowTemplate = insightSVC.getReviewSummaryTemplate(row);

        if (row.isExpanded && type !== $scope.prevExpandType) {
          $scope.insightDetail.methods.toggleExpand(row);
        }

        $scope.insightDetail.methods.toggleExpand(row, async function () {
          if (!row.entity.review.isLoad) {
            if (row.entity.review_cnt) {
              try {
                const reviewRes = (await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/insight/review`, { api_no: row.entity.api_no })).data.results;

                row.entity.review.isLoad = true; // 성공한 경우 저장후 다시 조회하지 않도록 함
                row.entity.review.positive = reviewRes.positive;
                row.entity.review.negative = reviewRes.negative;
              } catch (err) {
                if (err.data.error === 'now scraping') {
                  row.entity.review.error = err.data.error;
                } else {
                  $scope.insightDetail.methods.toggleExpand(row);

                  commonSVC.showMessage('알 수 없는 오류가 발생했습니다.\n다시 시도해주세요.', '계속 문제가 발생한다면 1:1 문의로 접수해 주시길 바랍니다.');

                }
              }
            } else { // 리뷰수가 없는 경우 조회 안함
              row.entity.review.isLoad = true; // 성공한 경우 저장후 다시 조회하지 않도록 함
              row.entity.review.positive = '긍정리뷰가 현저히 적거나 카테고리 정보가 적합하지 않아 요약이 어렵습니다.';
              row.entity.review.negative = '부정리뷰가 현저히 적거나 카테고리 정보가 적합하지 않아 요약이 어렵습니다.';
            }
          }
          $timeout(() => {});

          $scope.prevExpandType = 'summary';
        });

      } catch (err) {
        commonSVC.showToaster('error', '실패', '리뷰요약 조회에 실패했습니다.');
      }

    };

    /**
     * 그룹 순서 변경
     */
    $scope.changeGroupSeq = async (direction) => {
      const [row] = $scope.insightGroup.methods.selectedData('all');

      if (!row) {
        return true;
      }

      const arrLen = $scope.insightGroupData.length;
      const idx = $scope.insightGroupData.findIndex(o => o.ol_shop_no === row.ol_shop_no && o.sale_opt_seq === row.sale_opt_seq);

      if (idx === 0 && ['top', 'up'].includes(direction) || idx === arrLen - 1 && ['down', 'bottom'].includes(direction)) {
        return true;
      }

      $scope.insightGroupData.splice(idx, 1);

      if (direction === 'top') { // 최상단
        $scope.insightGroupData.splice(0, 0, row);
      } else if (direction === 'up') { // 위로
        $scope.insightGroupData.splice(idx - 1, 0, row);
      } else if (direction === 'down') { // 아래로
        $scope.insightGroupData.splice(idx + 1, 0, row);
      } else { // 최하단
        $scope.insightGroupData.splice(arrLen - 1, 0, row);
      }

      try {
        await insightModel.changeInsightGroupSeq(
          {
            groupList: $scope.insightGroupData.map((ol, idx) => ({
              ol_shop_no: ol.ol_shop_no,
              seq: idx
            }))
          }
        );
      } catch (err) {
        commonSVC.showToaster('error', '실패', '그룹 순서 변경에 실패하였습니다.');
        $scope.searchDo(true, true, 'insightGroup');
      }
    };

    /**
     * 키워드 검색
     */
    $scope.searchKeyword = function () {
      $scope.inputKeyword.expanded = false;
      $scope.insightDetailData.forEach(row => {

        if (!$scope.inputKeyword.search_value) {
          row.검색순위 = '-';
        } else {
          row.검색순위 = '100위밖';

          if (row.검색순위 < 100) {
            row.검색순위 = `${row.검색순위}위`;
          } else {
            row.검색순위 = '100위 밖';
          }
        }

      });
    };

    async function init () {
      if (['ZERO', 'SLIM'].includes($scope.userInfo.sol_version)) {
        return false;
      }

      insightModel.searchInsightCount().then(async (res) => {
        const data = res.data.results;

        $scope.editScheduleCnt = data.editScheduleCnt;
        $scope.alarmScheduleCnt = data.alarmScheduleCnt;
        $scope.insightPayData = data.insightPayData; // 마지막 가격비교 결제 데이터

        // 만료일+5일 이내까지 만료 모달 출력 그 이후에는 데이터 지움
        if (!$scope.userInfo.insight_info && ($scope.insightPayData && moment($scope.insightPayData?.charge_edate).diff(moment().format('YYYY-MM-DD'), 'days') > -5)) {

          const opt = {
            title: `<div style="text-align: left; font-size: 22px">가격비교 부가서비스 사용기간이 만료되었습니다.
                ${moment($scope.insightPayData?.charge_edate).clone().add(5, 'days').format('YYYY-MM-DD')} 내 연장하지 않을 경우 가격비교 데이터는 모두 삭제됩니다.
              <div>
            `,
            confirmButtonText: '결제하러 가기',
            cancelButtonText: '확인',
            width: 700,
            customClass: {
              confirmButton: 'my-swal2-confirm2',
            },
          };
          const { isConfirmed } = await commonSVC.showConfirmCustomSwal2(opt);

          if (isConfirmed) {
            // 결제 페이지 이동
            moveToPay();

            return false;
          }
        }

        $scope.searchDo(true, true, 'insightGroup');

        if ($scope.userInfo.insight_info) {
          $scope.maxInsightCnt = {
            groupCnt: 10, // 가격비교 그룹 제한 개수
            compareCnt: 5 // 비교상품 개당 제한 개수 (내상품1개, 비교상품5개)
          };
        }
      });
    }

    init();

    function moveToPay() {
      // 결제 페이지 이동
      payModel.getSolInfo(userInfo.user.m_no, function (status, data) {
        if (status === 'success') {

          $scope.solutionInfo = data.results.solutionInfos;
          const solutionProductInfo = data.results.solutionProductInfo;
          const payedJoinPrice = data.results.payedJoinPrice;    // 결제된 가입비 정보

          // 다음 카운트 갱신일
          const solEdate = moment($scope.solutionInfo.sol_edate);
          const today = moment();
          const usingChargeEdate = moment($scope.solutionInfo.using_charge_edate);

          if (solEdate.isAfter(today, 'day')) {
            $scope.solutionInfo.using_charge_edate = usingChargeEdate.add(1, 'days').format('YYYY-MM-DD');
          } else {
            $scope.solutionInfo.using_charge_edate = '없음';
          }

          // 남은일자
          $scope.solutionInfo.leftDay = moment($scope.solutionInfo.sol_edate).endOf('day').diff(moment().endOf('day'), 'days');

          // 현재 사용중인 솔루션의 월금액.
          for (let i = 0; i < solutionProductInfo.length; i++) {
          // 사용중인 솔루션의 일금액. ( 환불시 필요 )
            if (solutionProductInfo[i].prod_name === $scope.solutionInfo.sol_version && solutionProductInfo[i].prod_opt === '1일') {
              $scope.solutionInfo.dayPrice = solutionProductInfo[i].price;
            }
          }

          // 카운트 부족 추천 버전은 무조건 상위버전
          const recommendVersion = (() => {
            if ($scope.solutionInfo.sol_version == 'VIP+') { return 'VIP+'; }

            return $rootScope.recommendVersions[$rootScope.recommendVersions.findIndex(o => o === $scope.solutionInfo.sol_version) + 1];
          })();

          const params = {
            solutionInfo: $scope.solutionInfo,
            solutionProductInfo: solutionProductInfo,
            payedJoinPrice: payedJoinPrice,
            firstOrdYN: !$scope.solutionInfo.first_pay_no,
            closeFlag: true,
            insight_info: userInfo.user.insight_info,
            recommendVersion
          };

          $state.go('main.pay_list');

          commonSVC.openModal('x3g', { data: params }, 'PayRequestCtrl', 'views/pay/modals/pay_request.html');
        }
      });
    }

    /**
     * 테이블에서 선택 변경 시
     */
    $scope.$on('OnSelectChangeBefore', async function(event, data) {
      const { id, selectData, selectDataSelected } = data;

      // 그룹 선택 시 그룹 상세 리스트 조회하여 리스트에 뿌려줌
      if (id === 'insight_group_grid') {
        if (selectDataSelected) {

          $scope.selectedGroupData = selectData;

          $scope.insightDetail.searchForm.ol_shop_no = selectData.ol_shop_no;
          $scope.insightDetail.searchForm.sale_opt_seq = selectData.sale_opt_seq;

          $scope.searchDo(true, true, 'insightDetail');
        } else {
          $scope.selectedGroupData = {
            ol_shop_no: 0,
            sale_opt_seq: 0
          };

          $scope.insightDetail.searchForm.ol_shop_no = 0;
          $scope.insightDetail.searchForm.sale_opt_seq = 0;

          $scope.insightDetail.methods.setEmpty();
        }

        // 알람 툴팁 있을 경우 읽음 처리 및 툴팁 제거
        if (selectData.is_alarmed) {
          selectData.is_alarmed = 0;
          selectData._uiGridData.rowSpanToolTip = null;

          await insightModel.resetInsightGroupReadAlarm({ ol_shop_no: selectData.ol_shop_no, sale_opt_seq: selectData.sale_opt_seq });
        }
      }
    });
  });
