'use strict';

angular.module('gmpApp')
  .service('payModel', function ($http, $q, settings, commonSVC, $rootScope) {
    return {
      /**
       * ZERO 버전 사용하기
       */
      useZeroVersion: function () {
        const url = `${settings.pa20ApiUrl}/app/pay/useZeroVersion`;

        return commonSVC.sendUrl('PATCH', url, {});
      },
      /**
       * 사용 솔루션 정보 조회.
       */
      getSolInfo: function (m_no, next) {
        if (next) {
          commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/sol-info`, {}, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/sol-info`, {});
        }
      },
      /**
       * 은행정보 조회
      */
      getBankInfo: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/bank-info`;

        commonSVC.sendUrl('GET', url, null, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 환불요청정보 저장.
       */
      payRefundRequest: function (data, next) {
        // 백오피스로 요청
        const url = `${settings.adminApiUrl}/api/pay/refundRequest`;
        const resolve = data;

        // 파일정리.
        _.forEach(data.file_array, function (v, k) {
          resolve[`file${k}`] = v;
        });
        delete resolve.file_array;

        commonSVC.sendUrlFile('POST', url, resolve, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 주문상세정보 조회.
       */
      getPayDetail: function (pay_no, next) {
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/detail/${pay_no}`, {}, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 나이스결제 키 받아오기.
       */
      getNiceHashKey: function (data, next) {
        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/pay/nicepay-key`, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 결제 정보 저장.
       */
      savePayInfo: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/save-pay-info`;

        commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 결제완료 후 처리.
       * ( 솔루션 연장 작업 등... )
       */
      completeProcess: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/complete`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },
      /**
       * 나이스페이 가상계좌 발급
       */
      publishVbank: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/publish-vbank`;

        commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 자동결제정보 및 환불정보 조회.
       * ( 솔루션 연장 작업 등... )
       */
      getAutoPayInfo: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/autopay-info`;

        if (next) {
          commonSVC.sendUrl('GET', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, data);
        }
      },
      /**
       * 자동결제정보 및 환불정보 저장
       */
      setAutoPayInfo: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/autopay-info`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function (state, res) {
            next(state, res);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },
      /**
       * 프리빌 인증키 조회.
       */
      getFreebillAuthkey: function (data, next) {
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/freebill-auth-key`, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 세금계산서 조회 URL 호출
       */
      getUrlFreebill: function (data, next) {
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/freebill-url`, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 버전 다운그레이드 이력 조회
       */
      checkDowngrade: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/downgrade-history`;

        commonSVC.sendUrl('GET', url, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 결제내역 삭제
       * 2018-10-30 rony
      */
      deletePays: function (data, next) {
        commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/pay/${data.pay_nos[0]}`, {}, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 세금계산서 선결제 문의등록.
       * 2018-11-27 rony
      */
      pushTaxBill: function (data, next) {
        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/pay/tax-prepay`, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 현재 사용중인 버전 정보 가져오기 (카운트 사용내역 리스트)
       * 2018-12-11 Jacob
       */
      getCurrentCount: function (next) {
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/current-count`, {}, function (state, result) {
          next(state, result);
        });
      },

      /**
       * 카운트 사용내역 가져오기
       * 2018-12-11 Jacob
       */
      getCountHistory: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/count-history`;

        commonSVC.sendUrl('GET', url, data, function (state, result) {
          next(state, result);
        });
      },

      /**
       * 환불 계좌정보 조회
       * 2019-04-22 rony
       */
      getRefundInfo: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/refund-info`;

        commonSVC.sendUrl('GET', url, data, function (state, result) {
          next(state, result);
        });
      },

      /**
       * 프로모션 코드 등록
       * 2019-06-17 rony
       */
      setPromotion: function (data) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/pay/promotion-code`, data);
      },

      /**
       * 쿠폰 리스트 조회
       */
      getUserCouponList: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/coupons`;
        const method = 'GET';

        if (next) {
          commonSVC.sendUrl(method, url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl(method, url, data);
        }
      },

      /**
       * 쿠폰 사용
       */
      useCoupon: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/pay/use-coupon`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },

      /**
       * 기간 연장 (LITE)
       */
      liteExtension: async (data) => {
        return await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/pay/lite-version-extension`, data);
      },

      /**
       * 정기결제 QA테스트
       */
      autoPayTest: async (data) => {
        const url = `${settings.pa20ApiUrl}/lambda/run-regular-payment`;

        return await commonSVC.sendUrl('GET', url, data);
      },

      /**
       * 정기결제 혜택제공 QA테스트
       */
      autoPayBenefitApplyTest: async (data) => {
        const url = `${settings.pa20ApiUrl}/lambda/provide-regular-payment-benefits?sol_no=${data.test_sol_no}`;

        return await commonSVC.sendUrl('GET', url, data);
      }
    };
  });
