'use strict';

/**
 * 갤러리아
 * Boris
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B394-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B394categorySVC, commonSVC, errorSVC, settings, siteInfo) {
    const svc = B394categorySVC;

    // 갤러리아는 카테고리에 depth가 없고,
    // 너무 많은 양의 카테고리를 한번에 불러와 출고지, 검색기능도 추가해줘야함
    $scope.spVendCtg = angular.copy(svc.spVendCtg);               // 출고지
    $scope.getSpVendList = {};                                    // 조회된 출고지
    $scope.getVendCategoryList = [];

    $scope.spBaseCtg = angular.copy(svc.spBaseCtg);               // 카테고리
    $scope.getSpBaseList = {};                                    // 조회된 카테고리
    $scope.getBaseCategoryList = [];

    $scope.spDispCtg = angular.copy(svc.spDispCtg);               // 전시카테고리
    $scope.getSpDispList = {};                                    // 조회된 전시카테고리
    $scope.getDispCategoryList = [];

    $scope.category_name = '';                                    // 카테고리 이름(외부 보여주기용)
    $scope.pumbun_no = '';                                        // 품번

    // 출고지만 선택 가능
    $scope.base_disabled = true;
    $scope.disp_disabled = true;

    $scope.vend_placeholder = '로딩중...';
    $scope.base_placeholder = '출고지를 선택해주세요.';
    $scope.disp_placeholder = '출고지를 선택해주세요.';

    $scope.setVendCtgList = async () => {
      if ($scope.spVendCtg.vendCtgSelect) {
        $scope.spBaseCtg.baseCtgSelect = null;
        $scope.spDispCtg.dispCtgSelectList = '';
        $scope.category_name = '';
        $scope.pumbun_no = '';
        $scope.selectedDefaultDisp = null;

        await afterInit($scope.spVendCtg.vendCtgSelect);
        $scope.$apply();
      }
    };

    $scope.setBaseCtgList = () => {
      if ($scope.spBaseCtg.baseCtgSelect) {
        $scope.getBaseCategoryList.some(({ categoryNo, pumbunNo, fullPath }) => {
          if (categoryNo == $scope.spBaseCtg.baseCtgSelect) {
            $scope.category_name = fullPath.replace(/>/g, ' > ');
            $scope.pumbun_no = pumbunNo;
          }
        });
      }
    };

    $scope.getDefaultDispCategoruies = () => {
      return $scope.spDispCtg.dispCtgSelectList ? $scope.spDispCtg.dispCtgSelectList.map((c) => $scope.getSpDispList.find(a => a.disp_ctg_no === c)) : [];
    };

    $scope.$on('shopInfoSave', (event, param, check) => {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      // 표준 카테고리
      if (!$scope.spVendCtg.vendCtgSelect) {
        commonSVC.showMessage('실패', '출고지를 선택하여 주십시오.');

        return false;
      } else if (!$scope.spBaseCtg.baseCtgSelect) {
        commonSVC.showMessage('실패', '표준상품군을 선택하여 주십시오.');

        return false;
      } else if ($scope.spDispCtg.dispCtgSelectList && $scope.spDispCtg.dispCtgSelectList.length > 0 && !$scope.selectedDefaultDisp) {
        commonSVC.showMessage('실패', '기본 전시카테고리를 선택하여 주십시오.');

        return false;
      }

      const cateData = {
        category_code: $scope.spBaseCtg.baseCtgSelect,
        category_names: $scope.category_name,
        // 전시카테고리 코드
        key_display_codes: $scope.spDispCtg.dispCtgSelectList.length == 0 ? '' : $scope.spDispCtg.dispCtgSelectList.join(','),
        // 츨고지 코드
        key_vend_code: $scope.spVendCtg.vendCtgSelect,
        key_pumbun_no: $scope.pumbun_no,
        key_default_display_code: $scope.selectedDefaultDisp || ''
      };

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B394',
          site_id: $scope.matchingData.site_id,
          category_code: cateData.category_code,
          category_names: cateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: cateData,
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        }
        else if ($scope.matchingData.isOnlineAdd) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      }
    };

    // 출고지 초기화
    async function init() {
      // 로딩중
      $scope.spVendCtg.vendCtgLoad = true;  // 출고지 로딩중

      // 계정정보 선 로딩후 카테고리 로딩
      const res = await shopAccountModel.load({ shop_cd: 'B394', shop_id: $scope.shop_id });

      $scope.userInfo = res.data.results;

      try {
        // 출고지 조회
        const vendCate = await commonSVC.requestPA($scope.userInfo, siteInfo.B394.domain, 'etc.get_category.vend', {}, commonModel, shopAccountModel);
        const items = vendCate.data.data.message;

        $scope.getSpVendList = items;

        $scope.getVendCategoryList = items;
      } catch (err) {
        commonSVC.showMessage('실패', '출고지 조회 실패');

        $scope.spVendCtg.vendCtgLoad = false;
        $scope.vend_placeholder = '조회 실패. 다시 조회하시거나 쇼핑몰 계정을 확인해주세요.';

        return false;
      }

      const param = {
        categoryId: $scope.matchingData.categoryId,
        shop_cd: 'B394',
        shop_id: $scope.shop_id
      };

      let result = { data: { results: 'fail' } };

      let tmp = $scope.matchingData.shop_cate_no_add_info || $scope.matchingData.sol_cate_shop_add_info;

      tmp = tmp && typeof tmp === 'string' ? JSON.parse(tmp) : tmp;

      if ($scope.matchingData.categoryId) {
        result = await categoryModel.shopInfoDetailDataLoad(param);
      } else if (tmp?.category_code) {
        result = { data: { results: 'success', data: [{ ...tmp }] } };
      }

      $scope.spVendCtg.vendCtgLoad = false;
      $scope.vend_placeholder = '검색어를 입력해주세요.';

      $timeout(() => {
        $('#spVendCtg').trigger('change');
        $('#spBaseCtg').trigger('change');
        $('#spDispCtg').trigger('change');
      });

      if (result.data.results === 'success') {
        // 이전에 저장된 것이 있었다면 자동추적 구현
        if (result.data.data.length > 0) {
          const [{ sol_cate_shop_add_info: saved_cate1 }] = result.data.data;

          let saved_cate = saved_cate1;

          if (tmp && tmp.category_code) {
            saved_cate = tmp;
          } else if (typeof saved_cate == 'string') {
            saved_cate = JSON.parse(saved_cate);
          }

          $scope.category_name = saved_cate.category_names;
          $scope.spVendCtg.vendCtgSelect = saved_cate.key_vend_code;
          // 전시, 표준상품군도 자동선택
          await afterInit(saved_cate.key_vend_code, true, saved_cate);
        } else if ($scope.matchingData.shop_cate_no_add_info || $scope.matchingData.sol_cate_shop_add_info) {
          let saved_cate = $scope.matchingData.shop_cate_no_add_info || $scope.matchingData.sol_cate_shop_add_info;

          if (typeof saved_cate == 'string') {
            saved_cate = JSON.parse(saved_cate);
          }

          $scope.category_name = saved_cate.category_names;
          $scope.spVendCtg.vendCtgSelect = saved_cate.key_vend_code;
          // 전시, 표준상품군도 자동선택
          await afterInit(saved_cate.key_vend_code, true, saved_cate);
        }
      }

      // 바인딩이 안되는 이상한 오류가 있어서 넣어둠. 삭제금지
      $scope.$apply();
    }

    // 표준, 전시 초기화
    async function afterInit(vir_vend_no, auto = false, saved_cate = null) {
      $scope.base_disabled = true;
      $scope.disp_disabled = true;
      $scope.base_placeholder = '로딩중...';
      $scope.disp_placeholder = '로딩중...';
      // 로딩중
      $scope.spBaseCtg.baseCtgLoad = true;  // 표준상품군 로딩중
      $scope.spDispCtg.dispCtgLoad = true;  // 전시카테고리 로딩중

      // 계정정보 이미 로딩됨

      try {
        // 표준상품군 조회
        const baseCate = await commonModel.pa5(
          '/channel_accounts/{uuid}/etc/get_categories/sync',
          { code: vir_vend_no },
          $scope.userInfo.pa_shop_cd,
          $scope.userInfo.shop_id,
        );
        const items = baseCate.data.results[0].data;

        $scope.getSpBaseList = items;
        $scope.getBaseCategoryList = items;

        // 전시카테고리 조회
        const disCate = await commonSVC.requestPA($scope.userInfo, siteInfo.B394.domain, 'etc.get_category.display', { vir_vend_no }, commonModel, shopAccountModel);
        const items2 = disCate.data.data.message;

        $scope.getSpDispList = items2;
        $scope.getDispCategoryList = items2;

        // init에서 자동선택 돌아갈떄
        if (auto) {
          $scope.spBaseCtg.baseCtgSelect = saved_cate.category_code;
          if (saved_cate.key_display_codes) {
            $scope.spDispCtg.dispCtgSelectList = saved_cate.key_display_codes.split(',');
          }
          if (saved_cate.key_default_display_code) {
            $scope.selectedDefaultDisp = saved_cate.key_default_display_code;
          }
          if (saved_cate.key_pumbun_no) {
            $scope.pumbun_no = saved_cate.key_pumbun_no;
          }
        }
        $scope.spBaseCtg.baseCtgLoad = false;
        $scope.spDispCtg.dispCtgLoad = false;
        $scope.base_disabled = false;
        $scope.base_placeholder = '검색어를 입력해주세요.';

        if ($scope.getDispCategoryList.length) {
          $scope.disp_disabled = false;
          $scope.disp_placeholder = '검색어를 입력해주세요.';
        } else {
          $scope.disp_disabled = true;
          $scope.disp_placeholder = '선택할 수 있는 전시 카테고리가 없습니다.';
        }

        return true;

      } catch (err) {
        commonSVC.showMessage('실패', '카테고리 조회 실패');

        return false;
      }
    }

    init();
  });
