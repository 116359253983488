'use strict';
angular.module('gmpApp')
  .directive('optionTable', function ($compile) {
    return {
      restrict: 'E',
      templateUrl: function ($element, $attrs) {
        let template_url;

        switch ($attrs.optType) {
          case '0':
            template_url = 'views/online/product/option/option_table.html';
            break;
          case '1':
            template_url = 'views/online/product/option/esm_option_table.html';
            break;
          case '2':
            template_url = 'views/online/product/option/std_option_table.html';
            break;
          case '3':
            template_url = 'views/online/product/option/cpang_option_table.html';
            break;
          case '4':
            template_url = 'views/online/product/option/wmp_option_table.html';
            break;
          case '5':
            template_url = 'views/online/product/option/hplus_option_table.html';
            break;
          case '6':
            template_url = 'views/online/product/option/galleria_option_table.html';
            break;
          // case '7':
          //   template_url = 'views/online/product/option/lotte_on_option_table.html';
          //   break;
          case '7':
            template_url = 'views/online/product/option/naver_option_table.html';
            break;
          case '8':
            template_url = 'views/online/product/option/aliexpress_option_table.html';
            break;
          case '9':
            template_url = 'views/online/product/option/alibaba_option_table.html';
            break;
          case '10':
            template_url = 'views/online/product/option/rocket_option_table.html';
            break;
          case '11':
            template_url = 'views/online/product/option/naver_single_option_table.html';
            break;
          case '12':
            template_url = 'views/online/product/option/aliexpress_global_option_table.html';
            break;
          case '13':
            template_url = 'views/online/product/option/temu_option_table.html';
            break;
        }

        return template_url;
      },
      scope: {
        data: '=',
        prodOpt: '=',
        checked: '=',
        disabled: '=',
        opTabActive: '=',
        optType: '=',
        changeOptTab: '=',
        optOverwriteCk: '=',
        optOverwrite: '=',
        prodListSet: '=',
        flag: '=',
        importOpt: '=',
        changeCateFn: '=',
        viewModelNo: '=',
        shopCd: '=',             // 쇼핑몰코드 추가 (마스터 -> 단일상품 롯데슈퍼 추가인경우 넘어옴. 롯데슈퍼는 옵션없음만 지원하므로 조합형, 독립형 미지원처리하기 위해 사용)
        ebayDepots: '='
      },
      link: function ($scope) {
        //
      },
      controller: function optionTableController(
        $rootScope, $scope, $attrs, $timeout, userInfo,
        onlineProductSVC, commonSVC,
        commonModel, supplierModel, warehouseModel, productModel, systemModel
      ) {
        $scope.sol_stock_auth = $scope.$parent.sol_stock_auth;
        $scope.checked.lotte_onBatchType = 'sale_cnt';
        $scope.user_profile = $rootScope.user_profile;
        $scope.checked.std_checkbox = false;	//옵션등록 11번가 체크박스 비활성와 확인
        $scope.popup_coupang_show = false;
        $scope.warehouseList = $scope.$parent.$parent.warehouseList;
        $scope.selectOptList = $scope.$parent.selectOptList; // esm 추천옵션 리스트
        $scope.selectNaverOptList = []; // 스마트스토어 단일상품 추천옵션 리스트

        const site_tab = ['', 'esm_', 'std_', 'cpang_', 'wmp_', 'hplus_', 'galleria_', 'naver_', 'aliexpress_', 'alibaba_', 'rocket_', 'naver_single_', 'aliexpress_global_', 'temu_'];
        const currentOpt = site_tab[parseInt($scope.optType)];
        let currentRow;
        $scope.noneOptionMatchedSet = false;   // 옵션없음일때 세트상품 매칭여부

        $scope.optionSelect = function (option, row, $event) {
          const target = $($event.target);
          let mode;

          if (target.hasClass('option-div-clicked')) {
            mode = 'remove';
            target.removeClass('option-div-clicked');
          } else {
            mode = 'add';
            target.addClass('option-div-clicked');
          }

          $(`#${$.escapeSelector(`std_attr_${row}`)}`).tagsinput(mode, option);
        };

        $scope.isCheckOpt = function (text, name, type) {
          if (type === 'S') {
            const elemVal = $(`#${$.escapeSelector(`std_attr_${name}`)}`).val();

            if (elemVal) {
              return elemVal.split(',').includes(text);
            }
          }
        };

        $scope.optionTable = function (row, $event) {
          if ($('#popup-div')[0]) {
            $('#popup-div').remove();
            if (currentRow === row.name) {
              return;
            }
          }
          currentRow = row.name;

          const optArr = [];
          const appendBody = $($event.target.parentElement);
          const templete = `
            <div id="popup-div" style="width: 520px; height: 340px; background-color: lightgray; position: absolute; z-index: 99999; box-shadow: 0px 1px 3px 0px;">
              <div class="col-xs-12" style="text-align: left; line-height: 42px">
                <a style="color: black; font-size: 18px">구매 옵션 예시(최대 50개 선택 가능)</a>
              </div>  
              <div class="col-xs-12" style="height: 250px; background-color: white; overflow-y: auto;">
                
              <table class="table mt-5 mb-5">
                <tbody>
                  <tr ng-repeat="line in optMarr" height="40">
                    <td ng-repeat="text in line" ng-class="{'option-div-clicked': isCheckOpt(text, '${row.name}', '${row.typeCd}')}" ng-click="optionSelect(text, '${row.name}', $event)" class="option-div">
                      {{text}}
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div class="col-xs-12 mt-9" style="text-align: left; line-height: 15px">
                <a style="color: black;">*옵션값은 최대 50byte (한글기준 25자, 영문/숫자 기준 50자)까지 등록 가능합니다.</a>
                </br>
                <a style="color: black;">*등록된 옵션값은 관리자에 의해 모니터링 됩니다.</a>
              </div>
            </div>
          `;

          for (const opt of row.cateOpAttrList) {
            optArr.push(opt.stdOptValNm);
          }

          const optMArr = [[]];
          let arrLength = 0;

          for (let i = 0; i < optArr.length; i++) {
            if (i && i % 4 === 0) {
              optMArr.push(
                []
              );
              arrLength++;
            }
            optMArr[arrLength].push(optArr[i]);
          }

          $scope.optMarr = optMArr;

          $($($event.target.parentElement)).append($compile(templete)($scope));

          const margin = (appendBody[0].offsetWidth - appendBody.children('button')[0].offsetWidth) / 2;
          const popupDiv = $('#popup-div');

          popupDiv.css('left', popupDiv.position().left - (popupDiv.width() - $($event.target.parentElement).width() - margin));
        };

        /**
	  	  * 현재 옵션 추출
	      */
        $scope.optGetTables = function(opt_type, options_table, opt_value, type) {
          if (opt_type !== '옵션없음' && !options_table.length) {
            commonSVC.showMessage('옵션테이블이 없습니다. 옵션을 생성해주세요.');

			      return false;
          }

          if (opt_type == '조합형') {
            const opt_obj = {
              opt1: [],
              opt2: [],
              opt3: []
            };

            // 옵션 테이블에서 옵션 정보 추출
            if (options_table.length > 0) {
              if (opt_value.length > 0 && type === 'lotte_on_') {
                opt_value.forEach((item, index) => {
                  opt_obj[`opt${index + 1}`] = item.attr.split(',');
                });
              } else {
                _.each(options_table, function(row) {
                  if (row.attr1 && !_.includes(opt_obj.opt1, row.attr1)) {
                    opt_obj.opt1.push(row.attr1);
                  }
                  if (row.attr2 && !_.includes(opt_obj.opt2, row.attr2)) {
                    opt_obj.opt2.push(row.attr2);
                  }
                  if (row.attr3 && !_.includes(opt_obj.opt3, row.attr3)) {
                    opt_obj.opt3.push(row.attr3);
                  }
                });
              }
            }

            if (['', 'wmp_', 'cpang_', 'rocket_'].includes(type)) {
              // 옵션값 변경시 tagsInput 디렉티브에서 자동으로 갱신됨
              if (opt_value[0]) {
                if (options_table[0].name1) {
                  opt_value[0].name = options_table[0].name1;
                }
                opt_value[0].attr = opt_obj.opt1.join(',');
              }

              if (opt_value[1]) {
                if (options_table[0].name2) {
                  opt_value[1].name = options_table[0].name2;
                }
                opt_value[1].attr = opt_obj.opt2.join(',');
              }

              if (opt_value[2]) {
                if (options_table[0].name3) {
                  opt_value[2].name = options_table[0].name3;
                }
                opt_value[2].attr = opt_obj.opt3.join(',');
              }
            } else if (type == 'esm_') {
              $scope.prodOpt[`${type}checked_opt`] = [];

              for (let i = 0; i < 3; i++) {
                const recommendOption = $scope.data.esm_options.find(opt => opt.name === options_table[0][`name${i + 1}`]);

                opt_value[i].name = options_table[0][`name${i + 1}`] || '';

                if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
                  opt_value[i].cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName);

                  $timeout(() => {
                    const select2 = angular.element(document.querySelector(`#esm_opt_value${i}`));

                    if (select2) {
                      select2.controller('multiSelectCombo').destroySelect2(select2);
                    }
                    opt_value[i].attr = opt_obj[`opt${i + 1}`].length ? opt_obj[`opt${i + 1}`] : [];
                    $scope.selectOptList[i] = [ ...opt_value[i].cateOpAttr ];

                    $timeout(() => {
                      select2.controller('multiSelectCombo').initSelect2(select2, {});
                    }, 0);
                  });
                } else {
                  opt_value[i].cateOpAttr = [];
                  opt_value[i].attr = opt_obj[`opt${i + 1}`].length ? opt_obj[`opt${i + 1}`].join(',') : '';
                }
              }
            } else if (type == 'naver_single_') {
              $scope.prodOpt[`${type}checked_opt`] = [];

              const naversingleOptions = $scope.data.naver_single_options.find(opt => opt.guideId === $scope.data.naver_single_guide_id);

              for (const optIdx in naversingleOptions.cateOptList) {
                const i = Number(optIdx)
                      , recommendOption = naversingleOptions.cateOptList[optIdx];

                opt_value[optIdx].name = options_table[0][`name${i + 1}`] || '';

                if (recommendOption?.optionValues?.length) {
                  opt_value[optIdx].cateOpAttr = recommendOption.optionValues.map(options => ({
                    ...options,
                    name: options.valueName
                  }));

                  $timeout(() => {
                    const select2 = angular.element(document.querySelector(`#naver_single_opt_value${i}`));

                    if (select2) {
                      select2.controller('multiSelectCombo').destroySelect2(select2);
                    }
                    opt_value[i].attr = opt_obj[`opt${i + 1}`].length ? opt_obj[`opt${i + 1}`] : [];
                    $scope.selectNaverOptList[i] = [ ...opt_value[i].cateOpAttr,
                      ...opt_value[i].attr
                        .filter(value => !opt_value[i].cateOpAttr.some(item => item.name === value))
                        .map(opt => ({ name: opt }))
                    ];

                    $timeout(() => {
                      select2.controller('multiSelectCombo').initSelect2(select2, {});
                    }, 0);
                  });
                } else {
                  opt_value[i].cateOpAttr = [];
                  opt_value[i].attr = opt_obj[`opt${i + 1}`].length ? opt_obj[`opt${i + 1}`].join(',') : '';
                }
              }
            } else {
              for (let i = 1; i < 4; i++) {
                if (options_table[0][`name${i}`]) {
                  const opt_findIdx = _.findIndex(opt_value, function(row) {
                    if (type == 'std_') {
                      return options_table[0][`name${i}`] == row.name && options_table[0][`name${i}_cd`] == row.name_cd;
                    } else {
                      return options_table[0][`name${i}`] == (row.name || row.attributeTypeName);
                    }
                  });

                  if (opt_findIdx !== -1) {
                    if (type == 'std_') {
                      opt_value[opt_findIdx].attr = opt_obj[`opt${i}`].map(opt => !isNaN(parseInt(opt)) ? parseInt(opt) : opt).join(',');
                    } else {
                      opt_value[opt_findIdx].attr = opt_obj[`opt${i}`].join(',');
                    }

                    opt_value[opt_findIdx].checked = true;
                  }
                }
              }
            }
          } else {
            const opt = [], opt_name = [];

            // 옵션 테이블에서 옵션 정보 추출
            if (options_table.length > 0) {
              _.each(options_table, function(row) {
                if (!row.name) {
                  return false;
                }
                if (!opt[row.name]) {
                  opt[row.name] = [];
                }
                if (row.name && !_.includes(opt_name, row.name)) {
                  opt_name.push(row.name);
                }
                if (row.attr && !_.includes(opt[row.name], row.attr)) {
                  opt[row.name].push(row.attr);
                }
              });
            }

            // 옵션값 변경시 tagsInput 디렉티브에서 자동으로 갱신됨
            opt_value[0].name = opt_name[0] || '';
            opt_value[1].name = opt_name[1] || '';
            opt_value[2].name = opt_name[2] || '';

            if (type == 'esm_') {
              for (let i = 0; i < 1; i++) {
                const recommendOption = $scope.data.esm_options.find(opt => opt.name === opt_name[i]);

                opt_value[i].name = opt_name[i] || '';

                if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
                  opt_value[i].cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName);

                  $timeout(() => {
                    const select2 = angular.element(document.querySelector(`#esm_opt_value${i}`));

                    if (select2) {
                      select2.controller('multiSelectCombo').destroySelect2(select2);
                    }

                    opt_value[i].attr = opt[opt_name[i]]?.length ? opt[opt_name[i]] : [];
                    $scope.selectOptList[i] = [ ...opt_value[i].cateOpAttr ];

                    $timeout(() => {
                      select2.controller('multiSelectCombo').initSelect2(select2, {});
                    }, 0);
                  });
                } else {
                  opt_value[i].cateOpAttr = [];
                  opt_value[i].attr = opt[opt_name[i]]?.length ? opt[opt_name[i]].join(',') : '';
                }
              }
            } else {
              opt_value[0].attr = opt[opt_name[0]]?.length ? opt[opt_name[0]].join(',') : '';
              opt_value[1].attr = opt[opt_name[1]]?.length ? opt[opt_name[1]].join(',') : '';
              opt_value[2].attr = opt[opt_name[2]]?.length ? opt[opt_name[2]].join(',') : '';
            }
          }

          return true;
        };

        // 옵션 가이드 노출
        $scope.opt_add_guide = function () {
          commonSVC.openModal('xl', {}, 'OnlineProductOptGuide', 'views/online/product/modals/add_opt_guide.html');
        };
        //옵션 초기화
        $scope.optReset = function (type) {
          const site_type = site_tab[type];

          $scope.prodOpt[`${site_type}options_table`] = [];
          $(`.${site_type}attr`).tagsinput('removeAll');

          switch (type) {
            case 1:
            case 3:
            case 0:
            case 4:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
              $scope.prodOpt[`${site_type}opt`] = angular.copy(onlineProductSVC.default_opt);
              break;
            case 5:
              $scope.prodOpt[`${site_type}opt`] = _.filter($scope.prodOpt[`${site_type}opt`], item => {
                return item.isRequired;
              });
              break;
            case 6:
              $scope.data[`${site_type}options`] = $scope.data[`${site_type}options`].map((d) => ({
                ...d,
                checked: false
              }));
              $scope.prodOpt[`${site_type}opt`] = [];
          }
        };

        //SKU상품 매칭 모달
        $scope.prodProductSearch = function (idx, list, type, add, confirmflag, mainProd, tabNo) {
          // ESM 물류 상품인경우 G마켓 물류 배송처가 설정된 기초상품만 설정가능하므로 스마일배송처만 넘겨줌.
          const resolve = {
            data: {
              warehouseList: $scope.checked.smileProd && currentOpt === 'esm_' ? $scope.warehouseList.filter(o => o.ebaydepot_yn == 1) : $scope.warehouseList.filter(o => o.ebaydepot_yn != 2),
              type: type,
              modal_type: $scope.checked.smileProd && currentOpt === 'esm_' ? 'smileProd' : ''
            }
          };
          const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

          redata.result.then(function (re) {
            list[idx].opt_img = '';
            //일반옵션 매칭시 기본등록 체크해제
            if ($scope.opTabActive === 0) {
              $scope.checked.esm_defaultOpt = false;
            }

            //세트상품인 경우
            if (re.isSet) {
              $scope.noneOptionMatchedSet = true;
              setProdMatch(re.data, idx, list, type, add, tabNo);
            }
            //SKU상품인 경우
            else {
              $scope.noneOptionMatchedSet = false;
              productMatch(re, idx, list, type, add, confirmflag, mainProd, tabNo);
            }
            if (!$scope.data.add_single_prod) {
              $scope.prodListSet();
            }
            $(`#${currentOpt}sku_cd_${idx}`).focus();
          });
        };

        //로켓그로스 SKU 매칭 모달 (쿠팡 & 로켓그로스 테이블 전용)
        // rocket_options_table 내 쿠팡, 로켓그로스 매칭 정보가 각각 coupang, rocket 필드에 저장됨
        $scope.rocketProdProductSearch = function (idx, list, type, shop) {
          if (!list[idx][shop]) {
            list[idx][shop] = {};
          }

          let warehouseList = $scope.warehouseList.filter(o => o.ebaydepot_yn !== 2);

          if (shop === 'rocket') {
            warehouseList = $scope.warehouseList.filter(o => o.ebaydepot_yn === 2);
          }

          // ESM 물류 상품인경우 G마켓 물류 배송처가 설정된 기초상품만 설정가능하므로 스마일배송처만 넘겨줌.
          const resolve = {
            data: {
              warehouseList,
              type: type,
              modal_type: '',
              shop
            }
          };
          const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

          redata.result.then(function (re) {
            list[idx].opt_img = '';

            //세트상품인 경우
            if (re.isSet) {
              $scope.noneOptionMatchedSet = true;

              let main_product;

              //세트상품의 대표상품 구함
              angular.forEach(re.data.prod, function (row) {
                if (row.prod_no == re.data.set.main_prod_no) {
                  main_product = row;
                }
              });

              //세트상품의 대표상품 정보로 옵션 리스트에 등록
              angular.extend(list[idx][shop], main_product);
              list[idx][shop].prod_no = main_product.prod_no;
              list[idx][shop].prod_name = re.data.set.set_name;
              list[idx].prod_img = re.data.set.set_img ? re.data.set.set_img : (main_product.prod_img || '/assets/images/upload.png');
              list[idx][shop].pack_unit = re.data.set.set_pack_unit;
              // list[idx][shop].prod_weight = $scope.prod_auto_set_yn ? _(result.prod).map('prod_weight').sum() : null;
              list[idx][shop].set_no = re.data.set.set_no;
              list[idx][shop].set_cd = list[idx][shop].sku_cd = re.data.set.set_cd;
              list[idx][shop].prod_status = '정상';
              list[idx][shop].checked = false;
              list[idx][shop].set_prod_list = re.data.prod; //세트에 묶인 상품 리스트, 상품 등록시 쓰임
            }
            //SKU상품인 경우
            else {
              $scope.noneOptionMatchedSet = false;
              // 원소 할당 안된경우 오브젝트 할당 2018-05-03 roy 추가
              if (list[idx][shop] === undefined) {
                list[idx][shop] = {};
              }

              angular.extend(list[idx][shop], re);
              list[idx][shop].sku_cd = re.sku_cd;
              list[idx].prod_img = re.prod_img || '/assets/images/upload.png';
              list[idx][shop].safe_stock = re.stock_cnt_safe;
              list[idx][shop].pack_unit = re.pack_unit;
              list[idx][shop].prod_status = '정상';             //result.stock_status == '재고부족' ? '품절' : '정상';
              // list[idx][shop].prod_weight = $scope.prod_auto_set_yn ? result.prod_weight : null;
              list[idx][shop].checked = false;
              list[idx][shop].set_no = 0;
              list[idx][shop].set_cd = null;
            }
            if (!$scope.data.add_single_prod) {
              $scope.prodListSet();
            }
            $(`#${shop}_sku_cd_${idx}`).focus();
          });
        };

        // 매칭된 SKU상품 삭제
        $scope.prodProductRemove = async function (row, shop) {
          commonSVC.showConfirmCustom({
            title: 'SKU상품 매칭 해제 안내',
            text: '해당 옵션에 매칭된 SKU상품의 정보를 삭제합니다.\n매칭을 해제하는 경우 옵션의 이미지도 함께 삭제되며,\n검색 버튼 클릭으로 다른 SKU상품을 매칭할 수 있습니다',
            showCancelButton: true,
            confirmButtonText: '삭제',
            cancelButtonText: '취소'
          }).then((confirm) => {
            if (confirm) {
              row.prod_img = '/assets/images/upload.png';
              row.opt_img = '';
              row.sku_cd = null;
              row.set_no = null;
              row.set_cd = null;
              row.depot_no = null;
              row.depot_name = null;
              row.prod_no = null;
              row.prod_name = null;
              row.set_name = null;
              row.sale_price = null;
              row.attri = null;
              row.stock_cnt = null;
              row.safe_stock = null;

              if (shop) {
                row[shop].prod_img = '/assets/images/upload.png';
                row[shop].sku_cd = null;
                row[shop].set_no = null;
                row[shop].set_cd = null;
                row[shop].depot_no = null;
                row[shop].depot_name = null;
                row[shop].prod_no = null;
                row[shop].prod_name = null;
                row[shop].set_name = null;
                row[shop].sale_price = null;
                row[shop].attri = null;
                row[shop].stock_cnt = null;
                row[shop].safe_stock = null;
              }

              $scope.noneOptionMatchedSet = false;
              $timeout(() => { /** empty */ });
            }
          });
        };

        //SKU상품 상세 페이지 모달창
        $scope.showDetail = function(sku_cd, prod_number, prod_sol_no, shop = '') {

          // SKU상품 수정 권한 확인. 2019-01-03 rony
          if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
            return false;
          }

          const resolve = {
            data: {
              sku: sku_cd,
              number: prod_number,
              prodSolNo: prod_sol_no,
              detailType: 'edit'
            }
          };

          // 매칭된 SKU상품이 로그인계정 상품이 아닌 경우 상세에서 수정불가
          if (+prod_sol_no !== +$rootScope.user_profile.sol_no) {
            resolve.data.nonEdit = true;
          }

          resolve.supplierList = function() {
            return supplierModel.ListAll({ use_yn: true });
          };
          resolve.warehouseList = function() {
            if (shop === 'rocket') {
              return warehouseModel.ListAll({ use_yn: true, });
            }

            return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
          };
          resolve.madeinList = function() {
            return productModel.getMadein();
          };
          resolve.systemList = function() {
            return systemModel.load();
          };
          const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

          modal.result.then(function() {
            $scope.grid.dtInstance.reloadData(function() {}, false);
          });
        };

        //세트상품 옵션 테이블에 매칭
        function setProdMatch(result, idx, list) {
          let main_product;

          //세트상품의 대표상품 구함
          angular.forEach(result.prod, function (row) {
            if (row.prod_no == result.set.main_prod_no) {
              main_product = row;
            }
          });

          //세트상품의 대표상품 정보로 옵션 리스트에 등록
          angular.extend(list[idx], main_product);
          list[idx].prod_no = main_product.prod_no;
          list[idx].prod_name = result.set.set_name;
          list[idx].prod_img = result.set.set_img ? result.set.set_img : (main_product.prod_img || '/assets/images/upload.png');
          list[idx].pack_unit = result.set.set_pack_unit;
          // list[idx].prod_weight = $scope.prod_auto_set_yn ? _(result.prod).map('prod_weight').sum() : null;
          list[idx].set_no = result.set.set_no;
          list[idx].set_cd = list[idx].sku_cd = result.set.set_cd;
          list[idx].prod_status = '정상';
          list[idx].checked = false;
          list[idx].set_prod_list = result.prod; //세트에 묶인 상품 리스트, 상품 등록시 쓰임

        }

        //SKU상품 옵션 테이블에 매칭
        function productMatch(result, idx, list, type, add, confirmflag, mainProd, tabNo) {
          // 원소 할당 안된경우 오브젝트 할당 2018-05-03 roy 추가
          if (list[idx] === undefined) {
            list[idx] = {};
          }

          angular.extend(list[idx], result);
          list[idx].sku_cd = result.sku_cd;
          list[idx].prod_img = result.prod_img || '/assets/images/upload.png';
          list[idx].safe_stock = result.stock_cnt_safe;
          list[idx].pack_unit = result.pack_unit;
          list[idx].prod_status = '정상';             //result.stock_status == '재고부족' ? '품절' : '정상';
          // list[idx].prod_weight = $scope.prod_auto_set_yn ? result.prod_weight : null;
          list[idx].checked = false;
          list[idx].set_no = 0;
          list[idx].set_cd = null;
        }

        /**
					 * 세트상품 전체 선택 및 해제
				 */
        $scope.stockAllCheck = function (ob, flag) {
          for (const i in ob) {
            ob[i].checked = flag;
          }
        };

        //전체 체크박스 확인
        $scope.checkboxClick = function(ob, cbflag) {
          for (const i in ob) {
            if (!ob[i].checked) {
              $scope.flag[cbflag] = false;

              return;
            }
          }
          $scope.flag[cbflag] = true;
        };

        // 생성하려는 옵션이 30개가 넘을 경우 알림 메시지를 주기 위한 스코프 함수
        $scope.optCnt = function () {
          const prodOpt = $scope.prodOpt[`${currentOpt}checked_opt`];

          if (!prodOpt) {
            return false;
          }

          return prodOpt.reduce((prev, curr) => {
            const sumLength = curr?.attr ? (currentOpt === 'esm_' && typeof curr.attr !== 'string' ? curr.attr.length : curr.attr.split(',').length) : 0;

            return prev * sumLength;
          }, 1) > 30;
        };

        $scope.simpleOptCreate = () => {
          if (!$scope.prodOpt.naver_opt[0].attr || !$scope.prodOpt.naver_opt[1].attr) {
            const arr = !$scope.prodOpt.naver_opt[0].attr ? ['선택된 색상이 없습니다.', '색상을 선택해 주세요.\n(한가지 색상만 선택하셔도 됩니다.)']
              : ['선택된 사이즈가 없습니다.', '사이즈를 선택해 주세요.\n(한가지 사이즈만 선택하셔도 됩니다.)'];

            commonSVC.showMessage(...arr);

            return false;
          }
          if ($scope.prodOpt.naver_options_table.length) {
            const attr1 = _.uniq($scope.prodOpt.naver_options_table.map(o => o.attr1)).join();
            const attr2 = `${$scope.prodOpt.naver_options_table[0].name2_cd}${_.uniq($scope.prodOpt.naver_options_table.map(o => o.attr2)).join()}`;

            const newAttrSize = `${$scope.prodOpt.naver_opt[1].name_cd}${$scope.prodOpt.naver_opt[1].attr}`;

            if (attr1 === $scope.prodOpt.naver_opt[0].attr && attr2 === newAttrSize) {
              commonSVC.showMessage('변경사항이 없습니다.');

              return false;
            }
            commonSVC.showConfirmCustom({
              title: '옵션 변경 안내',
              text: '이미 생성된 옵션과 다른 항목이 있을 경우 [옵션생성] 후 필수값을 추가로 입력해 주셔야 합니다.\n설정하신 색상과 사이즈로 변경하시겠습니까?',
              confirmButtonText: '예',
              cancelButtonText: '아니오'
            }, async (confirm) => {
              if (confirm === true) {
                await $scope.optCreate(7);
              } else {
                const attr1_cd = _.uniq($scope.prodOpt.naver_options_table.map(o => o.attr1_cd));
                const attr2_cd = _.uniq($scope.prodOpt.naver_options_table.map(o => o.attr2_cd));

                // 색상 복구
                $scope.prodOpt.naver_opt[0].selected_attribute = [];
                $scope.prodOpt.naver_opt[0].sub_group[0].attribute.forEach(att => att.selected = false);
                attr1_cd.forEach(cd => {
                  const att = $scope.prodOpt.naver_opt[0].sub_group[0].attribute.find(a => a.attributeValueId === cd);
                  att.selected = true;
                  $scope.prodOpt.naver_opt[0].selected_attribute.push(att);
                });
                $scope.prodOpt.naver_opt[0].attr = $scope.prodOpt.naver_opt[0].selected_attribute.map(a => a.attributeValueName).join();
                $scope.prodOpt.naver_opt[0].attr_cd = $scope.prodOpt.naver_opt[0].selected_attribute.map(a => a.attributeValueId).join();

                // 사이즈 복구
                $scope.prodOpt.naver_opt[1].selected_attribute = [];
                $scope.prodOpt.naver_opt[1].selected_sub_group_name = $scope.prodOpt.naver_opt[1].sub_group.find(s => s.sub_group_id === $scope.prodOpt.naver_options_table[0].name2_cd).sub_group_name;
                $scope.changeNaverSizeList();
                $scope.prodOpt.naver_opt[1].selected_sub_group.forEach(att => att.selected = false);
                attr2_cd.forEach(cd => {
                  const att = $scope.prodOpt.naver_opt[1].selected_sub_group.find(a => a.attributeValueId === cd);
                  att.selected = true;
                  $scope.prodOpt.naver_opt[1].selected_attribute.push(att);
                });
                $scope.prodOpt.naver_opt[1].attr = $scope.prodOpt.naver_opt[1].selected_attribute.map(a => a.attributeValueName).join();
                $scope.prodOpt.naver_opt[1].attr_cd = $scope.prodOpt.naver_opt[1].selected_attribute.map(a => a.attributeValueId).join();

              }
              $scope.$apply();
            });
          } else {
            $scope.optCreate(7);
          }
        };

        // 옵션 생성
        $scope.optCreate = function (type) {
          const optType = site_tab[type];

          _.forEach($scope.prodOpt[`${optType}opt`], (data) => {
            const new_list = [];

            if (data.attr) {
              const attrList = ['esm_', 'naver_single_'].includes(optType) && typeof data.attr !== 'string' ? data.attr : data.attr.split(',');
              if (!(['esm_', 'naver_single_'].includes(optType) && typeof data.attr !== 'string')) {
                data.attr = attrList.map(v => v.trim()).join(',');
              }

              // 알리는 attr 값이 변해도 attr_cd 값이 필요함
              if (optType === 'aliexpress_') {
                const attrCdList = attrList.map(v => data.cateOpAttr.find(({ ObjOptEssenName }) => ObjOptEssenName === v)?.ObjOptClaseEssenNo);
                data.attr_cd = attrCdList.reduce((pre, curr) => pre && !!curr, true) ? attrCdList.join(',') : '';
              }
              if (optType === 'alibaba_') {
                const attrCdList = attrList.map(v => data.cateOpAttr.find(({ ObjOptEssenName }) => ObjOptEssenName === v)?.ObjOptClaseEssenNo);
                data.attr_cd = attrCdList.reduce((pre, curr) => pre && !!curr, true) ? attrCdList.join(',') : '';
              }
              if (optType === 'aliexpress_global_') {
                const attrCdList = attrList.map(v => data.cateOpAttr.find(({ ObjOptEssenName }) => ObjOptEssenName === v)?.ObjOptClaseEssenNo);
                data.attr_cd = attrCdList.reduce((pre, curr) => pre && !!curr, true) ? attrCdList.join(',') : '';
              }
              if (optType === 'temu_') {
                const attrCdList = attrList.map(v => data.cateOpAttr.find(({ ObjOptEssenName }) => ObjOptEssenName === v)?.ObjOptClaseEssenNo);
                data.attr_cd = attrCdList.reduce((pre, curr) => pre && !!curr, true) ? attrCdList.join(',') : '';
              }
            }
            if (data.unit) {
              const spread_opt = ['esm_', 'naver_single_'].includes(optType) && typeof data.attr !== 'string' ? data.attr : data.attr.split(',');

              _.forEach(spread_opt, (unit) => {
                const re = new RegExp(`${data.unit}$`, 'g');
                const is_match = unit.match(re);

                if (!is_match) {
                  unit = unit + data.unit;
                }
                new_list.push(unit);
              });

              data.attr = ['esm_', 'naver_single_'].includes(optType) && typeof data.attr !== 'string' ? new_list : new_list.join();
            }
          });

          const optTab = $scope.prodOpt[`${optType}opt`];

          if ($scope.data[`${optType}opt_type`] !== '옵션없음') {
            // 옵션명이나 옵션값 둘중 하나만 입력한 라인이 있는경우 옵션생성 실패처리
            const optPropChk = optTab.find(opt => {
              // esm, naver_single은 attr가 배열로 들어와 배열길이로 체크
              const atttCheck = ['esm_', 'naver_single_'].includes(optType) ? !!opt.attr.length : !!opt.attr;

              return !opt.name && atttCheck || opt.name && !atttCheck;
            });

            const emptyOpt = optTab.findIndex(opt => (!opt.name));
            const optSize = optTab.filter(opt => opt.name).length;

            if (optPropChk) {
              commonSVC.showMessage('옵션생성 실패', '옵션명과 옵션값을 모두 입력해주세요');

              return false;
            } else if (emptyOpt !== -1 && emptyOpt < optSize) {
              commonSVC.showMessage('옵션생성 실패', '옵션값은 빈칸 없이 위에서 차례로 입력해 주세요.');

              return false;
            }

            if (optType === 'aliexpress_') {
              for (let i = 1; i < 4; i++) {
                $scope.disabled[`aliexpress_opt_not_color${i}`] = true;
              }

              // 옵션명이 color면 disabled.aliexpress_opt_not_color = false 처리
              if (optTab.length && optTab.find(({ name }) => ['Color', 'Metal Color', 'Scent Type'].includes(name))) {
                const ind = optTab.findIndex(({ name }) => ['Color', 'Metal Color', 'Scent Type'].includes(name));
                $scope.disabled[`aliexpress_opt_not_color${ind + 1}`] = false;
              }

            }
            if (optType === 'alibaba_') {
              // 옵션명이 color면 disabled.alibaba_opt_not_color = false 처리
              if (optTab.length && optTab.find(({ name }) => name?.toUpperCase() === 'COLOR')) {
                const ind = optTab.findIndex(({ name }) => name?.toUpperCase() === 'COLOR');
                $scope.disabled[`alibaba_opt_not_color${ind + 1}`] = false;
              }

            }
            if (optType === 'alibaba_') {
              // 옵션명이 color면 disabled.alibaba_opt_not_color = false 처리
              if (optTab.length && optTab.find(({ name }) => name?.toUpperCase() === 'COLOR')) {
                const ind = optTab.findIndex(({ name }) => name?.toUpperCase() === 'COLOR');
                $scope.disabled[`alibaba_opt_not_color${ind + 1}`] = false;
              }

            }
            if (optType === 'aliexpress_global_') {
              for (let i = 1; i < 4; i++) {
                $scope.disabled[`aliexpress_global_opt_not_color${i}`] = true;
              }

              // 옵션명이 color면 disabled.aliexpress_global_opt_not_color = false 처리
              if (optTab.length && optTab.find(({ name }) => ['Color', 'Metal Color', 'Scent Type'].includes(name))) {
                const ind = optTab.findIndex(({ name }) => ['Color', 'Metal Color', 'Scent Type'].includes(name));
                $scope.disabled[`aliexpress_global_opt_not_color${ind + 1}`] = false;
              }

            }
            if (optType === 'temu_') {
              // 테무의 경우 temu_option_max가 0이면 추천옵션 및 추천옵션 값 선택 아니면 추천옵션 및 직접입력
              $scope.disabled.temu_direct_opt = $scope.data.temu_option_max !== 0;
            }
          }

          const opt_data = { opt: angular.copy(optTab) };

          if (['esm_', 'naver_single_'].includes(optType)) {
            for (const opt of opt_data.opt) {
              if (opt.attr && typeof opt.attr !== 'string') {
                opt.attr = opt.attr.join();
              }
            }
          }

          const optData = {
            in_data: opt_data,
            in_re: '',
            in_opt_type: $scope.data[`${optType}opt_type`],
            create_action: ''
          };

          reStoreOpt(optData);
        };

        /**
				 * 옵션추가
				 */
        $scope.add_sub_opt = function(type, ob, opt) {

          if (currentOpt == '' && $scope.data.opt_type == '독립형') {
            commonSVC.showMessage('실패', '독립형 옵션에 옵션값을 추가 하고자 하는 경우에는 상위 <옵션생성>을 통해 추가해 주셔야 합니다. ');

            return false;
          }
          let maxCount = 10;

          if (!(currentOpt == '' || currentOpt == 'wmp_')) {
            opt = _.filter(opt, 'checked');
          }

          // 옵션추가는 제한없음
          if (type == 'opt') {
            maxCount = 99999;
          }
          if (ob.length < maxCount) {
            ob.push({
              prod_img: '/assets/images/upload.png',
              parent: -1,
              opt_name1: opt[0] ? opt[0].name || opt[0].attributeTypeName : ob[0].name1 || '',
              opt_name2: opt[1] ? opt[1].name || opt[1].attributeTypeName : ob[0].name2 || '',
              opt_name3: opt[2] ? opt[2].name || opt[2].attributeTypeName : ob[0].name3 || '',
              name1: opt[0] ? opt[0].name || opt[0].attributeTypeName : ob[0].name1 || '',
              name2: opt[1] ? opt[1].name || opt[1].attributeTypeName : ob[0].name2 || '',
              name3: opt[2] ? opt[2].name || opt[2].attributeTypeName : ob[0].name3 || '',
              name1_cd: opt[0] ? opt[0].name_cd : ob[0].name1_cd || '',
              name2_cd: opt[1] ? opt[1].name_cd : ob[0].name2_cd || '',
              name3_cd: opt[2] ? opt[2].name_cd : ob[0].name3_cd || '',
              opt_img: null,
              skuCheck: false,
              attr1: '',
              attr2: '',
              attr3: '',
              attr1_cd: 0,
              attr2_cd: 0,
              attr3_cd: 0,
              opt_add_price: 0,
              pack_unit: 1,
              prod_status: '정상',
              ...(currentOpt === 'rocket_' && { coupang: { sku_cd: '', prod_status: '정상' }, rocket: { sku_cd: '', cnt_limit: 0, prod_status: '정상' } }),
              ...(currentOpt === 'esm_' && $scope.checked.smileProd && { cnt_limit: 99999 })
            });
          }
        };

        /**
				 * 옵션삭제
				 */
        $scope.del_sub_opt = function (ob) {
          for (let i = 0; i < ob.length; i++) {
            if (ob[i].checked == true) {
              //대표상품 지정인 경우 해제
              ob.splice(i, 1);
              i = i - 1;
            }
          }
        };
        /**
          * 세트 상세보기
   			  */
        $scope.setShowDetail = function(set_no) {
          const resolve = {
            data: { from: '수정', set_no: set_no }
          };

          commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
        };

        //옵션 이동
        $scope.moveOpt = function (type, upward, position) {
          const main_prod_type = '';

          const opt_table = $scope.prodOpt[`${currentOpt}options_table`];

          const obj = {
            upward: upward,
            position: position,
            opt_table: opt_table,
            add: main_prod_type === type
          };

          const resultOpt =	onlineProductSVC.optMoveSet(obj);

          if (resultOpt.state) {
            $scope.prodOpt[`${currentOpt}options_table`] = resultOpt.data;
          }
        };

        /**
 				  *  롯데ON 선택옵션(상품 등록)
 				  */
        $scope.select_opt = (shop, item, $event) => {
          item.checked = !item.checked;

          if (shop === 'lotte_on' && item.checked === true && $scope.prodOpt.lotte_on_opt.length === 3) {
            if ($scope.prodOpt.lotte_on_opt.every(item => item.name)) {
              item.checked = false;

              if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
              }

              return;
            }
          }

          // 필수옵션 제외 모두 제거
          $scope.prodOpt[`${shop}_opt`] = _.filter($scope.prodOpt[`${shop}_opt`], function(item) {
            return item.isRequired;
          });

          // 선택된 선택옵션 추가
          _.forEach($scope.data[`${shop}_options`].optional, function(item) {
            if (item.checked) {
              $scope.prodOpt[`${shop}_opt`].push(angular.copy(item));
            }
          });

          $scope.prodOpt[`${shop}_options_table`] = [];
        };
        /**
        * 갤러리아 상품등록 선택옵션(상품 등록)
        */
        $scope.galleria_select_opt = (item) => {
          if (!item.checked) {
            $scope.prodOpt.galleria_opt.push(angular.copy(item));
          } else {
            // 선택된 옵션 삭제
            $scope.prodOpt.galleria_opt.forEach(function(item_, idx) {
              if (item.name === item_.name) {
                $scope.prodOpt.galleria_opt.splice(idx, 1);

                return;
              }
            });
          }

          item.checked = !item.checked;

          $scope.prodOpt.galleria_options_table = [];
        };

        /**
   				* 옵션 조합 후 상품 매칭
   			*/
        function matchOptions(type, re) {
          const ob = $scope.prodOpt[`${currentOpt}options_table`];

          //SKU상품 신규 생성시
          if (re.options_list) {
            for (const i in re.options_list) {
              const optData = {};

              optData.prod_img = re.results[i].prod_img;
              optData.sku_cd = re.results[i].sku_cd;
              optData.prod_no = re.results[i].number;
              optData.depot_no = re.results[i].depot_no;
              optData.prod_name = re.options_list[i].prod_name;
              optData.sale_price = re.options_list[i].product_price;
              optData.real_stock = re.options_list[i].real_stock;
              optData.safe_stock = re.options_list[i].safe_stock;
              optData.pack_unit = 1;
              optData.madein_etc = re.etcMadein;
              optData.madein_type = re.madein_type;
              optData.madein_group = re.madein_group;
              optData.madein_name = re.madein_name;
              optData.multi_madein_yn = re.multi_madein_yn;
              optData.tax_type = re.options_list[i].tax_type;
              optData.model = re.options_list[i].model;
              optData.brand = re.options_list[i].brand;
              optData.maker = re.options_list[i].maker;
              optData.prod_status = '정상';//re.options_list[i].stock_status == '재고부족' ? '품절' : '정상';

              if (currentOpt === 'rocket_') {
                angular.extend(ob[i].coupang, ob[i]);
              } else {
                angular.extend(ob[i], optData);
              }
            }
          }
          //스타일 코드 매칭시
          else {
            for (const k in re) {
              for (const j in ob) {
                const ob_attri = ob[j].attr1 + ob[j].attr2 + ob[j].attr3;
                const re_attri = re[k].attri.replace(/,/gi, '');

                //스타일코드 옵션과 조합된 옵션이 같은 곳에 해당 상품 매칭
                if (ob_attri == re_attri) {
                  if (currentOpt === 'rocket_') {
                    // 로켓그로스 배송처
                    if (re[k].ebaydepot_yn == 2) {
                      angular.extend(ob[j].rocket, re[k]);
                    } else {
                      angular.extend(ob[j].coupang, re[k]);
                      ob[j].coupang.sale_price = re[k].product_price;
                      ob[j].coupang.pack_unit = 1;
                      ob[j].coupang.prod_status = '정상'; //re[i].stock_status == '재고부족' ? '품절' : '정상';
                      ob[j].prod_img = re[k].prod_img;
                    }
                  } else {
                    angular.extend(ob[j], re[k]);
                    ob[j].sale_price = re[k].product_price;
                    ob[j].pack_unit = 1;
                    ob[j].prod_status = '정상'; //re[i].stock_status == '재고부족' ? '품절' : '정상';
                  }

                  break;
                }
              }
            }

            //매칭 안된 상품 리스트에서 제거
            _.each(ob, function (row) {
              if (currentOpt !== 'rocket_') {
                if (!row.sku_cd) {
                  row.checked = true;
                }
              } else {
                if (!row.coupang.sku_cd && !row.rocket.sku_cd) {
                  row.checked = true;
                }
              }
            });
            $scope.del_sub_opt(ob);

          }
        }

        //옵션 테이브 세팅
        //in_data, in_re, in_opt_type, create_action
        function reStoreOpt(paramData) {
          const exceptShopCds = ['A112'];
          const in_data = paramData.in_data,
                in_re = paramData.in_re,
                in_opt_type = paramData.in_opt_type;

          // 2018-09-18 chris 동일한 옵션은 입력값 유지하기위해 옵션 테이블 정보 백업
          const options_table_bak = {};

          _.each(angular.copy($scope.prodOpt[`${currentOpt}options_table`]), function (opt) {
            if ($scope.data[`${currentOpt}opt_type`] == '조합형') {
              options_table_bak[opt.name1 + opt.name2 + opt.name3 + opt.attr1 + opt.attr2 + opt.attr3] = opt;
            } else {
              options_table_bak[opt.name + opt.attr] = opt;
            }
          });

          // 옵션명으로 동일한 옵션인지 체크후 동일한 옵션이면 백업한 옵션정보로 넣어줌
          const new_options_table = onlineProductSVC.setOptionsTable({ ...in_data, std_ol_yn: $scope.checked.smileProd && currentOpt === 'esm_' ? 2 : 0 }, in_opt_type, null, false);

          _.each(new_options_table, function (opt, key) {
            let k = '';

            if ($scope.data[`${currentOpt}opt_type`] == '조합형') {
              k = (opt.name1 || '') + (opt.name2 || '') + (opt.name3 || '') + (opt.attr1 || '') + (opt.attr2 || '') + (opt.attr3 || '');
            } else {
              k = opt.name + opt.attr;
            }

            if (currentOpt === 'rocket_') {
              new_options_table[key].coupang = angular.copy(new_options_table[key]);
              new_options_table[key].rocket = angular.copy(new_options_table[key]);

              new_options_table[key].rocket.cnt_limit = 0;
            }

            if (options_table_bak[k] && !exceptShopCds.includes(options_table_bak[k].shop_cd)) {
              new_options_table[key] = options_table_bak[k];
            }
          });

          $scope.prodOpt[`${currentOpt}options_table`] = new_options_table;

          if (in_re) {
            matchOptions(false, in_re);
          }
        }
        /**
				 * 기존 선택된 추천 옵션 필터링
				 */
        // $scope.isSelected = function(idx, attr) {
        //   var attArr = $scope.prodOpt.opt[idx].attr.split(',');
        //   var attArr2 = [];

        //   if (_.isArray(attArr) && attArr.length > 0) {
        //     _.each(attArr, function(row) {
        //       attArr2.push(row.trim());
        //     });
        //   }

        //   if (attArr2.indexOf(attr) != -1) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // };

				  /**
				 	* 판매수량 공통입력
				 */
        $scope.submitCnt = function(ob, type) {
          let flag = false;

          if (type != 'prod_status' && $scope.prodOpt.cnt_limit_all == '') {
            commonSVC.showMessage('실패', '일괄 입력할 값을 입력해 주십시오.');

            return false;
          }

          angular.forEach(ob, function(row) {
            if (row.checked) {
              if (currentOpt === 'rocket_') {
                if (type === 'prod_status' && $scope.prodOpt.cnt_limit_all == '') {
                  commonSVC.showMessage('일괄입력 실패', '[정상] 또는 [품절] 중의 하나의 상태를 선택해 주시면 일괄 변경이 가능합니다.');

                  return false;
                }

                // 판매 수량은 판매자배송 옵션만 수정 & 나머지는 개별 수정 가능
                if (type == 'sale_cnt') {
                  row.coupang.cnt_limit = Number($scope.prodOpt.cnt_limit_all);
                } else if (type == 'add_price') {
                  for (const shop of ['coupang', 'rocket']) {
                    row[shop].opt_add_price = Number($scope.prodOpt.cnt_limit_all);
                    row[shop].opPrice = Number($scope.prodOpt.cnt_limit_all); //추가구매 옵션
                  }
                } else if (type == 'prod_status') {
                  for (const shop of ['coupang', 'rocket']) {
                    row[shop].prod_status = $scope.prodOpt.cnt_limit_all;
                  }
                }
              } else {
                if (type == 'sale_cnt') {
                  row.cnt_limit = Number($scope.prodOpt.cnt_limit_all);
                } else if (type == 'add_price') {
                  row.opt_add_price = Number($scope.prodOpt.cnt_limit_all);
                  row.opPrice = Number($scope.prodOpt.cnt_limit_all); //추가구매 옵션
                } else if (type == 'prod_status') {
                  if ($scope.prodOpt.cnt_limit_all == '') {
                    commonSVC.showMessage('일괄입력 실패', '[정상] 또는 [품절] 중의 하나의 상태를 선택해 주시면 일괄 변경이 가능합니다.');
                  } else {
                    row.prod_status = $scope.prodOpt.cnt_limit_all;
                  }
                }
              }

              flag = true;
            }
          });

          if (!flag) {
            commonSVC.showMessage('실패', '일괄입력 하실 옵션 상품을 선택해주세요.');
          }

          $timeout(function() {
            for (let i = 0; i < ob.length; i++) {
              $(`#${currentOpt}cnt_limit_${i}`).focus();
            }
          });

        };
				 /**
   				* 스타일 코드 SKU상품 불러오기
   				*/
        $scope.styleProdList = function() {
          if (currentOpt == 'esm_' && (!$scope.data.esmDirectOptionEnable || $scope.disabled.esm_opt)) {
            commonSVC.showMessage('실패', '옵션명 직접입력이 불가능한 카테고리는 스타일상품 가져오기를 지원하지 않습니다.');

            return false;
          }

          let depot_no_list = $scope.warehouseList.filter(w => w.ebaydepot_yn != 2).map(w => w.code).join(',');
          // 쿠팡 로켓그로스 스타일상품이 있을 수 있어 조건 추가
          if (currentOpt === 'rocket_') {
            if ($scope.data.checkGrowth.rocket && $scope.data.checkGrowth.coupang) {
              depot_no_list = '';
            } else if ($scope.data.checkGrowth.rocket && !$scope.data.checkGrowth.coupang) {
              depot_no_list = $scope.warehouseList.filter(w => w.ebaydepot_yn == 2).map(w => w.code).join(',');
            }
          }

          //같은 옵션명 확인
          if (currentOpt == 'esm_' || currentOpt == 'std_') {
            const optionNames = $scope.prodOpt[`${currentOpt}opt`].filter(o => o.name).map(o => o.name);
            const duplicates = optionNames.filter((name, idx) => optionNames.indexOf(name) !== idx);

            if (duplicates.length) {
              commonSVC.showMessage('실패', '같은 추천 옵션명이 있을 경우 스타일코드를 사용할 수 없습니다.');

              return false;
            }
          }

          const resolve = {
            data: {
              currentOpt: currentOpt, 											              // ESM2.0, 11번가 여부
              opt: currentOpt === 'esm_' ? angular.copy($scope.data.esm_options) : $scope.prodOpt[`${currentOpt}opt`],					          // 옵션 값
              esmDirectOptionEnable: $scope.data.esmDirectOptionEnable,    // ESM 2.0 자율옵션 등록가능여부
              depot_no_list
            }
          };
          const redata = commonSVC.openModal('lg', resolve, 'styleProdList', 'views/online/product/modals/style_prod_list.html');

          redata.result.then(function (re) {
            //옵션 조합
            $scope.data[`${currentOpt}opt_type`] = '조합형';
            $scope.prodOpt[`${currentOpt}options_table`] = [];
            $scope.prodOpt[`${currentOpt}checked_opt`] = [];
            //옵션 리스트
            const attri_type = re.styleSkuList[0].attri_type.split(',');
            let i = 0;

            if (currentOpt == 'esm_') {
              if (currentOpt == 'esm_' && $scope.data.esmDirectOptionEnable) {
                $scope.prodOpt.esm_opt = re.styleSkuList[0].attri_type.split(',').map(name => {
                  const esmRecommendOpt = re.opt.find(o => o.name === name);

                  return esmRecommendOpt || {
                    attr: '',
                    name,
                    name_cd: '',
                    attr_list: [],
                  };
                });
              } else {
                $scope.prodOpt[`${currentOpt}opt`] = re.opt;
              }

              // 사용한 옵션 체크처리
              _.forEach($scope.prodOpt[`${currentOpt}opt`], function(opt) {
                opt.attr_list = [];
                opt.attr = '';

                if (_.find(attri_type, function(row) { return row === opt.name; })) {
                  opt.checked = true;
                } else {
                  opt.checked = false;
                }
              });

              //옵션값 세팅
              for (i in re.styleSkuList) {
                const attri = re.styleSkuList[i].attri.split(',');
                const attr_type = re.styleSkuList[i].attri_type.split(',');

                for (const j in attri) {
                  const idx = _.findIndex($scope.prodOpt[`${currentOpt}opt`], function(o) {
                    return o.name == attr_type[j];
                  });

                  if (idx !== -1) {
                    $scope.prodOpt[`${currentOpt}opt`][idx].attr_list.push(attri[j]);
                  }
                }
              }

              _.forEach($scope.prodOpt[`${currentOpt}opt`], function(opt) {
                if (re.options.length && opt.cateOpAttr) {
                  opt.attr = _.uniqBy(re.options).join(',');
                } else {
                  if (opt.attr_list.length) {
                    opt.attr = _.uniqBy(opt.attr_list).join(',');
                  }
                }
              });

              $scope.prodOpt[`${currentOpt}checked_opt`] = _.filter($scope.prodOpt[`${currentOpt}opt`], 'checked');
              $scope.checked.std_esm_defaultOpt = false;
              disabledCheckbox();

            } else {
              $scope.prodOpt[`${currentOpt}opt`] = _.cloneDeep(onlineProductSVC.default_opt);

              //옵션명,값 초기화
              for (i = 0; i < 3; i++) {
                $scope.prodOpt[`${currentOpt}opt`][i].name = '';
                $(`.${currentOpt}attr${i}`).tagsinput('removeAll');
              }
              //옵션명 세팅
              for (i in attri_type) {
                $scope.prodOpt[`${currentOpt}opt`][i].name = attri_type[i];
                $scope.prodOpt[`${currentOpt}opt`][i].checked = true;
              }
              //옵션값 세팅
              $timeout(() => {
                for (i in re.styleSkuList) {
                  const attrs = re.styleSkuList[i].attri.split(',');

                  for (const attrIdx in attrs) {
                    $(`.${currentOpt}attr${attrIdx}`).tagsinput('add', attrs[attrIdx]);
                  }
                }
              }, 100);
            }
            $timeout(() => {
              const set_data = { opt: angular.copy(_.filter($scope.prodOpt[`${currentOpt}opt`], 'checked')) };

              //in_data, in_re, in_opt_type, create_action
              const optData = {
                in_data: set_data,
                in_re: re.styleSkuList,
                in_opt_type: $scope.data[`${currentOpt}opt_type`],
                create_action: 'styleCode'
              };

              reStoreOpt(optData);

              if (!$scope.data.add_single_prod) {
                $scope.prodListSet();
              }
            }, 200);
          });
        };

        //옵션 타입 변경시 초기화
        $scope.opt_change = async () => {
          // 옵션 타입 변경 시 확인 모달 출력
          if (currentOpt === 'naver_' && $scope.data.naver_opt_type === '옵션없음' && $scope.prodOpt.naver_options_table.length && !await commonSVC.showConfirm('옵션 유형 변경 안내', '옵션의 유형을 변경하시면 입력된 값이 모두 초기화 됩니다.\n입력된 정보를 초기화 하고 변경하시겠습니까?')) {
            $scope.data.naver_opt_type = '조합형';
            $scope.$apply();

            return false;
          }

          $scope.prodOpt[`${currentOpt}matchStockList`] = angular.copy(onlineProductSVC.prodOpt[`${currentOpt}matchStockList`]);
          $scope.prodOpt[`${currentOpt}options_table`] = [];
          if (currentOpt === '' || currentOpt === 'wmp_' || currentOpt === 'cpang_' || currentOpt === 'rocket_' || currentOpt === 'esm_') {
            $scope.prodOpt[`${currentOpt}opt`] = angular.copy(onlineProductSVC.default_opt);
          } else {
            _.forEach($scope.prodOpt[`${currentOpt}opt`], function(row) {
              row.attr = '';
            });
            $scope.prodOpt[`${currentOpt}checked_opt`] = [];
          }
          if (!$scope.data.add_single_prod) {
            $scope.prodListSet();
          }
          if (currentOpt === 'std_' && $scope.data.std_opt_type === '옵션없음') {
            $scope.data.std_insert_opt = false;
          }
          if (currentOpt === 'galleria_' && $scope.data.std_opt_type === '옵션없음') {
            $scope.optReset(6);
          }
          if (currentOpt === 'std_') {
            $scope.prodOpt[`${currentOpt}opt`].forEach(opt => opt.name = '');
          }

          if (currentOpt === 'naver_' && $scope.data.std_opt_type === '옵션없음') {
            _.forEach($scope.prodOpt[`${currentOpt}opt`], function(row) {
              row.selected_attribute = [];
              row.sub_group[0].attribute.forEach(attr => attr.selected = false);
              if (row.name === '사이즈') {
                row.selected_sub_group_name = row.sub_group[0].sub_group_name;
                row.selected_sub_group = row.sub_group[0].attribute;
              }
            });
          }
        };

        /**
				 * 2018-04-12 Daniel
				 * 옵션 이미지 임시 업로드
				 */
        $scope.uploadOptFile = function(file, row) {
          const files = [file];
          const imgCheck = /(\.jpg|\.jpeg|\.png)$/i;

          if (file && imgCheck.exec(file.name)) {
            // # S3 temp 업로드 작업
            commonModel.tempUpload(files)
              .then(function (res) {
                row.opt_img = res.data.results[0].url;
              });
          } else {
            commonSVC.showMessage('주의', '이미지파일을 확인하세요.');
          }
        };

        /**
         * 옵션 이미지 삭제
         */
        $scope.delOptImg = (row) => {
          row.opt_img = '';
        };

        $scope.openLotteOnOptModal = function(data) {
          const resolve = {};
          const param = {};

          param.cateOpAttr = _.cloneDeep(data.selectList).map((v) => {
            v.name = v.attr_val_nm;
            v.ObjOptEssenName = v.attr_val_nm;

            return v;
          });

          resolve.data = {
            data: param,
          };

          const modal = commonSVC.openModal('lm', resolve, 'CategoryFixedOptionCTRL', 'views/online/product/option/shop_category_opt_modal.html');

          modal.result.then(function (re) {
            data.attr = re.result.attr;
            data.attr_cd = re.checkeds.map((v) => v.opt_cd).join(',');

            if (!$scope.$$phase && !$scope.$root.$$phase) {
              $scope.$apply();
            }
          });
        };

        function disabledCheckbox() {
          const checkedOpt = _.filter($scope.prodOpt[`${currentOpt}opt`], 'checked');
          let ck;

          if (currentOpt == 'esm_') {
            ck = _.filter(checkedOpt, function(opt) {
              return !_.find($scope.prodOpt.std_opt, { name: opt.name });
            });

            if (ck.length) {
              $scope.checked.std_checkbox = true;
            } else {
              $scope.checked.std_checkbox = false;
            }
          }
        }

        //옵션 확인 모달
        $scope.openEsmOptCheck = function () {
          const resolve = {
            data: {
              data: $scope.data.esm_options || $scope.prodOpt.esm_opt,
            }
          };

          commonSVC.openModal('lm', resolve, 'esmOptCheck', 'views/online/product/option/esm_opt_check.html');
        };

        $scope.openOptionModal = function(data, shop_cd) {
          const resolve = {
            data: {
              data: {
                ...data,
                shop_cd,
                enableDirectOptions: currentOpt === 'alibaba_' && data.cateOpAttr.find(opt => opt.ObjOptEssenNo === '-1')
              }
            }
          };

          const modal = commonSVC.openModal('lm', resolve, 'CategoryFixedOptionCTRL', 'views/online/product/option/shop_category_opt_modal.html');

          modal.result.then(function (re) {
            data.attr = re.result.attr;
          });
        };

        $scope.openGalleriaOptModal = function(data) {
          const resolve = {};

          resolve.data = {
            data: {
              ...data,
              cateOpAttr: data.opt_value.map(({ opt_val_nm }) => ({ ObjOptEssenName: opt_val_nm }))
            }
          };
          const modal = commonSVC.openModal('lm', resolve, 'CategoryFixedOptionCTRL', 'views/online/product/option/shop_category_opt_modal.html');

          modal.result.then(function (re) {
            $scope.prodOpt.galleria_opt.forEach(function(item_, idx) {
              if (data.name === item_.name) {
                $scope.prodOpt.galleria_opt[idx].attr = re.result.attr;

                return;
              }
            });
          });
        };

        $scope.disabledOptCheckbox = function(row, opt) {
          const checkedOpt = _.filter(opt, 'checked');
          const requiredOpt = _.filter(checkedOpt, 'required');

          if (row.checked) {
            if (checkedOpt.length < 4) {
              if (checkedOpt.length === 3 && currentOpt === 'esm_') {
                commonSVC.showMessage('필수 옵션은 2개까지 사용가능합니다');
                row.checked = false;

                return;
              }
              if (requiredOpt.length === 3) {
                commonSVC.showMessage('추천 옵션은 2개까지 사용가능합니다');
                row.checked = false;

                return;
              }
              $scope.prodOpt[`${currentOpt}checked_opt`].push(row);
              row.checked = true;
              if (currentOpt == 'esm_') {
                if (!_.find($scope.prodOpt.std_opt, { name: row.name })) {
                  $scope.checked.std_checkbox = true;
                  $scope.checked.std_esm_defaultOpt = false;
                }
                if (_.filter($scope.prodOpt[`${currentOpt}checked_opt`], { name: row.name }).length > 1) {
                  $scope.checked.std_checkbox = true;
                  $scope.checked.std_esm_defaultOpt = false;
                }
              }
            } else {
              commonSVC.showMessage('필수 옵션은 3개까지 사용가능합니다');
              row.checked = false;
            }
          } else {
            $scope.prodOpt[`${currentOpt}checked_opt`] = checkedOpt;

            disabledCheckbox();
          }
        };

        $scope.range = commonSVC.range;

        // --- 쿠팡 ---------------------------
        $scope.popup_coupang_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope.opt_popup_coupang_style = {
          name: {
            maxWidth: 250
          },
          value: {
          }
        };

        const coupang_popup_close = (e) => {
          $timeout (() => {
            if (!$scope.popup_coupang_detail) {
              $('html').off('mousedown', coupang_popup_close);

              return;
            }
            const type = $scope.popup_coupang_detail.type;
            const idx = $scope.popup_coupang_detail.idx;

            if (!$(e.target).closest('td').is($(`#${currentOpt}opt_${type}${idx}`).closest('td'))) {
              $scope.popup_coupang_show[type][idx] = false;
              $('html').off('mousedown', coupang_popup_close);

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            }
          });
        };

        $scope.optionCoupangNameRefresh = (idx) => {
          $timeout (() => {
            if ($scope.popup_coupang_show.name[idx] && Array.isArray($scope.data.cpang_options) && $scope.data.cpang_options.every(({ attributeTypeName }) => $scope.prodOpt[`${currentOpt}opt`].map(({ name }) => name).includes(attributeTypeName))) {
              $scope.popup_coupang_show.name[idx] = false;
              $('html').off('mousedown', coupang_popup_close);
            } else if (!$scope.popup_coupang_show.name[idx] && !$scope.data.cpang_options?.every(({ attributeTypeName }) => $scope.prodOpt[`${currentOpt}opt`].map(({ name }) => name).includes(attributeTypeName))) {
              $scope.popup_coupang_show.name[idx] = true;
              $scope.popup_coupang_detail = { type: 'name', idx };
              $('html').mousedown(coupang_popup_close);
            }
          });
        };

        $scope.optionCoupangPopUp = (idx, type) => {
          $timeout (() => {
            if (!$scope.data.cpang_options) {
              return;
            }

            if (type === 'value') {
              if (!$scope.prodOpt[`${currentOpt}opt`][idx].name || !$scope.getCoupangUsableUnits(idx).length) {
                return;
              }
              $scope.popup_target = $(`#${currentOpt}opt_${type}${idx}`).prev('.bootstrap-tagsinput');
            } else if (type === 'name') {
              if ($scope.data.cpang_options.every(({ attributeTypeName }) => $scope.prodOpt[`${currentOpt}opt`].map(({ name }) => name).includes(attributeTypeName))) {
                return;
              }
              $scope.popup_target = $(`#${currentOpt}opt_${type}${idx}`);
            }
            $scope.popup_coupang_detail = { type, idx };
            $scope.popup_coupang_show[type][idx] = true;
            $scope.opt_popup_coupang_style[type].minWidth = $scope.popup_target[0].offsetWidth;
            $('html').mousedown(coupang_popup_close);
          });
        };

        $scope.coupangRecommendOptSelect = (p0) => {
          $timeout (() => {
            const idx = $scope.popup_coupang_detail.idx;

            $scope.prodOpt[`${currentOpt}opt`][idx].name = $scope.data.cpang_options[p0].attributeTypeName;
            $scope.prodOpt[`${currentOpt}opt`][idx].usableUnits = $scope.data.cpang_options[p0].usableUnits;
            $scope.popup_coupang_show = {
              name: [false, false, false],
              value: [false, false, false]
            };
            $scope.popup_coupang_detail = null;
            $scope.popup_target = null;
          });
        };

        $scope.coupangAttrInclude = (p0) => $scope.prodOpt[`${currentOpt}opt`].some(({ name }) => name === p0);
        $scope.getCoupangUsableUnits = (p0) => {
          const tmp = $scope.data.cpang_options && $scope.data.cpang_options.find(({ attributeTypeName }) => attributeTypeName == $scope.prodOpt[`${currentOpt}opt`][p0].name);

          return tmp ? tmp.usableUnits : [];
        };
        // --- 쿠팡 ---------------------------

        // --- ESM ---------------------------
        $scope.popup_esm_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope.opt_popup_esm_style = {
          name: {
            maxWidth: 250
          },
          value: {
          }
        };

        const esm_popup_close = (e) => {
          $timeout (() => {
            if (!$scope.popup_esm_detail) {
              $('html').off('mousedown', esm_popup_close);

              return;
            }
            const type = $scope.popup_esm_detail.type;
            const idx = $scope.popup_esm_detail.idx;

            if (!$(e.target).closest('td').is($(`#esm_opt_${type}${idx}`).closest('td'))) {
              $scope.popup_esm_show[type][idx] = false;
              $('html').off('mousedown', esm_popup_close);

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            }
          });
        };

        /**
         * ESM 2.0 선택한 옵션값에 따른 추천옵션 갱신
         */
        $scope.optionEsmNameRefresh = (idx) => {
          $timeout (() => {
            const recommendOption = $scope.data.esm_options?.find(option => option.name === $scope.prodOpt.esm_opt[idx].name);

            if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
              $scope.prodOpt.esm_opt[idx].cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName).map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });

              $timeout(() => {
                // 옵션코드
                $scope.prodOpt.esm_opt[idx].name_cd = recommendOption.name_cd;

                const select2 = angular.element(document.querySelector(`#esm_opt_value${idx}`));

                if (select2) {
                  select2.controller('multiSelectCombo').destroySelect2(select2);
                }
                $scope.prodOpt.esm_opt[idx].attr = '';
                $scope.selectOptList[idx] = [ ...$scope.prodOpt.esm_opt[idx].cateOpAttr ];

                $timeout(() => {
                  select2.controller('multiSelectCombo').initSelect2(select2, {});
                }, 0);
              });
            } else {
              // 직접입력인 경우 옵션코드 0
              $scope.prodOpt.esm_opt[idx].name_cd = 0;
              $scope.prodOpt.esm_opt[idx].cateOpAttr = [];
            }
          });
        };

        /**
         * ESM 2.0 추천옵션 선택 모달
         */
        $scope.optionEsmPopUp = (idx, type) => {
          $timeout (() => {
            if (!$scope.data.esm_options) {
              return;
            }

            // esm 추천옵션 중복 설정 가능해서 주석처리 - 독립형은 중복 설정 불가 처리
            if ($scope.data.esm_options.every(({ name }) => $scope.prodOpt.esm_opt.map(({ name }) => name).includes(name)) && $scope.data.esm_opt_type === '독립형') {
              return;
            }

            $scope.popup_target = $(`#esm_opt_${type}${idx}`);
            $scope.popup_esm_detail = { type, idx };
            $scope.popup_esm_show[type][idx] = true;
            $scope.opt_popup_esm_style[type].minWidth = $scope.popup_target[0].offsetWidth;
            $('html').mousedown(esm_popup_close);
          });
        };

        /**
         * ESM 추천옵션 선택
         */
        $scope.esmRecommendOptSelect = (idx, optIdx) => {
          $timeout (() => {
            $scope.prodOpt.esm_opt[optIdx].name = $scope.data.esm_options[idx].name;
            $scope.prodOpt.esm_opt[optIdx].name_cd = $scope.data.esm_options[idx].name_cd;

            // 값이 없는경우 기본값 추가.
            if (!$scope.prodOpt.esm_opt[optIdx]) {
              $scope.prodOpt.esm_opt[optIdx] = { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false };
            }

            if ($scope.data.esm_options[idx]?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
              $scope.prodOpt.esm_opt[optIdx].cateOpAttr = $scope.data.esm_options[idx].cateOpAttrList.filter(o => o.ObjOptEssenName).map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });

              $timeout(() => {
                const select2 = angular.element(document.querySelector(`#esm_opt_value${optIdx}`));

                if (select2) {
                  select2.controller('multiSelectCombo').destroySelect2(select2);
                }
                $scope.prodOpt.esm_opt[optIdx].attr = '';
                $scope.selectOptList[optIdx] = [ ...$scope.prodOpt.esm_opt[optIdx].cateOpAttr ];

                $timeout(() => {
                  select2.controller('multiSelectCombo').initSelect2(select2, {});
                }, 0);
              });
            } else {
              $scope.prodOpt.esm_opt[optIdx].cateOpAttr = [];
            }

            $scope.popup_esm_show = {
              name: [false, false, false],
              value: [false, false, false]
            };
            $scope.popup_esm_detail = null;
            $scope.popup_target = null;
          });
        };

        // esm 추천옵션 중복 설정 가능해서 주석처리
        $scope.esmAttrInclude = (p0) => $scope.prodOpt.esm_opt.some(({ name }) => $scope.data.esm_opt_type === '독립형' && name === p0);
        // --- ESM ---------------------------

        // --- 네이버 ---------------------------
        $scope.naverOptSelect = (type, data) => {
          if (data.selected) {
            const attrIdx = $scope.prodOpt.naver_opt[type].selected_attribute.findIndex(attr => attr.attributeValueId === data.attributeValueId);

            $scope.prodOpt.naver_opt[type].selected_attribute.splice(attrIdx, 1);
          } else {
            $scope.prodOpt.naver_opt[type].selected_attribute.push(data);
          }
          data.selected = !data.selected;
          naverOptSetting(type);
        };

        $scope.changeNaverSizeList = () => {
          const new_sub_group = $scope.prodOpt.naver_opt[1].sub_group.find(group => group.sub_group_name === $scope.prodOpt.naver_opt[1].selected_sub_group_name);
          new_sub_group.attribute.forEach(attr => attr.selected = false);

          $scope.prodOpt.naver_opt[1].name_cd = new_sub_group.sub_group_id;
          $scope.prodOpt.naver_opt[1].selected_sub_group = new_sub_group.attribute;
          $scope.prodOpt.naver_opt[1].selected_attribute = [];
          naverOptSetting(1);
        };

        $scope.openChangeColorNameModal = () => {
          const resolve = {
            data: {
              color: $scope.prodOpt.naver_opt[0]
            }
          };
          commonSVC.openModal('lm', resolve, 'NaverChangeColorCtrl', 'views/online/product/modals/naver_opt_change_color_modal.html').result.then(() => {
            naverOptSetting(0);
          });
        };

        const naverOptSetting = (type) => {
          $scope.prodOpt.naver_opt[type].attr = $scope.prodOpt.naver_opt[type].selected_attribute.map(selected => selected.attributeValueName).join();
          $scope.prodOpt.naver_opt[type].attr_cd = $scope.prodOpt.naver_opt[type].selected_attribute.map(selected => selected.attributeValueId).join();
        };
        // --- 네이버 ---------------------------

        // --- 알리 ----------------------------

        /**
         * 옵션명 삭제
         */
        $scope.deleteAliOptName = (opt_idx, shop_name) => {
          if (shop_name === 'aliexpress') {
            if ($scope.prodOpt.aliexpress_opt[opt_idx].name) {
              $scope.prodOpt.aliexpress_opt[opt_idx].name = '';
            }
          } else if (shop_name === 'aliexpress_global_') {
            if ($scope.prodOpt.aliexpress_global_opt[opt_idx].name) {
              $scope.prodOpt.aliexpress_global_opt[opt_idx].name = '';
            }
          } else {
            if ($scope.prodOpt.alibaba_opt[opt_idx].name) {
              $scope.prodOpt.alibaba_opt[opt_idx].name = '';
            }
          }
        };

        // --- 알리 익스프레스 ----------------------
        $scope.popup_aliexpress_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope.opt_popup_aliexpress_style = {
          name: {
            maxWidth: 250
          },
          value: {
          }
        };

        $scope.opt_non_cursor_style = {
          name: {
            'pointer-events': 'none'
          },
          value: {
            'cursor': 'not-allowed',
            'disabled-input': false,
          }
        };

        const aliexpress_popup_close = (e) => {
          $timeout (() => {
            if (!$scope.popup_aliexpress_detail) {
              $('html').off('mousedown', aliexpress_popup_close);

              return;
            }
            const type = $scope.popup_aliexpress_detail.type;
            const idx = $scope.popup_aliexpress_detail.idx;

            if (!$(e.target).closest('td').is($(`#aliexpress_opt_${type}${idx}`).closest('td'))) {
              $scope.popup_aliexpress_show[type][idx] = false;
              $('html').off('mousedown', aliexpress_popup_close);

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            }
          });
        };

        /**
         * 선택한 옵션값에 따른 추천옵션 갱신
         */
        $scope.optionAliexpressNameRefresh = (idx) => {
          $timeout (() => {
            const setRecommendOpt = $scope.data.aliexpress_options?.find(option => option.name === $scope.prodOpt.aliexpress_opt[idx].name);

            if (setRecommendOpt) {
              // 옵션코드
              $scope.prodOpt.aliexpress_opt[idx].name_cd = setRecommendOpt.name_cd;

              $scope.prodOpt.aliexpress_opt[idx].cateOpAttr = setRecommendOpt.cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              // 직접입력인 경우 옵션코드 0
              $scope.prodOpt.aliexpress_opt[idx].name_cd = 0;
              $scope.prodOpt.aliexpress_opt[idx].cateOpAttr = [];
            }
          });
        };

        /**
         * 추천옵션 선택 모달
         */
        $scope.optionAliexpressPopUp = (idx, type) => {
          $timeout (() => {
            if (!$scope.data.aliexpress_options) {
              return;
            }

            $scope.popup_target = $(`#aliexpress_opt_${type}${idx}`);
            $scope.popup_aliexpress_detail = { type, idx };
            $scope.popup_aliexpress_show[type][idx] = true;
            $scope.opt_popup_aliexpress_style[type].minWidth = $scope.popup_target[0].offsetWidth;
            $('html').mousedown(aliexpress_popup_close);
          });
        };

        /**
         * 추천옵션 선택
         */
        $scope.aliexpressRecommendOptSelect = (idx) => {
          $timeout (() => {
            const detail_idx = $scope.popup_aliexpress_detail.idx;
            // 값이 없는경우 기본값 추가.
            if (!$scope.prodOpt.aliexpress_opt[detail_idx]) {
              $scope.prodOpt.aliexpress_opt[detail_idx] = { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false };
            }
            // 기존 선택 추천옵션을 다른 추천옵션으로 변경 시 기존 선택 옵션 태그 초기화 처리
            if (detail_idx !== idx) {
              $scope.prodOpt.aliexpress_opt[detail_idx].attr = '';
            }
            $scope.prodOpt.aliexpress_opt[detail_idx].name = $scope.data.aliexpress_options[idx].name;
            $scope.prodOpt.aliexpress_opt[detail_idx].name_cd = $scope.data.aliexpress_options[idx].name_cd;

            if ($scope.data.aliexpress_options[idx].cateOpAttrList.length) {
              $scope.prodOpt.aliexpress_opt[detail_idx].cateOpAttr = $scope.data.aliexpress_options[idx].cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              $scope.prodOpt.aliexpress_opt[detail_idx].cateOpAttr = [];
            }

            $scope.popup_aliexpress_show = {
              name: [false, false, false],
              value: [false, false, false]
            };
            $scope.popup_aliexpress_detail = null;
            $scope.popup_target = null;
          });
        };

        // 알리 익스프레스 추천옵션 중복 설정 안됨
        $scope.aliexpressAttrInclude = (p0) => $scope.prodOpt.aliexpress_opt.some(({ name }) => name === p0);
        // --- 알리 익스프레스 ----------------------

        // --- 알리바바 ----------------------
        $scope.popup_alibaba_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope.opt_popup_alibaba_style = {
          name: {
            maxWidth: 250
          },
          value: {
          }
        };

        const alibaba_popup_close = (e) => {
          $timeout (() => {
            if (!$scope.popup_alibaba_detail) {
              $('html').off('mousedown', alibaba_popup_close);

              return;
            }
            const type = $scope.popup_alibaba_detail.type;
            const idx = $scope.popup_alibaba_detail.idx;

            if (!$(e.target).closest('td').is($(`#alibaba_opt_${type}${idx}`).closest('td'))) {
              $scope.popup_alibaba_show[type][idx] = false;
              $('html').off('mousedown', alibaba_popup_close);

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            }
          });
        };

        /**
         * 선택한 옵션값에 따른 추천옵션 갱신
         */
        $scope.optionAlibabaNameRefresh = (idx) => {
          $timeout (() => {
            const setRecommendOpt = $scope.data.alibaba_options?.find(option => option.name === $scope.prodOpt.alibaba_opt[idx].name);

            if (setRecommendOpt) {
              // 옵션코드
              $scope.prodOpt.alibaba_opt[idx].name_cd = setRecommendOpt.name_cd;

              $scope.prodOpt.alibaba_opt[idx].cateOpAttr = setRecommendOpt.cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              // 직접입력인 경우 옵션코드 0
              $scope.prodOpt.alibaba_opt[idx].name_cd = 0;
              $scope.prodOpt.alibaba_opt[idx].cateOpAttr = [];
            }
          });
        };

        /**
         * 추천옵션 선택 모달
         */
        $scope.optionAlibabaPopUp = (idx, type) => {
          $timeout (() => {
            if (!$scope.data.alibaba_options) {
              return;
            }

            $scope.popup_target = $(`#alibaba_opt_${type}${idx}`);
            $scope.popup_alibaba_detail = { type, idx };
            $scope.popup_alibaba_show[type][idx] = true;
            $scope.opt_popup_alibaba_style[type].minWidth = $scope.popup_target[0].offsetWidth;
            $('html').mousedown(alibaba_popup_close);
          });
        };

        /**
         * 추천옵션 선택
         */
        $scope.alibabaRecommendOptSelect = (idx) => {
          $timeout (() => {
            const detail_idx = $scope.popup_alibaba_detail.idx;
            // 값이 없는경우 기본값 추가.
            if (!$scope.prodOpt.alibaba_opt[detail_idx]) {
              $scope.prodOpt.alibaba_opt[detail_idx] = { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false };
            }
            // 기존 선택 추천옵션을 다른 추천옵션으로 변경 시 기존 선택 옵션 태그 초기화 처리
            if (detail_idx !== idx) {
              $scope.prodOpt.alibaba_opt[detail_idx].attr = '';
            }
            $scope.prodOpt.alibaba_opt[detail_idx].name = $scope.data.alibaba_options[idx].name;
            $scope.prodOpt.alibaba_opt[detail_idx].name_cd = $scope.data.alibaba_options[idx].name_cd;

            if ($scope.data.alibaba_options[idx].cateOpAttrList.length) {
              $scope.prodOpt.alibaba_opt[detail_idx].cateOpAttr = $scope.data.alibaba_options[idx].cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              $scope.prodOpt.alibaba_opt[detail_idx].cateOpAttr = [];
            }

            $scope.popup_alibaba_show = {
              name: [false, false, false],
              value: [false, false, false]
            };
            $scope.popup_alibaba_detail = null;
            $scope.popup_target = null;
          });
        };

        // 알리바바 추천옵션 중복 설정 안됨
        $scope.alibabaAttrInclude = (p0) => $scope.prodOpt.alibaba_opt.some(({ name }) => name === p0);
        // --- 알리바바 ----------------------

        // 쿠팡 로켓그로스 selectbox 선택
        $scope.changeCoupangRocket = (type) => {

          if (!$scope.data.checkGrowth[type]) {
            const otherShop = type === 'rocket' ? 'coupang' : 'rocket';

            if (!$scope.data.checkGrowth[otherShop]) {
              $scope.data.checkGrowth[otherShop] = true;

            }
          }

          if ($scope.data.checkGrowth.rocket) {
            let msg = '';

            if ($scope.data.site_list.some(ch => ch.pa_shop_cd === 'B378' && ch.etc6 !== 'true')) {
              msg = '로켓그로스를 사용할 수 없는 계정이 있습니다.\n[설정 > 쇼핑몰 계정관리]에서 쿠팡계정 선택 후 로켓그로스 상품 사용여부를 확인해 주세요.';
            } else if (!$scope.data.is_possible_growth_cate) { // 카테고리 확인
              msg = '로켓그로스에서 사용 불가한 카테고리입니다.\n해당 카테고리는 판매자배송 상품만 선택 가능합니다.';
            }

            if (msg) {
              $scope.data.checkGrowth.rocket = false;
              $scope.data.checkGrowth.coupang = true;
              commonSVC.showMessage(msg, '');

              return false;
            }
          }
          //대표상품 리스트 재 검색
          $scope.prodListSet();

          $timeout(() => {});

          return true;
        };

        // 스마트스토어 단일옵션
        $scope.popup_naver_single_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope.opt_popup_naver_single_style = {
          name: {
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: 250
          },
          value: {
          }
        };

        const naver_single_popup_close = (e) => {
          $timeout (() => {
            if (!$scope.popup_naver_single_detail) {
              $('html').off('mousedown', naver_single_popup_close);

              return;
            }
            const type = $scope.popup_naver_single_detail.type;
            const idx = $scope.popup_naver_single_detail.idx;

            if (!$(e.target).closest('td').is($(`#naver_single_opt_${type}${idx}`).closest('td'))) {
              $scope.popup_naver_single_show[type][idx] = false;
              $('html').off('mousedown', naver_single_popup_close);

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            }
          });
        };

        /**
         * 스마트스토어 단일옵션 추천옵션 선택 모달
         */
        $scope.optionNaversinglePopUp = (idx, type) => {
          $timeout (() => {
            if (!$scope.data.naver_single_options) {
              return;
            }

            $scope.popup_target = $(`#naver_single_opt_${type}${idx}`);
            $scope.popup_naver_single_detail = { type, idx };
            $scope.popup_naver_single_show[type][idx] = true;
            $scope.opt_popup_naver_single_style[type].minWidth = $scope.popup_target[0].offsetWidth;
            $('html').mousedown(naver_single_popup_close);
          });
        };

        /**
         * 스마트스토어 단일옵션 추천옵션 선택
         */
        $scope.naversingleRecommendOptSelect = (idx) => {
          $timeout (() => {
            $scope.data.naver_single_guide_id = $scope.data.naver_single_options[idx].guideId;

            for (let optIdx = 0; optIdx < 3; optIdx++) {
              const cateOpt = $scope.data.naver_single_options[idx].cateOptList[optIdx];

              // 값이 없는경우 기본값 추가.
              if (!cateOpt) {
                $scope.prodOpt.naver_single_opt[optIdx] = { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false };
              } else {
                $scope.prodOpt.naver_single_opt[optIdx].name = $scope.data.naver_single_options[idx].cateOptList[optIdx].optionName;
                $scope.prodOpt.naver_single_opt[optIdx].name_cd = $scope.data.naver_single_options[idx].cateOptList[optIdx].optionId;
                $scope.prodOpt.naver_single_opt[optIdx].guideId = $scope.data.naver_single_guide_id;
              }

              if (cateOpt?.optionValues?.length) {
                $scope.prodOpt.naver_single_opt[optIdx].cateOpAttr = cateOpt.optionValues.map(options => ({
                  ...options,
                  name: options.valueName
                }));

                $timeout(() => {
                  const select2 = angular.element(document.querySelector(`#naver_single_opt_value${optIdx}`));

                  if (select2) {
                    select2.controller('multiSelectCombo').destroySelect2(select2);
                  }
                  $scope.prodOpt.naver_single_opt[optIdx].attr = '';
                  $scope.selectNaverOptList[optIdx] = [ ...$scope.prodOpt.naver_single_opt[optIdx].cateOpAttr ];

                  $timeout(() => {
                    select2.controller('multiSelectCombo').initSelect2(select2, {});
                  }, 0);
                });
              } else {
                $scope.prodOpt.naver_single_opt[optIdx].cateOpAttr = [];
              }
            }

            $scope.popup_naver_single_show = {
              name: [false, false, false],
              value: [false, false, false]
            };
            $scope.popup_naver_single_detail = null;
            $scope.popup_target = null;
          });
        };
        // 스마트스토어 단일옵션

        // --- 알리익스프레스 글로벌 ----------------------
        $scope.popup_aliexpress_global_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope.opt_popup_aliexpress_global_style = {
          name: {
            maxWidth: 250
          },
          value: {
          }
        };

        $scope.opt_non_cursor_style = {
          name: {
            'pointer-events': 'none'
          },
          value: {
            'cursor': 'not-allowed',
            'disabled-input': false,
          }
        };

        const aliexpress_global_popup_close = (e) => {
          $timeout (() => {
            if (!$scope.popup_aliexpress_global_detail) {
              $('html').off('mousedown', aliexpress_global_popup_close);

              return;
            }
            const type = $scope.popup_aliexpress_global_detail.type;
            const idx = $scope.popup_aliexpress_global_detail.idx;

            if (!$(e.target).closest('td').is($(`#aliexpress_global_opt_${type}${idx}`).closest('td'))) {
              $scope.popup_aliexpress_global_show[type][idx] = false;
              $('html').off('mousedown', aliexpress_global_popup_close);

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            }
          });
        };

        /**
         * 선택한 옵션값에 따른 추천옵션 갱신
         */
        $scope.optionAliexpressGlobalNameRefresh = (idx) => {
          $timeout (() => {
            const setRecommendOpt = $scope.data.aliexpress_global_options?.find(option => option.name === $scope.prodOpt.aliexpress_global_opt[idx].name);

            if (setRecommendOpt) {
              // 옵션코드
              $scope.prodOpt.aliexpress_global_opt[idx].name_cd = setRecommendOpt.name_cd;

              $scope.prodOpt.aliexpress_global_opt[idx].cateOpAttr = setRecommendOpt.cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              // 직접입력인 경우 옵션코드 0
              $scope.prodOpt.aliexpress_global_opt[idx].name_cd = 0;
              $scope.prodOpt.aliexpress_global_opt[idx].cateOpAttr = [];
            }
          });
        };

        /**
         * 추천옵션 선택 모달
         */
        $scope.optionAliexpressGlobalPopUp = (idx, type) => {
          $timeout (() => {
            if (!$scope.data.aliexpress_global_options) {
              return;
            }

            $scope.popup_target = $(`#aliexpress_global_opt_${type}${idx}`);
            $scope.popup_aliexpress_global_detail = { type, idx };
            $scope.popup_aliexpress_global_show[type][idx] = true;
            $scope.opt_popup_aliexpress_global_style[type].minWidth = $scope.popup_target[0].offsetWidth;
            $('html').mousedown(aliexpress_global_popup_close);
          });
        };

        /**
         * 추천옵션 선택
         */
        $scope.aliexpressGlobalRecommendOptSelect = (idx) => {
          $timeout (() => {
            const detail_idx = $scope.popup_aliexpress_global_detail.idx;
            // 값이 없는경우 기본값 추가.
            if (!$scope.prodOpt.aliexpress_global_opt[detail_idx]) {
              $scope.prodOpt.aliexpress_global_opt[detail_idx] = { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false };
            }
            // 기존 선택 추천옵션을 다른 추천옵션으로 변경 시 기존 선택 옵션 태그 초기화 처리
            if (detail_idx !== idx) {
              $scope.prodOpt.aliexpress_global_opt[detail_idx].attr = '';
            }
            $scope.prodOpt.aliexpress_global_opt[detail_idx].name = $scope.data.aliexpress_global_options[idx].name;
            $scope.prodOpt.aliexpress_global_opt[detail_idx].name_cd = $scope.data.aliexpress_global_options[idx].name_cd;

            if ($scope.data.aliexpress_global_options[idx].cateOpAttrList.length) {
              $scope.prodOpt.aliexpress_global_opt[detail_idx].cateOpAttr = $scope.data.aliexpress_global_options[idx].cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              $scope.prodOpt.aliexpress_global_opt[detail_idx].cateOpAttr = [];
            }

            $scope.popup_aliexpress_global_show = {
              name: [false, false, false],
              value: [false, false, false]
            };
            $scope.popup_aliexpress_global_detail = null;
            $scope.popup_target = null;
          });
        };

        // 알리 익스프레스 추천옵션 중복 설정 안됨
        $scope.aliexpressGlobalAttrInclude = (p0) => $scope.prodOpt.aliexpress_global_opt.some(({ name }) => name === p0);
        // --- 알리익스프레스 글로벌 ----------------------

        // --- 테무 ----------------------
        $scope.popup_temu_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope.opt_popup_temu_style = {
          name: {
            maxWidth: 250
          },
          value: {
          }
        };

        $scope.opt_non_cursor_style = {
          name: {
            'pointer-events': 'none'
          },
          value: {
            'cursor': 'not-allowed',
            'disabled-input': false,
          }
        };

        const temu_popup_close = (e) => {
          $timeout (() => {
            if (!$scope.popup_temu_detail) {
              $('html').off('mousedown', temu_popup_close);

              return;
            }
            const type = $scope.popup_temu_detail.type;
            const idx = $scope.popup_temu_detail.idx;

            if (!$(e.target).closest('td').is($(`#temu_opt_${type}${idx}`).closest('td'))) {
              $scope.popup_temu_show[type][idx] = false;
              $('html').off('mousedown', temu_popup_close);

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            }
          });
        };

        /**
         * 선택한 옵션값에 따른 추천옵션 갱신
         */
        $scope.optionTmuNameRefresh = (idx) => {
          $timeout (() => {
            const setRecommendOpt = $scope.data.temu_options?.find(option => option.name === $scope.prodOpt.temu_opt[idx].name);

            if (setRecommendOpt) {
              // 옵션코드
              $scope.prodOpt.temu_opt[idx].name_cd = setRecommendOpt.name_cd;

              $scope.prodOpt.temu_opt[idx].cateOpAttr = setRecommendOpt.cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              // 직접입력인 경우 옵션코드 0
              $scope.prodOpt.temu_opt[idx].name_cd = 0;
              $scope.prodOpt.temu_opt[idx].cateOpAttr = [];
            }
          });
        };

        /**
         * 추천옵션 선택 모달
         */
        $scope.optionTemuPopUp = (idx, type) => {
          $timeout (() => {
            if (!$scope.data.temu_options) {
              return;
            }

            $scope.popup_target = $(`#temu_opt_${type}${idx}`);
            $scope.popup_temu_detail = { type, idx };
            $scope.popup_temu_show[type][idx] = true;
            $scope.opt_popup_temu_style[type].minWidth = $scope.popup_target[0].offsetWidth;
            $('html').mousedown(temu_popup_close);
          });
        };

        /**
         * 추천옵션 선택
         */
        $scope.temuRecommendOptSelect = (idx) => {
          $timeout (() => {
            const detail_idx = $scope.popup_temu_detail.idx;
            // 값이 없는경우 기본값 추가.
            if (!$scope.prodOpt.temu_opt[detail_idx]) {
              $scope.prodOpt.temu_opt[detail_idx] = { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false };
            }
            // 기존 선택 추천옵션을 다른 추천옵션으로 변경 시 기존 선택 옵션 태그 초기화 처리
            if (detail_idx !== idx) {
              $scope.prodOpt.temu_opt[detail_idx].attr = '';
            }
            $scope.prodOpt.temu_opt[detail_idx].name = $scope.data.temu_options[idx].name;
            $scope.prodOpt.temu_opt[detail_idx].name_cd = $scope.data.temu_options[idx].name_cd;

            if ($scope.data.temu_options[idx].cateOpAttrList.length) {
              $scope.prodOpt.temu_opt[detail_idx].cateOpAttr = $scope.data.temu_options[idx].cateOpAttrList.map(options => {
                return {
                  ...options,
                  name: options.ObjOptEssenName
                };
              });
            } else {
              $scope.prodOpt.temu_opt[detail_idx].cateOpAttr = [];
            }

            $scope.popup_temu_show = {
              name: [false, false, false],
              value: [false, false, false]
            };
            $scope.popup_temu_detail = null;
            $scope.popup_target = null;
          });
        };

        // 테무 추천옵션 중복 설정 안됨
        $scope.temuAttrInclude = (p0) => $scope.prodOpt.temu_opt.some(({ name }) => name === p0);
        // --- 테무 ----------------------

        // 옵션테이블 초기화
        if ($scope.changeCateFn) {
          $scope.changeCateFn.changeCate = () => {
            $timeout(() => {
              $scope.opt_change();
            }, 500);
          };
        }
      }
    };
  })
  //추가구매 옵션 테이블
  .directive('addOptionTable', function () {

    return {
      restrict: 'E',
      templateUrl: function ($element, $attrs) {
        return 'views/online/product/option/add_option_table.html';
      },
      scope: {
        data: '=',
        addOpt: '=',
        addOptTable: '=',
        optTarget: '=',
        esmMasterFlag: '=',
        originAddOptTable: '=?'
      },
      link: function ($scope) {
      },
      controller: function addOptionTableController($rootScope, $scope, $timeout, commonSVC, onlineProductSVC) {
        $scope.sol_stock_auth = $scope.$parent.sol_stock_auth;
        $scope.user_profile = $rootScope.user_profile;
        $scope.batchType = 'sale_cnt';
        $scope.addOpt = $scope.addOpt.length ? $scope.addOpt : [{ name: '', opName: '' }];
        $scope.add_opt = $scope.addOpt;
        $scope.cnt_limit_all = '';
        $scope.add_opt_table = $scope.addOptTable;
        $scope.warehouseList = $scope.$parent.$parent.warehouseList;
        $scope.originAddOptTable = $scope.originAddOptTable || [];
        // 추가옵션테이블내의 추가금액 태그 위치값 -> 해당 값이 고유값으로 저장되어야 삭제 시 정확한 위치에 값이 삭제됨
        let opPricePositionValue = 0;
        /**
         * 옵션 테이블 더보기 버튼 클릭 함수
         */
        $scope.loadAddOptList = (init, isAll, isAdd) => {
          // 현재 더보기 진행 횟수와 총 더보기 가능횟수랑 같으면 return
          if ($scope.initAddOptListCnt === $scope.totalLoadAddOptCnt) {
            return;
          }

          $scope.initAddOptListCnt += init ? 0 : 1;

          if (init) {
            $scope.addOptTable = [];
          }

          if (isAll) {
            $scope.initAddOptListCnt = Math.ceil($scope.originAddOptTable.length / $scope.ADD_OPT_LIST_LIMIT);
          }

          const start = $scope.addOptTable.length;
          const end = $scope.initAddOptListCnt * $scope.ADD_OPT_LIST_LIMIT;
          $scope.addOptTable = $scope.addOptTable.concat(isAll ? $scope.originAddOptTable?.slice(start) : $scope.originAddOptTable?.slice(start, end));
          if (isAdd) {
            $scope.addOptTable.push({
              name: '',
              opName: '',
              prod_status: '정상'
            });
          }
        };

        // 추가구매옵션 템플릿 및 불러오기 모달이 닫힐때 추가금액 태그 한번더 로드해줘야함
        $rootScope.$on('addOptTemplateApplyModalClosed', function(event, data) {
          $timeout(() => {
            for (const index in data) {
              const opt = data[index];
              if (opt.opPrice) {
                const opPrice = opt.opPrice.split(',');
                const element = $(`#opPrice_${index} div.bootstrap-tagsinput`).siblings().eq(0);

                if (!element.data('tagsinput')) {
                  element.tagsinput();
                }

                while (element.tagsinput('items').length > 0) {
                  element.tagsinput('remove', element.tagsinput('items')[0]);
                }

                for (const priceIndex in opPrice) {
                  element.tagsinput('add', {
                    id: priceIndex,
                    text: opPrice[priceIndex]
                  });
                }
              }
            }
          });
        });
        /**
         * 쇼핑몰 상품 등록 및 수정 시 추가구매옵션내의 추가 금액 입력 시 태그 생성
         */
        function tagsInputEvent(value) {
          $(document).on('keydown focusout', `#opPrice_${value}`, function (e) {
            if ((e.type === 'keydown' && [13, 188].includes(e.keyCode)) || e.type === 'focusout') {
              const inputTag = `#${$(e.target).parents('td')[0].id} input`;
              if ($(inputTag).val() && $(inputTag).val() !== ',') {
                $(e.target).parent().siblings().eq(0).tagsinput('add', {
                  id: ++opPricePositionValue,
                  text: $(inputTag).val(),
                });
                $(inputTag).val('');
                e.preventDefault();
              }
            }

          });
        }

        $scope.add_sub_opt = function (ob, type) {
          $scope.loadAddOptList(false, true, true);
          const maxCount = type === 'opt' ? 10 : 100000;
          if (ob.length < maxCount) {
            ob.push({
              name: '',
              opName: '',
              prod_status: '정상'
            });

            $scope.originAddOptTable.push({
              name: '',
              opName: '',
              prod_status: '정상'
            });
          }

          const addOptPosition = $scope.addOpt.length - 1 || 0;
          tagsInputEvent(addOptPosition);
        };

        $scope.del_sub_opt = function (ob) {
          for (let i = 0; i < ob.length; i++) {
            if (ob[i].checked == true) {
              $(document).off('keydown', `#opPrice_${ob[i]}`);
              //대표상품 지정인 경우 해제
              ob.splice(i, 1);
              $scope.originAddOptTable.splice(i, 1);

              i = i - 1;
            }
          }
          $scope.loadAddOptList(false, true);
        };

        $scope.create_opt = function () {
          //일괄입력 초기화
          $scope.addOptTable = [];
          const new_add_option_table = [];

          for (const i in $scope.addOpt) {
            const opName = $scope.addOpt[i].opName ? $scope.addOpt[i].opName.split(',') : [];
            const opPrice = $scope.addOpt[i].opPrice ? $scope.addOpt[i].opPrice.split(',') : [];

            //추가 옵션명, 추가 금액의 길이가 같지 않는 경우
            if (!$scope.addOpt[i].name) {
              commonSVC.showMessage('실패', '추가 항목명을 입력해주세요.');

              return;
            }

            //추가 옵션명, 추가 금액의 길이가 같지 않는 경우
            if (opName.length != opPrice.length) {
              commonSVC.showMessage('실패', '추가 옵션명과 추가 금액을 맞춰주세요.');

              return;
            }
            for (let j = 0; j < opName.length; j++) {
              const optData = {
                name: $scope.addOpt[i].name,
                opName: opName[j],
                opPrice: Number(opPrice[j]),
                prod_status: '정상'
              };

              if (isNaN(opPrice[j])) {
                commonSVC.showMessage('실패', '추가 금액은 숫자만 입력 해주세요.');
                $scope.addOptTable = [];

                return;
              }
              if (optData.name) {
                new_add_option_table.push(optData);
              }
            }
          }

          // 동일한 추가 옵션은 입력값을 유지하기위해 추가 옵션 테이블 정보 백업
          const add_options_table_bak = {};

          $scope.add_opt_table.map((opt) => {
            add_options_table_bak[opt.name + opt.opName] = opt;
          });

          // 추가 옵션명으로 동일한지 체크후 동일한 추가 옵션이면 백업한 정보로 넣어줌
          new_add_option_table.forEach((opt, key) => {
            let k = '';

            k = opt.name + opt.opName;

            if (add_options_table_bak[k]) {
              new_add_option_table[key] = add_options_table_bak[k];
            }
          });

          $scope.addOptTable = new_add_option_table;
        };

        /**
          * 상품 전체 선택 및 해제
          */
        $scope.stockAllCheck = function (ob, flag) {
          for (const i in ob) {
            ob[i].checked = flag;
          }
        };

        /**
         * 추가구매 옵션 순서 변경
         */
        $scope.moveAddOpt = function (upward, position) {
          const obj = {
            upward: upward,
            position: position,
            opt_table: $scope.addOptTable,
            add: false,
          };
          const resultOpt = onlineProductSVC.optMoveSet(obj);

          if (resultOpt.state) {
            $scope.addOptTable = resultOpt.data;
          }
        };

        //세트상품 옵션 테이블에 매칭
        function setProdMatch(result, idx, list) {
          let main_product;

          //세트상품의 대표상품 구함
          angular.forEach(result.prod, function (row) {
            if (row.prod_no == result.set.main_prod_no) {
              main_product = row;
            }
          });

          //세트상품의 대표상품 정보로 옵션 리스트에 등록
          angular.extend(list[idx], main_product);
          list[idx].prod_no = main_product.prod_no;
          list[idx].prod_name = result.set.set_name;
          list[idx].prod_img = result.set.set_img ? result.set.set_img : (main_product.prod_img || '/assets/images/upload.png');
          list[idx].pack_unit = result.set.set_pack_unit;
          // list[idx].prod_weight = $scope.prod_auto_set_yn ? _(result.prod).map('prod_weight').sum() : null;
          list[idx].set_no = result.set.set_no;
          list[idx].set_cd = list[idx].sku_cd = result.set.set_cd;
          list[idx].prod_status = '정상';
          list[idx].checked = false;
          list[idx].set_prod_list = result.prod; //세트에 묶인 상품 리스트, 상품 등록시 쓰임
          list[idx].sale_price = result.set.sale_price;   // 세트 대표상품 가격 => 세트 가격으로 변경 2019-04-29 rony

        }
        //SKU상품 옵션 테이블에 매칭
        function productMatch(result, idx, list) {
          // 원소 할당 안된경우 오브젝트 할당 2018-05-03 roy 추가
          if (list[idx] === undefined) {
            list[idx] = {};
          }

          angular.extend(list[idx], result);
          list[idx].prod_img = result.prod_img || '/assets/images/upload.png';
          list[idx].safe_stock = result.stock_cnt_safe;
          list[idx].pack_unit = result.pack_unit;
          list[idx].prod_status = '정상';
          list[idx].checked = false;
          list[idx].set_no = 0;
          list[idx].set_cd = null;
          list[idx].sol_no = result.sol_no;
        }

        $scope.prodProductSearch = function (idx, list, type) {
          const resolve = {
            data: {
              warehouseList: $scope.warehouseList,
              type: type
            }
          };
          const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

          redata.result.then(function (re) {
            //세트상품인 경우
            if (re.isSet) {
              setProdMatch(re.data, idx, list);
            }
            //SKU상품인 경우
            else {
              productMatch(re, idx, list);
            }
          });
        };

        // 매칭된 SKU상품 삭제
        $scope.prodProductRemove = async function (row) {
          await commonSVC.showConfirmCustomSwal2({
            title: '<div style="text-align: left;">SKU상품 매칭 해제 안내</div>',
            text: '<div style="white-space: pre-wrap;text-align: left;line-height: 2;">해당 옵션에 매칭된 SKU상품의 정보를 삭제합니다.\n매칭을 해제하는 경우 옵션의 이미지도 함께 삭제되며,\n검색 버튼 클릭으로 다른 SKU상품을 매칭할 수 있습니다</div>',
            showCancelButton: true,
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
          }, (confirm) => {
            if (confirm) {
              row.prod_img = '/assets/images/upload.png';
              row.sku_cd = null;
              row.set_no = null;
              row.set_cd = null;
              row.depot_no = null;
              row.depot_name = null;
              row.prod_no = null;
              row.prod_name = null;
              row.set_name = null;
              row.sale_price = null;
              row.attri = null;
              row.stock_cnt = null;
              row.safe_stock = null;
              $timeout(() => {});
            }
          });
        };

        /**
         * 쇼핑몰 판매수량을 판매가능재고로 변경
         * 세트상품 및 sku 매칭 안되어 있는건 제외
         */
        $scope.apply_stock_cnt = (options_table) => {
          options_table.forEach((option, index) => {
            if (option.prod_no && !option.set_no) {
              if ($scope.data.ebay_shop_mas_no && $scope.data.shop_cd === 'A006') {
                $scope.addOptTable[index].A001_cnt_limit = option.stock_cnt;
                $scope.addOptTable[index].A006_cnt_limit = option.stock_cnt;
              } else {
                $scope.addOptTable[index].cnt_limit = option.stock_cnt;
              }
            }
          });
        };

        /**
         * 세트 상세보기
         */
        $scope.setShowDetail = function (set_no) {
          const resolve = {
            data: { from: '수정', set_no: set_no }
          };

          commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
        };

        $scope.submitCnt = function(ob, type) {
          let flag = false;

          if (type != 'prod_status' && $scope.cnt_limit_all == '') {
            commonSVC.showMessage('실패', '일괄 입력할 값을 입력해 주십시오.');

            return false;
          }

          angular.forEach(ob, function(row) {
            if (row.checked) {
              if (type == 'sale_cnt') {
                if ($scope.esmMasterFlag) {
                  row.A001_cnt_limit = Number($scope.cnt_limit_all);
                  row.A006_cnt_limit = Number($scope.cnt_limit_all);
                } else {
                  row.cnt_limit = Number($scope.cnt_limit_all);
                }
              } else if (type == 'add_price') {
                row.opt_add_price = Number($scope.cnt_limit_all);
                row.opPrice = Number($scope.cnt_limit_all); //추가구매 옵션
              } else if (type == 'prod_status') {
                if ($scope.cnt_limit_all == '') {
                  commonSVC.showMessage('일괄입력 실패', '[정상] 또는 [품절] 중의 하나의 상태를 선택해 주시면 일괄 변경이 가능합니다.');
                } else {
                  row.prod_status = $scope.cnt_limit_all;
                }
              }
              flag = true;
            }
          });

          if (!flag) {
            commonSVC.showMessage('실패', '일괄입력 하실 옵션 상품을 선택해주세요.');
          }
        };

        /**
         * 상품관리 - 추가구매옵션에 추가금액 tag 처리 및 추가금액 tag 등록 이벤트 등록 함수
         */
        function init() {
          if ($scope.optTarget === 'edit') {
            $scope.ADD_OPT_LIST_LIMIT = 100;   // 더보기 버튼 출력 제한 개수 변수(더보기 가능한 숫자 계산에도 사용)
            $scope.initAddOptListCnt = 1;   // 더보기 횟수 초기화 변수
            $scope.totalLoadAddOptCnt = Math.ceil($scope.originAddOptTable.length / $scope.ADD_OPT_LIST_LIMIT);

            $scope.loadAddOptList(true);
          }

          setTimeout(() => {
            for (const index in $scope.addOpt) {
              const opt = $scope.addOpt[index];

              if (opt.opPrice) {
                const opPrice = opt.opPrice.split(',');
                for (const priceIndex in opPrice) {
                  $(`#opPrice_${index} div.bootstrap-tagsinput`).siblings().eq(0).tagsinput('add', {
                    id: priceIndex,
                    text: opPrice[priceIndex]
                  });
                }
              }

              tagsInputEvent(index);
            }
          }, 500);
        }

        init();
      }
    };
  });

