'use strict';

angular.module('gmpApp')
  .controller('SettingsGlobalShopDetailCtrl', function ($rootScope, $scope, $state, $stateParams, shopAccountSVC, shopAccountModel, commonSVC, siteInfo, userInfo, gettextCatalog, $window, settings, $cookies, templateModel, $q, commonModel, $timeout) {
    $rootScope.channelDetailOpen = false;                         // 상세페이지 open 여부
    $scope.isChannelAdd = $stateParams.mode === 'add';           // 추가페이지 or 수정페이지
    $scope.reCheckClass = {};
    $scope.pa_shop_cd = $stateParams.pa_shop_cd;
    $scope.selectedShopList = []; // ShopeeKRSC 샵 리스트

    // 보안정보 수정
    $scope.encryptData = {
      password: {
        isEdit: false,
        oldvalue: '',
        placeHolder: '수정버튼을 눌러 PW 변경',
        buttonText: '수정'
      },
      apikey: {
        isEdit: false,
        oldvalue: '',
        placeHolder: '수정버튼을 눌러 API KEY 변경',
        buttonText: '수정'
      }
    };

    if (!$scope.isChannelAdd) { // 로그인 정보 입력 폼 수정여부 확인(신규추가가 아니면 default값 true)
      $scope.loginChecked = true;
    }

    init();

    $scope.init = function () {
      init();
    };

    async function init() {
      if ($scope.isChannelAdd) { // 추가시
        $scope.vdata = angular.copy(shopAccountSVC.dafaultVdata);
        $scope.vdata.pa_shop_cd = $scope.pa_shop_cd;
        $scope.vdata.shop_name = decodeURIComponent($stateParams.shop_name);
        if ($stateParams.pa_shop_cd === 'X095') {
          $scope.vdata.country = $stateParams.ctry_cd;
        }
      } else { // 수정시
        const params = {
          shop_cd: $stateParams.pa_shop_cd === 'X095' ? $stateParams.shop_cd : $stateParams.pa_shop_cd,
          shop_id: $stateParams.shop_id,
          shop_id_sub: $stateParams.shop_id_sub
        };

        const { status, data } = await shopAccountModel.load(params);

        if (status === 200) {
          const result = data.results;

          $scope.vdata = result;
          $scope.encryptData.apikey.oldvalue = angular.copy(result.api_enc);
          $scope.encryptData.password.oldvalue = angular.copy(result.shop_pwd);
          $scope.vdata.new_pwd = '';

          $scope.vdata.pa_shop_cd = $stateParams.pa_shop_cd;
          if ($stateParams.pa_shop_cd === 'X099') {
            $scope.selectedShopList = Object.values(_.groupBy(result.etc, 'etc6'));
          }
          if ($stateParams.pa_shop_cd === 'X095') {
            $scope.vdata.country = $stateParams.ctry_cd;
          }
          if ($stateParams.pa_shop_cd === 'X094') {
            $scope.vdata.etc3 = result.etc3 || '';
            $scope.vdata.etc4 = result.etc4 || '';
            $scope.vdata.etc5 = result.etc5 || '';
            $scope.vdata.etc7 = result.etc7 || '';
            $scope.channelList = [{
              channel_currency: result.etc7,
              channel_shop_name: result.etc5,
              channel_seller_id: result.etc3,
              channel: result.etc4,
            }];
          }
          if ($stateParams.pa_shop_cd === 'X093') {
            $scope.vdata.etc2 = result.etc2 || '';
            $scope.vdata.etc7 = result.etc7 || '';
          }
        } else {
          commonSVC.showToaster('error', '', gettextCatalog.getString('쇼핑몰 계정정보 로드 실패'));
        }
      }
    }

    /**
     * 토큰 생성
     */
    $scope.makeToken = async (shop_cd) => {
      $scope.loginChecked = false;
      window.localStorage.setItem('succeededToken', '');
      $cookies.put('pa_shop_cd', $scope.pa_shop_cd);
      let tokenUrl = '';
      let shopName = '';
      const shopPwd = $scope.encryptData.password.isEdit && $scope.vdata.new_pwd ? $scope.vdata.new_pwd : ($scope.encryptData.password.oldvalue ? $scope.encryptData.password.oldvalue : $scope.vdata.shop_pwd);

      if (!$scope.vdata.shop_id || !shopPwd) {
        commonSVC.showMessage('실패', '아이디 또는 비밀번호가 빈값입니다.');

        return false;
      }

      if ($scope.pa_shop_cd === 'X099') {
        shopName = 'shopee';

        const re = await commonSVC.sendUrl('POST', settings.pa20ApiUrl + '/app/global/getShopeeTokenUrl', { redirect_url: settings.appUrl });

        tokenUrl = re.data;
      } else if ($scope.pa_shop_cd === 'X096') {
        shopName = 'buyma';

        tokenUrl = settings.buymaTokenUrl;
      } else if ($scope.pa_shop_cd === 'X095') {
        shopName = 'lazada';

        tokenUrl = settings.lazadaTokenUrl;
      } else {
        switch ($scope.pa_shop_cd) {
          case 'Z002':
            shopName = 'ebay';
            break;
          case 'X095':
            shopName = 'lazada';
            break;
          case 'Z015':
            shopName = 'wish';
            break;
        }
        tokenUrl = settings[`${shopName}TokenUrl`];
      }

      const x = screen.width / 2 - 800 / 2;
      const y = screen.height / 2 - 700 / 2;

      const _window = $window.open(tokenUrl, `${shopName}GetToken`, 'width=1100,height=700,left=' + x + ',top=' + y);

      // broadcast, emit 으로 이벤트가 안받아와져서 로컬스토리지 형태로 받아서 타이머 돌려서 결과값 받음
      const tokenTimer = setInterval(async function () {
        if (window.localStorage.getItem('succeededToken') == 'Y') {
          clearInterval(tokenTimer);

          const token_data = JSON.parse(window.localStorage.getItem('token_data'));

          if ($scope.pa_shop_cd === 'X095') {
            if (token_data.country.toUpperCase() !== shop_cd.toUpperCase()) {
              commonSVC.showMessage(
                gettextCatalog.getString(`토큰 발급 쇼핑몰(국가)이 ${shop_cd}와 일치하지 않습니다.`)
              );

              return;
            }

            $scope.vdata.shop_id = token_data.account;
          }

          try {
            await makeShopData(token_data);

            const success_msg = {
              X099: 'Market ID가 생성되었습니다.',
              X096: 'Access Token 발급에 성공하였습니다.',
              X095: 'Refresh Token 발급에 성공하였습니다.'
            };

            commonSVC.showToaster('success', '성공', success_msg[$scope.pa_shop_cd]);

          } catch (e) {
            commonSVC.showMessage('토큰 발급 실패', e);
          }

          $timeout(() => { });
        } else if (window.localStorage.getItem('succeededToken') == 'N') {
          clearInterval(tokenTimer);

          const failure__msg = {
            X099: 'Market ID 발급에 실패하였습니다.',
            X096: 'Access Token 발급에 실패하였습니다.',
            X095: 'Refresh Token 발급에 실패하였습니다.'
          };

          commonSVC.showToaster('error', '실패', failure__msg[$scope.pa_shop_cd]);
        }

        /** 토큰 팝업창이 닫히면, 이벤트 종료 */
        if (!_window || _window.closed) {
          clearInterval(tokenTimer);
        }
      }, 1000);
    };

    /**
     * 계정 삭제
     */
    $scope.channelDel = function () {
      $scope.channelDetailOpen = false;
      commonSVC.showConfirm('삭제하시겠습니까?', '계정 삭제 시 상품, 주문, 문의 등 해당 계정의 모든 정보가 삭제되며 복구가 불가능하므로 주의바랍니다. \n- "출고완료" 이후 (출고완료/배송중/구매결정/배송완료) 주문은 삭제되지 않습니다. \n- 계정 삭제 후 솔루션을 재로그인하여 확인해주시기 바랍니다.', function () {
        const param = {
          pa_shop_cd: $scope.pa_shop_cd,
          shop_cd: $scope.vdata.shop_cd,
          shop_id: $scope.vdata.shop_id,
          shop_id_sub: $scope.vdata.shop_id_sub,
          seller_nick: $scope.vdata.seller_nick,
          uuid: $scope.vdata.uuid,
          etc: $scope.vdata.etc // etc에 담긴 하위 쇼핑몰 데이터 함께 삭제
        };

        shopAccountModel.delete(param, function (state) {
          if (state != 'success') {
            commonSVC.showToaster('error', '', '쇼핑몰 계정 삭제에 실패하였습니다.');
          } else {
            commonSVC.showToaster('success', '', '쇼핑몰 계정이 삭제 되었습니다.');
            // 사용중 쇼핑몰 정보 불러오기
            // pdb_s 에서 조회되기까지 시간이 걸리므로 0.5 초 딜레이 2018-07-02 rony
            $timeout(function () {
              $scope.reRoadUseChannel('delete');
            }, 500);
          }
        });
      });
    };

    /**
     * pa5 호출 request body 생성
     */
    const getRequestBody = async () => {
      const no_apikeyDecrypt = $scope.isChannelAdd || ($scope.encryptData.apikey.isEdit && $scope.vdata.new_api_enc);
      let market_password = $scope.vdata.shop_pwd;

      if ($scope.encryptData.password.isEdit && $scope.vdata.new_pwd) {
        market_password = $scope.vdata.new_pwd;
      } else if ($scope.encryptData.password.oldvalue) {
        market_password = $scope.encryptData.password.oldvalue;
      }

      const { data: etcInfo } = await shopAccountModel.getShopEtcData({ shop_cd: $scope.vdata.pa_shop_cd });

      const body = {
        id: $scope.vdata.uuid?.split('|')[0] || '',
        shop_id: `${userInfo.user.sol_no}`,
        market_code: siteInfo[$scope.pa_shop_cd].playapi_shop_cd,
        market_username: $scope.vdata.shop_id,
        market_password,
        etc: {},
      };

      // pa5 로그인체크시에는 etc값을 치환해서 넘겨줌
      if (etcInfo) {
        Object.keys(etcInfo).forEach(k => {
          // apikey 암호화 관련 처리
          if (etcInfo[k].api_enc) {
            let sellerApikey = $scope.vdata.api_enc;

            if (!no_apikeyDecrypt) {
              sellerApikey = $scope.encryptData.apikey.oldvalue;
            } else if (!$scope.isChannelAdd) {
              sellerApikey = $scope.vdata.new_api_enc;
            }

            body.apikeyData = {
              value: sellerApikey,
              field: etcInfo[k].field,
              isEncrypt: !no_apikeyDecrypt
            };
          } else if (etcInfo[k].field) {
            body.etc[etcInfo[k].field] = $scope.vdata[k];
          }
        });
      }

      return body;
    };

    /**
     * 판매자센터 로그인페이지 오픈
     */
    $scope.openAdmin = () => {
      let adminUrl = $scope.$parent.params?.admin_url;

      $scope.$parent.params?.admin_url.match(/{{\w+}}/g)?.forEach(val => {
        adminUrl = adminUrl.replace(val, $scope.$parent.params[val.replace(/[{}]/g, '')]);
      });

      window.open(adminUrl, '_blank');
    };

    $scope.getEngineData = async (action = '', action_name = '') => {
      const pa_shop_cd = $scope.pa_shop_cd;
      const body = await getRequestBody();
      if (!body.market_password) {
        commonSVC.showMessage('비밀번호를 입력해주세요.');

        return false;
      }
      if (pa_shop_cd === 'X094' && (!$scope.vdata.api_enc || !$scope.vdata.etc2)) {
        commonSVC.showMessage('토큰을 발급 받은 뒤 입력해 주세요.');

        return false;
      }
      try {
        showSpinner('loginSpinner', `${action_name} 로드 중`);

        const data = await commonModel.pa5(`/channel_accounts/${action}`, body);
        const { engine_result } = data.data;
        if (engine_result.has_error) {
          throw Error();
        }

        if (pa_shop_cd === 'X094') {
          $scope.channelList = engine_result.results[0].data;
        }

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }

        return true;
      } catch {
        $scope.loginChecked = false;
        commonSVC.showToaster('error', '실패', `${action_name} 로드에 실패했습니다.`);

        return false;
      } finally {
        hideSpinner('loginSpinner');
      }
    };

    /**
     * 로그인 체크
     */
    $scope.checkLogin = async () => {
      $scope.loginChecked = false;

      // 비번 수정 시 비번
      const shopPwd = $scope.isChannelAdd ? $scope.vdata.shop_pwd : ($scope.encryptData.password.isEdit ? $scope.vdata.new_pwd : $scope.encryptData.password.oldvalue);

      // 로그인 체크에 필요한 인자로 각 쇼핑몰 마다 필요시 etc데이터를 넣어주면 됨
      const params = {
        pa_shop_cd: $scope.pa_shop_cd,
        shop_cd: $scope.pa_shop_cd === 'X095' ? $stateParams.shop_cd : $scope.pa_shop_cd,
        shop_id: $scope.vdata.shop_id,
        shop_id_sub: $scope.vdata.shop_id_sub,
        shop_pwd: shopPwd,
        etc1: $scope.vdata.etc1,
        etc2: $scope.vdata.etc2,
        etc3: $scope.vdata.etc3,
        etc4: $scope.vdata.etc4,
        api_enc: $scope.vdata.api_enc,
        uuid: $scope.vdata.uuid,
        isChannelEdit: !$scope.isChannelAdd,
        ...($scope.pa_shop_cd === 'X099' && { etc: $scope.selectedShopList.flat() }),
      };

      if ($scope.encryptData.password.isEdit && !$scope.vdata.new_pwd) {
        commonSVC.showMessage('비밀번호를 변경하시려는 경우 변경할 비밀번호를 입력해주십시오.');

        return false;
      }

      if ($scope.form1.$valid) {
        showSpinner('loginSpinner', '로그인 확인중');

        try {

          if (['X094'].includes($scope.pa_shop_cd)) {
            if ($scope.pa_shop_cd === 'X094') {
              if (!$scope.vdata.etc3) {
                commonSVC.showMessage('채널을 선택해 주세요.');
                hideSpinner('loginSpinner');

                return false;
              } else {
                const { channel_currency, channel_shop_name, channel } = $scope.channelList.find(({ channel_seller_id }) => channel_seller_id.toString() === $scope.vdata.etc3);
                $scope.vdata.etc4 = channel;
                $scope.vdata.etc5 = channel_shop_name;
                $scope.vdata.etc7 = channel_currency;
              }
            }
            const body = await getRequestBody();
            const data = await commonModel.pa5('/channel_accounts/verify_account', body);
            const { engine_result } = data.data;

            if (engine_result) {
              if (engine_result.has_error) {
                throw engine_result.exception_message || engine_result.results?.[0].site_error || '알수없는 오류로 실패하였습니다. 잠시 후 재시도 해주세요.';
              } else {
                $scope.vdata.uuid = engine_result.channel_account_id;
              }
            } else {
              throw Error();
            }
          } else if (['X093'].includes($scope.pa_shop_cd)) {
            const body = await getRequestBody();
            const data = await commonModel.pa5('/channel_accounts/verify_account', body);
            const { engine_result } = data.data;

            if (engine_result) {
              if (engine_result.has_error) {
                throw engine_result.exception_message || engine_result.results?.[0].site_error || '알수없는 오류로 실패하였습니다. 잠시 후 재시도 해주세요.';
              } else {
                $scope.vdata.uuid = engine_result.channel_account_id;
                $scope.vdata.etc7 = engine_result.results[0].data.etc.market_default_currency;
              }
            } else {
              throw Error();
            }
          } else {
            const data = await shopAccountModel.checkLoginValid(params);
            const result = data.data;

            if (result.status !== 'success') {
              throw result.error;
            }

            $scope.vdata = Object.assign($scope.vdata, result.results);

            if ($scope.pa_shop_cd === 'X099') {
              $scope.selectedShopList = _.unionBy($scope.vdata.etc, $scope.selectedShopList.flat(), (o) => `${o.etc5} ${o.shop_id}`);

              $scope.selectedShopList = Object.values(_.groupBy($scope.selectedShopList, 'etc6'));
            }
          }

          $scope.loginChecked = true;

          commonSVC.showMessage('로그인 성공', '입력하신 계정정보로 로그인이 확인되었습니다.\n페이지 하단의 [저장]버튼을 눌러 계정 추가를 완료해주세요.');

          hideSpinner('loginSpinner');
        } catch (err) {
          $scope.loginChecked = false;

          const errMsg = err || '입력하신 계정정보로 로그인이 불가능합니다.\n쇼핑몰 판매자페이지에서 로그인정보를 확인해주시기 바랍니다.';
          commonSVC.showMessage('로그인 실패', errMsg);
          hideSpinner('loginSpinner');
        }

      } else {
        commonSVC.showMessage('필수 항목을 확인하여 주시길 바랍니다.');
      }
    };

    /**
     * 쇼핑몰계정 저장/수정 버튼
     */
    $scope.onSubmit = function () {
      if (!$scope.loginChecked) {
        commonSVC.showMessage('로그인 확인을 해주세요.');

        return false;
      }
      $scope.submitDisabled = true;

      if ($scope.form1.$valid) {
        $scope.isChannelAdd ? $scope.regist() : $scope.save();
      }
    };

    // 어드민 로그인 체크
    let limit_data = {};

    /**
     * 쇼핑몰계정 신규추가시 추가버튼
     */
    $scope.regist = async () => {

      try {

        // 버전별 쇼핑몰 ID수 제한 확인
        const idLimitRe = await shopAccountModel.checkLimit();
        limit_data = idLimitRe.data.results;

        if (limit_data.lim != '무제한' && !limit_data.freeShops?.includes($scope.vdata.pa_shop_cd) && parseInt(limit_data.total) >= parseInt(limit_data.lim)) {
          throw 'limit_error';
        }
        const isDup = await checkDup();

        if (!isDup) {
          return false;
        }

        const params = { ...$scope.vdata, ...($scope.pa_shop_cd === 'X099' && { etc: $scope.selectedShopList.flat() }), ...($scope.pa_shop_cd === 'X095' && { shop_cd: $stateParams.shop_cd }) };

        const re = await shopAccountModel.insert(params);

        if (re.status === 200) {
          $scope.submitDisabled = false;

          commonSVC.showToaster('success', '', '계정이 추가 되었습니다.');

          // pdb_s 에서 조회되기까지 시간이 걸리므로 0.5 초 딜레이 2018-07-02 rony
          $timeout(function () {
            $scope.reRoadUseChannel('add');
            init();
          }, 500);

          if (userInfo.user.user_grade === '마스터') {
            const msg = `솔루션 사용중에 추가된 쇼핑몰 계정은 마스터(관리자) 계정으로 로그인 후<br>
            각 사용자의 권한 설정 메뉴에서 채널별관리 권한을 부여하신 후 노출됩니다.<br>
            <div class="pt-10 text-grey-300">
              * 마스터(관리자)계정은 권한설정 불필요<br>
              * 사용자 계정에 "총괄관리자" 권한 부여되어 있을 시 권한설정 불필요
            </div>
          `;

            commonSVC.showMessageHtml('추가된 쇼핑몰의 관리 권한 설정 안내', msg, function () {
              $state.go('main.configs_user');
            }, { confirmButtonText: '사용자 관리로 이동', showCancelButton: true });
          } else {
            const msg = `솔루션 사용중에 추가된 쇼핑몰 계정은<br>
            채널별 관리 권한이 설정되어야 솔루션에 노출됩니다.<br>
            귀사의 마스터 권한이 있는 담당자에게 권한 설정을 요청 하신 후 이용해주세요.<br>
            - 경로 : 마스터(관리자) 계정으로 로그인>설정>사용자관리>각 계정의 상세>권한설정 탭>채널별관리
            <div class="pt-10 text-grey-300">
              * "총괄관리자" 권한이 부여되어 있는 사용자 계정 일시 권한 설정 요청 불필요
            </div>
          `;

            commonSVC.showMessageHtml('추가된 쇼핑몰의 관리 권한 설정 안내', msg, null);
          }
        } else {
          throw '계정추가 실패';
        }
      } catch (err) {
        $scope.submitDisabled = false;

        if (err.data.error_code === 'e1013') {
          commonSVC.showToaster('error', '', '이미 등록된 계정입니다.');
        } else if (err === 'limit_error') {
          let errMsg = `이용중인 버전에서 사용할 수 있는 쇼핑몰 ID 수가 부족합니다. \n추가 사용을 원하실 경우  이용버전을 업그레이드 하시기 바랍니다.\n- 현재 버전 ${userInfo.user.sol_version} : 쇼핑몰 ID - ${limit_data.lim}개 사용 가능`;

          // VIP 버전 쇼핑몰 계정 100 개 초과시 에러메세지 변경.
          if (userInfo.user.sol_version === 'VIP') {
            errMsg = 'PLAYAUTO 2.0에 등록할 수 있는 쇼핑몰 계정은 100 개를 초과할수 없습니다.';
          }

          commonSVC.showMessage('실패', errMsg);
        } else if (err.data) {
          const msg = err.data.message || err.data.error;

          commonSVC.showMessage('실패', msg);
        } else {
          const msg = '계정 추가에 실패했습니다.';

          commonSVC.showMessage('계정 추가 실패', msg);
        }
      }
    };

    /**
     * 계정 중복검사
     */
    const checkDup = async () => {
      $scope.vdata.etc7 = $scope.vdata.etc7 ? $scope.vdata.etc7 : '';

      const params = {
        shop_cd: [$scope.pa_shop_cd],
        pa_shop_cd: $scope.pa_shop_cd,
        shop_id: $scope.vdata.shop_id,
        shop_id_sub: $scope.vdata.shop_id_sub,
        shop_name: $scope.vdata.shop_name
      };

      try {
        const re = await shopAccountModel.checkDuplicate(params);

        if (re.data.results.state) {
          $scope.vdata.shop_cd = re.data.results.shop_cd || $scope.vdata.pa_shop_cd;

          return true;
        } else {

          commonSVC.showToaster('error', '', '중복된 계정입니다.');

          return false;
        }
      } catch (err) {
        if (err?.data?.messages[0] === 'Duplicate Shop Account') {
          commonSVC.showMessageHtml('쇼핑몰 계정 관리 안내', `
            다른 마스터 계정에 등록된 쇼핑몰 ID입니다.<br/>
            Zero 버전에서는 동일한 쇼핑몰 ID는 추가 및 관리가 불가합니다.
            <ul>
              <li>상위 버전으로 업그레이드</li>
              <li>기등록된 쇼핑몰 ID 삭제 후 추가</li>
            </ul>`);
        } else {
          commonSVC.showToaster('error', '', '중복검사 실패.');
        }

        return false;
      }
    };

    /**
     * 쇼핑몰계정 수정시 저장버튼
     */
    $scope.save = async function () {
      // 비밀번호 수정을 하는경우 처리.
      if ($scope.encryptData.password.isEdit) {
        if (!$scope.vdata.new_pwd) {
          commonSVC.showMessage('비밀번호를 변경하시려는 경우 변경할 비밀번호를 입력해주십시오.');

          return false;
        } else {
          $scope.vdata.shop_pwd = $scope.vdata.new_pwd;
        }
      } else {
        $scope.vdata.shop_pwd = '';
      }

      // apikey 수정하는경우
      if ($scope.encryptData.apikey.isEdit) {
        if (!$scope.vdata.new_api_enc) {
          commonSVC.showMessage('API KEY를 변경하시려는 경우 변경할 정보를 입력해주십시오.');

          return false;
        } else {
          $scope.vdata.api_enc = $scope.vdata.new_api_enc;
        }
      } else {
        $scope.vdata.api_enc = '';
      }

      const params = { ...$scope.vdata, ...($scope.pa_shop_cd === 'X099' && { etc: $scope.selectedShopList.flat().filter(o => !!o.shop_id_sub) }), };

      /**
       * ShopeeKRSC 계정 추가시 별도 모달
       */
      switch ($scope.pa_shop_cd) {

        case 'X099': {
          if ($scope.selectedShopList.flat().some(shop => !shop.shop_id_sub)) {
            const opt = {
              title: '<h2 class="text-danger text-bold" style="text-align: center !important; font-size: 25px">*경고*<div style="text-align: center; font-size: 16px; !important">토큰 발급 시 선택되지 않은 계정은 삭제됩니다.</div></h2>',
              text: `
            <div class="sweet-alert-body no-padding-top" style="font-size: 14px !important">
              <span class="text-danger">* 값이 비어있는 계정을 확인해 주세요.</span><br>
              <span class="text-danger">* 계정 삭제 시 해당 계정에 등록된 모든 정보가 삭제되며 복구가 불가능합니다.</span><br>
              <span class="text-mute">- 취소 후 shopee 토큰 발급 페이지에서 머천트와 숍 계정을 모두 선택 후 [저장] 을 눌러주세요.</span><br>
              <span class="text-mute">- 단, 재고 수량이 차감된 '출고완료' 이후의 주문은 삭제되지 않습니다.<br>- 계정 삭제 후 솔루션을 새로고침하여 확인해주시기 바랍니다.</span><br>
            </div>`,
              width: 750,
              input: 'checkbox',
              inputValue: 0,
              inputPlaceholder: '연동 쇼핑몰 삭제에 대한 주의사항을 숙지했습니다.',
              confirmButtonText: '계정 삭제 후 저장',
              cancelButtonText: '취소 후 토큰 재발급',
              willOpen: function () {
                const cfBt = Swal2.getConfirmButton();
                cfBt.disabled = true;
                Swal2.getInput().addEventListener('change', () => {
                  cfBt.disabled = !Swal2.getInput().checked;
                });
              },
            };

            const { isConfirmed } = await commonSVC.showConfirmCustomSwal2(opt);

            if (!isConfirmed) {
              $scope.makeToken();

              return false;
            }
          }
          break;
        }
      }

      shopAccountModel.update(params, function (state, data) {
        if (data.results === 'success') {
          $scope.submitDisabled = false;

          commonSVC.showToaster('success', '', '계정 정보가 수정 되었습니다.');

          // 계정정보 변경시 로컬스토리지에 저장된 셋폼토큰 재발급 필요하므로 제거
          localStorage.removeItem(`gmp_setForm_token_${$scope.vdata.shop_cd}_${$scope.vdata.shop_id}_${$scope.sol_no}`);

          // pdb_s 에서 조회되기까지 시간이 걸리므로 0.5 초 딜레이 2018-07-02 rony
          $timeout(function () {
            // 저장 시 복수 스토어 이용여부 초기화
            $scope.reRoadUseChannel('update');
          }, 500);
        } else {
          $scope.submitDisabled = false;
          commonSVC.showToaster('error', '', '계정 정보 수정에 실패하였습니다.');
        }
      });
    };

    /**
     * 보안정보 수정 버튼 클릭
     */
    $scope.editEncryptData = (type) => {
      $scope.encryptData[type].isEdit = !$scope.encryptData[type].isEdit;

      if (!$scope.encryptData[type].isEdit) {
        $scope.encryptData[type].placeHolder = `수정버튼을 눌러 ${type} 변경`;
        $scope.encryptData[type].buttonText = '수정';
      } else {
        $scope.encryptData[type].placeHolder = `변경할 ${type}를 입력해주세요`;
        $scope.encryptData[type].buttonText = '취소';
        $(`#new_${type}`).focus();
      }
      // 알리익스프레스는 비밀번호, apikey 수정시 채널 불러오기 버튼 노출
      if (['password', 'apikey'].includes(type) && $scope.pa_shop_cd === 'X094') {
        $scope.showLoadChannel = true;
      }
    };

    // 로딩스피너 보이기
    function showSpinner(id, text) {
      $(`#${id} > div`).html(`<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i><br />${text}`);
      $(`#${id}`).removeClass('hidden');
    }

    // 로딩스피너 숨기기
    function hideSpinner(id) {
      $(`#${id}`).addClass('hidden');
    }

    /**
     * 쇼핑몰 토큰 발급 결과 처리 함수
     * @param {object} shopData 쇼핑몰 토큰 발급 결과값
     */
    async function makeShopData(shopData) {
      $('#spinner').removeClass('hidden');
      switch ($scope.pa_shop_cd) {
        case 'X099': { // 토큰 발급 후 merchant 정보 요청 후 가공
          const pa_cd = siteInfo[$stateParams.pa_shop_cd].playapi_shop_cd;
          const shopPwd = $scope.encryptData.password.isEdit && $scope.vdata.new_pwd ? $scope.vdata.new_pwd : ($scope.encryptData.password.oldvalue ? $scope.encryptData.password.oldvalue : $scope.vdata.shop_pwd);
          const no_decrypt = $scope.isChannelAdd || ($scope.encryptData.password.isEdit && $scope.vdata.new_pwd);

          const pa = {
            market_code: pa_cd,
            market_username: $scope.vdata.shop_id,
            market_password: shopPwd,
            no_decrypt: no_decrypt,
            ...shopData
          };

          if (no_decrypt) {
            pa.no_decrypt = shopPwd;
          }

          const res = await commonModel.pa5('/channel_accounts/verify_account', pa);
          const data = res.data;

          if (!data.engine_result.has_error) {
            const result = data.engine_result.results[0]?.data;
            const shop_list = [];

            result.forEach(merchant => {
              merchant.shop_list.forEach(shop => {
                $scope.vdata.etc3 = $scope.vdata.etc3 || shop.shop_etc;
                Object.entries(siteInfo).forEach(([key, v]) => {
                  if (key !== v.pa_shop_cd && v.pa_shop_cd === $scope.pa_shop_cd && v.country === shop.country) {
                    shop_list.push({
                      shop_cd: key,
                      shop_id: shop.shop_etc,
                      shop_id_sub: shop.shop_id,
                      shop_name: v.name,
                      etc3: $scope.vdata.etc3, // pickup 계정 그대로 저장
                      etc4: $scope.vdata.shop_id,
                      etc5: merchant.merchant_id,
                      etc6: merchant.merchant_name,
                      etc7: merchant.merchant_currency,
                      ctry_cd: v.country,
                    });
                  }
                });
              });
            });

            $scope.vdata.shop_id_sub = shopData.main_account_id;
            $scope.vdata.etc1 = shopData.etc.refresh_token;
            $scope.vdata.etc2 = shopData.etc.access_token;
            $scope.vdata.etc4 = $scope.vdata.shop_id;

            if ($scope.isChannelAdd) {
              $scope.selectedShopList = shop_list;
            } else {
              $scope.selectedShopList = $scope.selectedShopList.flat().map(o => {

                if (!shop_list.find((s) => s.etc5.toString() === o.etc5.toString() && s.shop_id === o.shop_id)) {
                  return { ...o, shop_id_sub: '' };
                } else {
                  shop_list.find((s) => s.etc5.toString() === o.etc5.toString() && s.shop_id === o.shop_id).seller_nick = o.seller_nick;
                }
              }).filter(Boolean);
              $scope.selectedShopList = _.unionBy($scope.selectedShopList, shop_list, (o) => `${o.etc5} ${o.shop_id}`);
            }
            $scope.selectedShopList = Object.values(_.groupBy($scope.selectedShopList, 'etc6'));
            $('#spinner').addClass('hidden');

          } else {
            $('#spinner').addClass('hidden');
            throw data.engine_result.results ? data.engine_result.results[0].site_error : '알수없는 오류로 실패하였습니다. 잠시 후 재시도 해주세요.';
          }

          break;
        }
        case 'X095': {
          $scope.vdata.etc1 = shopData.refresh_token;
          $scope.vdata.etc2 = shopData.access_token;
          $scope.vdata.etc3 = shopData.country.toUpperCase();
        }
      }
      $('#spinner').addClass('hidden');
    }
  });
