'use strict';

// angular ui.grid 모듈 사용한 데이터그리드 디렉티브
angular.module('gmpApp')
  .directive('paUiGrid', ($rootScope, $state, $q, $timeout, $compile, $sce, $http, $filter, tokenSVC, commonSVC, columnSVC, delaySVC, commonModel, localStorageService, uiGridConstants, userInfo) => {
    return {
      restrict: 'E',
      scope: true,
      template: `<div ui-grid="gridOptions" ui-grid-pagination ui-grid-selection ui-grid-resize-columns ui-grid-move-columns ui-grid-save-state ui-grid-pinning ui-grid-edit ui-grid-expandable class="grid">
                    <div class="ui-grid-empty-data" ng-show="!gridOptions.data.length && !gridDataLoading">
                      <div ng-if="!gridOptions.emptyTemplate">
                        <div ng-bind-html="gridOptions.emptyText"></div>
                        <div ng-bind-html="gridOptions.emptyTextSub"></div>
                      </div>
                      <div ng-if="gridOptions.emptyTemplate">
                        <div bind-html-compile="gridOptions.emptyTemplate"></div>
                      </div>
                    </div>
                    <placeholder-bg class="placeholder-bg ui-grid-placeholder-bg" ng-if="gridDataLoading"></placeholder-bg>
                  </div>`,

      link: (scope, elem, attr) => {
        scope.isScrolling = false;

        // 기본 값
        const DEFAULT_OPTIONS = {
          refreshDelay: 800,
          pagingSize: 100,
          modalPagingSize: 10,
          pagingSizes: [10, 25, 50, 100, 300, 500],
          colWidth: 150,
          rowHeight: 31,

          // 템플릿은 라이브러리 파일에서 직접 뜯어온거라 라이브러리에서 변경돼서 뭔가 안될시 여기에 넣기바람
          rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid" ui-grid-one-bind-id-grid="rowRenderIndex + \'-\' + col.uid + \'-cell\'" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div>',
          paginationTemplate: 'views/uiGrid/paginationTemplate.html'
        };

        // 오른쪽 정렬 클래스
        const ALIGN_RIGHT_CLASS = 'text-right';
        // 중앙 정렬 클래스
        const ALIGN_CENTER_CLASS = 'text-center';

        // 그리드 유니크 ID
        const GRID_ID = `uiGrid_${attr.id}`;
        // 스토리지 저장용 키
        const GRID_STORAGE_ID = `${GRID_ID}_${userInfo.user.sol_no}${userInfo.user.m_no}`;

        const CHECKBOX_WIDTH = 42;

        // 컬럼별 스크롤 초기화 정의
        const NOT_COL_SCROLL_INIT_COLS = ['orderby'];
        const NOT_ROW_SCROLL_INIT_COLS = [];
        const NOT_SCROLL_INIT_COLS = [];

        // 한 페이지에 여러개의 테이블이 존재할경우 parent Attribute값으로 받아서 스코프 재 지정
        const _gridObjScope = attr.parent ? scope[attr.parent] : scope.grid;
        const _gridSearchObjScope = attr.parent && attr.splitView ? scope[attr.parent] : scope;
        /**
         * @name options
         * @description 그리드 설정 옵션
         *
         * @param {Boolean} modal - modal 여부
         * @param {Number} gridHeight - grid 높이 사이즈
         * @param {Boolean} noAutoResize - 자동 리사이징
         * @param {Boolean} enableSelectAll - 전체 선택 가능여부
         *
         * @param {Array} pinningColumns - 왼쪽 고정 컬럼
         * @param {Array} alignCenterColumns - 텍스트 가운데 정렬 컬럼
         * @param {Array} alignRightColumns - 텍스트 왼쪽 정렬 컬럼
         * @param {Array} defaultSortingColumns - 데이터 기본 정렬 컬럼
         * @param {Array OR String} notSortingColumns - 데이터 정렬 불가 컬럼
         * @param {Array OR String} notResizingColumns - 리사이징 불가 컬럼
         * @param {Array OR String} notMovingColumns - 무빙 불가 컬럼
         * @param {Array OR String} notVisibleColumns - 기본 노출 안하는 컬럼
         * @param {Array} enablePaginationControls - 페이지 노출 여부
         *
         * @param {Function} rowSpanToolTipFunc - 특정 조건으로 row 전체에 툴팁을 붙이고 싶은 경우 사용
         * @param {String} uiGridNgClass - 로우 ng-class (ex. {'sample': row.entity['data1'] < row.entity['data2']})
         * @param {String} page - 그리드 그려지는 페이지명
         * @param {Object} selectOptions - 로우 선택 옵션
         *  @param {Boolean} checkbox - 체크박스 여부
         *  @param {Boolean} multiSelect - 다중선택 여부
         *  @param {Boolean} notClickable - 클릭 불가 여부
         *  @param {Function} notClickableOption - 클릭 불가 로우 유효성 검사
         *  @param {String} headerClass - 헤더 적용 클래스 (체크박스))
         *
         * @param {Object} bundleOptions - 묶음 옵션
         *  @param {String} bundleCountKey - 묶음 카운팅 구분 키
         *  @param {String} bundleDataKey - 묶음 데이터 구분 키
         *  @param {String OR Array} bundleUniqKey - 묶음 유니크 구분 키
         *
         * @param {Object} familyOptions - 패밀리 옵션
         *  @param {String} familyCountKey - 패밀리 카운팅 구분 키
         *  @param {String} familyDataKey - 패밀리 데이터 구분 키
         *  @param {String} parentDataKey - 부모 데이터 구분 키
         *  @param {String} parentDataValue - 부모 데이터 구분 값
         *  @param {Function} parentRowTemplate - 부모 데이터 로우 템플릿
         *
         * @param {Object} groupOptions - 그룹 옵션
         *  @param {String} groupCountKey - 그룹 카운팅 구분 키
         *  @param {String} groupExceptionKey - 그룹 분리 키
         *  @param {String} groupDataKey - 그룹 구분 키
         *  @param {Function} groupRowHeaderTemplate - 그룹 로우 고정컬럼 템플릿
         *  @param {Function} groupRowTemplate - 그룹 로우 컬럼 템플릿
         *
         * @param {Object} externalRequestOptions - 데이터 요청 옵션
         *  @param {String} requestMethod - 데이터 통신 method
         *  @param {Boolean} isDirectInsert - api 통신 없이 데이터 직접 입력 여부
         *  @param {Array} directData - isDirectInsert가 true일 경우 직접 입력 데이터
         *  @param {String} requestUrl - 요청 URL
         *  @param {Function} requestWillAction - 요청 전 액션
         *  @param {Function} requestDidAction - 요청 후 액션
         *
         * @param {Array} columns - 컬럼 옵션
         *  @param {Object} column
         *    @param {String} key - 컬럼 데이터 키
         *    @param {String} title - 컬럼 헤더 타이틀
         *    @param {Number} width - 컬럼 너비
         *    @param {Boolean} notVisible - 컬럼 노출 여부
         *    @param {String OR Object} tooltip - 컬럼 헤더 툴팁
         *      @ifObject
         *      @param {String} text - 툴팁 내용
         *      @param {String} placement - 툴팁 위치
         *      @param {String} forView - 노출 조건
         *      @param {String} tooltipStyle - 스타일
         *    @param {String} filter - 텍스트 필터 네임(html리턴은 적용 안됨)
         *    @param {Boolean} requireStock - 컬럼 재고관리 전용 여부
         *    @param {Function} template - 컬럼 커스텀 템플릿
         *    @param {Function} addColGrid - 컬럼추가 버튼 노출 테이블
         *    @param {Boolean}  required - 필수 삽입일 경우 ast 노출
         *    @param {Boolean}  hideColVis - 노출항목설정 리스트 미노출 여부
         *    @param {String}   mouseOverTooltip - 마우스오버 툴팁
         *
         */

        const _options = Object.assign({}, _gridObjScope.options);

        // 2020-07-24 Boris
        // 설정을 안한 컬럼의 정렬을 commonSVC 기본값으로 설정
        const notInOption = Object.keys(commonSVC.dtAlign).reduce((arr, cur) => {
          if (!_options.hasOwnProperty(cur)) {
            arr.push(cur);
          }

          return arr;
        }, []);

        if (notInOption.length > 0) {
          notInOption.forEach((col) => {
            _options[`${col}`] = [];
          });

          // 컬럼 데이터의 key를 순환하며 기본설정 불러오기
          _options.columns.forEach(({ key, notSorting }) => {
            // 커스텀 컬럼은 특수한 인자를 받아서 정렬 안되도록 설정
            if (notSorting) {
              _options.notSortingColumns.push(key);
            }
            notInOption.forEach((col) => {
              if (commonSVC.dtAlign[`${col}`].includes(key)) {
                _options[`${col}`].push(key);
              }
            });
          });
        }

        // 정렬불가컬럼 설정 테이블 내에서 따로 한 경우 공통설정 추가 적용
        if (!notInOption.includes('notSortingColumns')) {
          _options.notSortingColumns = _options.notSortingColumns.concat(commonSVC.dtAlign.notSortingColumns);
        }

        scope.pagination = {
          uniqId: Math.floor(Math.random() * 1000000),
          panelStyle: {
            minWidth: '471px',
            display: 'flex',
            justifyContent: 'center'
          },
          infoList: [],
          totalDivideInfoList: [],
          popupShow: false,
          popupCurrentButtonCloseYn: false,
          popupMovePage: row => {
            _gridOptions.paginationCurrentPageMover(row);
            scope.pagination.popupShow = false;
            scope.pagination.justifyContent = _settingPaginationAlign();
          },
          currentOnClick: () => {
            // 팝업창에 해당하는 리스트가 없으면 보여줄 필요 없음 && 현재 페이지를 클릭했을 때도 팝업창이 사라지도록 처리
            scope.pagination.popupShow = !!scope.pagination.totalDivideInfoList.length && !scope.pagination.popupShow;

            // ng-if 이슈로 css적용시키려면 timeout이 필요
            $timeout(() => {
              const popupElem = angular.element('#pagination-popup');
              const currentElem = angular.element(`#current-page-${scope.pagination.uniqId}`);
              const buttonBoxElem = angular.element('#pagination-popup-button-box');
              const htmlElem = angular.element('html');

              htmlElem.off('click');

              // 팝업창 사이즈 조절
              const popupWidth = scope.pagination.totalDivideInfoList.length ? (scope.pagination.totalDivideInfoList[0].length * 40 + 4) + 4 : 0;
              const popupHeight = (scope.pagination.totalDivideInfoList.length > 5 ? 5.5 : scope.pagination.totalDivideInfoList.length) * 40 + 10;

              // 팝업창 위치 조정
              popupElem.css({
                top: 8 - popupHeight,
                left: currentElem.position().left - (popupWidth / 2) + 16,
                height: popupHeight,
                width: popupWidth,
                display: 'block'
              });

              buttonBoxElem.css({
                height: popupHeight - 12,
                overflowY: scope.pagination.totalDivideInfoList.length > 5 ? 'auto' : 'hidden',
              });

              // clickListener 추가
              htmlElem.on('click', e => {

                if (!$(e.target).hasClass('grid-popup') && !$(e.target).hasClass('current-page') && scope.pagination.popupShow) {
                  popupElem.css({ display: 'none' });
                  scope.pagination.popupShow = false;

                  // clickListener 제거
                  htmlElem.off('click');
                }
              });
            });
          },
          alignStyle: {
            marginTop: '8px',
            marginBottom: '8px',
          },
          orderTotalData: {
            sales: '0',
            sale_cnt: '0',
            shop_cost_price: '0',
            shop_supply_price: '0',
            ship_cost: '0'
          },
          resetOrderTotalData: () => {
            scope.pagination.orderTotalData = {
              sales: '0',
              sale_cnt: '0',
              shop_cost_price: '0',
              shop_supply_price: '0',
              ship_cost: '0'
            };
          },
          showOrderTotalData: attr.id.includes('shipment_grid')
        };

        // 그리드 API 객체
        let _gridApi;
        // 그리드 옵션
        const _gridOptions = scope.gridOptions = {

          // 검색된 데이터가 없는경우 출력 값
          emptyText: _options.emptyText || '데이터가 없습니다.',
          emptyTextSub: _options.emptyTextSub || '',
          emptyTemplate: _options.emptyTemplate || '',
          // 로우 높이
          rowHeight: _options.rowHeight || DEFAULT_OPTIONS.rowHeight,
          // 페이징 사이즈모음
          paginationPageSizes: _options.pagingSizes || DEFAULT_OPTIONS.pagingSizes,
          // 현재 페이징 사이즈
          paginationPageSize: _options.initPagingSize ||
            ((_options.modal && DEFAULT_OPTIONS.modalPagingSize) || DEFAULT_OPTIONS.pagingSize),
          // 페이징 사용
          enablePagination: _options.paging !== false,
          enableSelectAll: _options.enableSelectAll,
          useExternalPagination: true,
          paginationTemplate: _options.isNavigatorCenter ?
            DEFAULT_OPTIONS.paginationTemplate.replace('<div role="navigation" class="ui-grid-pager-container">', '<div role="navigation" class="ui-grid-pager-container" style="margin: 0 auto;">')
            : DEFAULT_OPTIONS.paginationTemplate,
          // 페이지 입력시마다 이동되는거 방지하기 위해 분리시킴
          paginationCurrentPage: 1,
          paginationCurrentPageViewer: 1,
          paginationCurrentPageMover: movepage => {
            _gridOptions.paginationCurrentPage = movepage;
          },
          // 정렬 사용
          enableSorting: _options.sorting !== false,
          useExternalSorting: true,
          // 다중 정렬 미사용 (default: true)
          suppressMultiSort: !(_options.multiSort || false),

          // 가상화 켜지는 최소 로우 갯수
          virtualizationThreshold: 30,
          excessColumns: 12,

          // 여러개 선택 가능
          multiSelect: _options.selectOptions && _options.selectOptions.multiSelect !== false,
          // 체크박스 노출여부
          enableRowHeaderSelection: _options.selectOptions && (_options.selectOptions.multiSelect !== false && _options.selectOptions.checkbox !== false),
          // 로우 클릭시에도 선택
          enableFullRowSelection: true,
          // 셀렉트 박스 컬럼 너비
          selectionRowHeaderWidth: 35,
          // 키로 다중선택
          // 묶음 한번에 선택 때문에 뺌
          modifierKeysToMultiSelect: _options.selectOptions && _options.selectOptions.modifierKeysToMultiSelect,
          // 컬럼 사이드 메뉴 여부
          enableColumnMenus: false,

          // 그리드 상태 저장여부
          // 없으면 기본값 true
          saveScroll: false,
          saveFocus: false,
          saveFilter: false,
          saveGrouping: false,
          saveGroupingExpandedStates: false,
          saveTreeView: false,
          saveSelection: false,

          // 로우별 템플릿
          rowTemplate: _getRowTemplate(),

          //페이지 노출 여부
          enablePaginationControls: _options.enablePaginationControls,

          addColGrid: _options.addColGrid || false,
          // 그리드 그리는 페이지
          page: _options.page || '',
          // 스크롤 이벤트 발동 조절
          customScroller: function myScrolling(uiGridViewport, scrollHandler) {
            uiGridViewport.on('scroll', function myScrollingOverride(event) {
              if (_options.simplifyScroll) {
                throttleScrollHandler(event, scrollHandler);
              } else {
                scrollHandler(event);
              }
            });
          },
          enableExpandable: _options.enableExpandable || false,
          enableExpandableRowHeader: false, // expand 버튼 없앰
          enableOnDblClickExpand: false, // 더블 클릭 시 행 펼침
          // bind-html-compile로 연결 시 ng-click이 정상적으로 동작하지 않아 toggleExpand 함수 내 태그 추가 후 compile함
          expandableRowTemplate: '<div id="expandedRowArea_{{row.index}}" style="position: absolute; left: 100px"></div>',
          expandableRowHeight: 400, // row 펼쳤을 때 높이 (유동적으로 변하고 싶을때 row.expandedRowHeight로 변경)
          rowAddTemplate: _options.rowAddTemplate || '', // row 뒤 추가 템플릿
        };

        // 스크롤이벤트
        const debounceScrollHandler = _.debounce(() => {
          scope.isScrolling = false;

          if (!scope.$$phase && !scope.$root.$$phase) {
            scope.$apply();
          }
        }, 200);

        const throttleScrollHandler = _.throttle((event, scrollHandler) => {
          scope.isScrolling = true;

          scrollHandler(event);
          debounceScrollHandler();
        }, 150);

        // 초기화용 그리드 초기 상태
        let _gridInitState;

        // 리퀘스트용 기본 내부 처리 데이터
        const _requestOptions = {
          start: 0,
          length: _gridOptions.paginationPageSize,
          orderby: ''
        };

        // 요청 파라미터 변경시에 스크롤 초기화 하기 위함
        let _requestFullOptions;
        let _prevRequestFullOptions;

        // 요청 함수에서가 아닌 별도 이벤트로 데이터 요청 가능여부
        // 초기세팅때 저장된 상태 리스토어시 정렬, 페이지 이벤트가 발생해서 막기위함
        // 페이지 초기화 데이터 요청시 페이지 변경하면 페이징 콜백이 발생해서 두번 요청하는거 방지
        let _sideEffectAllow = false;
        // 초기화시 컬럼 너비 조절 방지
        let _resetColumnFitAllow = false;

        // 로딩 처리 백그라운드 노출 여부
        scope.gridDataLoading = true;

        let prevUrl = ''; // 중복 호출 시 이전 호출 url
        let canceler = $q.defer(); // 비동기 작업의 결과를 추적할 때 사용

        // 데이터 요청
        async function _externalRequest(search) {
          // 로딩 배경 보임
          // rowsRenderedCallback에서 false처리함
          scope.gridDataLoading = true;
          _sideEffectAllow = false;

          // [주문입출고] '페이지 이동' & 'n건씩 보기'시 카운트 조회 안함
          if (_options.externalRequestOptions?.requestUrl?.includes('/stock/inout')) { _requestOptions.is_search = !!search; }

          // 요청 전 실행 함수
          // 요청시마다 내부 요청 옵션을 변경시키지 못하게 하기위해 그 순간의 옵션을 복사 생성
          // 파라미터 변경 체크용으로 전체 옵션 할당
          _requestFullOptions = _options.externalRequestOptions.requestWillAction(Object.assign({}, _requestOptions));

          // 요청 함수
          let result = [];

          if (_options.externalRequestOptions.isDirectInsert) {
            result = {
              data: {
                results: _options.externalRequestOptions.directData || [],
                recordsTotal: _options.externalRequestOptions.directData?.length || 0
              }
            };

            requestDidAction(result);
          } else {
            if (prevUrl === _options.externalRequestOptions.requestUrl) {
              canceler.resolve('canceled');
              // 새로운 요청을 위해 canceler 재설정
              canceler = $q.defer();
            }

            const clientToken = tokenSVC.getClientToken();
            const token = localStorageService.get('token');

            let isTokenError = false;

            // 현재 클라이언트와 토큰정보가 다를 시 로그아웃 처리
            if (clientToken !== token) {
              isTokenError = true;
              _requestFullOptions = { localStorageToken: token, clientToken, url: _options.externalRequestOptions.requestUrl, method: 'POST' };
              _options.externalRequestOptions.requestUrl = `${ _options.externalRequestOptions.requestUrl.split('/app/')[0]}/app/common/send-token-error`;
            }

            prevUrl = _options.externalRequestOptions.requestUrl; // 이전 URL 저장하여 동일 URL 호출 시 이전 요청 취소 처리

            if (isTokenError) {
              commonSVC.showMessage('알림', '로그인 정보가 갱신되어 새로고침 됩니다.', () => { location.reload(); });

              return Promise.reject();
            }

            $http.defaults.headers.common.Authorization = token;

            $http({
              method: _options.externalRequestOptions.requestMethod || 'POST',
              url: _options.externalRequestOptions.requestUrl,
              contentType: 'application/json',
              params: _options.externalRequestOptions.requestMethod === 'GET' ? (typeof _requestFullOptions === 'string' ? JSON.parse(_requestFullOptions) : _requestFullOptions) : undefined,
              data: _requestFullOptions,
              timeout: canceler.promise
            }).then(function(res) {
              requestDidAction(res);
            })
              .catch (function(err) {
                _sideEffectAllow = true;

                // 요청이 취소된 경우가 아닐 때
                if (err.xhrStatus !== 'abort') {
                  throw err;
                }
              });
          }

        }

        function requestDidAction (result) {
          try {
            _sideEffectAllow = true;

            // 요청 후 실행 함수
            _options.externalRequestOptions.requestDidAction(result.data);

            const additionalData = {
              bundle: {},
              family: {},
              group: {}
            };
            const resultKey = Object.keys(result.data).includes('results') ? 'results' : 'result';
            // 로우별 컬럼별 템플릿 생성해서 할당시켜놓음
            result.data[resultKey].forEach((row, index) => {
              row._uiGridData = {
                uiGridColTemplates: {}
              };

              // row ng-class 옵션 있을시 세팅
              row._uiGridData.uiGridNgClass = _options.rowNgClass || ''; // 체크박스 포함 전체 로우 적용

              // 특정 조건으로 row 전체에 툴팁을 적용할 경우 rowSpanToolTipFunc함수를 사용
              if (_options?.rowSpanToolTipFunc) {
                row._uiGridData.rowSpanToolTip = _options?.rowSpanToolTipFunc(row) || null; // 체크박스 포함 전체 로우 적용

              }

              _options.columns.forEach(col => {
                if (col.template) {
                  let value = col.template(row);

                  // lg 전자 추가항목 부분 보여지는 값만 수정
                  if (col.key === 'pa_addcol_희망배송 지연 사유') {
                    value = value && delaySVC.hopeDateDelayReason.find(r => r.value === value)?.label;
                  }
                  // 0이 아닌 다른 false값 들은 공백으로 보여지게 처리 2020-04-02 Alvin
                  row._uiGridData.uiGridColTemplates[col.key] = col.notCompile ? $sce.trustAsHtml(String(value !== 0 && !value ? '' : value)) : value;
                }
              });

              // 로우 묶음 옵션 있을시 묶음데이터 세팅
              if (_options.bundleOptions && _options.bundleOptions.bundleDataKey) {
                _setBundleOptionsData(row, index, additionalData.bundle, result.data);
              }
              // 패밀리 옵션 있을시 부모데이터 세팅
              if (_options.familyOptions && _options.familyOptions.parentDataKey) {
                _setFamilyOptionsData(row, index, additionalData.family, result.data);
              }

              // 그룹 옵션 있을시 그룹데이터 세팅
              if (_options.groupOptions && _options.groupOptions.groupDataKey) {
                _setGroupOptionsData(row, index, additionalData.group, result.data);
              }
            });

            // 데이터 추가, 변형 완료후 각 로우에 인덱스 할당
            result.data[resultKey].forEach((row, index) => {
              // $timeout(() => {
              row._uiGridData.uiGridRowIndex = index;
              row.uid = index + 1;
              // });
            });

            if (!scope.$$phase && !scope.$root?.$$phase) {
              scope.$apply();
            }

            // 선택 카운트 초기화
            $timeout(() => {
              _setSelectedCount();
              if (scope.pagination.showOrderTotalData) {
                scope.pagination.resetOrderTotalData();
              }
            });

            // 데이터 할당
            _gridOptions.totalItems = result.data.recordsTotal;
            // _gridOptions.data가 contextMenu 클리시에 계속 없는 경우가 있어서 따로 저장시킴
            _gridOptions.data = result.data[resultKey];

            // 가져온 데이터가 연속으로 없을시 rowsRendered가 실행안돼서 여기서 따로 처리
            if (!_gridOptions.data.length && !result.data.length) {
              scope.gridDataLoading = false;
            }

            scope.pagination.infoList = _getPaginationInfoList();
            scope.pagination.totalDivideInfoList = _getTotalDividePaginationInfoList();
            scope.pagination.panelStyle.justifyContent = _settingPaginationAlign();
            scope.pagination.alignStyle.justifyContent = scope.pagination.infoList.length === 1 ? 'center' : 'flext-start';

            return result.data[resultKey];
          } catch (err) {
            commonSVC.showToaster('error', '실패', '데이터 조회에 실패하였습니다.');
          }
        }

        // 요청 옵션 변경사항 체크
        function _differenceFullRequestOptionsCheck() {
          if (typeof _prevRequestFullOptions !== 'object' || typeof _requestFullOptions !== 'object') {
            return false;
          }

          const prevOptionsKeys = Object.keys(_prevRequestFullOptions);
          const optionsKeys = Object.keys(_requestFullOptions);

          // 파라미터 수가 변경됐다면 데이터 변경으로 체크
          if (prevOptionsKeys.length !== optionsKeys.length) {
            return [];
          }

          const changedOptionKeys = prevOptionsKeys.reduce((changedOptionKeys, optionKey) => {
            if (String(_prevRequestFullOptions[optionKey]) !== String(_requestFullOptions[optionKey])) {
              // 변경데이터 구분위해서 키를 반환
              changedOptionKeys.push(optionKey);
            }

            return changedOptionKeys;
          }, []);

          // 같은 파라미터 키중에 데이터가 다르면 데이터 변경
          if (changedOptionKeys.length) {
            return changedOptionKeys;
          }

          // 전부 통과했다면 변경 없음
          return false;
        }

        // 로우 묶음용 데이터 세팅
        function _setBundleOptionsData(row, index, additionalData) {
          const { prevRowClassData, rowClass } = additionalData;

          // 선택 옵션에 묶음데이터 키 세팅
          const { bundleDataKey } = _options.bundleOptions;

          // 묶음 데이터가 이전 데이터와 다르면 클래스 변경
          if (prevRowClassData !== row[bundleDataKey]) {
            additionalData.rowClass = (rowClass === undefined || rowClass) ? '' : 'ui-grid-bundle-even-row';
          }

          // 이전 데이터에 재할당
          additionalData.prevRowClassData = row[bundleDataKey];

          row._uiGridData.uiGridClass = additionalData.rowClass;
        }

        // 로우 패밀리용 데이터 세팅
        function _setFamilyOptionsData(row) {
          // 선택 옵션에 부모데이터 키 세팅
          const { parentDataKey, parentDataValue } = _options.familyOptions;

          // 부모 데이터 조건과 맞으면
          if (row[parentDataKey] === parentDataValue) {
            // 부모 로우용 클래스 부여
            row._uiGridData.uiGridClass = 'ui-grid-family-parent-row';
            // 부모 로우용 템플릿 할당
            row._uiGridData.uiGridParentRowTemplate = _options.familyOptions.parentRowTemplate(row);
          }
        }

        // 로우 그룹용 데이터 세팅
        function _setGroupOptionsData(row, index, additionalData, result) {
          const { groupDataKey, groupExceptionKey } = _options.groupOptions;

          // 새로운 그룹이거나 미그룹일시
          if (additionalData.groupNo !== row[groupDataKey]) {
            const groupRow = angular.copy(row);

            // 그룹로우 판단용 데이터
            groupRow[groupExceptionKey] = true;

            // 그룹 로우용 클래스 부여
            groupRow._uiGridData.uiGridClass = 'ui-grid-group-header-row';
            // 그룹 로우용 템플릿 할당
            groupRow._uiGridData.uiGridGroupRowHeaderTemplate = _options.groupOptions.groupRowHeaderTemplate(groupRow, index);
            groupRow._uiGridData.uiGridGroupRowTemplate = _options.groupOptions.groupRowTemplate(groupRow, index);

            additionalData.groupRows = (additionalData.groupRows || []).concat(groupRow);

            additionalData.groupNo = row[groupDataKey];
          }

          additionalData.groupRows.push(row);

          // 마지막에 실제 데이터에 그룹로우 추가시킴
          if ((index + 1) === result.results.length) {
            result.results = additionalData.groupRows;
          }
        }

        function _getPaginationInfoList() {
          const result = [];

          const { paginationCurrentPageViewer, totalItems, paginationPageSize } = _gridOptions;
          // paginationCurrentPageViewer : 현재 페이지
          // totalItems : 총 row 개수
          // paginationPageSize : n건씩 보기 값
          // totalPages : 페이지네이션에 노출되는 페이지 개수
          const totalPages = (totalItems === 0) ? 1 : Math.ceil(totalItems / paginationPageSize);

          if (totalPages <= 5) {
            // 총 페이지 수가 5 이하인 경우 모든 페이지를 표시함
            for (let page = 1; page <= totalPages; page++) {
              result.push({ current: page === paginationCurrentPageViewer, page });
            }
          } else if (paginationCurrentPageViewer <= 3) {
            // 현재 페이지가 3 이하인 경우, 1부터 5까지 표시함
            for (let page = 1; page <= 5; page++) {
              result.push({ current: page === paginationCurrentPageViewer, page });
            }
          } else if (paginationCurrentPageViewer >= totalPages - 2) {
            // 현재 페이지가 끝부분에 가까운 경우, 마지막 5개 페이지 표시함
            for (let page = totalPages - 4; page <= totalPages; page++) {
              result.push({ current: page === paginationCurrentPageViewer, page });
            }
          } else {
            // 그 외의 경우에는 현재 페이지를 중심으로 양옆 2개씩 표시함
            for (let page = paginationCurrentPageViewer - 2; page <= paginationCurrentPageViewer + 2; page++) {
              result.push({ current: page === paginationCurrentPageViewer, page });
            }
          }

          return result;
        }

        function _getTotalDividePaginationInfoList () {
          const totalDivideList = [];
          const result = [];

          const { totalItems, paginationPageSize } = _gridOptions;
          const totalPages = (totalItems === 0) ? 1 : Math.ceil(totalItems / paginationPageSize);

          for (let page = 10; page <= totalPages; page += 10) {
            totalDivideList.push(page);
          }

          for (let idx = 0; idx < totalDivideList.length; idx += 10) {
            result.push(totalDivideList.slice(idx, idx + 10));
          }

          return result;
        }

        function _settingPaginationAlign () {
          const sidePanelShowForm = {
            left: true,
            right: true,
          };

          const { totalItems, paginationPageSize, paginationCurrentPageViewer } = _gridOptions;
          const totalPages = (totalItems === 0) ? 1 : Math.ceil(totalItems / paginationPageSize);

          sidePanelShowForm.left = paginationCurrentPageViewer > 5 && totalPages > 10;
          sidePanelShowForm.right = paginationCurrentPageViewer < (totalPages - 4) && totalPages > 10;

          if (sidePanelShowForm.left && !sidePanelShowForm.right) {
            return 'flex-start';
          } else if (!sidePanelShowForm.left && sidePanelShowForm.right) {
            return 'flex-end';
          }

          return 'center';
        }

        // 로우 템플릿 생성
        function _getRowTemplate() {
          const rowTemplateHeader = '<div row-index="{{row.entity._uiGridData.uiGridRowIndex}}" class="ui-grid-row-inner {{row.entity._uiGridData.uiGridClass}}" ng-class="{{row.entity._uiGridData.uiGridNgClass}}" uib-tooltip="{{row.entity._uiGridData.rowSpanToolTip || \'\'}}">';
          const rowTemplateFooter = '</div>';

          let rowTemplateBody = DEFAULT_OPTIONS.rowTemplate;

          // 패밀리 옵션 존재시 부모 로우 템플릿 설정
          // if (_options.familyOptions && _options.familyOptions.parentRowTemplate) {
          //   rowTemplateBody = `
          //     <div ng-if="colContainer.name === 'left' || !row.entity._uiGridData.uiGridParentRowTemplate">
          //       <ui-grid-cell
          //         ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
          //         ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
          //         class="ui-grid-cell"
          //         ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader }"
          //       ></ui-grid-cell>
          //     </div>
          //     <div ng-if="colContainer.name !== 'left' && row.entity._uiGridData.uiGridParentRowTemplate" no-context="true" class="ui-grid-cell">
          //       <div ng-if="!grid.appScope.isScrolling" class="ui-grid-cell-contents" compile="row.entity._uiGridData.uiGridParentRowTemplate"></div>
          //     </div>
          //   `;
          // }

          // 그룹 옵션 존재시 그룹 로우 템플릿 설정
          if (_options.groupOptions && _options.groupOptions.groupRowHeaderTemplate) {
            rowTemplateBody = `
              <div
                ng-if="!row.entity._uiGridData.uiGridGroupRowHeaderTemplate"
                ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
                ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
                class="ui-grid-cell"
                ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader }"
                ui-grid-cell
              ></div>

              <div ng-if="row.entity._uiGridData.uiGridGroupRowHeaderTemplate" no-context="true" class="ui-grid-cell">
                <div class="ui-grid-cell-contents" ng-if="colContainer.name === 'left'" compile="row.entity._uiGridData.uiGridGroupRowHeaderTemplate"></div>
                <div class="ui-grid-cell-contents" ng-if="colContainer.name !== 'left'" compile="row.entity._uiGridData.uiGridGroupRowTemplate"></div>
              </div>
            `;
          }

          let rowAddTemplate;

          if (_options.rowAddTemplate) {
            rowAddTemplate = `
              <div bind-html-compile="grid.options.rowAddTemplate"></div>
            `;
          }

          return `
            ${rowTemplateHeader}
            ${rowTemplateBody}
            ${rowAddTemplate || ''}
            ${rowTemplateFooter}
          `;
        }

        // 전체 너비 맞추는 너비 세팅
        function _setColumnAutoFitWidth() {
          // 초기화로 들어올시 진행안함
          if (_resetColumnFitAllow) {
            return;
          }

          // 핀 제외 바디 컬럼
          const fullGridWidth = elem[0].parentElement.clientWidth;

          // 리사이징 가능한 컬럼 필터링
          const posResizeColumns = _gridOptions.columnDefs.filter(col => col.visible && !col.pinnedLeft);

          // 고정컬럼 너비 계산
          const totalPinnedColWidth = _gridOptions.columnDefs
            .filter(col => col.pinnedLeft)
            .map(col => col.width || DEFAULT_OPTIONS.colWidth)
            .reduce((totalWidth, colWidth) => { return totalWidth + colWidth; }, 0);

          // 고정컬럼, 체크박스 제외 컬럼 총 너비 계산
          const totalColWidth = posResizeColumns
            .map(col => col.width || DEFAULT_OPTIONS.colWidth)
            .reduce((totalWidth, colWidth) => { return totalWidth + colWidth; }, ((_options.selectOptions && _options.selectOptions.checkbox === false) ? totalPinnedColWidth : (CHECKBOX_WIDTH + totalPinnedColWidth)));

          // 모자란 너비 컬럼별로 나눠서 할당
          const shortWidth = fullGridWidth - totalColWidth;
          const columnAutoFitAddWidth = shortWidth <= 0 ? 0 : (Math.ceil(shortWidth / posResizeColumns.length));

          // 스테이트에 재할당할 컬럼별 너비
          const stateColWidths = {};

          // 컬럼 너비 자동세팅
          _gridOptions.columnDefs.forEach(col => {
            if (col.visible && !col.pinnedLeft) {
              col.width = col.width + columnAutoFitAddWidth;

              stateColWidths[col.name] = col.width;
            }
          });

          const state = _gridApi.saveState.save();

          // 스테이트에 재할당
          state.columns.forEach((stateCol) => {
            stateCol.width = stateColWidths[stateCol.name] || stateCol.width;
          });

          // 리스토어링
          // 뭐가 잘못된지는 모르겠지만 초기화후 defs수정후 noti를 날려도 정상적으로 작동하지 않아서
          // 스테이트에 할당해서 리스토어 하는처리로 변경함
          _gridApi.saveState.restore(scope, state);
          _gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        }

        // 테이블 헤더 타이틀 변경
        function _changeTitle(titleList) {
          // 파라미터 배열과 칼럼 수가 일치할 경우에만 변경
          if (titleList.length !== _options.columns.length) {
            return false;
          }

          _options.columns = _options.columns.map((col, idx) => {
            col.title = titleList[idx];

            return col;
          });

          _init();
        }

        // ui.grid용 컬럼 데이터 생성
        function _getUiGridColData(colData) {

          const page = _gridSearchObjScope.searchForm?.page || _gridSearchObjScope.searchForm?.pageFrom;

          // 기본 데이터
          const gridColData = {
            name: colData.key,
            displayName: colData.title,
            width: (colData.width || DEFAULT_OPTIONS.colWidth),
            visible: !(colData.key.includes('pa_addcol_') && page === 'product'),
          };

          // 컬럼별 최소 너비가 정의되어 있을 경우 적용
          if (colData.minWidth) {
            gridColData.minWidth = colData.minWidth;
          }

          // 컬럼별 최대 너비가 정의되어 있을 경우 적용
          if (colData.maxWidth) {
            gridColData.maxWidth = colData.maxWidth;
          }

          // 클래스 적용
          const colClasses = colData.class || [];

          if (_options.alignRightColumns.indexOf(colData.key) !== -1) {
            colClasses.push(ALIGN_RIGHT_CLASS);
          }

          if (_options.alignCenterColumns.indexOf(colData.key) !== -1) {
            colClasses.push(ALIGN_CENTER_CLASS);
          }
          // 헤더는 무조건 중앙 정렬
          gridColData.headerCellClass = ALIGN_CENTER_CLASS;
          // 바디는 설정따라 정렬
          gridColData.cellClass = colClasses.join(' ');

          // 고정 필드 설정
          if (_options.pinningColumns.indexOf(colData.key) !== -1) {
            gridColData.pinnedLeft = true;
          }

          // 기본 정렬필드 설정
          if (_options.defaultSortingColumns.indexOf(colData.key) !== -1) {
            if (_options.notSortingColumns === 'ALL') {
              // 정렬 변경이 가능한 컬럼이 없는경우 정렬아이콘 표시 안되게 함
              gridColData.defaultSort = { direction: uiGridConstants.DESC };
            } else {
              // defaultSort로 하면 암시적으로 표시되지않고 정렬되어서 sort로 함
              gridColData.sort = { direction: uiGridConstants.DESC };
            }
          }

          // 정렬 불가필드 설정
          if (!isColumnSortable(colData.key)) {
            gridColData.enableSorting = false;
          }
          // 기본값에 정렬 없는 경우가 있어서 정렬 무조건 되도록 해서 재할당
          else {
            gridColData.sortDirectionCycle = [uiGridConstants.DESC, uiGridConstants.ASC];
          }

          // 컬럼 리사이징 불가필드 설정
          if (_options.notResizingColumns === 'ALL' || _options.notResizingColumns.indexOf(colData.key) !== -1) {
            gridColData.enableColumnResizing = false;
          }

          // 컬럼 무빙 불가필드 설정
          if (_options.notMovingColumns === 'ALL' || _options.notMovingColumns.indexOf(colData.key) !== -1) {
            gridColData.enableColumnMoving = false;
          }

          // 기본 노출 여부
          if (_options.notVisibleColumns === 'ALL' || _options.notVisibleColumns.indexOf(colData.key) !== -1) {
            gridColData.visible = false;
          }

          // 필터 적용
          if (colData.filter) {
            gridColData.cellFilter = colData.filter;
          }

          // template 속성이 있는 경우에만 cellTemplate 추가
          // 템플릿 적용
          if (colData.template) {
            gridColData.cellTemplate = `
          <div class="ui-grid-cell-contents ${colData.class?.length ? colData.class.join(' ') : ''}" ng-class="${colData.ngClass}">
            <div ng-if="grid.appScope.isScrolling && ${!colData.important}">-</div>
            <div class="string-overflow ${colData.class?.length ? colData.class.join(' ') : ''}" ng-class="${colData.ngClass}" ng-if="!grid.appScope.isScrolling || ${colData.important}" ${colData.notCompile ? 'ng-bind-html' : 'compile'}="${!!colData.template} ? row.entity._uiGridData.uiGridColTemplates['${colData.key}'] : row.entity['${colData.key}']"></div>
          </div>
        `;
          }

          // 노출항목설정 리스트 미노출 여부
          if (colData.hideColVis) {
            gridColData.display = false;
          }

          // 헤더 툴팁 적용
          let tooltip = '';

          if (colData.mouseOverTooltip) {
            gridColData.cellTemplate = `
            <div class="ui-grid-cell-contents">
              <span style="overflow: visible !important;" uib-tooltip= "{{ row.entity.depot_name }}" tooltip-placement="top">{{ row.entity.to_m }}</span>
            </div>
            `;
          }

          if (colData.tooltip) {
            let tooltipOptions = {
              text: colData.tooltip,
              placement: colData.placement || _options.placement || 'top-right',
              forView: true,
              appendToBody: true,
              tooltipStyle: colData.tooltipStyle
            };

            // 옵션으로 적용시
            if (typeof colData.tooltip === 'object') {
              tooltipOptions = Object.assign(tooltipOptions, colData.tooltip);
            }

            tooltip = `<i class="icon-help fl-r" ${tooltipOptions.tooltipStyle ? `style=${tooltipOptions.tooltipStyle}` : ''} id="${`${colData.key}_icon`}" uib-tooltip="${tooltipOptions.text}" tooltip-placement="${tooltipOptions.placement}" tooltip-append-to-body="${tooltipOptions.appendToBody}" ng-if="${tooltipOptions.forView}"></i>`;

          }

          // 헤더 템플릿
          // highlight 플래그가 truthy일 경우 배경색 변경
          gridColData.headerCellTemplate = `<div ${colData.highlight && 'style="background-color:#329488"; border: 2px solid #156158; padding: 2px;'} role="columnheader" ng-class="{ 'sortable': sortable, 'ui-grid-header-cell-last-col': isLastCol }" ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'" aria-sort="none" class="sortable"><div role="button" tabindex="0" ng-keydown="handleKeyDown($event)" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex"><span class="ui-grid-header-cell-label" ng-class="{ 'ast': ${colData.required}}" ui-grid-one-bind-id-grid="col.uid + '-header-text'">{{col.displayName}}${tooltip}</span> <span ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'" ui-grid-visible="col.sort.direction" aria-label="Sort None" class="ui-grid-invisible"><i ng-class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }" title="" aria-hidden="true" class="ui-grid-icon-blank"></i>  <sub ui-grid-visible="isSortPriorityVisible()" class="ui-grid-sort-priority-number ui-grid-invisible">{{col.sort.priority + 1}}</sub>   </span></div><div ui-grid-filter="" ng-if="col.filterContainer === 'headerCell'"></div></div><div ui-grid-column-resizer="" ng-if="grid.options.enableColumnResizing" class="ui-grid-column-resizer right" col="col" position="right" render-index="renderIndex" unselectable="on"></div>`;
          gridColData.headerTooltip = colData.tooltip;

          return gridColData;
        }

        /* 비공개 함수 */
        function isColumnSortable(columnName) {
          return _options.notSortingColumns !== 'ALL' && !_options.notSortingColumns.includes(columnName);
        }

        /* 그리드 바인딩 이벤트 콜백 */
        // 정렬 변경 콜백
        async function _sortChangedCallback(grid, sortColumns) {
          // 정렬 기준 뽑아서 합침
          if (sortColumns.length > 1) {
            _requestOptions.orderby = sortColumns
              .map(colData => { return { key: colData.name, sort: colData.sort.direction }; });

          } else if (sortColumns.length) {
            _requestOptions.orderby = sortColumns
              .map(colData => `${colData.name} ${colData.sort.direction}`)
              .join(',');
          }

          // 초기세팅 완료 전일시 불가
          if (!_sideEffectAllow) {
            return;
          }

          // 그리드 상태 저장
          _stateSave();

          // 1페이지거나 페이지네이션 사용안할경우 페이지 이동 시켜도 이벤트 발동안돼서 첫페이지 일시 따로 요청
          if (_gridApi.pagination.getPage() === 1 || !_gridOptions.enablePagination) {
            await _externalRequest();
          } else {
            // 1페이지로 이동
            _gridApi.pagination.seek(1);
          }
        }

        // 페이지 변경 콜백
        async function _paginationChangedCallback(newPage, pageSize) {
          _requestOptions.start = (newPage - 1) * pageSize;
          _requestOptions.length = pageSize;

          // 노출용 페이지 카운트 변경된 페이지로 재할당
          _gridOptions.paginationCurrentPageViewer = newPage;

          // 초기세팅 완료 전일시 불가
          if (!_sideEffectAllow) {
            return;
          }

          // 그리드 상태 저장
          _stateSave();

          await _externalRequest();
        }

        // 컬럼 노출 수정 콜백
        function _columnVisibilityChangedCallback() {
          // 초기세팅 완료 전일시 불가
          if (!_sideEffectAllow) {
            return;
          }

          // 컬럼들의 총합이 전체 너비보다 모자랄시 전체 너비로 자동세팅
          _setColumnAutoFitWidth();

          // 그리드 상태 저장
          _stateSave();
        }

        // 컬럼 이동 콜백
        function _columnPositionChangedCallback() {
          // 초기세팅 완료 전일시 불가
          if (!_sideEffectAllow) {
            return;
          }

          // 그리드 상태 저장
          _stateSave();
        }

        // 컬럼 리사이징 콜백
        function _columnSizeChangedCallback() {
          // 초기세팅 완료 전일시 불가
          if (!_sideEffectAllow) {
            return;
          }

          // 그리드 상태 저장
          _stateSave();
        }

        // 로우 선택시 콜백
        function _rowSelectionChangedCallback(row, event) {
          // 로우 클릭 불가 옵션 존재시 선택 해제
          if (_options.selectOptions?.notClickable) {
            _gridApi.selection.unSelectRow(row.entity);

            return;
          }

          if (_options.selectOptions?.notClickableOption) {
            const flag = _options.selectOptions?.notClickableOption(row.entity);

            if (flag) {
              _gridApi.selection.unSelectRow(row.entity);

              return;
            }
          }

          // 버튼클릭시 클릭 막음
          if (event && event.target && event.target.localName === 'button') {
            const action = !row.isSelected ? 'selectRow' : 'unSelectRow';

            return _gridApi.selection[action](row.entity);
          }

          // 묶음 옵션 존재시 묶음선택
          if (_options.bundleOptions && _options.bundleOptions.bundleDataKey) {
            _bundleDataSelection(row, event);
          }

          // 패밀리 옵션 존재시 부모 로우 선택해제
          if (_options.familyOptions && _options.familyOptions.parentDataKey) {
            _familyDataSelection(row, event);
          }

          // 그룹 옵션 존재시 그룹 로우 선택해제
          if (_options.groupOptions && _options.groupOptions.groupDataKey) {
            // 그룹상품 테이블의 모두선택/모두해제 표기를 위한 처리
            if (_options.groupOptions.groupDataKey === 'std_ol_group_no') {
              if (!row.entity.groupRowYn) {
                const idx = row.grid.rows.findIndex(r => (row.uid === r.uid));
                const groupRow = row.grid.rows.filter((r, i) => i < idx && r.entity.groupRowYn).pop();
                if (!row.isSelected) {
                  groupRow.entity.check = false;
                } else {
                  const groupIdx = groupRow.grid.rows.findIndex(r => (groupRow.uid === r.uid));
                  let nextIdx = groupRow.grid.rows.findIndex((r, i) => i > groupIdx && r.entity.groupRowYn);

                  nextIdx = nextIdx !== -1 ? nextIdx : groupRow.grid.rows.length;

                  const target = row.grid.rows.filter((r, i) => i > groupIdx && i < nextIdx);
                  groupRow.entity.check = !target.some(t => !t.isSelected); // 선택 안된것이 없으면 모두해제로 변경
                }
              }
            }
            _groupDataSelection(row, event);
          }

          _setSelectedCount();

          // 선택 row 완료 송출
          const data = {
            id: attr.id,
            selectData: row.entity,
            selectDataSelected: row.isSelected,
            selectedCount: _gridSearchObjScope.searchData.selectCount,
            selectedAll: event === 'all',
            selectedDatas: getSelectedRows('all')
          };

          if (event !== 'all') {
            scope.$emit('OnSelectedAllRow', getSelectedRows('all'));
            if (scope.pagination.showOrderTotalData) {
              scope.pagination.orderTotalData = getSelectedRows('all').filter(ord => !['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(ord.ord_status)).reduce((acc, curr) => {
                for (const key in acc) {
                  // 추가하려는 값이 문자열인 경우 변경
                  if (typeof curr[key] === 'string') {
                    curr[key] = Number(curr[key].replace(/,/g, '')) || 0;
                  }

                  acc[key] = $filter('currency')((Number(acc[key].replace(/,/g, '')) + curr[key]), '', 0);
                }

                return acc;
              }, {
                sales: '0',
                sale_cnt: '0',
                shop_supply_price: '0',
                ship_cost: '0',
                shop_cost_price: '0'
              });
            }
          }

          // 2017-04-27 MatthewKim 좀더 빠른 반응이 필요한 SelectedChange 를 위해 OnSelectChangeBefore 추가
          scope.$emit('OnSelectChangeBefore', data);
        }

        // 로우 여러개 선택시 콜백
        function _rowSelectionChangedBatchCallback(rows) {
          scope.$emit('OnSelectedAllRow', getSelectedRows('all'));
          if (scope.pagination.showOrderTotalData) {
            scope.pagination.orderTotalData = getSelectedRows('all').filter(ord => !['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(ord.ord_status)).reduce((acc, curr) => {
              for (const key in acc) {
                // 추가하려는 값이 문자열인 경우 변경
                if (typeof curr[key] === 'string') {
                  curr[key] = Number(curr[key].replace(/,/g, '')) || 0;
                }
                acc[key] = $filter('currency')((Number(acc[key].replace(/,/g, '')) + curr[key]), '', 0);
              }

              return acc;
            }, {
              sales: '0',
              sale_cnt: '0',
              shop_supply_price: '0',
              ship_cost: '0',
              shop_cost_price: '0'
            });
          }

          rows.forEach((row) => {
            _rowSelectionChangedCallback(row, 'all');
          });
        }

        // 로우 렌더링 완료시 콜백
        function _rowsRenderedCallback() {
          // 초기 세팅 전에는 막음
          if (!_sideEffectAllow) {
            return;
          }

          _gridApi.grid.rows.forEach(row => {
            // 선텍처리 할 row 선택처리
            row.isSelected = row.entity.isSelected || row.isSelected;

            delete row.entity.isSelected;
          });

          // 스크롤 초기화 여부
          // 요청 파라미터가 변경됐다면 새로운 데이터이므로 스크롤 초기화시킴
          const reqOptChkRes = _differenceFullRequestOptionsCheck();

          if (_gridOptions.data.length && reqOptChkRes) {
            // 컬럼별 스크롤 초기화 여부에 따른 사항 적용
            const scrollInitYn = !!reqOptChkRes.filter(col => NOT_SCROLL_INIT_COLS.includes(col)).length;
            const colScrollInitYn = !scrollInitYn && !!reqOptChkRes.filter(col => NOT_COL_SCROLL_INIT_COLS.includes(col)).length;
            const rowScrollInitYn = !scrollInitYn && !!reqOptChkRes.filter(col => NOT_ROW_SCROLL_INIT_COLS.includes(col)).length;

            // 스크롤 맨위, 맨왼쪽으로 옮김
            _gridApi.core.scrollTo(
              (scrollInitYn || rowScrollInitYn) ? null : _gridOptions.data[0],
              (scrollInitYn || colScrollInitYn) ? null : _gridOptions.columnDefs[0]
            );
          }

          // 조회한 파라미터 재할당
          _prevRequestFullOptions = Object.assign({}, _requestFullOptions);

          // 현재 보이지 않는 그리드를 새로고침 시키면 뷰포트가 작아져서 다시 리사이징 시킴
          _gridApi.core.handleWindowResize();

          // 로딩 배경 숨김
          // 데이터가 연속으로 없으면 여기 안들어와서 externalRequest에 따로 처리함
          scope.gridDataLoading = false;
        }

        // 선택 로우 갯수
        const _setSelectedCount = _.debounce(() => {
          const counts = {
            selectCount: 0,
            selectBundleCount: 0
          };

          const selectBundleVaild = [];

          _gridApi.grid.rows.forEach(row => {

            // 선택데이터 카운팅 증가
            if (row.isSelected) {
              // 묶음 옵션 있을시
              if (_options.bundleOptions && _options.bundleOptions.bundleDataKey) {
                // 해당 묶음의 카운팅이 안됐다면 카운팅 증가
                if (selectBundleVaild.indexOf(row.entity[_options.bundleOptions.bundleDataKey]) < 0) {
                  counts.selectBundleCount++;

                  selectBundleVaild.push(row.entity[_options.bundleOptions.bundleDataKey]);
                }
              }

              // 그룹 옵션 있을시
              if (_options.groupOptions && _options.groupOptions.groupDataKey) {
                // 해당 묶음의 카운팅이 안됐다면 카운팅 증가
                if (selectBundleVaild.indexOf(row.entity[_options.groupOptions.groupDataKey]) < 0) {
                  counts.selectBundleCount++;

                  selectBundleVaild.push(row.entity[_options.groupOptions.groupDataKey]);
                }
              }

              // 패밀리 옵션 있을시
              if (_options.familyOptions && _options.familyOptions.parentDataKey) {
                // 부모 로우 선택갯수는 증가시지 않음
                if (row.entity[_options.familyOptions.parentDataKey] === _options.familyOptions.parentDataValue) {
                  return;
                }
                // 해당 묶음의 카운팅이 안됐다면 카운팅 증가
                if (selectBundleVaild.indexOf(row.entity[_options.familyOptions.familyDataKey]) < 0) {
                  counts.selectBundleCount++;

                  selectBundleVaild.push(row.entity[_options.familyOptions.familyDataKey]);
                }
              }

              counts.selectCount++;
            }
          });

          // 카운트 적용
          _gridSearchObjScope.searchData.selectCount = counts.selectCount;

          // 묶음 카운트 적용
          if (_options.bundleOptions && _options.bundleOptions.bundleCountKey) {
            _gridSearchObjScope.searchData[_options.bundleOptions.bundleCountKey] = counts.selectBundleCount;
          }

          // 그룹상품 묶음 카운트 적용
          if (_options.groupOptions && _options.groupOptions.groupCountKey) {
            _gridSearchObjScope.searchData[_options.groupOptions.groupCountKey] = counts.selectBundleCount;
          }

          // 온라인상품 묶음 카운트 적용
          if (_options.familyOptions && _options.familyOptions.familyCountKey) {
            _gridSearchObjScope.searchData[_options.familyOptions.familyCountKey] = counts.selectBundleCount;
          }

          // 카운트 넣어도 바로 업데이트 안돼서 따로 리프레시 처리
          _gridApi.core.queueGridRefresh();
        });

        // 그리드 상태 캐시 저장
        function _stateSave(state = _gridApi.saveState.save()) {
          if (scope.nosave) {
            return;
          }
          localStorageService.set(GRID_STORAGE_ID, state);
          commonModel.setTableState({ name: GRID_ID, state });
        }

        // 그리드 스크롤 시 확장 로우 있을 경우 스크롤바에 맞춰서 위치 조정 (가운데)
        function _syncExpandableWithScroll () {
          const expandableRows = document.querySelectorAll('[id^="expandedRowArea_"]'); // 모든 expandableRow 가져오기

          if (expandableRows.length === 0) { return; }

          expandableRows.forEach(row => {

            const gridContainer = row.closest('.ui-grid-render-container-body .ui-grid-viewport'); // UI Grid의 스크롤 영역

            row.style.transform = `translateX(${gridContainer.scrollLeft}px)`;
          });
        }

        /* /그리드 바인딩 이벤트 콜백 */

        // 묶음데이터 선택처리
        function _bundleDataSelection(row) {
          const bundleDataKey = _options.bundleOptions.bundleDataKey;
          const bundleUniqKey = _options.bundleOptions.bundleUniqKey;

          const self = row;
          const action = row.isSelected ? 'selectRow' : 'unSelectRow';
          const bundleData = row.entity[bundleDataKey];
          const bundleUniqData = Array.isArray(bundleUniqKey) ? bundleUniqKey.map(key => row.entity[key]).join('|') : row.entity[bundleUniqKey];

          // 같은 데이터 묶음의의 로우 동시 선택처리
          _gridApi.grid.rows.forEach(row => {
            const data = row.entity,
                  uniqData = Array.isArray(bundleUniqKey) ? bundleUniqKey.map(key => data[key]).join('|') : data[bundleUniqKey];

            if (data[bundleDataKey] === bundleData && uniqData !== bundleUniqData && self.isSelected !== row.isSelected) {
              _gridApi.selection[action](data);
            }
          });
        }

        // 패밀리데이터 선택처리
        function _familyDataSelection(row, event) {
          const { familyDataKey, parentDataKey, parentDataValue } = _options.familyOptions;

          const self = row;
          const action = row.isSelected ? 'selectRow' : 'unSelectRow';
          const parentFamilyData = row.entity[familyDataKey];
          const parentData = row.entity[parentDataKey];

          // 부모 로우 선택시
          if (parentData === parentDataValue && event) {
            // 자식 로우 전체 선택
            _gridApi.grid.rows.forEach(row => {
              const data = row.entity;

              if (data[parentDataKey] !== parentDataValue && data[familyDataKey] === parentFamilyData && self.isSelected !== row.isSelected) {
                _gridApi.selection[action](data);
              }
            });
          }
        }

        // 그룹데이터 선택처리
        function _groupDataSelection(row) {
          const { groupExceptionKey } = _options.groupOptions;

          // 그룹 로우 선택시
          if (row.entity[groupExceptionKey]) {
            // 선택해제
            _gridApi.selection.unSelectRow(row.entity);
          }
        }

        // 그리드 높이값 맞춤 적용
        function _resizingHeight() {
          const modalDialog = elem.closest('.modal-dialog')[0];
          const parentInnerHeight = !_options.modal ? window.innerHeight : modalDialog.clientHeight;

          // 그리드 부모 엘리먼트로 오프셋을 구해서 그리드에 할당
          const grid = elem.querySelectorAll('.grid')[0];
          const gridWrap = elem[0].parentElement;
          const gridWrapTop = (!_options.modal ? 0 : modalDialog.querySelector('.modal-header').clientHeight) + gridWrap.getBoundingClientRect().top;
          const gridWrapBottom = !_options.modal ? 20 : modalDialog.querySelector('.modal-footer').clientHeight;
          let gridMinusSize = 0;

          if (attr.belongGridElemId) {
            const belongGridElem = document.getElementById(attr.belongGridElemId);
            const boxMarginTopSize = parseInt(document.defaultView.getComputedStyle(belongGridElem).getPropertyValue('margin-top'), 10);

            gridMinusSize = belongGridElem.offsetHeight + boxMarginTopSize;
          }

          if (!scope.gridHeightNoCalc) {
            grid.style.height = _options.gridHeight && `${_options.gridHeight}px` || `${parentInnerHeight - gridWrapTop - gridWrapBottom - gridMinusSize}px`;
          }

          if (!(_options.modal && _options.gridHeight)) {
            // 높이 값 변경 되고 실행 되야함
            $timeout(() => {
              resizingHeightWithAppHeader(grid);
            });
          }

          // 사이드 검색 박스
          const searchBarId = attr.searchbarId || `${attr.id}_searchbar`;
          const gridSearchBar = document.getElementById(searchBarId);

          if (gridSearchBar) {
            const gridSearchBox = gridSearchBar.querySelectorAll('.a-sidebar-nav-box')[0];
            const gridSearchBoxTop = gridSearchBox.getBoundingClientRect().top;
            const gridSearchBoxBottom = 9;

            gridSearchBox.style.height = `${parentInnerHeight - gridSearchBoxTop - gridSearchBoxBottom - 1}px`;

            // 높이 값 변경 되고 실행 되야함
            $timeout(() => {
              resizingHeightWithAppHeader(gridSearchBox);
            });
          }

          // 적용후 그리드 노출부분도 적용시키기 위해 이벤트 발생
          _gridApi.core.handleWindowResize();
          _gridApi.core.refresh();
        }

        // 가끔씩 초기 로딩시에 offset값이 헤더가 측정이 안돼서 재검사후 헤더 높이를 추가로 빼는 처리
        function resizingHeightWithAppHeader(target) {
          const appHeaderHeight = document.getElementById('navbarHeight').clientHeight;
          const pageContainerHeight = document.querySelectorAll('.page-container')[0].clientHeight;
          const windowHeight = window.innerHeight;

          if (pageContainerHeight >= windowHeight) {
            target.style.height = `${(target.clientHeight - appHeaderHeight)}px`;
          }
        }
        /* 비공개 함수 */

        /* 공개 함수 */
        // 새로고침
        async function reloadData(callback, resetPaging, noDelay, resizingHeight) {
          // 기본 딜레이
          let delayProm = $q(resolve => {
            $timeout(() => {
              resolve();
            }, DEFAULT_OPTIONS.refreshDelay);
          });

          // 딜레이 없음 처리
          if (noDelay) {
            delayProm = $q.resolve();
          }

          // 데이터 리로딩
          await delayProm;

          // 페이징 초기화
          if (resetPaging) {
            // 페이지 이동 콜백으로 리퀘스트 막기위함
            _sideEffectAllow = false;

            // 1페이지로 이동
            _gridApi.pagination.seek(1);
            _requestOptions.start = 0;
          }

          // 데이터 불러오는 처리
          await _externalRequest(true);

          if (scope.pagination.infoList.filter(({ page }) => page === scope.gridOptions.paginationCurrentPage).length === 0) {

            // 해당페이지에 데이터가 없으면 1페이지로 이동
            _gridApi.pagination.seek(1);
            _requestOptions.start = 0;
          }

          if (callback) {
            callback();
          }

          // 그리드 높이 재 계산
          if (resizingHeight) {
            _resizingHeight();
          }

          // 주문 합계 UI 갱신
          $timeout(() => {
            if (scope.pagination.showOrderTotalData) {
              scope.pagination.orderTotalData = getSelectedRows('all').filter(ord => !['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(ord.ord_status)).reduce((acc, curr) => {
                for (const key in acc) {
                  // 추가하려는 값이 문자열인 경우 변경
                  if (typeof curr[key] === 'string') {
                    curr[key] = Number(curr[key].replace(/,/g, '')) || 0;
                  }

                  acc[key] = $filter('currency')((Number(acc[key].replace(/,/g, '')) + curr[key]), '', 0);
                }

                return acc;
              }, {
                sales: '0',
                sale_cnt: '0',
                shop_supply_price: '0',
                ship_cost: '0'
              });
            }
          });
        }

        /**
         * 데이터테이블 강제 빈값 초기화
         */
        function setEmpty() {
          // 데이터 할당
          _gridOptions.totalItems = 0;
          _gridOptions.data = [];
          _gridOptions.paginationCurrentPageViewer = 1;

          scope.pagination.infoList = _getPaginationInfoList();
          scope.pagination.totalDivideInfoList = _getTotalDividePaginationInfoList();
          scope.pagination.panelStyle.justifyContent = _settingPaginationAlign();
          scope.pagination.alignStyle.justifyContent = scope.pagination.infoList.length === 1 ? 'center' : 'flext-start';
        }

        /**
         * 직접입력 데이터 할당
         */
        function setDirectData(data) {
          _options.externalRequestOptions.directData = data;
        }

        // 리렌더링
        function refresh() {
          _gridApi.core.queueGridRefresh();
          _gridApi.core.notifyDataChange(uiGridConstants.dataChange.EDIT);
          $timeout(() => {});
        }

        function gridInit() {
          _gridInit(false);
        }

        // 필터링한 데이터
        function getFilteredRows(filter, funcType, rows) {
          let rowDatas = rows || _gridApi.grid.rows.map(row => row.entity);

          // 패밀리 옵션일시 부모 데이터 제외시키고 반환
          if (_options.familyOptions) {
            rowDatas = rowDatas.filter(row => row[_options.familyOptions.parentDataKey] !== _options.familyOptions.parentDataValue);
          }

          // 그룹 옵션일시 그룹 데이터 제외시키고 반환
          if (_options.groupOptions) {
            rowDatas = rowDatas.filter(row => !row[_options.groupOptions.groupExceptionKey]);
          }

          funcType = funcType || 'find';

          return rowDatas[funcType](row => {
            let filteringResult = true;

            switch (typeof filter) {
              case 'function':
                filteringResult = filter(row);
                break;

              case 'object':
                for (const key in filter) {
                  if (row[key] !== filter[key]) {
                    filteringResult = false;
                  }
                }
                break;
            }

            return filteringResult;
          });
        }

        // 선택 데이터
        function getSelectedRows(column, isGeneralProdAdd = false) {
          let rows = _gridApi.selection.getSelectedRows();
          // 패밀리 옵션일시 부모 데이터 제외시키고 반환
          if (_options.familyOptions && !isGeneralProdAdd) {
            rows = getFilteredRows(row => row[_options.familyOptions.parentDataKey] !== _options.familyOptions.parentDataValue, 'filter', rows);
          }

          return rows.map(row => {
            if (column) {
              // 일부 필드
              if (Array.isArray(column)) {
                return _.pick(row, column);
              }
              // 모든 필드
              else if (column === 'all') {
                return row;
              }
              // 해당 필드 데이터
              else {
                return row[column];
              }
            }
            // 없을시 모든 필드
            else {
              return row;
            }
          });
        }

        // 선택된 데이터중 필터링한 데이터
        function getSelectFilteredRows(filter, funcType) {
          let rows = _gridApi.selection.getSelectedRows();

          // 패밀리 옵션일시 부모 데이터 제외시키고 반환
          if (_options.familyOptions) {
            rows = getFilteredRows((row) => {
              return row[_options.familyOptions.parentDataKey] !== _options.familyOptions.parentDataValue;
            }, 'filter', rows);
          }

          return getFilteredRows(filter, funcType, rows);
        }

        /**
         * row 에 대해 선택 활성화/비활성화를 변경함
         * @param {object} row 변경할 row
         * @param {boolean} checked 체크 상태
         * @return {void}
         */
        function setSelectedRow(row, checked) {
          const selectAction = (checked && 'selectRowByVisibleIndex') || 'unSelectRowByVisibleIndex';

          _gridApi.selection[selectAction](row._uiGridData.uiGridRowIndex);
        }

        /**
         * row 전체 선택
         */
        function selectAllRows() {
          _gridApi.selection.selectAllRows();
        }

        /**
         * row 전체 선택 해제
         */
        function clearSelectedRows() {
          _gridApi.selection.clearSelectedRows();
        }

        /**
         * result.results를 가져옴
         * 데이터의 원본을 가져오기 때문에 가져온 곳에서 내부 필드를 수정하면 pa-ui-grid에서도 적용 됨
         * @return {object}
         */
        function getResultDatas() {
          return _gridOptions.data;
        }

        // 필터링 통과된 데이터 선택/해제
        function selectionConvertByFilter(filterFunc, checked) {
          const selectionDataIndexes = [];

          _gridApi.grid.rows.forEach((row, index) => {
            const rowData = row.entity;

            if (filterFunc(rowData)) {
              selectionDataIndexes.push(index);
            }
          });

          if (selectionDataIndexes.length) {
            let selectAction = 'selectRowByVisibleIndex';

            if (checked) {
              selectAction = 'unSelectRowByVisibleIndex';
            }

            _gridApi.selection[selectAction](selectionDataIndexes);
          }
        }

        // 필터링 통과된 데이터만 선택
        function selectByFilter(filterFunc, onlyInSelection, selectOne) {
          const selectionDataIndexes = [];

          for (const index in _gridApi.grid.rows) {
            const data = _gridApi.grid.rows[index].entity;

            if (filterFunc(data)) {
              // 선택된 데이터만 진행할시
              if (onlyInSelection && !_gridApi.grid.rows[index].isSelected) {
                continue;
              }

              selectionDataIndexes.push(index);

              // 단일데이터만 선택
              if (selectOne) { break; }
            }
          }

          // 나머지 선택데이터 클리어
          _gridApi.selection.clearSelectedRows();

          if (selectionDataIndexes.length) {
            selectionDataIndexes.forEach(_gridApi.selection.selectRowByVisibleIndex);
          }

          return selectionDataIndexes;
        }

        // 필터링 통과된 데이터만 해제
        function unSelectByFilter(filterFunc, onlyInSelection, selectOne) {
          const unSelectionDataIndexes = [];

          for (const index in _gridApi.grid.rows) {
            const data = _gridApi.grid.rows[index].entity;

            if (filterFunc(data)) {
              // 선택된 데이터만 진행할시
              if (onlyInSelection && (!_gridApi.grid.rows[index].isSelected)) {
                continue;
              }

              unSelectionDataIndexes.push(index);

              // 단일데이터만 선택
              if (selectOne) { break; }
            }
          }

          // 필터링 데이터 선택 해제
          if (unSelectionDataIndexes.length) {
            unSelectionDataIndexes.forEach(_gridApi.selection.unSelectRowByVisibleIndex);
          }

          return unSelectionDataIndexes;
        }

        // 컬럼 노출여부 설정
        async function setColVisibles() {
          const ctrl = 'PaUiGridColVisCtrl',
                view = 'views/common/paUiGridColVis.html',
                data = {
                  data: {
                    columns: _gridApi.grid.columns.filter(({ colDef: { display } }) => display !== false),
                    page: _gridSearchObjScope.searchForm.page || _gridSearchObjScope.searchForm.pageFrom,
                    _gridInitState,
                    _gridOptions,
                    _gridObjScope
                  }
                };

          const modal = commonSVC.openModal('xg', data, ctrl, view);
          const modalResult = await modal.result;

          switch (modalResult?.type) {
            // 적용
            case 'apply':
              // 같은 이름의 컬럼을 찾아서 변경시킴
              modalResult.columns.forEach(col => {
                col.colDef.visible = col.visible;
                col.colDef.width = col.width;
              });

              // 컬럼 추가 모듈 사용 시 잔여 데이터 삭제를 위해 리로딩
              if (userInfo.user.sol_ser.includes('addcol') && columnSVC.addColStatus.includes(`main.${attr.state}`)) {
                // 리스트 정보 로딩
                await reloadData();
              }

              // 변경사항 리렌더링
              await _gridApi.core.queueGridRefresh();
              _stateSave();
              break;
          }
        }

        // 그리드 설정 초기화
        async function setGridInit() {
          _resetColumnFitAllow = true;

          await _gridApi.saveState.restore(scope, _gridInitState);

          // 페이징갯수 초기화
          _gridSearchObjScope.searchData.showCount = _gridInitState.pagination.paginationPageSize;

          // 저장된 그리드 상태 제거
          localStorageService.remove(GRID_STORAGE_ID);
          localStorageService.set(GRID_STORAGE_ID, _gridApi.saveState.save());

          // 리스토어링 해도 defs의 width가 초기화가 안돼서 직접넣음
          _gridOptions.columnDefs.forEach((columnDef, index) => {
            columnDef.width = _gridInitState.columns[index].width;
          });

          _resetColumnFitAllow = false;
          commonModel.setTableState({ name: GRID_ID, state: _gridApi.saveState.save() });
        }

        // 노출 컬럼 리스트 조회
        function getVisibleColumns() {
          return _gridApi.grid.columns
            .filter(col => (
              // 체크박스와 도구컬럼 제외
              col.visible && ['selectionRowHeaderCol', 'widget'].indexOf(col.name) < 0
            ))
            .map(col => (
              // 형식 재구성
              { header: col.displayName, key: col.name }
            ));
        }

        // 컬럼 노출 설정
        async function setVisibleColumns(key, visibleYn, displayYn = true) {
          const { columns } = localStorageService.get(GRID_STORAGE_ID) || await commonModel.getTableState(GRID_ID).then(({ data: { state } }) => state);
          // 특정 탭에서만 존재하는 컬럼일 경우 로컬에 저장된 노출값 우선 적용
          if (columns && visibleYn) {
            visibleYn = !!columns.find(({ name }) => name === key).visible;
          }
          const visibleAction = visibleYn ? 'show' : 'hide';
          const col = _gridApi.grid.columns.find(({ name }) => name === key);

          col[`${visibleAction}Column`]();
          col.display = displayYn || false;

          col.colDef.display = col.colDef.display !== undefined ? displayYn : false; // 노출항목설정 리스트 노출 설정
          col.visible = visibleYn || false;
        }

        // 노출 페이징 갯수 세팅
        function setExposPageSize() {
          scope.searchData.showCount = _requestOptions.length;
        }

        // 그리드 페이징 갯수 세팅
        function setGridPageSize(size, isDraw) {
          // 사이즈 안넘어오면 기본 사이즈로 할당
          // 맨 처음에 searchData.showCount를 설정을 안해놓으면 change이벤트가 바로발동해서 방지용으로 넣음
          if (!size) {
            return;
          }

          // 그리드 옵션 페이징 사이즈 재할당
          _gridOptions.paginationPageSize = size;

          // 1페이지로 이동
          _gridApi.pagination.seek(1);
          // 페이징 초기화 시 선택된 로우 선택해제 처리
          _gridApi.selection.clearSelectedRows();

          if (isDraw) {
            refresh();
          }
        }

        async function toggleExpand(row, expandFunc) {
          _gridApi.expandable.toggleRowExpansion(row.entity);

          if (row.isExpanded) { // 로우 확장했을 떄만 실행
            if (expandFunc) {
              await expandFunc(row);
            }

            // html-bind-compile 사용했을 때 ng-click 같은 함수들이 동작하지 않는 경우가 있어 로우를 펼친 후에 html 태그를 붙이도록 함
            const element = angular.element(document.querySelectorAll(`#expandedRowArea_${row.index}`));

            const gridContainer = element[0].closest('.ui-grid-render-container-body .ui-grid-viewport'); // UI Grid의 스크롤 영역

            if (!gridContainer || element.length === 0) { return; }

            element[0].style.transform = `translateX(${gridContainer.scrollLeft}px)`;

            element.html(row.expandedRowTemplate);
            $compile(element.contents())(scope);
          }
        }

        /* 공개 함수 */

        // 라이브러리 데이터 형식 반환
        function _getContextMenuDataSet(menu) {
          let enable = true;

          // 스플릿 라인 요청일시
          if (menu.label === 'divider') {
            return null;
          }

          // 액션 활성화 필터링
          // 선택 데이터에서 넘어온 필터링 함수로 검증후 활성화 여부 할당
          if (menu.filter) {
            const selectedRows = getSelectedRows();

            selectedRows.forEach(row => {
              if (!menu.filter(row)) {
                enable = false;

                return false;
              }
            });
          }

          let htmlText = menu.label;

          // 메뉴 우클릭 툴팁 붙여주는 조건문
          if (menu.menu_tooltip) {
            htmlText = menu.label.replace('<i', `<i tooltip-value="${menu.menu_tooltip}"`);
          }

          return {
            html: `<a>${htmlText}</a>`,
            click: menu.action,
            enabled: () => enable
          };
        }

        // 우클릭 메뉴 데이터 세팅
        function _setContextMenu() {
          let menuGroups = [];
          const contextMenu = [];

          // 테이블 메뉴 병합
          if (_gridSearchObjScope.searchBtn?.table_actions?.some(btnGroup => /작업/.test(btnGroup.label))) {
            const tableActions = _gridSearchObjScope.searchBtn.table_actions
              .filter(btnGroup => {
                // 커스텀 컬럼 작업이 있을때 컬럼 추가 그리드가 아니면 false
                if (/커스텀 컬럼 작업/.test(btnGroup.label)) {
                  if (!_gridOptions?.addColGrid) {
                    return false;
                  } else {
                    return !!btnGroup.item_list?.length;
                  }
                }

                return /작업/.test(btnGroup.label);
              })
              .map((btnGroup) => {
                // 묶음 메뉴 분리
                if (btnGroup.hasOwnProperty('item_group')) {
                  btnGroup.item_list = btnGroup.item_group
                    .map((group, index) => {
                      // 컨텍스트 메뉴 비노출 아이템 거름
                      group = group.filter(item => item.contextVisible !== false);

                      return index && group.length ? [{ label: 'divider' }].concat(group) : group;
                    });
                }

                btnGroup.item_list = btnGroup.item_list.flat();
                const copyBtnGroup = angular.copy(btnGroup);

                copyBtnGroup.item_list = copyBtnGroup.item_list.flat().filter(item => {
                  return item.ngIfFunc ? item.ngIfFunc() === 'y' : true;
                });

                return copyBtnGroup;
              });

            menuGroups = menuGroups.concat(tableActions);
          }

          if (_gridSearchObjScope.searchBtn?.table_actions_btn) {
            const tableActionsBtn = _gridSearchObjScope.searchBtn.table_actions_btn
              .filter(btnGroup => /작업/.test(btnGroup.label))
              .map((btnGroup) => {
                // 묶음 메뉴 분리
                if (btnGroup.hasOwnProperty('item_group')) {
                  btnGroup.item_list = btnGroup.item_group
                    .map((group, index) => {
                      // 컨텍스트 메뉴 비노출 아이템 거름
                      group = group.filter(item => item.contextVisible !== false);

                      return index && group.length ? [{ label: 'divider' }].concat(group) : group;
                    });
                }

                btnGroup.item_list = btnGroup.item_list.flat();

                return btnGroup;
              });

            menuGroups = menuGroups.concat(tableActionsBtn);
          }

          // 커스텀 메뉴 병합
          if (scope.customContext) {
            menuGroups = menuGroups.concat(scope.customContext);
          }

          $(function() {
            let title_;

            $('.menu-tooltip').hover(function(e) {      // <a> hover 시 : mouseEnter

              title_ = $(this).attr('tooltip-value');     // tooltip-value 변수에 저장
              $(this).attr('tooltip-value', '');           // tooltip-value 속성 삭제( 기본 툴팁 기능 방지 )

              if (title_) {
                $('body').append("<div id='tip'></div>");   // body 내부에 div#tip 생성

                $('#tip').css('width', 'auto');
                $('#tip').css('height', 'auto');
                $('#tip').text(title_);

                // 툴팁이 현재 hover 한 요소의 상단에 생성되도록 설정
                const pageX = $(this).offset().left + 30;
                const pageY = $(this).offset().top - 10;

                $('#tip').css({ left: pageX + 'px', top: pageY + 'px' }).fadeIn(1);
                $('#tip').css('z-index', '10000');
              }
            }, function() {                         // <a> hover 시 : mouseLeave
              $(this).attr('tooltip-value', title_);      // tooltip-value 속성 반환
              $('#tip').remove();                 // div#tip 삭제
            });

            $('.menu-tooltip').mousedown(function () {
              $(this).attr('tooltip-value', title_);      // tooltip-value 속성 반환
              $('#tip').remove();                 // div#tip 삭제
            });
          });

          menuGroups.forEach((menuGroup, index) => {
            // 액션 리스트가 없다면 추가 안함
            if (!menuGroup.hasOwnProperty('item_list') && !menuGroup.hasOwnProperty('contextList')) {
              return;
            }

            const menuList = menuGroup.item_list || menuGroup.contextList;

            if (!menuList.length) {
              return;
            }
            if (menuGroup.isShow && !menuGroup.isShow()) {
              return;
            }

            // 묶음별로 스플릿 라인 생성
            if (index && menuGroup.line) {
              contextMenu.push(null);
            }

            // 2뎁스 메뉴일시 미리 메뉴를 하나 넣고 그 메뉴에 넣는 방식
            if (menuGroup.isSubMenu) {
              contextMenu.push([menuGroup.subMemuName, []]);
            }

            menuList.forEach(menu => {
              // 2뎁스 메뉴일시 미리 넣은 메뉴를 찾아서 두번째 배열에 넣음
              (
                menuGroup.isSubMenu
                  ? contextMenu[(contextMenu.length - 1)][1]
                  : contextMenu
              )
                .push(
                  _getContextMenuDataSet(menu)
                );
            });
          });

          scope.contextMenu = contextMenu;
        }

        // 우클릭 메뉴 초기화
        function _contextMenuInit() {
          scope.contextMenu = [];

          // 데이터 테이블에 버튼 엑션이 없을경우 우클릭 처리 안함
          if (!_gridSearchObjScope.searchBtn?.table_actions?.some(btnGroup => /작업|일괄/.test(btnGroup.label))) {
            return;
          }

          // 데이터 영역만 우클릭 메뉴 적용하기 위해서 추출
          // 고정 컬럼 없을 때 전체로 적용
          const uiGridCanvases = elem.querySelectorAll('.ui-grid-canvas');
          const uiGridCanvas = uiGridCanvases[1] || uiGridCanvases[0];

          // 우클릭 메뉴 삽입후 컴파일
          uiGridCanvas.setAttribute('context-menu', 'contextMenu');
          $compile(uiGridCanvas)(scope);

          // 우클릭시 로우 선택되야함
          // 이벤트 타겟이 필요해서 내장 이벤트로 함
          // Vanilla Javascript로 구현을 못하겠어서 어쩔수 없이 Jquery 사용
          // 방법을 아는사람은 교체 바람
          // 메뉴 오픈전 이벤트로 context-menu-opening가 있음 참고
          elem.find('.ui-grid-canvas:nth-child(1)').on('contextmenu', '.ui-grid-row-inner', (e) => {
            // 각 로우에 할당된 전체데이터의 인덱스를 추출
            const rowIndex = e.target.closest('.ui-grid-row-inner').attributes['row-index'].value;
            const row = _gridApi.grid.rows[rowIndex];

            // 패밀리옵션 부모 로우, 그룹옵션 그룹헤더 로우 일시 컨텍스트 메뉴 출력안함
            if (e.target.closest('.ui-grid-cell').attributes['no-context']) {
              e.preventDefault();
              e.stopPropagation();

              return;
            }

            // 선택처리
            _gridApi.selection.selectRow(row.entity);

            // 우클릭 메뉴 데이터 세팅
            _setContextMenu();
          });
        }

        // 그리드 초기 세팅 완료 후 실행 함수
        // ui.grid자체적으로 initComplete 이벤트가 없음
        // 첫 리스트 호출 완료후 실행
        function _initCompleteCallback() {
          _contextMenuInit();

          // 높이 계산후 적용
          _resizingHeight();

          if (!_options.modal && !_options.noAutoResize) {
            // 최대 로딩 시간 후 재 계산
            $timeout(_resizingHeight, 8000);
          }

          // 초기 세팅 완료 할당
          _sideEffectAllow = true;
        }

        const checkColumns = [
          {
            addCol: { name: 'stock_cd', visible: false, width: 160, sort: {}, pinned: '' },
            prevCol: 'sku_cd',
            tableList: ['order_shipment_grid', 'unstoring_shipment_grid', 'delivery_shipment_grid', 'integrated_shipment_grid', 'claim_shipment_grid', 'ebaydepot_shipment_grid', 'order_shipment_map_prod_grid', 'mapping_rule_grid']
          }, {
            addCol: { name: 'total_cnt', visible: false, width: 100, sort: {}, pinned: '' },
            prevCol: 'pack_unit',
            tableList: ['order_shipment_grid', 'unstoring_shipment_grid', 'delivery_shipment_grid', 'integrated_shipment_grid', 'claim_shipment_grid']
          }, {
            addCol: { name: 'notice_msg', visible: false, width: 200, sort: {}, pinned: '' },
            prevCol: 'ship_msg',
            tableList: ['payment_shipment_grid', 'order_shipment_grid', 'unstoring_shipment_grid', 'delivery_shipment_grid', 'integrated_shipment_grid', 'claim_shipment_grid']
          }
        ];

        // 그리드 초기 세팅
        async function _gridInit(callback = true) {
          // 컬럼들의 총합이 전체 너비보다 모자랄시 전체 너비로 자동세팅
          _setColumnAutoFitWidth();

          // 초기화용 세팅 저장
          _gridInitState = _gridApi.saveState.save();

          // 캐시된 상태 조회
          const state = localStorageService.get(GRID_STORAGE_ID) || await commonModel.getTableState(GRID_ID).then(({ data: { state } }) => state);
          // 리스트 노출용 페이징 사이즈 할당
          let pageSize = _gridOptions.paginationPageSize;

          // 저장된 상태 있을시
          if (state) {
            // 테이블 설정이 있고 항목이 새로 추가되는 경우 추가되는 항목이 맨 뒤로 가는 문제가 있어 처리
            if (_options.columns.length !== state.columns.length) {
              checkColumns.forEach(check => {
                if (check.tableList.indexOf(attr.id) > -1 && state.columns.findIndex(c => c.name === check.addCol.name) === -1) {
                  state.columns.splice(state.columns.findIndex(c => c.name === check.prevCol) + 1, 0, check.addCol);
                }
              });
            }

            // 정렬 가능한 컬럼 변경 될 시 초기화
            state.columns = state.columns.map(column => ({
              ...column,
              sort: isColumnSortable(column.name) ? column.sort : {}
            }));

            // 다중정렬 미지원 테이블인데 다중정렬 되어있는 경우, 가장 마지막 설정된 정렬 외 나머지 제거
            if (_gridOptions.suppressMultiSort) {
              const sortColLen = state.columns.filter(e => Object.prototype.hasOwnProperty.call(e.sort, 'priority')).length;

              if (sortColLen > 1) {
                state.columns.forEach(column => {
                  if (column.sort.priority !== (sortColLen - 1)) {
                    column.sort = {};
                  } else {
                    column.sort.priority = 0;
                  }
                });
              }
            }

            // 페이징 사이즈 존재시 노출용 사이즈 재할당
            pageSize = state.pagination.paginationPageSize;

            // 페이지는 무조건 1페이지부터 시작설정
            // 페이징 저장 옵션은 조정이 불가능하고 페이징 사이즈는 저장을 해야하기 때문에 강제로 1로 변경
            state.pagination.paginationCurrentPage = 1;

            // restore완료후 paginationChange발동 전에 request가 먼저 발동해서 페이지는 미리 변경시킴
            _requestOptions.start = 0;
            _requestOptions.length = state.pagination.paginationPageSize;

            _gridApi.saveState.restore(scope, state);
            _stateSave(state);
          }

          // 바인딩된 변수에 사이즈 할당
          _gridSearchObjScope.searchData.showCount = pageSize;

          if (_options.initShowCount) {
            _options.initShowCount(_gridSearchObjScope.searchData.showCount);
          }

          elem.find('.ui-grid-render-container.ui-grid-render-container-body .ui-grid-viewport')
            // 스크롤시에 툴팁남는 버그때문에 스크롤시에 툴팁 지우도록 바인딩
            .scroll(
              _.debounce(() => {
                document.body.querySelectorAll('.tooltip').forEach((tooltipElem) => {
                  tooltipElem.remove();
                });
              }, 100, { leading: true, trailing: false })
            )
            // 스크롤이 필요할 때만 생성되도록 변경
            .css('overflow', 'auto');

          // 컬럼 위치 이동시에 옆으로 빨리이동시 이동컬럼이 남는 버그때문에 추가시킴
          elem
            .on('mouseup mouseleave', () => {
              elem.find('.movingColumn').remove();
            });

          // 현재 보이지 않는 그리드를 새로고침 시키면 뷰포트가 작아져서 다시 리사이징 시킴
          scope.$on('$stateChangeSuccessGlobal', () => {
            const gridState = attr.state;
            const pageState = $state.current.name.split('.').slice(-1)[0];

            if (gridState === pageState) {
              _gridApi.core.handleWindowResize();
              _gridApi.core.queueGridRefresh();
              $timeout(_resizingHeight);
            }
          });

          // 초기 데이터 호출
          await _externalRequest(true);

          if (callback) {
            _initCompleteCallback();
          }
        }

        // 초기화
        function _init() {
          // ui.gird용 컬럼 데이터 생성
          _gridOptions.columnDefs = _options.columns
          // 재고관리 전용컬럼 확인
            .filter(col => {
              if (!$rootScope.user_profile.sol_stock && col.requireStock) {
                return false;
              }

              // 컬럼 노출여부 확인
              if (col.notVisible) {
                return false;
              }

              return true;
            })
            .map(_getUiGridColData);

          // API설정
          _gridOptions.onRegisterApi = gridApi => {
            _gridApi = gridApi;

            // 외부 사용 함수 세팅
            _gridObjScope.methods = {
              length: setGridPageSize,
              reloadData: reloadData,
              setEmpty: setEmpty,
              setDirectData: setDirectData,
              reDraw: refresh,
              filteredData: getFilteredRows,
              selectedData: getSelectedRows,
              selectFilteredData: getSelectFilteredRows,
              changeSelectByFilter: selectionConvertByFilter,
              doSelectByFilter: selectByFilter,
              unSelectByFilter: unSelectByFilter,
              openColvis: setColVisibles,
              reset: setGridInit,
              getColumnsVisible: getVisibleColumns,
              setColumnsVisible: setVisibleColumns,
              setShowCountByGridPageSize: setExposPageSize,
              selectAllRows: selectAllRows,
              setSelectedRow: setSelectedRow,
              getResultDatas: getResultDatas,
              setColumnAutoFitWidth: _setColumnAutoFitWidth,
              changeTitle: _changeTitle,
              gridInit: gridInit,
              getVisibleRows: _gridApi.core.getVisibleRows,
              clearSelectedRows: clearSelectedRows,
              toggleExpand: toggleExpand
            };

            // 정렬 변경시
            _gridApi.core.on.sortChanged(scope, _sortChangedCallback);

            // 페이지 변경시
            _gridApi.pagination.on.paginationChanged(scope, _paginationChangedCallback);

            // 컬럼 노출 수정시
            _gridApi.core.on.columnVisibilityChanged(scope, _columnVisibilityChangedCallback);

            // 컬럼 이동시
            _gridApi.colMovable.on.columnPositionChanged(scope, _columnPositionChangedCallback);

            // 컬럼 리사이징시
            _gridApi.colResizable.on.columnSizeChanged(scope, _columnSizeChangedCallback);

            // 로우 선택시
            _gridApi.selection.on.rowSelectionChanged(scope, _rowSelectionChangedCallback);

            // 로우 여러개 선택시
            _gridApi.selection.on.rowSelectionChangedBatch(scope, _rowSelectionChangedBatchCallback);

            // 로우 렌더링 완료시
            _gridApi.core.on.rowsRendered(scope, _rowsRenderedCallback);

            // 데이터테이블 스크롤 시
            _gridApi.core.on.scrollEnd(scope, _syncExpandableWithScroll);

            // 저장된 그리드 상태 불러오기
            $timeout(_gridInit);
          };

          // 처음 정렬 설정
          _requestOptions.orderby = _gridOptions.columnDefs
            .filter(colData => !!colData.sort)
            .map(colData => `${colData.name} ${colData.sort.direction}`)
            .join(',');
          // .map(colData => { return { key: colData.name, sort: colData.sort.direction }; });
        }
        _init();
      }
    };
  });
