'use strict';

angular.module('gmpApp')
  .controller('invoiceExcelDownCtrl', function ($scope, $rootScope, data, deliveryModel, commonSVC, $uibModalInstance, commonModel, $timeout, systemModel, gettextCatalog, localStorageService) {
    $scope.showDesc = localStorageService.get('invoiceExcelDownYn') || 'Y'; // Description 칸

    // 사내 IP로 확인할 경우 디버그 접속 시 이카운트 양식이 모든 업체에 확인되서 안내에 혼선이 있어 제거함
    $scope.isOurComp = $rootScope.user_profile.remote_ip === '127.0.0.1' || $rootScope.user_profile.sol_no == 496 || $rootScope.user_profile.sol_no == 596;
    $scope.modalData = data;
    $scope.templateList = [];
    $scope.editTemplateData = {};
    $scope.isFinish = false;
    $scope.percent = '0%';
    $scope.excel_password_yn = 0;

    function loadTemplate() {
      deliveryModel.DeliveryExcelTemplateList(function (state, data) {
        if (state === 'success') {
          $scope.templateList = data.results || [];
          $scope.editTemplateData = {};
        } else {
          commonSVC.showToaster('error', '실패', '양식 조회 실패');
        }
      });
    }

    loadTemplate();

    /**
     * 설명 Div 접기 변경
     */
    $scope.changeShowDesc = () => {
      $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
      localStorageService.set('invoiceExcelDownYn', $scope.showDesc);
    };

    // 시스템 환경설정 구하기
    let systemList;

    systemModel.load().then(function (result) {
      systemList = result;
    });

    $scope.viewPriceTable = function () {
      const viewStr = `<div class="sweet-alert-body" style="padding: 0 !important;">
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">방송통신위원회고시 ‘개인정보의 기술적/관리적 보호조치 기준’ 제6조 4항</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 6조(개인정보의 암호화)</span><br>
        <span style="font-size: small;">④ 정보통신서비스 제공자등은 이용자의 개인정보를 컴퓨터, 모바일 기기 및 보조저장매체 등에 저장 할 때에는 이를 암호화해야 한다.</span><br><br><br>
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">개인정보의 기술적/관리적 보호조치 기준</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 4조(접근통제)</span><br>
        <span style="font-size: small;">⑧ 정보통신서비스 제공자등은 개인정보취급자를 대상으로 다음 각 호의 사항을 포함하는 비밀번호 작성규칙을 수립하고, 이를 적용 운용하여야 한다.</span><br>
        <span style="font-size: small;">1. 영문, 숫자, 특수문자 중 2종류 이상을 조합하여 최소 10자리 이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 구성</span><br><br><br>
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">개인정보의 안전성 확보조치 기준</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 8조(접속기록의 보관 및 점검)</span><br>
        <span style="font-size: small;">② 개인정보처리자는 개인정보의 오·남용, 분실·도난·유출·위조·변조 또는 훼손 등에 대응하기 위하여 개인정보처리시스템의 접속기록 등을 월 1회 이상 점검하여야 한다.´</span><br>
        <span style="font-size: small;">특히 개인정보를 다운로드한 것이 발견되었을 경우에는 내부관리 계획으로 정하는 바에 따라 그 사유를 반드시 확인하여야 한다.</span><br>
        </div>`;
      commonSVC.showMessageHtml('관련 법적 고지 안내<hr style="margin-bottom: 0;">', viewStr);
    };

    /**
     * 비밀번호 변경사항이 있을때 유효성 검사 초기화.
     */
    $scope.$watch('password.new', function () {
      $scope.newOverlap = null;
      $scope.overlapResult = null;
      $scope.confirmOverlap = null;

      const regExp = /^(?=.*[a-zA-Z])(?=.*[,.<>/?~()_[\]{}|;:!@#$%^&*+=-])(?=.*[0-9]).{8,}$/;
      const validPassword = regExp.test($scope.password.new);
      if (!validPassword && $scope.password.new !== '') {
        $scope.newOverlap = 'n';
        $scope.alerts = { msg: '비밀번호 규칙에 맞게 다시 설정해 주세요.' };
      }
    });

    $scope.$watch('password.confirm', function () {
      $scope.overlapResult = null;
      $scope.confirmOverlap = null;

      if ($scope.password.confirm !== '') {
        if ($scope.password.confirm === $scope.password.new) {
          $scope.confirmOverlap = 'y';
          $scope.alerts = { msg: '비밀번호가 일치합니다.' };
        } else if ($scope.password.confirm !== $scope.password.new) {
          $scope.confirmOverlap = 'n';
          $scope.alerts = { msg: '비밀번호가 일치하지 않습니다.' };

          return false;
        }
      }
    });

    //비밀번호 초기값 변수
    $scope.password = {
      new: '',
      confirm: '',
      reason: ''
    };

    // localStroage에 저장된 사유 불러오기
    if (localStorage.getItem('PASSWORD_REASON')) {
      $scope.password.reason = localStorage.getItem('PASSWORD_REASON').replace(/"/g, '');
    }
    // localStroage에 엑셀 암호화 사용여부 체크박스 값 불러오기
    if (localStorage.getItem('EXCEL_PASSWORD_YN')) {
      $scope.excel_password_yn = JSON.parse(localStorage.getItem('EXCEL_PASSWORD_YN'));
    }

    /**
     * 양식 선택
     * */
    $scope.templateSelect = function (temp) {

      let defaultTemplateData;

      if (temp === 'default') {
        defaultTemplateData = {
          rename_yn: 0,
          columns_origin: [{ header: '묶음번호', key: 'bundle_no', select: true }, { header: '택배사', key: 'carr_name', select: true }, { header: '택배사코드', key: 'carr_no', select: true }, { header: '운송장번호', key: 'invoice_no', select: true }, { header: '출고가능여부', key: 'ship_avail_yn', select: true, type: 'boolean' }, { header: '출고상태', key: 'ord_status', select: true }, { header: '출고 지시일', key: 'out_order_time', select: true }, { header: 'SKU코드', key: 'sku_cd', select: true }, { header: 'SKU상품명', key: 'prod_data', select: true }, { header: '건별출고수량', key: 'pack_unit', select: true }, { header: '수령자명', key: 'to_name', select: true }, { header: '발송예정일', key: 'ship_plan_date', select: true }, { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true }, { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true }, { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true }, { header: '상품명', key: 'shop_sale_name', select: true }, { header: '옵션', key: 'shop_opt_name', select: true }, { header: '주문수량', key: 'sale_cnt', select: true }, { header: '추가구매옵션', key: 'shop_add_opt_name', select: true }, { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true }, { header: '수령자 주소', key: 'to_addr', select: true }, { header: '수령자 휴대폰번호', key: 'to_htel', select: true }, { header: '국가', key: 'to_ctry_cd', select: true }, { header: '배송처', key: 'depot_name', select: true }, { header: '규칙적용사은품', key: 'gift_pack', select: true }, { header: '사은품', key: 'gift_name', select: true }, { header: '배송방법', key: 'ship_method', select: true }, { header: '배송비', key: 'ship_cost', select: true }],
          columns_origin2: '',
          file_type: 'xlsx',
          name: '기본 양식',
          template_no: 'default'
        };

      } else if (temp === 'ecount') {
        // 이카운트엑셀
        defaultTemplateData = {
          apiUrl: '/app/order/ecountExcelDown',
          rename_yn: 0,
          columns_origin: [{ header: '일자', key: 'wdate', select: true }, { header: '순번', select: true }, { header: '거래처코드', select: true }, { header: '거래처명', key: 'shop_name', select: true }, { header: '담당자', select: true }, { header: '출하창고', key: 'depot_name', select: true }, { header: '거래유형', select: true }, { header: '통화', select: true }, { header: '환율', select: true }, { header: '품목코드', key: 'sku_cd', select: true }, { header: '품목명', key: 'prod_names', select: true }, { header: '규격', select: true }, { header: '수량', key: 'sale_cnt', select: true }, { header: '단가', key: 'prod_sales', select: true }, { header: '외화금액', select: true }, { header: '공급가액', select: true }, { header: '부가세', select: true }, { header: '적요', select: true }, { header: '관리항목', select: true }, { header: '생산전표생성', select: true }, { header: 'Ecount', key: 'ecount', select: true }],
          columns_origin2: '',
          file_type: 'xlsx',
          name: '이카운트 양식',
          template_no: 'ecount'
        };
      }

      // 이카운트 수정 2019-02-22 Jacob
      //'[{"header": "일자","key":"wdate","select": true}, {"header": "순번","select": true}, {"header": "거래처코드","select": true}, {"header": "거래처명","key": "shop_name","select": true}, {"header": "담당자","select": true}, {"header": "출하창고","key": "depot_name","select": true}, {"header": "거래유형","select": true}, {"header": "통화","select": true}, {"header": "환율","select": true}, {"header": "품목코드","key": "sku_cd","select": true}, {"header": "품목명","key": "prod_names","select": true},{"header": "규격","select": true}, {"header": "주문수량","key": "sale_cnt","select": true}, {"header": "단가","select": true}, {"header": "외화금액","select": true}, {"header": "공급가액","select": true}, {"header": "부가세","select": true}, {"header": "적요","select": true}, {"header": "관리항목","select": true}, {"header": "부대비용","select": true}, {"header": "생산전표생성","select": true}, {"header": "Ecount","key":"ecount","select": true}]',

      $(`#template_${defaultTemplateData ? defaultTemplateData.template_no : temp.template_no}`).prop('checked', true);
      $scope.editTemplateData = defaultTemplateData ? defaultTemplateData : temp;
    };

    /**
     * 양식 추가하기
     */
    $scope.addTemplate = function () {
      const resolve = {};

      resolve.data = {
        pageType: 'add'
      };
      const modal = commonSVC.openModal('lg', resolve, 'ExcelTemplateCtrl', 'views/order/shipment/modals/invoice_excel_template.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $timeout(function () {
            loadTemplate();
          }, 100);
        }
      });
    };

    /**
     * 양식 수정하기
     */
    $scope.editTemplate = function () {
      if ($scope.editTemplateData.template_no === 'default' || $scope.editTemplateData.template_no === 'ecount') {
        commonSVC.showMessage('기본양식은 수정할 수 없습니다.');

        return false;

      } else if ($scope.editTemplateData.template_no) {
        const resolve = {};

        resolve.data = {
          pageType: 'edit',
          template: $scope.editTemplateData
        };
        const modal = commonSVC.openModal('lg', resolve, 'ExcelTemplateCtrl', 'views/order/shipment/modals/invoice_excel_template.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $timeout(function () {
              loadTemplate();
            }, 100);
            // loadTemplate();
          }
        });
      } else {
        commonSVC.showMessage('수정할 양식을 선택하세요.');

        return false;
      }
    };

    /**
     * 양식 삭제하기
     */
    $scope.deleteTemplate = function () {
      if ($scope.editTemplateData.template_no === 'default' || $scope.editTemplateData.template_no === 'ecount') {
        commonSVC.showMessage('기본양식은 삭제할 수 없습니다.');

        return false;

      } else if ($scope.editTemplateData.template_no) {
        commonSVC.showConfirm('양식을 삭제 하시겠습니까?', '', function () {
          deliveryModel.DeleteDeliveryExcelTemplate({ template_no: $scope.editTemplateData.template_no }, function (state) {
            if (state === 'success') {
              commonSVC.showToaster('success', '성공', 'Excel 양식 삭제 성공');
              $timeout(function () {
                loadTemplate();
              }, 100);
              // loadTemplate();
            } else {
              commonSVC.showToaster('error', '실패', 'Excel 양식 삭제 실패');
            }
          });
        });

      } else {
        commonSVC.showMessage('삭제할 양식을 선택하세요.');

        return false;
      }
    };

    /**
     * 엑셀 다운로드
     * */
    $scope.download = function () {
      $scope.alerts = {};

      if ($scope.excel_password_yn === 1) {

        //비밀번호 유효성 체크
        if ($scope.password.new !== $scope.password.confirm) {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('비밀번호가 일치하지 않습니다.'));
          $scope.confirmOverlap = 'n';
          $scope.alerts = { msg: '비밀번호가 일치하지 않습니다.' };
          $timeout(function () { $scope.waitBtnDisableFlag = false; });

          return false;
        }
        if (!$scope.password.new || !$scope.password.confirm || !$scope.password.reason) {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('필수 입력 항목을 확인해주세요.'));
          $scope.overlapResult = 'blank';
          $scope.alerts = { msg: '필수 입력 항목입니다.' };
          $timeout(function () { $scope.waitBtnDisableFlag = false; });

          return false;
        }
      }
      /**
      * 사유 로컬스토리지에 저장
      **/

      localStorage.setItem('PASSWORD_REASON', JSON.stringify($scope.password.reason));
      localStorage.setItem('EXCEL_PASSWORD_YN', JSON.stringify($scope.excel_password_yn));

      let apiUrl = data.url;

      if (!$scope.editTemplateData.template_no) {
        commonSVC.showMessage('양식을 선택하세요.');

        return false;
      }

      if ($scope.editTemplateData.apiUrl) {
        apiUrl = $scope.editTemplateData.apiUrl;
      }

      let downList = $scope.editTemplateData.rename_yn ? $scope.editTemplateData.columns_rename : $scope.editTemplateData.columns_origin;

      // 재고관리 미사용버전인경우 sku 코드 및 SKU상품명은 엑셀 다운 제외 2018-11-28 rony
      if ($rootScope.user_profile.sol_stock < 1) {
        downList = _.filter(downList, function (o) {
          return o.key !== 'sku_cd' && o.key !== 'prod_data' && o.key !== 'pack_unit';
        });
      }

      const idx = _.findIndex(downList, { key: 'shop_name' });

      if (idx != -1) {
        downList[idx].header = '쇼핑몰';
        downList.splice(idx + 1, 0, { header: '계정', key: 'shop_id', select: true });
      }
      let params = {
        down_list: downList,
        file_ext: $scope.editTemplateData.file_type,
        sheet_name: $scope.editTemplateData.sheet_name || '출고관리',
        default_carr_no: $scope.editTemplateData.default_carr_no,
        is_all: data.isAll,
        sale_name_type: $scope.editTemplateData.sale_name_type,
        shop_id_view_type: systemList.data.shop_id_view_type,
        template_no: $scope.editTemplateData.template_no,
        password: $scope.password.new,
        reason: $scope.password.reason,
        excel_password_yn: $scope.excel_password_yn
      };

      if (data.searchForm) {
        params = angular.merge(params, data.searchForm);
      }

      // 선택 다운로드
      if (!data.isAll) {
        params.uniq = data.uniq;
      } else {
        // 주문 전체다운의 경우 데이터 조회에 시간이 다소 소요될 수 있으므로 timeout 시간 늘려줌
        params.timeout = 180000;
      }

      $('progress-bar-dir').css({ display: 'inline-block' });

      commonModel.excelDownload(apiUrl, params, function (state, result) {
        if (state == 'success') {
          $scope.isFinish = true;
          $scope.percent = '100%';

          // 팝업차단 설정 추가.
          const newWin = window.open(result.url);

          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
          } else {
            commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
            $uibModalInstance.close('success');
          }
        } else {
          $('progress-bar-dir').css({ display: 'none' });

          commonSVC.showToaster('error', '실패', '엑셀 다운로드에 실패하였습니다.');
        }
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  .controller('invoiceExcelUploadCtrl', function ($scope, $rootScope, $uibModalInstance, data, commonSVC, workSVC, settings, shipmentModel) {
    $scope.fileName = '파일 검색 또는 끌어다놓기';

    $scope.resultFileUrl = '';

    $scope.deliveryList = _.concat($rootScope.deliveryInfoListAll, data.globalCarrList || []);
    $scope.shopType = data.shopType;

    const carrList = data.shopType === 'domestic' ? angular.copy($rootScope.deliveryInfoList) : data.globalCarrList;

    // 엑셀 드래그앤드랍 검증
    $scope.fileSelect = (files) => {
      // 노출용 파일명 세팅, 파일 개수 제한
      $scope.fileData = [];
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    /**
     * 엑셀 업로드
     * */
    $scope.upload = function () {
      const [template_data] = $scope.grid.methods.selectedData('template_data');

      if (!template_data) {
        commonSVC.showMessage('실패', '업로드 양식을 선택하여 주시기 바랍니다.');

        return false;
      }

      if (!$scope.fileData) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');

        return false;
      }

      if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : $scope.fileData.name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
        $scope.fileData = [];

        return false;
      } else if ($scope.fileData) {
        const params = {
          default_carr_no: template_data.default_carr_no,
          field: template_data.field,
          type: data.type,
          overwrite: $scope.overwrite,
          is_global: data.shopType === 'global'
        };

        const action_name = data.type === 'as' ? 'UploadAsDeliveryInfoExcel' : 'UploadDeliveryInfoExcel';

        workSVC.addExcelWork(action_name, params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
          });

      }
    };

    // 업로드 양식 데이터 테이블
    const searchForm = {
      search_key: 'template_name',
      search_word: '',
      is_global: data.shopType === 'global'
    };

    $scope.searchForm = angular.copy(searchForm);

    $scope.searchData = {
      totalCount: 0,
      selectCount: 0,
      showCount: 5,
      selectType: false,
      all_checked: false,
      search_key_items: []
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      selectOptions: {
        multiSelect: false
      },
      gridHeight: 280,
      initPagingSize: 5,
      pinningColumns: ['mdate', 'template_name', 'carr_name', 'bookmark_yn'],
      alignCenterColumns: ['mdate', 'carr_name', 'bookmark_yn'],
      alignRightColumns: [],
      defaultSortingColumns: ['mdate'],
      notSortingColumns: ['carr_name', 'bookmark_yn'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/invoiceExcelUploadTemplate/list`,
        requestWillAction: data => Object.assign(data, $scope.searchForm),
        requestDidAction: result => {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.searchData.selectCount = result.recordsFiltered;
          result.results.forEach(template => {
            // json파싱
            template.template_data = JSON.parse(template.template_data);
          });

          $scope.templateList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'mdate',
          title: '최종수정일',
          width: '30%'
        }, {
          key: 'template_name',
          title: '양식명',
          width: '45%'
        }, {
          key: 'carr_name',
          title: '고정 택배사',
          width: '15%',
          template: row => {
            const carr = $scope.deliveryList.find(carr => carr.carr_no === parseInt(row.template_data.default_carr_no));

            return carr ? carr.carr_name : '사용안함';
          }
        }, {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: '12%',
          template: row => {
            if (row.bookmark_yn === null) { return ''; }

            row.bookmark_yn = !!row.bookmark_yn;

            return '<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    $scope.searchFn = {
      // 탭 변경
      // 검색
      searchDo: () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        $scope.grid.methods.reloadData(function () { }, true);
      },
      // 초기화
      resetDo: () => {
        $scope.searchForm = angular.copy(searchForm);
        $scope.searchFn.searchDo();
      }
    };

    $scope.search = (e) => {
      if ((e && (e.keyCode === 13 || e.which === 13))) {
        $scope.searchFn.searchDo();
      }
    };

    $scope.changeDo = async ({ template_no, bookmark_yn }) => {
      try {
        const res = await shipmentModel.editInvoiceExcelUploadTemplate({ template_no, bookmark_yn });

        if (res.data.results === 'success') {
          commonSVC.showToaster('success', '성공', '즐겨찾기 수정 성공');
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', `즐겨찾기 수정 실패 \n ${err.data.error}`);

        return false;
      }
    };

    /**
     * 양식 추가하기
     */
    $scope.addTemplate = () => {
      const resolve = { data: {
        shopType: data.shopType,
        carrList: carrList
      } };

      const modal = commonSVC.openModal('lg', resolve, 'InvoiceUploadTemplateController', 'views/order/shipment/modals/invoice_excel_upload_template_detail.html');

      modal.result.then((re) => {
        if (re === 'success') {
          $scope.searchFn.resetDo();
        }
      });
    };

    /**
     * 양식 수정하기
     */
    $scope.editTemplate = () => {
      const [template] = $scope.grid.methods.selectedData('all');

      if (!template) {
        commonSVC.showMessage('실패', '수정할 양식을 선택해 주세요.');

        return false;
      }

      if (template.template_no === 'default') {
        commonSVC.showMessage('실패', '기본 양식은 수정할 수 없습니다.');

        return false;
      }

      const resolve = {
        data: {
          template,
          shopType: data.shopType,
          carrList: carrList
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'InvoiceUploadTemplateController', 'views/order/shipment/modals/invoice_excel_upload_template_detail.html');

      modal.result.then((result) => {
        if (result === 'success') {
          $scope.searchFn.resetDo();
        }
      });
    };

    /**
     * 양식 삭제하기
     */
    $scope.deleteTemplate = () => {
      const [template] = $scope.grid.methods.selectedData('all');

      if (template) {
        if (template.template_no === 'default') {
          commonSVC.showMessage('실패', '기본 양식은 삭제할 수 없습니다.');

          return false;
        }

        let msg = '양식을 삭제 하시겠습니까?';

        if (template.bookmark_yn) {
          msg = `즐겨찾는 양식입니다.\n${msg}`;
        }

        commonSVC.showConfirm(msg, '', (flag) => {
          if (flag) {
            shipmentModel.deleteInvoiceExcelUploadTemplate({ template_no: template.template_no })
              .then(res => {
                if (res.data.results === 'success') {
                  commonSVC.showToaster('success', '성공', '운송장 엑셀 업로드 양식 삭제 성공');
                  $scope.searchFn.resetDo();

                }
              })
              .catch(err => {
                commonSVC.showToaster('error', '실패', err.data.error);

                return false;
              });
          }
        });
      } else {
        commonSVC.showMessage('실패', '삭제할 양식을 선택해 주세요.');

        return false;
      }
    };

    /**
     * 양식 복사하기
     */

    $scope.copyTemplate = () => {
      const [template_no] = $scope.grid.methods.selectedData('template_no');

      if (!template_no) {
        commonSVC.showMessage('실패', '복사할 양식을 선택해 주세요.');

        return false;
      }

      if (template_no === 'default') {
        commonSVC.showMessage('실패', '기본 양식은 복사할 수 없습니다.');

        return false;
      }

      commonSVC.showConfirm('양식을 복사 하시겠습니까?', '', flag => {
        if (flag) {
          shipmentModel.copyInvoiceExcelUploadTemplate({ template_no })
            .then(res => {
              if (res.data.results === 'success') {
                commonSVC.showToaster('success', '성공', '업로드 양식 복사 성공');
                $scope.searchFn.resetDo();

                return true;
              }
            })
            .catch(err => {
              commonSVC.showToaster('error', '실패', `업로드 양식 복사 실패\n${err.data.error}`);

              return false;
            });
        }
      });
    };

    $scope.downResult = () => {
      window.open($scope.resultFileUrl, '');
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  })
  .controller('InvoiceUploadTemplateController', function ($scope, $rootScope, data, shipmentModel, commonSVC, $uibModalInstance) {
    $scope.shopType = data.shopType;
    $scope.carrList = data.carrList;

    $scope.template = {};

    if (data.template) {
      $scope.template = angular.copy(data.template);

      delete $scope.template._uiGridData;
    } else {
      // 템플릿 정보 초기화
      $scope.template = {
        sol_no: $rootScope.user_profile.sol_no,
        template_type: 'invoice_upload',
        wm_no: $rootScope.user_profile.m_no,
        bookmark_yn: 0,
        template_name: '',
        template_data: {
          default_carr_no: '',
          field: {
            bundle_no: '',
            carr_no: '',
            invoice_no: ''
          }
        },
        etc1: $scope.shopType === 'global'
      };
    }

    $scope.save = () => {
      if (!$scope.template.template_name) {
        commonSVC.showMessage('실패', '템플릿명을 입력해주세요.');

        return false;
      } else if (!$scope.template.template_data.default_carr_no) {
        commonSVC.showMessage('실패', '일괄 입력할 택배사를 선택하여 주시기 바랍니다.');

        return false;
      }

      const type = data.template ? 'editInvoiceExcelUploadTemplate' : 'addInvoiceExcelUploadTemplate';

      shipmentModel[type]($scope.template)
        .then(res => {
          if (res.data.results === 'success') {
            commonSVC.showToaster('success', '성공', `업로드 양식 ${data.template ? '수정' : '등록'} 성공`);

            $uibModalInstance.close('success');
          }
        })
        .catch(err => {
          commonSVC.showToaster('error', '실패', `${err.data.error}`);

          return false;
        });

    };

    $scope.close = () => {
      $uibModalInstance.dismiss();
    };
  });