/**
 * 내 상품, 비교상품 등록 모달
 **/

'use strict';

angular.module('gmpApp')
  .controller('RegistInsightOnlineCtrl', function ($rootScope, $scope, $state, $compile, $timeout, $filter, settings, data, systemList, $uibModalInstance, commonSVC, userInfo, insightModel, onlineProductModel, workSVC, payModel) {

    $scope.maxInsightCnt = {
      groupCnt: $rootScope.insightPromotionYN ? 10 : 1, // 가격비교 그룹 제한 개수
      compareCnt: $rootScope.insightPromotionYN ? 5 : 2 // 비교상품 개당 제한 개수 (내상품 1개, 비교 상품 2개)
    };

    const channelListArr = $rootScope.useChannelList({ site_action: 'SyncProdState' });
    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach(channelListArr, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    $scope.type = data.type, // my : 내상품 등록 , another : 비교상품 등록
    $scope.urlDisabled = false; // 비교상품 등록 올바른 url인지 확인
    $scope.chkValid = false; // 내 상품 등록 유효성 체크

    // 쿠팡 선택 시 옵션 데이터 (내상품)
    $scope.selectedOlShopNo;
    $scope.selectedOptIdx = null;
    $scope.optDatas = []; // 옵션 리스트

    // 상품 URL (비교상품)
    $scope.input = {
      shopSaleUrl: '',
      checkShopCd: ''
    };

    $scope.waitBtnDisableFlag = true;

    async function init () {
      $scope.insightGroupData = (await insightModel.getInsightGroups()).data.results;

      if (userInfo.user.insight_info) {
        $scope.maxInsightCnt = {
          groupCnt: 10, // 가격비교 그룹 제한 개수
          compareCnt: 5 // 비교 상품 개당 제한 개수
        };
      }
    }

    // shop 테이블 detail_url과 다른 url이 있어 빌트온 요청으로 해당 url에 대해서만 등록되도록 수정
    // 비교 상품 등록할 때 업체가 URL을 그냥 긁어다가 복붙해서 쿼리스트링에 불필요한 정보가 들어갈 수 있으므로, 빌트온에서 요청한 포맷으로 가공해서 전송
    $scope.shopDetailUrl = {
      A077: ['https://smartstore.naver.com/', 'https://brand.naver.com/'],
      B378: ['https://www.coupang.com/vp/products/'],
      A112: ['https://www.11st.co.kr/products/', 'www.11st.co.kr/products/pa/'],
      A001: ['http://itempage3.auction.co.kr/DetailView.aspx?itemno=', 'http://itempage3.auction.co.kr/detailview.aspx?ItemNo='],
      A006: ['https://item.gmarket.co.kr/Item?goodsCode='],
      A524: ['https://www.lotteon.com/p/product/', 'www.lotteon.com/p/product/bundle/'],
      A032: ['https://www.ssg.com/item/itemView.ssg?itemId='],
      A011: ['https://www.gsshop.com/prd/prd.gs?prdid=']
    };

    init();

    $scope.selectOpt = (row, index) => {

      if ($scope.selectedOptIdx === index) {
        $scope.selectedOptIdx = null;
        $scope.chkValid = false;
      } else {
        $scope.selectedOptData = angular.copy(row);
        $scope.selectedOptIdx = index;
        $scope.chkValid = true;
      }
    };

    const search = {
      searchForm: {
        search_key: 'all',
        search_word: ''
      },
      searchData: {
        all_checked: false,
        totalCount: 0,
        showCount: 10,
        selectCount: 0,
        search_key_items: [ // 검색영역 키값
          { label: '전체', value: 'all' },
          { label: '상품명', value: 'shop_sale_name' },
          { label: '쇼핑몰 상품코드', value: 'shop_sale_no' },
          { label: 'SKU(세트)상품명', value: 'sku_name' },
          { label: 'SKU(세트)코드', value: 'sku_cd' },
          { label: 'ISBN코드', value: 'isbn' },
          { label: '판매자관리코드', value: 'c_sale_cd' },
          { label: '브랜드', value: 'brand' },
          { label: '모델명', value: 'model' }
        ]
      },
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.searchFn = {
      changeLen: function() {
        $scope.changeCount();
      }
    };
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: true,
      pinningColumns: [],
      defaultSortingColumns: [],
      alignCenterColumns: ['sale_status', 'sale_img1', 'shop_sale_name', 'shop_sale_no', 'sale_price', 'sku_cd', 'sku_name', 'isbn', 'c_sale_cd', 'brand', 'model'],
      alignRightColumns: [],
      alignLeftColumns: [],
      notSortingColumns: ['sale_status', 'sale_img1', 'sol_cate_name_first'],
      notVisibleColumns: [],
      notMovingColumns: [],
      notResizingColumns: [],
      initPagingSize: 10,
      gridHeight: 430,
      emptyText: '조건에 맞는 상품이 없습니다',
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/insight/online`,
        requestWillAction: function (d) {
          d = angular.merge({}, d, $scope.searchForm);

          return d;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'sale_status',
          title: '상품상태',
          width: 60,
          important: true,
          notCompile: true,
          tooltip: '판매중, 수정대기, 종료대기, 일시품절 상품만 노출됩니다.',
          template: function(row) {
            if (row.shop_cd === 'Z000') {
              return '';
            }

            return $filter('statusColorOl')(row.sale_status);
          }
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 120,
          important: true,
          template: function(row) {
            const shop_info = commonSVC.getShopIdViewText(
              systemList.data.shop_id_view_type,
              seller_nick_info,
              row.shop_cd,
              row.shop_id
            );

            return `<span uib-tooltip="${row.shop_name}(${shop_info[0]})" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${
              ['A001', 'A006', 'B378'].includes(row.shop_cd) && row.ebay_shop_mas_no ? (row.shop_cd === 'A001' ? 'ESMA' : (row.shop_cd === 'A006' ? 'ESMG' : 'CPRG')) : (row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd)
            }.png" style="width: 50px;">${shop_info[1]}</span>`;
          }
        },
        {
          key: 'sale_img1',
          title: '이미지',
          width: 50,
          notCompile: true,
          template: function(row) {
            //이미지
            let img = '';

            if (row.sale_img_url) {
              img += row.sale_img_url || '';
              img += row.sale_img1 || '';
            } else {
              img = '/assets/images/noimage.png';
            }

            return `<img src='${img}' width='25' height='25' onerror='this.src="/assets/images/noimage.png"'>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 150
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 100
        },
        {
          key: 'sol_cate_name_first',
          title: '카테고리',
          width: 120,
          template: function(row) {

            const cateArr = [];

            for (let i = 1; i <= 8; i++) {
              cateArr.push(row[`shop_cate${i}`]);
            }
            const match_cate_name = cateArr.filter(Boolean).join(' > ');
            const match_sol_cate_name = _(row.sol_cate_shop_names).split(',').compact().join(' > ') || '매칭 카테고리 없음';

            return (!match_cate_name || match_cate_name !== '임시') ? match_cate_name : match_sol_cate_name;
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자 관리코드',
          width: 100
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 80
        }
      ]
    };

    /**
     * ui-grid 노출 건수 설정
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.searchDo = function (refresh, noDelay) {
      $scope.optDatas = [];

      // 검색 시 초기화
      $scope.selectedOlShopNo = null;
      $scope.selectedOptData = null;
      $scope.selectedOptIdx = null;

      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(search.searchForm);

      $scope.searchDo(true, true);
    };

    $scope.onSubmit = async function () {

      const addParams = {
        ol_shop_no: data.groupData.ol_shop_no,
        insight_seq: data.groupData.insight_seq, // 그룹상품 현재 있는 개수이므로 인덱스로 동일하게 저장 (0: 1개, 1: 2개)
        url: '',
        shop_cd: '',
        seq: data.groupData.seq + 1,
        sale_opt_seq: data.groupData.sale_opt_seq || 1 // 옵션 순번(쿠팡, 네이버 그룹상품) 나머지 쇼핑몰은 1 (쿠팡은 옵션 별로 등록하여 옵션 순번 필요, 나머지는 상품별로 등록하여 1로 고정)
      };

      let loading;
      let timer;

      try {

        if ($scope.type === 'my') {
          if (data.groupData.totalCnt >= $scope.maxInsightCnt.groupCnt) {

            if (userInfo.user.insight_info || $rootScope.insightPromotionYN) {
              commonSVC.showMessage(`내 상품은 최대 ${$scope.maxInsightCnt.groupCnt}개까지 등록가능합니다.`);
            } else {
              const modal = commonSVC.openModal('mini', { data: {} }, 'confirmCtrl', 'views/summary/modals/insight_count.html');

              modal.result.then(function (res) {

                if (res === 'success') { // 결제 페이지 이동
                  payModel.getSolInfo(userInfo.user.m_no, function (status, data) {
                    if (status === 'success') {

                      $scope.solutionInfo = data.results.solutionInfos;
                      const solutionProductInfo = data.results.solutionProductInfo;
                      const payedJoinPrice = data.results.payedJoinPrice;    // 결제된 가입비 정보

                      // 다음 카운트 갱신일
                      const solEdate = moment($scope.solutionInfo.sol_edate);
                      const today = moment();
                      const usingChargeEdate = moment($scope.solutionInfo.using_charge_edate);

                      if (solEdate.isAfter(today, 'day')) {
                        $scope.solutionInfo.using_charge_edate = usingChargeEdate.add(1, 'days').format('YYYY-MM-DD');
                      } else {
                        $scope.solutionInfo.using_charge_edate = '없음';
                      }

                      // 남은일자
                      $scope.solutionInfo.leftDay = moment($scope.solutionInfo.sol_edate).endOf('day').diff(moment().endOf('day'), 'days');

                      // 현재 사용중인 솔루션의 월금액.
                      for (let i = 0; i < solutionProductInfo.length; i++) {
                        // 사용중인 솔루션의 일금액. ( 환불시 필요 )
                        if (solutionProductInfo[i].prod_name === $scope.solutionInfo.sol_version && solutionProductInfo[i].prod_opt === '1일') {
                          $scope.solutionInfo.dayPrice = solutionProductInfo[i].price;
                        }
                      }

                      // 카운트 부족 추천 버전은 무조건 상위버전
                      const recommendVersion = (() => {
                        if ($scope.solutionInfo.sol_version == 'VIP+') { return 'VIP+'; }

                        return $rootScope.recommendVersions[$rootScope.recommendVersions.findIndex(o => o === $scope.solutionInfo.sol_version) + 1];
                      })();

                      const params = {
                        solutionInfo: $scope.solutionInfo,
                        solutionProductInfo: solutionProductInfo,
                        payedJoinPrice: payedJoinPrice,
                        firstOrdYN: !$scope.solutionInfo.first_pay_no,
                        closeFlag: true,
                        insight_info: userInfo.user.insight_info,
                        recommendVersion
                      };

                      $state.go('main.pay_list');

                      commonSVC.openModal('x3g', { data: params }, 'PayRequestCtrl', 'views/pay/modals/pay_request.html');
                    }
                  });

                  $uibModalInstance.close();

                }
              });
            }

            return false;
          }
          const [selectedProd] = $scope.grid.methods.selectedData('all');

          if (['판매중지', '판매대기', '승인대기', '반려'].includes(selectedProd.sale_status)) {
            commonSVC.showMessage(`${selectedProd.sale_status} 상품은 등록할 수 없습니다.`, '판매중, 수정대기, 종료대기, 일시품절 상품만 등록가능합니다.');

            return false;
          }

          if (selectedProd.pa_shop_cd === 'B378' || (selectedProd.pa_shop_cd === 'A077' && selectedProd.std_ol_yn)) {
            if (!$scope.selectedOptData) {
              commonSVC.showMessage('쿠팡 상품, 스마트스토어 단일(그룹) 상품은 옵션까지 선택한 후 등록해주세요.');

              return false;

            } else if (!$scope.selectedOptData.opt_sale_cd) {
              commonSVC.showConfirm('해당 상품 가격정보를 확인하기 위해서 상품 동기화가 필요합니다.\n 진행하시겠습니까?', '', async (isConfirm) => {
                if (isConfirm) {
                  // 작업 추가
                  const params = {
                    numbers: [`${selectedProd.ol_shop_no}||${selectedProd.shop_cd}||${selectedProd.shop_id}`],
                    sync_data: [],
                    isSingleProd: selectedProd.std_ol_yn || 0,
                    isSmileProd: selectedProd.isSmileProd || false,
                  };

                  workSVC.addWorkSelect('SYNC_PROD_STATE', params);

                  $scope.searchDo(true, true);
                }
              });

              return false;

            }

          }

          if ($scope.insightGroupData.some(ol => ol.ol_shop_no === selectedProd.ol_shop_no && (ol.pa_shop_cd === 'B378' || (selectedProd.pa_shop_cd === 'A077' && selectedProd.std_ol_yn) ? ol.sale_opt_seq === $scope.selectedOptData.sale_opt_seq : true))) {
            commonSVC.showMessage('동일한 내 상품이 이미 등록되어 있습니다.');

            return false;
          }

          const row = channelListArr.find(ch => ch.shop_cd === selectedProd.shop_cd && ch.shop_id === selectedProd.shop_id);
          let url = $scope.shopDetailUrl[row.pa_shop_cd][0];

          if (row.pa_shop_cd === 'A077') {
            if (row.etc5.includes('brand.naver.com')) {
              url = 'https://brand.naver.com/';
            }
          }

          let sale_no = '';

          if (selectedProd.pa_shop_cd === 'B378') {
            sale_no = `${selectedProd.shop_sale_no}?vendorItemId=${$scope.selectedOptData.opt_sale_cd}`;
          } else if (selectedProd.pa_shop_cd === 'A077' && selectedProd.std_ol_yn) {
            sale_no = $scope.selectedOptData.opt_sale_cd;
          } else {
            sale_no = selectedProd.shop_sale_no;
          }

          switch (selectedProd.pa_shop_cd) {
            case 'A077':
              if (row.etc2) {
                url = `${url + row.etc2}/products/${sale_no}`;
              } else {
                url = `${row.etc5}/products/${sale_no}`;
              }
              break;
            default: {
              // url에 계정 데이터가 필요한 경우 치환해서 넣어줌
              const replaceVar = url.match(/{{\w+}}/g);

              replaceVar?.forEach(val => {
                url = url.replace(val, row[val.replace(/[{}]/g, '')]);
              });

              url = url + sale_no;
              break;
            }
          }

          addParams.url = url;
          addParams.ol_shop_no = selectedProd.ol_shop_no;
          addParams.pa_shop_cd = selectedProd.pa_shop_cd;
          addParams.shop_cd = selectedProd.pa_shop_cd;
          addParams.sale_opt_seq = $scope.selectedOptData?.sale_opt_seq || 1;

        } else {
          // 현재 해당 쇼핑몰 일부 미지원이나 추후 지원될 수 있으므로 추가 (내상품등록에서는 지원하고 있음)
          // 비교 상품 등록할 때 업체가 URL을 그냥 긁어다가 복붙해서 쿼리스트링에 불필요한 정보가 들어갈 수 있으므로, 빌트온에서 요청한 포맷으로 가공해서 전송
          // 쿼리스트링에 상품코드가 있는 경우 (A001, A006, A032, A011)
          // 쿼리스트링에 상품코드 및 옵션코드가 있는 경우 (B378)
          // 그 외 경우 쿼리스트링 제거하여 전송
          const queryStringKey = {
            A001: ['itemno', 'ItemNo'],
            A006: ['goodsCode'],
            A032: ['itemId'],
            A011: ['prdid'],
            B378: ['itemId', 'vendorItemId']
          };

          const params = new URL($scope.input.shopSaleUrl).searchParams;
          const queryStr = [];
          const regex = /^(https?:\/\/[a-zA-Z0-9.-]+(?:\/[^\?]*)?)/;

          addParams.pa_shop_cd = $scope.input.checkShopCd;
          addParams.shop_cd = $scope.input.checkShopCd;

          for (const key of (queryStringKey[addParams.pa_shop_cd] || [])) {
            const value = params.get(key);

            if (value) {
              queryStr.push(`${key}=${value}`);
            }
          }

          addParams.url = $scope.input.shopSaleUrl.match(regex)[1] + (queryStr.length ? ('?' + queryStr.join('&')) : '');
        }

        const editScheduleList = (await insightModel.priceEditScheduleList({ ol_shop_no: addParams.ol_shop_no, sale_opt_seq: addParams.sale_opt_seq, book_status: '예약대기' })).data.results;
        const alarmList = (await insightModel.priceAlarmScheduleList({ ol_shop_no: addParams.ol_shop_no, sale_opt_seq: addParams.sale_opt_seq, alarm_status: '미발송' })).data.results;

        if (editScheduleList.length > 0 || alarmList.length > 0) {
          const isConfirm = await commonSVC.showConfirm('비교상품을 추가/삭제하면 이미 설정된 예약/알림은 삭제됩니다. 계속하시겠습니까?', '');

          if (!isConfirm) {
            return false;
          }
        }

        loading = commonSVC.openModal('mini', { data: {} }, 'confirmCtrl', 'views/summary/modals/insight_loading.html', false, true, false);

        // 100퍼되면 꺼지므로 99퍼까지 만들어줌 2분 타임아웃으로 에러날 경우 100프로 안만듬
        $rootScope.progress = 0;

        timer = setInterval(() => {
          if ($rootScope.progress <= 99) {
            $rootScope.progress += 1.2;
          }

          $scope.$apply();
        }, 1000);

        const res = await insightModel.addInsightProd(addParams);

        if (res.data.results === 'success') {
          $rootScope.Progress = 100;

          clearInterval(timer);
          delete $rootScope.progress;
          loading.close();

          $uibModalInstance.close();
        }

      } catch (err) {
        if (loading) {
          loading.close();
        }

        if (timer) {
          clearInterval(timer);
        }

        if (err.data?.error === 'duplicate') {
          commonSVC.showMessage($scope.type === 'my' ? '동일한 내 상품이 이미 등록되어 있습니다.' : '동일한 비교상품이 이미 등록되어 있습니다.');
          $uibModalInstance.close();

          return false;
        } else if (err.data?.error === 'invalid') { //invalid => 지원하지 않는 쇼핑몰입니다. / 상품 ID를 식별할 수 없습니다. / 올바른 URL 형식을 입력해주세요. (빌트온에서 발생하는 에러)
          commonSVC.showMessage('해당 상품 가격정보 확인이 어렵습니다.\n쇼핑몰에서 정상적으로 판매중인지 확인 후 재시도해주세요.');
          $uibModalInstance.close();

          return false;
        } else if (err.data?.error === 'adult only') {
          commonSVC.showMessage('해당 상품은 미성년자 구매불가 상품으로 등록할 수 없습니다.');
          $uibModalInstance.close();

          return false;
        }

        const modal = commonSVC.openModal('mini', { data: {} }, 'confirmCtrl', 'views/summary/modals/insight_fail.html');

        modal.result.then(function () {
          $uibModalInstance.close();
        });
      }

    };

    /**
       * 창 닫기
       */
    $scope.close = function () {
      $uibModalInstance.close();
    };

    $scope.checkValidUrl = (type) => {
      if (type === 'input') {
        if (!$scope.input.checkShopCd) {
          $scope.urlDisabled = true;

          return false;
        }
        let matchUrl;

        for (const url of $scope.shopDetailUrl[$scope.input.checkShopCd]) {
          const regex = new RegExp(`^(https?:\\/\\/)?${url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}([^\\s?#]*)?(\\?[^\\s#]*)?(#[^\\s]*)?$`);

          if (regex.test($scope.input.shopSaleUrl)) {
            matchUrl = url;
          }
        }

        if (matchUrl) {
          $scope.urlDisabled = false;
        } else {
          $scope.urlDisabled = true;
        }
      } else {
        $scope.input.shopSaleUrl = '';
      }
    };

    $scope.chkSubmit = () => {
      if ($scope.type === 'another') {
        return !$scope.input.shopSaleUrl || !$scope.input.checkShopCd || $scope.urlDisabled;
      } else {
        return !$scope.chkValid;
      }
    };

    /**
     * 테이블에서 선택 변경 시
     */
    $scope.$on('OnSelectChangeBefore', async function(event, data) {
      const { selectData, selectDataSelected } = data;

      try {
        // 쿠팡 상품 선택 시 옵션 불러오기 함
        if (selectDataSelected && $scope.selectedOlShopNo !== selectData.ol_shop_no) {
          $scope.chkOptSelect = selectData.pa_shop_cd === 'B378' || (selectData.pa_shop_cd === 'A077' && selectData.std_ol_yn);

          if ($scope.chkOptSelect) {
            $('.box-loading').removeClass('hidden'); //로딩중

            $scope.optDatas = (await insightModel.getOnlineOpts({ ol_shop_no: selectData.ol_shop_no })).data.results;
            $scope.selectedShopCd = selectData.pa_shop_cd;
            $('.box-loading').addClass('hidden'); //로딩중
            $scope.chkValid = false;
          } else {
            $scope.optDatas = [];
            $scope.chkValid = true;
          }
        } else {
          $scope.optDatas = [];

        }

        // 다른 선택 시 초기화
        $scope.selectedOptData = null;
        $scope.selectedOptIdx = null;
        $scope.selectedOlShopNo = selectDataSelected ? selectData.ol_shop_no : null;

        $timeout(() => {});
      } catch (err) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showToaster('error', '실패', '옵션 조회에 실패하였습니다.');
      }
    });

    /**
       * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
       */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    $timeout(() => {});

  });