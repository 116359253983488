/**
 * Created by ally on 2017. 6. 28..
 */
'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentMappingCtrl',
    function (// common
      $rootScope, $scope, $filter, $uibModalInstance, gettextCatalog, $timeout,
      // info
      settings, userInfo,
      // SVC
      commonSVC,
      // Model
      supplierModel, warehouseModel, systemModel, shipmentModel, productModel, commonModel,
      // List
      warehouseList, systemList, selectList
    ) {
      const channelList = Array.from(new Set([
        ...$rootScope.useChannelList({ site_action: 'ScrapOrder' }),
        ...$rootScope.useChannelList({ func: shop => ['ORD', 'ORD_M'].includes(shop.shop_type) })
      ])).sort(o => o.no);

      $scope.isButtonDisabled = () => {
        return $rootScope.osse_sol && $scope.orderState === 'map_y';
      };

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(channelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      // 직접 입력 추가
      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      // 매핑여부 변수(1번이라도 하면 true)
      let isMapping = false;

      /* 검색 및 버튼 관련 변수 */
      $scope.order = {}; //주문 리스트 관련 변수
      $scope.orderData = {}; //선택한 주문데이터
      $scope.prod = {}; //SKU상품 리스트 관련 변수
      $scope.prodData = {}; //선택한 SKU상품데이터
      $scope.set = {}; //세트상품 리스트 관련 변수
      $scope.setData = {}; //선택한 세트상품데이터

      $scope.countList = { total: 0, map_y: 0, map_n: 0 }; //  카운트

      $scope.prodCountList = { prod: {}, set: {} }; // 매칭 수량 입력

      $scope.isModify = { name: [], cnt: [] };
      $scope.isModifyValue = { name: [], cnt: [] };

      $scope.isModal = selectList.uniq || false; // 모달, 논모달 구분
      $scope.orderState = $scope.isModal ? 'map_yn' : 'map_n'; // 주문 필터링 상태 ( 모달로 열렸을때 총매칭필요주문이 선택되어있어야 함. ) 2018-11-12 rony
      $scope.tab = 'prod'; //세트 상품, SKU상품 탭 구분
      $scope.isMatch = false; // 매칭 시 스피너 처리 위한 변수
      // 모달인 경우에만 스타일 추가
      if ($scope.isModal) {
        $scope.tableStyle = {
          ord: { 'height': '100%', 'overflow': 'hidden', 'overflow-y': 'auto' },
          prod: { 'height': '100%', 'overflow': 'hidden', 'overflow-y': 'auto' } }; //테이블 컨테이너 스타일
      }

      // const alreadyCheckTrigger = false; //로우 1개일때 trigger로 체크여부

      // 출고완료전 주문상태
      const beforeUnstoringState = ['신규주문', '주문보류', '출고대기', '출고보류'];

      let selectProdList = [];
      $scope.selectedCount = 0;

      /*****************************
       *    주 문 상 품 DataTable     *
       * ***************************/
      const order_search = {
        searchForm: {
          sdate: selectList.wdate || moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          date_type: 'wdate',
          search_key: 'all',
          search_word: '',
          shop_id: '',
          shop_cd: '',
          warehouse_name: '',
          map_yn: $scope.isModal ? '' : 'n',
          status: '',
          uniq: selectList.uniq || '',
          pageFrom: 'prodMatch'
        },
        searchData: {
          searchAreafull: true,
          test_id: 'drop-search-date',
          haveBoxContent: false,
          showDetailSearchArea: true,
          searchDateShow: false,
          all_checked: false,  // 전체선택 여부
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), test_id: 'btn-select-all', value: 'all' },
            { label: gettextCatalog.getString('주문번호'), test_id: 'btn-shop-ord-no', value: ['shop_ord_no'] },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), test_id: 'btn-shop-sale-no', value: ['shop_sale_no'] },
            { label: gettextCatalog.getString('상품명, 옵션명, 추가구매옵션'), test_id: 'btn-shop-name', value: ['ord_opt_name', 'shop_sale_name', 'shop_opt_name', 'shop_add_opt_name'] },
            { label: gettextCatalog.getString('SKU코드'), test_id: 'btn-sku-cd', value: ['sku_cd'] },
            { label: gettextCatalog.getString('세트코드'), test_id: 'btn-set-no', value: ['set_cd'] },
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), test_id: 'btn-order-select', value: 'wdate' },
            { label: gettextCatalog.getString('결제 완료일'), test_id: 'btn-pay-complete', value: 'pay_time' }
          ]
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            test_id: 'drop-select-shop',
            search_name: 'shop_cd',
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            test_id: 'drop-select-account',
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.order.searchForm.shop_cd && option.shop_id : _.intersection([$scope.order.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            }
          },
          {
            title: gettextCatalog.getString('주문상태'),
            test_id: 'drop-order-state',
            search_name: 'status',
            item_list: [{ status: '신규주문' }, { status: '주문보류' }, { status: '출고대기' }, { status: '출고보류' }, { status: '운송장출력' }, { status: '출고완료' }, { status: '배송중' }, { status: '배송완료' }, { status: '구매결정' }],
            item_key: 'status',
            item_value: 'status',
            select_value: ''
          }
        ]
      };

      $scope.order.searchData = angular.copy(order_search.searchData);
      $scope.order.searchForm = angular.copy(order_search.searchForm);
      $scope.order.searchDetail = angular.copy(order_search.searchDetail);
      $scope.order.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true, 'order');
        },
        resetDo: function() {
          $scope.resetDo('order');
        },
        changeLen: function(count) {
          $scope.changeCount('order', count);
        }
      };
      $scope.order.methods = {};
      $scope.order.options = {
        modal: $scope.isModal,
        pinningColumns: [],
        alignCenterColumns: ['memo', 'ord_status', 'shop_sale_no', 'opt_sale_cnt', 'pack_unit', 'total_pack_unit'],
        alignRightColumns: [],
        defaultSortingColumns: ['wdate'],
        notSortingColumns: ['memo', 'prod_code', 'total_pack_unit', 'notice_msg'],
        notResizingColumns: ['memo'],
        notMovingColumns: [],
        notVisibleColumns: ['memo', 'depot_name'],
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'bundle_no',
          bundleUniqKey: 'uniq'
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/matching-option-list`,
          requestWillAction: function(data) {
            data = angular.merge({}, data, $scope.order.searchForm);

            // 배송처일 때 해당 배송처 데이터만 검색되도록 수정 #gmpkr-7591 2019-09-18 Jacob
            if ($rootScope.user_profile.auth_type === '배송처') {
              data.delivery_vendor = $rootScope.user_profile.depot_no;
            }

            return data;
          },
          requestDidAction: function(result) {
            $scope.order.searchData.totalCount = $scope.order.searchForm.map_yn ? $scope.order.searchForm.map_yn === 'y' ? result.map_y : result.map_n : result.total;
            $scope.countList.total = result.total;
            $scope.countList.map_n = result.map_n;
            $scope.countList.map_y = result.map_y;

            return result.results;
          }
        },
        columns: [
          {
            key: 'memo',
            title: '메모',
            width: 50,
            template: function(row) {
              // 메모가 있는경우 primary 컬러로 변경
              return `<button ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5" ng-click="grid.appScope.openOrderCS('${row.uniq}')">메모</button>`;
            }
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 80,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 100,
            tooltip: '스마트스토어 그룹상품인 경우 그룹 상품번호가 노출됩니다',
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 270,
            template: (row) => {
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);
              const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
              const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';

              return `<span>${originOrderSpan}${dividedOrderSpan}<a ng-click="grid.appScope.showOrderDetail('${row.uniq}', ${row.pa_shop_cd?.[0] === 'X'})">${filteredShopSaleName}</a></span>`;
            }

          },
          {
            key: 'opt_name',
            title: '옵션',
            width: 200,
            notCompile: true,
            template: function(row) {
              const opt_type = row.shop_opt_name ? '[옵션]  ' : row.ord_opt_name ? '[추가구매옵션]  ' : '';
              const opt_name = row.shop_opt_name ? row.shop_opt_name : row.ord_opt_name ? row.ord_opt_name : '';

              return `${opt_type + opt_name}`;
            }
          },
          {
            key: 'opt_sale_cnt',
            title: '주문 수량',
            width: 80,
            notCompile: true,
            template: function(row) {
              return `${row.opt_sale_cnt}`;
            }
          },
          {
            key: 'prod_code',
            title: 'SKU(세트)코드',
            width: 100,
            template: function(row) {
              const sku_list = row.sku_cd ? row.sku_cd.split(';') : [];
              const cd_type = row.set_no ? '[세트코드]  ' : row.sku_cd ? sku_list.length > 1 ? `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="grid.appScope.showMultiSKUDetail('${row.uniq}', ${row.ord_opt_seq})">다중</button> ` : '[SKU 코드]  ' : '';
              const cd = row.set_no ? row.set_cd : sku_list.length ? sku_list[0] : '';

              return cd_type + cd + (!row.set_no && sku_list.length > 1 ? ` 외 ${sku_list.length - 1}건` : '');
            }
          },
          {
            key: 'prod_name',
            title: 'SKU(세트)상품명',
            width: 200,
            template: function(row) {
              const sku_cnt = row.sku_cd ? row.sku_cd.split(';').length : 0;

              return row.set_no ? row.set_name : row.sku_cd ? row.prod_name + (sku_cnt > 1 ? `  외 ${sku_cnt - 1}건` : '') : '';
            }
          },
          {
            key: 'attri',
            title: '속성',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.attri || '';
            }
          },
          {
            key: 'pack_unit',
            title: '출고수량',
            width: 80,
            template: function(row) {
              return row.set_no || !!row.sku_cd ? `${row.pack_unit}` : '';
            }
          },
          {
            key: 'total_pack_unit',
            title: '총출고수량',
            width: 80,
            template: function(row) {
              return row.set_no || !!row.sku_cd ? `${row.opt_sale_cnt * row.pack_unit}` : '';
            }
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 80
          },
          {
            key: 'notice_msg',
            title: '기타메세지',
            width: 80
          }
        ]
      };

      /*****************************
       *    기 초 상 품 DataTable     *
       * ***************************/
      const prod_search = {
        searchForm: {
          sdate: '',
          edate: '',
          search_word: '',                                                //검색어
          date_type: 'wdate',
          search_key: 'all',                                              //검색구분
          category: 'all',                                                //카테고리
          supplier_vendor: 'all',                                         //매입처
          delivery_vendor: 'all',                                         //배송처
          linkage_flag: 'all',                                            //연동상품여부
          tax_type: 'all',                                                //과세여부
          recently_prod: 'all',                                           //신규상품(최근 일주일)
          safe_stock_alert: false,                                        //재고부족
          real_stock_none: false,                                         //판매불가
          group_prod: false,                                              //그룹상품
          multi_search_word: '',                                          //멀티서치워드,
          multi_type: 'sku_cd',                                           //멀티서치 타입
          is_global: selectList.isGlobal                                  //해외주문 여부
        },
        searchData: {
          searchAreaClass: 'col-xs-9',
          searchAreafull: false,
          showDateSearch: false,
          haveBoxContent: false,
          showDetailSearchArea: true,
          all_checked: false,  // 전체선택 여부
          totalCount: 0,       // 검색전체 건수

          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: 'all' },
            { label: gettextCatalog.getString('SKU코드'), value: ['sku_cd'] },
            { label: gettextCatalog.getString('재고관리코드'), value: ['stock_cd'] },
            { label: gettextCatalog.getString('SKU상품명'), value: ['prod_name'] },
            { label: gettextCatalog.getString('속성명'), value: ['attri'] },
            { label: gettextCatalog.getString('바코드'), value: ['barcode'] },
            { label: gettextCatalog.getString('모델명'), value: ['model'] }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          ],
          ...(!selectList.isGlobal && { customSearchBtn: {
            title: '상품코드 기준 검색',
            tooltip: '선택한 주문의 상품이 솔루션에 존재하는 경우 쇼핑몰 상품코드에 매칭된 SKU상품 리스트를 검색합니다.',
            class: 'btn btn-primary',
            action: function() {
              $scope.standardSearch();
            },
          } })
        },
        searchDetail: [
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            test_id: 'drop-select-delivery',
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: '',
            add_class: 'select-search'

          },
          {
            title: gettextCatalog.getString('상태'),
            search_name: 'stock_status',
            item_list: [{ state: '정상', value: '정상' }, { state: '품절', value: '품절' }, { state: '재고부족', value: '재고부족' }],
            item_key: 'state',
            item_value: 'value',
            select_value: ''
          }
        ]
      };

      // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
      if ($rootScope.user_profile.auth_type === '배송처') {
        const searchIndex = _.findIndex(prod_search.searchDetail, { search_name: 'delivery_vendor' });

        if (searchIndex > -1) {
          prod_search.searchDetail.splice(searchIndex, 1);
        }
      }

      $scope.prod.searchData = angular.copy(prod_search.searchData);
      $scope.prod.searchForm = angular.copy(prod_search.searchForm);
      $scope.prod.searchDetail = angular.copy(prod_search.searchDetail);
      $scope.prod.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true, 'prod');
        },
        resetDo: function() {
          $scope.resetDo('prod');
        },
        changeLen: function(count) {
          $scope.changeCount('prod', count);
        }
      };

      $scope.prod.methods = {};
      $scope.prod.options = {
        modal: $scope.isModal,
        pinningColumns: ['widget'],
        alignCenterColumns: ['widget', 'pack_unit', 'stock_cnt'],
        alignRightColumns: [],
        defaultSortingColumns: ['wdate'],
        notSortingColumns: [ 'widget', 'pack_unit', 'stock_cnt', 'depot_name', 'bundle_avail_yn', 'stock_cd', 'stock_status'],
        notResizingColumns: ['widget'],
        notMovingColumns: [],
        notVisibleColumns: ['wdate', 'stock_status'],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/stock/matching-list-of-base`,
          requestWillAction: function(data) {
            data = angular.merge({}, data, $scope.prod.searchForm);

            data.sol_no = $rootScope.user_profile.sol_no;

            // 배송처일 때 해당 배송처 내용만 검색되도록 수정 #gmpkr-7591 2019-09-18 Jacob
            if ($rootScope.user_profile.auth_type === '배송처') {
              data.delivery_vendor = $rootScope.user_profile.depot_no;
            }

            // 선택된 항목에 있지만 선택 해제한 row 처리
            selectProdList = selectProdList.filter(prod => {
              if ($scope.prodData.find(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no)) {
                return $scope.prod.methods.selectedData('all').find(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no);
              } else {
                return true;
              }
            });

            const prodList = $scope.prod.methods.selectedData('all');
            // 출고수량 저장
            prodList.forEach(p => p.pack_unit = $scope.prodCountList.prod[`${p.prod_no}_${p.depot_no}`]);

            // 현재 선택된 row selectProdList에 추가
            selectProdList.push(...prodList);

            // selectProdList 중복 제거
            selectProdList = selectProdList.filter((prod, idx, arr) => arr.findIndex(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no) === idx);

            return data;
          },
          requestDidAction: function(result) {
            $scope.prod.searchData.totalCount = result.recordsTotal;

            // 선택된 row 선택 처리
            result.results.forEach((row) => {
              const matchProd = selectProdList.find(prod => prod.prod_no === row.prod_no && prod.depot_no === row.depot_no);

              row.isSelected = !!matchProd;

              // 출고수량 반영
              $scope.prodCountList.prod[`${row.prod_no}_${row.depot_no}`] = matchProd?.pack_unit ? matchProd.pack_unit : 1;
            });
            $scope.prodData = angular.copy(result.results);

            // 현재 페이지에 있는 row 제거
            selectProdList = selectProdList.filter(prod => !result.results.find(row => prod.prod_no === row.prod_no && prod.depot_no === row.depot_no));
            // 현재 페이지에 없는 선택된 row의 수
            $scope.selectedCount = selectProdList.length;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 50,
            template: function(row) {
              return `<button class="btn btn-primary btn-xxs mr-5" ng-disabled="grid.appScope.isButtonDisabled()" ng-click="grid.appScope.mappingDo('prod', ${row.prod_no}, ${row.depot_no})" >매칭</button>`;
            }
          },
          {
            key: 'wdate',
            title: '등록일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'sku_cd',
            title: 'SKU코드',
            width: 130
          },
          {
            key: 'prod_name',
            title: 'SKU상품명',
            width: 350,
            template: (row) => {

              return `<span><img src='${row.prod_img || '/assets/images/upload.png'}' width='20' height='20' class='mr-5'></img><a ng-click="grid.appScope.showDetail('${row.sku_cd}','${row.prod_no}')" >${row.prod_name}</a></span>`;
            }
          },
          {
            key: 'attri',
            title: '속성',
            width: 100
          },
          {
            key: 'pack_unit',
            title: '출고수량 입력',
            tooltip: {
              text: '선택한 SKU상품(또는 세트상품)이 주문수량 1개당 출고되어야 할 수량을 입력합니다.',
              placement: 'left'
            },
            width: 100,
            template: function(row) {
              return `<input type="number" min="1" class="form-control input-xs2" name="prod_pack_unit" id="${row.prod_no}_prod_pack_unit"` +
                `ng-model="grid.appScope.prodCountList.prod['${row.prod_no}_${row.depot_no}']">`;
            }
          },
          {
            key: 'stock_cnt',
            title: '판매가능재고',
            width: 150,
            notCompile: true,
            template: function(row) {
              row.stock_cnt = row.stock_cnt ? row.stock_cnt : 0;

              return `${$filter('currency')(row.stock_cnt, '', 0)}`;
            }
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 150
          },
          {
            title: '합포장 가능여부',
            key: 'bundle_avail_yn',
            width: 100,
            template: function (row) {
              return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
            }
          }, {
            key: 'stock_cd',
            title: '재고관리코드',
            width: 150
          },
          {
            key: 'stock_status',
            title: '상태',
            width: 100
          }
        ]
      };
      /*****************************
       *    세 트 상 품 DataTable     *
       * ***************************/
      const set_search = {
        searchForm: {
          sdate: '',
          edate: '',
          date_type: 'wdate',                                             //검색기간 구분
          search_word: '',                                                //검색어
          search_key: 'all',                                              //검색구분
          category: 'all',                                                //카테고리
          supplier_vendor: 'all',                                         //매입처
          delivery_vendor: 'all',                                         //배송처
          linkage_flag: 'all',                                            //연동상품여부
          tax_type: 'all',                                                 //과세여부
          recently_prod: 'all',                                             //신규상품(최근 일주일)
          safe_stock_alert: false,                                         //재고부족
          real_stock_none: false,                                           //판매불가
          group_prod: false,                                                //그룹상품
          multi_search_word: '', //멀티서치워드,
          multi_type: 'sku_cd', //멀티서치 타입
        },
        searchData: {
          searchAreaClass: 'col-xs-6',
          searchAreafull: false,
          showDateSearch: false,
          haveBoxContent: false,
          showDetailSearchArea: true,
          all_checked: false,  // 전체선택 여부
          totalCount: 0,       // 검색전체 건수

          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: 'all' },
            { label: gettextCatalog.getString('세트코드'), value: ['set_cd'] },
            { label: gettextCatalog.getString('세트상품명'), value: ['set_name'] },
            { label: gettextCatalog.getString('SKU코드'), value: ['sku_cd'] },
            { label: gettextCatalog.getString('SKU상품명'), value: ['prod_name'] },
            { label: gettextCatalog.getString('메모'), value: ['memo'] }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          ],
          ...(!selectList.isGlobal && { customSearchBtn: {
            title: '상품코드 기준 검색',
            tooltip: '선택한 주문의 상품이 솔루션에 존재하는 경우 쇼핑몰 상품코드에 매칭된 SKU상품 리스트를 검색합니다.',
            class: 'btn btn-primary',
            action: function() {
              $scope.standardSearch();
            },
          } })
        },
        searchDetail: [
          {
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: '',
            add_class: 'select-search'

          },
          {
            title: gettextCatalog.getString('상태'),
            search_name: 'set_status',
            item_list: [{ state: '정상', value: '정상' }, { state: '품절', value: '품절' }, { state: '사용불가', value: '사용불가' }, { state: '재고부족', value: '재고부족' }],
            item_key: 'state',
            item_value: 'value',
            select_value: ''
          }]

      };

      // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
      if ($rootScope.user_profile.auth_type === '배송처') {
        const searchIndex = _.findIndex(set_search.searchDetail, { search_name: 'delivery_vendor' });

        if (searchIndex > -1) {
          set_search.searchDetail.splice(searchIndex, 1);
        }
      }
      $scope.set.searchData = angular.copy(set_search.searchData);
      $scope.set.searchForm = angular.copy(set_search.searchForm);
      $scope.set.searchDetail = angular.copy(set_search.searchDetail);
      $scope.set.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true, 'set');
        },
        resetDo: function() {
          $scope.resetDo('set');
        },
        changeLen: function(count) {
          $scope.changeCount('set', count);
        }
      };

      //세트 데이터 테이블
      $scope.set.methods = {};
      $scope.set.options = {
        modal: $scope.isModal,
        pinningColumns: ['widget'],
        alignCenterColumns: ['widget', 'set_no', 'pack_unit', 'stock_status'],
        alignRightColumns: [],
        defaultSortingColumns: ['wdate'],
        notSortingColumns: ['widget', 'pack_unit'],
        notResizingColumns: ['widget'],
        notMovingColumns: [],
        notVisibleColumns: [],
        selectOptions: {
          checkbox: false,
          multiSelect: false
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/stock/set/list`,
          requestWillAction: function(data) {
            data = angular.merge({}, data, $scope.set.searchForm);

            // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
            if ($rootScope.user_profile.auth_type === '배송처') {
              data.delivery_vendor = $rootScope.user_profile.depot_no;
            }

            return data;
          },
          requestDidAction: function(result) {
            $scope.set.searchData.totalCount = result.recordsTotal;
            _.each(result.results, function(data) {
              $scope.prodCountList.set[data.set_no] = 1;
            });
            $scope.setData = angular.copy(result.results);

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 50,
            template: function(row) {
              return `<button class="btn btn-primary btn-xxs mr-5" ng-disabled="grid.appScope.isButtonDisabled()" ng-click="grid.appScope.mappingDo('set',${row.set_no})">매칭</button>`;
            }
          },
          {
            key: 'wdate',
            title: '등록일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'set_cd',
            title: '세트코드',
            width: 150,
            notCompile: true,
          },
          {
            key: 'set_name',
            title: '세트상품명',
            width: 350,
            template: (row) => {

              return `<span><a ng-click="grid.appScope.showSetDetails('${row.set_no}')" >${row.set_name}</a></span>`;
            }
          },
          {
            key: 'set_prod_cnt',
            title: '세트구성 상품 수',
            width: 100
          },
          {
            key: 'pack_unit',
            title: '출고수량 입력',
            tooltip: {
              text: '선택한 SKU상품(또는 세트상품)이 주문수량 1개당 출고되어야 할 수량을 입력합니다.',
              placement: 'left',
              forView: $rootScope.userProfileCheck('sol_ser', 'smile', 'like')
            },
            width: 100,
            template: function(row) {
              return `<input type="number" min="1" class="form-control input-xs2" name="set_pack_unit" id="${row.set_no}_set_pack_unit"` +
                `ng-model="grid.appScope.prodCountList.set['${row.set_no}']">`;
            }
          },
          {
            key: 'stock_status',
            title: '상태',
            width: 100,
            tooltip: {
              text: '품절: 세트 판매수량 = 0 또는 SKU상품 판매가능재고 = 0인 경우/ 재고부족: SKU상품이 0 < 판매가능재고 <= 안전재고인 경우\n사용불가: 세트에 속한 SKU상품 중 일부의 배송처가 변경된 경우',
              placement: 'left',
              forView: !$rootScope.userProfileCheck('sol_ser', 'smile', 'like')
            },
            notCompile: true,
            template: function(row) {
              const status = row.not_used ? '사용불가' : row.stock_status ? '품절' : row.set_status;

              return status;
            }
          },
          {
            key: 'delivery_vendor_name',
            title: '배송처',
            width: 150,
            notCompile: true
          }
        ]
      };

      /**********************************
       *  action
       *********************************/

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, type) {
        $scope[type].methods.reloadData(function () {}, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function(type) {
        const sf = type === 'order' ? order_search.searchForm : type === 'set' ? set_search.searchForm : prod_search.searchForm;
        const sd = type === 'order' ? order_search.searchDetail : type === 'set' ? set_search.searchDetail : prod_search.searchDetail;

        if (type === 'order') {
          sf.map_yn = $scope[type].searchForm.map_yn;
        }
        $scope[type].searchForm = angular.copy(sf);
        $scope[type].searchData.search_key_name = '전체';
        $scope[type].searchDetail = angular.copy(sd);

        // 선택된 값들 선택 해제
        // 더스킨 요청으로 인해 초기화인 경우만 선택항목 초기화(문의번호 : 339507)
        if (type === 'prod') {
          selectProdList = [];
          $scope.selectedCount = 0;
          $scope.prod.methods.unSelectByFilter(function (r) {
            return true;
          }, false);
        }
        $scope.searchDo(true, true, type);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function (type) {
        $scope[type].methods.length($scope[type].searchData.showCount);
      };

      /**
       * 카운트 버튼
       */
      $scope.countSearch = function(type) {
        if (type === 'map_n') {
          $scope.order.searchForm.map_yn = 'n';
        } else if (type === 'map_y') {
          $scope.order.searchForm.map_yn = 'y';
        } else if (type === 'map_yn') {
          $scope.order.searchForm.map_yn = '';
        }

        $scope.orderState = type;
        $scope.searchDo(true, true, 'order');
      };

      /**
       * view에서 보여줄 옵션명
       */
      $scope.getOpt = function(opt1, opt2, opt3) {
        return _.filter([opt1, opt2, opt3], function(n) {
          return n;
        }).join(',');
      };

      /**
       * 매칭 삭제
       * */
      $scope.deleteMatching = function() {
        const selectList = $scope.order.methods.selectedData('all');
        // var matchedOrd = _.reject(selectList, ['sku_cd', null]);  // 매칭된 주문이 있는것만
        const matchedOrd = _.filter(selectList, function(o) {
          return o.sku_cd !== null || o.set_no !== null;
        });

        if (selectList.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        if (!matchedOrd.length) {
          commonSVC.showMessage(gettextCatalog.getString('매칭된 주문이 없습니다.'));

          return false;
        }

        commonSVC.showConfirm(gettextCatalog.getString('선택한 주문의 SKU상품 매칭정보를 삭제하시겠습니까? '), '', function () {
          const opts = [];

          _.each(matchedOrd, function(re) {
            opts.push({ pa_shop_cd: re.pa_shop_cd, ord_opt_seq: re.ord_opt_seq, uniq: re.uniq, sku_cd: re.sku_cd, set_no: re.set_no, set_cd: re.set_cd, ...(re.pa_shop_cd.startsWith('X') && { sale_cnt: re.sale_cnt, opt_sale_cnt: re.opt_sale_cnt }) });
          });
          shipmentModel.delete({ uniqList: _.map(opts, 'uniq'), optionProdDelete: true, optionList: opts }, function (state, result) {
            if (state == 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('매칭된 SKU상품이 삭제되었습니다.'));
              $scope.searchDo(true, false, 'prod');
              $scope.searchDo(false, false, 'order');

              isMapping = true;
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('매칭된 SKU상품 삭제에 실패하였습니다.'));
            }
          });
        });
      };

      /**
       * 주문상세페이지 보여주기
       */
      $scope.showOrderDetail = function(uniq, isGlobal) {
        const resolve = {
          data: {
            fromPage: 'order',
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };
        let modal;
        if (isGlobal) {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
        } else {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
        }
        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo();
          }
        });
      };

      /**
       * 주문메모 등록 모달 열기
       * */
      $scope.openOrderCS = function(uniq) {
        const resolve = {};

        resolve.data = {
          uniq: uniq,
          type: 'ord',
          code: 'uniq',
        };
        const csModal = commonSVC.openModal('', resolve, 'memoCtrl', 'views/etc/memo.html');

        csModal.result.then(function() {
          //$scope.searchDo('order',true);
        });
      };

      /**
       * SKU상품등록 모달열기
       * 2018-05-17 rony
       * */
      $scope.openProdAdd = function() {

        // SKU상품등록 권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission) === false) {
          return false;
        }

        const resolve = {
          data: {
            // sku : sku_cd,
            // number : prod_number,
            detailType: 'add',
            openModal: true
          }
        };

        resolve.supplierList = function() {
          return supplierModel.ListAll({ use_yn: true });
        };
        resolve.warehouseList = function() {
          return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
        };
        resolve.madeinList = function() {
          return productModel.getMadein();
        };
        resolve.systemList = function() {
          return systemModel.load();
        };
        const modal = commonSVC.openModal('full', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

        modal.result.then(function() {
          $scope.searchDo(true, false, 'prod');
        });
      };

      /**
       * 세트상품생성 모달열기
       * 2018-05-17 rony
       * */
      $scope.openProdSet = function() {
        const modal = commonSVC.openModal('full', { data: {} }, 'addSetProdCtrl', 'views/prod/set/modals/add_set_prod.html');

        modal.result.then(function() {
          $scope.searchDo(true, false, 'set');
        });
      };

      /**
       * 세트 상세보기
       */
      $scope.showSetDetails = function(set_no) {
        const resolve = {
          data: { from: '수정', set_no: set_no }
        };

        commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
      };

      /**
       * 탭닫기
       */
      $scope.closeTab = function() {
        $rootScope.$emit('$closeTab', 'tab_order_shipment_mapping');
      };

      /**
       * 상품 상세페이지 모달창
       */
      $scope.showDetail = function(sku_cd, prod_number) {

        // SKU상품수정 권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
          return false;
        }

        const resolve = {
          data: {
            sku: sku_cd,
            number: prod_number,
            detailType: 'edit'
          }
        };

        resolve.supplierList = function() {
          return supplierModel.ListAll({ use_yn: true });
        };
        resolve.warehouseList = function() {
          return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
        };
        resolve.madeinList = function() {
          return productModel.getMadein();
        };
        resolve.systemList = function() {
          return systemModel.load();
        };
        commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');
      };

      /**
       * 매칭
       */
      $scope.mappingDo = function(type, no, depot_no = 0) {
        const ord_selected = $scope.order.methods.selectedData('all');
        if (ord_selected.length === 0) {
          commonSVC.showMessage('매칭할 주문을 선택해주세요');

          return false;
        }

        const dataNone = _.filter(ord_selected, { map_yn: 1 });

        if (dataNone.length && $rootScope.osse_sol) {
          if (dataNone.length === ord_selected.length) {
            commonSVC.showMessage('SKU상품이 매칭된 주문은 매칭 수정이 불가합니다.');
          } else {
            commonSVC.showConfirmCustom({
              title: 'SKU상품매칭이 불가능한 주문건이 포함되어 있습니다.',
              text: 'SKU상품 [미매칭] 주문만 매칭이 가능합니다.\n매칭이 가능한 주문건만 선택한 후 작업을 재시도 해주십시오.',
              confirmButtonText: 'SKU상품 매칭 가능한 주문건만 선택'
            }, () => {
              $scope.order.methods.unSelectByFilter(function (r) {
                return r.map_yn;
              }, true);
            });
          }

          return false;
        }
        // sku 상품인경우 다중배송처 이슈로 인해 prod_no + depot_no 를 키로 잡음.
        if (type === 'prod') {
          no = `${no}_${depot_no}`;
        }

        if (!$scope.prodCountList[type][no]) {
          commonSVC.showMessage('출고수량을 입력해주세요.');

          return false;
        }

        if ($scope.prodCountList[type][no] < 1 || $scope.prodCountList[type][no] > 9999) {
          commonSVC.showMessage('출고수량은 1 이상 9999 이하의 숫자만 입력 가능합니다.');

          return false;
        }

        const prod_selected = _.find($scope[`${type}Data`], function (d) {
          if (depot_no) {
            return d[`${type}_no`] === parseInt(no.split('_')[0]) && d.depot_no === depot_no;
          } else {
            return d[`${type}_no`] === no;
          }
        });

        // 주문 배송처와 sku 상품 배송처가 다를 경우, 매칭 실패
        const dupOrdList = {};

        // 같은 uniq 주문일 경우 하나로 묶음
        for (const ordIndex in ord_selected) {
          if (!dupOrdList[ord_selected[ordIndex].uniq]) {
            dupOrdList[ord_selected[ordIndex].uniq] = { ordInfo: ord_selected[ordIndex], indexes: [ord_selected[ordIndex].ord_opt_seq] };
          }
          else {
            dupOrdList[ord_selected[ordIndex].uniq].indexes.push(ord_selected[ordIndex].ord_opt_seq);
          }
        }

        // 주문과 세트(sku)상품 매칭 가능한지 체크 -> 조건에 맞지 않을 경우 매칭 실패
        for (const uniq in dupOrdList) {
          if (dupOrdList[uniq].ordInfo.ord_depot_group) {
            const ordDepotGroup = dupOrdList[uniq].ordInfo.ord_depot_group.split(',');
            const checkDepotGroup = ordDepotGroup.filter(depot_no => !dupOrdList[uniq].indexes.some(checkordIndex => depot_no.split('-')[0] === String(checkordIndex))).map(depot_no => depot_no.split('-')[1]);
            const checkMatchingProd = checkDepotGroup.some(depot_no => depot_no && depot_no != String(prod_selected.depot_no));

            if (checkMatchingProd) {
              commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

              return false;
            }
          }
        }

        /**
         * 2018-07-18 Daniel
         * G마켓물류주문 발송요청 대기, 발송요청 실패, 발송정보 수정대기 일때 다른배송처 막음
         */
        const stockMatchEbaydepotState = ['발송요청 대기', '발송요청 실패', '발송정보 수정대기'];
        const notSameEbayDepot = _.filter(ord_selected, function (ord) {
          if (ord.depot_no && (ord.depot_no !== prod_selected.depot_no && _.indexOf(stockMatchEbaydepotState, ord.ebaydepot_link_status) >= 0)) {
            return true;
          }
        });

        if (notSameEbayDepot.length) {
          commonSVC.showMessage('이미 발송정보가 전송되었으므로, 배송처 수정이 불가합니다.\n해당 주문을 발송정보 삭제 전송하신 후 SKU상품/배송처를 수정하시기 바랍니다.');

          return false;
        }

        /**
         * 2018-04-13 Daniel
         * 출고완료 이상일시 매칭시 다른배송처 막음
         */
        const stockMatchState = ['결제완료', '신규주문', '주문보류', '출고대기', '출고보류', '운송장출력'];
        const notSameDepot = _.filter(ord_selected, function (ord) {
          if (ord.depot_no && (ord.depot_no !== prod_selected.depot_no && _.indexOf(stockMatchState, ord.ord_status) < 0)) {
            return true;
          }
        });

        if (notSameDepot.length) {
          commonSVC.showMessage('주문에 지정된 배송처와 매칭할 SKU상품에 설정된 배송처가 다릅니다.\n주문에 지정된 배송처의 SKU상품으로 매칭하시기 바랍니다.');

          return false;
        }

        /**
         * 합포장 불가인 상품 매칭 시 막음
         * 주문에 매칭된 주문이 없고 주문중 하나의 옵션만 선택된 경우 합포장 불가여도 매칭가능
         * 합포장 가능한 재고만 세트로 묶여 세트 상품인경우에도 가능
         */
        const cantBundle = ord_selected.filter((ord) => {
          if (ord.bundle_avail_yn) {
            /** 합포장 가능
             * 세트 또는 합포장 가능 상품 매칭
             * 또는 매칭된 SKU가 없거나 재고 매칭된 옵션이 1개이고 해당 옵션 수정 + 해당 작업 해당 주문에서 수정하는 옵션이 1개인경우
             * 가 아니면 실패처리
             */

            return !((prod_selected.set_cd || prod_selected.bundle_avail_yn) || ((!ord.map_yn || (ord.ord_opt_prod_seq_cnt === 1 && ord.opt_map_yn)) && ord_selected.filter(o => o.uniq === ord.uniq).length === 1));
          } else {
            /** 합포장 불가
             * 세트 또는 합포장 가능 상품 매칭이고 수정하는 주문중에 해당 주문의 재고매칭된 옵션이 있는 경우
             * 재고 매칭된 옵션이 1개이고 해당 옵션 수정 + 해당 작업 해당 주문에서 수정하는 옵션이 1개인경우
             * 가 아니면 실패처리
             */
            return !(((prod_selected.set_cd || prod_selected.bundle_avail_yn) && ord_selected.find(o => o.uniq === ord.uniq && o.opt_map_yn)) || (ord.ord_opt_prod_seq_cnt === 1 && ord.opt_map_yn && ord_selected.filter(o => o.uniq === ord.uniq).length === 1));
          }
        });

        if (cantBundle.length) {
          commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

          return false;
        }

        prod_selected.pack_unit = $scope.prodCountList[type][no];
        prod_selected.set_pack_unit = type === 'set' ? $scope.prodCountList[type][no] : null;

        const params = {
          matchType: type,
          orders: ord_selected,
          bases: [prod_selected]
        };

        mapping(params);
      };

      $scope.mappingDoMulti = () => {
        const ord_selected = $scope.order.methods.selectedData('all');
        const dataNone = _.filter(ord_selected, { map_yn: 1 });

        if (dataNone.length && $rootScope.osse_sol) {
          if (dataNone.length === ord_selected.length) {
            commonSVC.showMessage('SKU상품이 매칭된 주문은 매칭 수정이 불가합니다.');
          } else {
            commonSVC.showConfirmCustom({
              title: 'SKU상품매칭이 불가능한 주문건이 포함되어 있습니다.',
              text: 'SKU상품 [미매칭] 주문만 매칭이 가능합니다.\n매칭이 가능한 주문건만 선택한 후 작업을 재시도 해주십시오.',
              confirmButtonText: 'SKU상품 매칭 가능한 주문건만 선택'
            }, () => {
              $scope.order.methods.unSelectByFilter(function (r) {
                return r.map_yn;
              }, true);
            });
          }

          return false;
        }
        // 선택했던 값들 출고수량 추가
        selectProdList.forEach(prod => $scope.prodCountList.prod[`${prod.prod_no}_${prod.depot_no}`] = prod.pack_unit);
        // 현재페이지 선택값 + 기존 선택값
        const prod_selected = selectProdList.concat($scope.prod.methods.selectedData('all'));

        if (ord_selected.length === 0) {
          commonSVC.showMessage('매칭할 주문을 선택해주세요');

          return false;
        }

        if (prod_selected.length === 0) {
          commonSVC.showMessage('매칭할 상품을 선택해주세요');

          return false;
        }

        if (prod_selected.length > 1 && prod_selected.some(prod => !prod.bundle_avail_yn)) {
          commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

          return false;
        }

        if (prod_selected.length > 1 && prod_selected.map(prod => prod.depot_no).filter((depot, idx, list) => list.indexOf(depot) === idx).length !== 1) {
          commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

          return false;
        }

        if (prod_selected.some(prod => !$scope.prodCountList.prod[`${prod.prod_no}_${prod.depot_no}`])) {
          commonSVC.showMessage('출고수량을 입력해주세요.');

          return false;
        }

        if (prod_selected.some(prod => $scope.prodCountList.prod[`${prod.prod_no}_${prod.depot_no}`] < 1 || $scope.prodCountList.prod[`${prod.prod_no}_${prod.depot_no}`] > 9999)) {
          commonSVC.showMessage('출고수량은 1 이상 9999 이하의 숫자만 입력 가능합니다.');

          return false;
        }

        const prodList = [];
        const notSameEbayDepot = [];
        const notSameDepot = [];
        const cantBundle = [];

        // 주문 배송처와 sku 상품 배송처가 다를 경우, 매칭 실패
        const dupOrdList = {};
        const notMatchDepot = [];
        prod_selected.forEach(prod => {
          // sku 상품인경우 다중배송처 이슈로 인해 prod_no + depot_no 를 키로 잡음.
          const no = `${prod.prod_no}_${prod.depot_no}`;

          /**
            * 2018-07-18 Daniel
            * G마켓물류주문 발송요청 대기, 발송요청 실패, 발송정보 수정대기 일때 다른배송처 막음
            */
          const stockMatchEbaydepotState = ['발송요청 대기', '발송요청 실패', '발송정보 수정대기'];
          notSameEbayDepot.push(...ord_selected.filter((ord) => ord.depot_no && (ord.depot_no !== prod.depot_no && _.indexOf(stockMatchEbaydepotState, ord.ebaydepot_link_status) >= 0)));

          /**
            * 2018-04-13 Daniel
            * 출고완료 이상일시 매칭시 다른배송처 막음
            */
          const stockMatchState = ['결제완료', '신규주문', '주문보류', '출고대기', '출고보류', '운송장출력'];
          notSameDepot.push(...ord_selected.filter((ord) => ord.depot_no && (ord.depot_no !== prod.depot_no && stockMatchState.indexOf(ord.ord_status) < 0)));

          /**
           * 합포장 불가인 상품 매칭 시 막음
           * 주문에 매칭된 주문이 없고 주문중 하나의 옵션만 선택된 경우 합포장 불가여도 매칭가능
           */
          cantBundle.push(...ord_selected.filter((ord) => {
            if (ord.bundle_avail_yn) {
              /** 합포장 가능
               * 세트 또는 합포장 가능 상품 매칭
               * 또는 매칭된 SKU가 없거나 재고 매칭된 옵션이 1개이고 해당 옵션 수정 + 해당 작업 해당 주문에서 수정하는 옵션이 1개인경우
               * 가 아니면 실패처리
               */

              return !((prod.set_cd || prod.bundle_avail_yn) || ((!ord.map_yn || (ord.ord_opt_prod_seq_cnt === 1 && ord.opt_map_yn)) && ord_selected.filter(o => o.uniq === ord.uniq).length === 1));
            } else {
              /** 합포장 불가
               * 세트 또는 합포장 가능 상품 매칭이고 수정하는 주문중에 해당 주문의 재고매칭된 옵션이 있는 경우
               * 재고 매칭된 옵션이 1개이고 해당 옵션 수정 + 해당 작업 해당 주문에서 수정하는 옵션이 1개인경우
               * 가 아니면 실패처리
               */
              return !(((prod.set_cd || prod.bundle_avail_yn) && ord_selected.find(o => o.uniq === ord.uniq && o.opt_map_yn)) || (ord.ord_opt_prod_seq_cnt === 1 && ord.opt_map_yn && ord_selected.filter(o => o.uniq === ord.uniq).length === 1));
            }
          }));

          // 같은 uniq 주문일 경우 하나로 묶음
          for (const ordIndex in ord_selected) {
            if (!dupOrdList[ord_selected[ordIndex].uniq]) {
              dupOrdList[ord_selected[ordIndex].uniq] = { ordInfo: ord_selected[ordIndex], indexes: [ord_selected[ordIndex].ord_opt_seq] };
            }
            else {
              dupOrdList[ord_selected[ordIndex].uniq].indexes.push(ord_selected[ordIndex].ord_opt_seq);
            }
          }

          // 주문과 세트(sku)상품 매칭 가능한지 체크 -> 조건에 맞지 않을 경우 매칭 실패
          for (const uniq in dupOrdList) {
            if (dupOrdList[uniq].ordInfo.ord_depot_group) {
              const ordDepotGroup = dupOrdList[uniq].ordInfo.ord_depot_group.split(',');
              const checkDepotGroup = ordDepotGroup.filter(depot_no => !dupOrdList[uniq].indexes.some(checkordIndex => depot_no.split('-')[0] === String(checkordIndex))).map(depot_no => depot_no.split('-')[1]);
              const checkMatchingProd = checkDepotGroup.find(depot_no => depot_no && depot_no != String(prod.depot_no));

              if (checkMatchingProd) {
                notMatchDepot.push(checkMatchingProd);

              }
            }
          }

          prod.pack_unit = $scope.prodCountList.prod[no];
          prod.set_pack_unit = null;

          prodList.push(prod);
        });

        if (notSameEbayDepot.length) {
          commonSVC.showMessage('이미 발송정보가 전송되었으므로, 배송처 수정이 불가합니다.\n해당 주문을 발송정보 삭제 전송하신 후 SKU상품/배송처를 수정하시기 바랍니다.');

          return false;
        }

        if (notSameDepot.length) {
          commonSVC.showMessage('주문에 지정된 배송처와 매칭할 SKU상품에 설정된 배송처가 다릅니다.\n주문에 지정된 배송처의 SKU상품으로 매칭하시기 바랍니다.');

          return false;
        }

        if (notMatchDepot.length) {
          commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

          return false;
        }

        if (cantBundle.length) {
          commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

          return false;
        }

        const params = {
          matchType: 'prod',
          orders: ord_selected,
          bases: prodList
        };

        mapping(params);
      };

      /**
       * 선택 주문 기준으로 매칭됐던 상품 검색
       * */
      $scope.standardSearch = function() {
        const ord_selected = $scope.order.methods.selectedData('shop_sale_no');

        if (ord_selected.length === 0) {
          commonSVC.showMessage('매칭된 SKU상품을 검색할 주문을 선택해주세요');

          return false;
        }

        const params = {
          shopSaleNoList: _.uniq(ord_selected),
          isSet: $scope.tab === 'set'
        };

        shipmentModel.searchMappingProd(params, function(state, data) {
          if (state === 'success') {
            if ($scope.tab === 'set') {
              $scope.set.searchForm = angular.copy(set_search.searchForm);
              $scope.set.searchForm.multi_type = 'set_cd';
              $scope.set.searchForm.multi_search_word = data.result.join('\n');
              $scope.searchDo(true, false, 'set');
            } else {
              $scope.prod.searchForm = angular.copy(prod_search.searchForm);
              $scope.prod.searchForm.prod_no = data.result;
              $scope.searchDo(true, false, 'prod');
            }
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('쇼핑몰 상품코드 기준검색에 실패하였습니다.'));
          }
        });
      };

      $scope.close = function () {
        $uibModalInstance.close(isMapping);
      };

      function mapping(params) {
        const resolve = {
          data: { from: 'mapping' }
        };
        const modal = commonSVC.openModal('', resolve, 'orderMappingProdCtrl', 'views/order/shipment/modals/mapping_prod.html');

        modal.result.then(function(data) {
          showSpinner('ordProdMatchSpinner', 'SKU 상품 매칭 작업중');
          $scope.isMatch = true;

          const ebaydepotYn = params.bases[0].ebaydepot_yn;
          const orderList = _.filter(params.orders, function (o) {
            return !o.ebaydepot_link_status && _.indexOf(beforeUnstoringState, o.ord_status) >= 0;
          });
          const ebayOrderList = _.filter(params.orders, function (o) {
            return o.ebaydepot_link_status;
          });

          /**
           * 2018-07-05 Daniel
           * SKU상품 매칭시 G마켓물류 배송처 여부확인후 얼럿
           * storageKey는 다시보지않기 저장하기 위함
           */
          let text = '';
          let storageKey = '';

          if (ebaydepotYn && orderList.length) {

            if (orderList.some(ord => ord.pa_shop_cd?.startsWith('X'))) {
              commonSVC.showMessage('해외 주문은 G마켓 물류서비스 배송처 선택이 불가합니다.');

              hideSpinner('ordProdMatchSpinner');
              $scope.isMatch = false;

              return false;
            }

            storageKey = 'ebaydepot_y_dont_see_again';
            text = '매칭할 SKU상품의 배송처가 G마켓 물류서비스 배송처 입니다.<br />매칭 이후 주문이 G마켓 물류서비스 주문 메뉴로 이동합니다.<br />잘못 매칭된 경우 G마켓 물류서비스 주문 메뉴에서 다른 배송처 SKU상품으로 재매칭 하시면 신규주문 메뉴로 다시 이동됩니다.<br /><br /><label class="checkbox-inline"><input type="checkbox" style="display:block; top:-2px; width:auto; height:auto; box-shadow:0 0 #fff;" onClick="localStorage.setItem(\'ebaydepot_y_dont_see_again\', this.checked);">다시보지 않기</label>';
          } else if (!ebaydepotYn && ebayOrderList.length) {
            storageKey = 'ebaydepot_n_dont_see_again';
            text = '매칭할 SKU상품의 배송처가 G마켓 물류서비스 배송처가 아닙니다.<br />매칭 이후 주문이 신규주문 메뉴로 이동합니다.<br />잘못 매칭된 경우 신규주문 메뉴에서 G마켓 물류서비스 배송처 SKU상품으로 재매칭 하시면 G마켓 물류서비스 주문 메뉴로 다시 이동됩니다.<br /><br /><label class="checkbox-inline"><input type="checkbox" style="display:block; top:-2px; width:auto; height:auto; box-shadow:0 0 #fff;" onClick="localStorage.setItem(\'ebaydepot_n_dont_see_again\', this.checked);">다시보지 않기</label>';
          }

          if (text && storageKey) {
            const item = localStorage.getItem(storageKey);

            if (item === 'false' || !item) {
              commonSVC.showMessageHtml('', text);
            }
          }

          params.sameOrderMatchYn = !data.isOnly;
          params.saveRuleYn = data.ruleModify;

          shipmentModel.matchingProd(params, function (state, data) {
            if (state === 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 {{count}}건이 SKU상품 매칭에 성공하였습니다.', { count: data.result.matched }));
              // 매칭 성공한 경우 선택한 값 초기화
              selectProdList = [];
              $scope.selectedCount = 0;
              $scope.prod.methods.unSelectByFilter(function (r) {
                return true;
              }, false);

              $scope.searchDo(true, false, 'prod');
              $scope.searchDo(false, false, 'order');
              $rootScope.isOrderMappingProd = true;

              isMapping = true;

            } else {
              const err = data.data.error === 'STOCK' ? '매칭할 SKU상품의 재고가 부족합니다. SKU상품 재고를 확인하시기 바랍니다.' : 'SKU상품 매칭에 실패하였습니다.';

              commonSVC.showToaster('error', gettextCatalog.getString('실패'), err);
            }

            $timeout(() => {
              hideSpinner('ordProdMatchSpinner');
            }, 300);
          });
        });
      }

      $scope.showMultiSKUDetail = (uniq, ord_opt_seq) => {

        shipmentModel.listOfMultiBase({ uniq, ord_opt_seq }, function (state, data) {
          if (state === 'success') {
            if (data.result.length) {
              const resolve = {
                data: { prodList: data.result }
              };

              commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
            }
          }
        });
      };

      $scope.changeTab = function (tab) {
        $scope.tab = tab;
      };

      // 로딩스피너 보이기
      function showSpinner(id, text) {
        $(`#${id} > div`).html(`<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i><br />${text}`);
        $(`#${id}`).removeClass('hidden');
      }

      // 로딩스피너 숨기기
      function hideSpinner(id) {
        $(`#${id}`).addClass('hidden');
      }
      /**
       * 테이블에서 선택 변경 시
       */
      $scope.$on('OnSelectChangeBefore', function(event, data) {
        $(data.targetClasses).addClass('selected');
      });

      /**
       * 테이블에서 선택해제시
       */
      $scope.$on('OnDeSelected', function(event, data) {
        $(data.targetClasses).removeClass('selected');
      });
    });
