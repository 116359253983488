/**
 * 정기결제 유도 및 이탈방지 모달
 */
'use strict';

angular.module('gmpApp')
  .controller('PayAutoInduceMentCtrl', function($scope, $uibModalInstance, data, commonSVC, userInfo, $window) {
    const noticePrice = {
      'SLIM': ['60,000', 'SLIM'],
      'ZERO': ['128,400', 'CLASSIC'],
      'LITE': ['128,400', 'CLASSIC'],
      'CLASSIC': ['128,400', 'CLASSIC'],
      'BASIC': ['88,800', 'BASIC'],
      'SPECIAL': ['164,400', 'SPECIAL'],
      'PLATINUM': ['200,400', 'PLATINUM'],
      'VIP': ['272,400', 'VIP'],
      'VIP+': ['272,400', 'VIP']
    };

    const dataLayer = $window.dataLayer = $window.dataLayer || [];

    // 절약 금액과 기준 버전 정보
    $scope.noticeInfo = noticePrice[userInfo.user.sol_version];
    $scope.runningCouponCount = data.runningCouponCount;

    $scope.close = () => {
      $uibModalInstance.close('close');
    };

    $scope.openRealModal = async () => {
      dataLayer.push({
        event: 'Button Click',
        category: 'Button Click',
        label: '1',
        action: 'click_autopay_cancel_button'
      });

      const modal = await commonSVC.showConfirmCustom({
        title: ' ',
        text: '<div style="text-align:center;font-weight:700;">정기 결제 해지 시 제공된 혜택은 모두 소멸되며 다시 복구할 수 없습니다.<br/>정기결제를 해지하시겠습니까?</div>',
        type: 'warning',
        confirmButtonText: '유지하겠습니다',
        cancelButtonText: '해지하겠습니다',
        buttonClass: {
          confirm: 'autopay_cancel_button',
          cancel: 'autopay_cancel_button'
        },
        isHtml: true,
        buttonCss: {
          'display': 'flex',
          'justify-content': 'space-evenly',
          'flex-direction': 'row-reverse'
        }
      });

      // GA4 dataLayer에 정기결제 이탈 방지 정보 전달 스크립트 추가
      if (modal) {
        dataLayer.push({
          event: 'Button Click',
          category: 'Button Click',
          label: '2',
          action: 'click_autopay_cancel_button'
        });
      } else {
        dataLayer.push({
          event: 'Button Click',
          category: 'Button Click',
          label: '3',
          action: 'click_autopay_cancel_button'
        });
      }

      return $uibModalInstance.close(modal);
    };

    /**
     * 정기결제 사용중 쿠폰 존재할 시 해지여부 확인 함수
     * @param {*} isMaintain
     *   - close: 유지
     *   - cancel: 해지
     */
    $scope.maintainRegularPayment = (isMaintain) => {
      return $uibModalInstance.close(isMaintain);
    };

    /**
     * 쿠폰관리 모달 열기
     */
    $scope.openCouponManagementModal = () => {
      commonSVC.openModal('xg', { data: { tab: 'used' } }, 'couponManagementCtrl', 'views/pay/modals/couponManagement.html');
    };
  });