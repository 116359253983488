/**
 * 결제 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayCtrl', function ($rootScope, $timeout, localStorageService, $scope, commonSVC, paySVC, userInfo, payModel, gettextCatalog, $filter, $stateParams, commonModel) {
    $scope.search = {
      pay: {
        searchForm: angular.copy(paySVC.searchForm),
        searchData: angular.copy(paySVC.searchData),
        searchDetail: angular.copy(paySVC.searchDetail),
        searchFn: {}
      },
      refund: {
        searchForm: angular.copy(paySVC.searchForm_refund),
        searchData: angular.copy(paySVC.searchData_refund),
        searchDetail: angular.copy(paySVC.searchDetail_refund),
        searchFn: {}
      },
      service: {
        searchForm: angular.copy(paySVC.searchForm_svc),
        searchData: angular.copy(paySVC.searchData_svc),
        searchDetail: angular.copy(paySVC.searchDetail_svc),
        sendType: {},
        searchFn: {}
      },
      translate: {
        searchForm: angular.copy(paySVC.searchForm_trnsl),
        searchData: angular.copy(paySVC.searchData_trnsl),
        searchDetail: angular.copy(paySVC.searchDetail_trnsl),
        searchFn: {}
      },
      benefit: {
        searchForm: angular.copy(paySVC.searchForm_benefit),
        searchData: angular.copy(paySVC.searchData_benefit),
        searchFn: {}
      }
    };

    $scope.searchDo = function() {
      $scope.search[$scope.gridType].searchFn.searchDo();
    };

    $scope.searchBtn = {
      // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: `<i class="picon-get"></i>${gettextCatalog.getString('카운트내역')}`,
          small_label: '카운트내역',
          add_class: 'col-xs-3',
          btn_type: 'button',
          action: function() {
            $scope.countHistory();
          }
        },
        {
          label: `<i class="icon-cog5"></i> ${gettextCatalog.getString('이용료결제')}`,
          small_label: '이용료결제',
          btn_type: 'button',
          add_class: 'col-xs-3',
          action: function() {
            $scope.payRequest();
          }
        },
        {
          label: `<i class="icon-cog5"></i> ${gettextCatalog.getString('결제관리')}`,
          small_label: '결제관리',
          btn_type: 'button',
          add_class: 'col-xs-3',
          action: function() {
            $scope.payManagement();
          }
        },
        {
          label: `<i class="icon-cog5"></i> ${gettextCatalog.getString('쿠폰관리')}`,
          small_label: '쿠폰관리',
          btn_type: 'button',
          add_class: 'col-xs-3',
          action: function() {
            $scope.couponManagement();
          }
        }
      ]
    };

    /**
     * 결제모달 자동오픈
     */
    $scope.$on('payReq', (event, paymentSetupInfo) => {
      $scope.payRequest(paymentSetupInfo);
    });

    /**
     * 결제관리모달 자동오픈
     */
    $scope.$on('payManagement', () => {
      $scope.payManagement();
    });

    /**
     * 홈페이지 이용요금 내 '신청하기' 클릭 시 결제모달 자동오픈
     */
    $stateParams.tab === 'pay' && $rootScope.openPayReq();

    /**
     * 결제관리모달 자동오픈
     */
    if ($stateParams.tab === 'autopay') {
      $rootScope.openPayManagement();

      delete $stateParams.tab;
    }

    $scope.gridType = $stateParams.tab || 'pay';

    $scope.changeTab = function (tab) {
      if ($scope.gridType !== tab) {
        $scope.gridType = tab;
      }

      return;
    };

    $scope.solutionProductInfo;
    let payedJoinPrice;
    let payInfosWithToday;

    const indv_pay_amt = $rootScope.indvPayInfo.indv_pay_amt; // 개별설정 월사용요금

    // 장기결제 할인율
    const percentMap = {
      3: 0.05,
      6: 0.1,
      12: 0.2,
      24: 0.3
    };

    /**
     * 솔루션 정보 갱신.
     */
    $scope.getSolutionInfo = function () {
      payModel.getSolInfo(userInfo.user.m_no, function (status, data) {
        if (status === 'success') {

          // 결제 권한이 없는 사용자에게는 결제 정보 출력하지 않음
          $scope.solutionInfo = userInfo.permission?.pay?.use_yn !== false ? data.results.solutionInfos : {};
          $scope.solutionProductInfo = data.results.solutionProductInfo;
          $scope.solSerInfo = data.results.solSerInfos;
          payedJoinPrice = data.results.payedJoinPrice;    // 결제된 가입비 정보
          payInfosWithToday = data.results.payInfosWithToday; // 현재 결제정보

          // 남은일자
          $scope.solutionInfo.leftDay = moment($scope.solutionInfo.sol_edate).endOf('day').diff(moment().endOf('day'), 'days');
          $scope.solutionInfo.textLeftDay = $scope.solutionInfo.sol_version == 'ZERO' ? '무제한' :
            `${$scope.solutionInfo.sol_edate} (${$scope.solutionInfo.leftDay}일 남음)`;
          // 카운트 갱신일
          $scope.resetDate = moment($scope.solutionInfo.using_charge_edate).endOf('day').add(1, 'day').date();

          // 현재 사용중인 솔루션의 월금액.
          for (let i = 0; i < $scope.solutionProductInfo.length; i++) {
            if ($scope.solutionProductInfo[i].prod_name === $scope.solutionInfo.sol_version && $scope.solutionProductInfo[i].prod_opt === '1개월') {
              // 결제가 아직 안된상태에서는 0원으로 노출.
              $scope.solutionInfo.monthPrice = $scope.solutionProductInfo[i].price;

              if (!$scope.solutionInfo.first_pay_no || paySVC.freeVersion.includes($scope.solutionInfo.sol_version)) {
                // 무료사용
                $scope.viewMonthPrice = $scope.solutionInfo.sol_version !== 'LITE' ? '테스트 사용중' : '-';
              } else if (indv_pay_amt || paySVC.freeVersion.includes($scope.solutionInfo.sol_version) || !$scope.solutionInfo.auto_payment_yn) {
                // 개별금액 or 무료버전 or 정기결제 미사용
                $scope.viewMonthPrice = `${$filter('currency')(indv_pay_amt || $scope.solutionInfo.monthPrice, '', 0)} 원`;
              } else {
                let monthPrice = $scope.solutionInfo.monthPrice;

                // 정기결제 중 장기결제 할인
                const saleCnt = Number($scope.solutionInfo.autopay_term || 1);
                if (saleCnt > 1 && saleCnt in percentMap) {
                  const percent = percentMap[saleCnt];
                  monthPrice -= (monthPrice * percent);
                }

                // 정기결제 할인
                monthPrice -= ($scope.solutionInfo.monthPrice * 0.03);

                $scope.viewMonthPrice = `${$filter('currency')(monthPrice, '', 0)} 원`;
              }
            }

            // 사용중인 솔루션의 일금액. ( 환불시 필요 )
            if ($scope.solutionProductInfo[i].prod_name === $scope.solutionInfo.sol_version && $scope.solutionProductInfo[i].prod_opt === '1일') {
              $scope.solutionInfo.dayPrice = $scope.solutionProductInfo[i].price;
            }

            // 부가서비스 금액 계산
            _.forEach($scope.solSerInfo, function (v, k) {
              if ($scope.solutionProductInfo[i].prod_name === v.sol_ser) {
                $scope.solSerInfo[k].price = $scope.solutionProductInfo[i].price * v.sol_ser_remain_cnt;
              }
              if ($scope.solutionProductInfo[i].prod_name === 'atalk' && v.sol_ser === '알림톡') {
                $scope.solSerInfo[k].price = $scope.solutionProductInfo[i].price * v.sol_ser_remain_cnt;
              }
            });
          }
        }

      });
      // 이메일, sms 카운트 조회
      commonModel.getServiceCnt().then((re) => {
        if (re.data?.status === 'success') {
          $scope.smsCnt = userInfo.permission?.pay?.use_yn !== false ? re.data?.smsCnt : {};
          $scope.emailCnt = userInfo.permission?.pay?.use_yn !== false ? re.data?.emailCnt : {};
          $scope.atalkCnt = userInfo.permission?.pay?.use_yn !== false ? re.data?.atalkCnt : {};
          $scope.trnslCnt = userInfo.permission?.pay?.use_yn !== false ? re.data?.trnslCnt : {};
        } else {
          throw new Error('EMAIL,SMS,알림톡 카운트 조회 실패');
        }

      }).catch(err => {
        commonSVC.showToaster('error', '실패', err.message);
      });
    };

    /**
     * 카운트 사용내역
     */
    $scope.countHistory = function () {
      commonSVC.openModal('full', '', 'CountHistoryCtrl', 'views/pay/modals/count_history.html');
    };

    /**
     * 이용료 결제
     */
    $scope.payRequest = async (paymentSetupInfo) => {
      // 추천 버전이 없으면 재검사
      const recommendVersion = paymentSetupInfo?.recommendVersion || $rootScope.getVersionInfos().recommendInfo.prod_name;

      const params = {
        solutionInfo: $scope.solutionInfo,
        solutionProductInfo: $scope.solutionProductInfo,
        payedJoinPrice: payedJoinPrice,
        firstOrdYN: !$scope.solutionInfo?.first_pay_no,
        closeFlag: true,
        insight_info: userInfo.user.insight_info,
        recommendVersion,
        paymentSetupInfo
      };

      const modal = commonSVC.openModal('x3g', { data: params }, 'PayRequestCtrl', 'views/pay/modals/pay_request.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          // $rootScope.searchDo();
          // $scope.getSolutionInfo();
          // 2018-07-06 chris 결제완료시 브라우저 새로고침
          $scope.getSolutionInfo();
          location.reload();
        }
      });
    };

    /**
     * 쿠폰관리 모달 오픈
     */
    $scope.couponManagement = () => {
      commonSVC.openModal('xg', { data: { tab: 'available' } }, 'couponManagementCtrl', 'views/pay/modals/couponManagement.html');
    };

    /**
     * LITE 버전 기간 연장
     */
    $scope.liteExtension = async () => {
      const confirm = await commonSVC.showConfirm('LITE 버전 기간 연장하시겠습니까?');

      if (confirm) {
        const result = await payModel.liteExtension();

        if (result.status === 200) {
          commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
            localStorageService.set('token', `${'Token' + ' '}${result}`);
          });

          commonSVC.showMessageHtml('LITE 버전 1개월 연장되었습니다.');

          location.reload();
        }
      }

    };

    /**
     * 결제관리 ****
     */
    $scope.payManagement = async function () {
      if (_.isUndefined($scope.solutionInfo)) {
        commonSVC.showMessage(gettextCatalog.getString('페이지 로딩이 완료될때까지 기다려 주십시오.'));

        return false;
      }

      const params = {
        solutionInfo: $scope.solutionInfo,
        solSerInfo: $scope.solSerInfo,
        payInfosWithToday: payInfosWithToday
      };

      commonSVC.openModal('lg', { data: params }, 'PayManagementCtrl', 'views/pay/modals/pay_management.html');
    };

    /**
     * 쿠폰관리
     */
    $scope.coupon = function () {
      // 일단 로컬에서만 노출.
      if (window.debug) {
        commonSVC.openModal('lg', '', 'couponCtrl', 'views/pay/modals/coupon.html');
      } else {
        commonSVC.showMessage(gettextCatalog.getString('서비스 준비중입니다.'));
      }
    };
  });
