'use strict';

angular.module('gmpApp')
  .service('insightSVC', function (gettextCatalog, commonSVC) {

    /**
     * 세일즈 인사이트 서비스
     */
    return {
      chartData: {
        size: {
          height: 350
        },
        padding: {
          right: 100,
          bottom: 20
        },
        transition: {
          duration: 1500
        },
      },
      getPriceStatTemplate: (row) => {
        return `
        <div style="width: 1200px;">
          <div class="fl-r custom-chart-btn-box">
            <div class="btn-group pt-10 mb-40 text-right" data-toggle="buttons">
              <button
                type="button"
                class="btn btn-default mr-10 no-padding"
                ng-class="insightDetailData[${row.index}].date_unit === 'day' ? 'btn-primary' : btn-default"
                ng-click="changeDateUnit(${row.index}, 'day')"
                tooltip-placement="top-right"
              >
                일
              </button>
              <button
                type="button"
                class="btn btn-default mr-10 no-padding"
                ng-class="insightDetailData[${row.index}].date_unit === 'week' ? 'btn-primary' : btn-default"
                ng-click="changeDateUnit(${row.index}, 'week')"
              >
                주
              </button>
              <button
                type="button"
                class="btn btn-default mr-20 no-padding"
                ng-class="insightDetailData[${row.index}].date_unit === 'month' ? 'btn-primary' : btn-default"
                ng-click="changeDateUnit(${row.index}, 'month')"
              >
                월
              </button>
            </div>
            <div class="btn-group pt-10 pr-10 mb-40 text-right">
              <div class="btn-group">
                <select class="form-control" test-id="dropdown-left-view" style="width: 100px" ng-model="insightDetailData[${row.index}].search_date" ng-change="changeSearchDate(${row.index}, insightDetailData[${row.index}].search_date)">
                  <option ng-value="7" test-id="btn-left-view10" translate>1주</option>
                  <option ng-value="30" test-id="btn-left-view25" translate>1개월</option>
                  <option ng-value="90" test-id="btn-left-view50" translate>3개월</option>
                  <option ng-value="180" test-id="btn-left-view100" translate>6개월</option>
                  <option ng-value="365" test-id="btn-left-view300" translate>1년</option>
                </select>
              </div>
            </div>
          </div>

          <div style="width: 100%; height: 100%; color: darkgrey;" class="text-center spinner_default_${row.index} spinner_insight_${row.index}">
            <i class="icon-spinner3 spinner" style="font-size: 86px; top: 40%"></i>
          </div>
          <div class="chart-container" style="clear: both;">
            <div ng-show="insightDetailData[${row.index}].statData.length">
              <div id="insight_sales_all_${row.index}" class="chart_default_${row.index} has_scroll"></div>
            </div>
            <div ng-show="!insightDetailData[${row.index}].statData.length" style="text-align: center; padding-top: 50px;">
              기간을 재설정하여 판매가 변동추이를 확인해보세요.
            </div>
          </div>
        </div>
      `;
      },
      getReviewSummaryTemplate: (row) => {
        return `
          <div ng-if="insightDetailData[${row.index}].review.isLoad" class="insight-review" style="width: 1200px">
            <div class="insight-review-box">
              <strong style="font-size: 14px; color: #3083F7; font-weight: 700;">긍정리뷰 요약</strong>
              <p class="mt-10" style="margin-bottom: 0; font-size: 12px; line-height: 20px">
                {{insightDetailData[${row.index}].review.positive ? insightDetailData[${row.index}].review.positive : ''}}
              </p>
            </div>
            <div class="insight-review-box">
              <strong style="font-size: 14px; color: #F44A3E; font-weight: 700;">부정리뷰 요약</strong>
              <p class="mt-10" style="margin-bottom: 0; font-size: 12px; line-height: 20px">
              {{insightDetailData[${row.index}].review.negative ? insightDetailData[${row.index}].review.negative : ''}}
              </p>
            </div>
          </div>
          <div ng-if="!insightDetailData[${row.index}].review.isLoad && (insightDetailData[${row.index}].review.error === 'now scraping')" style="text-align: center; padding-top: 50px;">
            상품 리뷰내용을 분석 중이라 요약까지 시간이 조금 걸립니다. 빠르게 진행 중이니 조금만 기다려 주세요 (최대 1일 소요)
          </div>
        `;
      }
    };
  });
