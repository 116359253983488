'use strict';

angular.module('gmpApp')
  .factory('invoiceTemplateSVC', function () {

    return {
      column: [
        { header: '쇼핑몰', val: 'shop_name', stock_yn: 0 },
        { header: '계정', val: 'shop_id', stock_yn: 0 },
        { header: '주문자[주문자명]', val: 'order_name', stock_yn: 0 },
        { header: '온라인상품명', repeatKey: 'prod_list_temp', val: 'prod_without_prop', stock_yn: 0 },
        { header: '온라인상품명(옵션)', repeatKey: 'prod_list_temp', val: 'prod_name', stock_yn: 0 },
        { header: '옵션', repeatKey: 'prod_list_temp', val: 'opt_name', stock_yn: 0 },
        { header: '옵션(온라인상품명)', repeatKey: 'prod_list_temp', val: 'opt_name_with_prod_name', stock_yn: 0 },
        { header: 'SKU상품명', repeatKey: 'prod_list_temp', val: 'sku_without_prop', stock_yn: 1 },
        { header: 'SKU상품명(속성)', repeatKey: 'prod_list_temp', val: 'sku_name', stock_yn: 1 },
        { header: '규칙적용사은품', val: 'gift_prod_name', stock_yn: 1 },
        { header: '사은품', val: 'gift_name', stock_yn: 0 },
        { header: 'SKU코드', repeatKey: 'prod_list_temp', val: 'sku_cd', stock_yn: 1 },
        { header: '재고관리코드', repeatKey: 'prod_list_temp', val: 'stock_cd', stock_yn: 1 },
        { header: '판매자관리코드', repeatKey: 'prod_list_temp', val: 'c_sale_cd', stock_yn: 0 },
        { header: '추가메세지', val: 'order_msg', stock_yn: 0 },
        { header: '별칭', val: 'seller_nick', stock_yn: 0 },
        { header: '총 출고 수량', val: 'print_prod_cnt', stock_yn: 0 },
        { header: '인쇄 순번', val: 'print_seq', stock_yn: 0 },
        { header: '쇼핑몰 주문번호', val: 'shop_ord_no', stock_yn: 0 },
        { header: '판매자관리코드 + 상품명(옵션)', repeatKey: 'prod_list_temp', val: 'c_sale_cd+prod_name', stock_yn: 0 },
        { header: '판매자관리코드 + 옵션', repeatKey: 'prod_list_temp', val: 'c_sale_cd+opt_name', stock_yn: 0 },
        { header: '판매자관리코드 + SKU상품명(속성)', repeatKey: 'prod_list_temp', val: 'c_sale_cd+sku_name', stock_yn: 1 },
        { header: 'SKU코드 + SKU상품명(속성)', repeatKey: 'prod_list_temp', val: 'sku_cd+sku_name', stock_yn: 1 },
        { header: '재고관리코드 + 옵션', repeatKey: 'prod_list_temp', val: 'stock_cd+opt_name', stock_yn: 1 },
        { header: '재고관리코드 + SKU상품명(속성)', repeatKey: 'prod_list_temp', val: 'stock_cd+sku_name', stock_yn: 1 },
        { header: '쇼핑몰주문번호 + 상품명(옵션)', repeatKey: 'prod_list_temp', val: 'shop_ord_no+prod_name', stock_yn: 0 },
        { header: '쇼핑몰주문번호 + 옵션', repeatKey: 'prod_list_temp', val: 'shop_ord_no+opt_name', stock_yn: 0 },
        { header: '쇼핑몰주문번호 + SKU상품명(속성)', repeatKey: 'prod_list_temp', val: 'shop_ord_no+sku_name', stock_yn: 1 },
      ]
    };
  });