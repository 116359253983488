'use strict';

angular.module('gmpApp')
  .controller('confirmCtrl', function ($rootScope, $scope, $uibModalInstance) {
    // rootScope HTML에서 사용중이므로 지우지말것

    // 확인
    $scope.submit = function () {
      $uibModalInstance.close('success');
    };

    // 닫기
    $scope.close = function () {
      $uibModalInstance.close();
    };
  });