/**
 * 결제 상세 컨틀홀러
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayDetailCtrl', function ($scope, data, payModel, commonSVC, $uibModalInstance, gettextCatalog, paySVC, userInfo, $filter, $rootScope) {
    const solutionProductInfo = angular.copy(data.solutionProductInfo);
    delete data.solutionProductInfo;

    $scope.payDatas = data;

    // $scope.payStatus = data.pay_status;   // 결제상태
    $scope.totalPrice = 0;

    // 닫힘 버튼 확성화 여부
    $scope.closeFlag = data.closeFlag;

    $scope.payData = angular.copy(paySVC.payData);
    $scope.payData.BuyerName = userInfo.user.company_name;
    $scope.payData.BuyerTel = userInfo.user.htel !== '' ? userInfo.user.htel : userInfo.user.tel;
    $scope.payData.UserIP = userInfo.user.remote_ip;
    $scope.payData.BuyerEmail = userInfo.user.email;

    const REFUND_AVAIL_STATUS = '환불';
    const insightDayPrice = solutionProductInfo.find(prod => prod.prod_name === '가격비교' && prod.prod_opt === '1일').price;

    // 결제방법.
    let payMethod = '계좌이체';
    // 이후 결제완료된 솔루션 청구서가 있는지여부.
    let afterPaymentSolution = false;

    /**
     * 주문상세정보 조회.
     */
    $scope.init = function() {
      payModel.getPayDetail(data.pay_no, function (status, ret) {

        payMethod = ret.results.payinfo.pay_method;
        afterPaymentSolution = ret.results.afterPaymentSolution;

        // 솔루션 결제 정보
        $scope.solutions = ret.results.solutions;

        // 부가서비스 결제 정보
        $scope.additionals = ret.results.additionals;
        // 선불 카운트 환불 가능 여부 -> 두번 연속으로 구매한 경우에는 첫번째 청구서가 환불 불가한 대상건
        if (data.pay_title.includes('카운트 추가구매')) {
          let cntResult = ret.results?.refundableStatus;
          const cntType = data.pay_title.includes('상품카운트') ? 'ol_shop' : 'ord';
          const preCntPayList = data.pay_list.filter(pay => pay.title.includes('카운트 추가구매') && (pay.title.includes('상품카운트') && cntType === 'ol_shop') || (pay.title.includes('주문카운트') && cntType === 'ord')).map(pay => {
            if (cntResult - ret.results?.additionals[0].sale_cnt >= 0) {
              cntResult -= ret.results?.additionals[0].sale_cnt;

              return { ...pay, refundable: true };
            }

            return { ...pay, refundable: false };
          });

          $scope.preCntRefundableStatus = preCntPayList.find(pay => pay.pay_no === +data.pay_no)?.refundable;
        }

        // 업/다운 그레이드전 결제 정보
        const prevPayInfos = ret.results?.prevPayInfos;
        // 전체환불여부
        $scope.allRefuncYN = false;
        $scope.refundPrice = 0;

        // 2018-07-18 chris 가상계좌 입금 정보 추가
        $scope.virtual_info = '';
        if (payMethod == '가상계좌' && ret.results.payinfo.virtual_acct_no) {
          $scope.virtual_info = `가상계좌번호 : ${ret.results.payinfo.bank_name} ${ret.results.payinfo.virtual_acct_no}`;
        }

        // 합계금액 계산
        $scope.totalPrice = 0;
        const ord_nos = [];
        const admin_ord_nos = [];
        const today = moment().endOf('day');

        // 환불정보
        const getRefundInfo = (row, dayPrice, used_date) => {
          row.prod_name = row.note;
          row.sales = parseInt(row.sales);
          row.sales_tax = parseInt(row.sales_tax);

          // 결제당일에만 결제금액 전액 환불, 결제내용 적용 시작일이 오늘보다 큰경우도 전액 환불.
          if (row.sol_sdate > used_date.format('YYYY-MM-DD') || today.diff(moment(row.cash_time).endOf('day'), 'days') === 0) {
            return {
              refundPrice: row.sales,
              diff_reason: '※ 솔루션 결제금액'
            };
          }

          const useDay = Math.abs(moment(row.sol_sdate).endOf('day').diff(used_date, 'days'));
          const usePrice = useDay * parseInt(dayPrice);
          const refundPrice = row.sales - usePrice;

          return {
            refundPrice,
            diff_reason: `※ 솔루션 결제금액(${$filter('number')(parseInt(row.sales * 1.1))})-(사용일(${useDay})X일금액(${$filter('number')(dayPrice)})X부가세(1.1))`
          };
        };

        const addOrds = (ordNos, row, refundInfo, refund_status = '환불신청') => {
          ordNos.push({
            ord_no: row.ord_no,
            pay_no: row.pay_no,
            refund_price: row.refund_price,
            ori_price: row.sales,
            sale_cnt: row.sale_cnt,
            diff_reason: refundInfo.diff_reason ? `${refundInfo.diff_reason} : ${$filter('number')(parseInt(row.refund_price * 1.1))}원` : '',
            refund_rjct_reason: '', // 환불거부사유
            prod_group_type: row.prod_group_type,
            refund_status,
            cash_time: row.cash_time,
            pay_method: row.pay_method,
            title: row?.title
          });
        };

        let refundInfo;

        // 솔루션 정보 가공.
        _.forEach($scope.solutions, function(row) {

          const daysFromPay = today.diff(moment(row.cash_time).endOf('day'), 'days');

          row.prod_name = row.note;
          row.sales = parseInt(row.sales);
          row.sales_tax = parseInt(row.sales_tax);
          $scope.totalPrice += row.sales + row.sales_tax;

          if (row.refund_status == null) {
            row.refund_status = REFUND_AVAIL_STATUS;
            $scope.allRefuncYN = true;

            // 가입비는 환불해주지 않음
            // 환불 가능 일수에 도달하지 못한 쿠폰사용 결제도 환불 불가
            if (row.prod_name.indexOf('가입비') > -1 || row.refund_avail_day && row.refund_avail_day > daysFromPay) {
              row.refund_price = 0;

            // 이전 결제 내역의 종료일이 오늘보다 작은경우 이전청구서까지 일괄 환불처리
            } else {
              /**
               * 환불 금액
               *
               * 1. 업체요청시
               *  ㄴ 0원 초과하면 해당 청구서만 환불요청. 원복된 상태에서 이전 결제건을 업체가 또 환불요청을 하든..쓰시든..
               *  ㄴ 0원 이하이면 환불불가
               *
               * 2. 어드민모드일시 0원 이하 청구서 계산식 노출위해 따로 계산
               *  ㄴ 현재 날짜 > 이전 청구서의 sol_edate 인 청구서를 전부 찾아서 결제금액 합산
               *  ㄴ 이전 버전에서 현재 버전 시작일(sol_sdate) 까지 사용한 금액은 차감
               *  ㄴ 결제 합산금액 금액보다 사용금액이 높으면 (환불금액이 0이하) 환불불가
               *  ㄴ 환불할 금액이 있다면 현재청구서는 100% 환불. 역순으로 환불가능금액 계산
               *  ㄴ 계산식에 사용된 청구서는 모두 환불불가처리 (환불거부 청구서 상태로 만들어주기)
               *
               * 3. 다중청구서환불건은 환불거부 안됨
              */
              refundInfo = getRefundInfo(row, $scope.payDatas.dayPrice, today);

              row.refund_price = refundInfo.refundPrice > 0 ? refundInfo.refundPrice : 0;
              $scope.refundPrice = row.refund_price;

              addOrds(ord_nos, row, refundInfo);

              // 업/다운그레이드 청구서이고 0원 이하 환불금액이 나왔을 경우
              if ($rootScope.adminMode && row.prod_name.includes('그레이드') && refundInfo.refundPrice <= 0 && prevPayInfos.length) {
                let prevDayPrice, prevRefundInfo;

                // 다중청구서 환불
                row.refund_status = '환불(DEBUG)';
                row.refund_price = refundInfo.refundPrice;

                // 일단 환불불가처리
                addOrds(admin_ord_nos, row, refundInfo, '환불거부');

                // 이전버전 사용금액을 제외한 총 금액
                let sol_sdate = row.sol_sdate;
                const { prevPrice, maxPricePay } = prevPayInfos.reduce((prev, o) => {
                  // 이전버전의 1일 사용금액
                  prevDayPrice = solutionProductInfo.find(prod => prod.prod_name == o.prod_name && prod.prod_opt == '1일').price;
                  prevRefundInfo = getRefundInfo(o, prevDayPrice, sol_sdate);

                  // 실제 사용한 금액을 제외한 환불가능금액
                  o.refund_price = prevRefundInfo.refundPrice;

                  // 이전 청구서의 실제 사용일 계산을 위해 시작일 갱신
                  sol_sdate = o.sol_sdate;

                  // 일단 환불불가처리
                  addOrds(admin_ord_nos, o, prevRefundInfo, '환불거부');

                  // 환불가능한 금액 합산
                  prev.prevPrice += prevRefundInfo.refundPrice;

                  // 결제청구서 중 가장 결제금액이 큰 청구서 찾기
                  prev.maxPricePay = prev.maxPricePay.sales > o.sales ? prev.maxPricePay : o;

                  return prev;
                }, { prevPrice: 0, maxPricePay: { ...row } });

                // 실제 사용금액을 뺀 최종 환불금액
                const finallyRefundPrice = prevPrice + refundInfo.refundPrice;

                // 환불 금액 산정 내역 합치기
                const diff_reason_sum = `${ admin_ord_nos.map(o => `(${o.diff_reason.replace('솔루션 결제금액', `청구서 ${o.pay_no}`)})`).join('+') }:${$filter('number')(parseInt(finallyRefundPrice * 1.1))}`;
                admin_ord_nos.map(o => o.diff_reason_sum = diff_reason_sum);

                // 가장 결제금액이 큰 1개 청구서로 환불요청이 가능하면 해당 청구서로만 환불요청
                if (maxPricePay.sales >= finallyRefundPrice) {
                  const refundPay = admin_ord_nos.find(o => maxPricePay.ord_no == o.ord_no);

                  refundPay.refund_status = '환불신청';
                  refundPay.refund_price = finallyRefundPrice;

                } else if (finallyRefundPrice > 0) {
                  // 2개이상 청구서를 사용해야한다면 역순으로 환불가능금액 계산
                  let checkPrice = finallyRefundPrice;
                  for (const refundPay of admin_ord_nos) {
                    if (checkPrice == 0) {
                      break;
                    }
                    refundPay.refund_status = '환불신청';
                    refundPay.refund_price = checkPrice >= refundPay.ori_price ? refundPay.ori_price : checkPrice;

                    checkPrice -= refundPay.refund_price;
                  }
                }

                $scope.refundPrice = finallyRefundPrice;
              }
            }
          }

          // 솔루션 환불인 경우 정기결제 여부 확인
          ord_nos.filter(ordNo => ordNo.prod_group_type === '솔루션').map(o => o.isAutoPay = row?.title.includes('정기결제') ? 1 : 0);
        });

        // 부가서비스 정보 가공.
        _.forEach($scope.additionals, function(row) {
          let diff_reason = '';
          row.sales = parseInt(row.sales);
          row.sales_tax = parseInt(row.sales_tax);
          $scope.totalPrice += row.sales + row.sales_tax;
          // 선불카운트는 환불 불가.
          if (row.refund_status == null) {
            if (!$rootScope.adminMode && row.prod_name.indexOf('(월)_선불') > -1) {
              row.refund_status = '환불 불가';
            } else {
              row.refund_status = REFUND_AVAIL_STATUS;
              row.prod_name = row.prod_name === '가격비교' ? row.prod_name : `${row.prod_name} 충전 / ${row.sale_cnt} 건`;

              if ($rootScope.adminMode && row.prod_name.indexOf('(월)_선불') > -1) {
                row.refund_status = '환불(DEBUG)';
                row.refund_price = parseInt(row.sales);
                if (!$scope.preCntRefundableStatus) {
                  row.refund_status = '환불 불가';
                }
                diff_reason = `※ 선불카운트 결제금액 : ${parseInt(row.refund_price * 1.1)}원`;
              }

              // 잔여 카운트가 환불금액보다 적은경우 환불금액 재계산해야 함.
              // SMS
              if (row.prod_name.includes('sms 충전')) {
                if ($scope.payDatas.remain_sms_cnt >= row.sale_cnt) {
                  row.refund_price = parseInt(row.sales);
                  diff_reason = `※ SMS 결제금액 : ${parseInt(row.refund_price * 1.1)}원`;
                } else {
                  row.refund_price = $scope.payDatas.remain_sms_cnt * 25;
                  row.sale_cnt = $scope.payDatas.remain_sms_cnt;
                  diff_reason = `※ SMS 카운트 사용으로 잔여카운트(${$scope.payDatas.remain_sms_cnt}) X 25원 X 부가세(1.1) : ${parseInt(row.refund_price * 1.1)}원`;
                }
              }

              if (row.prod_name.includes('email 충전')) {
                if ($scope.payDatas.remain_email_cnt >= row.sale_cnt) {
                  row.refund_price = parseInt(row.sales);
                  diff_reason = `※ Email 결제금액 : ${parseInt(row.refund_price * 1.1)}원`;
                } else {
                  row.refund_price = $scope.payDatas.remain_email_cnt * 10;
                  row.sale_cnt = $scope.payDatas.remain_email_cnt;
                  diff_reason = `※ Email 카운트 사용으로 잔여카운트(${$scope.payDatas.remain_email_cnt}) X 10원 X 부가세(1.1) : ${parseInt(row.refund_price * 1.1)}원`;
                }
              }
              if (row.prod_name.includes('atalk 충전')) {
                if ($scope.payDatas.remain_atalk_cnt >= row.sale_cnt) {
                  row.refund_price = parseInt(row.sales);
                  diff_reason = `※ 알림톡 결제금액 : ${parseInt(row.refund_price * 1.1)}원`;
                } else {
                  row.refund_price = $scope.payDatas.remain_atalk_cnt * 12;
                  row.sale_cnt = $scope.payDatas.remain_atalk_cnt;
                  diff_reason = `※ 알림톡 카운트 사용으로 잔여카운트(${$scope.payDatas.remain_atalk_cnt}) X 12원 X 부가세(1.1) : ${parseInt(row.refund_price * 1.1)}원`;
                }
              }
              if (row.prod_name.includes('번역 충전')) {
                if ($scope.payDatas.remain_trnsl_cnt >= row.sale_cnt) {
                  row.refund_price = parseInt(row.sales);
                  diff_reason = `※ 번역 결제금액 : ${parseInt(row.refund_price * 1.1)}원`;
                }
              }
              if (row.prod_name.includes('가격비교')) {
                // 결제당일에만 결제금액 전액 환불, 결제내용 적용 시작일이 오늘보다 큰경우도 전액 환불.
                if (row.sol_sdate > today.format('YYYY-MM-DD') || today.diff(moment(row.cash_time).endOf('day'), 'days') === 0) {
                  row.refund_price = row.sales,
                  diff_reason = '※ 가격비교 결제금액';
                } else {
                  const useDay = Math.abs(moment(row.sol_sdate).endOf('day').diff(today, 'days'));
                  const usePrice = useDay * insightDayPrice; // 가격 계산으로 30일 기준으로 한달씩 처리, 그 외 일할 계산
                  const refundPrice = row.sales - usePrice;

                  row.refund_price = parseInt(refundPrice);
                  diff_reason = `※ 가격비교 결제금액(${$filter('number')(parseInt(row.sales * 1.1))})-(사용일(${useDay})X일금액(${$filter('number')(insightDayPrice)})X부가세(1.1))`;
                }
              }

              addOrds(ord_nos, row, { diff_reason });
              addOrds(admin_ord_nos, row, { diff_reason });

              $scope.allRefuncYN = true;
              $scope.refundPrice += parseInt(row.refund_price) > 0 ? parseInt(row.refund_price) : 0;
            }
          }
        });

        $scope.ordNos = ord_nos;
        $scope.adminOrdNos = admin_ord_nos;

        // 번역 카운트 환불 가능 여부
        $scope.refundTrnslFlag = $scope.additionals.find(add => add.prod_name.includes('번역 충전') && add.sale_cnt > $scope.payDatas.remain_trnsl_cnt);
      });
    };

    /**
     * 환불요청
     */
    $scope.refundRequest = function (ord_no, payName) {
      if (data.pay_status != '결제완료') {
        commonSVC.showMessage('결제완료 상태의 결제정보만 환불요청이 가능합니다.');

        return false;
      }

      if (ord_no == '' && $scope.allRefuncYN === false) {
        commonSVC.showMessage('환불가능한 주문건이 없습니다.');

        return false;
      }

      if ($scope.payDatas.pay_title.indexOf('후불카운트') > -1) {
        commonSVC.showMessage('후불 카운트 결제건은 환불이 불가능합니다.');

        return false;
      }

      if (userInfo.user.user_grade !== '마스터' && $scope.payDatas.pay_email !== userInfo.user.email) {
        commonSVC.showMessage('마스터계정, 또는 결제한 사용자만 환불요청이 가능합니다.', `결제사용자 아이디 : ${$scope.payDatas.pay_email}`);

        return false;
      }

      if ($scope.payDatas.pay_title.includes('카운트 추가구매 ') && !$scope.preCntRefundableStatus) {
        commonSVC.showMessage('환불가능한 주문건이 없습니다.');

        return false;
      }

      // ord_no 가 빈값인경우 전체 환불이므로 전체 ord_no 값 담음.
      // TODO: 어드민모드일 때 조건 왜 있는지 확인해봐야함
      const sendOrdNos = $rootScope.adminMode && $scope.adminOrdNos.length ? $scope.adminOrdNos :
        $scope.ordNos.filter(o => o.refund_price > 0 && (!ord_no || o.ord_no === ord_no));

      if (!sendOrdNos.length) {
        commonSVC.showMessage('환불 실패', '환불 가능한 금액이 없어 환불 신청에 실패되었으나\n남은 기간 동안은 정상적인 이용이 가능합니다.');

        return false;
      }

      if (afterPaymentSolution && sendOrdNos.find(o => o.prod_group_type === '솔루션')) {
        commonSVC.showMessage('환불 실패', '해당 청구서 이후에 결제된 청구서가 있습니다.\n\n솔루션 결제의 경우 마지막 결제건이 아닌 결제 청구서 환불시 솔루션 만료일에 문제가 발생될수 있기 때문에 솔루션 환불요청은 마지막 결제 청구서로 진행해주십시오.');

        return false;
      }

      let refundPrice = 0;

      if (ord_no) { // 선택 환불
        // 가격비교 함께 결제 했을 때 솔루션만 환불할 경우 가격비교 함께 환불되어야 함 (가격 비교만 환불 가능)
        if (sendOrdNos.some(prod => prod.prod_group_type === '솔루션') && $scope.additionals.some(prod => prod.prod_name === '가격비교')) {
          if (!sendOrdNos.some(prod => prod.diff_reason.includes('가격비교'))) {
            const insightPay = angular.copy($scope.ordNos.find(p => p.diff_reason.includes('가격비교')));
            insightPay.diff_reason += '<br>ㄴ솔루션 환불 요청 시, 가격비교 부가서비스도 함께 환불 처리됩니다.';

            sendOrdNos.push(insightPay);
          }
        }

        refundPrice = sendOrdNos.reduce((prev, curr) => prev + curr.refund_price + Math.round(curr.refund_price / 10), 0);
      } else { // 전체 환불
        refundPrice = $scope.refundPrice + Math.round($scope.refundPrice / 10);
      }

      const params = {
        ord_no: sendOrdNos,
        sales: refundPrice,
        payName: payName,
        payMethod: payMethod
      };

      const modal = commonSVC.openModal('md', { data: params }, 'PayRefundRequestCtrl', 'views/pay/modals/refund_request.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          // 환불요청 완료후 환불요청 버튼을 환불상태에 맞게 변경해주어야 함.
          $scope.init();
        }
      });
    };

    /**
     * 청구서 인쇄
     */
    $scope.payBillPrint = function() {
      commonSVC.openModal('md', '', 'PayBillPrintCtrl', 'views/pay/modals/pay_bill_print.html');
    };

    /**
     * 찯 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
