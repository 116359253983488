/**
 * Created by harry on 2017. 8. 8..
 */
'use strict';

angular.module('gmpApp')
  .controller('invoiceTemplateCtrl', function ($scope, $rootScope, userInfo, data, commonSVC, $uibModalInstance, deliveryModel, deliverySVC, $timeout, invoiceTemplateSVC, logHistorySVC) {
    // dpi 계산할 높이 값
    // 대한통운
    //T007: BIXOLON 가로표준
    //T008: BIXOLON 가로표준 3단
    //T009: BIXOLON 가로표준 소형
    //T014: TSC 가로표준
    //T020: 가로표준 5인치
    // 한진
    //T010: BIXOLON 가로표준(NS형)
    //T021: BIXOLON 가로표준(NS_2P형, IS형)
    //T028: BIXOLON 가로표준(FS형)
    //T030: BIXOLON 가로표준(SF형)
    // 우체국
    //T017: A4 신형 3면
    //T018 : 신형 B형
    //T019: 신형 C형
    // 카카오T당일배송
    //T023: 구 송장지
    //T032: 신 송장지
    // 롯데택배
    //T025: 전용송장
    //T026: A4송장
    $scope.tabActive = 1;
    $scope.number = data.template?.template_no;
    // 택배사 운송장 도움말 페이지 번호
    const carrHelpBtnVal = {
      5: 13789, // 한진
      4: 13797, // CJ대한통운
      6: 13798, // 우체국
      8: 13799, // 롯데
      956: 13800  // 카카오T당일배송
    };

    // 맞춤양식 미지원 양식
    const customFormNotSupport = ['T026'];
    // 맞춤설정은 맞춤양식 지원 양식만 활성화
    $scope.customSettingValid = customFormNotSupport.includes(data.template?.template_type_cd);
    // 로그용 데이터
    let oldData = {};
    const logField = {

      template_name: '양식 이름',
      template_type_cd: '양식 종류',
      main_item_name: '대표 품목명',
      sale_name_sub: '상품명 표기방식(상품관리코드)',
      sale_name_type: '상품명 표기방식(상품명)',
      print_set_yn: '세트 표기방식',
      sku_print_type: 'SKU상품명 표기방식',
      gift_yn: '사은품 출력여부',
      bundle_division_cnt: '분할인쇄 설정',
      bundle_ship_cost_type: '묶음배송 배송비',
      print_prod_cnt_yn: '총 출고 수량',
      print_out_cnt_yn: '총 출고 수량 인쇄여부',
      print_seq_yn: '인쇄 순번',
      print_order_name_yn: '주문자명 출력',
      print_receive_time_yn: '접수일시 출력',
      order_name_masking_field: '주문자명 마스킹',
      maskingFields: '수령자정보 마스킹',
      send_ol_prod_cnt_yn: '내품수량 전송여부',
      // print_sort_criteria: '정렬인쇄 설정',

      box_type: '박스정보',
      box_weight: '박스무게',
      box_volume: '박스크기',
      box_count: '박스개수',
      cod_use_yn: 'COD 사용여부',
      mountain_noti_yn: '도서산간 알림',
      ship_jeju_type: '제주도 배송비',
      sett_ship_free_txt: '정산구분',
      sett_ship_cod_txt: '착불 시 출력내용',
      contract_price: '계약운임',
      sett_ship_method: '정산구분',
      ship_cod_cost: '착불 시 운임비',
      sender_name: '보내는 분',
      from_zipcd: '우편번호',
      from_addr1: '주소',
      from_addr2: '상세주소',
      show_invoice: '주문수량',

      custom_data: '맞춤설정',

      pre_invoice_barcode_yn: '예비 바코드 출력여부'
    };

    const height = {
      T007: 101.5,
      T008: 99,
      T009: 102,
      T010: 101.5,
      T010_new: 101.5,
      T014: 101.5,
      T017: 199.5,
      T018: 106.5,
      T019: 106.5,
      T020: 104,
      T021: 101.5,
      T021_new: 101.5,
      T022: 101.5,
      T023: 100,
      T025: 100,
      T026: 105,
      T027: 100,
      T028: 100,
      T030: 85,
      T032: 100,
      T033: 108,
      T034: 100,
      T035: 100,
    };

    $scope.font = {
      fontSizes: [...Array(31).keys()].slice(6).map(i => i + 'pt'), // 변경 가능한 폰트 크기 리스트
      defaultFontSize: '9pt' // 폰트 크기 초깃값
    };

    $scope.dropdown_sub_list = [
      'SKU상품명',
      'SKU상품명(속성)',
      '판매자관리코드 + SKU상품명(속성)',
      'SKU코드 + SKU상품명(속성)',
      '재고관리코드 + SKU상품명(속성)',
      '쇼핑몰주문번호 + SKU상품명(속성)'
    ];

    $scope.show_invoice = false; // 맞춤설정 영역 보여줄지 여부

    // 수령자정보 및 주문자명 마스킹 영역
    // 롯데택배 마스킹 처리 변경에 따라 핸드폰번호, 전화번호 필드 hideType에 ['T025', 'T026', 'T027'] 추가
    $scope.maskingFields = [
      {
        key: 'to_addr1',
        name: '주소',
        selected: false,
        position: 'upper',
        hideType: ['T018', 'T019']
      },
      {
        key: 'to_addr1',
        name: '주소',
        selected: false,
        position: 'lower'
      },
      {
        key: 'order_name',
        name: '주문자명',
        selected: false
      }
    ];

    const input = {
      text_content: '',
      variable_content: '',
      columnType: 'text',
      style: {
        top: '',
        left: '',
        width: '',
        height: '',
        fontSize: $scope.font.defaultFontSize
      },
      isbold: true,
      print_set_yn: '0',
      sku_print_type: 'all',
      print_prod_cnt_yn: 0,
    };
    let id = 0;
    let dpi = 0;
    $scope.id;

    $scope.inputFlag = {
      isCanvasEdit: false // 선택박스 편집모드 여부
    };

    // 재고관리여부
    $scope.isManageStock = $rootScope.user_profile.sol_stock > 0;
    $scope.isAs = !!data.isAs;
    $scope.delivery = data.delivery ? angular.copy(data.delivery) : {};
    $scope.pageType = data.pageType;
    $scope.delivery.carr_no += ''; // 문자열로 처리
    $scope.parent = data.parent;
    $scope.original_template;
    $scope.wait = false;

    /**
   * $scope.selectedTemp.custom_data를 기반으로 텍스트박스를 custom area에 추가
   */
    $scope.canvasRender = () => {
      $scope.selectedTemp.custom_data.forEach(col => {
        if (col.val) {
          $scope.input = {
            text_content: col.columnType === 'text' ? col.val : '',
            variable_content: col.columnType === 'variable' ? $scope.column.find(column => column.val === col.val) : '',
            columnType: col.columnType,
            print_set_yn: col.print_set_yn,
            sku_print_type: col.sku_print_type,
            not_print_salecnt: col.not_print_salecnt,
            style: { fontSize: $scope.font.defaultFontSize, ...col.style },
          };
          if (col.val === 'print_prod_cnt') {
            $scope.input.print_prod_cnt_yn = +col.print_prod_cnt_yn;
          }
          if (col.style.fontFamily) {
            $scope.input.isbold = false;
          } else {
            $scope.input.isbold = true;
          }
          $scope.canvasAdd(true);
        } else if (col.dataIndex) {
          Object.assign(document.querySelector(`#invoice_template_edit_modal div[data-index='${col.dataIndex}']`).style, col.style);
        }
      });

      $scope.input = angular.copy(input);
    };

    /**
   * Custom area에 추가한 선택박스 $scope.selectedTemp.custom_data에 적용
   */
    $scope.canvasApply = () => {
      const customList = [];
      let err = false;

      if (!document.querySelector('#editableArea')) {
        return;
      }
      const editable = document.querySelector('#editableArea').getBoundingClientRect();

      document.querySelectorAll('#invoice_template_edit_area .customs').forEach((elm) => {
        const custom = {
          val: $(elm).hasClass('customs') ? elm.getAttribute('custom-data') : '',
          columnType: elm.getAttribute('custom-type'),
          print_set_yn: elm.getAttribute('print_set_yn'),
          sku_print_type: elm.getAttribute('sku_print_type'),
          not_print_salecnt: elm.getAttribute('not_print_salecnt'),
          repeatKey: elm.getAttribute('repeatKey') || '',
          dataIndex: elm.getAttribute('data-index')
        };

        if (custom.val === 'print_prod_cnt') {
          custom.print_prod_cnt_yn = elm.getAttribute('print_prod_cnt_yn');
        }

        const { top, right, left, bottom } = elm.getBoundingClientRect();

        // 위치 확인.
        if (!$scope.isAdminEdit && (left < editable.left || right > editable.right || bottom > editable.bottom || top < editable.top)) {
          err = true;
        }

        custom.style = { ...getPosition(elm), fontSize: elm.style.getPropertyValue('font-size') };
        if (elm.getAttribute('isbold') === 'false') {
          custom.style.fontFamily = 'unset';
        }
        customList.push(custom);
      });
      $scope.selectedTemp.custom_data = $scope.parent.selectedTemp.custom_data = customList;

      if (err) {
        commonSVC.showMessage('실패', '맞춤설정 내부 텍스트 박스는 초록색으로 표시된 수정가능한 영역 내부에 위치해 있어야 합니다.');
      }

      return err;
    };

    /**
   * 템플릿 선택
   */
    $scope.selectTemplate = (reset = true) => {
      // 맞춤설정은 맞춤양식 지원 양식만 활성화
      $scope.customSettingValid = customFormNotSupport.includes($scope.selectedTemp.template_type_cd);
      // 맞춤양식 미지원 양식은 맞춤설정 체크해제
      if ($scope.customSettingValid) {
        $scope.show_invoice = false;
      }
      if (reset) {
        // 커스텀 박스 초기화
        $scope.selectedTemp.custom_data = [];
        // 템플릿 변경 시 특정 템플릿에 대해서 마스킹 필드 기본값 모두 선택 처리 및 다른 템플릿의 경우에는 마스킹 필드 모두 선택해제 처리
        if ((deliverySVC.invoiceTemplate[$scope.delivery.carr_no].maskingAllType || []).includes($scope.selectedTemp.template_type_cd)) {
          $scope.maskingFields.forEach(field => field.selected = true);
          // 주문자명 마스킹 false 처리
          $scope.maskingFields.find(field => field.key === 'order_name').selected = false;
        } else {
          $scope.maskingFields.forEach(field => field.selected = false);
        }
      }
      $scope.parent.templateSelect($scope.selectedTemp, false, true);
      // 3단용 데이터
      if (['T008', 'T017'].indexOf($scope.selectedTemp.template_type_cd) > -1) {
        $scope.shipmentList = [[$scope.parent.selectList[0]]];
      } else {
        $scope.selectList = [$scope.parent.selectList[0]];
      }

      $scope.view = `views/order/shipment/invoice_type/${$scope.selectedTemp.template_type_cd}.html`;
    };

    /**
   * 초기화 함수
   */
    function init() {
      $scope.carrGuidNo = carrHelpBtnVal[$scope.delivery.carr_no];
      $scope.original_template = $scope.parent.selectedTemp;
      if ($scope.pageType === 'add') {
        $scope.selectedTemp = {
          carr_no: $scope.delivery.carr_no,
          carr_name: $scope.delivery.carr_name,
          carr_id: $scope.delivery.carr_id,
          carr_id_sub: $scope.delivery.carr_id_sub,
          gift_yn: 0,
          bundle_division_cnt: 0,
          contract_price: 0,
          sale_name_sub: '',
          print_set_yn: '0',
          print_out_cnt_yn: 0,
          print_prod_cnt_yn: 0,
          print_seq_yn: 0,
          custom_data: [],
          sku_print_type: 'all',
          send_ol_prod_cnt_yn: 1,
          print_order_name_yn: 0,
          print_receive_time_yn: 0,
          main_item_name: '',
          pre_invoice_barcode_yn: 0,    // 예비 송장 바코드 출력여부
          // print_sort_criteria: '사용안함' // 정렬인쇄 여부
        };

        angular.extend($scope.selectedTemp, angular.copy(deliverySVC.invoiceTemplate[$scope.delivery.carr_no].defaultData));
      } else if ($scope.pageType === 'edit') {
        $scope.isAdminEdit = data.admin;
        $scope.selectedTemp = data.template ? angular.copy(data.template) : {};
        $scope.selectedTemp.sale_name_sub = $scope.selectedTemp.sale_name_sub || '';
        $scope.selectedTemp.print_set_yn = String($scope.parent.print_set_yn);
        // 사용하지 않는 데이터삭제
        delete $scope.selectedTemp.template_type_name;
        delete $scope.selectedTemp.carr_info;
        delete $scope.selectedTemp.carr_id_sub;
        if (!$scope.selectedTemp.box_type) {
          delete $scope.selectedTemp.box_type;
        }

        // 수령자정보 및 주문자명 마스킹 영역 선택처리
        const maskingList = $scope.selectedTemp.maskingFields ? $scope.selectedTemp.maskingFields.split(',') : [];

        maskingList.forEach(masking => {
          const field = $scope.maskingFields.find(field => `${field.position}_${field.key}` === masking || field.key === masking);

          if (field) {
            field.selected = true;
          }
        });

        $scope.selectedTemp.print_order_name_yn = $scope.selectedTemp.print_order_name_yn || 0;
        $scope.selectedTemp.contract_price = $scope.selectedTemp.contract_price || 0;

      }

      $scope.selectedTemp.custom_data = $scope.selectedTemp.custom_data || [];
      if ($scope.selectedTemp.custom_data.length) {
        $scope.selectedTemp.custom_data = $scope.selectedTemp.custom_data.map(item => ({
          ...item, ...(!item.not_print_salecnt && { not_print_salecnt: '0' })
        }));
        $scope.show_invoice = true;
      }

      $scope.invoiceTemplate = angular.copy(deliverySVC.invoiceTemplate[$scope.delivery.carr_no]);
      $scope.isEdit = true;

      // 특정 업체 대한 통운 박스타입 이형 타입 선택 가능하도록 추가
      // 이형의 경우 계약에 등록이 되어 있어야 하며, 계약지점에 확인 필요
      if ($scope.delivery.carr_no === '4' && ([1, 640, 812, 4804, 4521, 11313, 13322, 36160, 31365, 74097].includes(userInfo.user.sol_no))) {
        $scope.invoiceTemplate.boxType.push({ name: '이형', value: '이형' });
      }

      // 특정 업체 대한 통운 박스타입 취급제한 타입 선택 가능하도록 추가
      // 이형의 경우 계약에 등록이 되어 있어야 하며, 계약지점에 확인 필요
      if ($scope.delivery.carr_no === '4' && ([1, 640, 31365].includes(userInfo.user.sol_no))) {
        $scope.invoiceTemplate.boxType.push({ name: '취급제한', value: '취급제한' });
      }

      // 특정 업체 대한 통운 박스타입 대2 타입 선택 가능하도록 추가
      // 대2의 경우 계약에 등록이 되어 있어야 하며, 계약지점에 확인 필요 2022-02-14 Denver
      if ($scope.delivery.carr_no === '4' & ([1, 640, 4521, 11313, 13322, 36160, 31365, 5898, 57924, 74097, 143023].includes(userInfo.user.sol_no))) {
        $scope.invoiceTemplate.boxType.push({ name: '대2', value: '대2' });
      }

      // 맞춤설정 영역 내부 운송장 커스터마이징 뷰가 로드된 이후 해당 뷰의 dpi 계산
      // 또한 custom_data 리스트를 iterate 하면서 커스텀 텍스트 박스를 한개씩 생성
      $timeout(() => {
        dpi = $('#invoice_template_edit_area .print-wrapper').height() / height[$scope.selectedTemp.template_type_cd];
        $scope.canvasRender();
      }, 1000);
      if ($scope.isAdminEdit) {
        // 어드민 수정모드일때만 전부 수정가능하도록 처리
        $('#invoice_template_edit_area .print-box').resizable().draggable().addClass('customs');
      }
      $scope.today = moment().format('YYYY-MM-DD');
      // 한진택배, CJ대한통운, 우체국택배, 롯데택배, 카카오T당일배송 맞춤설정 도움말 노출
      $scope.isCustomCarr = [4, 5, 6, 8, 956].includes(+$scope.delivery.carr_no);
      // 재고관리 미사용 반영
      $scope.column = invoiceTemplateSVC.column.filter(c => c.stock_yn <= $rootScope.user_profile.sol_stock);
      $scope.input = angular.copy(input);
      $scope.type = 'preview';
      $scope.selectTemplate(false);
      oldData = angular.copy($scope.selectedTemp); // 수정 로그용 원본 데이터
    }

    init();

    $scope.changeSubName = () => {
      if ($scope.selectedTemp.sale_name_sub === 'SKU코드' || $scope.selectedTemp.sale_name_sub === '재고관리코드') {
        $scope.selectedTemp.sale_name_type = 'SKU상품명';
      }
      $scope.selectedTemp.print_set_yn = '0';
      $scope.selectedTemp.sku_print_type = 'opt_sale_cnt';
    };

    $scope.changeTypeName = () => {
      $scope.selectedTemp.print_set_yn = '0';
    };

    $scope.changePrintSetYn = () => {
      if (!['2', '4', '6', '8', '9'].includes($scope.selectedTemp.print_set_yn)) {
        $scope.selectedTemp.sku_print_type = 'all';
      }
    };

    /**
     * 양식 저장
     */
    $scope.submit = async () => {
      delete $scope.selectedTemp?.epost_cust_id;
      if ($scope.inputFlag.isCanvasEdit) {
        const confirm = await commonSVC.showConfirm('맞춤설정에 수정중인 항목이 있습니다.\n 수정된 사항으로 반영하시겠습니까?');
        if (confirm) {
          const re = $scope.canvasEdit();

          if (!re) {
            return false;
          }
        }
      }

      const senderTelNumber = $scope.selectedTemp.sender_tel?.replace(/[^0-9]/g, '');

      // 대한통운은 발송자 전화번호가 필수이기 때문에 없는경우 에러.
      if (data.delivery.carr_no !== 956 && data.delivery.carr_no !== 107 && data.delivery.carr_no !== 1007 && (!senderTelNumber || senderTelNumber.length < 8)) {
        commonSVC.showMessage('발송인 전화번호 에러!', '보내는분 전화번호는 필수 입니다.\n올바른 전화번호로 입력해주십시오.');
        $scope.wait = false;

        return false;
      }
      // 주문자명 인쇄 안함 이면 주문자명 마스킹 해제
      if ($scope.selectedTemp.print_order_name_yn == 0) {
        $scope.maskingFields.find(({ key }) => key === 'order_name').selected = false;
      }

      if (!oldData.set_no && oldData.sku_pack?.includes(' 외 ')) {
        oldData.sku_pack = oldData.sku_cd.split(';').join();

      }

      // 마스킹필드 선택사항 저장
      $scope.selectedTemp.maskingFields = $scope.maskingFields.filter(({ selected }) => selected).map(field => field.position ? `${field.position}_${field.key}` : field.key);
      // 켄버스 상태 저장
      if ($scope.canvasApply()) {
        return;
      }

      if ($scope.deliveryTemplateForm.$valid) {
        if ($scope.pageType === 'add') {
          insert();
        } else if ($scope.pageType === 'edit') {
          // 수정 값 한글로 치환(log 용)
          const fixedValue = replaceValue(logField, { ...oldData }, { ...$scope.selectedTemp });
          $scope.selectedTemp.log_contents = logHistorySVC.compareReturnValue(logField, fixedValue.pastData, fixedValue.newData);
          update();
        }
      }
    };

    function replaceValue(compObj, pastData, newData) {

      // 로그용 데이터
      const fixedDataValues = {
        mountain_noti_yn: [
          { name: '적용 안함', value: '0' },
          { name: '적용함', value: '1' },
        ],
        cod_use_yn: [
          { name: '사용 안함', value: '0' },
          { name: '사용 함', value: '1' },
        ],
        print_prod_cnt_yn: [
          { name: '품목 수량', value: '0' },
          { name: '출고 수량', value: '1' },
          { name: '품목 + 출고수량', value: '2' }
        ],
        sku_print_type: [
          { name: '건 별 출고수량과 주문수량 모두 출력합니다.', value: 'all' },
          { name: '주문 수량만 출력합니다.', value: 'opt_sale_cnt' },
          { name: '총 출고 수량만 출력합니다.(건별 출고수량 * 주문수량)', value: 'pack_unit' },
          { name: '세트 출고수량과 주문수량 모두 출력합니다.', value: 'set_all' },
          { name: '세트 총 출고수량만 출력합니다.', value: 'set_pack_unit' }
        ],
        gift_yn: [
          { name: '적용안함', value: '0' },
          { name: '사은품(쇼핑몰에서 수집)', value: '1' },
          { name: '규칙사은품(수집 후 사은품규칙에 의해 적용된 값)', value: '2' }
        ],
        print_set_yn: [
          { name: '선택 안함', value: '0' },
          { name: '세트 상품인 경우 세트 상품명으로 출력합니다.', value: '2' },
          { name: '세트 상품 또는 다중 매칭인 경우 SKU상품명을 한 줄로 이어서 출력합니다.', value: '3' },
          { name: '세트 상품인 경우 판매자관리코드, 세트 상품명으로 출력합니다.', value: '4' },
          { name: '세트 상품 또는 다중 매칭인 경우 판매자관리코드, SKU상품명을 한 줄로 이어서 출력합니다.', value: '5' },
          { name: '세트 상품인 경우 세트 상품코드, 세트 상품명으로 출력합니다.', value: '6' },
          { name: '세트 상품 또는 다중 매칭인 경우 SKU코드, SKU상품명을 한 줄로 이어서 출력합니다.', value: '7' },
          { name: '세트 상품인 경우 재고관리코드, 세트 상품명으로 출력합니다.', value: '8' },
          { name: '세트 상품인 경우 대표상품의 재고관리코드, 세트 상품명으로 출력합니다.', value: '9' },
          { name: '세트 상품 또는 다중 매칭인 경우 재고관리코드, SKU상품명을 한 줄로 이어서 출력합니다.', value: '10' }
        ],
        print_out_cnt_yn: [
          { name: '인쇄함', value: '0' },
          { name: '인쇄안함', value: '1' }
        ],
        print_seq_yn: [
          { name: '인쇄함', value: '0' },
          { name: '인쇄안함', value: '1' }
        ],
        send_ol_prod_cnt_yn: [
          { name: '인쇄함', value: '1' },
          { name: '인쇄안함', value: '0' }
        ],
        print_order_name_yn: [
          { name: '인쇄함', value: '1' },
          { name: '인쇄안함', value: '0' }
        ],
        print_receive_time_yn: [
          { name: '인쇄함', value: '1' },
          { name: '인쇄안함', value: '0' }
        ],
        pre_invoice_barcode_yn: [
          { name: '인쇄함', value: '1' },
          { name: '인쇄안함', value: '0' }
        ]
      };

      function fixedData(data, values) {
        const replaceData = values.find((item) => item.value === data?.toString());

        return replaceData ? replaceData.name : data;
      }
      for (const key in compObj) {
        // 숫자 값을 한글로 치환 예: '1' -> '적용함'
        const obj = fixedDataValues[key];
        if (obj) {
          pastData[key] = fixedData(pastData[key], obj);
          newData[key] = fixedData(newData[key], obj);
        }
        // 주문자명 마스킹
        if (key == 'order_name_masking_field') {
          pastData[key] = pastData.maskingFields?.split(',').find(item => item == 'order_name') ? '주문자명' : '선택안함';
          newData[key] = newData.maskingFields?.find(item => item == 'order_name') ? '주문자명' : '선택안함';
        }

        // 양식 종류 코드 한글로 치환 ex)'T020' > '가로표준 5인치'
        if (key == 'template_type_cd' && deliverySVC.invoiceTemplate[data.delivery.carr_no].invoiceType) {
          pastData[key] = fixedData(pastData[key], deliverySVC.invoiceTemplate[data.delivery.carr_no].invoiceType);
          newData[key] = fixedData(newData[key], deliverySVC.invoiceTemplate[data.delivery.carr_no].invoiceType);
        }

        if (key == 'custom_data') {
          // 맞춤설정 로그
          const compColumn = $scope.column.map(({ header, val, stock_yn }) => ({ name: header, value: val, stock_yn }));

          // 영문 > 한글로 치환
          const processCustomData = (data, compColumn) => {
            return data.reduce((result, data) => {
              const prefix = data.columnType === 'text' ? '고정' : '변수';
              const prefixVal = fixedData(data.val, compColumn);
              result.push(`${prefix}_${prefixVal}`);

              return result;
            }, []);
          };
          const pastCustom = processCustomData(pastData[key], compColumn);
          const newCustom = processCustomData(newData[key], compColumn);

          pastData[key] = pastCustom.length ? pastCustom.sort().join(', ') : '해제';
          newData[key] = newCustom.length ? newCustom.sort().join(', ') : '해제';
        }

        if (key == 'maskingFields') {
          // maskingFields에 "주문자명" 마스킹여부가 있어서 필터함
          const recipient_masking_fields = $scope.maskingFields.filter(({ position }) => position);
          // 마스킹설정 로그
          // 수정데이터는 selected: true || false 필터로 쉽게 가공되나 원본데이터는 문자열로 'upper_to_name, lower_to_addr1'처럼 최대 8개까지 들어오기 때문에 추가가공이 필요
          let newFields = recipient_masking_fields.filter(({ selected }) => selected)
            .map(field => `${field.position}_${field.name}`)
            .map(str => str.replace(/upper/g, '상단').replace(/lower/g, '하단'));

          recipient_masking_fields.forEach(field => {
            field.value = `${field.position}_${field.key}`;
            field.name = `${field.position.replace(/upper/g, '상단').replace(/lower/g, '하단')}_${field.name}`;
          });

          let oldFields = pastData[key] ? pastData[key].split(',').filter(item => item.trim() !== '' && item != 'order_name') : [];

          if (oldFields.length) {
            for (let i = 0; i < oldFields.length; i++) {
              oldFields[i] = fixedData(oldFields[i], recipient_masking_fields);
            }
          }
          const removeKeywordUpper = ['상단_'];
          const removeKeywordLower = ['하단_'];

          // T020, T023 양식은 상단영역과 하단영역이 나뉘지않는데 값은 upper_, lower_가 다 들어와 해당 키워드 삭제 및 중복키워드 삭제처리
          const getFieldValues = (fields, removeKeywords) => {
            return fields
              .filter((field) => !removeKeywords.some((keyword) => field.startsWith(keyword)))
              .map((field) => field.split('_'))
              .map((field) => field.filter((_, index) => index === 1))
              .reduce((acc, cur) => acc.concat(cur), [])
              .sort()
              .join(', ');
          };

          switch (oldData.template_type_cd) {
            case 'T020':
              oldFields = getFieldValues(oldFields, removeKeywordLower);
              break;
            case 'T023':
            case 'T032':
              oldFields = getFieldValues(oldFields, removeKeywordUpper);
              break;
            default:
              oldFields = oldFields.sort().join(',');
              break;
          }
          pastData[key] = oldFields ? oldFields : '선택안함';

          switch ($scope.selectedTemp.template_type_cd) {
            case 'T020':
              newFields = getFieldValues(newFields, removeKeywordLower);
              break;
            case 'T023':
            case 'T032':
              newFields = getFieldValues(newFields, removeKeywordUpper);
              break;
            default:
              newFields = newFields.sort().join(',');
              break;
          }
          newData[key] = newFields ? newFields : '선택안함';
        }
      }

      return { newData: { ...newData }, pastData: { ...pastData } };
    }

    /**
   * 양식 추가
   */
    function insert() {
      delete $scope.selectedTemp?.epost_cust_id;
      deliveryModel.InsertDeliveryTemplate({ ...$scope.selectedTemp, custom_data: JSON.stringify($scope.selectedTemp.custom_data) }, function(state) {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '택배사 양식 등록 성공');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '택배사 양식 등록 실패');
        }
      });
    }

    /**
   * 양식 수정
   */
    function update() {
      delete $scope.selectedTemp?.epost_cust_id;
      const params = {
        ...$scope.selectedTemp,
        custom_data: JSON.stringify($scope.selectedTemp.custom_data),
        carr_no: $scope.selectedTemp.carr_no.toString(),
        log_contents: Array.isArray($scope.selectedTemp.log_contents) ? $scope.selectedTemp.log_contents : []
      };
      deliveryModel.UpdateDeliveryTemplate(params, state => {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '택배사 양식 수정 성공');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '택배사 양식 수정 실패');
        }
      });
    }

    $scope.cancel = function() {
      $scope.parent.selectedTemp = $scope.original_template;
      $uibModalInstance.dismiss('cancel');
    };

    $scope.showAlert = function() {
      if ($scope.customSettingValid) {
        commonSVC.showMessage('해당 양식은 맞춤설정을 지원하지 않습니다.');
      } else {
        commonSVC.showMessage('필수값을 모두 입력하여 주십시오.');
      }
    };

    /**
     * 미리보기
     */
    $scope.print = _.throttle(async () => {
      if ($scope.inputFlag.isCanvasEdit) {
        const confirm = await commonSVC.showConfirm('맞춤설정에 수정중인 항목이 있습니다.\n 수정된 사항으로 반영하시겠습니까?');

        if (confirm) {
          const re = $scope.canvasEdit();

          if (!re) {
            return false;
          }
        }
      }

      if ($scope.canvasApply()) {
        return;
      }

      $scope.wait = true;
      // 주문자명 인쇄 안함 이면 주문자명 마스킹 해제
      if ($scope.selectedTemp.print_order_name_yn == 0) {
        $scope.maskingFields.find(({ key }) => key === 'order_name').selected = false;
      }
      $scope.selectedTemp.maskingFields = $scope.maskingFields.filter(({ selected }) => selected).map(field => field.position ? `${field.position}_${field.key}` : field.key).join(',');

      // digest cycle때문에 아래 template의 ng-init 동작 안해서 호출
      $timeout(() => {});

      await $scope.parent.templateSelect($scope.selectedTemp, false, true);
      await new Promise(resolve => $timeout(() => resolve(), 600)); // 바코드가 랜더링될 때까지 대기시간
      $scope.parent.setCustom();

      const result = await $scope.parent.print('preview', $scope.selectedTemp);
      $scope.wait = false;

      // 인쇄 불가능한 경우 확인 모달 뜰 때 취소 시 false 리턴, 확인 시 true 시에 양식 수정 모달 닫아줌, 인쇄 성공 시 undefined 리턴
      if (result) {
        $uibModalInstance.close();
      }
    }, 1500, { trailing: false });

    /**
     * 선택박스 클릭 이벤트
     */
    function clickEvent(elm) {
      const element = elm.closest('div.print-box');

      if ($scope.inputFlag.isCanvasEdit) {
        return false;
      } else if (!$scope.inputFlag.isCanvasEdit && $scope.id === element.id) {
        // 선택영역 해제
        document.querySelector('.print-box.custom-selected').classList.remove('custom-selected');
        // 선택값 초기화
        $scope.id = null;
        $scope.input = angular.copy(input);
        $scope.inputFlag.isCanvasEdit = false;
      } else {
        if (element.getAttribute('custom-type') === 'text') {
          $scope.input.text_content = element.innerText;
          $scope.input.variable_content = '';
          $scope.input.columnType = 'text';
        } else {
          $scope.input.variable_content = $scope.column.find(column => column.header === element.innerText);
          $scope.input.text_content = '';
          $scope.input.columnType = 'variable';
          $scope.input.print_set_yn = element.getAttribute('print_set_yn');
          $scope.input.not_print_salecnt = element.getAttribute('not_print_salecnt') == '1';
          $scope.input.sku_print_type = element.getAttribute('sku_print_type');
          if ($scope.input.variable_content.val === 'print_prod_cnt') {
            $scope.input.print_prod_cnt_yn = +element.getAttribute('print_prod_cnt_yn');
          }
        }

        $scope.input.style.fontSize = element.style.getPropertyValue('font-size');
        $scope.input.isbold = element.getAttribute('isbold') === 'true';

        const selected = document.querySelector('.print-box.custom-selected');

        if (selected) {
          selected.classList.remove('custom-selected');
        }

        element.classList.add('custom-selected');

        $scope.id = element.id;
      }

      // 변경사항 적용
      $timeout(() => {});
    }

    /**
     * 선택박스 삭제
     */
    $scope.canvasRemove = () => {
      const elm = document.getElementById($scope.id);

      if (elm) {
        elm.parentNode.removeChild(elm);
      }

      $scope.id = null;
      $scope.input = angular.copy(input);

      $timeout(() => {});
    };

    /**
     * 선택박스 수정 취소
     */
    $scope.canvasEditCancel = () => {
      const id = $scope.id;

      $scope.inputFlag.isCanvasEdit = false;
      $scope.id = false;

      // 원래 데이터로 초기화
      clickEvent(document.getElementById(id));
    };

    /**
     * 선택박스 수정
     */
    $scope.canvasEdit = () => {
      const elm = document.getElementById($scope.id);

      // 고정텍스트인데 텍스트가 없으면 알럿
      if ($scope.input.columnType === 'text' && !$scope.input.text_content) {
        commonSVC.showMessage('텍스트를 입력해주세요');

        return false;
      }

      // innerText 로 변경시 resize엘리먼트까지 날라감.
      elm.firstChild.replaceData(0, -1, $scope.input.columnType === 'text' ? $scope.input.text_content : $scope.input.variable_content.header);
      elm.setAttribute('custom-data', $scope.input.columnType === 'text' ? $scope.input.text_content : $scope.input.variable_content.val);
      elm.setAttribute('repeatKey', $scope.input.columnType !== 'text' && $scope.input.variable_content.repeatKey ? $scope.input.variable_content.repeatKey : '');
      elm.setAttribute('custom-type', $scope.input.columnType);
      elm.setAttribute('print_set_yn', String(+$scope.input.print_set_yn));
      elm.setAttribute('sku_print_type', $scope.input.sku_print_type);
      elm.setAttribute('not_print_salecnt', String(+$scope.input.not_print_salecnt));
      elm.setAttribute('isbold', $scope.input.isbold);
      elm.style.setProperty('font-size', `${$scope.input.style.fontSize || $scope.font.defaultFontSize}`, 'important');

      if ($scope.input.variable_content.val === 'print_prod_cnt') {
        elm.setAttribute('print_prod_cnt_yn', $scope.input.print_prod_cnt_yn);
      }

      $scope.inputFlag.isCanvasEdit = false;

      return true;
    };

    /**
     * 선택박스 추가
     */
    $scope.canvasAdd = (init) => {
      if ($scope.input.columnType === 'text' && !$scope.input.text_content) {
        commonSVC.showMessage('실패', '텍스트를 입력해주세요.');

        return false;
      }
      if ($scope.input.columnType === 'variable' && !$scope.input.variable_content) {
        commonSVC.showMessage('실패', '변수를 선택해주세요.');

        return false;
      }

      const elm = document.createElement('div');

      elm.innerText = $scope.input.columnType === 'text' ? $scope.input.text_content : $scope.input.variable_content.header;

      elm.setAttribute('custom-data', $scope.input.columnType === 'text' ? $scope.input.text_content : $scope.input.variable_content.val);

      if ($scope.input.columnType !== 'text' && $scope.input.variable_content.repeatKey) {
        elm.setAttribute('repeatKey', $scope.input.variable_content.repeatKey);
      }

      elm.setAttribute('custom-type', $scope.input.columnType);
      elm.setAttribute('print_set_yn', String(+$scope.input.print_set_yn));
      elm.setAttribute('sku_print_type', $scope.input.sku_print_type);
      elm.setAttribute('not_print_salecnt', String(+$scope.input.not_print_salecnt));
      elm.setAttribute('class', 'print-box customs');
      elm.setAttribute('id', `custom_div_${id}`);
      elm.setAttribute('isbold', $scope.input.isbold);

      if ($scope.input.variable_content.val === 'print_prod_cnt') {
        elm.setAttribute('print_prod_cnt_yn', $scope.input.print_prod_cnt_yn);
      }

      // 수정 모달에서 초기화
      if (init) {
        Object.assign(elm.style, $scope.input.style);
      } else {
        // 추가 버튼 클릭 시 기본 위치 설정
        elm.style.cssText = 'top:0;left:0;width:30mm;height:10mm;';
      }
      // 폰트 사이즈 설정
      elm.style.setProperty('font-size', $scope.input.style.fontSize, 'important');

      // 폰트사이즈가 클 때 텍스트 상단이 잘려보이는 문제 해결을 위해 padding 추가
      Object.assign(elm.style, { 'padding-top': '0.3em' });
      // child 추가
      document.querySelector('#invoice_template_edit_area .print-wrapper').appendChild(elm);

      // 클릭 이벤트시 포커싱 처리
      elm.addEventListener('mousedown', event => { clickEvent(event.target); });

      $('#invoice_template_edit_area .customs').resizable().draggable();

      if (!init) {
        $timeout(() => {
          clickEvent(elm);
        });
      }

      id++;
    };

    /**
     * 컬럼 타입 변경시 초기화
     */
    $scope.changeColumnType = (type) => {
      if (type === 'text') {
        $scope.input.variable_content = '';
      } else {
        $scope.input.text_content = '';
      }

      $timeout(() => {
        // 변경사항 적용
        $('#custom_type').trigger('change');
        $('#custom_type_text').trigger('change');
        $('#custom_type_variable').trigger('change');
      });
    };

    /**
     * 픽셀로 밀리미터 구하기
     */
    function pxToMm (px) {
      return ((px < 0 ? 0 : px) / dpi).toFixed(2);
    }
    // 위치 스타일구하기
    function getPosition (elm) {

      return {
        top: `${pxToMm(parseInt($(elm).css('top')))}mm`,
        left: `${pxToMm(parseInt($(elm).css('left')))}mm`,
        width: `${pxToMm(parseInt($(elm).css('width')))}mm`,
        height: `${pxToMm(parseInt($(elm).css('height')))}mm`,
      };
    }

    /**
     * Custom area 변경사항 리셋
     */
    $scope.resetCustomArea = () => {
      $scope.inputFlag.isCanvasEdit = false;
      $scope.show_invoice = !$scope.show_invoice;
      $('#invoice_template_edit_area .print-wrapper.print-box-bg > .print-box').remove();
      $scope.input = angular.copy(input);
      $scope.selectTemplate(false);
    };

    function deleteTextBoxByKey(e) {
      if (['Delete', 'Backspace', 'Del'].includes(e.key) && e.target.nodeName !== 'INPUT') {
        $scope.canvasRemove();
      }
    }

    /**
     * Delete 키를 눌렀을 때 선택되어 있는 선택박스 삭제
     */
    document.addEventListener('keydown', deleteTextBoxByKey);

    /**
     * 모달이 닫혔을 때 실행되는 리스너 함수
     */
    $scope.$on('$destroy', function () {
      document.removeEventListener('keydown', deleteTextBoxByKey);
    });
  });