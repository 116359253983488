/**
 * 2018-11-11
 * jacob
 * 카운트 사용내역
 */
'use strict';

angular.module('gmpApp')
  .controller('CountHistoryCtrl', function ($scope, $uibModalInstance, payModel, shipmentModel, onlineProductModel, productModel, categoryModel, systemModel, warehouseModel, commonSVC, $compile, $timeout, settings, $rootScope, gettextCatalog, paySVC) {
    $scope.init = false;
    $scope.nosave = true;
    $scope.searchForm = {};
    $scope.searchData = {
      view: 'Y',                 // 임시방편 (listSearch 기간 1개월까지)
      selectCount: 0,             // 선택박스 카운트
      totalCount: 0,              // 총카운트
      viewCount: 1,               // 몇번째 부터 시작인지 보여줌
      search_key_items: [
        { label: gettextCatalog.getString('전체'), value: '' }
      ],
    };

    payModel.getCurrentCount(function (state, result) {
      if (state === 'success') {
        _.each(result, function (re) {
          if (re.charge_type === 'ol_shop') {
            $scope.olShopCount = re;
          } else {
            $scope.ordCount = re;
          }
        });
        $scope.tab = 'ord';
        const searchForm = {
          search_word: '',
          search_key: 'all',
          sdate: result[0].charge_sdate,
          edate: result[0].charge_edate,         // 활성화 탭 (ol, ord)
          job_name: null,
        };

        const searchDetail = [
          {
            title: gettextCatalog.getString('작업종류 선택'),
            search_name: 'job_name',
            item_list: [{ key: '주문수집', value: '주문수집', tab: 'ord' }, { key: '상품등록', value: '상품등록', tab: 'ol' }, { key: '상품수정', value: '상품수정', tab: 'ol' }, { key: '상품부분수정', value: '상품부분수정', tab: 'ol' }, { key: '상품재등록', value: '상품재등록', tab: 'ol' }, { key: '판매기간변경', value: '판매기간변경', tab: 'ol' }],
            item_key: 'key',
            item_value: 'value',
            select_value: '',
            value: '',
            filter: function (option) {
              return $scope.tab === option.tab;
            }
          },
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: angular.copy(_.unionBy($rootScope.use_channel_list || [], 'shop_cd')),
            item_key: 'shop_name',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search',
            value: '',
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: angular.copy($rootScope.use_channel_list || []),
            item_key: 'shop_id',
            item_value: 'shop_id',
            select_value: '',
            add_class: 'select-search',
            value: '',
            filter: function (option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
            }
          }
        ];

        // 실시간 집계기준 날짜를 기준으로 로그 조회
        $scope.sdate = moment($scope.olShopCount.charge_sdate).format('MM/DD');
        $scope.edate = moment($scope.olShopCount.charge_edate).format('MM/DD');
        $scope.currentCount = result;
        $scope.searchForm = angular.copy(searchForm);
        $scope.searchData.searchDetail = angular.copy(searchDetail);
        $scope.searchBtn = {      // 상세조건 버튼
          actions_left: [
            [
              {
                label: '<i class="icon-file-excel text-success"></i> ' + '전체 엑셀 다운로드',
                action: function() { $scope.excelDown('all'); },
                perm_only: ['online.template+write']
              },
              {
                label: '<i class="icon-file-excel text-success"></i> ' + '선택 엑셀 다운로드',
                action: function() { $scope.excelDown('select'); },
                perm_only: ['online.template+write']
              }
            ]
          ]
        };

        // 데이터 테이블 관련기능

        $scope.searchFn = {
          // 몇 건씩 볼지 결정
          changeLen: function (len) {
            if ($scope.tab === 'ord') {
              $scope.ord.methods.length(len, true);
            } else {
              $scope.ol.methods.length(len, true);
            }
          },
          // 탭 변경
          changeTab: function (tab) {
            $scope.tab = tab;
            $scope.searchFn.resetDo();
          },
          // 검색
          searchDo: function (fromReset) {
            if (!fromReset) {
              if (moment($scope.searchForm.sdate).add(1, 'M').format('YYYY-MM-DD') < $scope.searchForm.edate) {
                commonSVC.showMessage('오류', '최대 기간 설정은 한 달입니다.');

                return false;
              }
            }
            if ($scope.tab === 'ord') {
              $scope.ord.methods.reloadData(function() {}, true);
            } else {
              $scope.ol.methods.reloadData(function() {}, true);
            }
          },
          // 초기화
          resetDo: function () {
            $scope.searchForm = angular.copy(searchForm);
            $scope.searchData.searchDetail = angular.copy(searchDetail);
            $scope.searchFn.searchDo(true);
          },
        };
      } else {
        commonSVC.showMessage('error', '카운트 사용내역 조회 실패');
      }

      /**
       * 2018-11-21 Jacob
       * 주문 로그 모달 열기
       */
      $scope.openOrdModal = function (shop_ord_no) {
        const data = {
          shop_ord_no: shop_ord_no
        };

        shipmentModel.getOrdUniq(data, function (state, result) {
          if (state === 'success') {

            const resolve = {
              data: {
                uniq: result.uniq,
                tab: 2,
                fromPage: 'payment'
              }
            };

            if (result.is_global) {
              resolve.data.tab = 0;
              commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
            } else {
              commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
            }

          } else {
            commonSVC.showMessage('Error', result.data.error);
          }
        });

      };
      /**
       * 2018-11-22 Jacob
       * 온라인 상품 상세 로그 모달 열기
       */
      $scope.openOlProdModal = function (ol_shop_no, shop_cd, shop_id) {
        const resolve = {};
        const data = {};

        data.shop_cd = shop_cd;
        data.shop_id = shop_id;
        data.country = 'KR';
        data.tab = 7;
        data.warehouseList = warehouseModel.warehouseList();
        resolve.data = data;

        onlineProductModel.detail(shop_cd, shop_id, ol_shop_no, function (state, result) {
          if (state === 'success') {
            resolve.prodDetail = {};
            resolve.prodDetail.data = result;
            // 원산지 리스트 조회
            resolve.madeinList = function () {
              return productModel.getMadein();
            };
            //환경설정 셋팃값
            resolve.systemList = function () {
              return systemModel.load();
            };

            const modal = commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');

            modal.result.then(function () {})
              .catch(function (err) {
                if (err !== 'cancel' && err !== 'escape key press') {
                  commonSVC.showToaster('error', '', err);
                }
              });
          } else {
            commonSVC.showMessage('error', result.data.error);

            return false;
          }
        });

      };

      /**
     * 엑셀 다운 모달창
     * */
      $scope.excelDown = function (type) {
        const list = ($scope.tab == 'ord' ? $scope.ord.methods.selectedData('all') : $scope.ol.methods.selectedData('all')).map(v => ({ ...v, 'shop_name,shop_id': `${v.shop_name}/${v.shop_id}` }));
        const resolve = {};
        const excelFieldList = $scope.tab == 'ord' ? angular.copy(paySVC.countOrdExcelFieldList) : angular.copy(paySVC.countOlExcelFieldList);

        resolve.data = {
          type: type,
          excelFieldList: excelFieldList,
          title: $scope.tab == 'ord' ? '주문_카운트_사용내역' : '상품_카운트_사용내역',
          url: '/app/pay/excel-down/count',
          searchForm: $scope.tab == 'ord' ? $scope.ordData : $scope.olData,
          page: 'pay/countHistory'
        };

        if (type === 'all') {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.tab = $scope.tab == 'ord' ? 'ord' : 'ol';
          resolve.data.select_list = null;
        } else {

          if (list.length == 0) {
            commonSVC.showMessage('선택된 카운트 사용내역이 없습니다.');

            return false;
          } else {
            resolve.data.select_list = list;
            resolve.data.count = list.length;
            resolve.data.tab = $scope.tab == 'ord' ? 'ord' : 'ol';
          }
        }

        commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
      };

      $scope.ord = {};
      $scope.ord.methods = {};
      $scope.ord.options = {
        modal: true,
        gridHeight: 500,
        enablePaginationControls: true,
        pinningColumns: ['sol_ord_log_no'],
        alignCenterColumns: ['sol_ord_log_no', 'job_name', 'shop_info', 'shop_ord_no'],
        defaultSortingColumns: ['sol_ord_log_no'],
        notSortingColumns: ['job_name', 'shop_info', 'shop_ord_no'],
        notResizingColumns: ['sol_ord_log_no'],
        notMovingColumns: ['sol_ord_log_no'],
        notVisibleColumns: [],
        initPagingSize: 25,
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/pay/count-history`,
          requestMethod: 'GET',
          requestWillAction: function(d) {
            $scope.searchForm.tab = 'ord';
            const data = angular.merge({}, d, $scope.searchForm);
            //엑셀 모달용 데이터
            $scope.ordData = angular.copy(data);
            data.start = data.start || 0;
            $scope.searchData.showCount = data.length;

            return JSON.stringify(data);
          },
          requestDidAction: function(json) {
            $scope.searchData.totalCount = json.recordsTotal;
            $scope.searchData.viewCount = json.rowsTotal;
          },
        },
        columns: [
          {
            key: 'sol_ord_log_no', title: '작업진행일', width: 200, template: function(row) {
              return row.wdate || '';
            },
            notCompile: true
          },
          {
            key: 'job_name', title: '작업종류', width: 100, template: function() {
              return '주문수집';
            },
            notCompile: true
          },
          {
            key: 'shop_info', title: '쇼핑몰(계정)', width: 150, template: function(row) {
              return `${row.shop_name}(${row.shop_id})`;
            }
          },
          {
            key: 'shop_ord_no', title: '쇼핑몰 주문번호', width: 130, template: function(row) {
              return row.shop_ord_no ? `<a ng-click="grid.appScope.openOrdModal('${row.shop_ord_no}')">${row.shop_ord_no}</a>` : '';
            }
          }
        ]
      };

      $scope.ol = {};
      $scope.ol.methods = {};
      $scope.ol.options = {
        modal: true,
        gridHeight: 500,
        enablePaginationControls: true,
        pinningColumns: ['sol_ol_shop_log_no'],
        alignCenterColumns: ['sol_ol_shop_log_no', 'job_name', 'shop_info', 'c_sale_cd', 'shop_sale_no'],
        defaultSortingColumns: ['sol_ol_shop_log_no'],
        notSortingColumns: ['job_name', 'shop_info', 'c_sale_cd', 'shop_sale_no'],
        notResizingColumns: ['sol_ol_shop_log_no'],
        notMovingColumns: ['sol_ol_shop_log_no'],
        notVisibleColumns: [],
        initPagingSize: 25,
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/pay/count-history`,
          requestMethod: 'GET',
          requestWillAction: function (d) {
            $scope.searchForm.tab = 'ol';
            const data = angular.merge({}, d, $scope.searchForm);
            //엑셀 모달용 데이터
            $scope.olData = angular.copy(data);
            data.start = data.start || 0;
            $scope.searchData.showCount = data.length;

            return JSON.stringify(data);
          },
          requestDidAction: function (json) {
            $scope.searchData.totalCount = json.recordsTotal;
            $scope.searchData.selectCount = json.rowsTotal;

            return json.results;
          }
        },

        columns: [
          {
            key: 'sol_ol_shop_log_no', title: '작업진행일', width: 200, template: function (row) {
              return row.wdate || '';
            },
            notCompile: true
          },
          {
            key: 'job_name', title: '작업종류', width: 100, template: function (row) {
              return row.job_name;
            },
            notCompile: true
          },
          {
            key: 'shop_info', title: '쇼핑몰(계정)', width: 150, template: function (row) {
              return `${row.shop_name}(${row.shop_id})`;
            },
            notCompile: true
          },
          {
            key: 'c_sale_cd', title: '판매자관리코드', width: 130, template: function (row) {
              return row.c_sale_cd ? row.c_sale_cd : '';
            },
            notCompile: true
          },
          {
            key: 'shop_sale_no', title: '쇼핑몰 상품코드', width: 130, template: function (row) {
              const arr = [row.ol_shop_no, row.shop_cd, row.shop_id];

              return row.shop_sale_no ? `<a ng-click="grid.appScope.openOlProdModal('${arr.join("','")}')">${row.shop_sale_no}</a>` : '';
            }
          }
        ]
      };
      $scope.init = true;
    });

    /**
     * 모달 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });