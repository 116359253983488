/* vendor css */
import 'sweetalert/dist/sweetalert.css';
import 'sweetalert2/dist/sweetalert2.css';
import 'ui-select/dist/select.min.css';
import 'fullcalendar/dist/fullcalendar.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'c3/c3.min.css';
import 'angular-ui-tree/dist/angular-ui-tree.min.css';
import 'angular-ui-grid/ui-grid.min.css';

/* user css */
import './styles/html.css';
import './styles/icons/icomoon/styles.css';
import './styles/icons/fontawesome/styles.min.css';
import './styles/icons/peicon/pe-icon-set-e-commerce.min.css';
import './styles/icons/peicon/pe-icon-set-communication.min.css';
import './styles/icons/peicon/pe-icon-set-edition.min.css';
import './styles/icons/peicon/pe-icon-set-hardware-software.min.css';
import './styles/icons/peicon/pe-icon-set-social-people.min.css';
import './styles/icons/playauto/style.css';
import './styles/icons/playautoicon/style.css';
import './styles/icons/playauto/playauto-gmp-order.css';
import './styles/icons/tutorial/style.css';
import './styles/icons/pasolution/style.css';
import './styles/bootstrap.css';
import './styles/core.css';
import './styles/components.css';
import './styles/colors.css';
import './styles/print.css';
import './styles/notice.css';
import './styles/custom_gmp.css';

import './styles/chrome-tabs.css';
import './styles/swalExtend.css';
import './styles/hotkeys.css';
import './styles/animate.min.css';
import './styles/angular-tree-control.css';
import './styles/multiSelect2.css';

/* vendor js */
import '@babel/polyfill/dist/polyfill.js';
import 'jquery/dist/jquery.js';
import 'fastdom/fastdom.min.js';
import 'lodash/lodash.min.js';
import 'ptg-lodash/dist/ptg-lodash.min.js';
import 'jquery-ui-dist/jquery-ui.js';
import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'angular/angular.min.js';
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls.js';
import 'angular-animate/angular-animate.min.js';
import 'angular-cookies/angular-cookies.min.js';
import 'angular-messages/angular-messages.min.js';
import 'angular-resource/angular-resource.min.js';
import 'angular-sanitize/angular-sanitize.min.js';

import 'angular-touch/angular-touch.min.js';
import 'oclazyload/dist/ocLazyLoad.js';
import 'angular-local-storage/dist/angular-local-storage.min.js';
import 'jquery-validation/dist/jquery.validate.js';
import 'pnotify/dist/pnotify.js';
import 'pnotify/dist/pnotify.buttons.js';
import 'sweetalert/dist/sweetalert.min.js';
import 'datatables.net/js/jquery.dataTables.js';
import 'datatables.net-select/js/dataTables.select.min.js';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.colVis.min.js';
import 'datatables.net-buttons/js/buttons.flash.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-keytable/js/dataTables.keyTable.min.js';
import 'datatables.net-colreorder/js/dataTables.colReorder.min.js';
import 'jquery.uniform/dist/js/jquery.uniform.standalone.js';
import 'moment/moment.js';
import 'bootstrap-daterangepicker/daterangepicker.js';
import 'switchery/dist/switchery.js';
import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.min.js';
import 'angular-ui-mask/dist/mask.min.js';
import 'angular-tree-control/angular-tree-control.js';
import 'ui-select/dist/select.min.js';
import 'ng-file-upload/dist/ng-file-upload.min.js';
import 'ngDraggable/ngDraggable.js';
import 'inko/inko.min.js';
import 'fullcalendar/dist/fullcalendar.min.js';
import 'angular-ui-calendar/src/calendar.js';
import 'angular-bind-html-compile/angular-bind-html-compile.min.js';
import 'angular-clipboard/angular-clipboard.js';
import 'angular-md5/angular-md5.min.js';
import 'angular-moment/angular-moment.min.js';
import 'sweetalert2/dist/sweetalert2.min.js';
import 'jspdf/dist/jspdf.min.js';
import 'simple-web-notification/web-notification.js';
import 'angular-web-notification/angular-web-notification.js';
import 'angular-gettext/dist/angular-gettext.min.js';
import 'angular-permission/dist/angular-permission.min.js';
import 'angular-permission/dist/angular-permission-ui.min.js';
import 'angular-bootstrap-contextmenu/contextMenu.js';
import 'mousetrap/mousetrap.min.js';
import 'ng-idle/angular-idle.min.js';
import 'd3/d3.js';
import 'd3/d3.min.js';
import 'c3/c3.min.js';
import 'angular-bootstrap-select/build/angular-bootstrap-select.min.js';
import 'angular-lazy-img/release/angular-lazy-img.min.js';
import 'ng-webworker/src/ng-webworker.min.js';
import 'angular-ui-tree/dist/angular-ui-tree.min.js';
import 'jquery-watch/jquery-watch.js';
import 'angular-ui-sortable/dist/sortable.min.js';
import 'ng-infinite-scroll/build/ng-infinite-scroll.min.js';
import 'intersection-observer/intersection-observer.js';

import 'angular-ui-grid/ui-grid.core.min.js';
import 'angular-ui-grid/ui-grid.pagination.min.js';
import 'angular-ui-grid/ui-grid.resize-columns.min.js';
import 'angular-ui-grid/ui-grid.move-columns.min.js';
import 'angular-ui-grid/ui-grid.selection.js';
import 'angular-ui-grid/ui-grid.cellnav.min.js';
import 'angular-ui-grid/ui-grid.tree-base.min.js';
import 'angular-ui-grid/ui-grid.tree-view.min.js';
import 'angular-ui-grid/ui-grid.grouping.min.js';
import 'angular-ui-grid/ui-grid.pinning.min.js';
import 'angular-ui-grid/ui-grid.saveState.min.js';
import 'angular-ui-grid/ui-grid.expandable.min.js';

import 'moment/min/locales.min.js';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min.js';
import 'jsbarcode/dist/barcodes/JsBarcode.code128.min.js';
import 'jsbarcode/dist/barcodes/JsBarcode.itf.min.js';
import 'jsbarcode/dist/barcodes/JsBarcode.code39.min.js';
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

// angular-carousel
import 'angular-touch/angular-touch.js';
import 'angular-carousel/dist/angular-carousel.js';

/* vendor user js */
// import './scripts/libs/ckeditor/ckeditor.js';
import './scripts/cores/plugins/forms/selects/bootstrap_multiselect.js';
import './scripts/cores/plugins/forms/selects/select2.min.js';
import './scripts/cores/plugins/forms/wizards/steps.min.js';
import './scripts/cores/plugins/notifications/noty.min.js';

import './scripts/libs/angular-init-loader.js';
import './scripts/libs/html2canvas.js';
import './scripts/libs/canvas2image.js';
import './scripts/libs/libphonenumber/libphonenumber.js';
import './scripts/libs/datatables.full_numbers_custom/full_numbers_custom.js';
import './scripts/libs/cryptojs/crypto-js.js';
import './scripts/libs/angular-ui-router-tabs.js';
import './scripts/libs/hover_dropdown.min.js';
import './scripts/libs/chrome.tabs/js/chrome-tabs.js';
import './scripts/libs/hotkeys.js';
// import './scripts/libs/clusterws.min.js';
import './scripts/libs/colResizable-1.6.js';
import './scripts/libs/detect-browser.js';

/* user js */

/* js */
import './scripts/libs/ptgui/ptgui.js';
import './scripts/libs/ptgui/ptgui-imagebox.js';
import './scripts/libs/ptgui/ptgui-zipcode.js';
import './scripts/libs/ptgui/ptgui-tel.js';
import './scripts/libs/ptgui/ptgui-decimal.js';
import './scripts/libs/ptgui/ptgui-numberonly.js';
import './scripts/libs/ptgui/ptgui-regex-mask.js';
import './scripts/libs/ptgui/ptgui-permission.js';
import './scripts/js/jquery/input.jquery';

/* angular base */
import './scripts/app.layout.js';
import './scripts/cores/string.class.js';
import './scripts/app.environment.ts';
import './scripts/app.settings.js';
import './scripts/app.loader.js';
import './scripts/app.js';
import './scripts/app.roles.js';
import './scripts/app.state.js';

import './scripts/services/worksvc.js';

/* filter */
import './scripts/filters/join.js';
import './scripts/filters/uniq.js';
import './scripts/filters/statusColor.js';
import './scripts/filters/checkEmptyVal.js';
import './scripts/filters/testFilters.js';
import './scripts/filters/sumBy.js';
import './scripts/filters/search.js';
import './scripts/filters/date.js';
import './scripts/filters/whiteSpace.js';
import './scripts/filters/isNullHyphen.js';

/* directive */
import './scripts/directives/_common.js';
import './scripts/directives/spinner.js';
import './scripts/directives/form.js';
import './scripts/directives/logtable.js';
import './scripts/directives/paUiGrid.js';
import './scripts/directives/scrollToLast.js';
import './scripts/directives/tabmenu.js';
import './scripts/directives/listSearch.js';
import './scripts/directives/leftContent.js';
import './scripts/directives/leftContentSearch.js';
import './scripts/directives/leftContentTree.js';
import './scripts/directives/leftContentOlShopTree.js';
import './scripts/directives/leftContentOlCateTree.js';
import './scripts/directives/leftContentSummary.js';
import './scripts/directives/leftContentMemo.js';
import './scripts/directives/leftContentLog.js';
import './scripts/directives/countmenu.js';
import './scripts/directives/listFooter.js';
import './scripts/directives/c3Chart.js';
import './scripts/directives/countryPicker.js';
import './scripts/directives/memo.js';
import './scripts/directives/imageOnError.js';
import './scripts/directives/calendarPreview.js';
import './scripts/directives/detailPreview.js';
import './scripts/directives/convertToNumber.js';
import './scripts/directives/ngRepeatEndWatch.js';
import './scripts/directives/sticky.js';
import './scripts/directives/placeholderBg.js';
import './scripts/directives/draggable.js';
import './scripts/directives/compile.js';
import './scripts/directives/progressBar.js';
import './scripts/directives/addColumnDetail.js';
import './scripts/directives/searchAutoComplete.js';
import './scripts/directives/smartCheckbox.js';
import './scripts/directives/onFinishRender.js';
import './scripts/directives/olTemplateSearch.js';
import './scripts/directives/resizable.js';
import './scripts/directives/multiSelect2';

/* service */
import './scripts/services/commonsvc.js';
import './scripts/services/logHistory.js';
import './scripts/services/overlap.js';
import './scripts/services/msgsvc.js';
import './scripts/services/jquerysvc.js';
import './scripts/services/langsvc.js';
import './scripts/services/stockExceptionsvc.js';
import './scripts/services/errorSVC.js';
import './scripts/services/channelSVC.js';
import './scripts/services/tokensvc.js';
import './scripts/services/socket.js';
import './scripts/services/policySVC.js';

import './scripts/services/home/helpsvc.js';
import './scripts/services/home/cautionsvc.js';

import './scripts/services/configs/user.js';
import './scripts/services/configs/system.js';
import './scripts/services/configs/admin.js';
import './scripts/services/configs/history.js';

import './scripts/services/settings/supplier.js';
import './scripts/services/settings/brand.js';
import './scripts/services/settings/delivery.js';
import './scripts/services/settings/category/openMarketCate.js';
import './scripts/services/simpleGuideSVC.js';

import './scripts/services/settings/category/A001_category.js';
import './scripts/services/settings/category/A004_category.js';
import './scripts/services/settings/category/A006_category.js';
import './scripts/services/settings/category/A008_category.js';
import './scripts/services/settings/category/A011_category.js';
import './scripts/services/settings/category/A012_category.js';
import './scripts/services/settings/category/A017_category.js';
import './scripts/services/settings/category/A027_category.js';
import './scripts/services/settings/category/A032_category.js';
import './scripts/services/settings/category/A064_category.js';
import './scripts/services/settings/category/A077_category.js';
import './scripts/services/settings/category/A083_category.js';
import './scripts/services/settings/category/A076_category.js';
import './scripts/services/settings/category/A084_category.js';
import './scripts/services/settings/category/A085_category.js';
import './scripts/services/settings/category/A100_category.js';
import './scripts/services/settings/category/A109_category.js';
import './scripts/services/settings/category/A112_category.js';
import './scripts/services/settings/category/A118_category.js';
import './scripts/services/settings/category/A124_category.js';
import './scripts/services/settings/category/A131_category.js';
import './scripts/services/settings/category/A140_category.js';
import './scripts/services/settings/category/A150_category.js';
import './scripts/services/settings/category/A133_category.js';
import './scripts/services/settings/category/A526_category.js';
import './scripts/services/settings/category/B005_category.js';
import './scripts/services/settings/category/B007_category.js';
import './scripts/services/settings/category/B041_category.js';
import './scripts/services/settings/category/B048_category.js';
import './scripts/services/settings/category/B051_category.js';
import './scripts/services/settings/category/B118_category.js';
import './scripts/services/settings/category/B378_category.js';
import './scripts/services/settings/category/B394_category.js';
import './scripts/services/settings/category/B430_category.js';
import './scripts/services/settings/category/B502_category.js';
import './scripts/services/settings/category/B513_category.js';
import './scripts/services/settings/category/B579_category.js';
import './scripts/services/settings/category/B594_category.js';
import './scripts/services/settings/category/B603_category.js';
import './scripts/services/settings/category/B614_category.js';
import './scripts/services/settings/category/B616_category.js';
import './scripts/services/settings/category/B687_category.js';
import './scripts/services/settings/category/B688_category.js';
import './scripts/services/settings/category/B691_category.js';
import './scripts/services/settings/category/B693_category.js';
import './scripts/services/settings/category/B719_category.js';
import './scripts/services/settings/category/B720_category.js';
import './scripts/services/settings/category/B730_category.js';
import './scripts/services/settings/category/B851_category.js';
import './scripts/services/settings/category/B877_category.js';
import './scripts/services/settings/category/B878_category.js';
import './scripts/services/settings/category/B917_category.js';
import './scripts/services/settings/category/B956_category.js';
import './scripts/services/settings/category/B959_category.js';
import './scripts/services/settings/category/C009_category.js';
import './scripts/services/settings/category/A524_category.js';
import './scripts/services/settings/category/B700_category.js';
import './scripts/services/settings/category/B666_category.js';
import './scripts/services/settings/category/P012_category.js';
import './scripts/services/settings/category/P013_category.js';
import './scripts/services/settings/category/A528_category.js';
import './scripts/services/settings/category/P014_category.js';
import './scripts/services/settings/category/P015_category.js';
import './scripts/services/settings/category/P016_category.js';
import './scripts/services/settings/category/P017_category.js';
import './scripts/services/settings/category/P018_category.js';
import './scripts/services/settings/category/P020_category.js';
import './scripts/services/settings/category/B957_category.js';
import './scripts/services/settings/category/P028_category.js';
import './scripts/services/settings/category/B669_category.js';
import './scripts/services/settings/category/B325_category.js';
import './scripts/services/settings/category/B696_category.js';
import './scripts/services/settings/category/B612_category.js';
import './scripts/services/settings/category/B690_category.js';
import './scripts/services/settings/category/P037_category.js';
import './scripts/services/settings/category/P039_category.js';
import './scripts/services/settings/category/P046_category.js';
import './scripts/services/settings/category/A530_category.js';
import './scripts/services/settings/category/P044_category.js';
import './scripts/services/settings/category/P045_category.js';
import './scripts/services/settings/category/P047_category.js';
import './scripts/services/settings/category/P048_category.js';
import './scripts/services/settings/category/P050_category.js';
import './scripts/services/settings/category/A170_category.js';
import './scripts/services/settings/category/A171_category.js';
import './scripts/services/settings/category/A536_category.js';
import './scripts/services/settings/category/A176_category.js';
import './scripts/services/settings/category/A537_category.js';
import './scripts/services/settings/category/P053_category.js';
import './scripts/services/settings/category/A175_category.js';
import './scripts/services/settings/category/A178_category.js';
import './scripts/services/settings/category/A179_category.js';
import './scripts/services/settings/category/P042_category.js';
import './scripts/services/settings/category/P055_category.js';
import './scripts/services/settings/category/A093_category.js';
import './scripts/services/settings/category/A092_category.js';
import './scripts/services/settings/category/P058_category.js';
import './scripts/services/settings/category/A538_category.js';
import './scripts/services/settings/category/P056_category.js';
import './scripts/services/settings/category/P054_category.js';
import './scripts/services/settings/category/B668_category.js';
import './scripts/services/settings/category/A527_category.js';
import './scripts/services/settings/category/P060_category.js';
import './scripts/services/settings/category/X093_category.js';
import './scripts/services/settings/category/X094_category.js';
import './scripts/services/settings/category/A098_category.js';

import './scripts/services/prod/gift.js';
import './scripts/services/prod/product.js';

import './scripts/services/stock/inventory.js';
import './scripts/services/stock/warehouse.js';

import './scripts/services/online/onlineProduct.js';
import './scripts/services/online/template.js';
import './scripts/services/online/onlineProd.js';
import './scripts/services/online/category.js';

import './scripts/services/order/carr.js';
import './scripts/services/order/tag.js';
import './scripts/services/order/delay.js';
import './scripts/services/order/shipment.js';
import './scripts/services/order/customer_inquiry.js';
import './scripts/services/order/invoice_template.js';
import './scripts/services/order/order_print_form.js';

import './scripts/services/calculate/state.js';
import './scripts/services/pay/pay.js';
import './scripts/services/pay/card.js';
import './scripts/services/notice/noticeSVC.js';
import './scripts/services/question/questionSVC.js';

import './scripts/services/log/excel.js';
import './scripts/services/modules/columnSVC.js';
import './scripts/services/modules/asSVC.js';
import './scripts/services/summary/summary.js';
import './scripts/services/summary/insight.js';
import './scripts/services/memo/memo.js';

/* model */
import './scripts/models/commonModel.js';
import './scripts/models/configs/admin.js';
import './scripts/models/configs/user.js';
import './scripts/models/configs/system.js';
import './scripts/models/configs/security.js';
import './scripts/models/home/help.js';
import './scripts/models/home/caution.js';

import './scripts/models/settings/shopAccount.js';
import './scripts/models/settings/category.js';
import './scripts/models/settings/supplier.js';
import './scripts/models/settings/delivery.js';

import './scripts/models/prod/gift.js';
import './scripts/models/order/tag.js';
import './scripts/models/prod/product.js';

import './scripts/models/memo/memo.js';

import './scripts/models/notice/notice.js';

import './scripts/models/stock/warehouse.js';
import './scripts/models/stock/inventory.js';

import './scripts/models/online/product.js';
import './scripts/models/online/template.js';

import './scripts/models/order/shipment.js';
import './scripts/models/order/customer_Inquiry.js';
import './scripts/models/calculate/state.js';
import './scripts/models/pay/pay.js';
import './scripts/models/question/question.js';
import './scripts/models/modules/column.js';
import './scripts/models/modules/as.js';
import './scripts/models/summary/summary.js';
import './scripts/models/summary/insight.js';

import './scripts/models/atalk/atalk.js';

/* controller */
import './scripts/controllers/AppController.js';
import './scripts/controllers/MainController.js';

import './scripts/controllers/common/paUiGridColVis.js';
import './scripts/controllers/common/simpleModal.js';
import './scripts/controllers/common/workCountAlarmModal.js';
import './scripts/controllers/common/versionAdvisor.js';
import './scripts/controllers/common/paySolExpired.js';

import './scripts/controllers/configs/modals/email.js';
import './scripts/controllers/configs/modals/sms.js';
import './scripts/controllers/configs/modals/sms_template.js';
import './scripts/controllers/configs/modals/sms_template_add.js';
import './scripts/controllers/configs/modals/sms_template_edit.js';
import './scripts/controllers/configs/admin.js';
import './scripts/controllers/configs/user.js';
import './scripts/controllers/configs/sub_user.js';
import './scripts/controllers/configs/user_add.js';
import './scripts/controllers/configs/user_edit.js';
import './scripts/controllers/configs/master_edit.js';
import './scripts/controllers/configs/security.js';
import './scripts/controllers/configs/system.js';
import './scripts/controllers/configs/ex_rate_calc.js';

import './scripts/controllers/log/del_history.js';
import './scripts/controllers/log/map_history.js';
import './scripts/controllers/log/excel_history.js';
import './scripts/controllers/log/stock_alloc_history.js';
import './scripts/controllers/log/auto_soldout_history.js';
import './scripts/controllers/log/barcode_history.js';
import './scripts/controllers/log/configs_history.js';
import './scripts/controllers/log/user_connect_history.js';
import './scripts/controllers/configs/modals/get_auto_order.js';

import './scripts/controllers/settings/category/cateInfoDetail.js';
import './scripts/controllers/settings/category/main.js';
import './scripts/controllers/settings/category/searchHscode.js';
import './scripts/controllers/settings/category/cateMatching.js';
import './scripts/controllers/settings/category/modals/cateApplyEachProduct.js';
import './scripts/controllers/settings/category/modals/cateApplyShopId.js';
import './scripts/controllers/settings/category/modals/categoryAdd.js';
import './scripts/controllers/settings/category/modals/categoryClassAdd.js';
import './scripts/controllers/settings/category/modals/categoryLog.js';
import './scripts/controllers/settings/category/modals/excel_modals.js';
import './scripts/controllers/settings/shopAccount/list.js';
import './scripts/controllers/settings/shopAccount/detail.js';
import './scripts/controllers/settings/shopAccount/detailGlobal.js';
import './scripts/controllers/settings/shopAccount/esm_common_info.js';
import './scripts/controllers/settings/shopAccount/rocket_growth_info.js';
import './scripts/controllers/settings/shopAccount/modals/autoSettingModal.js';

import './scripts/controllers/settings/supplier/list.js';
import './scripts/controllers/settings/supplier/add.js';
import './scripts/controllers/settings/supplier/edit.js';
import './scripts/controllers/settings/supplier/modals/trade_prod_list.js';

import './scripts/controllers/settings/delivery/delivery.js';
import './scripts/controllers/settings/delivery/delivery_linked.js';
import './scripts/controllers/settings/delivery/delivery_link_reg.js';
import './scripts/controllers/settings/delivery/listModal.js';

import './scripts/controllers/settings/category/shopDetail/A001.js';
import './scripts/controllers/settings/category/shopDetail/A006.js';
import './scripts/controllers/settings/category/shopDetail/A008.js';
import './scripts/controllers/settings/category/shopDetail/A004.js';
import './scripts/controllers/settings/category/shopDetail/A011.js';
import './scripts/controllers/settings/category/shopDetail/A012.js';
import './scripts/controllers/settings/category/shopDetail/A017.js';
import './scripts/controllers/settings/category/shopDetail/A027.js';
import './scripts/controllers/settings/category/shopDetail/A032.js';
import './scripts/controllers/settings/category/shopDetail/A064.js';
import './scripts/controllers/settings/category/shopDetail/A076.js';
import './scripts/controllers/settings/category/shopDetail/A077.js';
import './scripts/controllers/settings/category/shopDetail/A083.js';
import './scripts/controllers/settings/category/shopDetail/A084.js';
import './scripts/controllers/settings/category/shopDetail/A085.js';
import './scripts/controllers/settings/category/shopDetail/A100.js';
import './scripts/controllers/settings/category/shopDetail/A109.js';
import './scripts/controllers/settings/category/shopDetail/A112.js';
import './scripts/controllers/settings/category/shopDetail/A118.js';
import './scripts/controllers/settings/category/shopDetail/A124.js';
import './scripts/controllers/settings/category/shopDetail/A131.js';
import './scripts/controllers/settings/category/shopDetail/A140.js';
import './scripts/controllers/settings/category/shopDetail/A150.js';
import './scripts/controllers/settings/category/shopDetail/A133.js';
import './scripts/controllers/settings/category/shopDetail/A526.js';
import './scripts/controllers/settings/category/shopDetail/B005.js';
import './scripts/controllers/settings/category/shopDetail/B007.js';
import './scripts/controllers/settings/category/shopDetail/B041.js';
import './scripts/controllers/settings/category/shopDetail/B048.js';
import './scripts/controllers/settings/category/shopDetail/B051.js';
import './scripts/controllers/settings/category/shopDetail/B118.js';
import './scripts/controllers/settings/category/shopDetail/B378.js';
import './scripts/controllers/settings/category/shopDetail/B394.js';
import './scripts/controllers/settings/category/shopDetail/B430.js';
import './scripts/controllers/settings/category/shopDetail/B502.js';
import './scripts/controllers/settings/category/shopDetail/B513.js';
import './scripts/controllers/settings/category/shopDetail/B579.js';
import './scripts/controllers/settings/category/shopDetail/B594.js';
import './scripts/controllers/settings/category/shopDetail/B603.js';
import './scripts/controllers/settings/category/shopDetail/B614.js';
import './scripts/controllers/settings/category/shopDetail/B616.js';
import './scripts/controllers/settings/category/shopDetail/B687.js';
import './scripts/controllers/settings/category/shopDetail/B688.js';
import './scripts/controllers/settings/category/shopDetail/B691.js';
import './scripts/controllers/settings/category/shopDetail/B693.js';
import './scripts/controllers/settings/category/shopDetail/B719.js';
import './scripts/controllers/settings/category/shopDetail/B720.js';
import './scripts/controllers/settings/category/shopDetail/B730.js';
import './scripts/controllers/settings/category/shopDetail/B851.js';
import './scripts/controllers/settings/category/shopDetail/B877.js';
import './scripts/controllers/settings/category/shopDetail/B878.js';
import './scripts/controllers/settings/category/shopDetail/B917.js';
import './scripts/controllers/settings/category/shopDetail/B956.js';
import './scripts/controllers/settings/category/shopDetail/B959.js';
import './scripts/controllers/settings/category/shopDetail/C009.js';
import './scripts/controllers/settings/category/shopDetail/A524.js';
import './scripts/controllers/settings/category/shopDetail/B700.js';
import './scripts/controllers/settings/category/shopDetail/B666.js';
import './scripts/controllers/settings/category/shopDetail/P012.js';
import './scripts/controllers/settings/category/shopDetail/P013.js';
import './scripts/controllers/settings/category/shopDetail/A528.js';
import './scripts/controllers/settings/category/shopDetail/P014.js';
import './scripts/controllers/settings/category/shopDetail/P015.js';
import './scripts/controllers/settings/category/shopDetail/P016.js';
import './scripts/controllers/settings/category/shopDetail/P017.js';
import './scripts/controllers/settings/category/shopDetail/P018.js';
import './scripts/controllers/settings/category/shopDetail/P020.js';
import './scripts/controllers/settings/category/shopDetail/B957.js';
import './scripts/controllers/settings/category/shopDetail/B669.js';
import './scripts/controllers/settings/category/shopDetail/B325.js';
import './scripts/controllers/settings/category/shopDetail/B696.js';
import './scripts/controllers/settings/category/shopDetail/P028.js';
import './scripts/controllers/settings/category/shopDetail/B612.js';
import './scripts/controllers/settings/category/shopDetail/B690.js';
import './scripts/controllers/settings/category/shopDetail/P037.js';
import './scripts/controllers/settings/category/shopDetail/P039.js';
import './scripts/controllers/settings/category/shopDetail/P046.js';
import './scripts/controllers/settings/category/shopDetail/A530.js';
import './scripts/controllers/settings/category/shopDetail/P044.js';
import './scripts/controllers/settings/category/shopDetail/P045.js';
import './scripts/controllers/settings/category/shopDetail/P047.js';
import './scripts/controllers/settings/category/shopDetail/P048.js';
import './scripts/controllers/settings/category/shopDetail/P050.js';
import './scripts/controllers/settings/category/shopDetail/A170.js';
import './scripts/controllers/settings/category/shopDetail/A171.js';
import './scripts/controllers/settings/category/shopDetail/A536.js';
import './scripts/controllers/settings/category/shopDetail/A537.js';
import './scripts/controllers/settings/category/shopDetail/A176.js';
import './scripts/controllers/settings/category/shopDetail/P051.js';
import './scripts/controllers/settings/category/shopDetail/P053.js';
import './scripts/controllers/settings/category/shopDetail/A175.js';
import './scripts/controllers/settings/category/shopDetail/A178.js';
import './scripts/controllers/settings/category/shopDetail/A179.js';
import './scripts/controllers/settings/category/shopDetail/P042.js';
import './scripts/controllers/settings/category/shopDetail/P055.js';
import './scripts/controllers/settings/category/shopDetail/A093.js';
import './scripts/controllers/settings/category/shopDetail/A092.js';
import './scripts/controllers/settings/category/shopDetail/P058.js';
import './scripts/controllers/settings/category/shopDetail/A538.js';
import './scripts/controllers/settings/category/shopDetail/P056.js';
import './scripts/controllers/settings/category/shopDetail/P054.js';
import './scripts/controllers/settings/category/shopDetail/B668.js';
import './scripts/controllers/settings/category/shopDetail/A527.js';
import './scripts/controllers/settings/category/shopDetail/X094.js';
import './scripts/controllers/settings/category/shopDetail/P060.js';
import './scripts/controllers/settings/category/shopDetail/X093.js';
import './scripts/controllers/settings/category/shopDetail/A098.js';

import './scripts/controllers/prod/modals.js';
import './scripts/controllers/prod/product/list.js';
import './scripts/controllers/prod/product/sub_prod_list.js';
import './scripts/controllers/prod/product/detail.js';
import './scripts/controllers/prod/product/modals/product_linkage.js';
import './scripts/controllers/prod/product/modals/modify_modals.js';
import './scripts/controllers/prod/product/modals/modify_prod.js';
import './scripts/controllers/prod/product/modals/copy_modal.js';
import './scripts/controllers/prod/product/modals/memo_list_confirm.js';
import './scripts/controllers/prod/product/modals/add_to_group.js';
import './scripts/controllers/prod/product/modals/scrap_sku.js';
import './scripts/controllers/prod/product/modals/sync_sku.js';
import './scripts/controllers/prod/product/modals/prod_info.js';
import './scripts/controllers/prod/product/modals/stock_excel_upload.js';
import './scripts/controllers/prod/product/modals/excel_upload.js';
import './scripts/controllers/prod/product/modals/excel_upload_template.js';
import './scripts/controllers/prod/product/modals/scrap_super_prod.js';
import './scripts/controllers/prod/product/modals/notsaleDashboard.js';
import './scripts/controllers/prod/product/modals/stock_transfer.js';
import './scripts/controllers/prod/product/modals/multi_sku_order_prod_list.js';
import './scripts/controllers/prod/product/modals/sku_excel_down_integrated.js';
import './scripts/controllers/prod/product/modals/sku_excel_edit_integrated.js';
import './scripts/controllers/prod/product/modals/prod_cost_template_detail.js';
import './scripts/controllers/prod/product/modals/prod_cost_template.js';
import './scripts/controllers/prod/product/modals/prod_package_box.js';
import './scripts/controllers/prod/product/modals/edit_prod_package_box.js';
import './scripts/controllers/prod/product/modals/package_box_linkage_sku.js';

import './scripts/controllers/prod/set/list.js';
import './scripts/controllers/prod/set/modals/add_set_prod.js';
import './scripts/controllers/prod/set/modals/excel_upload.js';

import './scripts/controllers/online/product/prodTranslate.js';

import './scripts/controllers/online/template.js';
import './scripts/controllers/online/product/add.js';
import './scripts/controllers/online/product/option_table.js';
import './scripts/controllers/online/product/edit.js';
import './scripts/controllers/online/product/list.js';
import './scripts/controllers/online/product/sub_online_list.js';
import './scripts/controllers/online/product/modals.js';
import './scripts/controllers/online/product/prod_info.js';
import './scripts/controllers/online/product/prodProductSearch.js';
import './scripts/controllers/online/product/prodRuleSearch.js';

import './scripts/controllers/online/product/modals/excel_modals.js';
import './scripts/controllers/online/product/modals/group_modals.js';
import './scripts/controllers/online/product/modals/online_category_match.js';
import './scripts/controllers/online/product/modals/wmp_group_modals.js';
import './scripts/controllers/online/product/modals/edit_c_sale_cd.js';
import './scripts/controllers/online/product/modals/addcontent.js';
import './scripts/controllers/online/product/modals/addcontent_rule.js';
import './scripts/controllers/online/product/modals/addcontent_apply_prods.js';
import './scripts/controllers/online/product/modals/addcontent_confirm.js';
import './scripts/controllers/online/product/modals/mas_addcontent.js';
import './scripts/controllers/online/product/modals/mas_addcontent_rule.js';
import './scripts/controllers/online/product/modals/online_category.js';
import './scripts/controllers/online/product/modals/add_opt_modals.js';
import './scripts/controllers/online/product/modals/add_single_prod.js';
import './scripts/controllers/online/product/modals/general_product_add.js';
import './scripts/controllers/online/product/modals/model_search.js';
import './scripts/controllers/online/product/modals/auto_match_modal.js';
import './scripts/controllers/online/product/modals/load_category_template.js';
import './scripts/controllers/online/product/modals/load_master_prod.js';
import './scripts/controllers/online/product/modals/prod_info_template_list.js';
import './scripts/controllers/online/product/modals/edit_prod_info_template.js';
import './scripts/controllers/online/product/olImg.js';
import './scripts/controllers/online/product/olImgListModal.js';
import './scripts/controllers/online/product/product_scrap.js';
import './scripts/controllers/online/product/prodScrap_match.js';
import './scripts/controllers/online/product/online_prod_match.js';
import './scripts/controllers/online/product/shop/A001.js';
import './scripts/controllers/online/product/shop/A004.js';
import './scripts/controllers/online/product/shop/A006.js';
import './scripts/controllers/online/product/shop/A008.js';
import './scripts/controllers/online/product/shop/A011.js';
import './scripts/controllers/online/product/shop/A012.js';
import './scripts/controllers/online/product/shop/A017.js';
import './scripts/controllers/online/product/shop/A112.js';
import './scripts/controllers/online/product/shop/A118.js';
import './scripts/controllers/online/product/shop/A124.js';
import './scripts/controllers/online/product/shop/A027.js';
import './scripts/controllers/online/product/shop/A032.js';
import './scripts/controllers/online/product/shop/A064.js';
import './scripts/controllers/online/product/shop/A076.js';
import './scripts/controllers/online/product/shop/A077.js';
import './scripts/controllers/online/product/shop/A100.js';
import './scripts/controllers/online/product/shop/A131.js';
import './scripts/controllers/online/product/shop/A133.js';
import './scripts/controllers/online/product/shop/A083.js';
import './scripts/controllers/online/product/shop/A084.js';
import './scripts/controllers/online/product/shop/A085.js';
import './scripts/controllers/online/product/shop/A109.js';
import './scripts/controllers/online/product/shop/A140.js';
import './scripts/controllers/online/product/shop/A150.js';
import './scripts/controllers/online/product/shop/B005.js';
import './scripts/controllers/online/product/shop/B007.js';
import './scripts/controllers/online/product/shop/B041.js';
import './scripts/controllers/online/product/shop/B048.js';
import './scripts/controllers/online/product/shop/B051.js';
import './scripts/controllers/online/product/shop/B118.js';
import './scripts/controllers/online/product/shop/B394.js';
import './scripts/controllers/online/product/shop/B430.js';
import './scripts/controllers/online/product/shop/B502.js';
import './scripts/controllers/online/product/shop/B378.js';
import './scripts/controllers/online/product/shop/B603.js';
import './scripts/controllers/online/product/shop/B614.js';
import './scripts/controllers/online/product/shop/B616.js';
import './scripts/controllers/online/product/shop/B688.js';
import './scripts/controllers/online/product/shop/B513.js';
import './scripts/controllers/online/product/shop/B579.js';
import './scripts/controllers/online/product/shop/B594.js';
import './scripts/controllers/online/product/shop/B687.js';
import './scripts/controllers/online/product/shop/B691.js';
import './scripts/controllers/online/product/shop/B693.js';
import './scripts/controllers/online/product/shop/B719.js';
import './scripts/controllers/online/product/shop/B720.js';
import './scripts/controllers/online/product/shop/B730.js';
import './scripts/controllers/online/product/shop/B851.js';
import './scripts/controllers/online/product/shop/B877.js';
import './scripts/controllers/online/product/shop/B917.js';
import './scripts/controllers/online/product/shop/B956.js';
import './scripts/controllers/online/product/shop/B959.js';
import './scripts/controllers/online/product/shop/A524.js';
import './scripts/controllers/online/product/shop/B878.js';
import './scripts/controllers/online/product/shop/B700.js';
import './scripts/controllers/online/product/shop/B666.js';
import './scripts/controllers/online/product/shop/P012.js';
import './scripts/controllers/online/product/shop/P012_relate_products_modal.js';
import './scripts/controllers/online/product/shop/P013.js';
import './scripts/controllers/online/product/shop/P013_relate_products_modal.js';
import './scripts/controllers/online/product/shop/material_add_edit.js';
import './scripts/controllers/online/product/shop/A112_material_add_edit.js';
import './scripts/controllers/online/product/shop/P014.js';
import './scripts/controllers/online/product/shop/P015.js';
import './scripts/controllers/online/product/shop/P016.js';
import './scripts/controllers/online/product/shop/P017.js';
import './scripts/controllers/online/product/shop/P017_relate_products_modal.js';
import './scripts/controllers/online/product/shop/P018.js';
import './scripts/controllers/online/product/shop/B957.js';
import './scripts/controllers/online/product/shop/B669.js';
import './scripts/controllers/online/product/shop/P020.js';
import './scripts/controllers/online/product/shop/B325.js';
import './scripts/controllers/online/product/shop/B696.js';
import './scripts/controllers/online/product/shop/P028.js';
import './scripts/controllers/online/product/shop/B612.js';
import './scripts/controllers/online/product/shop/B690.js';
import './scripts/controllers/online/product/shop/A530.js';
import './scripts/controllers/online/product/shop/P037.js';
import './scripts/controllers/online/product/shop/P046.js';
import './scripts/controllers/online/product/shop/P044.js';
import './scripts/controllers/online/product/shop/P045.js';
import './scripts/controllers/online/product/shop/P047.js';
import './scripts/controllers/online/product/shop/P048.js';
import './scripts/controllers/online/product/shop/P050.js';
import './scripts/controllers/online/product/shop/P051.js';
import './scripts/controllers/online/product/shop/A170.js';
import './scripts/controllers/online/product/shop/A171.js';
import './scripts/controllers/online/product/shop/A536.js';
import './scripts/controllers/online/product/shop/A176.js';
import './scripts/controllers/online/product/shop/A537.js';
import './scripts/controllers/online/product/shop/P053.js';
import './scripts/controllers/online/product/shop/A175.js';
import './scripts/controllers/online/product/shop/A178.js';
import './scripts/controllers/online/product/shop/A179.js';
import './scripts/controllers/online/product/shop/A538.js';
import './scripts/controllers/online/product/shop/A093.js';
import './scripts/controllers/online/product/shop/A092.js';
import './scripts/controllers/online/product/shop/P056.js';
import './scripts/controllers/online/product/shop/P054.js';
import './scripts/controllers/online/product/shop/B668.js';
import './scripts/controllers/online/product/shop/A527.js';
import './scripts/controllers/online/product/shop/P055.js';
import './scripts/controllers/online/product/shop/A098.js';
import './scripts/controllers/online/product/shop/X094.js';
import './scripts/controllers/online/product/shop/P060.js';
import './scripts/controllers/online/product/shop/X093.js';
import './scripts/controllers/online/product/shop/P055.js';

import './scripts/controllers/online/setForm/list.js';
import './scripts/controllers/online/setForm/add.js';
import './scripts/controllers/online/setForm/setFormTemplate.js';
import './scripts/controllers/online/setForm/scrap_template_copy.js';
import './scripts/controllers/online/setForm/setFormCopy.js';

import './scripts/controllers/imageHosting/home.js';

import './scripts/controllers/order/shipment/payment_list.js';
import './scripts/controllers/order/shipment/order_list.js';
import './scripts/controllers/order/shipment/unstoring_list.js';
import './scripts/controllers/order/shipment/delivery_list.js';
import './scripts/controllers/order/shipment/as_list.js';
import './scripts/controllers/order/shipment/integrated_list.js';
import './scripts/controllers/order/shipment/fulfillment_order_list.js';
import './scripts/controllers/order/shipment/claim_list.js';
import './scripts/controllers/order/shipment/ebaydepot_list.js';
import './scripts/controllers/order/shipment/detail.js';
import './scripts/controllers/order/shipment/detailGlobal.js';
import './scripts/controllers/order/shipment/prod_mapping.js';
import './scripts/controllers/order/shipment/mapping_rule.js';
import './scripts/controllers/order/shipment/modals/get_order.js';
import './scripts/controllers/order/shipment/modals/order_unstoring.js';
import './scripts/controllers/order/shipment/modals/order_confirm.js';
import './scripts/controllers/order/shipment/modals/order_copy.js';
import './scripts/controllers/order/shipment/modals/order_delete.js';
import './scripts/controllers/order/shipment/modals/send_invoice.js';
import './scripts/controllers/order/shipment/modals/send_order.js';
import './scripts/controllers/order/shipment/modals/send_order_setting.js';
import './scripts/controllers/order/shipment/modals/print_invoice.js';
import './scripts/controllers/order/shipment/modals/claim_invoice.js';
import './scripts/controllers/order/shipment/modals/cancel_claim_invoice.js';
import './scripts/controllers/order/shipment/agency_list.js';
import './scripts/controllers/order/shipment/gift_list.js';
import './scripts/controllers/order/shipment/consignment_list.js';

import './scripts/controllers/order/shipment/modals/prod_name_manager.js';
import './scripts/controllers/order/shipment/modals/prod_name_dup_rule.js';
import './scripts/controllers/order/shipment/modals/prod_name_excel.js';
import './scripts/controllers/order/shipment/modals/tag_list.js';
import './scripts/controllers/order/shipment/modals/tag_detail.js';
import './scripts/controllers/order/shipment/modals/tag_term.js';
import './scripts/controllers/order/shipment/modals/invoice_template.js';
import './scripts/controllers/order/shipment/modals/claim_invoice_template.js';
import './scripts/controllers/order/shipment/modals/supply_price_manager.js';
import './scripts/controllers/order/shipment/modals/supply_price_rule_add.js';
import './scripts/controllers/order/shipment/modals/order_sync.js';
import './scripts/controllers/order/shipment/modals/order_cs.js';
import './scripts/controllers/order/shipment/modals/order_hold.js';
import './scripts/controllers/order/shipment/modals/delay_delivery.js';
import './scripts/controllers/order/shipment/modals/update_hope_date.js';
import './scripts/controllers/order/shipment/modals/custom_shop_excel_template.js';
import './scripts/controllers/order/shipment/modals/excel_template.js';
import './scripts/controllers/order/shipment/modals/excel_modals.js';
import './scripts/controllers/order/shipment/modals/invocie_excel_modals.js';
import './scripts/controllers/order/shipment/modals/manual_order.js';
import './scripts/controllers/order/shipment/modals/order_print.js';
import './scripts/controllers/order/shipment/modals/order_package.js';
import './scripts/controllers/order/shipment/modals/order_package_combine.js';
import './scripts/controllers/order/shipment/modals/order_bundle_print.js';
import './scripts/controllers/order/shipment/modals/order_bundle_print_add.js';
import './scripts/controllers/order/shipment/modals/make_bundle.js';
import './scripts/controllers/order/shipment/modals/delivery_info_edit.js';
import './scripts/controllers/order/shipment/modals/mapping_prod.js';
import './scripts/controllers/order/shipment/modals/ebaydepot_send_ship_info.js';
import './scripts/controllers/order/shipment/modals/ebaydepot_delete_ship_info.js';
import './scripts/controllers/order/shipment/modals/ebaydepot_send_deliv_request.js';
import './scripts/controllers/order/shipment/modals/ebaydepot_cancel.js';
import './scripts/controllers/order/shipment/modals/ebaydepot_sync_deliv_request_state.js';
import './scripts/controllers/order/shipment/modals/ebaydepot_sync_deliv_info.js';
import './scripts/controllers/order/shipment/modals/divide_order.js';
import './scripts/controllers/order/shipment/modals/divide_bundle_select.js';
import './scripts/controllers/order/shipment/modals/excel_down_integrated.js';
import './scripts/controllers/order/shipment/modals/excel_edit_integrated.js';
import './scripts/controllers/order/shipment/modals/barcode_manager.js';
import './scripts/controllers/order/shipment/modals/column.js';
import './scripts/controllers/order/shipment/modals/as_modal.js';
import './scripts/controllers/order/shipment/modals/add_matching_rule.js';
import './scripts/controllers/order/shipment/modals/order_multi_sort.js';
import './scripts/controllers/order/shipment/modals/gift_detail.js';
import './scripts/controllers/order/shipment/modals/gift_delete.js';
import './scripts/controllers/order/shipment/modals/domesticPrintSetting.js';
import './scripts/controllers/order/shipment/modals/domesticAddressSetting.js';
import './scripts/controllers/order/shipment/modals/shopeeCreatePickup.js';
import './scripts/controllers/order/shipment/modals/printInvoiceGlobal.js';
import './scripts/controllers/order/shipment/modals/gift_used_cnt_list.js';
import './scripts/controllers/order/shipment/modals/suspicious_order.js';
import './scripts/controllers/order/customer_inquiry/list.js';
import './scripts/controllers/order/customer_inquiry/same_customer_inquiry_list.js';
import './scripts/controllers/order/customer_inquiry/collect_all.js';
import './scripts/controllers/order/customer_inquiry/collect.js';
import './scripts/controllers/order/customer_inquiry/send_answer.js';
import './scripts/controllers/order/customer_inquiry/answer.js';
import './scripts/controllers/order/customer_inquiry/foreword.js';
import './scripts/controllers/order/customer_inquiry/answer_template.js';
import './scripts/controllers/order/customer_inquiry/agency_list.js';
import './scripts/controllers/order/customer_inquiry/modals/excel_down.js';

import './scripts/controllers/stock/inventory/list.js';
import './scripts/controllers/stock/inventory/inout_list.js';
import './scripts/controllers/stock/inventory/order_list.js';
import './scripts/controllers/stock/inventory/stock_allocation_rule.js';
import './scripts/controllers/stock/inventory/stock_reallocation_rule.js';
import './scripts/controllers/stock/inventory/add_stock_allocation_rule.js';
import './scripts/controllers/stock/inventory/add_stock_reallocation_rule.js';
import './scripts/controllers/stock/inventory/set_stock_allocation_channel_rule.js';
import './scripts/controllers/stock/inventory/set_stock_reallocation_channel_rule.js';
import './scripts/controllers/stock/inventory/stock_allocation_channel.js';
import './scripts/controllers/stock/inventory/alloc_help.js';
import './scripts/controllers/stock/inventory/realloc_help.js';
import './scripts/controllers/stock/warehouse/edit.js';
import './scripts/controllers/stock/warehouse/list.js';
import './scripts/controllers/stock/warehouse/add.js';

import './scripts/controllers/calculate/state_list.js';
import './scripts/controllers/calculate/detail_list.js';
import './scripts/controllers/calculate/order_list.js';
import './scripts/controllers/calculate/state_per_prod_list.js';
import './scripts/controllers/calculate/modals/detail.js';
import './scripts/controllers/calculate/modals/calendar.js';
import './scripts/controllers/calculate/modals/collect.js';
import './scripts/controllers/calculate/modals/collect_all.js';
import './scripts/controllers/calculate/modals/excel_upload.js';
import './scripts/controllers/calculate/modals/custom_calculate_excel_template.js';

import './scripts/controllers/pay/pay.js';
import './scripts/controllers/pay/pay_grid.js';
import './scripts/controllers/pay/refund_grid.js';
import './scripts/controllers/pay/service_grid.js';
import './scripts/controllers/pay/translate_grid.js';
import './scripts/controllers/pay/benefit_grid.js';
import './scripts/controllers/pay/modals/refund_request.js';
import './scripts/controllers/pay/modals/pay_detail.js';
import './scripts/controllers/pay/modals/pay_method.js';
import './scripts/controllers/pay/modals/pay_request.js';
import './scripts/controllers/pay/modals/set_coupon.js';
import './scripts/controllers/pay/modals/pay_management.js';
import './scripts/controllers/pay/modals/autopay_info.js';
import './scripts/controllers/pay/modals/pay_bill_print.js';
import './scripts/controllers/pay/modals/couponManagement.js';
import './scripts/controllers/pay/modals/msg_list.js';
import './scripts/controllers/pay/modals/count_history.js';
import './scripts/controllers/pay/modals/addPromoCode.js';
import './scripts/controllers/pay/modals/editUserInfo.js';
import './scripts/controllers/pay/modals/pay_auto_inducement.js';

import './scripts/controllers/notice/list.js';
import './scripts/controllers/notice/detail.js';
import './scripts/controllers/notice/modals/addNoti.js';

import './scripts/controllers/question/list.js';
import './scripts/controllers/question/modals/detail.js';
import './scripts/controllers/question/modals/inquiry.js';
import './scripts/controllers/question/modals/replyDelayInfo.js';

import './scripts/controllers/etc/down_excel.js';
import './scripts/controllers/etc/excel_down.js';
import './scripts/controllers/etc/integratedMemo_down.js';
import './scripts/controllers/etc/lock_screen.js';
import './scripts/controllers/etc/memo.js';
import './scripts/controllers/etc/subAuth.js';
import './scripts/controllers/etc/unLock.js';
import './scripts/controllers/etc/memo_template.js';
import './scripts/controllers/etc/memo_template_list.js';

import './scripts/controllers/etc/work.js';
import './scripts/controllers/etc/memo_list.js';
import './scripts/controllers/home/calendar.js';
import './scripts/controllers/home/dashboard.js';
import './scripts/controllers/home/modals/guide.js';
import './scripts/controllers/home/modals/shopCaution.js';
import './scripts/controllers/home/modals/simpleGuide.js';
import './scripts/controllers/home/modals/orderReport.js';
import './scripts/controllers/home/modals/rejectReport.js';
import './scripts/controllers/home/modals/reportExistingUser.js';
import './scripts/controllers/home/modals/confirm.js';
import './scripts/controllers/home/modals/solIntroduction.js';
import './scripts/controllers/home/modals/notice.js';
import './scripts/controllers/home/modals/quickCollect.js';
import './scripts/controllers/home/modals/firstShopRegist.js';
import './scripts/controllers/home/modals/quickOnlineProdAdd.js';
import './scripts/controllers/home/modals/wakeUp.js';
import './scripts/controllers/home/modals/wakeUpAddInfo.js';
import './scripts/controllers/home/modals/solution_use_info.js';
import './scripts/controllers/home/modals/autoSettingResult.js';
import './scripts/controllers/summary/summary.js';
import './scripts/controllers/summary/insight.js';
import './scripts/controllers/summary/modals/regist_price_schedule.js';
import './scripts/controllers/summary/modals/regist_online_prod.js';
import './scripts/controllers/summary/modals/price_edit_schedule_list.js';
import './scripts/controllers/summary/modals/price_alarm_schedule_list.js';
import './scripts/controllers/pay/modals/email_list.js';
import './scripts/controllers/summary/aggregation.js';

import './scripts/controllers/altalk/altalk_list.js';
import './scripts/controllers/altalk/detail.js';
import './scripts/controllers/altalk/altalk_link.js';
import './scripts/controllers/altalk/altalk_template_add.js';
import './scripts/controllers/altalk/altalk_template.js';
import './scripts/controllers/altalk/altalk_template_detail.js';
import './scripts/controllers/altalk/altalk_send_group.js';
import './scripts/controllers/altalk/send_group_add.js';
import './scripts/controllers/altalk/send_group_detail.js';
import './scripts/controllers/altalk/send_group_excel.js';
import './scripts/controllers/altalk/altalk_auto_send_register.js';
import './scripts/controllers/altalk/altalk_test_send.js';
import './scripts/controllers/altalk/altalk_auto_register.js';
import './scripts/controllers/altalk/altalk_auto_register_detail.js';
import './scripts/controllers/altalk/altalk_send.js';
import './scripts/controllers/altalk/altalk_auto_update.js';

import './scripts/controllers/event/eventPage.js';

import './scripts/services/settings/shopAccount.js';

import 'react';
import 'react-dom';
import 'ngreact';

