'use strict';

angular.module('gmpApp')
  .controller('setFormTemplateCtrl', function ($scope, $rootScope, $timeout, $state, $window, data, templateModel, commonSVC, $stateParams, $uibModalInstance, onlineProductModel, settings) {

    $rootScope.initView = $state.current.name !== 'main.setForm_template_add.channelSub';    //템플릿관리 등록 초기화면 여부
    $scope.saveEventData = {};  // 템플릿 저장 데이터
    let confirm_modal = true;   //저장 후 컨펌모달 여부
    const modal_type = data.type;   //템플릿 상세, 온라인상품 템플릿 상세 구분 값
    const userInfo = $rootScope.user_profile; // 사용자 정보
    let updated = false;

    // 기존등록 템플릿 정보
    $scope.registed_template_info = data.registed_template_info || null;
    $scope.templateTitle = $scope.registed_template_info ? '기존 템플릿 상세' : '템플릿 상세';

    // 수집템플릿 신규등록시 사용되는 값
    const scrapTemplateAddData = $scope.registed_template_info && $scope.registed_template_info?.registType === 'scrap' ? {
      ...data,
      registed_template_info: {
        ...$scope.registed_template_info,
        registType: 'scrap'
      }
    } : null;

    if (!scrapTemplateAddData && $scope.registed_template_info) {
      delete $scope.registed_template_info.registType;
    }

    if (data.type === 'olProd_add') {
      $scope.templateTitle = '템플릿 등록';
      $stateParams.type = 'add';
      $stateParams.shop_cd = data.shop_cd;
      $stateParams.shop_id = data.shop_id;
      $stateParams.shop_name = data.shop_name;
      $stateParams.pa_shop_cd = data.pa_shop_cd;
    } else if (data.type === 'olProd') {
      $stateParams.type = '';
    }

    loading(false);

    function setformListener(e) {
      if (e.data.updated === false) {
        updated = true;
      }

      $('.box-loading').addClass('hidden'); //로딩중
      $('#setForm').css('height', event.data.height + 200);

      //저장, 수정 버튼 누를시 데이터 저장
      if (event.data.action == 'saved') {
        $scope.saveEventData = event.data;
        const param = {
          //셋폼 url
          url: event.data.result
        };
        let jsonobj = {};

        templateModel.detail(param, function (state, param) {
          if (state == 'success') {
            jsonobj = JSON.parse(param.results.rows);

            //온라인상품 템플릿 요약테이블 정보 초기화
            const summaryInfo = setSummaryObj(jsonobj);
            const summary_obj = summaryInfo.summary_obj;

            jsonobj = summaryInfo.jsonobj;

            const plate = document.location.href.split('/').pop();

            // 템플릿 수정인경우에만 confirm 모달 띄움
            if ($stateParams.type == 'add' && (plate !== 'list' || modal_type === 'olProd_add')) {
              prodStatusUpdate(jsonobj, summary_obj, false);
            } else if (!updated) {
              prodStatusUpdate(jsonobj, summary_obj, false);
            } else {
              const title = '템플릿 변경내용 확인';
              const contents = "템플릿에 변경된 내역이 있습니다.<br>해당 템플릿이 설정된 온라인 상품을 '수정대기' 상태로 변경하시겠습니까?<br>수정대기로 변경된 온라인 상품은 수정전송하여야 실제 쇼핑몰에 수정된 정보로 반영됩니다.";
              const buttons = [
                {
                  name: '예',
                  next: function () {
                    // 템플릿이 적용된 상품 수정대기로 변경후 템플릿 정보 수정.
                    $timeout(function () {
                      prodStatusUpdate(jsonobj, summary_obj, true);
                    });
                  }
                },
                {
                  name: '아니요',
                  next: function () {
                    // 템플릿 정보 수정.
                    $timeout(function () {
                      prodStatusUpdate(jsonobj, summary_obj, false);
                    });
                  }
                },
              ];

              commonSVC.showMultiButton(title, contents, buttons);
            }
          }
        });
      }
      // 신규템플릿 등록
      else if (event.data.action == 'new') {
        const modal = commonSVC.openModal('lg', { data: scrapTemplateAddData }, 'ScrapTemplateCopyCtrl', 'views/online/setForm/scrap_template_copy.html', false, true, false);

        modal.result.then(function(re) {
          $timeout(function () {
            if (re.result === 'success') {
              $uibModalInstance.close(re);
              $window.removeEventListener('message', setformListener, false);
            }
          }, 300);
        });
      }

      //모달 닫기시
      if (event.data.action == 'updated') {
        $uibModalInstance.close('success');
        $window.removeEventListener('message', setformListener, false);
      }
    }

    //템플릿 등록
    if ($stateParams.type == 'add') {
      $scope.TemplateData = {
        name: '',
        shop_cd: $stateParams.shop_cd,
        shop_id: $stateParams.shop_id,
        template_data: $stateParams
      };

      const param = {
        shop_id: $stateParams.shop_id,
        shop_cd: $stateParams.shop_cd,
        shop_name: $stateParams.shop_name,
        pa_shop_cd: $stateParams.pa_shop_cd
      };

      //template 테이블에 insert한후 등록된 number를 가져온다.
      templateModel.insert($scope.TemplateData, function (state, data) {
        if (state === 'success') {
          const number = data.number;

          makeSetForm(number, $stateParams.type, param);
          loading(true);

        } else {
          $scope.submitDisabled = false;
          commonSVC.showToaster('error', '', data);
        }
      });
    }
    //템플릿 수정
    else {
      makeSetForm(data.template_no, $stateParams.type, data);
    }

    //모달 닫기
    $scope.close = function () {
      //2018-03-14 ally 상세보기용일때 컨펌창 없애기위해 추가
      if (data.isEdit === false) {
        $uibModalInstance.dismiss('cancel');
      } else {
        $('#setForm')[0].contentWindow.postMessage({
          action: 'updated'
        }, '*');
      }
    };

    /**
     * 온라인상품 상태 업데이트
     */
    function prodStatusUpdate(jsonobj, summary_obj, prodUpdateYN) {
      if (prodUpdateYN) {
        const parmas = { template_no: jsonobj.item.template_code };

        onlineProductModel.setStateByTemplate(parmas)
          .then(function () {
            updateTemplate(jsonobj, summary_obj);
          })
          .catch(function () {
            commonSVC.showToaster('error', '실패', '상태변경에 실패하였습니다');

            return false;
          });
      } else {
        updateTemplate(jsonobj, summary_obj);
      }
    } // END function

    /**
     * 템플릿 업데이트
     */
    function updateTemplate(jsonobj, summary_obj) {
      templateModel.edit(jsonobj, summary_obj, function (state, data) {
        if (state == 'success') {
          if ($stateParams.type == 'add') {
            let save_type = '';

            if ($scope.saveEventData.save_type == 'saved') {
              save_type = '등록';
            } else if ($scope.saveEventData.save_type == 'updated') {
              save_type = '수정';
            }

            if ($stateParams.type2 == 'ol') {
              commonSVC.showToaster('success', '', `템플릿이 ${save_type} 되었습니다.`);

              //온라인상품 템플릿 리스트 새로고침
              $rootScope.$broadcast('templateRefresh');

              commonSVC.showConfirm(`${save_type}에 성공했습니다.쇼핑몰 상품 등록으로 이동하시겠습니까?`, '', function (confirm) {
                if (confirm) {
                  $scope.initView = true;

                  $state.go('main.online_product_add');
                  $window.removeEventListener('message', setformListener, false);
                }
              });
            } else {
              commonSVC.showToaster('success', '', `템플릿이 ${save_type} 되었습니다.`);

              if (modal_type === 'olProd_add') {
                commonSVC.showToaster('success', '', '템플릿이 등록 되었습니다.');

                commonSVC.showConfirm('템플릿이 등록 되었습니다.', '', function (confirm) {
                  if (confirm) {
                    $uibModalInstance.close('success');
                    $window.removeEventListener('message', setformListener, false);
                  } else {
                    return false;
                  }
                });
              } else {
                commonSVC.showConfirm(`${save_type}에 성공했습니다.\n템플릿 목록으로 이동하시겠습니까?`, '', function (confirm) {
                  if (confirm) {
                    const param = {
                      value: 'true'
                    };

                    $scope.initView = true;
                    $state.go('main.setForm_template_list', param);
                    $state.reload();
                    $window.removeEventListener('message', setformListener, false);
                  }
                });
              }
            }
          } else {
            if (modal_type == 'olProd') {
              commonSVC.showToaster('success', '', '템플릿이 수정 되었습니다.');
              commonSVC.showConfirm('정보가 수정되었습니다.', '', function (confirm) {
                if (confirm) {
                  $uibModalInstance.close('success');
                  $window.removeEventListener('message', setformListener, false);
                } else {
                  return false;
                }
              });
            } else {
              commonSVC.showToaster('success', '', '템플릿이 수정 되었습니다.');
              if (confirm_modal == true) {
                commonSVC.showConfirm('수정에 성공했습니다.\n템플릿 목록으로 이동하시겠습니까?', '', function (confirm) {
                  if (confirm) {
                    $uibModalInstance.close('success');
                    $window.removeEventListener('message', setformListener, false);
                  } else {
                    return false;
                  }
                  confirm_modal == false;
                });
              } else {
                confirm_modal = false;
                $uibModalInstance.close('success');
                $window.removeEventListener('message', setformListener, false);
              }
            }
          }
        } else {
          const errMsg = data.data.error || '템플릿정보 저장에 실패하였습니다.';

          commonSVC.showToaster('error', '', errMsg);
        }
      });
    } // END function

    /*
     * 셋폼 생성
     * 2018-09-18 - Alvin
     * @param number, type, param
     */
    function makeSetForm(number, type, param) {
      // 로컬스토리지 셋폼 키
      const localKey = `gmp_setForm_token_${data.shop_cd}_${data.shop_id}_${userInfo.sol_no}`;

      // 토큰이 없거나 유효시간이 지난 경우 재발급 함
      if (!localStorage[localKey] || JSON.parse(localStorage[localKey]).timestamp <= new Date().getTime()) {
        // 셋폼 토큰 발급
        templateModel.createToken(param, function (state, data) {
          if (state === 'success') {
            //2018-03-14 ally 바로 열려질 탭 추가
            if (type != 'add') {
              data.tab = data.tab ? data.tab : 0;
            }
            const setFormToken = data.token;
            const timeSet = 1000 * 60 * 60 * 24; // 24시간 설정
            const record = { value: setFormToken, timestamp: new Date().getTime() + (timeSet - 10000) };

            localStorage.setItem(localKey, JSON.stringify(record));

            setFormLoad(number, param, localKey);

          } else {
            commonSVC.showToaster('error', '', '토큰생성 실패');
          }
        });
      } else {
        setFormLoad(number, param, localKey);
      }
    }

    /*
     * 셋폼 url 추가
     * 2018-09-18 - Alvin
     * @param number, param
     */
    function setFormLoad(number, param, localKey) {
      const setFormToken = JSON.parse(localStorage[localKey]).value;
      const { uuid } = $rootScope.use_channel_list.find(shop => shop.shop_id === param.shop_id && shop.shop_cd === param.shop_cd);

      // 쇼피파이 국내(P060)와 글로벌(X093) 템플릿 공통 사용으로 pa_shop_cd 변경
      const site_code = param.pa_shop_cd === 'P060' ? 'X093' : param.pa_shop_cd;
      const url = `${settings.setFormUrl}/company/1/form?token=${setFormToken}&site_code=${site_code}&template_code=${number}&title=` + '템플릿 등록' + '&submit=' + '등록하기' + '&save_popup=off' + `&site_name=${param.shop_name}&channel_account_id=${uuid.split('|')[0]}`;

      // 수집템플릿 등록용 데이터 셋팅.
      if (scrapTemplateAddData) {
        scrapTemplateAddData.token = setFormToken;
        scrapTemplateAddData.channel_account_id = uuid.split('|')[0];
      }

      setTimeout(function() {
        $('#setForm').removeClass('hide');

        // 기존템플릿인경우
        if ($scope.registed_template_info) {
          setTimeout(() => {
            document.sendSetForm.action = url;
            document.sendSetForm.submit();
          });
        } else {
          $('#setForm').attr('src', url);
        }
      }, 0);
    } // END function

    /*
     * 로딩이미지
     * 2018-09-18 - Alvin
     * @param complete
     */
    function loading(complete) {
      if (complete) {
        $('.box-loading').addClass('hidden'); //로딩중
      } else {
        $('.box-loading').removeClass('hidden'); // 로딩완료
      }
    } // END function

    /**
     * 템플릿 요약정보 셋팅
     *
     * @param {*} jsonobj 템플릿 상세정보
     */
    function setSummaryObj(jsonobj) {
      const summary_obj = {};

      switch (data.pa_shop_cd) {
        case 'A001':
          summary_obj.selTerms = jsonobj.item.data.key_selTerms;    //판매기간

          if (jsonobj.item.data.key_deliv_fee_type == '0') {     //배송비
            summary_obj.deliv_fee_type = '온라인상품 정보 사용';
          } else if (jsonobj.item.data.key_deliv_fee_type == '1') {
            summary_obj.deliv_fee_type = '옥션 묶음배송비 사용';
          } else {
            summary_obj.deliv_fee_type = '템플릿 정보 사용';
          }

          if (jsonobj.item.data.key_rdoProdType == 'N') {    //상품상태
            summary_obj.ProdType = '신상품';
          } else {
            summary_obj.ProdType = '중고품';
          }

          summary_obj.key_DeliveryFeeTemplateNo = jsonobj.item.data.key_DeliveryFeeTemplateNo; //묶음배송비
          summary_obj.key_DeliveryFeeTemplateNo2 = jsonobj.item.data.key_DeliveryFeeTemplateNo2; //묶음배송비 esm2.0
          break;
        case 'A004':
          summary_obj.end_sale_date = jsonobj.item.data.key_end_sale_date;
          summary_obj.deliv_cost = jsonobj.item.data.key_deliv_cost;
          summary_obj.deliv_property_name = jsonobj.item.data.key_deliv_property_name;
          break;
        case 'A006':
          summary_obj.selTerms = jsonobj.item.data.key_selTerms;    //판매기간

          if (jsonobj.item.data.key_deliv_fee_type == '0') {     //배송비
            summary_obj.deliv_fee_type = '온라인상품 정보 사용';
          } else if (jsonobj.item.data.key_deliv_fee_type == '1') {
            summary_obj.deliv_fee_type = 'G마켓 묶음배송비 사용';
          } else {
            summary_obj.deliv_fee_type = '템플릿 정보 사용';
          }

          if (jsonobj.item.data.key_rdoProdType == 'N') {    //상품상태
            summary_obj.ProdType = '신상품';
          } else {
            summary_obj.ProdType = '중고품';
          }

          summary_obj.key_DeliveryFeeTemplateNo = jsonobj.item.data.key_DeliveryFeeTemplateNo; //묶음배송비
          summary_obj.key_DeliveryFeeTemplateNo2 = jsonobj.item.data.key_DeliveryFeeTemplateNo2; //묶음배송비 esm2.0
          summary_obj.key_GmktDeliveryWayOPTSELParcelCOMP = jsonobj.item.data.key_GmktDeliveryWayOPTSELParcelCOMP; //esm2.0 택배사
          break;
        case 'A008':
          //판매기간
          if (jsonobj.item.data.key_stdSaleTerm == '5') {
            summary_obj.selTerms = '5일';
          } else if (jsonobj.item.data.key_stdSaleTerm == '7') {
            summary_obj.selTerms = '7일';
          } else if (jsonobj.item.data.key_stdSaleTerm == '15') {
            summary_obj.selTerms = '15일';
          } else if (jsonobj.item.data.key_stdSaleTerm == '30') {
            summary_obj.selTerms = '30일';
          } else if (jsonobj.item.data.key_stdSaleTerm == '60') {
            summary_obj.selTerms = '60일';
          } else if (jsonobj.item.data.key_stdSaleTerm == '90') {
            summary_obj.selTerms = '90일';
          } else if (jsonobj.item.data.key_stdSaleTerm == '180') {
            summary_obj.selTerms = '180일';
          } else if (jsonobj.item.data.key_stdSaleTerm == '360') {
            summary_obj.selTerms = '360일';
          }

          if (jsonobj.item.data.key_proddelvCostUseYn == 'N2') {     //배송비
            summary_obj.deliv_fee_type = '온라인상품 정보 사용';
          } else if (jsonobj.item.data.key_proddelvCostUseYn == 'N') {
            summary_obj.deliv_fee_type = '인터파크 묶음배송비 사용';
          } else if (jsonobj.item.data.key_proddelvCostUseYn == 'Y') {
            summary_obj.deliv_fee_type = '템플릿 정보 사용';
          }

          if (jsonobj.item.data.key_prdStat == '01') {    //상품상태
            summary_obj.ProdType = '새상품';
          } else if (jsonobj.item.data.key_prdStat == '02') {
            summary_obj.ProdType = '중고상품';
          } else if (jsonobj.item.data.key_prdStat == '03') {
            summary_obj.ProdType = '반품상품';
          }
          break;
        case 'A011':
          //배송형태
          if (jsonobj.item.data.key_deliveryType == '10') {
            summary_obj.key_deliveryType = '입고택배';
          } else if (jsonobj.item.data.key_deliveryType == '11') {
            summary_obj.key_deliveryType = '입고택배(행거의류)';
          } else if (jsonobj.item.data.key_deliveryType == '12') {
            summary_obj.key_deliveryType = '입고택배(귀금속)';
          } else if (jsonobj.item.data.key_deliveryType == '20') {
            summary_obj.key_deliveryType = '직택배';
          } else if (jsonobj.item.data.key_deliveryType == '30') {
            summary_obj.key_deliveryType = '직송(택배)';
          } else if (jsonobj.item.data.key_deliveryType == '31') {
            summary_obj.key_deliveryType = '직송(설치)';
          }

          //수거형태
          if (jsonobj.item.data.key_collectType == '1') {
            summary_obj.key_collectType = '택배수거';
          } else if (jsonobj.item.data.key_collectType == '2') {
            summary_obj.key_collectType = '직반출';
          } else if (jsonobj.item.data.key_collectType == '3') {
            summary_obj.key_collectType = '업체수거';
          }

          //환불시점
          if (jsonobj.item.data.key_rtnFlag == '10') {
            summary_obj.key_rtnFlag = '상품확인 후 환불';
          } else if (jsonobj.item.data.key_rtnFlag == '20') {
            summary_obj.key_rtnFlag = '즉시환불';
          } else if (jsonobj.item.data.key_rtnFlag == '30') {
            summary_obj.key_rtnFlag = '상담원 우선 상담';
          }
          break;
        case 'A012':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'A017':
          summary_obj.selTerms = `${jsonobj.item.data.key_sale_start_date} ~ ${jsonobj.item.data.key_sale_end_date}`;
          summary_obj.deliv_fee_type = jsonobj.item.data.key_deliv_price_policy_id;
          summary_obj.ProdType = '없음';
          summary_obj.adultYn = jsonobj.item.data.key_adultYn;
          break;
        case 'A027':
          //판매기간
          if (jsonobj.item.data.key_periodRadioSelect == 'N') {
            summary_obj.selTerms = '설정안함';
          } else {
            if (jsonobj.item.data.key_stdSaleTerm == '5') {
              summary_obj.selTerms = '5일';
            } else if (jsonobj.item.data.key_stdSaleTerm == '7') {
              summary_obj.selTerms = '7일';
            } else if (jsonobj.item.data.key_stdSaleTerm == '15') {
              summary_obj.selTerms = '15일';
            } else if (jsonobj.item.data.key_stdSaleTerm == '30') {
              summary_obj.selTerms = '30일';
            } else if (jsonobj.item.data.key_stdSaleTerm == '60') {
              summary_obj.selTerms = '60일';
            } else if (jsonobj.item.data.key_stdSaleTerm == '90') {
              summary_obj.selTerms = '90일';
            } else if (jsonobj.item.data.key_stdSaleTerm == '180') {
              summary_obj.selTerms = '180일';
            } else if (jsonobj.item.data.key_stdSaleTerm == '360') {
              summary_obj.selTerms = '360일';
            }
          }

          if (jsonobj.item.data.key_proddelvCostUseYn == 'N2') {     //배송비
            summary_obj.deliv_fee_type = '온라인상품 정보 사용';
          } else if (jsonobj.item.data.key_proddelvCostUseYn == 'N') {
            summary_obj.deliv_fee_type = '인터파크 묶음배송비 사용';
          } else if (jsonobj.item.data.key_proddelvCostUseYn == 'Y') {
            summary_obj.deliv_fee_type = '템플릿 정보 사용';
          }

          if (jsonobj.item.data.key_prdStat == '01') {    //상품상태
            summary_obj.ProdType = '새상품';
          } else if (jsonobj.item.data.key_prdStat == '02') {
            summary_obj.ProdType = '중고상품';
          } else if (jsonobj.item.data.key_prdStat == '03') {
            summary_obj.ProdType = '반품상품';
          }
          break;
        case 'A032':
          //판매기간
          summary_obj.selTerms = `${jsonobj.item.data.key_dispStrtDts} ~ ${jsonobj.item.data.key_dispEndDts}`;

          //배송비
          if (jsonobj.item.data.key_whoutShppcst_Cd == '0000503053') {
            summary_obj.deliv_fee_type = '선불 / 상품별 주문금액 합산 / 0원';
          } else if (jsonobj.item.data.key_whoutShppcst_Cd == '0000517844') {
            summary_obj.deliv_fee_type = '선불 / 상품별 주문금액 합산 / 2500원';
          } else if (jsonobj.item.data.key_whoutShppcst_Cd == '0000507523') {
            summary_obj.deliv_fee_type = '선불 / 주문 금액 합산 / 2500원';
          } else if (jsonobj.item.data.key_whoutShppcst_Cd == '0000499014') {
            summary_obj.deliv_fee_type = '선불 / 상품 수량 별 / 2500원';
          }

          //배송비
          if (jsonobj.item.data.key_itemStatTypeCd == '10') {
            summary_obj.ProdType = '새상품';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '20') {
            summary_obj.ProdType = '중고';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '30') {
            summary_obj.ProdType = '리퍼';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '40') {
            summary_obj.ProdType = '전시';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '50') {
            summary_obj.ProdType = '반품';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '60') {
            summary_obj.ProdType = '스크래치';
          }
          break;
        case 'A064':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'A076':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'A077':
          //판매기간
          if (jsonobj.item.data.key_selTermsYn == 'N') {
            summary_obj.selTerms = '설정안함';
          } else if (jsonobj.item.data.key_selTermsYn == 'Y') {
            if (jsonobj.item.data.key_selTerms == '3') {
              summary_obj.selTerms = '3일';
            } else if (jsonobj.item.data.key_selTerms == '5') {
              summary_obj.selTerms = '5일';
            } else if (jsonobj.item.data.key_selTerms == '7') {
              summary_obj.selTerms = '7일';
            } else if (jsonobj.item.data.key_selTerms == '15') {
              summary_obj.selTerms = '15일';
            } else if (jsonobj.item.data.key_selTerms == '30') {
              summary_obj.selTerms = '30일';
            } else if (jsonobj.item.data.key_selTerms == '60') {
              summary_obj.selTerms = '60일';
            } else if (jsonobj.item.data.key_selTerms == '90') {
              summary_obj.selTerms = '90일';
            } else if (jsonobj.item.data.key_selTerms == '120') {
              summary_obj.selTerms = '120일';
            } else if (jsonobj.item.data.key_selTerms == '0') {
              summary_obj.selTerms = `${jsonobj.item.data.key_SaleStartDate}~${jsonobj.item.data.key_SaleEndDate}`;
            }
          }

          if (jsonobj.item.data.key_proddelvCostUseYn == 'N2') {     //배송비
            summary_obj.deliv_fee_type = '온라인상품 정보 사용';
          } else if (jsonobj.item.data.key_deliv_fee_type == 'Y') {
            summary_obj.deliv_fee_type = '템플릿 정보 사용';
          }

          if (jsonobj.item.data.key_rdoMethod == 'NEW') {    //상품상태
            summary_obj.ProdType = '신상품';
          } else if (jsonobj.item.data.key_rdoMethod == 'OLD') {
            summary_obj.ProdType = '중고품';
          }
          break;
        case 'A083':
        case 'A084':
        case 'A085':
          //판매기간
          summary_obj.selTerms = `${jsonobj.item.data.key_dispStrtDts} ~ ${jsonobj.item.data.key_dispEndDts}`;

          //배송비
          if (jsonobj.item.data.key_whoutShppcst_Cd == '0000503053') {
            summary_obj.deliv_fee_type = '선불 / 상품별 주문금액 합산 / 0원';
          } else if (jsonobj.item.data.key_whoutShppcst_Cd == '0000517844') {
            summary_obj.deliv_fee_type = '선불 / 상품별 주문금액 합산 / 2500원';
          } else if (jsonobj.item.data.key_whoutShppcst_Cd == '0000507523') {
            summary_obj.deliv_fee_type = '선불 / 주문 금액 합산 / 2500원';
          } else if (jsonobj.item.data.key_whoutShppcst_Cd == '0000499014') {
            summary_obj.deliv_fee_type = '선불 / 상품 수량 별 / 2500원';
          }

          //배송비
          if (jsonobj.item.data.key_itemStatTypeCd == '10') {
            summary_obj.ProdType = '새상품';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '20') {
            summary_obj.ProdType = '중고';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '30') {
            summary_obj.ProdType = '리퍼';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '40') {
            summary_obj.ProdType = '전시';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '50') {
            summary_obj.ProdType = '반품';
          } else if (jsonobj.item.data.key_itemStatTypeCd == '60') {
            summary_obj.ProdType = '스크래치';
          }
          break;
        case 'A100':
          //매입형태
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          } else if (jsonobj.item.data.key_pur_shp_cd == '4') {
            summary_obj.key_pur_shp_cd = '특정';
          }

          //배송처리유형
          if (jsonobj.item.data.key_dlv_proc_tp_cd == '1') {     //배송비
            summary_obj.key_dlv_proc_tp_cd = '업체배송';
          } else if (jsonobj.item.data.key_dlv_proc_tp_cd == '3') {
            summary_obj.key_dlv_proc_tp_cd = '센터배송';
          } else if (jsonobj.item.data.key_dlv_proc_tp_cd == '6') {
            summary_obj.key_dlv_proc_tp_cd = 'e-쿠폰배송';
          }

          //교환/반품 가능여부
          if (jsonobj.item.data.key_exch_rtgs_sct_cd == '20') {    //상품상태
            summary_obj.key_exch_rtgs_sct_cd = '가능';
          } else if (jsonobj.item.data.key_exch_rtgs_sct_cd == '10') {
            summary_obj.key_exch_rtgs_sct_cd = '불가능';
          }
          break;
        case 'A109':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'A112':
          jsonobj.item.data.selTerms = jsonobj.item.data.key_selTerms;    //판매기간

          // 실제 처리되는 값은 key_selTerms 이 아니라 key_selPrdClfCd 라고 하여 수정 2018-05-17 rony
          if (jsonobj.item.data.key_selTermUseYn == 'Y') {
            if (jsonobj.item.data.key_selPrdClfCd == '3:101') {
              summary_obj.selTerms = '3일';
            } else if (jsonobj.item.data.key_selPrdClfCd == '5:102') {
              summary_obj.selTerms = '5일';
            } else if (jsonobj.item.data.key_selPrdClfCd == '7:103') {
              summary_obj.selTerms = '7일';
            } else if (jsonobj.item.data.key_selPrdClfCd == '15:104') {
              summary_obj.selTerms = '15일';
            } else if (jsonobj.item.data.key_selPrdClfCd == '30:105') {
              summary_obj.selTerms = '30일(1개월)';
            } else if (jsonobj.item.data.key_selPrdClfCd == '60:106') {
              summary_obj.selTerms = '60일(2개월)';
            } else if (jsonobj.item.data.key_selPrdClfCd == '90:107') {
              summary_obj.selTerms = '90일(3개월)';
            } else if (jsonobj.item.data.key_selPrdClfCd == '120:108') {
              summary_obj.selTerms = '120일(4개월)';
            } else if (jsonobj.item.data.key_selPrdClfCd == '0:10099') {
              summary_obj.selTerms = `종료일 : ${jsonobj.item.data.key_write_edate}`;
            }
          } else {
            summary_obj.selTerms = '설정안함';
          }

          if (jsonobj.item.data.key_deliv_fee_type == '00') {     //배송비
            summary_obj.deliv_fee_type = '온라인상품 정보 사용';
          } else {
            summary_obj.deliv_fee_type = '템플릿 정보 사용';
          }

          if (jsonobj.item.data.key_prdStatCd == '01') {    //상품상태
            summary_obj.ProdType = '새상품';
          } else if (jsonobj.item.data.key_prdStatCd == '03') {
            summary_obj.ProdType = '재고상품';
          } else if (jsonobj.item.data.key_prdStatCd == '02') {
            summary_obj.ProdType = '중고상품';
          } else if (jsonobj.item.data.key_prdStatCd == '08') {
            summary_obj.ProdType = '반품상품';
          } else if (jsonobj.item.data.key_prdStatCd == '04') {
            summary_obj.ProdType = '리퍼상품';
          } else if (jsonobj.item.data.key_prdStatCd == '05') {
            summary_obj.ProdType = '전시(진열)상품';
          } else if (jsonobj.item.data.key_prdStatCd == '09') {
            summary_obj.ProdType = '스크래치상품';
          } else if (jsonobj.item.data.key_prdStatCd == '06') {
            summary_obj.ProdType = '주문제작상품';
          } else if (jsonobj.item.data.key_prdStatCd == '07') {
            summary_obj.ProdType = '희귀소상품';
          }
          break;
        case 'A118':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'A131':
          jsonobj.item.data.selTerms = jsonobj.item.data.key_selTerms;    //판매기간

          if (jsonobj.item.data.key_selTermUseYn == 'Y') {
            if (jsonobj.item.data.key_selTerm == '3:101') {
              summary_obj.selTerms = '3일';
            } else if (jsonobj.item.data.key_selTerm == '5:102') {
              summary_obj.selTerms = '5일';
            } else if (jsonobj.item.data.key_selTerm == '7:103') {
              summary_obj.selTerms = '7일';
            } else if (jsonobj.item.data.key_selTerm == '15:104') {
              summary_obj.selTerms = '15일';
            } else if (jsonobj.item.data.key_selTerm == '30:105') {
              summary_obj.selTerms = '30일(1개월)';
            } else if (jsonobj.item.data.key_selTerm == '60:106') {
              summary_obj.selTerms = '60일(2개월)';
            } else if (jsonobj.item.data.key_selTerm == '90:107') {
              summary_obj.selTerms = '90일(3개월)';
            } else if (jsonobj.item.data.key_selTerm == '120:108') {
              summary_obj.selTerms = '120일(4개월)';
            } else if (jsonobj.item.data.key_selTerm == '0:10099') {
              summary_obj.selTerms = `종료일 : ${jsonobj.item.data.key_write_edate}`;
            }
          } else {
            summary_obj.selTerms = '설정안함';
          }

          if (jsonobj.item.data.key_deliv_fee_type == '00') {     //배송비
            summary_obj.deliv_fee_type = '온라인상품 정보 사용';
          } else {
            summary_obj.deliv_fee_type = '템플릿 정보 사용';
          }

          if (jsonobj.item.data.key_prdStatCd == '01') {    //상품상태
            summary_obj.ProdType = '새상품';
          } else if (jsonobj.item.data.key_prdStatCd == '03') {
            summary_obj.ProdType = '재고상품';
          } else if (jsonobj.item.data.key_prdStatCd == '02') {
            summary_obj.ProdType = '중고상품';
          } else if (jsonobj.item.data.key_prdStatCd == '08') {
            summary_obj.ProdType = '반품상품';
          } else if (jsonobj.item.data.key_prdStatCd == '04') {
            summary_obj.ProdType = '리퍼상품';
          } else if (jsonobj.item.data.key_prdStatCd == '05') {
            summary_obj.ProdType = '전시(진열)상품';
          } else if (jsonobj.item.data.key_prdStatCd == '09') {
            summary_obj.ProdType = '스크래치상품';
          } else if (jsonobj.item.data.key_prdStatCd == '06') {
            summary_obj.ProdType = '주문제작상품';
          } else if (jsonobj.item.data.key_prdStatCd == '07') {
            summary_obj.ProdType = '희귀소상품';
          }
          break;
        case 'A140':
        case 'A150':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'A524':
          //배송형태
          if (jsonobj.item.data.key_deliveryType == '10') {
            summary_obj.key_deliveryType = '입고택배';
          } else if (jsonobj.item.data.key_deliveryType == '11') {
            summary_obj.key_deliveryType = '입고택배(행거의류)';
          } else if (jsonobj.item.data.key_deliveryType == '12') {
            summary_obj.key_deliveryType = '입고택배(귀금속)';
          } else if (jsonobj.item.data.key_deliveryType == '20') {
            summary_obj.key_deliveryType = '직택배';
          } else if (jsonobj.item.data.key_deliveryType == '30') {
            summary_obj.key_deliveryType = '직송(택배)';
          } else if (jsonobj.item.data.key_deliveryType == '31') {
            summary_obj.key_deliveryType = '직송(설치)';
          }

          //수거형태
          if (jsonobj.item.data.key_collectType == '1') {
            summary_obj.key_collectType = '택배수거';
          } else if (jsonobj.item.data.key_collectType == '2') {
            summary_obj.key_collectType = '직반출';
          } else if (jsonobj.item.data.key_collectType == '3') {
            summary_obj.key_collectType = '업체수거';
          }

          //환불시점
          if (jsonobj.item.data.key_rtnFlag == '10') {
            summary_obj.key_rtnFlag = '상품확인 후 환불';
          } else if (jsonobj.item.data.key_rtnFlag == '20') {
            summary_obj.key_rtnFlag = '즉시환불';
          } else if (jsonobj.item.data.key_rtnFlag == '30') {
            summary_obj.key_rtnFlag = '상담원 우선 상담';
          }
          break;
        case 'A528':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'B005':
          summary_obj.selTerms = jsonobj.item.data.key_selTerms;    //판매기간
          summary_obj.key_DeliveryFeeTemplateNo = jsonobj.item.data.key_DeliveryFeeTemplateNo; //묶음배송비
          summary_obj.key_DeliveryFeeTemplateNo2 = jsonobj.item.data.key_DeliveryFeeTemplateNo2; //묶음배송비 esm2.0
          break;
        case 'B007':
          summary_obj.txtBrandName = jsonobj.item.data.key_txtBrandName;
          summary_obj.txtProductCo = jsonobj.item.data.key_txtProductCo;
          summary_obj.txtDeliveryType = jsonobj.item.data.key_txtDeliveryType;
          break;
        case 'B048':
          //할인가
          if (jsonobj.item.data.key_dctype == 'P') {
            summary_obj.key_dctype = '할인가';
          } else {
            summary_obj.key_dctype = '할인율';
          }
          summary_obj.key_dc = jsonobj.item.data.key_dc;

          //배송
          if (jsonobj.item.data.key_deliv == 'X') {
            summary_obj.key_deliv = '업체배송(무료)';
          } else if (jsonobj.item.data.key_deliv == 'Y') {
            summary_obj.key_deliv = '업체배송(착불)';
          } else if (jsonobj.item.data.key_deliv == 'W') {
            summary_obj.key_deliv = '1300K배송(착불)';
          } else if (jsonobj.item.data.key_deliv == 'Z') {
            summary_obj.key_deliv = '업체배송(유료)';
          }

          //상품종류
          if (jsonobj.item.data.key_goodstype == '0') {
            summary_obj.key_goodstype = '일반상품';
          } else if (jsonobj.item.data.key_goodstype == '1') {
            summary_obj.key_goodstype = '여행상품';
          } else if (jsonobj.item.data.key_goodstype == '2') {
            summary_obj.key_goodstype = '공연';
          } else if (jsonobj.item.data.key_goodstype == '4') {
            summary_obj.key_goodstype = '교육';
          } else if (jsonobj.item.data.key_goodstype == '7') {
            summary_obj.key_goodstype = '도서';
          } else if (jsonobj.item.data.key_goodstype == '8') {
            summary_obj.key_goodstype = '음반';
          } else if (jsonobj.item.data.key_goodstype == '12') {
            summary_obj.key_goodstype = '해외구매대행';
          } else if (jsonobj.item.data.key_goodstype == '13') {
            summary_obj.key_goodstype = '주문제작';
          }
          break;
        case 'B051':
          summary_obj.brandNm = jsonobj.item.data.key_BRAN_Nm;                // 브팬드명
          summary_obj.delivery_time = jsonobj.item.data.key_DELIVERY_TIME;    // 출고가능일
          summary_obj.delivery_cost = jsonobj.item.data.key_DELIVERY_COST;    // 배송과금방법
          summary_obj.delivery_price = jsonobj.item.data.key_DELIVERY_PRICE;  // 배송비
          break;
        case 'B118':
          switch (jsonobj.item.data.key_ems_type) {
            case 'DEFAULT':
              summary_obj.ems_type = '기본값 사용';
              break;
            case 'OVERSEAS':
              summary_obj.ems_type = '국내/해외 배송 상품';
              break;
            case 'KOR_ONLY':
              summary_obj.ems_type = '국내배송 전용 상품';
              break;
          }

          switch (jsonobj.item.data.key_freedeli) {
            case 'N':
              summary_obj.freedeli = '기본배송비 적용 (상품배송관련 조건에서 설정한 기본배송비가 청구됩니다.)';
              break;
            case 'Y':
              summary_obj.freedeli = '단품무료 배송비 적용 (기본배송비 설정과 별개로 이 상품만 무료 배송됩니다.)';
              break;
            case 'A':
              summary_obj.freedeli = '전체무료 배송비 적용 (이 상품을 포함한 모든 주문서의 상품들을 무료 배송 처리합니다.)';
              break;
          }
          break;
        case 'B378':
          summary_obj.saleEndedAt = jsonobj.item.data.key_saleEndedAt;
          switch (jsonobj.item.data.key_deliveryMethod) {
            case 'SEQUENCIAL':
              summary_obj.deliveryMethod = '일반배송';
              break;
            case 'VENDOR_DIRECT':
              summary_obj.deliveryMethod = '업체직송';
              break;
            case 'MAKE_ORDER':
              summary_obj.deliveryMethod = '주문제작';
              break;
            case 'INSTRUCTURE':
              summary_obj.deliveryMethod = '설치배송';
              break;
            case 'AGENT_BUY':
              summary_obj.deliveryMethod = '구매대행';
              break;
            case 'COLD_FRESH':
              summary_obj.deliveryMethod = '신선냉동';
              break;
            case 'MAKE_ORDER_DIRECT':
              summary_obj.deliveryMethod = '주문제작(업체직송)';
              break;
          }
          summary_obj.deliveryCharge = jsonobj.item.data.key_deliveryCharge;
          summary_obj.parallelImported = jsonobj.item.data.key_parallelImported;
          break;
        case 'B394':
        case 'B502':
          summary_obj.selTerms = jsonobj.item.data.key_selTerms;    //판매기간
          summary_obj.key_DeliveryFeeTemplateNo = jsonobj.item.data.key_DeliveryFeeTemplateNo; //묶음배송비
          summary_obj.key_DeliveryFeeTemplateNo2 = jsonobj.item.data.key_DeliveryFeeTemplateNo2; //묶음배송비 esm2.0
          break;
        case 'B430':
          summary_obj.key_deliveryPrice = jsonobj.item.data.key_deliveryPrice;                    // 배송비
          summary_obj.key_exchangeDeliveryPrice = jsonobj.item.data.key_exchangeDeliveryPrice;    // 교환배송비
          summary_obj.key_returnDeliveryPrice = jsonobj.item.data.key_returnDeliveryPrice;        // 반품배송비
          break;
        case 'B513':
        {
          summary_obj.selTerms = `${jsonobj.item.data.key_saleFrDt} ~ ${jsonobj.item.data.key_saleToDt}`; //판매기간

          const deli_fee = jsonobj.item.data.key_delryFee ? `(${jsonobj.item.data.key_delryFee}원)` : '(무료)'; //배송비

          switch (jsonobj.item.data.key_dlvWyCd) {
            case '1':
              summary_obj.deliv_fee_type = `택배${deli_fee}`;
              break;
            case '5':
              summary_obj.deliv_fee_type = `우편(소포/등기)${deli_fee}`;
              break;
            case '3':
              summary_obj.deliv_fee_type = `직접전달(화물배달)${deli_fee}`;
              break;
          }
          summary_obj.ProdType = '없음'; //상품상태
          break;
        }
        case 'B579':
          // 상품상태
          switch (jsonobj.item.data.key_goodsState) {
            case 'none': summary_obj.key_goodsState = '새상품';
              break;
            case 'used': summary_obj.key_goodsState = '중고상품';
              break;
            case 'back': summary_obj.key_goodsState = '리퍼상품';
              break;
            case 'display': summary_obj.key_goodsState = '전시상품';
              break;
            case 'refund': summary_obj.key_goodsState = '반품상품';
              break;
            case 'scratch': summary_obj.key_goodsState = '스크래치';
              break;
          }

          // 배송비
          switch (jsonobj.item.data.key_deliveryMethod) {
            case '101': summary_obj.key_deliveryMethod = `무료배송이며 묶음배송 ${jsonobj.item.data.key_isbundleDelivery ? '가능' : '불가'}`;
              break;
            case '103': summary_obj.key_deliveryMethod = `착불 ${jsonobj.item.data.key_deliveryFeeCollect}원이며 ${jsonobj.item.data.key_deliveryLimitCollect}원 이상이면 무료`;
              break;
            case '102': summary_obj.key_deliveryMethod = `선불 ${jsonobj.item.data.key_deliveryFeeCollect}원이며 ${jsonobj.item.data.key_deliveryLimitCollect}원 이상이면 무료`;
              break;
            case '930': summary_obj.key_deliveryMethod = `상품수량별 ${jsonobj.item.data.key_DeliveryFeeSu}개당 ${jsonobj.item.data.key_DeliveryFeeSuPrice}원`;
              break;
            case '920': summary_obj.key_deliveryMethod = '판매자 정책';
              break;
          }
          break;
        case 'B594':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'B603':
        {
          let dlprcTpCd = '무료';

          if (jsonobj.item.data.key_dlprcTpCd == '20') {
            dlprcTpCd = '주문수량별부과';
          } else if (jsonobj.item.data.key_dlprcTpCd == '30') {
            dlprcTpCd = '협력사기본설정';
          }

          let sale_term = `${jsonobj.item.data.key_prodSellStrtDts} ~ ${jsonobj.item.data.key_prodSellEndDts}`;

          if (jsonobj.item.data.key_allTimesSale == 'Y') {
            sale_term = '상시판매';
          }

          summary_obj.buy_type = jsonobj.item.data.key_pfTpCd == '10' ? '판매분매입' : '수수료매입';       // 매입유형
          summary_obj.sale_term = sale_term;                                                             // 판매기간
          summary_obj.delivery_type = dlprcTpCd;                                                         // 배송비유형
          summary_obj.delivery_price = _.isUndefined(jsonobj.item.data.key_prodDlprc) || jsonobj.item.data.key_prodDlprc == '' ? 0 : jsonobj.item.data.key_prodDlprc;  // 배송비
          break;
        }
        case 'B614':
          summary_obj.mdCode = jsonobj.item.data.key_mdCode_nm;           // 엠디
          summary_obj.buyMed = jsonobj.item.data.key_buyMed_nm;           // 매입방법
          summary_obj.margin = jsonobj.item.data.key_margin;           // 마진율
          summary_obj.shipCost = jsonobj.item.data.key_shipCostCode_nm;   // 배송비정책
          break;
        case 'B616':
          switch (jsonobj.item.data.key_prd_used_type) {
            case 'N': summary_obj.prd_used_type = '신상품'; break;
            case 'R': summary_obj.prd_used_type = '반품/재고/진열 상품'; break;
            case 'U': summary_obj.prd_used_type = '중고상품'; break;
          }

          summary_obj.use_expiration_date = jsonobj.item.data.key_use_expiration_date;               // 유효기간 사용여부(사용:T,미사용:F)
          summary_obj.pr_start_date = jsonobj.item.data.key_pr_start_date;                           // 유효기간 시작
          summary_obj.pr_end_date = jsonobj.item.data.key_pr_end_date;                               // 유효기간 종료
          summary_obj.use_individual_ship_config = jsonobj.item.data.key_use_individual_ship_config; // 배송정보(개별설정:T,기본설정:F)

          switch (jsonobj.item.data.key_delivery_fee_type) {
            case 'T': summary_obj.delivery_fee_type = '배송비 무료'; break;
            case 'R': summary_obj.delivery_fee_type = '고정배송비 사용'; break;
            case 'M': summary_obj.delivery_fee_type = '구매 금액에 따른 부과'; break;
            case 'D': summary_obj.delivery_fee_type = '구매 금액별 차등 배송료 사용'; break;
            case 'W': summary_obj.delivery_fee_type = '상품 무게별 차등 배송료 사용'; break;
            case 'C': summary_obj.delivery_fee_type = '상품 수량별 차등 배송료 사용'; break;
            case 'N': summary_obj.delivery_fee_type = '상품 수량에 비례하여 배송료 부과'; break;
          }

          summary_obj.pr_date = summary_obj.use_expiration_date == 'T' ? (`${summary_obj.pr_start_date} ~ ${summary_obj.pr_end_date}`) : '사용안함';
          summary_obj.delibery_fee_type = summary_obj.use_individual_ship_config == 'T' ? summary_obj.delivery_fee_type : '기본설정 사용';
          break;
        case 'B666':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'B687':
        case 'B690':
        case 'B957':
        case 'B959':
          summary_obj.selTerms = jsonobj.item.data.key_selTerms;    //판매기간
          summary_obj.key_DeliveryFeeTemplateNo = jsonobj.item.data.key_DeliveryFeeTemplateNo; //묶음배송비
          summary_obj.key_DeliveryFeeTemplateNo2 = jsonobj.item.data.key_DeliveryFeeTemplateNo2; //묶음배송비 esm2.0
          break;
        case 'B688':
          summary_obj.selTerms = jsonobj.item.data.key_selTerms;    //판매기간

          switch (jsonobj.item.data.key_ProductCondition) {
            case 'NEW': summary_obj.prd_used_type = '새상품'; break;
            case 'OLD': summary_obj.prd_used_type = '중고상품'; break;
            case 'STOCKED': summary_obj.prd_used_type = '재고상품'; break;
            case 'REFURBISH': summary_obj.prd_used_type = '리퍼상품'; break;
            case 'DISPLAY': summary_obj.prd_used_type = '전시상품'; break;
          }

          summary_obj.use_expiration_date = jsonobj.item.data.key_useSalePeriod == 'true'; // 유효기간 사용여부
          summary_obj.pr_start_date = jsonobj.item.data.key_saleStartedAt;                           // 유효기간 시작
          summary_obj.pr_end_date = jsonobj.item.data.key_saleEndedAt;                               // 유효기간 종료
          summary_obj.use_individual_ship_config = jsonobj.item.data.key_UseDelivType; // 배송정보(개별설정:set,기본설정:prod)

          switch (jsonobj.item.data.key_DeliveryFeeType) {
            case 'FREE': summary_obj.delivery_fee_type = '배송비 무료'; break;
            case 'PAID': summary_obj.delivery_fee_type = '고정배송비 사용'; break;
            case 'CONDITIONAL_FREE': summary_obj.delivery_fee_type = '배송비 조건부 무료'; break;
            case 'QUANTITY_PAID': summary_obj.delivery_fee_type = '배송비 수량별 부과'; break;
          }
          summary_obj.pr_date = summary_obj.use_expiration_date ? (`${summary_obj.pr_start_date} ~ ${summary_obj.pr_end_date}`) : '사용안함';
          summary_obj.delibery_fee_type = summary_obj.use_individual_ship_config == 'set' ? summary_obj.delivery_fee_type : '상품 기본 정보 사용';
          break;
        case 'B691':
          summary_obj.selTerms = jsonobj.item.data.key_salesRange === 'N' ? '설정안함' : jsonobj.item.data.key_salesEndYmd;    //판매기간
          break;
        case 'B696':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'B700':
          summary_obj.saleDate = `${jsonobj.item.data.saleStartDate} ~ ${jsonobj.item.data.saleEndDate}`;           // 판매기간
          summary_obj.productTypeName = jsonobj.item.data.productTypeName;           // 상품유형
          summary_obj.shipPolicyName = jsonobj.item.data.shipPolicyName;             // 배송정보
          summary_obj.claimShipFeeName = jsonobj.item.data.claimShipFeeName;         // 반품/교환배송비
          break;
        case 'B719':
          summary_obj.saleDate = `${jsonobj.item.data.saleStartDate} ~ ${jsonobj.item.data.saleEndDate}`;           // 판매기간
          summary_obj.productTypeName = jsonobj.item.data.productTypeName;           // 상품유형
          summary_obj.shipPolicyName = jsonobj.item.data.shipPolicyName;             // 배송정보
          summary_obj.claimShipFeeName = jsonobj.item.data.claimShipFeeName;         // 반품/교환배송비
          break;
        case 'B877':
        case 'B878':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
        case 'B917':
          summary_obj.selTerms = jsonobj.item.data.key_sales_range === 'N' ? '설정안함' : jsonobj.item.data.key_sales_range_end;    //판매기간

          if (jsonobj.item.data.key_delivery_type == '0') {     //배송비
            summary_obj.deliv_fee_type = '사용안함(기본 배송 정책에 따름)';
          } else if (jsonobj.item.data.key_delivery_type == '1') {
            summary_obj.deliv_fee_type = '무료배송';
          } else if (jsonobj.item.data.key_delivery_type == '3') {
            summary_obj.deliv_fee_type = '착불 배송비';
          } else if (jsonobj.item.data.key_delivery_type == '4') {
            summary_obj.deliv_fee_type = '고정 배송비';
          } else if (jsonobj.item.data.key_delivery_type == '5') {
            summary_obj.deliv_fee_type = '수량별 배송비';
          }

          if (jsonobj.item.data.key_goods_status == 'R') {    //상품상태
            summary_obj.ProdType = '반품/재고상품';
          } else if (jsonobj.item.data.key_goods_status == 'U') {
            summary_obj.ProdType = '중고품';
          } else {
            summary_obj.ProdType = '신상품';
          }
          break;
        case 'B956':
        {
          summary_obj.delivery_fee_policy = jsonobj.item.data.delivery_fee_policy;    //배송비 정책
          summary_obj.delivery_fee = jsonobj.item.data.delivery_fee;    //배송비 금액

          const key_date = `${jsonobj.item.data.key_startDate} ~ ${jsonobj.item.data.key_endDate}`;

          summary_obj.key_date = key_date;
          break;
        }
        case 'P012':
        case 'P013':
        case 'P014':
        case 'P015':
        case 'P016':
        case 'P017':
        case 'P018':
        case 'P028':
          if (jsonobj.item.data.key_pur_shp_cd == '1') {
            summary_obj.key_pur_shp_cd = '직매입';
          } else if (jsonobj.item.data.key_pur_shp_cd == '3') {
            summary_obj.key_pur_shp_cd = '위탁판매';
          }

          //key_GOODS_STAT_CD
          if (jsonobj.item.data.key_GOODS_STAT_CD == '1') {
            summary_obj.key_GOODS_STAT_CD = '신상품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '2') {
            summary_obj.key_GOODS_STAT_CD = '중고';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '3') {
            summary_obj.key_GOODS_STAT_CD = '리퍼';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '4') {
            summary_obj.key_GOODS_STAT_CD = '전시';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '5') {
            summary_obj.key_GOODS_STAT_CD = '반품';
          } else if (jsonobj.item.data.key_GOODS_STAT_CD == '6') {
            summary_obj.key_GOODS_STAT_CD = '스크래치';
          }

          //병행수입여부
          if (jsonobj.item.data.key_PRL_IMP_YN == 'N') {
            summary_obj.key_PRL_IMP_YN = 'No';
          } else if (jsonobj.item.data.key_PRL_IMP_YN == 'Y') {
            summary_obj.key_PRL_IMP_YN = 'Yes';
          }

          //할부여부
          if (jsonobj.item.data.key_INST_YN == 'N') {
            summary_obj.key_INST_YN = 'No';
          } else if (jsonobj.item.data.key_INST_YN == 'Y') {
            summary_obj.key_INST_YN = 'Yes';
          }
          break;
      }

      return {
        summary_obj: {
          data: summary_obj
        },
        jsonobj
      };
    }

    //셋폼 이벤트 리스너
    $window.addEventListener('message', setformListener);

    /* 스코프가 제거될 때 리스너 함께 제거 */
    $scope.$on('$destroy', () => {
      $window.removeEventListener('message', setformListener, false);
    });
  });
