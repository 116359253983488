'use strict';

angular.module('gmpApp')
  .controller('SummaryCtrl', function ($scope, $rootScope, $timeout, summarySVC, summaryModel, commonSVC, userInfo, systemModel) {
    if (userInfo.permission.summary && !userInfo.permission.summary.use_yn) {
      commonSVC.showMessage('권한이 없는 사용자입니다.');
      $rootScope.$emit('$closeTab', 'tab_summary');
    }

    // 별칭정보 담기
    const seller_nick_info = [];

    $rootScope.use_channel_list.forEach(chRow => {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    let isInitDone = false; // 페이지 초기화 완료 여부

    const channelListArr = [
      ...$rootScope.use_channel_list,
      { shop_name: '직접입력', shop_cd: 'A000', shop_id: '' }
    ];
    const options = commonSVC.getSiteIdList(channelListArr.filter(channel => !(channel.pa_shop_cd?.[0] === 'X'))).map(shop => {
      return {
        label: `${shop.shop_name === '직접입력' ? shop.shop_name : shop.shop_name + '(' + shop.shop_id + ')'}`,
        title: `${shop.shop_name === '직접입력' ? shop.shop_name : shop.shop_name + '(' + shop.shop_id + ')'}`,
        value: `("${shop.shop_cd}", "${shop.shop_id}")`,
      };
    });

    $('#shop_multi_select').multiselect({
      includeSelectAllOption: true,
      nonSelectedText: '쇼핑몰(계정)선택',
      buttonWidth: '200px',
      optionClass: () => {
        return 'pl-20';
      }
    });
    $('#shop_multi_select').multiselect('dataprovider', options);

    $scope.today = moment().format('YYYY년 MM월 DD일 (dd)');

    // 차트 노출데이터 없음 처리
    $scope.chartNoData = {
      shop: false,
      prod: false
    };

    $scope.date_type = [
      { name: '주문수집일', value: 'wdate' },
      { name: '출고완료일', value: 'out_time' },
      { name: '송장전송일', value: 'invoice_send_time' },
      { name: '주문일', value: 'ord_time' },
      { name: '배송완료일', value: 'ship_com_time' },
    ];

    // c3 차트 load 처리를 위한 객체 저장 변수
    const graphData = {
      c3_data: []
    };

    // 페이지 로드 시, 기본으로 보여줄 탭 설정
    $scope.tabIndex = -1;

    // 상세 검색 사용함
    $scope.detailSearchFlag = true;

    // 하나의 페이지에서 여러 탭의 구현을 위해 ng-if 참조 값으로 사용할 변수 집합
    $scope.totalInit = false;
    $scope.prodInit = false;
    $scope.shopInit = false;
    $scope.baseInit = false;

    // 상품별 탭 그래프 차트에 대한 데이터 표출 유형 참조 변수 ( 스위치를 버튼으로 구현 )
    $scope.prod_sell = true;
    $scope.prod_sell_cnt = false;

    /**
     * 검색폼 초기화
     */
    const formReset = type => {
      if (type === 'all') {
        $scope.searchForm = angular.copy(summarySVC.searchForm);
      } else {
        $scope.searchForm.date_type_sub[type] = angular.copy(summarySVC.searchForm.date_type_sub[type]);
        $scope.searchForm.date_sub[type] = angular.copy(summarySVC.searchForm.date_sub[type]);
        if (type === 'top') {
          $scope.searchForm.date_unit_sub.shop = angular.copy(summarySVC.searchForm.date_unit_sub.shop);
          $scope.searchForm.date_unit_sub.prod = angular.copy(summarySVC.searchForm.date_unit_sub.prod);
        }
      }

      if (['sales', 'all'].includes(type)) {
        $scope.searchForm.date_unit = 'day';

        // 멀티셀렉트 초기화.
        $('option', $('#shop_multi_select')).each(function(element) {
          $(this).removeAttr('selected').prop('selected', false);
        });
        $('#shop_multi_select').multiselect('refresh');
      }
    };

    formReset('all');

    // 요약 탭 상단에 표기될 바인딩용 변수 선언
    const fetchingData = data => {
      $scope.sales = data.sales ? Math.round(data.sales).toLocaleString() : 0;
      $scope.pay_amt = data.pay_amt ? Math.round(data.pay_amt).toLocaleString() : 0;
      $scope.ship_cnt = data.ship_cnt?.toLocaleString() || 0;
      $scope.ord_cnt = data.ord_cnt?.toLocaleString() || 0;
      $scope.sales_cnt = data.sale_cnt?.toLocaleString() || 0;
      $scope.cancel_sales = data.cancel_sales ? Math.round(data.cancel_sales).toLocaleString() : 0;
      $scope.cancel_cnt = data.cancel_sale_cnt?.toLocaleString() || 0;
      $scope.return_sales = data.return_sales ? Math.round(data.return_sales).toLocaleString() : 0;
      $scope.return_cnt = data.return_sale_cnt?.toLocaleString() || 0;

      $timeout(() => {
        $('.spinner_summary').addClass('hidden');
        $('.chart_summary').removeClass('hidden');
      });
    };

    const chartColor = {
      shop: ['#FF7171', '#9FD8E0', '#D7E9F7', '#FFE194', '#F0E4D7', '#94B3FD', '#89B5AE', '#F7D08A', '#FF87CA', '#533636'],
      prod: ['#BEAEE2', '#B8E0D8', '#FFE5E2', '#FFF5C0', '#BEDCFA', '#E6DDC4', '#F9975D', '#C85C5C', '#A8ECE7', '#99A799']
    };

    let formatter;
    function updateFormatter(byMonth) {
      formatter = d3.time.format(byMonth ? '%Y-%m' : '%Y-%m-%d');
    }

    updateFormatter();

    /**
    * 초기 그래프 생성 함수
    * Lucas 200316
    * @param {Object} data 그래프에 사용될 데이터 오브젝트
    * @returns {Object} 추후 그래프의 데이터 갱신에 사용될 메소드가 담긴 c3 오브젝트 return;
    */
    const initGraph = data => {
      return [
        // 매출현황
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          grid: {
            x: {
              show: true
            }
          },
          bindto: '#sales_all',
          data: {
            x: 'x',
            xFormat: '%Y-%m-%d',
            columns: [
              ['x'].concat(data.sales.map(({ curdate }) => curdate)),
              ['sales_total'].concat(data.sales.map(({ sales }) => sales)),
              ['return_total'].concat(data.sales.map(({ return_sales }) => return_sales)),
              ['cancel_total'].concat(data.sales.map(({ cancel_sales }) => cancel_sales))
            ],
            names: {
              sales_total: '매출',
              return_total: '반품',
              cancel_total: '취소'
            },
            colors: {
              sales_total: '#FE5051',
              return_total: '#FFE699',
              cancel_total: '#7F7F7F',
            },
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: x => { return formatter(x); }
              },
            },
            y: {
              tick: {
                format: d3.format(',')
              }
            }
          },
          transition: summarySVC.chartData.transition
        }),
        // 쇼핑몰별 매출 순위 TOP10
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          bindto: '#top10_shop',
          data: {
            columns: data.shop.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []),
            type: 'donut',
            names: Object.fromEntries(data.shop.map((e, i) => [i + 1, `${e.shop_name}${e.shop_name !== '직접입력' ? `(${e.shop_id})` : ''}`])),
            colors: Object.fromEntries(data.shop.map((e, i) => [i + 1, chartColor.shop[i]])),
          },
          donut: {
            label: {
              show: true,
            }
          },
          legend: {
            show: true,
            position: 'right'
          },
          transition: summarySVC.chartData.transition
        }),
        // 인기상품 TOP10
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          bindto: '#top10_prod',
          data: {
            columns: data.prod.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []),
            type: 'donut',
            names: Object.fromEntries(data.prod.map((e, i) => [i + 1, commonSVC.getByteLength(e.shop_sale_name) > 40 ? commonSVC.cutByByte(e.shop_sale_name, 40) + '...' : e.shop_sale_name])),
            colors: Object.fromEntries(data.prod.map((e, i) => [i + 1, chartColor.prod[i]])),
          },
          donut: {
            label: {
              show: true,
            }
          },
          legend: {
            show: true,
            position: 'right'
          },
          transition: summarySVC.chartData.transition
        })];
    };

    /**
    * 그래프 데이터 갱신 함수
    * Lucas 200316
    * @param {Object} data 그래프 갱신에 사용될 데이터 오브젝트
    * @param {Array} typeList 업데이트할 그래프 타입 리스트
    */
    const reloadGraph = (data, typeList) => {
      const typeMap = ['sales', 'shop', 'prod'];

      typeList.forEach(type => {
        $(`.spinner_${type}`).addClass('hidden');
        $(`.chart_${type}`).removeClass('hidden');
      });

      /** i
       * 0 - 매출현황
       * 1 - 쇼핑몰별 매출순위 TOP10
       * 2 - 인기상품 TOP10
       */
      for (const i in graphData.c3_data) {
        if (!typeList.includes(typeMap[i])) {
          continue;
        }

        const newData = {
          columns: [],
          unload: true
        };

        if (i == 0) {
          newData.columns = [
            ['x'].concat(data.sales.map(({ curdate }) => curdate)),
            ['sales_total'].concat(data.sales.map(({ sales }) => sales)),
            ['return_total'].concat(data.sales.map(({ return_sales }) => return_sales)),
            ['cancel_total'].concat(data.sales.map(({ cancel_sales }) => cancel_sales))
          ];
        } else if (i == 1) {
          newData.columns = data.shop.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []);
          newData.names = Object.fromEntries(data.shop.map((e, i) => [i + 1, `${e.shop_name}${e.shop_name !== '직접입력' ? `(${e.shop_id})` : ''}`]));
          newData.colors = Object.fromEntries(data.shop.map((e, i) => [i + 1, chartColor.shop[i]]));
        } else {
          newData.columns = data.prod.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []);
          newData.names = Object.fromEntries(data.prod.map((e, i) => [i + 1, commonSVC.getByteLength(e.shop_sale_name) > 40 ? commonSVC.cutByByte(e.shop_sale_name, 40) + '...' : e.shop_sale_name]));
          newData.colors = Object.fromEntries(data.prod.map((e, i) => [i + 1, chartColor.prod[i]]));
        }

        graphData.c3_data[i].load(newData);
        updateFormatter($scope.searchForm.date_unit === 'month');
      }
    };

    /**
     * 조회
     * @param {String} type 차트 타입
     * @param {String} date_range 최근 날짜범위 (month: 최근 1개월, week: 1주, day: 1일)
     */
    $scope.searchDo = _.throttle(async (type, date_range) => {
      const data = angular.copy($scope.searchForm);
      let searchType = type;

      data.isSuper = false;

      if (type === 'all') {
        data.typeList = ['summary', 'sales', 'shop', 'prod'];
      } else {
        if (type === 'top') {
          data.typeList = ['shop', 'prod'];
        } else {
          data.typeList = [type];
        }

        if (['shop', 'prod'].includes(type)) {
          searchType = 'top';
        }

        // 타입별 조회일자 / 조회날짜타입 별도 적용
        data.sdate = $scope.searchForm.date_sub[searchType].sdate;
        data.edate = $scope.searchForm.date_sub[searchType].edate;
        data.date_type = $scope.searchForm.date_type_sub[searchType];

        if (date_range) {
          data.sdate = moment().subtract(1, date_range).format('YYYY-MM-DD');
          data.edate = moment().subtract(1, 'day').format('YYYY-MM-DD');
        }
      }

      $timeout(() => {
        // 스피너 노출 및 차트 숨김
        for (const type of data.typeList) {
          $(`.spinner_${type}`).removeClass('hidden');
          $(`.chart_${type}`).addClass('hidden');
          $scope.chartNoData[type] = false;
        }
      });

      try {
        const { data: { results } } = await summaryModel.getData(data);

        // 요약 데이터 갱신
        if (['all', 'summary'].includes(type)) {
          fetchingData(results.summary[0]);
        }
        // 차트 갱신
        if (['all', 'top', 'shop', 'prod', 'sales'].includes(type)) {
          reloadGraph(results, data.typeList);
        }

        // 데이터 없는경우 처리
        if (['all', 'top', 'shop', 'prod'].includes(type)) {
          $timeout(() => {
            for (const type of data.typeList) {
              if (type !== 'summary' && !results[type].length) {
                $scope.chartNoData[type] = true;
                $(`.chart_${type}`).addClass('hidden');
              }
            }
          });
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '통계 데이터 조회에 실패했습니다.');

        $('.spinner_default').addClass('hidden');
        $('.cnt').removeClass('hidden');
        $('.chart_default').removeClass('hidden');
      }
    }, 1000);

    /**
     * 검색 초기화
     *
     * throttle 붙여 1초에 한번씩만 조회도록 처리 2018-10-12 rony
     */
    $scope.resetDo = _.throttle(type => {
      formReset(type);
      $scope.searchDo(type);
    }, 1000);

    /**
     * 검색날짜 단위 변경
     * (매출현황: 월별/주별/일별 데이터 출력, 나머지차트: 최근 1개월, 1주, 1일 데이터 출력)
     * @param {String} date_unit 검색날짜 단위 (day, week, month)
     * @param {String} type 차트 타입
     */
    $scope.changeDateUnit = _.throttle((date_unit, type) => {
      if (type === 'sales') {
        $scope.searchForm.date_unit = date_unit;
      } else {
        $scope.searchForm.date_unit_sub[type] = date_unit;
      }

      $scope.searchDo(type, type === 'sales' ? '' : date_unit);
    }, 1000);

    /**
     * 엑셀 다운로드
     */
    $scope.excelDown = type => {
      if (!userInfo.user.auth_type.includes('관리자') && userInfo.permission.summary && commonSVC.checkPermission('summary.roles.excelSummary', userInfo.permission) === false) {
        commonSVC.PermissionDenyAlert('');

        return false;
      }

      const resolve = {};

      let title = '',
          typeList = [];

      switch (type) {
        case 'summary':
          title = '요약';
          break;
        case 'sales':
          title = '매출현황';
          break;
        case 'shop':
          title = '쇼핑몰별 매출 순위';
          break;
        case 'prod':
          title = '쇼핑몰 상품별 판매 순위';
          break;
        case 'stock':
          title = 'SKU 상품별 판매 순위';
          break;
      }

      typeList = [type];

      $scope.searchForm.typeList = typeList;
      $scope.searchForm.excelType = type;

      const data = { ...$scope.searchForm };

      // 타입별 조회일자 / 조회날짜타입 별도 적용
      if (['shop', 'prod', 'stock'].includes(type)) {
        data.sdate = $scope.searchForm.date_sub.top.sdate;
        data.edate = $scope.searchForm.date_sub.top.edate;
        data.date_type = $scope.searchForm.date_type_sub.top;
        data.date_unit = $scope.searchForm.date_unit_sub[type === 'shop' ? 'shop' : 'prod'];
      } else {
        data.sdate = $scope.searchForm.date_sub[type].sdate;
        data.edate = $scope.searchForm.date_sub[type].edate;
        data.date_type = $scope.searchForm.date_type_sub[type];
      }
      data.date_type_name = $scope.date_type.find(({ value }) => value === data.date_type).name;

      resolve.data = {
        type: 'all',
        excelFieldList: [],
        title: `${title}_${data.date_type_name}`,
        sheetName: title,
        url: '/app/summaries/excelDown',
        searchForm: data,
        page: 'summary',
        progress: true,
        isAll: true
      };

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 초기화
     */
    const init = () => {
      $timeout(async () => {
        const data = angular.copy($scope.searchForm);

        data.isSuper = false;
        data.typeList = ['summary', 'sales', 'shop', 'prod'];

        try {
          const { data: { results } } = await summaryModel.getData(data);

          fetchingData(results.summary[0]);
          graphData.c3_data = initGraph(results);

          $('.spinner_default').addClass('hidden');

          // 데이터 없는경우 처리
          $timeout(() => {
            for (const key of data.typeList) {
              if (key !== 'summary' && !results[key].length) {
                $scope.chartNoData[key] = true;
                $(`.chart_${key}`).addClass('hidden');
              }
            }
          });

          isInitDone = true;
        } catch (err) {
          commonSVC.showToaster('error', '실패', '통계 데이터 조회에 실패했습니다.');

          $('.spinner_default').addClass('hidden');
          $('.cnt').removeClass('hidden');
          $('.chart_default').removeClass('hidden');
        }
      });
    };

    init();

    /**
     * 차트 redraw
     */
    function redraw() {
      for (const chart of graphData.c3_data) {
        // Force to redraw
        chart.flush();
      }
    }

    $scope.$on('$stateChangeStart', function(e, state, params, fromState) {
      // 차트 크기에 영향 주는 페이지
      const affectStates = ['main.setForm_template_add', 'main.setForm_template_add.channelSub', 'main.settings_shop_account', 'main.settings_shop_account.channelSub', 'main.settings_category', 'main.settings_category.categoryDetailPanel'];

      // 특정 페이지에서 통계 페이지로 반복해서 이동시 차트 크기가 변경되는 이슈가 있어 강제 redraw 처리
      if (isInitDone && state.name === 'main.summary' && affectStates.includes(fromState.name)) {
        $timeout(() => {
          redraw();
        });
      }
    });

    $scope.openOrderReport = async () => {
      const { data: { result: reportUser } } = await systemModel.checkReportUser({ sol_no: userInfo.user.sol_no });
      commonSVC.openModal('xmd', { data: { isSummary: true }, addClass: 'order-report', reportUser }, 'orderReportCtrl', 'views/home/modals/orderReport.html', false, false, false);
    };

  });
