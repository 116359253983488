'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A098-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, shopAccountModel, onlineProductModel, commonSVC, commonModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    $scope.pa5_job_data = {
      get_goods_template: {
        load: false,
        data: []
      },
      get_size_chart: {
        load: false,
        data: []
      },
      get_compliance_extra_template: {
        load: false,
        data: []
      },
      get_compliance_rules: {
        load: false,
        data: []
      },
      get_brand_trademark: {
        load: false,
        data: []
      }
    };

    const cate_code = _.last(data.data?.cate_cd?.split('_') ?? data.data?.shop_cate_cd?.split('_') ?? data?.shop_data?.match_cate_code?.split('_') ?? data.data?.sol_cate_shop_add_info?.category_code?.split('_'));

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $('.box-loading').removeClass('hidden'); //로딩중
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
          $('.box-loading').addClass('hidden'); //로딩중
        } else {
          $('.box-loading').addClass('hidden'); //로딩중
          const error_message = re.data.exception_message ?? re.data.results[0].site_error;
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${error_message}`);
        }
      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const init = async () => {
      // 속성 ======================================
      {
        $scope.row.set_info.key_goods_template = $scope.row.set_info.key_goods_template || [];

        // 부모 속성 조건 확인 함수 (showCondition 및 templatePropertyValueParentList 고려)
        $scope.checkParentConditions = (goods_template, allTemplates) => {
          let isVisible = false;

          // showCondition 조건 검사
          if (goods_template.showCondition && goods_template.showCondition.length > 0) {
            isVisible = goods_template.showCondition.some(condition => {
              const parent = allTemplates.find(g => g.refPid === condition.parentRefPid);

              return parent && condition.parentVids.includes(+parent.selectedValue);
            });
          }

          // templatePropertyValueParentList 조건 검사 (부모 속성 값이 특정 조건을 만족할 경우)
          if (!isVisible && goods_template.templatePropertyValueParentList && goods_template.templatePropertyValueParentList.length > 0) {
            isVisible = goods_template.templatePropertyValueParentList.some(condition => {
              // 부모 템플릿 ID를 기반으로 부모 찾기
              const parent = allTemplates.find(g => g.templatePid === goods_template.parentTemplatePid);

              return parent && condition.parentVids.includes(+parent.selectedValue);
            });
          }

          return isVisible;
        };

        // 부모 선택 값에 따라 하위 속성 표시 여부 업데이트
        $scope.updateTemplateVisibility = () => {
          $scope.goods_templates.forEach(goods_template => {
            if (goods_template.showType === 0) {
              goods_template.isVisible = true;
              return;
            }

            goods_template.isVisible = $scope.checkParentConditions(goods_template, $scope.goods_templates);
          });

          $scope.$applyAsync();
        };

        // 상품 속성 조회 및 화면 데이터 생성
        try {
          await run_pa5_jobs('get_goods_template', '상품속성', { code: cate_code }, (data) => {
            $scope.goods_templates = [];
            $scope.get_brands = data.goodsProperties?.find(template => template.name.toLowerCase() === 'brand')?.values ?? [];

            const goodsTemplateMap = {};
            data.goodsProperties.filter(template => template.name.toLowerCase() !== 'brand').forEach(template => {
              const existingData = $scope.row.set_info.key_goods_template.find(t => t.refPid === template.refPid) || {};

              let matchedValue = null;
              if (existingData.vid) {
                const foundOption = template.values?.find(v => v.vid == existingData.vid);
                matchedValue = foundOption ? foundOption.value : null;
              }

              const isVisible = template.showType === 0 || $scope.checkParentConditions(template, $scope.row.set_info.key_goods_template);

              const valueUnitListTF = template.valueUnitList && template.valueUnitList.length > 0;
              const firstValueUnitList = valueUnitListTF ? template.valueUnitList.find(value => value.valueUnitId) : '';
              const valueUnitId = valueUnitListTF ? firstValueUnitList.valueUnitId : null;
              const numberInputValue = valueUnitListTF && firstValueUnitList.valueUnitId === 57 ? '100' : firstValueUnitList?.valueUnit || null;
              goodsTemplateMap[template.refPid] = {
                ...template,
                isVisible,
                selectedValue: existingData.vid?.toString() || existingData.value || null,
                values: template.values || [],
                value: matchedValue || existingData.value,
                valueUnitId,
                numberInputValue,
              };

              if (!existingData.refPid) {
                $scope.row.set_info.key_goods_template.push({
                  templatePid: template.templatePid,
                  refPid: template.refPid,
                  pid: template.pid,
                  vid: null,
                  value: null,
                  valueUnitId,
                  numberInputValue,
                });
              }
            });

            // 조회되는 템플릿에 없는 항목들은 제거
            $scope.row.set_info.key_goods_template = $scope.row.set_info.key_goods_template
              .filter(key_template => Object.keys(goodsTemplateMap).includes(key_template.refPid.toString()));

            $scope.goods_templates = Object.values(goodsTemplateMap);

            $scope.updateTemplateVisibility();
            $scope.$applyAsync();
          });
        } catch (e) {
          commonSVC.showMessage('실패', '상품속성 정보 로드를 실패했습니다.');
        }

        // 선택 값이 변경될 때 `key_goods_template` 업데이트
        $scope.updateSelectedValue = (goods_template) => {
          if (goods_template.values !== null) {
            const selectedOption = goods_template.values.find(v => v.vid == goods_template.selectedValue);
            goods_template.vid = selectedOption ? selectedOption.vid : null;
            goods_template.value = selectedOption ? selectedOption.value : null;
          } else {
            goods_template.vid = null;
            goods_template.value = goods_template.selectedValue;
          }

          const savedData = $scope.row.set_info.key_goods_template.find(t => t.refPid === goods_template.refPid);
          if (savedData) {
            savedData.vid = goods_template.vid;
            savedData.value = goods_template.value;
          }

          // 부모 값 변경에 따른 하위 속성 노출 여부 갱신
          $scope.updateTemplateVisibility();
          $scope.$applyAsync();
        };

      }

      // 트레이드 마크 ========================================================================
      {
        $scope.getBrandTrademarkInfo = async (brandId, type) => {
          // 트레이드 마크 정보 받아오기
          try {
            await run_pa5_jobs('get_brand_trademark', 'brand trademark', { brand_id: brandId }, (data) => {
              if (!data) {
                return;
              }

              $scope.trademarkList = data.trademarkList;

            });
          } catch (e) {
            commonSVC.showMessage('실패', 'brand trademark 정보 로드를 실패했습니다.');
          }

          // 브랜드 선택시(click) 트레이트마크 정보 초기화 / 저장된 화면 로드시(load) 기존 정보 유지
          if (type === 'click') {
            $scope.row.set_info.key_trademarkId = null;
          }
        };

        if ($scope.row.set_info.key_brandId) {
          $scope.getBrandTrademarkInfo($scope.row.set_info.key_brandId, 'load');
        }
      }

      // Bullet point ==============================================================
      {
        $scope.row.set_info.key_bulletPoints = $scope.row.set_info.key_bulletPoints ? $scope.row.set_info.key_bulletPoints : [''];

        $scope.addBulletPoint = () => {
          $scope.$applyAsync(() => {
            $scope.row.set_info.key_bulletPoints.push('');
          });
        };

        $scope.removeBulletPoint = (index) => {
          $scope.$applyAsync(() => {
            $scope.row.set_info.key_bulletPoints.splice(index, 1);
          });
        };
      }

      // 사이즈차트 세팅 ==============================================================================
      {
        // 상품 사이즈차트 조회하여 세팅
        $scope.measurementGroups = $scope.row.set_info.measurementGroups ?? [];
        $scope.row.set_info.key_size_chart = $scope.row.set_info.key_size_chart ?? {};

        // 사이즈 목록 초기화
        if ($scope.data.opt_sort1?.toLowerCase() === 'size') {
          $scope.sizeList = $scope.data.opt_sort1_desc.split(',');
        } else if ($scope.data.opt_sort2?.toLowerCase() === 'size') {
          $scope.sizeList = $scope.data.opt_sort2_desc.split(',');
        } else if ($scope.data.opt_sort3?.toLowerCase() === 'size') {
          $scope.sizeList = $scope.data.opt_sort3_desc.split(',');
        } else {
          $scope.sizeList = ['size']; // 기본 사이즈 리스트
        }

        $scope.updateMeasurements = function (groupIndex, type) {
          const group = $scope.measurementGroups[groupIndex];

          // 기존 저장된 값 복사
          const prevSavedValues = angular.copy(group[`save_${type}`]) || [];

          // 체크된 항목만 필터링하여 사용
          group[`used_${type}`] = group[type].filter(el => el.checked);

          // 기존 값 유지하면서 추가된 항목만 반영
          group[`save_${type}`] = $scope.sizeList.map((size, sizeIndex) => {
            return group[`used_${type}`].map(measurement => {
              // 기존 값 찾기 (size_data와 elementId가 일치하는 값 유지)
              const existingValue = prevSavedValues
                .flat() // 이전 데이터 구조가 2D 배열이므로 1D 배열로 변환
                .find(item => item.size_data === size && item.elementId === measurement.elementId)?.send_value ?? '';

              return {
                ...measurement,
                size_data: size,
                send_value: existingValue, // 기존 값 유지 (없으면 빈 값)
              };
            });
          });
        };

        $scope.updateMeasurementTables = function () {
          $scope.measurementGroups.forEach(group => {
            ['product_measurements', 'body_measurements'].forEach(type => {
              const usedMeasurements = group[type].filter(el => el.checked);
              group[`used_${type}`] = usedMeasurements;

              // key_size_chart에서 저장된 데이터를 가져옴
              const savedChart = $scope.row.set_info.key_size_chart.goodsSizeChartList?.find(chart => chart.classId === group.classId);
              const savedRecords = type === 'product_measurements' ? savedChart?.records : savedChart?.bodyRecords || [];

              group[`save_${type}`] = $scope.sizeList.map((size, sizeIndex) => {
                const savedValues = savedRecords?.[sizeIndex]?.values || [];

                return usedMeasurements.map(measurement => {
                  // key_size_chart의 records에서 elementId가 일치하는 값을 찾음
                  const matchedValue = savedValues.find(v => v.id === measurement.elementId)?.value ?? '';

                  return {
                    ...measurement,
                    size_data: size,
                    send_value: matchedValue, // key_size_chart에서 가져온 값 적용
                  };
                });
              });
            });
          });
        };

        const savedCharts = $scope.row.set_info.key_size_chart?.goodsSizeChartList || [];
        const createMeasurementGroup = (className, classId, localCodeId, localCodeName, sizeSpecElementList) => {
          const savedChart = savedCharts.find(chart => chart.classId === classId);

          const productSavedValues = savedChart?.records?.[0]?.values || [];
          const productMeasurements = sizeSpecElementList?.filter(el => el.type === '1').map(el => {
            const matchedValue = productSavedValues.find(v => v.id === el.elementId)?.value ?? '';

            return {
              ...el,
              checked: el.necessary || matchedValue !== '', // 저장된 값이 있으면 자동 체크
            };
          }) || [];

          // US 컬럼을 맨 앞에 추가하고 send_value 반영
          if (localCodeId) {
            productMeasurements.unshift({
              elementId: localCodeId,
              value: localCodeName,
              necessary: true,
              checked: true,
              isLocalCode: true,
            });
          }

          const bodySavedValues = savedChart?.bodyRecords?.[0]?.values || [];
          const bodyMeasurements = sizeSpecElementList?.filter(el => el.type === '2').map(el => {
            const matchedValue = bodySavedValues.find(v => v.id === el.elementId)?.value ?? '';

            return {
              ...el,
              checked: el.necessary || matchedValue !== '',
            };
          }) || [];

          return {
            className,
            classId,
            localCodeId,
            localCodeName,
            product_measurements: productMeasurements,
            body_measurements: bodyMeasurements,
          };
        };

        // 사이즈차트 정보 받아오기
        try {
          await run_pa5_jobs('get_size_chart', '상품 사이즈 차트', { code: cate_code }, (data) => {
            if (!data) {
              return;
            }

            const rule = data.sizeSpecElementRule;

            if (rule.setElementList && rule.setElementList.length > 0) {
              $scope.measurementGroups = rule.setElementList.map(el =>
                createMeasurementGroup(el.className, el.classId, el.localCodeId, el.localCodeName, el.sizeSpecElementList)
              );
            } else {
              $scope.measurementGroups = [
                createMeasurementGroup(rule.className, rule.classId, rule.localCodeId, rule.localCodeName, rule.sizeSpecElementList)
              ];
            }

            $timeout(() => {
              $scope.updateMeasurementTables();
            });
          });
        } catch (e) {
          commonSVC.showMessage('실패', '상품 사이즈 차트 정보 로드를 실패했습니다.');
        }

        // 상품 전송에 사용되는 형태로 변형(수집시에도 같은형태로 주기에 미리 만들어놓고 엔진에 전달)
        $scope.generateKeySizeChart = function () {
          $scope.row.set_info.key_size_chart = {
            goodsSizeChartList: $scope.measurementGroups.map(group => ({
              classId: group.classId,
              ...(group.save_product_measurements.length &&
                group.save_product_measurements[0].length &&
              {
                meta: {
                  groups: [
                    {
                      id: 1,
                      name: 'Size'
                    },
                    {
                      id: 6,
                      name: 'US Size'
                    }
                  ],
                  elements: group.used_product_measurements
                    .filter(el => ![1, 6].includes(el.elementId))
                    .map(el => ({
                      id: el.elementId,
                      name: el.value
                    })),
                },
                records: group.save_product_measurements
                  .map((measurement, measurementIndex) => ({
                    values: [
                      {
                        id: 1,
                        value: $scope.sizeList[measurementIndex],
                      },
                      ...measurement.map(data => ({
                        id: data.elementId,
                        value: data.send_value ?? '',
                      }))
                    ]
                  }))
              }),
              ...(group.save_body_measurements.length &&
                group.save_body_measurements[0].length &&
              {
                bodyMeta: {
                  groups: [
                    {
                      id: 1,
                      name: 'Size'
                    },
                    {
                      id: 21,
                      name: 'Korea'
                    }
                  ],
                  elements: group.used_body_measurements
                    .filter(el => ![1, 6].includes(el.elementId))
                    .map(el => ({
                      id: el.elementId,
                      name: el.value
                    })),
                },
                bodyRecords: group.save_body_measurements
                  .map((measurement, measurementIndex) => ({
                    values: [
                      {
                        id: 1,
                        value: $scope.sizeList[measurementIndex],
                      },
                      ...measurement.map(data => ({
                        id: data.elementId,
                        value: data.send_value ?? '',
                      }))
                    ]
                  }))
              })
            }))
          };
        };
      }

      // EXTRA ==============================================================================
      {
        // 저장된 데이터를 기반으로 선택 상태 복원 (상품수집시에도 적용 가능)
        $scope.initSelections = function () {
          const savedData = $scope.row.set_info.key_extraTemplateDetailList;
          if (!savedData || !savedData.extraTemplateDetailList || savedData.extraTemplateDetailList.length === 0) {
            return;
          }

          // templateId별로 데이터 그룹화 (같은 templateId가 여러 개 있을 수 있음)
          const groupedData = savedData.extraTemplateDetailList.reduce((acc, detail) => {
            const { templateId, properties, inputText, property } = detail;

            if (!acc[templateId]) {
              acc[templateId] = { properties: {}, inputText: {} };
            }

            // properties 데이터 정리
            const propData = properties || property;
            Object.keys(propData).forEach(refPid => {
              if (!acc[templateId].properties[refPid]) {
                acc[templateId].properties[refPid] = [];
              }
              acc[templateId].properties[refPid] = acc[templateId].properties[refPid].concat(propData[refPid]);
            });

            // inputText 데이터 정리
            if (inputText) {
              Object.keys(inputText).forEach(refPid => {
                acc[templateId].inputText[refPid] = inputText[refPid]?.lang?.ko || '';
              });
            }

            return acc;
          }, {});

          // templateId별로 처리
          Object.keys(groupedData).forEach(templateId => {
            const template = $scope.extraTemplateList.find(t => t.templateId === Number(templateId));
            if (!template) return;

            Object.keys(groupedData[templateId].properties).forEach(refPid => {
              const parent = template.templatePropertyDTOList.find(p => p.refPid === Number(refPid));
              if (!parent) return;

              const selectedVids = groupedData[templateId].properties[refPid];
              const inputText = groupedData[templateId].inputText[refPid] || null;

              // 부모 값 복원 (select & checkbox)
              if (parent.selectNum === 1) {
                // Select 타입 복원
                $scope.selectedParentValue[templateId] = $scope.selectedParentValue[templateId] || {};

                if (inputText && typeof inputText === 'string') {
                  // 직접 입력(`manual`)이 존재하는 경우
                  $scope.selectedParentValue[templateId][refPid] = 'manual';
                  $scope.manualInputValues[templateId] = $scope.manualInputValues[templateId] || {};
                  $scope.manualInputValues[templateId][refPid] = inputText;
                } else {
                  // 일반적인 `select` 값 복원
                  $scope.selectedParentValue[templateId][refPid] = selectedVids.length > 0 ? String(selectedVids[0]) : null;
                }
              } else {
                // Checkbox 타입 복원 (숫자 타입으로 변환)
                $scope.selectedParents[templateId] = $scope.selectedParents[templateId] || {};
                selectedVids.forEach(vid => {
                  $scope.selectedParents[templateId][Number(vid)] = true;
                });
              }

              // 자식 속성 복원 로직 (checkbox 포함)
              template.templatePropertyDTOList.forEach(child => {
                if (child.parentPid === Number(refPid)) {
                  $scope.selectedChildren[templateId] = $scope.selectedChildren[templateId] || {};

                  // 부모 선택 여부에 따라 자식 속성 활성화
                  selectedVids.forEach(vid => {
                    if ($scope.selectedParents[templateId][Number(vid)] || child.parentVidList.includes(Number(vid))) {
                      groupedData[templateId].properties[child.refPid]?.forEach(childVid => {
                        $scope.selectedChildren[templateId][Number(childVid)] = true;
                      });
                    }
                  });

                  // Select 타입 자식 복원
                  if (child.selectNum === 1) {
                    $scope.selectedChildValue[templateId] = $scope.selectedChildValue[templateId] || {};

                    if (inputText && typeof inputText === 'string') {
                      // 직접 입력(`manual`)이 존재하는 경우
                      $scope.selectedChildValue[templateId][child.refPid] = 'manual';
                      $scope.manualInputValues[templateId][child.refPid] = inputText;
                    } else {
                      $scope.selectedChildValue[templateId][child.refPid] = selectedVids.length > 0 ? String(selectedVids[0]) : null;
                    }
                  }
                }
              });
            });
          });

          // UI 업데이트 반영
          $scope.$applyAsync();
        };

        // 상품 extra 조회하여 세팅
        try {
          await run_pa5_jobs('get_compliance_extra_template', '상품 compliance extra template', { code: cate_code }, (data) => {
            $scope.extraTemplateList = data.extraTemplateList;
            $scope.selectedParents = {};  // 부모 선택 상태 (템플릿 별로 관리)
            $scope.selectedChildren = {}; // 자식 선택 상태 (템플릿 별로 관리)
            $scope.selectedParentValue = {}; // 부모 선택된 값 저장
            $scope.selectedChildValue = {}; // 자식 선택된 값 저장
            $scope.manualInputValues = {};

            // 저장되어있는 값 있으면 복원하기
            if ($scope.row.set_info.key_extraTemplateDetailList) {
              $scope.initSelections();
            }

            return data;
          });
        } catch (e) {
          commonSVC.showMessage('실패', '상품 extra 정보 로드를 실패했습니다.');
        }

        // 부모 체크 여부 확인 후 자식 속성 필터링
        $scope.getFilteredChildProperties = function (templateId) {
          const template = $scope.extraTemplateList.find(t => t.templateId === templateId);
          if (!template) {
            return [];
          }

          return template.templatePropertyDTOList.filter(child => {
            if (child.parentPid === 0) {
              return false; // 부모 속성 제외
            }
            const parent = template.templatePropertyDTOList.find(p => p.refPid === child.parentPid);
            if (!parent) {
              return false;
            }

            return parent.propertyValueList.some(val =>
              $scope.selectedParents[templateId]?.[val.vid] && child.parentVidList.includes(val.vid)
            );
          });
        };

        // 선택된 부모 개수 계산 (selectNum 적용)
        $scope.countSelectedParents = function (templateId, parent) {
          if (!$scope.selectedParents[templateId]) {
            return 0;
          }

          return parent.propertyValueList.filter(value => $scope.selectedParents[templateId][value.vid]).length;
        };

        // 선택 개수 제한 확인
        $scope.isMaxSelectionReached = function (templateId, property) {
          if (!property.selectNum) {
            return false; // 최대 선택 개수 제한이 없으면 패스
          }

          let selectedCount = 0;
          if (property.parentPid === 0) {
            // 부모 속성에서 선택 개수 확인
            selectedCount = property.propertyValueList.filter(value => $scope.selectedParents[templateId]?.[value.vid]).length;
          } else {
            // 자식 속성에서 선택 개수 확인
            selectedCount = property.propertyValueList.filter(value => $scope.selectedChildren[templateId]?.[value.vid]).length;
          }

          return selectedCount >= property.selectNum;
        };

        // excludeVidMap 체크: 특정 값이 선택되었을 때 일부 값 비활성화
        $scope.isExcluded = function (parent, vid, templateId) {
          if (!parent.excludeVidMap || !parent.excludeVidMap[vid]) {
            return false;
          }

          const excludedList = parent.excludeVidMap[vid];

          // excludedList에 있는 값이 이미 선택되어 있다면, `vid`를 비활성화 시킴
          const isDisabled = excludedList.some(excludedVid => $scope.selectedParents[templateId]?.[excludedVid]);

          if (isDisabled) {
            // `vid` 체크 해제 (선택 취소)
            $scope.selectedParents[templateId][vid] = false;
          }

          return isDisabled;
        };

        // 부모 체크 상태 변경 시 excludeVidMap 반영
        $scope.toggleParentCheckbox = function (templateId, parent, vid) {
          if (!$scope.selectedParents[templateId]) {
            $scope.selectedParents[templateId] = {};
          }

          const isChecked = !$scope.selectedParents[templateId][vid];

          if (isChecked) {
            // 최대 선택 개수를 초과하면 기존 선택 해제
            if ($scope.isMaxSelectionReached(templateId, parent)) {
              parent.propertyValueList.forEach(value => {
                if ($scope.selectedParents[templateId][value.vid]) {
                  $scope.selectedParents[templateId][value.vid] = false;
                }
              });
            }

            // 선택한 값이 exclude 대상이면 기존 선택 해제
            if (parent.excludeVidMap && parent.excludeVidMap[vid]) {
              parent.excludeVidMap[vid].forEach(excludedVid => {
                if ($scope.selectedParents[templateId][excludedVid]) {
                  $scope.selectedParents[templateId][excludedVid] = false;
                }
              });
            }
          } else {
            // 반대로, `excludedList`에 속한 값이 선택되면 현재 선택한 값 해제
            Object.keys(parent.excludeVidMap || {}).forEach(excludeKey => {
              if (parent.excludeVidMap[excludeKey].includes(vid) && $scope.selectedParents[templateId][excludeKey]) {
                $scope.selectedParents[templateId][excludeKey] = false;
              }
            });
          }

          // 최종적으로 선택 상태 업데이트
          $scope.selectedParents[templateId][vid] = isChecked;

          // 자식 속성 업데이트 (다중 계층도 처리)
          $scope.updateChildProperties(templateId);
        };

        // 자식 체크 상태 변경 시 excludeVidMap 반영
        $scope.toggleChildCheckbox = function (templateId, child, vid) {
          if (!$scope.selectedChildren[templateId]) {
            $scope.selectedChildren[templateId] = {};
          }

          const isChecked = !$scope.selectedChildren[templateId][vid];

          if (isChecked) {
            // 최대 선택 개수를 초과하면 기존 선택 해제
            if ($scope.isMaxSelectionReached(templateId, child)) {
              child.propertyValueList.forEach(value => {
                if ($scope.selectedChildren[templateId][value.vid]) {
                  $scope.selectedChildren[templateId][value.vid] = false;
                }
              });
            }

            // 선택한 값이 exclude 대상이면 기존 선택 해제
            if (child.excludeVidMap && child.excludeVidMap[vid]) {
              child.excludeVidMap[vid].forEach(excludedVid => {
                if ($scope.selectedChildren[templateId][excludedVid]) {
                  $scope.selectedChildren[templateId][excludedVid] = false;
                }
              });
            }
          } else {
            // 반대로, `excludedList`에 속한 값이 선택되면 현재 선택한 값 해제
            Object.keys(child.excludeVidMap || {}).forEach(excludeKey => {
              if (child.excludeVidMap[excludeKey].includes(vid) && $scope.selectedChildren[templateId][excludeKey]) {
                $scope.selectedChildren[templateId][excludeKey] = false;
              }
            });
          }

          // 최종적으로 선택 상태 업데이트
          $scope.selectedChildren[templateId][vid] = isChecked;
          $scope.updateChildProperties(templateId);
        };

        // select에 대한 처리를 위한 함수들
        $scope.addParentSelection = function (templateId, parent) {
          if (!$scope.selectedParentValue[templateId]) {
            $scope.selectedParentValue[templateId] = {};
          }

          const selectedVid = $scope.selectedParentValue[templateId][parent.refPid];
          $scope.selectedParents[templateId] = $scope.selectedParents[templateId] || {};

          // 부모 선택 상태 업데이트
          if (selectedVid) {
            $scope.selectedParents[templateId][selectedVid] = true;
          }

          // 자식 속성 업데이트 (다중 계층도 처리)
          $scope.updateChildProperties(templateId);
        };

        $scope.addChildSelection = function (templateId, child) {
          if (!$scope.selectedChildValue[templateId]) {
            $scope.selectedChildValue[templateId] = {};
          }

          const selectedVid = $scope.selectedChildValue[templateId][child.refPid];
          $scope.selectedChildren[templateId] = $scope.selectedChildren[templateId] || {};

          // 자식 선택 상태 업데이트
          if (selectedVid) {
            $scope.selectedChildren[templateId][selectedVid] = true;
          }

          // 부모 체크 상태에 맞춰 자식 속성 업데이트
          $scope.updateChildProperties(templateId);
        };

        // 자식 속성 활성화 조건 확인
        $scope.isChildEnabled = function (child, value, templateId) {
          const parent = $scope.extraTemplateList.flatMap(t => t.templatePropertyDTOList)
            .find(p => p.refPid === child.parentPid);
          if (!parent) {
            return false;
          }

          return parent.propertyValueList.some(val =>
            $scope.selectedParents[templateId]?.[val.vid] && child.parentVidList.includes(val.vid)
          );
        };

        // 부모 체크 상태 변경 시 자식 속성 업데이트
        $scope.updateChildProperties = function (templateId) {
          const template = $scope.extraTemplateList.find(t => t.templateId === templateId);
          if (!template) {
            return;
          }

          // 모든 자식 속성에 대해 처리
          template.templatePropertyDTOList.forEach(child => {
            if (child.parentPid !== 0) {
              // 부모가 선택되지 않았다면 자식 선택 해제
              const parent = template.templatePropertyDTOList.find(p => p.refPid === child.parentPid);
              if (!parent || !parent.propertyValueList.some(val => $scope.selectedParents[templateId]?.[val.vid])) {
                child.propertyValueList.forEach(value => {
                  $scope.selectedChildren[templateId] = $scope.selectedChildren[templateId] || {};
                  $scope.selectedChildren[templateId][value.vid] = false;
                });
              }
            }
          });

          $scope.$applyAsync();
        };

        // 전송해야하는 데이터 형태로 가공
        $scope.getExtraTemplateListFinalData = function () {
          const result = {
            extraTemplateDetailList: []
          };

          // 각 templateId에 대해 처리
          $scope.extraTemplateList.forEach(template => {
            const templateId = template.templateId;
            const properties = {};
            const inputText = {};

            template.templatePropertyDTOList.forEach(property => {
              let selectedValues = [];
              let inputValues = {};

              // 부모 값 저장 (selectNum === 1 -> select 사용 시)
              if (property.selectNum === 1) {
                const selectedParentVid = $scope.selectedParentValue[templateId]?.[property.refPid];
                if (selectedParentVid !== 'manual') {
                  selectedValues.push(+selectedParentVid);
                }

                const input_text = $scope.manualInputValues[templateId]?.[property.refPid] || '';
                if (selectedParentVid === 'manual') {
                  inputValues = {
                    name: input_text,
                    lang: {
                      ko: input_text
                    }
                  };
                }
              } else {
                selectedValues = property.propertyValueList
                  .filter(value =>
                    (property.parentPid === 0 && $scope.selectedParents[templateId]?.[value.vid]) ||
                    (property.parentPid !== 0 && $scope.selectedChildren[templateId]?.[value.vid])
                  )
                  .map(value => value.vid);
              }

              if (selectedValues.length > 0) {
                properties[property.refPid] = selectedValues;
              } else {
                properties[property.refPid] = [];
                inputText[property.refPid] = inputValues;
              }
            });

            // 해당 templateId에 맞는 properties를 result에 추가
            if (Object.keys(properties).length === 1) {
              result.extraTemplateDetailList.push({
                templateId,
                properties,
                ...(Object.keys(inputText).length > 0 && { inputText }),
              });
            } else if (Object.keys(properties).length > 1) {
              Object.keys(properties).map(property => result.extraTemplateDetailList.push({
                templateId,
                property: {
                  [`${property}`]: properties[property]
                },
                ...(Object.keys(inputText).length > 0 && properties[property].length === 0 && { inputText }),
              }));
            }
          });

          // 최종 데이터 저장
          $scope.row.set_info.key_extraTemplateDetailList = result;
        };

      }

      // TODO: 미국관 기준으로 코드를 짯는데 미국관과 한국관의 데이터가 많이 다름
      // TODO: 또한, 한국관에서는 조회되는데이터가 없어서 혹시 조회되는 카테고리가 있으면 문의가 들어올수 있도록 유도하기 위해 조회해봄
      // RULES ==================================
      {
        // 기존 데이터와 새로운 데이터를 병합하고 certName을 찾아 채우는 함수
        const mergeCertificateData = (newCertList, existingCertList) => {
          return newCertList.map(newCert => {
            // 기존 데이터에서 해당 certType 찾기
            const existingCert = existingCertList.find(cert => cert.certType === newCert.certType);

            return {
              certType: newCert.certType,
              certName: (existingCert && existingCert.certName)
                ? existingCert.certName
                : newCert.certName, // certName이 없으면 newCert에서 가져옴
              certFiles: existingCert && existingCert.certFiles.length > 0
                ? existingCert.certFiles
                : []  // 기존 데이터 유지, 없으면 빈 배열로 초기화
            };
          });
        };

        // certName이 비어있을 경우 goodsCertList에서 찾아 자동으로 채우는 함수
        const updateCertNames = () => {
          if (!$scope.row.set_info.key_certificateDetailList || !$scope.goodsCertList) {
            return;
          }

          $scope.row.set_info.key_certificateDetailList.forEach(cert => {
            if (!cert.certName || cert.certName.trim() === '') {
              const matchingCert = $scope.goodsCertList.find(item => item.certType === cert.certType);
              if (matchingCert) {
                cert.certName = matchingCert.certName;
              }
            }
          });
        };

        // 상품 rules 조회하여 세팅
        try {
          await run_pa5_jobs('get_compliance_rules', '상품 compliance rules', { code: cate_code }, (data) => {
            // cert 정보
            $scope.goodsCertList = data.goodsCertList;
            // 기존 데이터 유지하면서 새로운 데이터를 반영
            if (!$scope.row.set_info.key_certificateDetailList) {
              $scope.row.set_info.key_certificateDetailList = [];
            }

            $scope.row.set_info.key_certificateDetailList = mergeCertificateData(
              $scope.goodsCertList,
              $scope.row.set_info.key_certificateDetailList
            );

            // certName 업데이트 실행
            updateCertNames();

            return data;
          });
        } catch (e) {
          commonSVC.showMessage('실패', '상품 compliance rules 정보 로드를 실패했습니다.');
        }

        // 파일 업로드
        $scope.uploadFile = function ($files, cert) {
          if (!$files) {
            alert('CERT 파일을 선택하세요.');

            return false;
          }

          commonModel.tempUpload($files)
            .then(function (res) {
              const results = res.data.results;

              _.forEach(results, function (v) {
                if (v) {
                  // 기존 데이터 업데이트
                  cert.certFiles = [{
                    fileName: v.url.split('/').pop(), // URL에서 파일명 추출
                    fileUrl: v.url
                  }];
                }
              });
            })
            .catch(function (err) {
              alert('파일 업로드 실패: ', err);
            });
        };

        // 파일 삭제 함수
        $scope.deleteFile = function (cert) {
          cert.certFiles = [];
        };
      }
    };

    init();

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if (!$scope.row.set_info.key_Length || !$scope.row.set_info.key_Width || !$scope.row.set_info.key_Height) {
        commonSVC.showToaster('error', '실패', '길이/너비/높이 항목 중 입력되지 않은 항목이 존재합니다.');

        return false;
      }
      if (isNaN(+$scope.row.set_info.key_Length) || isNaN(+$scope.row.set_info.key_Width) || isNaN(+$scope.row.set_info.key_Height)) {
        commonSVC.showToaster('error', '실패', '길이/너비/높이 항목 중 숫자로 입력되지 않은 항목이 존재합니다. 숫자로만 입력해주세요.');

        return false;
      }

      // 현재 화면에 노출되고있는 속성 리스트
      const visibleAttributes = $scope.goods_templates.filter(attr => attr.isVisible);

      // 선택되지 않은 필수 속성이 있는지 검사
      const unselectedAttributes = visibleAttributes.filter(attr => attr.required && (!attr.vid && (!attr.value || attr.value.trim() === '')));

      if (unselectedAttributes.length > 0) {
        commonSVC.showToaster('error', '실패', `필수 속성 중 선택되지 않은 항목이 있습니다: ${unselectedAttributes.map(attr => attr.name).join(', ')}`);

        return false;
      }

      // 최신 데이터를 반영한 key_size_chart 생성
      $scope.generateKeySizeChart();

      // 엔진으로 전달해야하는 데이터로 가공
      $scope.getExtraTemplateListFinalData();

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function (event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });

