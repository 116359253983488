/**
 * 상품명 정리매니저 중복규칙 설정
 */
'use strict';

angular.module('gmpApp')
  .controller('ProdNameDupRuleCtrl', function ($scope, data, $uibModalInstance, $timeout, commonSVC, errorSVC, shipmentModel, gettextCatalog) {
    $scope.dupRules = [...(data.dupRules || []), ...(data.oppRules || [])];

    const { uniqList, applyOpt, applyRange, targetState, shopType } = data;
    let changeUseYn = false; // 사용여부 변경했는지

    function init() {
      // 테이블 컬럼 리사이즈
      $timeout(() => {
        $('.prodNameRuleDup tbody th').filter(':nth-child(7), :nth-child(8)').resizable({
          handles: 'e',
          resize: (event, ui) => {
            const thElems = ui.element.parents('tr').children(); // 동일 테이블 내의 모든 th
            const thWidth = ui.size.width, // 조정한 컬럼 사이즈
                  targetThIdx = ui.element.text() === '검색 문자' ? 7 : 8, // 조정한 컬럼 idx
                  modalWidth = ui.element.closest('.modal-body').width(); // 모달의 너비
            let tableWidth = 0; // 테이블의 총너비

            for (let i = 0; i < thElems.length; i++) {
              tableWidth += thElems.eq(i).width();
            }

            // 다른 테이블도 동일하게 width 설정
            $('.prodNameRuleDup tbody th').filter(`:nth-child(${targetThIdx})`).css({ width: thWidth });
            $('.prodNameRuleDup table').css({ width: tableWidth > modalWidth ? tableWidth : modalWidth });
          }
        });
      });
    }

    init();

    /**
     * 사용여부 변경
     */
    $scope.changeUse = (rule, parentIdx) => {
      shipmentModel.prodNameRuleEdit({ ord_name_rule_no: rule.ord_name_rule_no, use_yn: !!rule.use_yn, change_use_yn: true })
        .then(re => {
          changeUseYn = true;

          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙이 수정되었습니다'));

          rule.disabled = !rule.disabled;

          // 선택된 규칙을 off 시켰을경우
          if (rule.disabled && rule.selected) {
            // on 규칙중 가장 처음에 있는거
            const firstAbleRule = $scope.dupRules[parentIdx].find(({ disabled }) => !disabled);

            rule.selected = false;
            $scope.selectRule(rule);

            if (firstAbleRule) {
              firstAbleRule.selected = true;
              $scope.selectRule(firstAbleRule);
            } else {
              $scope.selectRule(rule);
            }
          }
        })
        .catch(err => {
          rule.use_yn = !rule.use_yn;
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙수정에 실패하였습니다'));
        });
    };

    /**
     * 규칙적용
     */
    $scope.apply = async () => {
      const params = {
        dupChecked: true,
        uniqList,
        applyOpt,
        applyRange,
        targetState,
        shopType
      };
      const selectedDupRule = $scope.dupRules.reduce((prev, curr) => [...prev, ...curr.filter(({ selected }) => selected)], []).filter(rule => rule);

      data.uniqRules.forEach(rule => rule.selected = true);
      params.ruleList = selectedDupRule.concat(data.uniqRules);

      try {
        const re = await shipmentModel.applyProdNameRule(params);
        const { data: { results } } = re;

        results.isApplied = true;
        commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙이 적용되었습니다'));
        $uibModalInstance.close(results);
      } catch (err) {
        const errMsg = errorSVC.getError('order', err.data.error);

        commonSVC.showToaster('error', gettextCatalog.getString('실패'), errMsg);

        return false;
      }
    };

    /**
     * 중복규칙 선택
     */
    $scope.selectRule = selectedRule => {
      const rules = $scope.dupRules.flat();
      const selectedDupNos = (selectedRule.dup_rule_no || '').concat(selectedRule.opp_rule_no || ''); // 선택한 규칙이 가진 중복번호들
      const oriSelectedRules = rules.filter(rule => rule.selected && rule.ord_name_rule_no !== selectedRule.ord_name_rule_no),
            // 기존에 선택돼있던 규칙들이 가진 중복번호들
            oriSelectedRuleNos = oriSelectedRules.reduce((acc, cur) => [...acc, cur.dup_rule_no, cur.opp_rule_no], []).filter(ruleNos => ruleNos).join();

      // ---dupDisabled된 규칙 선택했을 때 쓰는 값들---
      let newSelectedRules, // 기존에 선택돼있던 규칙 + 선택한 규칙 배열
          newSelectedRuleNos, // newSelectedRules 규칙번호 배열
          newDupDisabledRuleNos; // 새로 disable 시켜야하는 규칙번호들 (=newSelectedRules에 있는 규칙들이 가진 중복번호들)

      if (selectedRule.dupDisabled && selectedDupNos) {
        newSelectedRules = [...oriSelectedRules, selectedRule].filter(({ ord_name_rule_no }) => !selectedDupNos.includes(ord_name_rule_no)),
        newSelectedRuleNos = newSelectedRules.map(({ ord_name_rule_no }) => ord_name_rule_no),
        newDupDisabledRuleNos = newSelectedRules.reduce((acc, cur) => [...acc, cur.dup_rule_no, cur.opp_rule_no], []).filter(ruleNos => ruleNos).join();
      }

      rules.forEach(rule => {
        if (selectedDupNos && selectedDupNos.includes(rule.ord_name_rule_no)) {
          if (selectedRule.selected) {
            rule.dupDisabled = true;
          } else if (!oriSelectedRuleNos.includes(rule.ord_name_rule_no)) {
            rule.dupDisabled = false;
          }
        }

        if (newSelectedRuleNos || newDupDisabledRuleNos) {
          rule.selected = newSelectedRuleNos.includes(rule.ord_name_rule_no);
          rule.dupDisabled = newDupDisabledRuleNos.includes(rule.ord_name_rule_no);
        }

        if (rule.ord_name_rule_no === selectedRule.ord_name_rule_no) {
          rule.selected = selectedRule.selected;
        }
      });
    };

    /**
     * 취소
     */
    $scope.cancel = () => {
      $uibModalInstance.close({ changeUseYn });
    };
  });
